import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { message, Popconfirm, Table, Modal } from 'antd';
import "antd/dist/antd.css";

import addressSearch from '../../../../assets/svg/search_black.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';

import ReactDragListView from "react-drag-listview";
import searchBlack from '../../../../assets/svg/search_black.svg';

import { ModalCategoryPage, GuideProductList } from '../../../modal/AppModal';
import Layout from '../../Layout';
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';
const Brand = ({ oProps, setCheck }) => {
    const [isLoading, setLoading] = useState(false);
    const [sProductList, setProductList] = useState([]);
    const [sBrandName, setBrandName] = useState("");
    const [sProductColumns] = useState([

        {
            title: "브랜드명",
            key: "action",
            render: (text, record, index) =>
                <div className="brand_name">
                    <>
                        {text.type === "brand" &&
                            <p className="brand_mark">표준</p>
                        }
                    </>
                    <div>{text.name}</div>
                </div>
        },

        {
            title: "메뉴연동",
            key: "action",
            render: (text, record, index) =>
                <>
                    {text.type === "brand" &&
                        <Popconfirm
                            title={["메뉴 연동 하시겠습니까?", <br />, "메뉴 연동 사용시 기존 메뉴는 삭제됩니다."]}
                            onConfirm={() => {
                                setLoading(true);
                                syncMenu(text);
                            }}
                            okText="예"
                            cancelText="아니오"
                        >
                            <div className="detail_box" ><p>연동</p></div>
                        </Popconfirm>
                    }
                </>


        },

        {
            title: "메뉴복사",
            key: "action",
            render: (text, record, index) =>
                <Popconfirm
                    title={["메뉴 복사 하시겠습니까?", <br />, "메뉴 복사 사용시 기존 메뉴는 삭제됩니다."]}
                    onConfirm={() => {
                        setLoading(true);
                        copyMenu(text);
                    }}
                    okText="예"
                    cancelText="아니오"
                >
                    <div className="detail_box"><p>복사</p></div>
                </Popconfirm>
        },



    ]);

    const [modal, contextHolder] = Modal.useModal();

    const initMenu = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;

        const oData = {
            store_id: store_id
        };

        const oResponse = await oProps.oManager.fDoAxios("/sales/menu/delMenu", "post", 'login', oData);
        if (oResponse !== undefined && oResponse.success === true) {
            message.info("초기화 완료.");
            // setCheck("초기화");
        }
        else if (oResponse !== undefined && oResponse.success === false) {
            message.error("초기화 실패." + oResponse.msg);
        }
        else {
            message.error("네트워크 오류.");
        }

        getMenuListByName(sBrandName);
        setLoading(false);
    }


    const syncMenu = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;

        const oData = {
            menu_id: sIndex.menu_id,
            target_store_id: store_id
        };

        const oResponse = await oProps.oManager.fDoAxios("/sales/menu/syncMenuId", "post", 'login', oData);
        console.log(oResponse)
        if (oResponse !== undefined && oResponse.success === true) {
            message.info("연동완료.");
            // setCheck("연동" + sIndex.menu_id);
        }
        else if (oResponse !== undefined && oResponse.success === false) {
            message.error("연동실패." + oResponse.msg);
        }
        else {
            message.error("네트워크 오류.");
        }
        setLoading(false);
    }

    const copyMenu = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;

        const oData = {
            menu_id: sIndex.menu_id,
            target_store_id: store_id
        };

        const oResponse = await oProps.oManager.fDoAxios("/sales/menu/copyMenuId", "post", 'login', oData);

        if (oResponse !== undefined && oResponse.success === true) {
            message.info("복사완료.");
            // setCheck("복사" + sIndex.menu_id);
        }
        else if (oResponse !== undefined && oResponse.success === false) {
            message.error("복사실패." + oResponse.msg);
        }
        else {
            message.error("네트워크 오류.");
        }
        setLoading(false);
    }

    const getMenuListByName = async (sName) => {
        const oData = {
            name: sName
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/brand/menuListName", "post", "login", oData);
        if (oResponse !== undefined && oResponse.sResult !== undefined) {
            setProductList(oResponse.sResult);
        }
    }

    const findMenu = (e) => {
        // console.log(e.target.value);
        setBrandName(e.target.value);
        getMenuListByName(e.target.value);
        setLoading(false);
    };

    const modalOpen = {
        content: (
            <ModalCategoryPage sProps={oProps} />
        )
    };

    const modalOpenGuide = {
        width: "50vw",
        content: (
            <GuideProductList sProps={oProps} />
        )
    };

    useEffect(() => {
        getMenuListByName(sBrandName);
    }, [])



    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.product}>
            <BrowserView>
                <Style>
                    <div className="container">
                        {isLoading ?
                            <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                            :
                            <div>
                                <div className="category_box" style={{ height: 'auto' }}>
                                    <div>
                                        <div className="title">
                                            <p>내 브랜드 찾기</p>
                                        </div>
                                        <input type="seach" className='brandSearch' placeholder="🔎브랜드명을 검색해주세요" onChange={findMenu} />
                                    </div>
                                </div>
                                <div className="table_wrapper">
                                    <Table
                                        columns={sProductColumns}
                                        dataSource={sProductList}
                                        pagination={true}

                                    />
                                    <Popconfirm
                                        title={["초기화시 기존 메뉴는 복구 되지 않습니다.", <br />, "메뉴 초기화 하시겠습니까?"]}
                                        onConfirm={() => {
                                            setLoading(true);
                                            initMenu();
                                        }}
                                        okText="예"
                                        cancelText="아니오"
                                    >
                                        <div className="insert_box">메뉴 초기화</div>
                                    </Popconfirm>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="side_help_content">
                        <div className="app_modal_container" onClick={() => modal.success(modalOpen)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={addressSearch} alt="none" />
                                    <p>앱 미리보기</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>내 입력정보의 예상화면을 <br />미리 확인해보세요</p>
                                </div>
                            </div>
                        </div>
                        <div className="sync_guide_container" >
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={guide_question} alt="none" />
                                    <p>메뉴 복사</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>
                                        해당 브랜드 메뉴를 복사합니다.
                                        <br />
                                        복사된 메뉴는 상품관리탭에서 자유롭게 설정이 가능합니다.
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="copy_guide_container">
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={guide_question} alt="none" />
                                    <p>메뉴 연동</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>
                                        브랜드 매장에서 상품관리를 합니다.
                                        <br />
                                        브랜드 매장에서 상품추가시 자동으로 반영됩니다
                                        <br />
                                        메뉴 연동시 상품관리 기능을 사용할 수 없습니다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {contextHolder}
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>

                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <div>
                            <div className="brandSearch" style={{ height: 'auto' }}>
                                <div>
                                    <div className="title">
                                        <p>내 브랜드 찾기</p>
                                    </div>
                                    <input type="seach" className='brandSearch' placeholder="🔎브랜드명을 검색해주세요" onChange={findMenu} />
                                </div>

                                <div className="table_wrapper">
                                    <ReactDragListView.DragColumn
                                        // onDragEnd={dragProps}
                                        handleSelector="a"
                                    >
                                        <Table
                                            columns={sProductColumns}
                                            dataSource={sProductList}
                                            pagination={true}

                                        />
                                    </ReactDragListView.DragColumn>
                                    <Popconfirm
                                        title={["초기화시 기존 메뉴는 복구 되지 않습니다.", <br />, "메뉴 초기화 하시겠습니까?"]}
                                        onConfirm={() => {
                                            setLoading(true);
                                            initMenu();
                                        }}
                                        okText="예"
                                        cancelText="아니오"
                                    >
                                        <div className="insert_box">메뉴 초기화</div>
                                    </Popconfirm>
                                    {/* 
                                <div className="location_box_line" style={{marginTop: "10px"}}>
                                    <div className="how_box">
                                        <p>순서를 변경하고 싶으시다면 순서의 숫자를 잡고 드래그를 해주세요!</p>
                                    </div>
                                </div> 
                            */}
                                </div>
                            </div>
                        </div>
                    }

                    <div className="app_modal_container">
                        <div className="app_modal" onClick={() => modal.success(modalOpen)}>
                            <p>앱 미리보기</p>
                            <img className='help_content_img' src={searchBlack} alt="search" style={{ marginLeft: "5%" }} />
                        </div>
                        <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
                    </div>




                    {contextHolder}
                </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default Brand;

const Style = styled.div`
    position: relative;
    width: 42vw;
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  
    
    .location_box_line {
        align-items: center;
        margin-bottom: 50px;
        height: 50px;
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    /* 앱미리보기/메뉴복사/메뉴연동 공통css */
    .side_help_content {
        position: absolute;
        left: 103%;
        top: 0;
    }
    .app_modal_container, .sync_guide_container, .copy_guide_container {
        padding: 13px 13px 40px 13px;
        background: red;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #FBFBFB;
        border: 1px solid #DFDFE3;
        border-radius: 8px;
        
        .arrow_box img {
            width: 5px;
        }
        .text_box {
            margin-left: 5px;
            min-width: 220px;

            .title_box {
                height: auto;
                margin-bottom: 9px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                }
            }
            .help_content_img {
                width: 18px;
            }
        }
    }    

    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }


    .option_title {
        margin-top: 70px;

        .selected_option_box {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 50px;
            margin-top: 10px;
            border: 1px solid #BBBBBB;
            border-radius: 5px;

            p {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
        .option_select_box {
            margin-top: 20px;
            display: flex;
            align-items: center;
            height: 30px;
            cursor: pointer;

            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #617BE3;
            }
        }

    }
    

    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 5vw;
            border: 1px solid #DD1212;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #DD1212;
            }
        }

        .new_category_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 5vw;
            background: #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box:hover {
                border: none;
                background: #DD1212;
                p {
                    color: #fff;
                }
            }
    }

    .table_wrapper {
        margin-top: 2%;
    }
    .option_table_wrapper {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .insert_box {
        max-width: 25%;
        margin-left: auto;
        padding: 10px 0;
        margin-top: 40px;
        border: 1px solid rgb(0, 30, 98);
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        color: rgb(0, 30, 98);
    }
    .insert_box:hover{
        background: rgb(0, 30, 98);
        color: #fff;
    }



    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .container {
        width: 42vw; 
        padding: 32px;
        margin-bottom: 1px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
    }

    .category_box {
        .title {

            p {
                margin-right: auto;
                font-size: 1rem;
                font-weight: 700;
                color: #000;
            }
        } 
        .brandSearch {
            width: 100%;
            margin: 30px 0 10px 0;
            padding: 12px;
            border: 1px solid #617BE3;
            border-radius: 4px;
            outline: none;
        }
        .brandSearch::placeholder {
            font-size: 17px;
            font-weight: 500;
            color: #617BE3;
        }
    }

    /* 브랜드생성 */
    .brand_register {
    
        h3 {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 500;
        }
        input {
            width: 100%;
            margin: 10px 0;
            padding: 12px;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            font-weight: 500;
            color: #000;
        }
        select {
            width: 100%;
            padding: 12px;
            margin-top: 10px;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        button {
            width: 100%;
            padding: 15px 0;
            margin-top: 100px;
            background: #617BE3;
            border-radius: 5px;
            border: none;
            color: #fff;
            font-size: 0.9rem;
            font-weight: 700;
            cursor: pointer;
        }
    }

    /* table */
    .ant-table-cell:nth-child(1) {
        width: 60%;
    }
    .ant-table-cell:nth-child(2) {
        width: 20%;
        text-align: center;
    }
    .ant-table-cell:nth-child(3) {
        width: 20%;
        text-align: center;
    }
    .brand_name {
        max-width: 250px!important;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div {
            max-width: 100%!important;
        }
    }
    .brand_mark {
        min-width: 50px;
        margin-right: 4px;
        background: #1BA1F2;
        padding: 1px 12px;
        color: #fff;
        border-radius: 4vw;
        vertical-align: center;
    }
    .detail_box {
        width: 80%!important;
        padding: 5px;
        margin: 0 auto;
        border-radius: 8px;
        background: #617be3;   
        cursor: pointer;

        p {
            color: #fff;
        }
    }


`



const Mstyle = styled.div`

    .init_button {
        text-align: center;
        width: 80%;
        margin: 10px auto;
        padding: 12px 0;
        background-color: #617be3;
        font-size: 1rem;
        font-weight: 700;
        border-radius: 4px;
        border: none;
        outline: none;
        color: white;
    }

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .option_table_wrapper {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            .detail_line {
                margin-left: 5px;
                font-size: 0.6rem;
                font-weight: 700;
                color: #617BE3;
            }

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }
    .category_line_box {
        padding: 10px 16px;
        background-color: #617BE3;

        .select_bank {
            padding: 6px;
            width: 100%;
            background: #fff;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table_content {
            display: flex;
            align-items: center;
            width: 65%;
            height: 7vh;

            .image_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15vw;
                height: 8vh;
                background: #F1F3F7;
                border-radius: 5px;
                margin-right: 5%;

                img {
                    height: 7vh;
                    width: 10vw;
                }
            }

            .main_title {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
                height: 3vh;
            }
            p {
                font-size: 0.8rem;
                font-weight: 700;
                height: 3.5vh;
            }
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;
            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    /* 내 브랜드 찾기 */
    .brandSearch {
        padding: 5% 3%;
        background: #fff;

        .title p {
            font-size: 16px;
            font-weight: bold;
        }
        .brandSearch {
            width: 100%;
            margin: 20px 0 10px 0;
            padding: 10px 5px;
            border: 1px solid #617BE3;
            border-radius: 4px;
            outline: none;
        }
        .brandSearch::placeholder {
            font-size: 14px;
            font-weight: 500;
            color: #617BE3;
        }
    }

    /* 메뉴초기화 */
    .insert_box {
        max-width: 40%;
        margin-left: auto;
        padding: 10px 0;
        margin-top: 40px;
        border: 1px solid rgb(0, 30, 98);
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        color: rgb(0, 30, 98);
    }
    .insert_box:hover{
        background: rgb(0, 30, 98);
        color: #fff;
    }

    /* table */
    .ant-table-cell:nth-child(1) {
        width: 50%;
    }
    .ant-table-cell:nth-child(2) {
        width: 25%;
        text-align: center;
    }
    .ant-table-cell:nth-child(3) {
        width: 25%;
        text-align: center;
    }
    .brand_name {
        max-width: 100px!important;
        display: flex;
        align-items:center;
        justify-content: flex-start;

        div {
            max-width: 100%!important;
        }
    }
    .brand_mark {
        min-width: 50px;
        margin-right: 4px;
        background: #1BA1F2;
        padding: 1px 12px;
        color: #fff;
        border-radius: 4vw;
        vertical-align: center;

        
    }
    .detail_box {
        width: 100%;
        background: #617BE3;
        padding: 5px 10px;
        border-radius: 4px;

        p {
            color: #fff;
            font-weight: 700;
        }
    }
`


