import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Table, DatePicker } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import manual from '../../../../assets/svg/manual.svg';
import addressSearch from '../../../../assets/svg/addressSearch.svg';

import AdjustmentPayment from './AdjustmentPayment';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';
const defaultFormat = "YYYY-MM";

const Payment = ({ oProps }) => {
   const [isLoading, setLoading] = useState(true);

   const [sAmount, setAmount] = useState(0);
   const [sPeriod, setsPeriod] = useState(null);
   const [dateValue, setDateValue] = useState(moment().format(defaultFormat));
   const [sList, setList] = useState([]);
   const [fakeList] = useState([]);
   const [iList, setIList] = useState([]);

   const onOperatingDate = async (date, dateString) => {
      if (date != null) {
         setDateValue(dateString);
      } else {
         setDateValue(moment().format(defaultFormat));
      }
   }

   const searchList = () => {
      setLoading(true);
      getList(dateValue);
   }

   const getList = async (bIndex) => {
      let storeId = oProps.UserConfigReducer.StoreID;
      let fromDate = moment().format(defaultFormat);
      if (bIndex !== undefined && bIndex !== null) {
         fromDate = bIndex;
      }

      const oData = {
         storeId,
         fromDate,
      };
      const oResponse = await oProps.oManager.fDoAxios(`/payment/AdjustmentPayment/v2`, "post", "login", oData);
      if (oResponse !== undefined) {
         setAmount(oResponse.amount);
         setsPeriod(oResponse.period);
         setList(oResponse.result);
      }
      setLoading(false);
   }

   useEffect(() => {
      getList();
   }, []);

   return (
      <Layout sProps={oProps} routeList={layoutHeaderLists.payment}>
         <BrowserView>
            <Style>
               <div className="content_container">
                  <div className="container">
                     <div className="title_box">
                        <h2>정산 조회</h2>
                        <div className="tip">
                           <SVG src={manual} width="18px" height="18px" />
                           <span><strong>정산내역</strong>을 날짜별 확인하고<br /><strong>엑셀파일로</strong>다운로드 가능합니다</span>
                        </div>
                     </div>
                     {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                           <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <>
                           <div className="title_line">
                              <p>정산 예상 금액<span className="period_box">({sPeriod})</span></p>
                           </div>
                           <div className="last_line">
                              <div style={{ width: "50%", cursor: "" }}>
                                 <div className="today_box">
                                    <p>{sAmount} 원</p>
                                 </div>
                              </div>
                           </div>
                           <div className="searching_line">
                              <div style={{ width: "25%" }}>
                                 <div className="search_date">
                                    <DatePicker
                                       picker="month"
                                       bordered={false}
                                       style={{ marginLeft: "7%", fontSize: "0.7rem" }}
                                       value={moment(dateValue, defaultFormat)}
                                       onChange={onOperatingDate}
                                    />
                                 </div>
                              </div>
                              <div style={{ width: "30%" }}>
                                 <div className="search_bar" onClick={searchList} >
                                    <img src={addressSearch} alt="none" />
                                    <p>조회</p>
                                 </div>
                              </div>
                              <div style={{ width: "25%" }}>

                              </div>
                              <div style={{ width: "25%" }}>

                              </div>
                           </div>
                           {sList.length > 0 ?
                              <>
                                 <div className="data_title">
                                    <div className="pay_date">
                                       <p>입금일</p>
                                    </div>
                                    <div className="pay_status">
                                       <p>상태</p>
                                    </div>
                                    <div className="pay_period">
                                       <p>정산대상기간</p>
                                    </div>
                                    <div className="pay_amount">
                                       <p>입금금액</p>
                                    </div>
                                    <div className="excel_download" />
                                    <div className="detail_line" />
                                 </div>
                                 <div className={"data_box"}>
                                    {sList.map((item, index) => {
                                       return (
                                          <AdjustmentPayment sName={oProps.UserConfigReducer.StoreName} sData={item} key={"listing-" + index.toString()} />
                                       )
                                    })}
                                 </div>
                              </>
                              :
                              <div className={"nodata_box"}>
                                 <Table
                                    bordered={true}
                                    pagination={false}
                                    dataSource={fakeList}
                                 />
                              </div>
                           }
                        </>
                     }
                  </div>
               </div>
            </Style>
         </BrowserView>
         <MobileView>
            <Mstyle>
               {isLoading ?
                  <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                     <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                  </div>
                  :
                  <>
                     <div className="payment_title_line">
                        <div className="payment_title">
                           <h2>정산조회</h2>
                           <div className="tip">
                              <SVG src={manual} width="18px" height="18px" />
                              <span><strong>자세한 정산내역 조회는</strong>PC에서<br />확인이 가능합니다.</span>
                           </div>
                        </div>
                        <div className="first_line">
                           <h3>이번주 정산 받을 금액</h3>
                           <div className="today_box">
                              <p>{sAmount} 원</p>
                           </div>
                        </div>
                        <div className="middle_line">
                           <DatePicker
                              picker="month"
                              bordered={false}
                              style={{ fontSize: "0.7rem", width: "100%", marginLeft: "5%", marginRight: "5%" }}
                              value={moment(dateValue, defaultFormat)}
                              onChange={onOperatingDate}
                           />
                        </div>
                        <div className="last_line" onClick={searchList} >
                           <img src={addressSearch} />
                           <p>조회</p>
                        </div>
                     </div>
                     {sList.length > 0 ?
                        <>
                           {sList.map((item, index) => {
                              // 입금완료
                              // 입금예정
                              let sPaymentStatus = "입금예정";
                              //if (item.payment_status_text) {
                              //   sPaymentStatus = item.payment_status_text;
                              //}

                              let today = moment().isoWeekday();
                              let iYear = moment().year();
                              let bCheckTime = moment().isAfter(moment({ hour: 18, minute: 0 }));
                              if (today === 3 && bCheckTime === true) {
                                 sPaymentStatus = "입금완료";
                              }

                              let sDate = iYear.toString() + "-" + item.date;
                              let iDiff = moment().isAfter(sDate, 'days');

                              if (iDiff === true) {
                                 sPaymentStatus = "입금완료";
                              }

                              return (
                                 <div key={"listing-" + index.toString()} className="table_box">
                                    <div className="box_line">
                                       <p>거래기간</p>
                                       <p>{item.period}</p>
                                    </div>
                                    <div className="box_line">
                                       <p>입금상태</p>
                                       {sPaymentStatus === "입금예정" ?
                                          <p style={{ color: "#e39161" }}>{sPaymentStatus}</p>
                                          :
                                          <p style={{ color: "#617BE3" }}>{sPaymentStatus}</p>
                                       }
                                    </div>
                                    <div className="box_line">
                                       <p>(A)총 거래금액</p>
                                       <p>{item.totalPayment}원</p>
                                    </div>
                                    <div className="box_line">
                                       <p>(B)정산잔액</p>
                                       <p>{item.total_carried_balance}원</p>
                                    </div>
                                    <div className="box_line">
                                       <p>(C)스루-부담-할인</p>
                                       <p>{item.throoTotal}원</p>
                                    </div>
                                    <div className="box_line">
                                       <p>(D)사장님-부담-할인</p>
                                       <p>{item.partnerTotal}원</p>
                                    </div>
                                    <div className="box_line">
                                       <div className='guide_box'>
                                          <p>(E)카드결제 수수료</p>
                                          <div className="tip">
                                             <SVG src={manual} width="14px" height="14px" />
                                             <span style={{ left: '0px' }}><strong>카드수수료 3%(부가세별도) </strong>가 차감되어 정산 됩니다.</span>
                                          </div>
                                       </div>
                                       <p>{item.fee}원</p>
                                    </div>
                                    {item.usage_balance &&
                                       <div className="box_line">
                                          <p>(F)서비스 이용료</p>
                                          <p>{item.usage_balance}원</p>
                                       </div>
                                    }
                                    <div className="box_line">
                                       <p>입금받을금액((A + B) - (D + E + F))</p>
                                       <p style={{ fontWeight: "bold" }}>{item.rawReturn}원</p>
                                    </div>
                                 </div>
                              )
                           })}
                        </>
                        :
                        <div className="table_box_none">
                           <p>데이터가 없습니다.</p>
                        </div>
                     }
                  </>
               }
            </Mstyle>

         </MobileView>
      </Layout>
   )
}

export default Payment;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .content_container {
        width: 42vw; 
        border-radius: 4px;
        margin-bottom: 4%;
        background-color: #F1F3F7;

        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 180px;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }
    }

    .title_box {
        display: flex;
        align-items: center;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 16px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
    }

    .title_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3vh;
        width: 100%;

        .period_box {
            margin-left: 10px;
            color: #617BE3;
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }
    }
    .last_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 8vh;
        padding-bottom: 1%;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 24px;
            color: #617BE3;
        }

        .today_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 5vh;
            border-bottom : 1px solid #617BE3;
        }

        .yesterday_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
        }
    }

    .searching_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;
    }

    .search_bar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #fff;
        }
    }

    .search_file {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        width: 70%;
        height: 5vh;
        
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.7rem;
            text-align: center;
            color: #fff;
        }
    }

    .search_no_file {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        width: 70%;
        height: 5vh;
        
        background: #dfdfdf;
        border-radius: 5px;
        cursor: not-allowed;

        p {
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .search_selectOption {
        display: flex;
        align-items: center;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .search_date {
        display: flex;
        align-items: center;
        margin-right: 5%;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .data_box {
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 20%;
    }

    .nodata_box {
        margin-top: 3%;
    }

    .data_title {
        margin-top: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #000;
        border-bottom: 1px solid #dfdfdf;
        height: 3vh;

        .pay_date {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;
        }
        .pay_status {
            width: 13%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .pay_period {
            width: 21%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .pay_amount {
            width: 22%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .excel_download {
            width: 23%;
            display: flex;
            align-items: center;
            justify-content: center;
        } 

        .detail_line {
            width: 13%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p{
            font-style: normal;
            font-weight: 400;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }
    
`





const Mstyle = styled.div`
    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .payment_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .payment_title_line {
        padding: 20px 16px;
        margin-bottom: 5%;
        background-color: #fff;

        h2 {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .first_line {
        margin-top: 5%;
        height: 15vh;
        border-bottom: 1px solid #dfdfdf;

        h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }

        .today_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 6vh;
            border-bottom: 1px solid #617BE3;
            margin-top: 2%;
            margin-right: 5%;

        }

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 24px;
            color: #617BE3;
        }
        
    }

    .middle_line {
        margin-top: 7%;
        display: flex;
        align-items: center;
        height: 6vh;
        border : 1px solid #000;
        border-radius: 5px;
        font-size: 0.8rem;
        margin-bottom: 5%;
    }

    .last_line {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6vh;
        border-radius: 5px;
        background-color: #617BE3;
        

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            margin-left: 3%;
            color: #fff; 
        }
    }

    .table_box { 
        background: #fff;
        border-top: 1px solid #dfdfdf;
        padding-bottom: 2%;

        .box_line {
            margin: 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .guide_box{
               display: flex;
               align-items: center;
               justify-content: flex-start;

               svg {
                  margin-left: 5px;
               }
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 0.8rem;
                line-height: 24px;
                color: #000;
            }
        }
        .box_line_red {
         margin: 5%;
         display: flex;
         align-items: center;
         justify-content: space-between;

         .guide_box{
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
               margin-left: 5px;
            }
         }
         p {
             font-style: normal;
             font-weight: 400;
             font-size: 0.8rem;
             line-height: 24px;
             color: #ff0000;
         }
     }
    }

    .table_box_none {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vh;
        background: #fff;
        border-bottom: 1px solid #dfdfdf;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 24px;
        }
    }
`


