import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import "../../../assets/css/font.css";

import footerLogo from "../../../assets/svg/footerLogo.svg";
import arrow_up from "../../../assets/svg/arrow_up2.svg";
import kakaoLogo from "../../../assets/svg/throoKakao.svg";
import kbButton from "../../../assets/img/kbButton.png";

const FooterPage = ({ oProps }) => {
  const kakaoTalk = () => {
    if (isMobile) {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    } else {
      window.open(
        "https://pf.kakao.com/_tzxkNK/chat",
        "_blank",
        "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no"
      );
    }
  };
  const openKblife = () => {
    window.open("https://throo.co.kr/kb-life-ceo.html", "_blank");
  };
  const [bIsPosStore, set_bIsPosStore] = useState(false);

  useEffect(() => {
    let oUserData = oProps.UserConfigReducer;
    if (oUserData && oUserData.isPOSStore === true) {
      set_bIsPosStore(true);
    }
  }, []);

  if (bIsPosStore === true) {
    return <FooterStyle></FooterStyle>;
  }

  return (
    <FooterStyle>
      <div className="kakao">
        <button
          className="kakaoButton"
          onClick={kakaoTalk}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SVG src={kakaoLogo} width="250px" title="사장님창구 카카오톡상담" />
        </button>
        <button
          onClick={openKblife}
          style={{
            paddingBottom: "50px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={kbButton}
            alt="kb보험상식체크"
            width="100%"
            style={{ width: "270px", marginRight: "14px" }}
          />
        </button>
      </div>
      <div className="FooterPage">
        <div className="text">
          <SVG
            className="footerLogo"
            src={footerLogo}
            width="90px"
            height="31px"
            title="스루로고"
          />
          <p>인비저블 아이디어</p>
          <p>대표이사:윤언식</p>
          <p>서울특별시 서초구 서초대로398 플래티넘타워 4-7층</p>
          <p>통신판매업신고: 2020-서울서초-3341</p>
          <p>사업자등록번호: 159-86-01794</p>
        </div>
        <a
          href="#"
          data-href="HeaderPage"
          className="js-self-inst"
          style={{
            background: "#fff",
            height: "7vh",
            width: "15vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5vh",
          }}
        >
          <SVG
            className="arrow_up"
            src={arrow_up}
            width="30px"
            height="30px"
            title="위로이동버튼"
          />
        </a>
      </div>
    </FooterStyle>
  );
};

export default FooterPage;

const FooterStyle = styled.div`
  .kakao {
    background-color: #f1f3f7;
    padding-top: 5% !important;
  }
  button {
    width: 100%;
    margin-top: 5% !important;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background: none;
  }

  .FooterPage {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px;
    background-color: #f1f1f1;

    svg {
      margin-bottom: 16px;
    }
    svg path {
      fill: #bbb;
    }
    p {
      margin-bottom: 5px;
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      color: #bbb;
    }

    /* 맨위로 이동 버튼 */
    .arrow_up {
      position: fixed;
      z-index: 999;
      bottom: 0;
      right: 0;
      margin: 16px;
    }
    .arrow_up path {
      fill: #000;
    }
  }
`;
