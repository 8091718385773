export const WebRoute = {
  HOME: '/',
  PROMOTION: '/promotion',
  NOTICE: '/notice',
  POSGUIDE: '/posGuide',
  DOWNLOAD: '/posdownload',
  SERVICE: '/service',
  GUIDE: '/guide',
  LANDING: '/landing',
  JOINFORM: '/join',
  CEOGUIDE: '/ceoguide',
  CEOTIP: '/ceotip',
  CEOTIPSUB: '/ceotipSubPage',

  ADMIN_LOGIN: '/selfmanage',
  ADMIN_SIGNUP: '/selfmanage/signup',
  ADMIN_SIGNIN: '/selfmanage/signin',
  ADMIN_FIND_ID: '/selfmanage/findid',
  ADMIN_FIND_PW: '/selfmanage/findpw',
  ADMIN_STORE_LIST: '/selfmanage/stores',
  ADMIN_STORE_ADD: '/selfmanage/stores/add',
  ADMIN_SALES_HOME: '/selfmanage/sales',
  ADMIN_SALES_DASHBOARD: '/selfmanage/dashboard',

  ADMIN_PROPUCT: '/selfmanage/product',
  ADMIN_NOTICE: '/selfmanage/notice',
  ADMIN_STORE: '/selfmanage/store',
  ADMIN_GUIDE: '/selfmanage/guide',

  ADMIN_STORE_INFO: '/selfmanage/info',
  ADMIN_STORE_PICKUP_INFO: '/selfmanage/info/pickup',
  ADMIN_STORE_OPERATION_INFO: '/selfmanage/info/operation',
  ADMIN_STORE_DUVET: '/selfmanage/info/duvet',
  ADMIN_STORE_PICTURE: '/selfmanage/info/picture',
  ADMIN_STORE_DELIVERY: '/selfmanage/info/delivery',

  ADMIN_PRODUCT_CATEGORY: '/selfmanage/product',
  ADMIN_PRODUCT_PRODUCT: '/selfmanage/product/manage',
  ADMIN_PRODUCT_OPTION: '/selfmanage/product/option',
  ADMIN_PRODUCT_BRAND: '/selfmanage/product/brand',

  ADMIN_BUSINESS: '/selfmanage/business',


  ADMIN_PAYMENT: '/selfmanage/payment',
  ADMIN_PAYMENT_CHART: '/selfmanage/payment/chart',
  ADMIN_PAYMENT_CALCULATE: '/selfmanage/payment/calculate',
  ADMIN_PAYMENT_TAX: '/selfmanage/payment/tax',

  ADMIN_INVENTORY: '/selfmanage/inventory',

  ADMIN_COUPON: '/selfmanage/coupon',
  ADMIN_COUPON_ADD: '/selfmanage/coupon/add',

  ADMIN_STAMP: '/selfmanage/stamp',
  ADMIN_STAMP_ADD: '/selfmanage/stamp/add',
  ADMIN_STAMP_EDIT: '/selfmanage/stamp/edit',

  ADMIN_COMMERCIAL: '/selfmanage/commercial',
  ADMIN_COMMERCIAL_CHART: '/selfmanage/commercial/chart',
  ADMIN_COMMERCIAL_ADD: '/selfmanage/commercial/add',
  ADMIN_COMMERCIAL_PAYMENT_LIST: '/selfmanage/commercial/plist',

  ADMIN_GUIDE_POS: '/selfmanage/guide/pos',
  ADMIN_GUIDE_PRINTER: '/selfmanage/guide/printer',

  ADMIN_REVIEW: '/selfmanage/review',

  AUTHENTICATESTORE: '/selfmanage/authenticate/store',
  AUTHENTICATEUSER: '/selfmanage/authenticate/user',
  AUTHENTICATESALES: '/selfmanage/authenticate/sales',

  POS: '/selfmanage/pos/item',
  POS_INVENTORY: '/selfmanage/pos/inventory',
  POS_PAYMENT: '/selfmanage/pos/payment',
  POS_PRODUCT: '/selfmanage/pos/product',
  POS_USERLOCATION: '/selfmanage/pos/user/location',

  MANAGERAPPCOMERCIAL: '/selfmanage/commercial/app/ceo',
  APP_SIDEBAR:'/selfmanage/app/ceo/sidebar',

  SALESAPP: '/selfmanage/app/sales',

  FAIL: '/error',

  // 비하인드키오스크
  BEHIND: '/behind',
  BEHINDINVENTORY: '/behind/inventory'

};
