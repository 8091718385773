import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import ScrollContainer from 'react-indiana-drag-scroll';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Loader from "react-loader-spinner";
import styled from 'styled-components';
import { message } from 'antd';
import "antd/dist/antd.css";
import '../../assets/css/font.css';

import mainLogo from '../../assets/svg/loginlogo.svg';


import Adjustment from './manage/Adjustment';
import Payment from './manage/Payment';
import OrderList from './manage/OrderList';
import moment from 'moment';
import { WebRoute } from '../../navigation/WebRoutes';
import { useNavigate } from 'react-router-dom';

const Main = ({ sProps, fModeChange }) => {
    const [loading, setLoading] = useState(true);

    const [iStatus, setStatus] = useState(0);
    const [sCategory, setCategory] = useState("매출조회");

    const [xList, setXList] = useState([]);
    const navigate = useNavigate()
    const [sList] = useState([
        { id: 0, name: "매출조회", value: "0" },
        { id: 1, name: "일자별 주문내역", value: "1" },
        { id: 2, name: "정산", value: "2" },
        { id: 3, name: "부가세 신고자료", value: "3" },
    ]);
    const [iList] = useState([
        { id: 0, name: "매출조회", value: "0" },
        { id: 1, name: "일자별 주문내역", value: "1" },
        { id: 2, name: "정산", value: "2" },
    ]);

    const moveCategory = async (aIndex, sIndex) => {
        if (sIndex === "부가세 신고자료") {
            message.info("죄송합니다 준비중입니다.");
        } else {
            setStatus(aIndex);
            setCategory(sIndex);
        }
    }

    const modeChange = async () => {
        // if(fModeChange !== undefined && typeof fModeChange === "function"){
        //     await fModeChange("sales");
        // }

        let oUserConfig = {};

        oUserConfig['SalesLoggedIn'] = false;
        if (!sProps.UserConfigReducer.RememberId) {
            oUserConfig['SalesEmail'] = ""
        }
        oUserConfig['SalesId'] = 0;
        oUserConfig['SalesKey'] = "";

        oUserConfig['SalesUserName'] = "";
        oUserConfig['SalesStoreList'] = [];

        oUserConfig['SalesLoginTime'] = "";
        oUserConfig['isPOS'] = false;
        oUserConfig['LoggedIn'] = false;
        oUserConfig['Token'] = "";
        oUserConfig['RefreshToken'] = "";
        oUserConfig['UUID'] = "";

        oUserConfig['SalesId'] = 0;

        oUserConfig['UserKey'] = "";
        oUserConfig['SalesUserName'] = "";
        oUserConfig['SalesStoreList'] = [];

        oUserConfig['StoreID'] = 0;
        oUserConfig['StoreName'] = "";
        oUserConfig['StorePhone'] = "";
        oUserConfig['StoreOwner'] = "";
        oUserConfig['Address'] = "";
        oUserConfig['StoreLat'] = "";
        oUserConfig['StoreLng'] = "";

        oUserConfig['accessible'] = "";
        oUserConfig['LoginTime'] = "";


        await sProps.reduxSetUserConfig(oUserConfig);

        navigate(WebRoute.ADMIN_SIGNIN)
    }

    const asyncData = async () => {
        const oData = {
            sales_id: sProps.UserConfigReducer.SalesId,
            type: sProps.UserConfigReducer.SalesUserType,
        }
        const oResponse = await sProps.oManager.fDoAxios("/sales/getSalesTeamDataV2", "post", null, oData);
        if (oResponse !== undefined && oResponse !== null) {
            setXList(oResponse);
        }
        setLoading(false);
    }

    useEffect(() => {
        asyncData();
    }, []);

    return (
        <Style>
            <div className="headerPage">
                <div className="logo">
                    <SVG className="mainLogo" src={mainLogo} width="54px" height="24px" title="로그인페이지로고" />
                </div>
                <div className="user_box">
                    <p style={{ fontSize: "0.9rem" }}>{sProps.UserConfigReducer.SalesUserName}님</p>
                </div>
                <div className="logout_box">
                    <p style={{ fontSize: "0.9rem" }} className="logout" onClick={modeChange}>리스트로 이동</p>
                </div>
            </div>
            <div className="totalContent">
                <div className="wrapper">
                    <Row>
                        <Col>
                            <div className="firstLine">
                                <div className="menu_container">
                                    <div className="tab">
                                        {sProps.UserConfigReducer.SalesUserType === "owner" ?
                                            <ScrollContainer style={{ width: "100%" }}>
                                                {sList.map((item, index) => {
                                                    return (
                                                        <div className={item.name === sCategory ? "scroll_menu_active" : "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.id, item.name)}>
                                                            {item.name}
                                                        </div>
                                                    )
                                                })}
                                            </ScrollContainer>
                                            :
                                            <ScrollContainer style={{ width: "100%" }}>
                                                {iList.map((item, index) => {
                                                    return (
                                                        <div className={item.name === sCategory ? "scroll_menu_active" : "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.id, item.name)}>
                                                            {item.name}
                                                        </div>
                                                    )
                                                })}
                                            </ScrollContainer>
                                        }
                                    </div>
                                </div>
                                {loading ?
                                    <div style={{ display: "flex", justifyContent: "center", height: "8vh", alignItems: "center" }}>
                                        <Loader type="BallTriangle" color="#13166b" height={40} width={40} timeout={9000000} />
                                    </div>
                                    :
                                    <>
                                        {parseInt(iStatus) === 0 && <Payment oProps={sProps} parentList={xList} />}
                                        {parseInt(iStatus) === 1 && <OrderList oProps={sProps} parentList={xList} />}
                                        {parseInt(iStatus) === 2 && <Adjustment oProps={sProps} parentList={xList} />}

                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </Style>
    )

}

export default Main;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .mobile_container {
        width: 100%;
        margin: 0 auto;
    }

    .totalContent {
        background-color: #F1F3F7;
        width: 100%;
        height: 100%;
    }

    .headerPage {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
        height: 4vh;
        padding : 32px;
        .logo {
            flex: 0 0 82%;
            justify-content: flex-start;
            cursor: pointer;
        }
        p {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #000;
        }

        .user_box {
            position: absolute;
            right: 130px;
        }
        .logout_box {
            cursor: pointer;
            position: absolute;
            right: 15px;
        }
    }

    .wrapper {
        background-color: #F1F3F7;
        padding-top: 2%;
        padding-left: 30%;
        padding-bottom: 2.2%;
    }

    .menu_container {
        margin-top: 10px;
        border-radius: 4px;

        .tab {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw; 
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
`