import React, { useState, Fragment } from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import Modal from 'react-modal';
import styled from 'styled-components';
import '../../assets/fonts/font.css'


const AlcoholModal = (oProps) => {
    const iFnAddList = oProps.fnAddList;
    const [sCheckValue, set_sCheckValue] = useState(false);
    const [sPasswordValue, set_sPasswordValue] = useState('');
    const [eMessage, set_eMessage] = useState('');

    // 모달
    const closeModal = () => {
        if (sCheckValue === false) {
            set_eMessage('');
            set_sPasswordValue('');
            oProps.setIsChecked(false);
            oProps.setModalIsOpen(false);
            oProps.set_checkValue('0');
        }
    };
    const cancelModal = () => {
        if (sCheckValue === false) {
            set_eMessage('');
            set_sPasswordValue('');
            oProps.setModalIsOpen(false);
            oProps.setIsChecked(false);
            oProps.set_checkValue('0');
        }
    };

    // 비밀번호 입력
    const onChange = (e) => {
        set_sPasswordValue(e.target.value);
    };

    // 비밀번호 확인
    const onSubmit = async () => {
        console.log('oProps',oProps)
        try {
            const aPassword = await oProps.iAlcoholList(sPasswordValue);

            if (aPassword !== undefined && aPassword.success === true) {
                oProps.setModalIsOpen(false);
                set_eMessage('');
                oProps.set_successMessage('인증완료');
                oProps.set_checkValue('1');
            } else {
                set_eMessage('비밀번호가 틀렸습니다. 다시 입력해주세요.')
                oProps.setModalIsOpen(true);
                set_sPasswordValue('');
                oProps.set_checkValue('0');
            }

        } catch (err) {
            console.log("err-->", err);
        }
    }

    return (
        <Fragment>
            <BrowserView>
                <Modal
                    isOpen={oProps.modalIsOpen}
                    onRequestClose={closeModal}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,.75)',
                            overflow: 'hidden',
                            height: '100%',
                            zIndex: 100
                        },
                        content: {
                            position: 'absolute',
                            top: '35%',
                            left: '30%',
                            bottom: '35%',
                            height: "40vh",
                            border: 'none',
                            width: "35vw",
                            background: '#fff',
                            borderRadius: '1%',
                            outline: 'none',
                            felxDirection: 'column',
                        }
                    }}
                >
                    <Style>
                        <h2>⚠️주류 판매 고객 신분 확인 의무 고지⚠️</h2>
                        <p style={{ fontWeight: '900', marginBottom: '10px' }}>주류 등록 시 꼭 확인해 주세요!</p>
                        <p>스루는 실수령하는 고객님의 연령을 확인할 수 없습니다.</p>
                        <p>주류 판매 시 성인 확인 의무는 판매자에 있으며,<br /> 미성년자 주류 구매로 발생하는 모든 책임은 판매자에 있습니다.</p>
                        <h3>아래 비밀번호 인증으로 위 고지된 사항을 충분히 인지하고 동의하는 것으로 간주됩니다.</h3>
                        <div className="password">
                            <p>비밀번호 : </p>
                            <input type="password" onChange={onChange} value={sPasswordValue} />
                        </div>
                        <p className='errorMsg'>{eMessage}</p>
                        <div className="fBtn">
                            <button onClick={onSubmit}>주류 등록하기</button>
                            <button onClick={cancelModal}>등록 취소 하기</button>
                        </div>
                    </Style>
                </Modal>
            </BrowserView>
            <MobileView>
                <Modal
                    isOpen={oProps.modalIsOpen}
                    onRequestClose={closeModal}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,.75)',
                            overflow: 'hidden',
                            height: '100%',
                            zIndex: 100
                        },
                        content: {
                            position: 'absolute',
                            top: '13%',
                            left: '2.5%',
                            bottom: '5%',
                            minHeight: 'auto',
                            maxHeight: "70vh",
                            border: 'none',
                            width: "95vw",
                            background: '#fff',
                            borderRadius: '1%',
                            outline: 'none',
                            felxDirection: 'column',
                        }
                    }}
                >
                    <Style>
                        <h2>⚠️주류 판매 고객 신분 확인 의무 고지⚠️</h2>
                        <p style={{ fontWeight: '900', marginBottom: '10px' }}>주류 등록 시 꼭 확인해 주세요!</p>
                        <p>스루는 실수령하는 고객님의 연령을 확인할 수 없습니다.</p>
                        <p>주류 판매 시 성인 확인 의무는 판매자에 있으며,<br /> 미성년자 주류 구매로 발생하는 모든 책임은 판매자에 있습니다.</p>
                        <h3>아래 비밀번호 인증으로 위 고지된 사항을 충분히 인지하고 동의하는 것으로 간주됩니다.</h3>
                        <div className="password">
                            <p>비밀번호 : </p>
                            <input type="password" onChange={onChange} value={sPasswordValue} />
                        </div>
                        <p className='errorMsg'>{eMessage}</p>
                        <div className="fBtn">
                            <button onClick={onSubmit}>주류 등록하기</button>
                            <button onClick={cancelModal}>등록 취소 하기</button>
                        </div>
                    </Style>
                </Modal>
            </MobileView>
        </Fragment>

    )
};
export default AlcoholModal;

const Style = styled.div`
    h2 {
        margin: 10px 0 20px 0;
        font-size: 16px;
        font-weight: 800;
        color: red;
    }
    h3 {
        margin: 20px 0;
        font-size: 15px;
        font-weight: 900;
    }
    p {
        margin:0;
        font-size: 16px;
        font-weight: 700;
    }
    .password {
        margin - bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        input {
            min - width: 200px;
            margin-left: 20px;
            border: none;
            border-bottom: 1px solid #000;
        }
    }
    .errorMsg {
        font-size: 14px;
        color: #DD1212;
    }
    .fBtn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 10px;

        button {
            margin-top: 20px;
            margin-left: 5px;
            padding: 10px 15px;
            border-radius: 4px;
            font-weight: 700;
        }
        button:nth-child(1) {
            width: 50%;
            background: #6490E7;
            border: none;
            outline: none;
            color: #fff;
        }
        button:nth-child(2){
            color: #DD1212;
            border: 1px solid #DD1212;
            background: none;
        }
    }

`
