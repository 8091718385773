import React, { useState, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import Loader from "react-loader-spinner";

import Category from './Category';
import Product from './Product';
import Option from './Option';
import stopIcon from '../../../assets/img/stopIcon.png';

const Main = ({ oProps }) => {
   const [iStatus, setStatus] = useState(1);
   const [sCategory, setCategory] = useState("상품분류");
   const [oSharedMenuData, set_oSharedMenuData] = useState('');

   const [sList] = useState([
      { id: 1, name: "상품분류", value: "menu" },
      { id: 2, name: "상품관리", value: "product" },
      { id: 4, name: "옵션관리", value: "option" },
   ]);

   const moveCategory = async (aIndex, sIndex) => {
      setStatus(aIndex);
      setCategory(sIndex);
   }

   const checkForSharedMenu = async () => {
      const store_id = oProps.UserConfigReducer.StoreID;
      const oResponse = await oProps.oManager.fDoAxios("/store/menu/checkShared", "post", null, { store_id: store_id });
      if (oResponse !== undefined) {
         if (oResponse.has_shared_menu !== undefined && oResponse.has_shared_menu === 1) {
            set_oSharedMenuData(oResponse);
         }
      }
   }

   useEffect(() => {
      checkForSharedMenu();

      setTimeout(() => {
         window.location.replace('https://ceo.throo.co.kr');
      }, 500);
   }, []);


   return (
      <Style>
         <div className='renewalPage'>
            <img src={stopIcon} alt="페이지 준비중 아이콘" width={280} />
            <h2><strong>페이지 이동중입니다...</strong></h2>
            {/*
            <p>이용에 불편을 드려 죄송합니다.</p>
            <p>보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.</p>
            <p>빠른시일내에 준비하여 찾아뵙겠습니다.</p>
            */}
         </div>
      </Style>
   )

   return (
      <Style>
         <div className="slaesMobilePage">
            {oSharedMenuData !== '' && oSharedMenuData.has_shared_menu !== undefined &&
               <div className="tapPage">
                  <div className="menu_container" style={{ height: '12vh' }}>
                     <p style={{ fontSize: 14, color: 'red', paddingLeft: 16, paddingRight: 16, paddingTop: 10 }}>공유 메뉴 사용하고 있습니다. 메뉴를 수정이 필요하시면 마스터 메뉴 수정하십시오. <span style={{ fontSize: 14, color: 'red', paddingTop: 4, fontWeight: 600 }}><br />연결된 마스터 메뉴 매장명 : {oSharedMenuData.store_name}</span>
                     </p>
                  </div>
               </div>
            }
            {oSharedMenuData === '' &&
               <>
                  <div className="tapPage">
                     <div className="menu_container">
                        <div className="tab">
                           <ScrollContainer style={{ width: "100%" }}>
                              {sList.map((item, index) => {
                                 return (
                                    <div className={item.name === sCategory ? "scroll_menu_active" : "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.id, item.name)}>
                                       {item.name}
                                    </div>
                                 )
                              })}
                           </ScrollContainer>
                        </div>
                     </div>
                  </div>
                  {parseInt(iStatus) === 0 &&
                     <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                     </div>
                  }
                  {parseInt(iStatus) === 1 &&
                     <Category oProps={oProps} />
                  }
                  {parseInt(iStatus) === 2 &&
                     <Product oProps={oProps} />
                  }
                  {parseInt(iStatus) === 4 &&
                     <Option oProps={oProps} />
                  }
               </>
            }
         </div>
      </Style>
   )
}

export default Main;

const Style = styled.div`
   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Noto Sans KR', sans-serif;
      color: #000;
   }  
   
.renewalPage {
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   h2 {
      margin: 10px 0 40px 0;
      font-size: 40px;
   }
   p {
      font-size: 20px;
      color: #888;
   }
}

   .slaesMobilePage {
      background-color: #f1f1f1;
   }

   .tapPage {
      background-color: #F1F3F7;
      padding-bottom: 8px;
   }

   .menu_container {
      background: #fff;
      height: 10vh;

      .tab {
         display: flex;
         align-items: center;
         height: 10vh;
         margin-bottom: 24px;
         background-color: #fff;
         border-radius: 4px;
         overflow: auto;
         white-space: nowrap;
      }

      .scroll_menu {
         display: inline-block;
         text-align: center;
         text-decoration: none;
         margin: 5%;
         cursor: pointer;
         *display:inline;
         *zoom:1;
         vertical-align:top;
         font-size: 0.9rem;
         font-weight: 400;
         color: #666;
      }

      .scroll_menu_active {
         border-bottom: 1px solid #000;
         display: inline-block;
         text-align: center;
         text-decoration: none;
         margin: 5%;
         cursor: pointer;
         *display:inline;
         *zoom:1;
         vertical-align:top;
         font-size: 0.9rem;
         font-weight: 500;
         color: #000;
      }
   }

   .tab {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
   }
   .tab::-webkit-scrollbar {
      display: none; 
   }

`


