import React, { useState, useEffect, Fragment } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollContainer from "react-indiana-drag-scroll";
import Loader from "react-loader-spinner";
import { message } from "antd";
import SVG from "react-inlinesvg";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import "antd/dist/antd.css";

import {
  Style,
  MobileStyle,
} from "../../../../assets/styledComponents/InceptionStyle";

import throoKakao from "../../../../assets/svg/throoKakao.svg";
// import kbButton from "../../../../assets/svg/kbButton.svg";
import kbButton from "../../../../assets/img/kbButton.png";
import pos_print from "../../../../assets/img/pos_print.png";
import pos_img from "../../../../assets/img/pos_img.png";

import SalesStatus from "../SalesStatus";
import NoticeList from "../Notice";
import Business from "../Business";
import Product from "../Product";
import Store from "../Store";
import { useNavigate } from "react-router-dom";
import { WebRoute } from "../../../../navigation/WebRoutes";

const Dashboard = ({ initialLoading, sProps, fnMoveCategory, sInitialize }) => {
  const [merchantActive, setMerchantActive] = useState(false);
  const [productActive, setProductActive] = useState(false);
  const [storeActive, setStoreActive] = useState(false);
  const navigate = useNavigate();
  const [salesData, setSalesData] = useState({});
  const [noticeList, setNoticeList] = useState({});
  const [businessData, setBusinessData] = useState({});
  const [productData, setProductData] = useState({});
  const [storeData, setStoreData] = useState({});
  const [sGuide] = useState([
    {
      param: "pos",
      name: "스루 포스 가이드",
      img: pos_img,
    },
    {
      param: "printer",
      name: "스루 포스 프린터 가이드",
      img: pos_print,
    },
  ]);

  const [salesLoading, setSalesLoading] = useState(true);
  const [noticeLoading, setNoticeLoading] = useState(true);
  const [businessLoading, setBusinessLoading] = useState(true);
  const [productLoading, setProductLoading] = useState(true);
  const [storeLoading, setStoreLoading] = useState(true);
  const [sDashBoardClassName, set_sDashBoardClassName] = useState("dashboard");

  const moveCategory = async (sIndex, aIndex) => {
    navigate(
      sIndex === 0 ? WebRoute.ADMIN_GUIDE_POS : WebRoute.ADMIN_GUIDE_PRINTER
    );
  };

  const initialData = async () => {
    if (sInitialize !== undefined && sInitialize !== null) {
      if (sInitialize.mainData !== undefined && sInitialize.mainData !== null) {
        setSalesLoading(false);
        setSalesData(sInitialize.mainData);
      }
      if (
        sInitialize.merchantData !== undefined &&
        sInitialize.merchantData !== null
      ) {
        setBusinessLoading(false);
        setMerchantActive(sInitialize.merchantData.result);
        setBusinessData(sInitialize.merchantData);
      }
      if (
        sInitialize.noticeData !== undefined &&
        sInitialize.noticeData !== null
      ) {
        setNoticeLoading(false);
        setNoticeList(sInitialize.noticeData.list);
      }
      if (
        sInitialize.productData !== undefined &&
        sInitialize.productData !== null
      ) {
        if (
          sInitialize.productData.list !== undefined &&
          sInitialize.productData.list.length > 0
        ) {
          const config = {
            appendPadding: 10,
            data: sInitialize.productData.list,
            angleField: "value",
            colorField: "type",
            radius: 0.9,
            label: {
              type: "inner",
              offset: "-30%",
              content: function content(_ref) {
                var percent = _ref.percent;
                return "".concat((percent * 100).toFixed(0), "%");
              },
              style: {
                fontSize: 14,
                textAlign: "center",
              },
            },
            interactions: [{ type: "element-active" }],
          };
          setProductData(config);
        }
        setProductLoading(false);
        setProductActive(sInitialize.productData.result);
      }
      if (
        sInitialize.storeData !== undefined &&
        sInitialize.storeData !== null
      ) {
        setStoreLoading(false);
        setStoreActive(sInitialize.storeData.result);
        setStoreData(sInitialize.storeData);
      }
    }
  };

  useEffect(() => {
    initialData();

    let oUserData = sProps.UserConfigReducer;
    if (oUserData && oUserData.isPOSStore === true) {
      set_sDashBoardClassName("dashboard is-pos-store");
    }
  }, [initialLoading]);

  const openCEOKakao = () => {
    if (isMobile) {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    } else {
      window.open(
        "https://pf.kakao.com/_tzxkNK/chat",
        "_blank",
        "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no"
      );
    }
  };
  const openKblife = () => {
    window.open("https://throo.co.kr/kb-life-ceo.html", "_blank");
  };

  return (
    <Fragment>
      <BrowserView>
        <Style>
          <div className="wrapper">
            <Row>
              <Col sm={6}>
                <div className="firstLine">
                  <div className="topContent">
                    {salesLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "30vh",
                          alignItems: "center",
                        }}
                      >
                        <Loader
                          type="BallTriangle"
                          color="#13166b"
                          height={80}
                          width={80}
                          timeout={9000000}
                        />
                      </div>
                    ) : (
                      <SalesStatus sData={salesData} mState={merchantActive} />
                    )}
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={6}>
                    <div className="secondLine">
                      {businessLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "30vh",
                            alignItems: "center",
                          }}
                        >
                          <Loader
                            type="BallTriangle"
                            color="#13166b"
                            height={80}
                            width={80}
                            timeout={9000000}
                          />
                        </div>
                      ) : (
                        <Business
                          fnMove={(sIndex, aIndex) =>
                            moveCategory(sIndex, aIndex)
                          }
                          sData={businessData}
                          iState={merchantActive}
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="secondLine">
                      {noticeLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "30vh",
                            alignItems: "center",
                          }}
                        >
                          <Loader
                            type="BallTriangle"
                            color="#13166b"
                            height={80}
                            width={80}
                            timeout={9000000}
                          />
                        </div>
                      ) : (
                        <NoticeList
                          fnMove={(sIndex) => moveCategory(sIndex)}
                          sData={noticeList}
                          mState={merchantActive}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={6}>
                    <div className="thirdLine">
                      {storeLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "30vh",
                            alignItems: "center",
                          }}
                        >
                          <Loader
                            type="BallTriangle"
                            color="#13166b"
                            height={80}
                            width={80}
                            timeout={9000000}
                          />
                        </div>
                      ) : (
                        <Store
                          fnMove={(sIndex) => moveCategory(sIndex)}
                          sData={storeData}
                          iState={storeActive}
                          pState={productActive}
                          mState={merchantActive}
                        />
                      )}
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="forthLine">
                      {productLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "30vh",
                            alignItems: "center",
                          }}
                        >
                          <Loader
                            type="BallTriangle"
                            color="#13166b"
                            height={80}
                            width={80}
                            timeout={9000000}
                          />
                        </div>
                      ) : (
                        <Product
                          fnMove={(sIndex) => moveCategory(sIndex)}
                          sData={productData}
                          iState={productActive}
                          sState={storeActive}
                          mState={merchantActive}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <div className="lastLine">
                  <div className="guide">
                    <div className="title">
                      <h1>스루 가이드</h1>
                    </div>
                    <div style={{ height: "12vh" }}>
                      <h2 style={{ marginTop: "2%", fontSize: "1.3rem" }}>
                        스루 200% 활용하기
                      </h2>
                      <h3>다양한 사례와 활용 방법을 알려드립니다.</h3>
                    </div>
                    <ScrollContainer
                      className="scroll-container"
                      style={{ display: "flex" }}
                    >
                      {sGuide.map((item, index) => {
                        return (
                          <div
                            key={"notice-listing" + index}
                            className="box"
                            onClick={() => moveCategory(index)}
                          >
                            <div className="imgBox">
                              <img src={item.img} alt="none" />
                            </div>
                            <p>{item.name}</p>
                          </div>
                        );
                      })}
                    </ScrollContainer>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="kakakoCeo">
              <button
                onClick={openKblife}
                style={{
                  marginRight: "10px",
                  width: "260px",
                }}
              >
                <img
                  src={kbButton}
                  alt="kb보험상식체크"
                  width="100%"
                  style={{ marginBottom: "24px" }}
                />
              </button>
              <button onClick={openCEOKakao}>
                <SVG
                  className="throoKakao"
                  src={throoKakao}
                  title="카카오톡상담하기"
                />
              </button>
            </div>
          </div>
        </Style>
      </BrowserView>
      <MobileView>
        <MobileStyle>
          <div className={sDashBoardClassName}>
            {salesLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "30vh",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="BallTriangle"
                  color="#13166b"
                  height={80}
                  width={80}
                  timeout={9000000}
                />
              </div>
            ) : (
              <SalesStatus sData={salesData} />
            )}
            {businessLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "30vh",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="BallTriangle"
                  color="#13166b"
                  height={80}
                  width={80}
                  timeout={9000000}
                />
              </div>
            ) : (
              <Business
                fnMove={(sIndex, aIndex) => moveCategory(sIndex, aIndex)}
                sData={businessData}
                iState={merchantActive}
              />
            )}
            {storeLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "30vh",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="BallTriangle"
                  color="#13166b"
                  height={80}
                  width={80}
                  timeout={9000000}
                />
              </div>
            ) : (
              <Store
                fnMove={(sIndex) => moveCategory(sIndex)}
                sData={storeData}
                iState={storeActive}
                mState={merchantActive}
              />
            )}
            {productLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "30vh",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="BallTriangle"
                  color="#13166b"
                  height={80}
                  width={80}
                  timeout={9000000}
                />
              </div>
            ) : (
              <Product
                fnMove={(sIndex) => moveCategory(sIndex)}
                sData={productData}
                iState={productActive}
                mState={merchantActive}
              />
            )}
            <div className="content5">
              <div className="title">
                <h1>스루 가이드</h1>
              </div>
              <h2>스루 200% 활용하기</h2>
              <h3>다양한 사례와 활용 방법을 알려드립니다.</h3>
              <ScrollContainer
                className="scroll-container"
                style={{ display: "flex" }}
              >
                {sGuide.map((item, index) => {
                  return (
                    <div
                      className="box"
                      key={"guide-listing" + index.toString()}
                      onClick={() => moveCategory(item.param)}
                    >
                      <div className="imgBox">
                        <img src={item.img} alt="none" />
                      </div>
                      <p>스타터 기본 가이드</p>
                    </div>
                  );
                })}
              </ScrollContainer>
            </div>
            {noticeLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "30vh",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="BallTriangle"
                  color="#13166b"
                  height={80}
                  width={80}
                  timeout={9000000}
                />
              </div>
            ) : (
              <NoticeList
                fnMove={(sIndex) => moveCategory(sIndex)}
                sData={noticeList}
              />
            )}
          </div>
        </MobileStyle>
      </MobileView>
    </Fragment>
  );
};
export default Dashboard;
