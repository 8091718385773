import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_subCeoTip from "./mobile/M_subCeoTip";
import D_subCeoTip from "./desktop/D_subCeoTip";

const MainSubCeoTip = (oProps) => {
    const LendingRender = () => {
        if (isMobile) {
            return <M_subCeoTip />
        }

        return <D_subCeoTip />
    }

    return (
        <>
            {LendingRender()}
        </>
    )
}

export default MainSubCeoTip;