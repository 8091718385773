import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Banner = ({ iList }) => {
    const [sList] = useState(iList);

    useEffect(() => {
        window.sliderImage();
    }, [])


    return (
        <BannerStyle>
            <div className="banner">
                <div className="bannerSlide">
                    <ul class="bxslider">
                        {sList.map((item,index) => {
                            return (
                                <li key={index}>
                                    {(item.move_path !== undefined && item.move_path !== null && item.move_path !== "") ?
                                        <>
                                            {(item.param !== undefined && item.param !== null && item.param !== "") ?
                                                <Link to={{ pathname: item.move_path, page_id: item.param, search: item.param }}><img src={item.url_path} width="100%" alt="스루포스 새로운기능 이미지" /></Link>
                                                :
                                                <Link to={item.move_path}><img src={item.url_path} width="100%" alt="스루마켓 배너 이미지" /></Link>
                                            }
                                        </>
                                        :
                                        <img src={item.url_path} width="100%" />
                                    }
                                </li>
                            ) 
                        })}
                    </ul>
                </div>
            </div>
        </BannerStyle>
    )
}

export default Banner;

export const BannerStyle = styled.div`
   * {
        margin:0;
        padding:0;
        box-sizing:border-box; 
        cursor: pointer;
    }
    .banner {
        width: 100%;
        padding: 24px 5vw 16px 5vw;
        position: relative;
        margin: 0 auto;
        max-height: 487px;
    }
    .bannerSlide {
        min-width: 100%;
        min-width: 100%;
        margin-top: 60px;
        
    }
    
    .bx-wrapper {
        border-color: none;
        background:none;
        box-shadow:none;
        border: 0;
        width: 100%;
    }
    .bx-pager.bx-default-pager{
        position: absolute;
        bottom: 7%;

    }
    .bx-wrapper .bx-pager.bx-default-pager a.active {
        /* background: none; */
        background: #fff;
    }
    .bx-wrapper .bx-pager.bx-default-pager a {
        /* background: none; */
    }
    li {
        max-height: 487px;
        overflow: hidden;
    }
    img {
        max-height: 487px;
    }
`; 
