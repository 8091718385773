import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { message, Modal } from 'antd';
import "antd/dist/antd.css";

import searchBlack from '../../../../assets/svg/search_black.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';

import { ModalFrontPage, ModalBackPage, GuidePickUpInfo, GuidePickUpZone } from '../../../modal/AppModal';

import SettingInfo from './SettingInfo';
import PickUpZone from './PickUpZone';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';

const Category = ({ oProps }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [sData, setData] = useState({});
    const [iData, setIData] = useState({});

    const [modal, contextHolder] = Modal.useModal();

    const modalOpen = {
        content: (
            <ModalFrontPage sProps={oProps} sType={"info"} />
        )
    };

    const modalOpen2 = {
        content: (
            <ModalBackPage sProps={oProps} />
        )
    };

    const modalGuide1 = {
        width: "50vw",
        content: (
            <GuidePickUpInfo />
        )
    };

    const modalGuide2 = {
        width: "50vw",
        content: (
            <GuidePickUpZone />
        )
    };

    const insertForm = async (sIndex) => {
        setIsLoading(true);

        const oData = {
            store_id: oProps.UserConfigReducer.StoreID,
            sAddress: sIndex.sAddress,
            sExtraAddress: sIndex.sExtraAddress,
            sPhoneNm: sIndex.sPhoneNm,
            sNotsNearByDistanceValue: sIndex.sNearByDistance,
            sParkingTime: sIndex.sParkingTime,
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/pickup/editPickUpInfo", "post", "login", oData);
        if (oResponse !== undefined) {
            if (!oResponse) {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                message.info("정상적으로 저장이 완료되었습니다.");
            }
        }
        getParam();
    }

    const getParam = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/pickup/getPickUpInfo-" + store_id, "get", "login", null);
        if (oResponse !== undefined && oResponse !== null) {
            const oData = {
                address: oResponse.address,
                extraAddress: oResponse.extraAddress,
                sNm: oResponse.Nm,
                parkingTime: oResponse.parkingTime,
                sNoti: oResponse.sNoti,
            };
            setData(oData);
        }

        const iResponse = await oProps.oManager.fDoAxios("/store/pickup/getPickUpZoneInfo/v2-" + store_id, "get", "login", null);
        if (iResponse !== undefined && iResponse !== null) {
            const oData = {
                lat: iResponse.lat,
                lng: iResponse.lng,
                pointView: iResponse.pointView,
                parkingImg: iResponse.sParkingImg,
            };
            setIData(oData);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getParam();
    }, []);

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.store}>
            <BrowserView>
                <Style>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <>
                            <SettingInfo
                                fnInsertForm={(sIndex) => insertForm(sIndex)}
                                iData={sData}
                            />
                            <PickUpZone
                                sProps={oProps}
                                sData={iData}
                            />
                            <div className="side_help_content">
                                <div className="app_modal_container" onClick={() => modal.success(modalOpen)}>
                                    <div className="arrow_box">
                                        <img src={guide_arrow} alt="none" />
                                    </div>
                                    <div className="text_box">
                                        <div className="title_box">
                                            <img className='help_content_img' src={searchBlack} alt="none" />
                                            <p>앱 미리보기</p>
                                        </div>
                                        <div className="detail_box">
                                            <p>내 입력정보의 예상화면을 <br />미리 확인해보세요</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="app_guide_modal_container" onClick={() => modal.success(modalGuide1)}>
                                    <div className="arrow_box">
                                        <img src={guide_arrow} alt="none" />
                                    </div>
                                    <div className="text_box">
                                        <div className="title_box">
                                            <img className='help_content_img' src={guide_question} alt="none" />
                                            <p>간편도움말</p>
                                        </div>
                                        <div className="detail_box">
                                            <p>입력예시를 통해 <br />입력방법을 알려드립니다.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="side_help_content2">
                                <div className="app_modal_container2" onClick={() => modal.success(modalOpen2)}>
                                    <div className="arrow_box">
                                        <img src={guide_arrow} alt="none" />
                                    </div>
                                    <div className="text_box">
                                        <div className="title_box">
                                            <img className='help_content_img' src={searchBlack} alt="none" />
                                            <p>앱 미리보기</p>
                                        </div>
                                        <div className="detail_box">
                                            <p>내 입력정보의 예상화면을 <br />미리 확인해보세요</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="app_guide_modal_container2" onClick={() => modal.success(modalGuide2)}>
                                    <div className="arrow_box">
                                        <img src={guide_arrow} alt="none" />
                                    </div>
                                    <div className="text_box">
                                        <div className="title_box">
                                            <img className='help_content_img' src={guide_question} alt="none" />
                                            <p>간편도움말</p>
                                        </div>
                                        <div className="detail_box">
                                            <p>입력예시를 통해 <br />입력방법을 알려드립니다.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {contextHolder}
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <>
                            <SettingInfo
                                fnInsertForm={(sIndex) => insertForm(sIndex)}
                                iData={sData}
                            />
                            <PickUpZone
                                sProps={oProps}
                                sData={iData}
                            />
                        </>
                    }
                </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default Category;

const Style = styled.div`
    position: relative;
    width: 42vw;

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  

    /* 앱미리보기/간편도움말 공통css */
    .side_help_content {
        position: absolute;
        left: 103%;
        top: 0;
    }
    .side_help_content2 {
        position: absolute;
        left: 103%;
        top: 61%;
    }
    .app_guide_modal_container, .app_modal_container,.app_guide_modal_container2,.app_modal_container2 {
        padding: 13px 13px 40px 13px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #FBFBFB;
        border: 1px solid #DFDFE3;
        border-radius: 8px;
        
        .arrow_box img {
            width: 5px;
        }
        .text_box {
            margin-left: 5px;
            width: 220px;

            .title_box {
                height: auto;
                margin-bottom: 9px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                }
            }
            .help_content_img {
                width: 18px;
            }
        }
    }

    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        width: 11vw;
        min-height: 52px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .pictures_title {
        height: 80px;

        .location_box_line {
            height: 50px;

            .how_box {
                display: flex;
                align-items: center;
                height: 50px;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
    .pictures_list_title {
        height: 25vh;
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .essential_form {
        font-size: 0.8rem;
        font-weight: 400;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8%;
        height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    

    

    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3vh;
            width: 5vw;;
            border: 1px solid #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 1px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        width: 42vw;
    }


    .select_store_type {
        width: 100%;
        border: 1px solid #BBBBBB;
        border-radius: 4px;
    }

    .selectItemWrapper {
        margin: 15px 0;
        padding: 20px 14px;
        background-color: #F1F3F7;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;

        .ant-radio-group.ant-radio-group-outline.radioButton {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        .ant-radio-wrapper {
            flex: 0 0 25%;
            margin: 3px 0px;
        }
    }

    .category_address_box {
        position: relative;
        margin-bottom: 3%;

        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
        }

        .address {
            position: absolute;
            top: 22%;
            right: 0;

            .search_box {
                width: 72px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        
        .address button {
            top: 14px;
            bottom: 0;
            right: 0;
            border: 0;
            outline: 0;
            left: 90%;
        }
    }

    .category_title {
        position: relative;
        margin-bottom: 3%;

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        input {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        
        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 72px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            position: relative;
            top: 14px;
            bottom: 0;
            right: 0;
            border: 0;
            outline: 0;
            left: 90%;
        }

        textarea {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            min-height: 15vh;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        textarea::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .set_location_box {
            display: flex;
            align-items: center;
            height: 106px;

            .set_location_button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 6vw;
                height: 6vh;
                border-radius: 4px;
                background: #617BE3;
                cursor: pointer;
                p {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }

        .location_box_line {
            height: 90px;
            border-bottom: 1px solid #dfdfdf;
            
            .how_box {
                display: flex;
                align-items: center;
                height: 70px;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }

        .location_box_line2 {
            height: 50px;
            
            .how_box {
                display: flex;
                align-items: center;
                height: 50px;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }


    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }
    
`



const Mstyle = styled.div`

    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 40vw;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.8rem;
        text-align: left;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    .detail_line {
        margin-left: 5px;
        font-size: 0.7rem;
        font-weight: 700;
        color: #617BE3;
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .pickUpZone_box {
        position: relative;
        margin-bottom: 3%;

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        .set_location_box {
            display: flex;
            align-items: center;
            height: 10vh;

            .set_location_button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25vw;
                height: 6vh;
                border-radius: 4px;
                background: #617BE3;
                cursor: pointer;
                p {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }

        .location_box_line {
            height: 13vh;
            border-bottom: 1px solid #dfdfdf;
            
            .how_box {
                display: flex;
                align-items: center;
                height: 10vh;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.6rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table_content {
            width: 60%;
            height: 7vh;

            .title {
                display: flex;
                height: 3.5vh;
                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                }
            }

            .content {
                display: flex;
                height: 3.5vh;

                .main_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 17vw;
                    height: 3vh;
                    background: #001E62;
                    border-radius: 5px;
                    margin-right: 5%;

                    p {
                        font-size: 0.7rem;
                        font-weight: 400;
                        color: #fff;
                    }
                }
                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                }
            }
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;

            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;
            
            .search_box {
                width: 72px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }

        .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 10vh;
            width: 23vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                height: 10vh;
                width: 23vw;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 10vh;
            width: 23vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                height: 5vh;
                width: 10vw;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 6vh;
                width: 20vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 6vh;
                width: 20vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }
    }
    
    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .last {
        width: 100%;
        padding: 24px 0px 32px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  

    

`


