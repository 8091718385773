import React, { useEffect, useLayoutEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { isMobile, isBrowser } from "react-device-detect";
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import AppInstallModal from '../modal/AppInstallModal';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { SignInStyle } from '../../assets/styledComponents/SignInStyle';
import loginLogo from '../../assets/svg/loginlogo.svg';
import kakaoLogin from '../../assets/svg/throoKakao.svg';
import { WebRoute } from '../../navigation/WebRoutes';

const LoginScreen = ({ fModeChange, sProps }) => {
    console.log(sProps.UserConfigReducer)
    const [userId, setUserId] = useState(sProps.UserConfigReducer.UserId || sProps.UserConfigReducer.SalesEmail);
    const [userPwd, setUserPwd] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [idCheck, setIdCheck] = useState(sProps.UserConfigReducer.RememberId);
    const [loginCheck, setLoginCheck] = useState(sProps.UserConfigReducer.AutoLogin);

    const [classNameId, setClassNameId] = useState(null);
    const [classNamePw, setClassNamePw] = useState(null);
    const navigate = useNavigate()
    useEffect(() => {
        if (sProps.UserConfigReducer.LoggedIn) {
            detgoPath(sProps.UserConfigReducer)
        }
        document.addEventListener('message', function WebViewListener({ data }) {
            try {
                const loginData = JSON.parse(data)
                HandleWebViewLogin(loginData)
                document.removeEventListener('message', WebViewListener)
            } catch (err) {
                alert(err)
            }
        })
    }, [])
    const kakaoTalk = () => {
        if (isMobile) {
            window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
        } else {
            window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank', "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
        }
    }

    const inputUserId = (e) => {
        setErrorMessage("");
        setUserId(e.target.value);
    }

    const inputUserPwd = (e) => {
        setErrorMessage("");
        setUserPwd(e.target.value);
    }

    const changeIdCheck = () => {
        setIdCheck(!idCheck);
    }

    const changeLoginCheck = () => {
        setLoginCheck(!loginCheck);
    }

    const inputClassNameId = () => {
        setClassNamePw(null);
        setClassNameId("input_action");
    }

    const inputClassNamePw = () => {
        setClassNameId(null);
        setClassNamePw("input_action");
    }

    const signUp = async () => {
        if (fModeChange !== undefined && typeof fModeChange === "function") {
            await fModeChange("signUp");
        }
    }

    const findMemeber = async (sIndex) => {
        if (fModeChange !== undefined && typeof fModeChange === "function") {
            await fModeChange("findInfo", sIndex);
        }
    }

    const checkUp = async () => {
        if (userId === null || userId === "") {
            setErrorMessage("아이디를 입력하세요");
            return false;
        } else if (userPwd === null || userPwd === "") {
            setErrorMessage("비밀번호를 입력하세요");
            return false;
        }

        await signIn();
    }

    const onKeyAction = e => {
        if (e.key === "Enter") {
            checkUp();
        }
    }
    const detgoPath = async (oUserConfig) => {
        if (oUserConfig.AutoLogin) {
            navigate(WebRoute.ADMIN_SALES_DASHBOARD)
        } else if (oUserConfig.SalesAutoLogin) {
            if (oUserConfig.LoggedIn) {
                navigate(WebRoute.ADMIN_SALES_DASHBOARD)
            } else {
                navigate(WebRoute.ADMIN_STORE_LIST)
            }
        } else if (oUserConfig.SalesLoggedIn) {
            const isBefore = moment().isBefore(oUserConfig.SalesLoginTime)
            if (isBefore) {
                if (oUserConfig.LoggedIn) {
                    navigate(WebRoute.ADMIN_SALES_DASHBOARD)
                } else {
                    navigate(WebRoute.ADMIN_STORE_LIST)
                }
            } else {
                navigate(WebRoute.ADMIN_STORE_LIST)
            }
        } else if (oUserConfig.LoggedIn) {
            navigate(WebRoute.ADMIN_SALES_DASHBOARD)
        }
    }

    const HandleWebViewLogin = async (data) => {
        try {
            // const oResponse = await sProps.oManager.fDoAxios("/app/ceo/autoAuthenticate/v2", "post", null, oData);
            await sProps.reduxSetUserConfig(data);
            detgoPath(data)
        } catch (err) {
            alert(err)
        }
    }

    const signIn = async () => {
        let randomDeviceId = (Math.random() * (10 - 1)) + 1;
        let oData = {
            id: userId,
            password: userPwd,
            deviceuuid: randomDeviceId.toString()
        }

        const oResponse = await sProps.oManager.fDoAxios("/authenticate/v2", "post", null, oData);

        setUserConfig(oResponse)
    }

    const setUserConfig = async (oResponse) => {

        if (oResponse !== undefined && oResponse !== null) {

            let oUserConfig = {};
            const new_date = moment().add(1, "days");
            if (oResponse.resultId === "0000") {
                if (fModeChange !== undefined && typeof fModeChange === "function") {
                    await fModeChange("dashboard");
                }
                if (idCheck) {
                    oUserConfig['RememberId'] = true;
                    oUserConfig['UserId'] = userId;
                } else {
                    oUserConfig['RememberId'] = false;
                }

                if (loginCheck) {
                    oUserConfig['AutoLogin'] = true;
                    oUserConfig['UserId'] = userId;
                    oUserConfig['UserKey'] = oResponse.key;
                } else {
                    oUserConfig['AutoLogin'] = false;
                    oUserConfig['UserKey'] = "";
                }

                oUserConfig['isPOS'] = false;
                oUserConfig['LoggedIn'] = true;
                oUserConfig['Token'] = await oResponse.token;
                oUserConfig['RefreshToken'] = await oResponse.refresh_token;
                oUserConfig['UUID'] = await oResponse.uuid;

                oUserConfig['Commercial'] = await oResponse.commercial;
                oUserConfig['StorePause'] = await oResponse.pause;
                oUserConfig['StoreID'] = await oResponse.store_id;
                oUserConfig['StoreName'] = await oResponse.store_name;
                oUserConfig['StorePhone'] = await oResponse.store_phone_number;
                oUserConfig['StoreOwner'] = await oResponse.store_merc_full_name;
                oUserConfig['Address'] = await oResponse.store_address1;
                oUserConfig['StoreLat'] = await oResponse.store_lat;
                oUserConfig['StoreLng'] = await oResponse.store_lng;

                oUserConfig['accessible'] = await oResponse.status;
                oUserConfig['LoginTime'] = await moment(new_date).format('YYYY-MM-DD');
                console.log(oUserConfig)
                await sProps.reduxSetUserConfig(oUserConfig);
                detgoPath(oUserConfig)
            } else if (oResponse.resultId === "6666") {
                if (fModeChange !== undefined && typeof fModeChange === "function") {
                    await fModeChange("sales");
                }
                if (idCheck) {
                    oUserConfig['RememberId'] = true;
                    oUserConfig['SalesEmail'] = userId;
                } else {
                    oUserConfig['RememberId'] = false;
                    // oUserConfig['SalesEmail'] = "";
                }

                if (loginCheck) {
                    oUserConfig['SalesAutoLogin'] = true;
                    oUserConfig['SalesEmail'] = userId;
                    oUserConfig['SalesKey'] = oResponse.key;
                } else {
                    oUserConfig['SalesAutoLogin'] = false;
                    // oUserConfig['SalesEmail'] = "";
                    oUserConfig['SalesKey'] = "";
                }

                oUserConfig['isPOS'] = false;
                oUserConfig['SalesLoggedIn'] = true;
                oUserConfig['SalesId'] = oResponse.salesId;
                oUserConfig['SalesGroupId'] = oResponse.salesGroup;
                oUserConfig['SalesUserType'] = oResponse.key;
                oUserConfig['SalesUserName'] = oResponse.salesName;
                oUserConfig['SalesLoginTime'] = await moment(new_date).format('YYYY-MM-DD');
                oUserConfig['Token'] = await oResponse.token;
                oUserConfig['RefreshToken'] = await oResponse.refresh_token;
                oUserConfig['SalesToken'] = await oResponse.token;
                oUserConfig['SalesRefreshToken'] = await oResponse.refresh_token;

                await sProps.reduxSetUserConfig(oUserConfig);
                detgoPath(oUserConfig)
            } else {
                setErrorMessage(oResponse.resultMsg);
            }
        } else {
            setErrorMessage("네트워크 에러입니다 나중에 다시 시도바랍니다.");
        }
    }

    return (
        <SignInStyle>
            {/* {isMobile && <AppInstallModal />} */}
            <div className="loginPage">
                <div className="loginBox">
                    <SVG src={loginLogo} width="54px" height="18px" title="로그인페이지로고" />
                    <h2>사장님 로그인</h2>
                    <div>
                        <input
                            type="id"
                            value={userId}
                            onChange={inputUserId}
                            placeholder="아이디"
                            className={classNameId}
                            onClick={inputClassNameId}
                            onKeyPress={onKeyAction}
                        />
                        <input
                            type="password"
                            value={userPwd}
                            onChange={inputUserPwd}
                            placeholder="비밀번호"
                            className={classNamePw}
                            onClick={inputClassNamePw}
                            onKeyPress={onKeyAction}
                        />
                        <p style={{ fontSize: "10px", fontWeight: "400", lineHeight: "15px", color: "#dd1212" }}>{errorMessage}</p>
                    </div>
                    <div className="checkbox_wrapper">
                        <div className="saveId">
                            <input
                                type="checkbox"
                                width="18px"
                                height="18px"
                                className="checkInput"
                                id="chk1"
                                checked={idCheck} onChange={changeIdCheck} onClick={changeIdCheck}
                            />
                            <label htmlFor="chk1" style={{ fontSize: "0.8rem" }}>아이디저장</label>
                        </div>
                        <div className="saveId">
                            <input
                                type="checkbox"
                                width="18px"
                                height="18px"
                                className="checkInput"
                                id="chk2"
                                checked={loginCheck} onChange={changeLoginCheck} onClick={changeLoginCheck}
                            />
                            <label htmlFor="chk2" style={{ fontSize: "0.8rem" }}>항상 로그인 유지</label>
                        </div>
                    </div>
                    <button className="loginButton" onClick={checkUp}>로그인</button>
                    <div className="findMember">
                        <Link to={WebRoute.ADMIN_FIND_ID}>
                            <p onClick={() => findMemeber("id")}><span className="chk_signup">아이디 찾기</span></p>
                        </Link>
                        <Link to={WebRoute.ADMIN_FIND_PW}>
                            <p onClick={() => findMemeber("pw")}><span className="chk_signup">비밀번호 찾기</span></p>
                        </Link>
                        <Link to={WebRoute.ADMIN_SIGNUP}>
                            <p onClick={signUp}><span className="chk_signup">매장등록하기</span></p>
                        </Link>
                    </div>
                </div>
                <button className="kakao">
                    <SVG src={kakaoLogin} onClick={kakaoTalk} title="카카오톡 상담하기" />
                </button>
            </div>
        </SignInStyle>
    )

}

export default LoginScreen;
