import React, { useState, useEffect, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { message, Modal, Pagination, Table, DatePicker } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import manual from '../../../../assets/svg/manual.svg';
import addressSearch from '../../../../assets/svg/addressSearch.svg';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';
const defaultFormat = "YYYY/MM/DD";

const { RangePicker } = DatePicker;

const CommercialPaymentList = ({ oProps }) => {
    const [iLoading, setILoading] = useState(true);

    const [payList, setPayList] = useState([]);

    const [fromDate, setFromDate] = useState(moment().add(-30, 'days').format(defaultFormat));
    const [toDate, setToDate] = useState(moment().add(1, 'days').format(defaultFormat));

    const asyncData = async () => {
        setILoading(true);
        let oData = {
            store_id: oProps.UserConfigReducer.StoreID,
            fromData: fromDate,
            toData: toDate
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/commercial/paymentList", "post", "login", oData);
        if (oResponse !== undefined) {
            setPayList(oResponse);
        }
        setILoading(false);
    }

    const onOperatingDate = (value, dateString) => {
        if (dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== "") {
            if (fromDate === moment().format(defaultFormat)) {
                if (dateString[1] !== undefined && dateString[1] !== null && dateString[1] !== "") {
                    setFromDate(dateString[0]);
                    setToDate(dateString[1]);
                } else {
                    setFromDate(dateString[0]);
                }
            } else {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
            }
        } else {
            setFromDate(moment().format(defaultFormat));
            setToDate(moment().add(1, 'days').format(defaultFormat));
        }
    }

    useEffect(() => {
        asyncData();
    }, [])
    return (
        <Fragment>
            <Layout sProps={oProps} routeList={layoutHeaderLists.commercial}>
                <BrowserView>
                    <Style>
                        {iLoading ?
                            <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                            :
                            <div className="container">
                                <div className="title_box">
                                    <h2>결제내역</h2>
                                    <div className="tip">
                                        <SVG src={manual} width="18px" height="18px" />
                                        <span>광고를 이용한 내역이나 결제내역<br />확인이 가능합니다.</span>
                                    </div>
                                </div>
                                <div className="searching_line">
                                    <div className="search_date">
                                        <RangePicker
                                            bordered={false}
                                            className="date_picker"
                                            style={{ marginLeft: "2%", fontSize: "0rem", width: "100%" }}
                                            format={defaultFormat}
                                            value={[moment(fromDate, defaultFormat), moment(toDate, defaultFormat)]}
                                            onChange={onOperatingDate}
                                        />
                                    </div>
                                    <div className="search_box" onClick={asyncData}>
                                        <img src={addressSearch} alt="none" />
                                        <p>조회</p>
                                    </div>
                                </div>
                                <div className="wrapper_chart">
                                    <Table columns={iCategoryColumns} dataSource={payList} pagination={false} />
                                </div>
                            </div>
                        }
                    </Style>
                </BrowserView>
                <MobileView>
                    <Mstyle>
                        {iLoading ?
                            <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                            :
                            <>
                                <div className="payment_title_line">
                                    <div className="payment_title">
                                        <h2>결제내역</h2>
                                        <div className="tip">
                                            <SVG src={manual} width="18px" height="18px" />
                                            <span>광고를 이용한 내역이나 결제내역<br />확인이 가능합니다.</span>
                                        </div>
                                    </div>
                                    <div className="middle_line">
                                        <RangePicker
                                            bordered={false}
                                            style={{ marginLeft: "5%" }}
                                            format={defaultFormat}
                                            value={[moment(fromDate, defaultFormat), moment(toDate, defaultFormat)]}
                                            onChange={onOperatingDate}
                                        />
                                    </div>
                                    <div className="last_line">
                                        <img src={addressSearch} />
                                        <p>조회</p>
                                    </div>
                                </div>
                                {payList.length > 0 &&
                                    <>
                                        {payList.map((item, index) => {
                                            return (
                                                <div className="table_box" key={"listing-" + index.toString()}>
                                                    <div className="box_line">
                                                        <p>날짜</p>
                                                        <p>{item.date}</p>
                                                    </div>
                                                    <div className="box_line">
                                                        <p>무상포인트</p>
                                                        <p>{item.freeAmount}</p>
                                                    </div>
                                                    <div className="box_line">
                                                        <p>유상포인트</p>
                                                        <p>{item.chargedAmount}</p>
                                                    </div>
                                                    <div className="box_line">
                                                        <p>광고금액</p>
                                                        <p>{item.cartAmount}</p>
                                                    </div>
                                                    <div className="box_line">
                                                        <p>결제금액</p>
                                                        <p>{item.payment}</p>
                                                    </div>
                                                    <div className="box_line">
                                                        <p>내용</p>
                                                        <p>{item.type}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                }
                            </>
                        }
                    </Mstyle>

                </MobileView></Layout>
        </Fragment>
    )
}

export default CommercialPaymentList;

const iCategoryColumns = [
    {
        title: "날짜",
        key: "date",
        dataIndex: "date",
    },
    {
        title: "무상 포인트",
        key: "freeAmount",
        dataIndex: "freeAmount",
    },
    {
        title: "유상포인트",
        key: "chargedAmount",
        dataIndex: "chargedAmount",
    },
    {
        title: "광고금액",
        dataIndex: "cartAmount",
        key: "cartAmount"
    },
    {
        title: "결제금액",
        dataIndex: "payment",
        key: "payment"
    },
    {
        title: "내용",
        dataIndex: "type",
        key: "type"
    }
];

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  

    .detail_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4vw;
        height: 4vh;
        background:  #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #fff;
        }
    }

    .wrapper_chart {
        margin-top: 5%;
    }

    .title_box {
        display: flex;
        align-items: center;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 10vw;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }
    }

    .container {
        width: 42vw; 
        padding: 32px;
        margin-bottom: 16px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;


    }

    
    .last_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 8vh;
        padding-bottom: 1%;
        border-bottom: 1px solid #dfdfdf;

        
        .selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 5vh;
            background : #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #fff;
            }
            
        }

        .unselected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #617BE3;
            }
        }

        .unselected_box:hover {
            background : #617BE3;

            p {
                color: #fff;
            }
        }

    }

    .searching_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;

        .search_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 2%;
            width: 20%;
            height: 5vh;
            background : #617BE3;
            border-radius: 5px;
            cursor: pointer;

            p {
                margin-left: 5%;
                font-size: 0.9rem;
                color: #fff;
            }
        }
    } 

    .search_bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        min-height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            color: #fff;
            text-align: center;
        }
        svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }

    .search_selectOption {
        display: flex;
        align-items: center;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
        font-size: 0.8rem;
    }

    .search_date {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 100%;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .data_box {
        margin-top: 2%;
    }

    .nodata_box {
        margin-top: 2%;
        height: 50vh;
    }
    
`



const Mstyle = styled.div`
    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .payment_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .payment_title_line {
        padding: 20px 16px;
        margin-bottom: 1vh;
        background-color: #fff;

        h2 {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .middle_line {
        margin-top: 7%;
        display: flex;
        align-items: center;
        height: 6vh;
        border : 1px solid #000;
        border-radius: 5px;
        font-size: 0.8rem;
        margin-bottom: 5%;
    }

    .last_line {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 6vh;
        border-radius: 5px;
        background-color: #617BE3;
        

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            margin-left: 3%;
            color: #fff; 
        }
    }

    .table_box { 
        background: #fff;
        padding-top: 1vh;
        padding-bottom: 1vh;
        border-bottom: 1px solid #dfdfdf;

        .box_line {
            margin: 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 0.8rem;
                line-height: 24px;
                color: #000;
            }
        }
    }
`;