import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { isMobile } from "react-device-detect";
import '../../../../assets/css/font.css';
import { Modal } from 'antd';

// SVG
import joinIcon01 from "../../../../assets/img/joinIcon01.png";
import joinIcon02 from "../../../../assets/img/joinIcon02.png";
import kakaoBtn from "../../../../assets/svg/kakaoBtn.svg";


const D_joinPage = (oProps) => {
   const [storeName, setStoreName] = useState("");
   const [sEmail, setEmail] = useState("");
   const [sPhoneNm, setPhoneNm] = useState("");
   const [sText, setText] = useState("");

   const [bChecked, setChecked] = useState(false);
   const [channel, set_channel] = useState();

   const [storeError, setStoreError] = useState("");
   const [phoneError, setPhoneError] = useState("");
   const [emailError, setEmailError] = useState("");
   const [checkError, setCheckError] = useState("");
   const [channelError, setChannelError] = useState("");
   const [channelText, set_channelText] = useState("");

   // input 에러
   const [classNameId, setClassNameId] = useState(null);
   const [classNamePhone, setClassNamePhone] = useState(null);
   const [classNameEmail, setClassNameEmail] = useState(null);

   const inputStoreName = (e) => {
      let sValue = e.target.value;
      setStoreName(sValue);
      setStoreError("");

      if (sValue != '') {
         setClassNameId("input");
         setClassNameEmail("input");
      }

   }

   const inputPhoneNumber = (e) => {
      const { value } = e.target;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
         setPhoneNm(e.target.value);
      }
      setPhoneError("");

      setClassNamePhone("input");
   }

   const inputEmail = (e) => {
      setEmail(e.target.value);
      setEmailError("");
      setClassNameEmail("input");
   }

   const inputContents = (e) => {
      setText(e.target.value);
   }

   const inputChecked = (e) => {
      e.target.checked ? setCheckError("") : setCheckError("개인정보처리방침에 동의해주세요.");
      setChecked(e.target.checked);
   }

   // 방문경로
   const handleChange = (e) => {
      e.target.checked ? setChannelError("") : setChannelError("방문경로를 선택해주세요.");
      set_channel(e.target.value);
   }

   const onChannelText = (e) => {
      channel === "기타" && channelText === "" ? setChannelError("스루방문경로를 직접 입력해주세요."):setChannelError("");
      set_channelText(e.target.value);
   };

   const onFinish = () => {
      if (storeName === "") {
         setClassNameId("input_wrong");
         setStoreError("매장명을 입력해주세요");
         return true;
      }
      if (sPhoneNm === "") {
         setClassNamePhone("input_wrong");
         setPhoneError("전화번호를 입력해주세요");
         return false;
      }
      if (sEmail === "") {
         setClassNameEmail("input_wrong");
         setEmailError("이메일을 입력해주세요");
         return false;
      }
      if (channel === "" || channel === undefined) {
         setChannelError("방문경로를 선택해주세요.");
         return "";
      }
      if (channel === "기타" && channelText === "") {
         setChannelError("스루방문경로를 직접 입력해주세요.");
         return false;
      }
      if (bChecked === false) {
         setCheckError("개인정보처리방침에 동의해주세요.");
         return false;
      }

      insertInquiry();
   }

   const openNotificationWithIcon = (sIndex, aIndex) => {
      if (sIndex === "success") {
         Modal.success({
            content: aIndex,
         });
      } else {
         Modal.error({
            content: aIndex,
         });
      }
   }

   const insertInquiry = async () => {
      const oData = {
         storeName,
         sPhoneNm,
         sEmail,
         sText,
         channel,
         channelText
      }

      const oResponse = await oProps.oManager.fDoAxios("/home/writingToInquire", "post", null, oData);
      if (oResponse !== undefined) {
         if (oResponse) {
            openNotificationWithIcon('success', "감사합니다, 빠른 시일안에 연락드리겠습니다");
            setStoreName("");
            setPhoneNm("");
            setEmail("");
            setChecked(false);
         } else {
            openNotificationWithIcon('warning', "네트워크에러입니다, 잠시 후 다시 시도바랍니다.");
         }
      }
   }

   // 링크
   const openThrooCEO = () => {
      let sDomainUrl = "https://ceo.throo.co.kr";
      if (process.env !== undefined && process.env.REACT_APP_CEO_WEBURL !== undefined) {
         sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
      }
      window.open(sDomainUrl + '/selfmanage/signup', '_blank');
   }
   const openCEOKakao = () => {
      if (isMobile) {
         window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
      } else {
         window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
      }
   }


   return (
      <D_joinForm>
         <div className="joinForm">
            <div className="container">
               <h1>입점신청 및 문의</h1>
               <div className="jContent">
                  <div className="title">
                     <div className="left">
                        <h3>방법 1</h3>
                        <h2>셀프 입점 신청하기</h2>
                     </div>
                     <div className="right">
                        <img src={joinIcon01} width="72px" height="72px" title="입점신청 이미지" />
                     </div>
                  </div>
                  <p>스루를 이용하기로 결정하셨다면, 상담 없이 직접 설치 가능해요.</p>
                  <button onClick={openThrooCEO}>매장 셀프 등록하기</button>
               </div>
               <div className="jContent">
                  <div className="title">
                     <div className="left">
                        <h3>방법 2</h3>
                        <h2>입점 문의</h2>
                     </div>
                     <div className="right">
                        <img src={joinIcon02} width="72px" height="72px" title="말풍선 이미지" />
                     </div>
                  </div>
                  <p>상담을 먼저 받아보시고 진행하실 경우, 입점 문의를 남겨주세요.</p>
                  <div className="inputContent">
                     <div className="formContent">
                        <input type="text" className={classNameId} placeholder="매장명 (브랜드명+지점)" value={storeName} onChange={inputStoreName} />
                     </div>
                     <p className="errorText">{storeError}</p>
                     <div className="formContent">
                        <input type="text" className={classNamePhone} placeholder="휴대폰 번호" value={sPhoneNm} onChange={inputPhoneNumber}
                           maxLength={11} />
                     </div>
                     <p className="errorText">{phoneError}</p>
                     <div className="formContent">
                        <input type="email" className={classNameEmail} placeholder="이메일" value={sEmail} onChange={inputEmail} />
                     </div>
                     <p className="errorText">{emailError}</p>
                     <div className="formContentLast">
                        <textarea type="text" placeholder="문의 내용" value={sText} onChange={inputContents} />
                     </div>
                     <div className="funnel">
                        <h2>방문경로<span> (필수)</span></h2>
                        <form className="channelContent">
                           <div className="channel">
                              <input type="radio" value="홈페이지" id="channel01" onChange={handleChange} name="channel" style={{ width: "18px", height: "18px" }} />
                              <label for="channel01"><p>홈페이지</p></label>
                           </div>
                           <div className="channel">
                              <input type="radio" value="유튜브(장사 권프로)" id="channel02" onChange={handleChange} name="channel" style={{ width: "18px", height: "18px" }} />
                              <label for="channel02"><p>유튜브(장사 권프로)</p></label>
                           </div>
                           <div className="channel">
                              <input type="radio" value="유튜브(위라클)" id="channel03" onChange={handleChange} name="channel" style={{ width: "18px", height: "18px" }} />
                              <label for="channel03"><p>유튜브(위라클)</p></label>
                           </div>
                           <div className="channel">
                              <input type="radio" value="네이버" id="channel04" onChange={handleChange} name="channel" style={{ width: "18px", height: "18px" }} />
                              <label for="channel04"><p>네이버</p></label>
                           </div>
                           <div className="channel">
                              <input type="radio" value="우편" id="channel05" onChange={handleChange} name="channel" style={{ width: "18px", height: "18px" }} />
                              <label for="channel05"><p>우편</p></label>
                           </div>
                           <div className="channel">
                              <input type="radio" value="기타" id="channel06" onChange={handleChange} name="channel" style={{ width: "18px", height: "18px" }} />
                              <label for="channel06"><p>기타</p></label>
                              <input className='textInput' type="text" onChange={onChannelText} value={channelText} />
                           </div>
                        </form>
                        <p className="errorText" style={{ color: "#F45452", fontSize: "14px", fontWeight: "700", paddingLeft:"0" }}>{channelError}</p>
                     </div>

                     <div className="btn">
                        <div className="privacy">
                           <input type="checkbox" checked={bChecked} onChange={inputChecked} style={{ width: "20px", height: "20px", borderRadius: "6px" }} />
                           <div className="text">
                              <h3>개인정보 처리방침 동의<span> (필수)</span></h3>
                              <p>상담을 위한 연락처 정보로 활용되며, 이후 폐기됩니다.</p>
                           </div>
                        </div>
                        <p className="errorText" style={{ color: "#F45452", fontSize: "14px", fontWeight: "700", marginLeft: "33px" }}>{checkError}</p>
                        <button type="submit" onClick={onFinish}>문의하기</button>
                     </div>
                  </div>
               </div>
            </div>
            <div onClick={openCEOKakao} className="app" style={{ position: "fixed", bottom: 0, right: 0 }}>
               <img src={kakaoBtn} alt="카카오톡상담하기" />
               <h2>카톡문의</h2>
            </div>
         </div>
      </D_joinForm>
   )
}

export default D_joinPage;

export const D_joinForm = styled.aside`

    /* reset */
    ul,ol,li {
        text-decoration: none;
        list-style: none;
    }
    p, a, h1, h2, h3, h4, h5, h6, button, textarea {
        transform: skew(-0.1deg);
        color: #000;
        font-family: 'NanumSquare';
    }

    html,
    body {
        display: block;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .joinForm {
        background-color: #F8F9FA;
        padding: 150px 0 180px 0;
    }
    .container {
        width: 700px;
        padding: 0 22px;
    }
    
    h1 {
        margin-bottom: 72px;
        text-align: center;
        font-size: 54px;
        font-weight: 900;
        color: #212329;
    }

    .jContent {
        padding: 20px;
        background-color: #fff;
        border: 1px solid #ECEEF2;
        border-radius: 4px;
        margin-bottom: 20px;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                h3 {
                    padding: 6px 12px;
                    background-color: #212634;
                    max-width: 60px;
                    font-size: 12px;
                    font-weight: 700;
                    color: #fff;
                    text-align: center;
                    border-radius: 40px;
                }
                h2 {
                    margin:20px 0 10px 0;
                    font-size: 22px;
                    font-weight: 900; 
                }
            }
        }
        
        p {
            margin: 10px 0 40px 0;
            font-size: 16px;
            font-weight: 700;
        }
        button {
            padding: 12px 0;
            width: 100%;
            background-color: #001E62;
            border: none;
            color: #fff;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 900;
            outline: none;
        }
    }
    .inputContent {
        p {
            margin:0;
            padding: 6px;
            font-size: 14px;
        }
        .formContent {
            input {
                width: 100%;
                padding: 12px 13px;
                border: 1px solid #ECEEF2;
                border-radius: 6px;
                outline: none;
                font-size: 16px;
                font-weight: 700;
                color: #888;
            }
        }
        .formContentLast {
            textarea {
                width: 100%;
                padding: 12px 13px;
                border: 1px solid #ECEEF2;
                border-radius: 6px;
                min-height: 142px;
                outline: none;
                font-size: 16px;
                font-weight: 700;
                color: #888;
            }
        }
    }
    /* 방문경로 */
    .funnel {
      margin-top: 15px;
      h2 {
         font-size: 16px;
         font-weight: 700;
         color: #000;
      }
      .channelContent {
         display: flex;
         align-items: center;
         justify-content: flex-start;
         flex-wrap: wrap;
      }
      .channel {
         display: flex;
         align-items: center;
         justify-content: flex-start;
         margin-right: 20px;
         flex: 0 0 30%;

         label {
            margin-bottom: 0;
         }
         p {
            margin-right: 5px;
            font-size: 15px;
            font-weight: 700;
            color: #686868;
         }
         .textInput {
            min-width: 120px;
            max-width: 120px;
            border: none;
            outline: none;
            border-bottom: 1px solid #000;
         }
      }
    }
    /* 개인정보 처리방침 동의 */
    .btn {
        width: 100%;
        padding: 0;
        margin-top: 15px;

        .privacy {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            input {
                margin-right: 12px;
            }
        }
        h3 {
            margin-bottom: 4px;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
        }
        p {
            padding: 0;
            font-size: 14px;
            font-weight: 700;
            color: #888;
            text-align: left;
        }
        button {
            margin-top: 32px;
            outline: none;
        }
    }

    .input_wrong {
        border: 1px solid #F45452 !important;
        background-color: #FEF5F5;
    }
    .input {
        border: 1px solid #ECEEF2 !important;
        background-color: #fff;
        color: #888888;
    }
    .errorText {
        font-size: 12px;
        font-weight: 400;
        color: #F45452;
    }

    /* 카카오톡 문의 */
    .app {
        margin: 0 40px 34px 0;
        padding: 11px 21px;
        border-radius: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FBD300;
        box-shadow: 0px 5px 10px 0px rgba(0,0,0, 0.25);
        cursor: pointer;

        h2 {
            margin: 0;
            font-size: 22px;
            font-weight: 900;
        }
    }
`