import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import '../../../assets/css/font.css';

import arrow_right from '../../../assets/svg/arrow_right.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { WebRoute } from '../../../navigation/WebRoutes';

const Side = ({ fnMoveCategory, iStatus, sProps, iStoreUnReviewCount }) => {
    const [status, setStatus] = useState("dashboard");
    const location = useLocation()
    const moveCategory = async (sIndex) => {
    }
    // 로그아웃
    const navigate = useNavigate()
    const goLogOut = async () => {
        let oUserConfig = {};

        oUserConfig['isPOS'] = false;
        oUserConfig['AutoLogin'] = false;
        oUserConfig['LoggedIn'] = false;
        oUserConfig['Token'] = "";
        oUserConfig['RefreshToken'] = "";
        oUserConfig['UUID'] = "";

        oUserConfig['UserId'] = "";
        oUserConfig['UserKey'] = "";

        oUserConfig['StoreID'] = 0;
        oUserConfig['StoreName'] = "";
        oUserConfig['StorePhone'] = "";
        oUserConfig['StoreOwner'] = "";
        oUserConfig['Address'] = "";
        oUserConfig['StoreLat'] = "";
        oUserConfig['StoreLng'] = "";

        oUserConfig['accessible'] = "inaccessible";
        oUserConfig['LoginTime'] = "";

        await sProps.reduxSetUserConfig(oUserConfig);
        navigate(WebRoute.ADMIN_SIGNIN)
    }

    useEffect(() => {
        setStatus(iStatus);
    }, [iStatus]);

    return (
        <SideStyle>
            <div className="side">
                <Link to={WebRoute.ADMIN_SALES_DASHBOARD}>
                    <div className={location.pathname === WebRoute.ADMIN_SALES_DASHBOARD ? "activate_line" : "unactivate_line"} >
                        <h2>홈</h2>
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_STORE_INFO}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_STORE_INFO) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("store")}>
                        <h2>매장관리</h2>
                        {sProps.UserConfigReducer.accessible === "inaccessible" &&
                            <div className="app_guide__container">
                                <div className="title_box">
                                    <p>필수</p>
                                </div>
                            </div>
                        }
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_PRODUCT_CATEGORY}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("product")}>
                        <h2>상품관리</h2>
                        {sProps.UserConfigReducer.accessible === "inaccessible" &&
                            <div className="app_guide__container">
                                <div className="title_box">
                                    <p>필수</p>
                                </div>
                            </div>
                        }
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_BUSINESS}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_BUSINESS) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("business")}>
                        <h2>사장님정보</h2>
                        {sProps.UserConfigReducer.accessible === "inaccessible" &&
                            <div className="app_guide__container">
                                <div className="title_box">
                                    <p>필수</p>
                                </div>
                            </div>
                        }
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_PAYMENT}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_PAYMENT) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("payment")}>
                        <h2>매출관리</h2>
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_INVENTORY}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_INVENTORY) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("inventory")}>
                        <h2>재고관리</h2>
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_COUPON}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_COUPON) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("coupon")}>
                        <h2>쿠폰관리</h2>
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_STAMP}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_STAMP) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("stamp")}>
                        <h2>스탬프관리</h2>
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <Link to={WebRoute.ADMIN_GUIDE_POS}>
                    <div className={location.pathname.indexOf(WebRoute.ADMIN_GUIDE) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("guide")}>
                        <h2>사용자 가이드</h2>
                        <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                    </div>
                </Link>
                <div className="logout_box" onClick={goLogOut}>
                    <h2 className="logout" >로그아웃</h2>
                </div>

                <div className="bottom_fixed">
                    <Link to={WebRoute.ADMIN_REVIEW}>
                        <div className={location.pathname.indexOf(WebRoute.ADMIN_REVIEW) >= 0 ? "activate_bottom" : "unactivate_bottom"}>
                            <h2>리뷰관리</h2>
                            <div className="app_guide__container" style={{ marginLeft: 0 }}>
                                <div className="beta_icon" >
                                    <p>Beta</p>
                                </div>
                            </div>
                            {/* 리뷰갯수 */}
                            <div className="count">
                                {iStoreUnReviewCount}
                            </div>
                            <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                        </div>
                    </Link>
                    <Link to={WebRoute.ADMIN_COMMERCIAL}>
                        <div className={location.pathname.indexOf(WebRoute.ADMIN_COMMERCIAL) >= 0 ? "activate_bottom" : "unactivate_bottom"} onClick={() => moveCategory("commercial")} >
                            <h2>광고관리</h2>
                            <div className="app_guide__container" style={{ marginLeft: 0 }}>
                                <div className="beta_icon" >
                                    <p>Beta</p>
                                </div>
                            </div>
                            <SVG src={arrow_right} width="20px" height="20px" title="더보기 화살표" />
                        </div>
                    </Link>

                    <Link to={WebRoute.ADMIN_SALES_DASHBOARD}>
                        <div className="bottom_home" onClick={() => moveCategory("dashboard")}>
                            <h3>스루사장님창구 홈</h3>
                        </div>
                    </Link>
                </div>
            </div>
        </SideStyle>
    )
}

export default Side;

const SideStyle = styled.div`
    /* 공통 css */
    *, h1, h2, h3, p, button, ul, li {
        font-family: 'Pretendard';
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: #000;
    }   

    /* side */
    .side {
        width: 257px;
        height: 85vh;
        margin-top: 60px;
        padding-bottom: 120px;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none; 
    } 
    .side::-webkit-scrollbar {
    display: none; 
}  
    .activate_line,.unactivate_line, .logout_box  {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 256px;
        padding: 12px 24px; 
        background-color: #F4F6FA;
        border: none;
        outline: none;
        cursor: pointer;

        h2 {
            margin-right: auto;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color : #1F57C7;
        }
        svg path {
            fill : #617be3;
        }
        
    }
    .unactivate_line, .logout_box {
        background-color: #FBFBFB;
        
        svg path {
            fill : rgba(0, 0, 0, 0.4)!important;
        }

        &:hover {
            background: #F4F6FA;
        }

        h2 {
            color: #000!important;
        }
    }

    /* 리뷰관리/광고관리 */
    .bottom_fixed {
        position: fixed;
        bottom: 0;
        width: 257px;
        cursor: pointer;
        
        h2 {
            font-family: 'Pretendard';
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color : #fff;
        }
        svg {
            margin-left:auto;
        }
        
        /* 리뷰개수 */
        .count {
            /* min-width: 24px; */
            padding: 3px 9px;
            background-color: #6490E7;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            border-radius: 99px;
            line-height: 24px;
            letter-spacing: 0.6px;
        }
        
    }
    .bottom_home {
        background: #050F21;
        cursor: pointer;

        h3 {
            padding: 11px 0; 
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
        }
    }
    .unactivate_bottom {
        display: flex;
        align-items: center;
        padding: 12px 24px;
        background-color: #0A1D42;
        border: none;
        outline: none;
        
        svg path {
            fill : rgba(255, 255, 255, 0.4)!important;
        }
        
        &:hover {
            background: #050F21;
            svg path {
                fill : #6490E7;
            }
        }
        h2 {
            color : #fff;
        }
        svg path {
            fill : #6490E7;
        }
    }
    .activate_bottom {
        display: flex;
        align-items: center;
        padding: 12px 24px;
        margin-left: auto;
        background-color: #050F21!important;

        h2 {
            color: #6490E7;
            cursor: pointer;
        }
        svg path {
            fill : #6490E7;
        }
    }

    /* 필수/beta아이콘 */
    .app_guide__container {
        margin-right: 12px;
        
        .title_box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 6px;
            background: #F5F5F5;
            border: 1px solid #DFDFE3;
            border-radius: 4px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            color: #EF4643;
        }
        .beta_icon p{
            margin-left: 4px;
            color: #6490E7!important;
        }
    }


`