import styled from 'styled-components';

const Style = styled.div`
    * {
        margin:0;
        padding:0;
        box-sizing:border-box;
        color: #000;
    }
    /* 옵션종류 가이드 */
    .guide_option {
        margin-top: 25px;
        h1 {
            margin-bottom: 15px;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.3;
            word-break: keep-all;
        }
        h2 {
            font-size: 16px;
            font-weight: 700;
            color: #617BE3;
        }
        p {
            margin: 7px 0 20px 0;
        }
        span {
            font-weight: 900;
        }
    }

    /* 재고관리 도움말 */
    
    .stock_guide_modal_container {
        h2 {
            margin-top: 30px;
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: 800;
        }
        h2:nth-of-type(1) {
            margin-top: 0px;
        }
        h3 {
            margin-top: 10px;
            font-size: 16px;
            color: #6490E7;
            font-weight: 700;
            line-height: 24px;

            span {
                padding: 5px;
                line-height: 30px;
                font-size: 16px;
                font-weight: 900;
                color: #6490E7;
                background:yellow;
            }
        }
        p {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }


    .category_picture {
        height: 13vh;
        margin-top: 10%;
        margin-bottom: 10%;

        .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .address input {
            width: 90%;
        }
        .address_button {
            cursor: pointer;
            margin-top: 2%;
            width: 12%;
        }
        
    }

    .payment_box {
        height: 20vh;

        .summary_box {
            display: flex;
            align-items: center;
            height: 5vh;

            p {
                font-size: 1rem;
                font-weight: 800;
            }
        }
        .sum_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 5vh;
        }
    }

    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 4vh;
                width: 3vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 4vh;
                width: 3vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 4vh;
                width: 3vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .none_commercial_box {
        margin-top: 5%;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color : #bbb;
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .insert_option_box {
        margin-top: 5%;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #1e22aa;
        border-radius: 5px;
        cursor: pointer;

        p {
            color : #1e22aa;
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .guide_text {
        margin-top: 5%;
        margin-bottom: 5%;
        text-align: left;
        font-size: 0.7rem;
        font-weight: 400;

        strong {
            color : #617BE3;
            font-weight: 700;
        }

    }

    .guide_text_line {
        margin: 5%;
        text-align: left;
        font-size: 0.8rem;
        font-weight: 400;

        strong {
            color : #617BE3;
            font-weight: 700;
        }

    }

    .select_bank {
        padding: 6px;
        width: 100%;
        border: 1px solid #BBBBBB;
        border-radius: 4px;
        outline: none;
    }

    .select_bank::placeholder {
        font-size: 0.9rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .option_list_decide {
        border-top: 1px solid #dfdfdf;
        margin-bottom: 5%;
        .list_option_wrapper {
            margin-top: 20px;
            display:flex;
            align-items: center;
            justify-content: space-between; 
            height: 50px;
            border: 1px solid #BBBBBB;
            border-radius: 5px;
            padding:5%;

            p {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
    }

    .insert_option_box:hover {
        background: #1e22aa;
        p {
            color : #fff;
        }
    }

    .option_guide_pic {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5%; 
        img {
            width: 100%;
        }
    }

    .option_text {
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 4%;
        background: #F1F3F7;
        align-items: center;

        .optionDivider {
            color : #6b7684;
            font-size: 0.75rem;

            strong {
                cursor: pointer;
            }
        }
    }

    .commercial_banner_text {
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 4%;
        height: 6vh;
        background: #F1F3F7;
        align-items: center;

        .optionDivider {
            color : #6b7684;
            font-size: 0.75rem;

            strong {
                cursor: pointer;
            }
        }
    }

    .commercial_banner_wrapper {
        height: 10vh;
        background: #F1F3F7;
        width: 19vw;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 5px;
        
    }

    .option_text_mobile {
        margin-top: 5%;
        margin-bottom: 5%;
        padding: 4%;
        height: 40px;
        background: #F1F3F7;
        align-items: center;

        .optionDivider {
            color : #6b7684;
            font-size: 0.75rem;

            strong {
                cursor: pointer;
            }
        }
    }

    .option_box_data {
        background: #F1F3F7;
        margin-bottom: 5%;
        .option_title {
            padding-top: 4%;
            padding-left: 4%;
            padding-right: 4%;
            padding-bottom: 2%;
            display: flex;
            justify-content: space-between; 
            align-items: center;

            .arrow_box {
                cursor: pointer;
                p {
                    color: #666666;
                    font-size: 0.8rem;
                    font-weight: 400;
                    text-decoration:underline;
                }
            }
        }

        .border_box {
            padding: 4%;

            .option_list_Data {
                padding: 6px 0;
                border-top: 1px solid #dfdfdf;
                display: flex;
                justify-content: space-between; 
                align-items: center;

                p {
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: #666666;
                }
            }
        }
    }
    .cOption,.sOption {
        padding: 2px 6px;
        background: #FBFBFB;
        border: 1px solid gray;
        color: gray;
        border-radius: 4px;
        font-size: 12px;
        white-space: nowrap
    }
    .sOption {
        background: #E8EFFC;
        border: 1px solid #6490E7;
        color: #6490E7;
    }

    .eraser_box {
        width: 85%;
        position: absolute;
        background: #fff;
        height: 50px;
        top: 5%;
        display: flex;
        justify-content: center; 
        text-align: center;
        border-bottom: 1px solid #dfdfdf;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .charged_eraser_box {
        width: 85%;
        position: absolute;
        background: #fff;
        height: 4vh;
        top: 8%;
        display: flex;
        justify-content: center; 
        text-align: center;
        border-bottom: 1px solid #dfdfdf;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .total_amount_box {
        width: 85%;
        background: #001e62;
        height: 5vh;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 5px;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .total_amount {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        height: 5vh;
        width: 17vw;
        p {
            margin-left: 2%;
            font-size: 1rem;
            font-weight: 700;
        }
    }
    
    .charged_select {
        display: flex;
        justify-content: center; 
        align-items: center;
        height: 7vh;
        width: 100%;

        .charged_selected_box {
            width: 90%;
            background: #;
            height: 5vh;
            top: 8%;
            display: flex;
            cursor: pointer;
            justify-content: center; 
            align-items: center;
            text-align: center;
            background : #617BE3;
            border-radius: 5px;

            p {
                font-size: 1rem;
                font-weight: 800;
                color: #fff;
            }
        }

        .charged_select_box {
            width: 90%;
            background: #fff;
            height: 5vh;
            top: 8%;
            cursor: pointer;
            display: flex;
            justify-content: center; 
            align-items: center;
            text-align: center;
            border-radius: 5px;
            border: 1px solid #617BE3;
    
            p {
                font-size: 1rem;
                font-weight: 800;
                color: #617BE3;
            }
        }

        .charged_select_box: hover {
            background: #617BE3;
    
            p {
                color: #fff;
            }
        }
    }


    .guide_eraser_box {
        width: 90%;
        position: absolute;
        background: #fff;
        height: 50px;
        top: 5%;
        display: flex;
        justify-content: center; 
        text-align: center;
        border-bottom: 1px solid #dfdfdf;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }
    .eraser_box_mobile {
        width: 85%;
        position: absolute;
        background: red;
        height: 50px;
        top: 5%;
        display: flex;
        justify-content: center; 
        text-align: center;
        border-bottom: 1px solid #dfdfdf;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .back_add_option {
        width: 20%;
        top: 5%;
        right: 0%;
        background: #fff;
        position: absolute;
        cursor: pointer;

        p {
            color: #666666;
            font-size: 0.8rem;
            font-weight: 400;
        }
    }
    .cancel_add_option {
        width: 5%;
        left: 0%;
        background: #fff;
        position: absolute;
        cursor: pointer;

        img {
            height: 15px;
        }
    }
    
    .addOption_box {
        padding-top: 5%;
        height: 40vh;
        width: 100%;

    }

    .modal_footer {
        width: 85%;
        position: absolute;
        height: 60px;
        background: #fff;
        bottom: 3%;
        z-index: 1;

        .button_box {
            display: flex;
            justify-content: center; 
            align-items: center;
            height: 60px;
            border: 1px solid #1e22aa;
            cursor: pointer;
            border-radius: 5px;

            p {
                color: #1e22aa;
                font-size: 1rem;
                font-weight: 700;
            }
        }
        .button_box:hover {
            background: #1e22aa;

            p {
                color: #fff;
            }
        }
        
    }
    .option_group_guide_null {
        margin-top: 10%;
        margin-bottom: 15%;
        height: 70px;
        border-radius: 5px;
        background: #F1F3F7;

        .title_box {
            margin-left: 5%;
            padding-top: 5%;
            justify-content: center; 
            align-items: center;

            .title {
                font-size: 0.8rem;
                font-weight: 600;
            }
            .content {
                color: #666666;
                font-size: 0.7rem;
                font-weight: 400;
            }
        }
    }

    .option_group_guide {
        margin-top: 15%;
        margin-bottom: 15%;
        height: 120px;
        border-radius: 5px;
        background: #F1F3F7;

        .title_box {
            margin-left: 5%;
            padding-top: 5%;
            justify-content: center; 
            align-items: center;

            .title {
                font-size: 0.8rem;
                font-weight: 600;
            }
            .content {
                color: #666666;
                font-size: 0.6rem;
                font-weight: 400;
            }
        }
        .take_box {
            margin: 5%;
            display: flex;
            justify-content: center; 
            align-items: center;
            width: 100px;
            height: 30px;
            border-radius: 5px;
            background: #fff;
            cursor: pointer;
            p {
                color: #617BE3;
                font-size: 0.8rem;
                font-weight: 700;
            }
        }
    }

    
    .essential_box {
        height: 70px;
        margin-bottom: 40px;

        .select_line_box {
            margin-top: 5px;
            display: flex;


            .selected_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: 50px;
                background: #617BE3;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .unselect_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: 50px;
                border: 1px solid #617BE3;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }

            .disable_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 200px;
                height: 50px;
                border: 1px solid #BBBBBB;
                border-radius: 5px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #bbbbbb;
                }
            }

        }
    }

    .category_title {
        height: 7vh;
        margin-bottom: 5%;

        input {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        
    }

    .option_group_title {
        margin-top : 5%;
        height: 100px;

        input {
            margin-top: 5px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        p {
            font-size: 0.9rem;
            font-weight: 400;
        }
    }

    .option_list_write_box {
        margin-top : 5%;
        height: 170px;

        .plus_option {
            margin-top: 10px;
            font-size: 0.9rem;
            font-weight: 700;
            color: #617BE3;
            cursor: pointer;
        }

        input {
            margin-top: 5px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        p {
            font-size: 0.9rem;
            font-weight: 400;
        }
    }

    .charged_group_list_box {
        height: 25vh;
        width: 100%;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        h2 {
            margin-bottom : 20px;
            font-size: 1rem;
            font-weight: 600;
            color: #3B3B46;
        }

        p {
            margin-top: 5%;
            font-size: 0.9rem;
            font-weight: 600;
            color: #6B7583;

            span {
                font-weight: 800;
                color: #6490E7;
            }
        }

        .selectPrice_button {
            margin-bottom: 10px;
            margin-right: 6px;
            padding: 6px 10px;
            font-size: 1rem;
            font-weight: 600;
            color: #6B7583;
            background: #F2F3F5;
            border: none;
            outline: none;
            border-radius: 4px;
            
        }

        .selectPrice_un_act_button {
            margin-bottom: 10px;
            margin-right: 6px;
            padding: 6px 10px;
            font-size: 1rem;
            font-weight: 600;
            color: #6490E7;
            background: #F3F7FF;
            border: none;
            outline: none;
            border-radius: 4px;
            
        }
    }

    .option_group_list_box {
        padding-top: 5%;
        height: 40vh;
        width: 100%;
        overflow-y: scroll;
    }

    .commercial_main_banner_box {
        padding-top: 5%;
        height: 55vh;
        width: 100%;
    }
    .option_group_list_box::-webkit-scrollbar {
        width: 0.1px;
    }
    .option_group_list_box::-webkit-scrollbar-thumb {
        background-color: none;
    }
    .option_group_list_box::-webkit-scrollbar-track {
        background-color: none;
    }

    .guide_modal_container {
        padding-top: 5%;
        height: 60vh;
        width: 100%;
        overflow-y: scroll;
    }
    .guide_modal_container::-webkit-scrollbar {
        width: 0.1px;
    }
    .guide_modal_container::-webkit-scrollbar-thumb {
        background-color: none;
    }
    .guide_modal_container::-webkit-scrollbar-track {
        background-color: none;
    }

    /* optionlist */

    .noneOptionData {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vh;

        p {
            text-align: center;
            color : #6b7684;
            font-size: 0.9rem;
        }
    }

    .optionLine {
        margin-top: 5%;
        border-style: solid;
        border-radius : 10px;
        border-color: #dfdfdf;
        border-width: 1px;
    }

    .optionDivider {
        color : #6b7684;
        font-size: 0.75rem;
    }

    .optionList {
        margin-left: 5%;
        display: flex;
        height: 6vh;
        align-items: center;
    }

    .optionDetail {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .option_name {
            color : #666666;
            font-weight:bold;
            font-size: 0.8rem;
        }
        .option_price {
            color : #bbbbbb;
            font-size: 0.8rem;
        }
    }

    /* storemenu */
    .menu p {
        margin-bottom: 15px;
        
    }
    .menu ul {
        list-style: none;
        width: 100%;
    }
    .menu_title {
        display: flex;
        text-align: center; 
        white-space: nowrap;
        cursor: grab;
        overflow: hidden;
    }
    .menu_title li {
        padding: 10px 0;
        width: 100%;
        padding-right: 15px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 2px solid #dfdfdf;
    }
    .menu_content ul {
    }
    .menu_content li {
        max-width: 100%;
        min-width: 100%;
        border-bottom: 1px solid #dfdfdf;
        padding: 10px 10px;
        overflow: hidden;
        font-size: 16px;
        font-weight:600;
        color: #333d4b;
        cursor: pointer;
    }
    .menu_content li:last-child{
        border: none;
    }
    .menu_content li img {
        float: right;
        text-align: center;
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        margin-top: -25px;
        padding: 0;
    }

    /* storeintro */
    .introtitle {
        text-align: center;
    }
    .introtitle h2 {
        font-size: 17px;
        font-weight: bold;
        margin: 4px 0;
    }
    .introtitle p {
        font-size: 12px;
    }
    li {
        list-style: none;
    }
    .introcontent {
        height: 38px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 20px 0;
        border: 1px solid #dfdfdf;
        font-weight: bold;
        font-size: 12px;
        background: #f6f6f6;
        border-radius: 3px;
        
    }
    .download {
        height: 40px;
        margin: -10px 0 0 0;
        background: #1e22aa;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 12px;
        border-radius: 3px;
        border-style: double;
        border-spacing: top;
    }
    .download:hover {
        background: #617be3;
    }
    .intro_time {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .intro_time p {
        color: #6b7684;
    }
    .introcontent .street {
        display: flex;
        align-items: center;
    }
    .introcontent .waiting {
        display: flex;
        align-items: center;
    }
    .introcontent .parking {
        display: flex;
        align-items: center;
    }
    .introcontent span {
        color: #617be3;
        padding-left: 3px;
        font-size: 12px;
    }
    .download {
        display: flex;
    }
    .download p {
        color: #fff;
        padding-left: 3px;
        font-weight: 500;
    }
    .introcefooter {
        margin: 20px 0 0 0;
        text-align: left;
        font-size: 11px;
        color: #6b7684;
    }
    .introcefooter li {
        padding-bottom: 4px;
    }
    .introcefooter span {
        border-bottom: 1px solid #6b7684;
    }
    .storemore {
        padding-top: 5%;
    }
    /* storemore */
    .intromore img {
        margin: 20px 0 0 0;
    }
    .intromore h2 {
        font-size: 15px;
        font-weight: bold;
        margin: 15px 0;
    }
    .intromore p {
        font-size: 12px;
        color: #6b7684;
    }


    /* storecheck/storechoice */
    .menucheck img {
        margin: 20px 0 0 0;
    }
    .menucheck h2 {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding: 15px 0;
        border-bottom: 1px solid #dfdfdf;
    }
    .menucheck .price {
        display: flex;
        justify-content: space-between;
        padding: 20px 10px;
        font-size: 15px;
    }
    .menucheck .amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15.5px;
        margin-top: -5px;
        padding: 0 10px;
        margin-bottom: 25px;
    }
    .amount .atitle {
        flex: 0 0 70%;
    }
    .amount button {
        width: 22px;
        height:22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        outline: none;
        border: 2px solid #1e22aa;
        font-size: 18px;
        font-weight: bold;
        color: #1e22aa;
        padding-bottom: 5px;
    }
    .amount .anumber {
        font-weight: bold;
        font-size: 16px;
    }
    .order button {
        width: 50%;
        height: 40px;
        margin-top: 50px;
        background: #fff;
        border: 2px solid #1e22aa;
        font-size: 13px;
        font-weight: bold;
    }
    .order .putbutton {
        background: #1e22aa;
        color: #fff;
    }
    .order .orderbutton {
        color: #1e22aa;
    }

    .choice {
        padding-top: 5%;
        width: 100%;
        overflow-y: scroll;
    }
    .choice::-webkit-scrollbar {
        width: 0.1px;
    }
    .choice::-webkit-scrollbar-thumb {
        background-color: none;
    }
    .choice::-webkit-scrollbar-track {
        background-color: none;
    }
    .menuchoice h2 {
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        margin: 15px 0;
        background: #f6f6f6;
        border-top: 1px solid #dfdfdf;
        padding: 0 10px;
    }
    .menuchoice .menu {
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-size: 15px;
    }
    .menu button {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        outline: none;
        background: none;
        border: 1px solid #dfdfdf;
        margin-top: -13px; 
    }
    .menu p {
        padding: 0px 10px;
        font-size: 14px;
    }

    @media (max-width: 1600px){
        .amount button {
            padding-bottom: 0px;
            padding-top: 0px;
        }
    }
`;


export default Style;