
import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactDragListView from "react-drag-listview";
import { Modal, message, Table, Select } from 'antd';
import "antd/dist/antd.css";

import noneImageBox from '../../../../assets/svg/none_image.svg';

import { ModalOptionChoice, ModalOptionRelease, ModalSetProductChoice, ModalSetProductRelease } from '../../../modal/AppModal';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

let discountCheckTime;
const { Option } = Select;

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const ProductForm = ({ iProps, iCategoryList, fnChangeNormal, iDetail, fnEdit }) => {
    const [iStatus, setStatus] = useState("");

    const [sCategory, setCategory] = useState("");
    const [sPreviewImage, setPreviewImage] = useState("");
    const [sFileList, setFileList] = useState("");
    const [productNm, setProductNm] = useState("");
    const [product_type, set_product_type] = useState("");
    const [sPrice, setPrice] = useState("");
    const [dPrice, setDiscountPrice] = useState("");
    const [productDetail, setProductDetail] = useState("");
    const [discountNm, setDiscountNm] = useState("");
    const [iStock, setStock] = useState("");
    const [productType, setProductType] = useState("normal");

    const [classProductNm, setClassProductNm] = useState(null);
    const [classPrice, setClassPrice] = useState(null);
    const [classProductDetail, setClassProductDetail] = useState(null);

    const [sCategoryList, setCategoryList] = useState([]);
    const [sProductId, setProductId] = useState(0);
    const [preOptionList, setPreOptionList] = useState([]);
    const [mediaId, setMediaId] = useState(0);
    const [isUse, setIsUse] = useState("unused");
    const setMenuOptionBox = useRef([]);
    const optionBox = useRef([]);
    const optionSelected = useRef(false);
    const [modal, contextHolder] = Modal.useModal();

    const [setMenuBox, setSetMenuBox] = useState([]);
    const [sOptionBox, setOptionBox] = useState([]);

    // 일반옵션
    const modalDone = () => {
        let temp = false;
        if (optionBox.current.length > 0) {
            temp = true;
            setOptionBox(optionBox.current);
            optionSelected.current = temp;
        }


    }
    const deleteList = (key) => {
        optionBox.current = optionBox.current.filter((item) => item.key !== key);
        setOptionBox(optionBox.current);
    }
    const [sProductColumns] = useState(isMobile ? [
        {
            title: "옵션그룹명",
            key: "name",
            width: "60%",
            dataIndex: "name",
        },
        {
            title: "순서",
            key: "key",
            width: "20%",
            render: (text, record, index) =>
                <div className='spc-between'>

                    <div onClick={() => ChangeRowMob(index, -1)}>
                        ↑
                    </div>
                    <div onClick={() => ChangeRowMob(index, 1)}>
                        ↓
                    </div>
                </div>
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteList(text.key)}><p style={{ color: "#DD1212" }}>해제</p></div>
        }] : [
        {
            title: "순서",
            key: "key",
            width: "15%",
            render: (text, record, index) => <a className="drag-handle">{parseInt(index) + 1}</a>
        },
        {
            title: "옵션그룹명",
            key: "name",
            width: "65%",
            dataIndex: "name",
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteList(text.key)}><p>해제</p></div>
        }
    ]);
    const ChangeRowMob = async (sIndex, fIndex) => {
        if ((sIndex === 0 && fIndex === -1) || (sIndex === optionBox.current.length - 1 && fIndex === 1)) {
            return;
        }
        let tempColumns = optionBox.current;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(sIndex, 1)[0];
        columnsCopy.splice(sIndex + fIndex, 0, item);
        optionBox.current = columnsCopy;
        setOptionBox(columnsCopy);
        setPreOptionList(columnsCopy);
    }

    const inputClassProductNm = () => {
        if (classProductNm !== "input_wrong") {
            setClassProductNm("input_action");
        }
        setClassPrice(null);
        setClassProductDetail(null);
    }
    const inputClassPrice = () => {
        if (classPrice !== "input_wrong") {
            setClassPrice("input_action");
        }
        setClassProductNm(null);
        setClassProductDetail(null);
    }

    const inputClassDetail = () => {
        if (classProductDetail !== "input_wrong") {
            setClassProductDetail("input_action");
        }
        setClassPrice(null);
        setClassProductNm(null);
    }
    const inputClassCategory = () => {
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
    }

    const uploadLogoImage = async (e) => {
        let origin = e.target.files[0];
        if (origin !== undefined) {
            if (origin.type !== undefined && origin.type !== null) {
                if (origin.type === "image/png" || origin.type === "image/jpeg") {
                    setFileList(origin);
                    origin = await getBase64(origin);
                    setPreviewImage(origin);
                } else {
                    message.error("파일형식은 jpg나 png만 가능합니다.");
                }
            }
        }
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
    }
    const onChangeCategoryValue = (event) => {
        if (isMobile) {
            setCategory(event);
        } else
            setCategory(event.target.value);
    }
    const cancelLogoImage = () => {
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
        setFileList("");
        setPreviewImage("");
    }
    const onChangeNmValue = (event) => {
        const value = event.target.value;
        setClassProductNm("input_action");
        setProductNm(value);
    }
    const onChangePriceValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setClassPrice("input_action");
            setPrice(value);
        }
    }
    const onChangeStock = event => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setStock(value);
        }
    };
    const onChangeDiscountPriceValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setDiscountPrice(value);
        }

        if (discountCheckTime) clearTimeout(discountCheckTime);
        discountCheckTime = setTimeout(() => {
            let temp = 0;
            if (value.toString().trim() === "" || sPrice.toString().trim() === "") {
                temp = 0;
            } else {
                temp = Math.round(parseFloat(100 - (value / sPrice * 100)));
            }
            setDiscountNm(temp);
        }, 300);
    }
    const onChangeProductDetail = (event) => {
        const value = event.target.value;
        setClassProductDetail("input_action");
        setProductDetail(value);
    }

    const sChangeOption = (e) => {
        if (optionSelected.current) {
            modal.success(modalOptionRelease);
        } else {
            modal.success(modalOptionSelect);
        }
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
    }


    const modalOptionSelect = {
        content: (
            <ModalOptionChoice iOptions={sOptionBox} sProps={iProps} fUploadData={(sIndex) => uploadData(sIndex)} />
        ), onOk() {
            modalDone();
        },
    };

    const modalOptionRelease = {
        content: (
            <ModalOptionRelease iOptions={sOptionBox} fUploadData={(sIndex) => uploadData(sIndex)} sProps={iProps} />
        ), onOk() {
            modalDone();
        },
    };


    const uploadData = (sIndex) => {
        optionBox.current = sIndex;
    }

    const editProduct = async () => {
        try {

            if (iCategoryList.length > 0) {
                const oData = {
                    preOptionList,
                    sPreviewImage,
                    sProductId,
                    sFileList,
                    sCategory,
                    productNm,
                    sPrice,
                    dPrice,
                    productDetail,
                    sOptionBox,
                    mediaId,
                    isUse,
                    iStock,
                    productType,
                    setMenuBox,
                    product_type
                };
                let temp = sCategory;

                if (sCategory === "") {
                    temp = iCategoryList[0].id;
                }
                // if (product_type === 1 &&setMenuBox.length === 0){
                //     message.error('세트 메뉴 상품을 추가해주세요.')
                // }
                else if (fnEdit !== undefined && typeof fnEdit === "function") {


                    await fnEdit(oData);
                    changeStatus();
                    console('수정완료',oData)

                }
            }
        } catch (err) {
            console.log("err-->", err);
        }

    }

    const changeStatus = async () => {
        optionBox.current = [];
        setMenuOptionBox.current = [];
        optionSelected.current = false;
        // setOptionBox([]);
        // setPreOptionList([]);
        // setSetMenuBox([])
        setStatus("");
        setPreviewImage(noneImageBox);
        setFileList("");
        setCategory("");
        setProductNm("");
        setPrice("");
        setDiscountPrice("");
        setProductDetail("");
        setStock("");
        setProductType("normal");
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
        if (fnChangeNormal !== undefined && typeof fnChangeNormal === "function") {
            await fnChangeNormal();
        }
    }


    const dragProps = (fromIndex, toIndex) => {
        let tempColumns = sOptionBox;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        optionBox.current = columnsCopy;
        setOptionBox(columnsCopy);
        setPreOptionList(columnsCopy);
    }

    useEffect(() => {
        if (iStatus === "" || parseInt(sProductId) !== parseInt(iDetail.productId)) {
            console.log('불러오기',iDetail)
            setProductId(iDetail.productId);
            setCategory(iDetail.categoryId);
            setPrice(iDetail.org_price);
            setProductNm(iDetail.product_name);
            setProductDetail(iDetail.description);
            setIsUse(iDetail.status);
            setMediaId(iDetail.mediaId);
            setStock(iDetail.inStock);
            setProductType(iDetail.isThrooOnly);
            set_product_type(iDetail.productType);
            setStatus("edit");

            if (iDetail.url_path !== undefined && iDetail.url_path !== null && iDetail.url_path !== "") {
                setPreviewImage(iDetail.url_path);
            } else {
                setPreviewImage("");
            }

            if (iDetail.setOptions !== undefined && iDetail.setOptions !== null && iDetail.setOptions.length !== 0 && iDetail.productType === 1) {
                optionSelected.current = true;
                setMenuOptionBox.current = iDetail.setOptions;
                setSetMenuBox(iDetail.setOptions);
                setOptionBox(iDetail.options);
                setPreOptionList(iDetail.options);
            }
            // if (iDetail.options !== undefined && iDetail.options !== null && iDetail.options.length > 0 && iDetail.productType !== 1) {
            //     optionSelected.current = true;
            //     optionBox.current = iDetail.options;
            //     setOptionBox(iDetail.options);
            //     setPreOptionList(iDetail.options);
            // }
            else {
                optionSelected.current = true;
                optionBox.current = iDetail.options;
                setOptionBox(iDetail.options);
                setPreOptionList(iDetail.options);
            }
            // else {
            //     optionSelected.current = false;
            //     // optionBox.current = [];
            //     // setMenuOptionBox.current = [];
            //     // setOptionBox([]);
            //     // setSetMenuBox([]);
            //     // setPreOptionList([]);
            // }
            if (parseInt(iDetail.base_price) > 0) {
                let temp = 0;
                if (iDetail.base_price.toString().trim() === "" || iDetail.org_price.toString().trim() === "") {
                    temp = 0;
                } else {
                    temp = Math.round(parseFloat(100 - (parseInt(iDetail.base_price) / parseInt(iDetail.org_price) * 100)));
                }
                setDiscountNm(temp);
                setDiscountPrice(iDetail.base_price);
            } else {
                setDiscountPrice("");
                setDiscountNm("");
            }

        }
    }, [iDetail]);

    // 세트상품만들기
    const setMenuModalDone = () => {
        let temp = false;

        if (setMenuOptionBox.current.length > 0) {
            temp = true;
        }
        optionSelected.current = temp;
        setSetMenuBox(setMenuOptionBox.current)
    }
    const deleteProductList = (key) => {
        if (setMenuOptionBox.current.length === 1) {
            message.error('최소 1개의 세트 메뉴 상품을 추가해야합니다.')
        } else {
            setMenuOptionBox.current = setMenuOptionBox.current.filter((item) => item.key !== key);
            setSetMenuBox(setMenuOptionBox.current);
        }
    }
    const ChangeSetMenuRowMob = async (sIndex, fIndex) => {
        if ((sIndex === 0 && fIndex === -1) || (sIndex === setMenuOptionBox.current.length - 1 && fIndex === 1)) {
            return;
        }
        let tempColumns = setMenuOptionBox.current;
        const columnsCopy = tempColumns.slice();

        const item = columnsCopy.splice(sIndex, 1)[0];
        columnsCopy.splice(sIndex + fIndex, 0, item);
        setMenuOptionBox.current = columnsCopy;
        setSetMenuBox(columnsCopy)
    }
    const [setProductColumns] = useState(isMobile ? [
        {
            title: "세트메뉴 옵션명",
            key: "name",
            width: "60%",
            dataIndex: "name",
        },
        {
            title: "순서",
            key: "key",
            width: "20%",
            render: (text, record, index) =>
                <div className='spc-between'>

                    <div onClick={() => ChangeSetMenuRowMob(index, -1)}>
                        ↑
                    </div>
                    <div onClick={() => ChangeSetMenuRowMob(index, 1)}>
                        ↓
                    </div>
                </div>
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteProductList(text.key)}><p style={{ color: "#DD1212" }}>해제</p></div>
        }
    ] : [
        {
            title: "순서",
            key: "key",
            width: "15%",
            render: (text, record, index) => <a className="drag-handle">{parseInt(index) + 1}</a>
        },
        {
            title: "세트메뉴 옵션명",
            key: "name",
            width: "40%",
            dataIndex: "name",
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteProductList(text.key)}><p>해제</p></div>
        }
    ]);
    const setChangeSetMenu = (e) => {
        if (optionSelected.current) {
            modal.success(modalSetProductRelease);
        } else {
            modal.success(modalSetProductSelect);
        }
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);

    }
    const modalSetProductSelect = {
        content: (
            <ModalSetProductChoice iOptions={setMenuBox} sProps={iProps} fUploadData={(sIndex) => uploadsData(sIndex)} />
        ), onOk() {
            setMenuModalDone();
        },
    };
    const modalSetProductRelease = {
        content: (
            <ModalSetProductRelease iOptions={setMenuBox} fUploadData={(sIndex) => uploadsData(sIndex)} sProps={iProps} />
        ), onOk() {
            setMenuModalDone();
        },
    };
    const uploadsData = (sIndex) => {
        setMenuOptionBox.current = sIndex;
    }
    const drag_set_Props = (fromIndex, toIndex) => {
        let tempColumns = setMenuBox;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        setMenuOptionBox.current = columnsCopy;
        setSetMenuBox(columnsCopy);
    }

    useEffect(() => {
        setCategoryList(iCategoryList);
        
    }, [iCategoryList]);

    return (
        <Fragment>
            <BrowserView>
                <div className="container">

                    <div className="title_box">
                        <h2>상품관리</h2>
                        <div className="cancel_box" onClick={() => changeStatus()}>
                            <p>수정취소</p>
                        </div>
                    </div>
                    <div className="category_title">
                        <p>상품분류 선택</p>
                        <select placeholder="메뉴가 들어갈 분류를 선택해주세요" value={sCategory} onClick={inputClassCategory} onChange={onChangeCategoryValue} >
                            {sCategoryList.map((item, index) => {
                                return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                )
                            })};
                        </select>
                    </div>
                    <div className="category_title">
                        <p>스루온리 상품 설정</p>
                        <div className='throo_only_wrap'>
                            <div onClick={() => setProductType("normal")} className='left' style={{ background: productType === "normal" ? '#617BE3' : '#F1F3F7' }}>
                                <p style={{ color: productType === "normal" ? "#fff" : "#A1A1A1" }}>일반상품</p>
                            </div>
                            <div onClick={() => setProductType("only")} className='right' style={{ background: productType !== "normal" ? '#617BE3' : '#F1F3F7' }}>
                                <p style={{ color: productType !== "normal" ? "#fff" : "#A1A1A1" }}>스루온리 상품</p>
                            </div>
                        </div>
                    </div>
                    <div className="category_title">
                        <p>상품명</p>
                        <input
                            type="text"
                            placeholder="상품명을 입력해주세요."
                            value={productNm}
                            onChange={onChangeNmValue}
                            className={classProductNm}
                            onClick={inputClassProductNm}
                        />
                    </div>
                    {product_type === 1 &&
                        <div className='option_title'>
                            <p>세트 메뉴 상품</p>
                            <div className="option_select_box" style={{ marginTop: '10px' }}>
                                <p onClick={setChangeSetMenu}>+ 세트 메뉴 상품 추가하기</p>
                            </div>
                            <div className="option_table_wrapper">
                                <ReactDragListView.DragColumn
                                    onDragEnd={drag_set_Props}
                                    handleSelector="a"
                                >
                                    <Table columns={setProductColumns} dataSource={setMenuBox} pagination={false} />
                                </ReactDragListView.DragColumn>
                            </div>
                            <div className="location_box_line">
                                <div className="how_box">
                                    <p>순서를 변경하고 싶으시다면 순서의 숫자를 잡고 드래그를 해주세요!</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="category_price_line">
                        <p>가격</p>
                        <div style={{ position: "relative", marginTop: "2%", marginBottom: "2%" }}>
                            <input
                                type="text"
                                placeholder="(필수) 상품 정가 입력"
                                value={sPrice}
                                onChange={onChangePriceValue}
                                className={classPrice}
                                onClick={inputClassPrice}
                            />
                            <p className="product_price_box">원</p>
                        </div>
                        <p>할인된 최종 판매가격<span>(선택)</span></p>
                        <div style={{ position: "relative", marginTop: "2%" }}>
                            <input
                                type="text"
                                placeholder="(선택) 할인된 최종 판매가격"
                                value={dPrice}
                                onChange={onChangeDiscountPriceValue}
                                className="classDiscountPrice"
                            />
                            <div className="discount_box">
                                <p>할인율</p>
                                <p className="discount_text">- {discountNm} %</p>
                            </div>
                            <p className="product_discount_price_box">원</p>
                        </div>
                        <div className="discount_detail_box">
                            <p>※할인된 최종 판매가격 입력시 앱에 원래 가격,할인 판매가격 그리고 할인율이 표시되며, 결제할때 최종판매가격입니다.</p>
                        </div>
                    </div>
                    <div className="category_title" style={{ marginBottom: "70px", marginTop: "70px" }}>
                        <p>상품 이미지<span>(선택)</span></p>
                        <div className="image_line">
                            {sPreviewImage !== "" ?
                                <div className="image_box">
                                    <img src={sPreviewImage} alt="none_image" />
                                </div>
                                :
                                <div className="none_image_box">
                                    <img src={noneImageBox} alt="none_image" />
                                </div>
                            }
                            <div className="image_box_button">
                                <div className="filebox">
                                    <label htmlFor="menu_file">
                                        <div className="edit_image">
                                            <p>등록</p>
                                        </div>
                                        <input type="file" id="menu_file" onChange={(e) => uploadLogoImage(e)} />
                                    </label>
                                </div>
                                <div className={sPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage()}><p>삭제</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="category_title">
                        <p>상품설명<span>(선택)</span></p>
                        <textarea
                            type="text"
                            placeholder="상품설명을 입력해주세요."
                            value={productDetail}
                            onChange={onChangeProductDetail}
                            className={classProductDetail}
                            onClick={inputClassDetail}
                        />
                    </div>
                    <div className="option_title">
                        <p>옵션<span>(선택)</span></p>
                        <div className="option_select_box" >
                            <p onClick={sChangeOption}>+ 옵션 추가하기</p>
                        </div>
                        <div className="option_table_wrapper">
                            <ReactDragListView.DragColumn
                                onDragEnd={dragProps}
                                handleSelector="a"
                            >
                                <Table columns={sProductColumns} dataSource={sOptionBox} pagination={false} />
                            </ReactDragListView.DragColumn>
                        </div>
                        <div className="location_box_line">
                            <div className="how_box">
                                <p>순서를 변경하고 싶으시다면 순서의 아이콘을 잡고 드래그를 해주세요!</p>
                            </div>
                        </div>
                    </div>
                    <div className="insert_box" onClick={editProduct}>
                        <p>상품수정하기</p>
                    </div>
                    {contextHolder}
                </div>
            </BrowserView>
            <MobileView>
                <div className="category_title_line">
                    <div className="category_title">
                        <div className="title_box">
                            <h2>상품관리</h2>
                        </div>
                        <div className="cancel_box" onClick={() => changeStatus()}>
                            <p>수정취소</p>
                        </div>
                    </div>
                    <div className="inputText">
                        <h3>상품분류 선택</h3>
                        <Select value={sCategory} onClick={inputClassCategory} onChange={onChangeCategoryValue} className="select_bank" bordered={false}>
                            {sCategoryList.map((item, index) => {
                                return (
                                    <Option key={index} value={item.id}>{item.name}</Option>
                                )
                            })};
                        </Select>
                    </div>
                    <div className="inputText">
                        <h3>스루온리 상품 설정</h3>
                        <div className='throo_only'>
                            <div onClick={() => setProductType("normal")} className='left' style={{ background: productType === "normal" ? '#617BE3' : '#F1F3F7' }}>
                                <p style={{ color: productType === "normal" ? "#fff" : "#A1A1A1" }}>일반상품</p>
                            </div>
                            <div onClick={() => setProductType("only")} className='right' style={{ background: productType !== "normal" ? '#617BE3' : '#F1F3F7' }}>
                                <p style={{ color: productType !== "normal" ? "#fff" : "#A1A1A1" }}>스루온리 상품</p>
                            </div>
                        </div>
                    </div>
                    <div className="inputText">
                        <h3>상품명</h3>
                        <input
                            type="text"
                            placeholder="상품명을 입력해주세요."
                            value={productNm}
                            onChange={onChangeNmValue}
                            className={classProductNm}
                            onClick={inputClassProductNm}
                        />
                    </div>
                    {product_type === 1 &&
                        <div className='option_title' style={{ marginTop: '15px' }}>
                            <p>세트 메뉴 상품</p>
                            <div className="option_select_box" style={{ marginTop: '10px' }}>
                                <p onClick={setChangeSetMenu}>+ 세트 메뉴 상품 추가하기</p>
                            </div>
                            <div className="option_table_wrapper" style={{ margin: '15px 0' }}>
                                <ReactDragListView.DragColumn
                                    onDragEnd={drag_set_Props}
                                    handleSelector="a"
                                >
                                    <Table columns={setProductColumns} dataSource={setMenuBox} pagination={false} />
                                </ReactDragListView.DragColumn>
                            </div>
                            <div className="location_box_line" style={{ marginTop: '2px', background: '#f1f3f7', padding: '0 5px', borderRadius: '4px' }}>
                                <div className="how_box" style={{ padding: '10px 2px' }}>
                                    <p style={{ fontSize: '12px', color: '#666' }}>순서를 변경하고 싶으시다면 순서 변경 화살표를 클릭해주세요!</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="category_price_line">
                        <h3>가격</h3>
                        <div style={{ position: "relative", marginTop: "2%", marginBottom: "2%" }}>
                            <input
                                type="text"
                                placeholder="(필수) 상품 정가 입력"
                                value={sPrice}
                                onChange={onChangePriceValue}
                                className={classPrice}
                                onClick={inputClassPrice}
                            />
                            <p className="product_price_box">원</p>
                        </div>
                        <h3>할인된 최종 판매가격<span>(선택)</span></h3>
                        <div style={{ position: "relative", marginTop: "2%" }}>
                            <input
                                type="text"
                                placeholder="(선택) 할인된 최종 판매가격"
                                value={dPrice}
                                onChange={onChangeDiscountPriceValue}
                                className="classDiscountPrice"
                            />
                            <div className="discount_box">
                                <p>할인율</p>
                                <p className="discount_text">- {discountNm} %</p>
                            </div>
                            <p className="product_discount_price_box">원</p>
                        </div>
                        <div className="discount_detail_box" style={{ paddingRight: '4px' }}>
                            <p>※할인된 최종 판매가격 입력시 앱에 원래 가격,할인 판매가격 그리고 할인율이 표시되며, 결제할때 최종판매가격입니다.</p>
                        </div>
                    </div>
                    <div className="inputText" style={{ marginBottom: "20px" }}>
                        <h3>상품 이미지<span>(선택)</span></h3>
                        <div className="image_line">
                            {sPreviewImage !== "" ?
                                <div className="none_image_box">
                                    <img src={sPreviewImage} alt="none_image" />
                                </div>
                                :
                                <div className="image_box">
                                    <img src={noneImageBox} alt="none_image" />
                                </div>
                            }
                            <div className="image_box_button">
                                <div className="filebox">
                                    <label htmlFor="menu_file">
                                        <div className="edit_image">
                                            <p>등록</p>
                                        </div>
                                        <input type="file" id="menu_file" onChange={(e) => uploadLogoImage(e)} />
                                    </label>
                                </div>
                                <div className={sPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage()}><p>삭제</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="inputText">
                        <h3>상품설명<span>(선택)</span></h3>
                        <textarea
                            type="text"
                            placeholder="상품설명을 입력해주세요."
                            value={productDetail}
                            onChange={onChangeProductDetail}
                            className={classProductDetail}
                            onClick={inputClassDetail}
                        />
                    </div>
                    <div className="option_title">
                        <p>옵션<span>(선택)</span></p>
                        <div className="option_select_box" >
                            <p onClick={sChangeOption}>+ 옵션 추가하기</p>
                        </div>
                        <div className="option_table_wrapper" style={{marginBottom:'10px'}}>
                            <ReactDragListView.DragColumn
                                onDragEnd={dragProps}
                                handleSelector="a"
                            >
                                <Table columns={sProductColumns} dataSource={sOptionBox} pagination={false} />
                            </ReactDragListView.DragColumn>
                        </div>
                    </div>
                    <div className="location_box_line" style={{ marginTop: '2px', background: '#f1f3f7',marginBottom: '20px', padding: '0 5px', borderRadius: '4px' }}>
                        <div className="how_box" style={{ padding: '10px 2px' }}>
                            <p style={{ fontSize: '12px', color: '#666' }}>순서를 변경하고 싶으시다면 순서 변경 화살표를 클릭해주세요!</p>
                        </div>
                    </div>
                    <div className="bottom_fixed">
                        <button className="finishButton" onClick={editProduct}>상품수정하기</button>
                    </div>
                    {contextHolder}
                </div>
            </MobileView>
        </Fragment>
    )
}

export default ProductForm;