import React, { useState, useEffect, useRef, Fragment } from 'react';
import { message, Select } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import styled from 'styled-components';

let startHourList = [];
const minuteList = [];
for (let i = 0; i < 24; i++) {
    startHourList.push({
        key: i,
        value: i > 9 ? i.toString() : "0" + i.toString(),
        name: i > 9 ? `${i}시` : `0${i}시`,
    });
};
for (let i = 0; i < 12; i++) {
    minuteList.push({
        key: i,
        value: i > 1 ? (i * 5).toString() : "0" + (i * 5).toString(),
        name: i > 1 ? `${i * 5}분` : `0${i * 5}분`,
    });
};
const dayList = [
    { id: 1, value: 1, name: "월요일" },
    { id: 2, value: 2, name: "화요일" },
    { id: 3, value: 3, name: "수요일" },
    { id: 4, value: 4, name: "목요일" },
    { id: 5, value: 5, name: "금요일" },
    { id: 6, value: 6, name: "토요일" },
    { id: 0, value: 0, name: "일요일" },
];

const eachDayList = [
    { value: 7, name: "매일" },
    { value: 8, name: "평일" },
    { value: 9, name: "토요일" },
    { value: 10, name: "일요일" },
];
const congestionList = [
    { value: 0, name: "여유" },
    { value: 1, name: "보통" },
    { value: 2, name: "바쁨" },
];
const congestionList2 = [
    { value: 0, name: "여유" },
    { value: 1, name: "보통" },
    { value: 2, name: "바쁨" },
    { value: 3, name: "워크스루" },
];

async function* asyncGenerator(sIndex) {
    let count = sIndex;
    while (count < 34)
        yield count++;
};

async function* asyncGenerator2() {
    let count = 0;
    while (count < 2)
        yield count++;
};

async function* asyncGenerator3(sIndex) {
    let count = 1;
    while (count < sIndex)
        yield count++;
};

const { Option } = Select;

const InsertForm = ({ fnInsert, listData, iAlert }) => {
    const [sType, setType] = useState("");

    const [sAlert] = useState(iAlert);

    const [sDayValue, setDayValue] = useState(1);
    const [sDayData, setDayData] = useState("월요일");

    const [sEachDayValue, setEachDayValue] = useState(7);
    const [sEachDayData, setEachDayData] = useState("매일");

    const [sCongestionValue, setCongestionValue] = useState(1);
    const [sCongestionData, setCongestionData] = useState("보통");

    const [startHour, setStartHour] = useState('00');
    const [startMin, setStartMin] = useState('00');
    const [endHour, setEndHour] = useState("01");
    const [endMin, setEndMin] = useState('00');

    const [endHourList, setEndHourList] = useState([]);
    const sData = useRef([]);

    const changeDayValue = (value) => {
        if (!isMobile) {
            value = value.target.value
        }
        let temp = "일요일";
        if (value > 0 && value < 2) {
            temp = "월요일";
        } else if (value > 1 && value < 3) {
            temp = "화요일";
        } else if (value > 2 && value < 4) {
            temp = "수요일";
        } else if (value > 3 && value < 5) {
            temp = "목요일";
        } else if (value > 4 && value < 6) {
            temp = "금요일";
        } else if (value > 5 && value < 7) {
            temp = "토요일";
        }

        setDayData(temp);
        setDayValue(value);
    }

    const changeStartHourValue = async (value) => {
        if (!isMobile) {
            value = value.target.value
        }
        const tempHour = parseInt(value) + 1;
        const temp = value;
        let tempList = [];
        if (parseInt(value) === 23) {
            tempList = [{
                key: 23,
                value: "23",
                name: "23시",
            }];
        } else {
            for await (const iterator of asyncGenerator(tempHour)) {
                tempList.push({
                    key: iterator,
                    value: iterator > 9 ? iterator.toString() : "0" + iterator.toString(),
                    name: iterator > 9 ? (parseInt(iterator) > 24 ? `다음날(새벽) ${parseInt(iterator) - 24}시` : `${iterator}시`) : `0${iterator}시`,
                });
            }
        }
        if (parseInt(endHour) <= parseInt(temp)) {
            if (parseInt(temp) < 9) {
                setEndHour("0" + (parseInt(temp) + 1).toString());
            } else if (parseInt(temp) === 23) {
                setEndHour(parseInt(temp).toString());
            } else {
                setEndHour((parseInt(temp) + 1).toString());
            }
        }

        setEndHourList(tempList);
        setStartHour(temp);
    }

    const changeStartMinValue = (value) => {
        if (!isMobile) {
            value = value.target.value
        }
        setStartMin(value);
    }
    const changeEndHourValue = (value) => {
        if (!isMobile) {
            value = value.target.value
        }
        if (parseInt(value) <= parseInt(startHour)) {
            message.error("끝나는 시간이 시작 시간보다 작습니다.");
        } else {
            setEndHour(value);
        }
    }
    const changeEndMinValue = (value) => {
        if (!isMobile) {
            value = value.target.value
        }
        setEndMin(value);
    }

    const changeEachDayValue = (value) => {
        if (!isMobile) {
            value = value.target.value
        }
        let temp = "매일";
        if (value > 7 && value < 9) {
            temp = "평일";
        } else if (value > 8 && value < 10) {
            temp = "토요일";
        } else if (value > 9 && value < 11) {
            temp = "일요일";
        }

        setEachDayData(temp);
        setEachDayValue(value);
    }

    const changeCongestionValue = (value) => {
        if (!isMobile) {
            value = value.target.value
        }
        let temp = "여유";
        if (value > 0 && value < 2) {
            temp = "보통";
        } else if (value > 1 && value < 3) {
            temp = "바쁨";
        } else if (value > 2 && value < 4) {
            temp = "워크스루";
        }

        setCongestionData(temp);
        setCongestionValue(value);
    }

    const onChangeType = (sIndex) => {
        setType(sIndex);
    }

    const checkUpInsert = async () => {
        let sIndex = 0;
        for await (const iterator of listData) {
            if (sType !== "date") {
                if (parseInt(iterator.day_of_week) === 7) {
                    if (sEachDayValue > 7 || sEachDayValue < 7) {
                        sIndex += 1;
                    }
                } else {
                    if (parseInt(sEachDayValue) === 7) {
                        sIndex += 1;
                    }
                }
            }
        }
        if (sIndex > 0) {
            message.error("중복되는 요일이 존재합니다.");
        } else {
            if ((startHour + startMin) === (endHour + endMin)) {
                message.error("시작하는 시간과 끝나는시간이 같습니다.");
            } else {
                insertList();
            }
        }
    }

    const everyDayBraces = async (xIndex, iCount, tempHour) => {
        let temp = {};
        if (xIndex === "over") {
            if (parseInt(iCount) > 0) {
                temp = {
                    key: sEachDayValue.toString() + "00:00~0" + tempHour.toString() + ":" + endMin,
                    day: sEachDayData,
                    operation: "00:00~0" + tempHour.toString() + ":" + endMin,
                    time: sCongestionData,
                    day_of_week: sEachDayValue,
                    congestion_type: sCongestionValue,
                    opening_time: "00:00",
                    closing_time: "0" + tempHour.toString() + ":" + endMin,
                }
            } else {
                temp = {
                    key: sEachDayValue.toString() + startHour + ":" + startMin + "~24:00",
                    day: sEachDayData,
                    operation: startHour + ":" + startMin + "~24:00",
                    time: sCongestionData,
                    day_of_week: sEachDayValue,
                    congestion_type: sCongestionValue,
                    opening_time: startHour + ":" + startMin,
                    closing_time: "24:00",
                }
            }
        } else {
            temp = {
                key: sEachDayValue.toString() + startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                day: sEachDayData,
                operation: startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                time: sCongestionData,
                day_of_week: sEachDayValue,
                congestion_type: sCongestionValue,
                opening_time: startHour + ":" + startMin,
                closing_time: endHour + ":" + endMin,
            }
        }
        return temp;
    }

    const saturdayBraces = async (xIndex, iCount, tempHour) => {
        let temp = {};
        if (xIndex === "over") {
            if (parseInt(iCount) > 0) {
                temp = {
                    key: "000:00~0" + tempHour.toString() + ":" + endMin,
                    day: "일요일",
                    operation: "00:00~0" + tempHour.toString() + ":" + endMin,
                    time: sCongestionData,
                    day_of_week: 0,
                    congestion_type: sCongestionValue,
                    opening_time: "00:00",
                    closing_time: "0" + tempHour.toString() + ":" + endMin,
                }
            } else {
                temp = {
                    key: "6" + startHour + ":" + startMin + "~24:00",
                    day: "토요일",
                    operation: startHour + ":" + startMin + "~24:00",
                    time: sCongestionData,
                    day_of_week: 6,
                    congestion_type: sCongestionValue,
                    opening_time: startHour + ":" + startMin,
                    closing_time: "24:00",
                }
            }
        } else {
            temp = {
                key: "6" + startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                day: "토요일",
                operation: startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                time: sCongestionData,
                day_of_week: 6,
                congestion_type: sCongestionValue,
                opening_time: startHour + ":" + startMin,
                closing_time: endHour + ":" + endMin,
            }
        }
        return temp;
    }

    const sundayBraces = async (xIndex, iCount, tempHour) => {
        let temp = {};
        if (xIndex === "over") {
            if (parseInt(iCount) > 0) {
                temp = {
                    key: "100:00~0" + tempHour.toString() + ":" + endMin,
                    day: "월요일",
                    operation: "00:00~0" + tempHour.toString() + ":" + endMin,
                    time: sCongestionData,
                    day_of_week: 1,
                    congestion_type: sCongestionValue,
                    opening_time: "00:00",
                    closing_time: "0" + tempHour.toString() + ":" + endMin,
                }
            } else {
                temp = {
                    key: "0" + startHour + ":" + startMin + "~24:00",
                    day: "일요일",
                    operation: startHour + ":" + startMin + "~24:00",
                    time: sCongestionData,
                    day_of_week: 0,
                    congestion_type: sCongestionValue,
                    opening_time: startHour + ":" + startMin,
                    closing_time: "24:00",
                }
            }
        } else {
            temp = {
                key: "0" + startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                day: "일요일",
                operation: startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                time: sCongestionData,
                day_of_week: 0,
                congestion_type: sCongestionValue,
                opening_time: startHour + ":" + startMin,
                closing_time: endHour + ":" + endMin,
            }
        }
        return temp;
    }

    const weekdayBraces = async (xIndex, iCount, tempHour, xCount, isLargeNumber) => {
        let iLargeNumber = isLargeNumber;
        let temp = {};
        if (xIndex === "over") {
            if (parseInt(iCount) > 0) {
                iLargeNumber = (element) => element.value === (parseInt(xCount) + 1);
                temp = {
                    key: (parseInt(xCount) + 1).toString() + "00:00~0" + tempHour.toString() + ":" + endMin,
                    day: dayList[dayList.findIndex(iLargeNumber)].name,
                    operation: "00:00~0" + tempHour.toString() + ":" + endMin,
                    time: sCongestionData,
                    day_of_week: parseInt(xCount) + 1,
                    congestion_type: sCongestionValue,
                    opening_time: "00:00",
                    closing_time: "0" + tempHour.toString() + ":" + endMin,
                }
            } else {
                temp = {
                    key: xCount.toString() + startHour + ":" + startMin + "~24:00",
                    day: dayList[dayList.findIndex(iLargeNumber)].name,
                    operation: startHour + ":" + startMin + "~24:00",
                    time: sCongestionData,
                    day_of_week: parseInt(xCount),
                    congestion_type: sCongestionValue,
                    opening_time: startHour + ":" + startMin,
                    closing_time: "24:00",
                }
            }
        } else {
            temp = {
                key: xCount.toString() + startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                day: dayList[dayList.findIndex(iLargeNumber)].name,
                operation: startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                time: sCongestionData,
                day_of_week: parseInt(xCount),
                congestion_type: sCongestionValue,
                opening_time: startHour + ":" + startMin,
                closing_time: endHour + ":" + endMin,
            }
        }
        return temp;
    }

    const rollingDate = async (loopCountX, xIndex, aIndex) => {
        let isProcess = false;
        let xData = [];
        let tempHour = endHour;
        tempHour = parseInt(tempHour) - 24;
        for await (const x of asyncGenerator3(loopCountX)) {
            let isLargeNumber = (element) => element.value === parseInt(x);
            if (xIndex === "over") {
                for await (const i of asyncGenerator2()) {
                    let result = {};
                    if (aIndex === "weekday") {
                        result = await weekdayBraces(xIndex, i, tempHour, x, isLargeNumber);
                    } else if (aIndex === "sunday") {
                        result = await sundayBraces(xIndex, i, tempHour);
                    } else if (aIndex === "saturday") {
                        result = await saturdayBraces(xIndex, i, tempHour);
                    } else if (aIndex === "everyDay") {
                        result = await everyDayBraces(xIndex, i, tempHour);
                    }
                    const validation = await validateData(result, listData);
                    if (validation < 1) {
                        xData.push(result);
                    }
                }
                if (xData.length < 2) {
                    message.error("중복되는 시간이 존재합니다");
                    break;
                }
            } else {
                let result = {};
                if (aIndex === "weekday") {
                    result = await weekdayBraces(xIndex, null, tempHour, x, isLargeNumber);
                } else if (aIndex === "sunday") {
                    result = await sundayBraces(xIndex, null, tempHour);
                } else if (aIndex === "saturday") {
                    result = await saturdayBraces(xIndex, null, tempHour);
                } else if (aIndex === "everyDay") {
                    result = await everyDayBraces(xIndex, null, tempHour);
                }
                const validation = await validateData(result, listData);
                if (validation < 1) {
                    xData.push(result);
                } else {
                    message.error("중복되는 시간이 존재합니다");
                    break;
                }
            }
        }

        if (aIndex !== "weekday") {
            if ((xIndex === "over" && xData.length === 2) || (xIndex !== "over" && xData.length === 1)) {
                isProcess = true;
            }
        } else {
            if ((xIndex === "over" && xData.length === 10) || (xIndex !== "over" && xData.length === 5)) {
                isProcess = true;
            }
        }

        if (isProcess && fnInsert !== undefined && typeof fnInsert === "function") {
            await fnInsert([...listData, ...xData]);
        }
    }

    const rollingDay = async (xIndex) => {
        let sNm = 0;
        let isLargeNumber;
        let isProcess = false;
        let xData = [];
        let tempHour = endHour;
        tempHour = parseInt(tempHour) - 24;
        if (xIndex === "over") {
            for await (const iCount of asyncGenerator2()) {
                let temp = {};
                if (parseInt(iCount) > 0) {
                    if (sDayValue.toString() === "0") {
                        isLargeNumber = "월요일";
                        sNm = (parseInt(sDayValue) + 1).toString();
                    } else {
                        if (sDayValue.toString() === "6") {
                            sNm = "0";
                        } else {
                            sNm = (parseInt(sDayValue) + 1).toString()
                        }
                        isLargeNumber = dayList[parseInt(sDayValue)].name;
                    }
                    temp = {
                        key: sDayValue.toString() + "00:00~0" + tempHour.toString() + ":" + endMin,
                        day: isLargeNumber,
                        operation: "00:00~0" + tempHour.toString() + ":" + endMin,
                        time: sCongestionData,
                        day_of_week: sNm,
                        congestion_type: sCongestionValue,
                        opening_time: "00:00",
                        closing_time: "0" + tempHour.toString() + ":" + endMin,
                    }
                } else {
                    if (sDayValue.toString() === "0") {
                        isLargeNumber = "일요일"
                    } else {
                        isLargeNumber = dayList[parseInt(sDayValue) - 1].name;
                    }
                    temp = {
                        key: (parseInt(sDayValue) - 1).toString() + startHour + ":" + startMin + "~24:00",
                        day: isLargeNumber.toString(),
                        operation: startHour + ":" + startMin + "~24:00",
                        time: sCongestionData,
                        day_of_week: sDayValue,
                        congestion_type: sCongestionValue,
                        opening_time: startHour + ":" + startMin,
                        closing_time: "24:00",
                    }
                }
                const validation = await validateData(temp, listData);
                if (validation < 1) {
                    xData.push(temp);
                }
            }
            if (xData.length < 2) {
                message.error("중복되는 시간이 존재합니다");
            }
        } else {
            const temp = {
                key: sDayValue.toString() + startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                day: sDayData,
                operation: startHour + ":" + startMin + "~" + endHour + ":" + endMin,
                time: sCongestionData,
                day_of_week: sDayValue,
                congestion_type: sCongestionValue,
                opening_time: startHour + ":" + startMin,
                closing_time: endHour + ":" + endMin,
            }
            const validation = await validateData(temp, listData);
            if (validation < 1) {
                xData.push(temp);
            } else {
                message.error("중복되는 시간이 존재합니다");
            }
        }

        if ((xIndex === "over" && xData.length === 2) || (xIndex !== "over" && xData.length === 1)) {
            isProcess = true;
        }

        if (isProcess && fnInsert !== undefined && typeof fnInsert === "function") {
            await fnInsert([...listData, ...xData]);
        }
    }

    const insertList = async () => {
        let temp = "under";
        if (parseInt(endHour) > 24) {
            temp = "over"
        }

        if (sType !== "date") {
            if (parseInt(sEachDayValue) > 7 && parseInt(sEachDayValue) < 9) {
                rollingDate(6, temp, "weekday");
            } else if (parseInt(sEachDayValue) > 8 && parseInt(sEachDayValue) < 10) {
                rollingDate(2, temp, "saturday");
            } else if (parseInt(sEachDayValue) > 9 && parseInt(sEachDayValue) < 11) {
                rollingDate(2, temp, "sunday");
            } else {
                rollingDate(2, temp, "everyDay");
            }
        } else {
            rollingDay(temp);
        }
    }

    const validateData = async (qList, lDate) => {
        let oResult = 0;
        if (lDate.length > 0) {
            for await (const iterator of lDate) {
                if (iterator.day === qList.day) {
                    if (((parseInt(qList.opening_time) || 0) === 0 && (parseInt(qList.closing_time) || 0) === 0)) {
                        oResult = oResult + 1;
                    } else {
                        const iStartTime = moment(iterator.opening_time, "HH:mm");
                        const iEndTime = moment(iterator.closing_time, "HH:mm");
                        const openTime = moment(qList.opening_time, "HH:mm");
                        const endTime = moment(qList.closing_time, "HH:mm");
                        if (iterator.opening_time === iterator.closing_time) {
                            oResult = oResult + 1;
                        } else if (iterator.closing_time === qList.opening_time) {

                        } else {
                            if (iEndTime === endTime) {
                                oResult = oResult + 1;
                            } else {
                                if (!moment(iEndTime).isBefore(openTime)) {
                                    if (!moment(endTime).isBefore(iStartTime)) {
                                        if (qList.closing_time !== iterator.opening_time) {
                                            oResult = oResult + 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return oResult
    }

    const setEndHoursList = async () => {
        let tempList = [];
        for await (const iterator of asyncGenerator(1)) {
            tempList.push({
                key: iterator,
                value: iterator > 9 ? iterator.toString() : "0" + iterator.toString(),
                name: iterator > 9 ? (parseInt(iterator) > 24 ? `다음날(새벽) ${parseInt(iterator) - 24}시` : `${iterator}시`) : `0${iterator}시`,
            });
        }
        setEndHourList(tempList);
    }

    useEffect(() => {
        setEndHoursList();
    }, []);

    return (
        <Fragment>
            <BrowserView>
                <Style>
                    <div className="category_title" style={{ marginTop: "1%" }}>
                        <p>영업시간<span className="essential_form">(필수)</span></p>
                        <div className="select_box">
                            <div className={sType === "date" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => onChangeType("date")}>
                                <p>매일 달라요</p>
                            </div>
                            <div className={sType !== "date" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => onChangeType("")}>
                                <p>항상 같거나 주말,평일만 달라요</p>
                            </div>
                        </div>
                        <div className="select_box">
                            {sType === "date" ?
                                <select value={sDayValue} onChange={changeDayValue}>
                                    {dayList.map((item, index) => (
                                        <option key={index} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                                :
                                <select value={sEachDayValue} onChange={changeEachDayValue}>
                                    {eachDayList.map((item, index) => (
                                        <option key={index} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                            }
                        </div>
                        <div className="select_box">
                            <div className="start_hour_box">
                                <select value={startHour} onChange={changeStartHourValue}>
                                    {startHourList.map((item, index) => (
                                        <option key={item.key} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="start_minute_box">
                                <select value={startMin} onChange={changeStartMinValue}>
                                    {minuteList.map((item, index) => (
                                        <option key={item.key} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="time_arrange_box">
                                <p>~</p>
                            </div>
                            <div className="start_hour_box">
                                <select value={endHour} onChange={changeEndHourValue}>
                                    {endHourList.map((item, index) => (
                                        <option key={item.key} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="start_minute_box">
                                <select value={endMin} onChange={changeEndMinValue}>
                                    {minuteList.map((item, index) => (
                                        <option key={item.key} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="select_box">
                            {!sAlert ?
                                <select value={sCongestionValue} onChange={changeCongestionValue}>
                                    {congestionList.map((item, index) => (
                                        <option key={index} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                                :
                                <select value={sCongestionValue} onChange={changeCongestionValue}>
                                    {congestionList2.map((item, index) => (
                                        <option key={index} value={item.value}>{item.name}</option>
                                    ))}
                                </select>
                            }
                        </div>
                        <div className="location_box_line">
                            <div className="how_box">
                                <p>
                                    해당시간의 바쁨 정도를 설정해 놓으시면 상품 준비시간이 시간대에 맞게 자동으로 변경됩니다
                                </p>
                            </div>
                        </div>
                        <div className="option_select_box" onClick={checkUpInsert}>
                            <p>+ 시간 추가하기</p>
                        </div>
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <div className="category_title" style={{ marginTop: "1%" }}>
                    <p>영업시간<span className="essential_form">(필수)</span></p>
                    <div className="select_box">
                        <div className={sType === "date" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => onChangeType("date")}>
                            <p>매일 달라요</p>
                        </div>
                        <div className={sType !== "date" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => onChangeType("")}>
                            <p>매일,주말,평일</p>
                        </div>
                    </div>
                    <div className="select_box">
                        {sType === "date" ?
                            <Select value={sDayValue} onChange={changeDayValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {dayList.map((item, index) => (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                            :
                            <Select value={sEachDayValue} onChange={changeEachDayValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {eachDayList.map((item, index) => (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        }
                    </div>
                    <div className="select_box">
                        <p className="hour_text">시작</p>
                        <div className="start_hour_box">
                            <Select value={startHour} onChange={changeStartHourValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {startHourList.map((item, index) => (
                                    <Option key={item.key} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="start_minute_box">
                            <Select value={startMin} onChange={changeStartMinValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {minuteList.map((item, index) => (
                                    <Option key={item.key} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="select_box">
                        <p className="hour_text">종료</p>
                        <div className="start_hour_box">
                            <Select value={endHour} onChange={changeEndHourValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {endHourList.map((item, index) => (
                                    <Option key={item.key} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>
                        <div className="start_minute_box">
                            <Select value={endMin} onChange={changeEndMinValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {minuteList.map((item, index) => (
                                    <Option key={item.key} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="select_box">
                        <p className="hour_text">혼잡도</p>
                        {!sAlert ?
                            <Select value={sCongestionValue} onChange={changeCongestionValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {congestionList.map((item, index) => (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                            :
                            <Select value={sCongestionValue} onChange={changeCongestionValue} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                                {congestionList2.map((item, index) => (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                ))}
                            </Select>
                        }
                    </div>
                    <div className="option_select_box" onClick={checkUpInsert}>
                        <p>+ 시간 추가하기</p>
                    </div>
                </div>

            </MobileView>
        </Fragment>
    )
}

export default InsertForm;


const Style = styled.div`
`