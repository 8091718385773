/*global kakao*/
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import adasd from '../../assets/img/kakao_car.png';

const KakaoRoute = ({ sUserLat, sUserLng, sPickUp }) => {

   const sList = useRef([]);
   const startLat = useRef(null);
   const startLng = useRef(null);
   const routeList = useRef(null);

   const mapScript = document.createElement("script");

   mapScript.async = true;
   mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_KEY}&autoload=false`;

   document.head.appendChild(mapScript);

   const calculate = async () => {
      let tempList = [];
      for await (const iterator of sPickUp) {
         let temp = new window.kakao.maps.LatLng(parseFloat(iterator.lat), parseFloat(iterator.lng));
         tempList.push(temp);
      }
      routeList.current = tempList;
   }

   const onLoadMap = async () => {
      window.kakao.maps.load(async () => {
         const mapContainer = document.getElementById('map');
         let mapCenter;
         let options;

         if (sPickUp !== undefined && sPickUp !== null && sPickUp.length > 0) {
            startLat.current = await sPickUp[sPickUp.length - 1].lat;
            startLng.current = await sPickUp[sPickUp.length - 1].lng;
            await calculate();

            mapCenter = await new window.kakao.maps.LatLng(parseFloat(startLat.current), parseFloat(startLng.current));
            options = {
               center: mapCenter,
               level: 5
            };

            const map = await new window.kakao.maps.Map(mapContainer, options);

            const startSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/red_b.png';
            const arriveSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/blue_b.png';
            const currentSrc = adasd;
            const startSize = await new window.kakao.maps.Size(50, 45);
            const arriveSize = await new window.kakao.maps.Size(50, 45);
            const startOption = {
               offset: await new window.kakao.maps.Point(15, 43)
            };
            const arriveOption = {
               offset: await new window.kakao.maps.Point(15, 43)
            };

            const startImage = await new window.kakao.maps.MarkerImage(startSrc, startSize, startOption);
            const arriveImage = await new window.kakao.maps.MarkerImage(arriveSrc, arriveSize, arriveOption);
            const currentImage = await new window.kakao.maps.MarkerImage(currentSrc, arriveSize, arriveOption);
            const currentPosition = await new window.kakao.maps.LatLng(parseFloat(startLat.current), parseFloat(startLng.current));
            const startPosition = await new window.kakao.maps.LatLng(parseFloat(sPickUp[0].lat), parseFloat(sPickUp[0].lng));
            const arrivePosition = await new window.kakao.maps.LatLng(sUserLat, sUserLng);

            await new window.kakao.maps.Marker({
               map: map,
               position: currentPosition,
               draggable: false,
               image: currentImage
            });
            await new window.kakao.maps.Marker({
               map: map,
               position: startPosition,
               draggable: false,
               image: startImage
            });
            await new window.kakao.maps.Marker({
               map: map,
               position: arrivePosition,
               draggable: false,
               image: arriveImage
            });

            const linePath = routeList.current;

            const polyline = await new window.kakao.maps.Polyline({
               path: linePath,
               strokeWeight: 5,
               strokeColor: '#6490E8',
               strokeOpacity: 0.9,
               strokeStyle: 'solid'
            });

            await polyline.setMap(map);

         } else {
            mapCenter = await new window.kakao.maps.LatLng(parseFloat(sUserLat), parseFloat(sUserLng));
            options = {
               center: mapCenter,
               level: 5
            };
            await new window.kakao.maps.Map(mapContainer, options);
         }
      });
   };



   useEffect(() => {
      if (sList.current.length < 1) {
         sList.current = sPickUp;
      }
      mapScript.addEventListener("load", onLoadMap);
   }, [])

   useEffect(() => {
      let isProcess = false;

      if (sList.current.length > 0) {
         if (sList.current.length < sPickUp.length) {
            isProcess = true;
         }
      }
      if (isProcess) {
         onLoadMap();
         sList.current = sPickUp;
      }
   }, [sPickUp])


   return (
      <Style>
         <div className="mapWrapper_box">
            <div id="map" style={{ width: "100%", height: "100%" }}></div>
         </div>
      </Style>
   )
}

export default KakaoRoute;

const Style = styled.div`
    .mapWrapper_box {
        width: 100%;
        height:100vh;
    }
`;
