import React, { useState, useEffect, useRef } from 'react';
import Loader from "react-loader-spinner";
import { DatePicker, message, Table } from 'antd';
import SVG from 'react-inlinesvg';
import moment from 'moment-timezone';
import "antd/dist/antd.css";
import 'moment/locale/ko';
import Layout from '../../Layout'
import layoutHeaderLists from '../../config';
import { BrowserView, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import manual from '../../../../assets/svg/manual.svg';
import { useNavigate } from 'react-router-dom';
let amountList = [];
let percentList = [];
for (let i = 1; i <11 ; i++) {
    amountList.push({
        key: (i*1000),
        value: (i*1000).toString(),
        name: `${(i*1000)}원`,
    });
};

for (let i = 1; i <11 ; i++) {
    percentList.push({
        key: (i*5),
        value: (i*5).toString(),
        name: `${(i*5)}%`,
    });
};

const defaultFormat = "YYYY/MM/DD";
const { RangePicker } = DatePicker;

const CouponInsert = ({ oProps, fnBackList }) => {
    const [sCouponNm, setCouponNm] = useState("");
    const [sErrCouponNm, setErrCouponNm] = useState("");
    
    const [fromDate, setFromDate] = useState(moment().format(defaultFormat));
    const [toDate, setToDate] = useState(moment().add(1, 'M').format(defaultFormat));
    
    const [couponCountType, setCouponCountType] = useState("none");
    const [couponCount, setCouponCount] = useState("");
    const [sErrCouponCount, setErrCouponCount] = useState("");
    
    const [couponDiscountType, setCouponDiscountType] = useState("amount");
    const [amountDiscount, setAmountDiscount] = useState("");
    const [percentDiscount, setPercentDiscount] = useState("");
    const [maxLimitDiscount, setMaxLimitDiscount] = useState("");
    
    
    const [couponPriceType, setCouponPriceType] = useState("none");
    const [couponPrice, setCouponPrice] = useState("");
    const [sErrCouponPrice, setErrCouponPrice] = useState("");
    
    const [classCouponNm, setClassCouponNm] = useState(null);
    const [classCouponCount, setClassCouponCount] = useState(null);
    const [classCouponPrice, setClassCouponPrice] = useState(null);
    const [classCouponDiscountAmount, setClassCouponDiscountAmount] = useState(null);
    const [classCouponDiscountPercent, setClassCouponDiscountPercent] = useState(null);
    const [classCouponDiscountPercentAmount, setClassCouponDiscountPercentAmount] = useState(null);
    const navigate = useNavigate()
    const backList = async () => {
        navigate(-1)
    }

    const onOperatingDate = (value, dateString) => {
        if(dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== ""){
            if(fromDate === moment().format(defaultFormat)){
                if(dateString[1] !== undefined && dateString[1] !== null && dateString[1] !== ""){
                    setFromDate(dateString[0]);
                    setToDate(dateString[1]);
                } else {
                    setFromDate(dateString[0]);
                }
            } else {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
            }
        } else {
            setFromDate(moment().format(defaultFormat));
            setToDate(moment().add(1, 'days').format(defaultFormat));
        }
    }

    const onChangeCountValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setErrCouponCount("");
            setClassCouponCount("input_action");
            setCouponCount(value);
        }
    }
    const onChangePriceValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setClassCouponPrice("input_action");
            setErrCouponPrice("");
            setCouponPrice(value);
        }
    }

    const onChangeAmountValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setAmountDiscount(value);
        }
    }

    const onChangePercentValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            if(parseInt(value) > 100){
            } else {
                setPercentDiscount(value);
            }
        }
    }

    const onChangeMaxPercentValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setMaxLimitDiscount(value);
        }
    }
    
    const onChangeCouponNm = (event) => {
        setErrCouponNm("");
        setClassCouponNm("input_action");
        setCouponNm(event.target.value);
    }
    
    const inputClassCouponNm = () => {
        if(classCouponNm !== "input_wrong"){
            setClassCouponNm("input_action");
        }
        if(classCouponCount !== "input_wrong"){
            setClassCouponCount(null);
        }
        if(classCouponPrice !== "input_wrong"){
            setClassCouponPrice(null);
        }
        if(classCouponDiscountAmount !== "input_wrong"){
            setClassCouponDiscountAmount(null);
        }
        if(classCouponDiscountPercent !== "input_wrong"){
            setClassCouponDiscountPercent(null);
        }
        if(classCouponDiscountPercentAmount !== "input_wrong"){
            setClassCouponDiscountPercentAmount(null);
        }
    }
    const inputClassCouponCount = () => {
        if(classCouponNm !== "input_wrong"){
            setClassCouponNm(null);
        }
        if(classCouponCount !== "input_wrong"){
            setClassCouponCount("input_action");
        }
        if(classCouponPrice !== "input_wrong"){
            setClassCouponPrice(null);
        }
        if(classCouponDiscountAmount !== "input_wrong"){
            setClassCouponDiscountAmount(null);
        }
        if(classCouponDiscountPercent !== "input_wrong"){
            setClassCouponDiscountPercent(null);
        }
        if(classCouponDiscountPercentAmount !== "input_wrong"){
            setClassCouponDiscountPercentAmount(null);
        }
    }
    const inputClassCouponPrice = () => {
        if(classCouponNm !== "input_wrong"){
            setClassCouponNm(null);
        }
        if(classCouponCount !== "input_wrong"){
            setClassCouponCount(null);
        }
        if(classCouponPrice !== "input_wrong"){
            setClassCouponPrice("input_action");
        }
        if(classCouponDiscountAmount !== "input_wrong"){
            setClassCouponDiscountAmount(null);
        }
        if(classCouponDiscountPercent !== "input_wrong"){
            setClassCouponDiscountPercent(null);
        }
        if(classCouponDiscountPercentAmount !== "input_wrong"){
            setClassCouponDiscountPercentAmount(null);
        }
    }
    const inputClassCouponDiscount = () => {
        if(classCouponNm !== "input_wrong"){
            setClassCouponNm(null);
        }
        if(classCouponCount !== "input_wrong"){
            setClassCouponCount(null);
        }
        if(classCouponPrice !== "input_wrong"){
            setClassCouponPrice(null);
        }
        if(classCouponDiscountAmount !== "input_wrong"){
            setClassCouponDiscountAmount("input_action");
        }
        if(classCouponDiscountPercent !== "input_wrong"){
            setClassCouponDiscountPercent(null);
        }
        if(classCouponDiscountPercentAmount !== "input_wrong"){
            setClassCouponDiscountPercentAmount(null);
        }
    }
    const inputClassCouponDiscountPercent = () => {
        if(classCouponNm !== "input_wrong"){
            setClassCouponNm(null);
        }
        if(classCouponCount !== "input_wrong"){
            setClassCouponCount(null);
        }
        if(classCouponPrice !== "input_wrong"){
            setClassCouponPrice(null);
        }
        if(classCouponDiscountAmount !== "input_wrong"){
            setClassCouponDiscountAmount(null);
        }
        if(classCouponDiscountPercent !== "input_wrong"){
            setClassCouponDiscountPercent('input_action');
        }
        if(classCouponDiscountPercentAmount !== "input_wrong"){
            setClassCouponDiscountPercentAmount(null);
        }
    }
    const inputClassCouponDiscountPercentAmount = () => {
        if(classCouponNm !== "input_wrong"){
            setClassCouponNm(null);
        }
        if(classCouponCount !== "input_wrong"){
            setClassCouponCount(null);
        }
        if(classCouponPrice !== "input_wrong"){
            setClassCouponPrice(null);
        }
        if(classCouponDiscountAmount !== "input_wrong"){
            setClassCouponDiscountAmount(null);
        }
        if(classCouponDiscountPercent !== "input_wrong"){
            setClassCouponDiscountPercent(null);
        }
        if(classCouponDiscountPercentAmount !== "input_wrong"){
            setClassCouponDiscountPercentAmount("input_action");
        }
    }

    const insertCoupon = async () => {
        let process1 = false;
        let process2 = false;
        let process3 = false;
        let process4 = false;

        if(sCouponNm === ""){
            message.error("쿠폰이름을 입력해주세요.");
        } else {
            process1 = true;
        }
        
        if(couponCountType === "count"){
            if(couponCount === ""){
                message.error("쿠폰 수량을 입력해주세요.");
            } else {
                process2 = true;
            }
        } else {
            process2 = true;
        }

        if(couponDiscountType === "amount"){
            if(amountDiscount === ""){
                message.error("할인 금액을 입력해주세요.");
            } else {
                process3 = true;
            }
        } else {
            if(percentDiscount === "" || parseInt(percentDiscount) <= 0){
                message.error("할인 %을 입력해주세요.");
            } else {
                if(maxLimitDiscount === "" || parseInt(maxLimitDiscount) <= 0){
                    message.error("할인 %을 입력해주세요.");
                } else {
                    process3 = true;
                }
            }
        }

        if(couponPriceType === "min"){
            if(couponPrice === ""){
                message.error("최소 주문 금액을 입력해주세요.");
            } else {
                process4 = true;
            }
        } else {
            process4 = true;
        }
        

        if(process1 && process2 && process3 && process4){
            const oData = {
                cNm: sCouponNm,
                couponCount,
                countType: couponCountType,
                cAmount: amountDiscount,
                limitAmount: couponPriceType,
                sLimitAmount: couponPrice,
                couponDiscountType,
                percentDiscount,
                maxLimitDiscount,
                store_id: oProps.UserConfigReducer.StoreID,
                startDate: fromDate,
                endDate: toDate,
                description: "사장님창구에서 생성"
            }
            const oResponse = await oProps.oManager.fDoAxios("/coupon/insertCoupon", "post", "login", oData);
            if(oResponse !== undefined){
                if(oResponse.resultCd === "0000"){
                    message.info("쿠폰이 정상적으로 등록되었습니다.");
                    backList();
                } else {
                    message.error(oResponse.resultMsg);
                }
            }
        }
    }

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.coupon}>
            <BrowserView>
            <Style>
        <div className="container">
            <div className="category_title">
                <p>쿠폰이름을 입력해주세요</p>
                <input 
                    type="text" 
                    placeholder="쿠폰이름을 입력해주세요(10자이내)"
                    value={sCouponNm} 
                    onChange={onChangeCouponNm}
                    className={classCouponNm} 
                    onClick={inputClassCouponNm} 
                />
                <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{sErrCouponNm}</p>
                <div className="location_box_line" style={{marginTop: "10px"}}>
                    <div className="how_box">
                        <p>고객에게 보여지는 쿠폰이름입니다 <br />ex) 쿨썸머 할인 쿠폰</p>
                    </div>
                </div>
            </div>
            <div className="category_title">
                <p>어떤 방식의 할인을 원하시나요?</p>
                <div className="select_box">
                    <div className={couponDiscountType === "amount" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponDiscountType("amount")}>
                        <p>정액할인</p>
                    </div>
                    <div className={couponDiscountType !== "amount" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponDiscountType("percent")}>
                        <p>정률할인</p>
                    </div>
                </div>
                {couponDiscountType === "amount" ?
                    <input 
                        type="text" 
                        placeholder="할인금액을 입력해주세요"
                        value={amountDiscount} 
                        onChange={onChangeAmountValue}
                        className={classCouponDiscountAmount} 
                        onClick={inputClassCouponDiscount} 
                    />
                    :
                    <>
                        <input 
                            type="text" 
                            placeholder="할인 %를 입력해주세요"
                            value={percentDiscount} 
                            onChange={onChangePercentValue}
                            className={classCouponDiscountPercent} 
                            onClick={inputClassCouponDiscountPercent} 
                        />
                        <input 
                            type="text" 
                            placeholder="최대할인금액을 입력해주세요"
                            value={maxLimitDiscount} 
                            onChange={onChangeMaxPercentValue}
                            className={classCouponDiscountPercentAmount} 
                            onClick={inputClassCouponDiscountPercentAmount} 
                        />
                        <div style={{position: "absolute", bottom: 75, right: 10}}>
                            <p>%</p>
                        </div>
                    </>
                }
            </div>

            <div className="category_array">
                <p>쿠폰기간</p>
                <div className="search_date">
                    <RangePicker
                        className="date_picker"
                        style={{width: "100%", borderRadius: "4px", fontSize: "0px"}}
                        format={defaultFormat}
                        value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                        onChange={onOperatingDate}
                    />
                </div>
                <div className="location_box_line" style={{marginTop: "10px"}}>
                    <div className="how_box">
                        <p>기간 종료시, 쿠폰발행(노출)과 사용이 모두 제한됩니다. <br />발행시 사용가능기간이 고객에게 알려집니다.</p>
                    </div>
                </div>
            </div>
            <div className="category_price_line">
                <p>쿠폰수량을 입력해주세요</p>
                <div className="select_box">
                    <div className={couponCountType === "count" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponCountType("count")}>
                        <p>정해진 수량</p>
                    </div>
                    <div className={couponCountType !== "count" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponCountType("none")}>
                        <p>제한없음</p>
                    </div>
                </div>
                {couponCountType === "count" &&
                    <>
                    <div style={{position:"relative", marginTop:"2%", marginBottom: "2%"}}>
                        <input 
                            type="text" 
                            placeholder="쿠폰수량 입력" 
                            value={couponCount} 
                            onChange={onChangeCountValue}
                            className={classCouponCount} 
                            onClick={inputClassCouponCount} 
                        />
                        <p className="product_price_box">개</p>
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{sErrCouponCount}</p>
                    </>
                }
                <div className="location_box_line" style={{marginTop: "10px"}}>
                    <div className="how_box">
                        <p>발행수량 제한시 기간제한 필수!<br />수량 제한시 발행일과 상관없이 위 선택한 기간동안 사용이 가능합니다.</p>
                    </div>
                </div>
            </div>
            <div className="category_price_line">
                <p>쿠폰 사용시 최소주문금액이 있나요?</p>
                <div className="select_box">
                    <div className={couponPriceType === "min" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponPriceType("min")}>
                        <p>네</p>
                    </div>
                    <div className={couponPriceType !== "min" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponPriceType("none")}>
                        <p>아니오</p>
                    </div>
                </div>
                {couponPriceType === "min" &&
                    <>
                    <div style={{position:"relative", marginTop:"2%", marginBottom: "2%"}}>
                        <input 
                            type="text" 
                            placeholder="최소 구매금액 입력" 
                            value={couponPrice} 
                            onChange={onChangePriceValue}
                            className={classCouponPrice} 
                            onClick={inputClassCouponPrice} 
                        />
                        <p className="product_price_box">원</p>
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{sErrCouponPrice}</p>
                    </>
                }
            </div>
            
            <div className="insert_box" onClick={insertCoupon}>
                <p>상품등록하기</p>
            </div>
        </div></Style>
            </BrowserView>
            <MobileView>
        <Mstyle>
            <div className="buisness">
                <div className="businessTitle">
                    <p>쿠폰등록</p>
                    <div className="tip">
                        <SVG src={manual} width="18px" height="18px" />
                        <span>고객님들에게 할인기능을 제공합니다.</span>
                    </div>
                </div>
                <div className="inputText">
                    <h3>쿠폰이름을 입력해주세요</h3>
                    <input 
                        type="text" 
                        placeholder="쿠폰이름을 입력해주세요(10자이내)" 
                        value={sCouponNm} 
                        onChange={onChangeCouponNm}
                        className={classCouponNm} 
                        onClick={inputClassCouponNm} 
                    />
                </div>
                <div className="inputText">
                    <h3>어떤 방식의 할인을 원하시나요?</h3>
                    <div className="select_box" style={{marginBottom: "3%"}}>
                        <div className={couponDiscountType === "amount" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponDiscountType("amount")}>
                            <p>정액할인</p>
                        </div>
                        <div className={couponDiscountType !== "amount" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponDiscountType("percent")}>
                            <p>정률할인</p>
                        </div>
                    </div>
                    {couponDiscountType === "amount" ?
                        <input 
                            type="text" 
                            placeholder="할인금액을 입력해주세요"
                            value={amountDiscount} 
                            onChange={onChangeAmountValue}
                            className={classCouponDiscountAmount} 
                            onClick={inputClassCouponDiscount} 
                        />
                    :
                        <>
                            <input 
                                type="text" 
                                placeholder="할인 %를 입력해주세요"
                                value={percentDiscount} 
                                onChange={onChangePercentValue}
                                className={classCouponDiscountPercent} 
                                onClick={inputClassCouponDiscountPercent} 
                            />
                            <input 
                                style={{marginTop: "3%"}}
                                type="text" 
                                placeholder="최대할인금액을 입력해주세요"
                                value={maxLimitDiscount} 
                                onChange={onChangeMaxPercentValue}
                                className={classCouponDiscountPercentAmount} 
                                onClick={inputClassCouponDiscountPercentAmount} 
                            />
                        </>
                    }
                </div>
                <div className="inputText">
                    <h3>쿠폰 사용기간</h3>
                </div>
                <div className="search_date">
                    <RangePicker
                        className="date_picker"
                        style={{width: "100%", borderRadius: "4px"}}
                        format={defaultFormat}
                        value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                        onChange={onOperatingDate}
                    />
                </div>
                <div className="inputText">
                    <h3>쿠폰수량을 입력해주세요</h3>
                    <div className="select_box" style={{marginBottom: "3%"}}>
                        <div className={couponCountType === "count" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponCountType("count")}>
                            <p>정해진 수량</p>
                        </div>
                        <div className={couponCountType !== "count" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponCountType("none")}>
                            <p>제한없음</p>
                        </div>
                    </div>
                    {couponCountType === "count" &&
                        <>
                        <div style={{position:"relative", marginTop:"2%", marginBottom: "5%"}}>
                            <input 
                                type="text" 
                                placeholder="쿠폰수량 입력" 
                                value={couponCount} 
                                onChange={onChangeCountValue}
                                className={classCouponCount} 
                                onClick={inputClassCouponCount} 
                            />
                        </div>
                        </>
                    }
                </div>
                <div className="inputText">
                    <p>쿠폰 사용시 최소주문금액이 있나요?</p>
                    <div className="select_box" style={{marginBottom: "3%"}}>
                        <div className={couponPriceType === "min" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponPriceType("min")}>
                            <p>네</p>
                        </div>
                        <div className={couponPriceType !== "min" ? "selected_box_wrapper" : "unselected_box_wrapper"} onClick={() => setCouponPriceType("none")}>
                            <p>아니오</p>
                        </div>
                    </div>
                    {couponPriceType === "min" &&
                        <>
                        <div style={{position:"relative", marginTop:"2%", marginBottom: "2%"}}>
                            <input 
                                type="text" 
                                placeholder="최소 구매금액 입력" 
                                value={couponPrice} 
                                onChange={onChangePriceValue}
                                className={classCouponPrice} 
                                onClick={inputClassCouponPrice} 
                            />
                        </div>
                        </>
                    }
                </div>
                <div className="last">
                    <button className="finishButoon" onClick={insertCoupon}>
                        등록하기
                    </button>
                </div>
            </div>
        </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default CouponInsert;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .essential_form {
        font-size: 0.8rem;
        font-weight: 400;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }
    
    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }

    .menu_container {
        margin-top: 10px;
        border-radius: 4px;

        .tab {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw; 
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    
    .content_container {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;

            p {
                margin-left: 5%;
                font-size: 1rem;
                font-weight: 700;
            }
        }
        
        .menuContent {
            height: 30vh;

            .menu {
                display: flex;
                align-items: center;
                padding: 2%;

                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4vw;
                    height: 7vh;
                    margin-right: 16px;
                    border-radius: 4px;

                    .box_image {
                        width: 4vw;
                        height: 7vh;
                        border-radius: 5%;
                    }
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 30vw;

                    h3 {
                        margin-left: 5%;
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .content_detail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 2%;
                margin-left: 5%;
                margin-right: 5%;

                p {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: #3B3B46;
                }
            }

            .sold_out {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 5vw;
                height: 4vh;
                background : #DD1212;
                border-radius: 5px;
                margin-left: auto;
                cursor: pointer;

                h2 {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            
        }
        .page_nation {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 7vh;
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 100px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        width: 42vw;
    }

    .pictures_title {
        height: 80px;

        .location_box_line {
            height: 50px;

            .how_box {
                display: flex;
                align-items: center;
                height: 50px;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
    .pictures_list_title {
        height: 25vh;
    }
    
    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .category_title {
        position: relative;
        margin-bottom: 3%;

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        input {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 2px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        
        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 72px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            position: relative;
            top: 14px;
            bottom: 0;
            right: 0;
            border: 0;
            outline: 0;
            left: 90%;
        }

        textarea {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            min-height: 15vh;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        textarea::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .set_location_box {
            display: flex;
            align-items: center;
            height: 106px;

            .set_location_button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 6vw;
                height: 6vh;
                border-radius: 4px;
                background: #617BE3;
                cursor: pointer;
                p {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }


    }
    .location_box_line {
        height: 60px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 60px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }
    
    .location_box_line2 {
        height: 50px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .category_array {
        margin-bottom: 3%;

        .search_date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2%;

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 9%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }

            input {
                padding: 12px;
            }
        }

    }

    .category_price_line {
        position: "relative";
        margin-bottom: 5%;

        .discount_detail_box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            height: 40px;
            background: #F1F3F7;
            border-radius: 5px;
             
            p {
                margin-left: 15px;
                color: #DD1212;
                font-size: 0.6rem;
                font-weight: 500;
            }
        }

        .product_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 0;
        }
        .product_discount_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 25%;
        }

        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
            
        }

        .classDiscountPrice {
            background: #F1F3F7;
            border: 1px solid #617BE3;
        }

        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 2px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .discount_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right:0;
            background: #001E62;
            padding: 11px;
            width: 8vw;

            .discount_text {
                font-size: 16px;
                font-weight: bold;
                color: #fff; 
            }

            p {
                font-size: 10px;
                font-weight: normal;
                color: #dfdfe3; 
            }

        }
    }

    .select_box {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .start_hour_box_null {
            width: 49%;
        }
        .start_minute_box_null {
            width: 49%;
        }
        .start_hour_box {
            width: 28%;
        }
        .start_minute_box {
            width: 19%;
        }
        .time_arrange_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4%;
        }
        
        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7vh;
        height: 6vh;
        background: #fff;
        border : 1px solid #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .insert_box:hover {
        background: #617BE3;
        cursor: pointer;

        p {
            color: #fff;
        }
    }

    .option_select_box {
        margin-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 50px;
        border : 1px solid #617BE3;
        border-radius: 4px;
        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .option_select_box:hover {
        background : #617BE3;

        p {
            color: #fff;
        }
    }

    .limit_select_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .delete_selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2%;
            width: 20%;
            height: 50px;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #DD1212;
                text-decoration: underline;
            }
        }

        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }

    .delete_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DD1212;
        border-radius: 5px;
        cursor: pointer;
        height: 30px;
        width: 70px;
        font-size: 0.8rem;
        font-weight: 400;
        color: #DD1212;
    }

    .delete_box:hover {
        background: #DD1212;
        color: #fff
    }
`

const Mstyle = styled.div`
    .tip {
        position: relative;
        display: flex;
    }

    .search_date {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2%;

        .delete_selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;
            height: 5vh;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #DD1212;
                text-decoration: underline;
            }
        }

        input {
            padding: 12px;
        }
    }

    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .businessTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 2px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 78px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }

        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .buisness, .number {
        padding: 20px 16px;
        margin-bottom: 8px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .last {
        width: 100%;
        padding: 24px 0px 32px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  
   
    .select_box {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .hour_text {
            width: 20vw;
            font-size: 0.8rem;
            font-weight: 400;
        }
        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .start_hour_box_null {
            width: 49%;
        }
        .start_minute_box_null {
            width: 49%;
        }
        .start_hour_box {
            width: 50%;
        }
        .start_minute_box {
            width: 50%;
        }
        .time_arrange_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4%;
        }
        
        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }
`;