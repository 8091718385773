import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Loader from "react-loader-spinner";
import moment from "moment-timezone";
import "moment/locale/ko";
import "../../../assets/fonts/font.css";
import PosGuide from "../posGuide/MainPosGuide";
import Modal from "../../modal/MainModal";
import ModalSmartStore from "../../modal/ModalSmartStore";

import notice from "../../../assets/img/ceoscreen/notice.png";
import rightArrow from "../../../assets/img/ceoscreen/rightArrow.png";
import posImg from "../../../assets/img/ceoscreen/posImg.png";
import tip01 from "../../../assets/img/ceoscreen/tip01.png";
import tip04 from "../../../assets/img/ceoscreen/tip04.png";
import tip05 from "../../../assets/img/ceoscreen/tip05.png";

import { WebRoute } from "../../../navigation/WebRoutes";

const ContentsPage = (oProps) => {
  const [noticeLoad, setNoticeLoad] = useState(true);
  const [nList, setList] = useState([]);
  const [modal, setModal] = useState(true);
  const [smartStoreModal, setSmartStoreModal] = useState(true);

  const goProductPath = async () => {
    let sDomainUrl = "https://ceo.throo.co.kr";
    if (
      process.env !== undefined &&
      process.env.REACT_APP_CEO_WEBURL !== undefined
    ) {
      sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
    }
    window.open(sDomainUrl + "/selfmanage/commercial", "_blank");
  };

  const openCEOKakao = () => {
    if (isMobile) {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    } else {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    }
  };

  const getList = async () => {
    const oResponse = await oProps.oManager.fDoAxios(
      "/home/contentList",
      "get",
      null,
      null
    );
    if (oResponse !== undefined) {
      if (oResponse.resultCd === "0000") {
        if (oResponse.resultData.length > 0) {
          setList(oResponse.resultData);
          setNoticeLoad(false);
        }
      }
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <ContentsStyle>
      <div className="content">
        {/* {modal == true ? <Modal /> : null} */}
        <div className="container">
          <div className="contentPage">
            <div className="left">
              <div className="leftContent">
                <h2>스루 사장님이 꼭 알아야 할 정보</h2>
                <div className="tipContent">
                  <Link
                    to={{
                      pathname: "/ceotip",
                      page_id: "stamp",
                      search: "stamp",
                    }}
                  >
                    <div className="tip">
                      <div className="img">
                        <img src={tip05} alt="스탬프 이미지" width="100%" />
                      </div>
                      <div className="content">
                        <div className="icon">TIP</div>
                        <p>
                          스탬프 기능을 활용하여 우리 매장 단골 고객을
                          만들어보세요
                        </p>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/ceotip",
                      page_id: "coupon",
                      search: "coupon",
                    }}
                  >
                    <div className="tip">
                      <div className="img">
                        <img src={tip04} alt="할인쿠폰 이미지" width="100%" />
                      </div>
                      <div className="content">
                        <div className="icon">TIP</div>
                        <p>할인쿠폰으로 고객의 주문을 유도해 보세요</p>
                      </div>
                    </div>
                  </Link>
                  <Link
                    to={{
                      pathname: "/ceotip",
                      page_id: "sales",
                      search: "sales",
                    }}
                  >
                    <div className="tip">
                      <div className="img">
                        <img src={tip01} alt="스루정산 이미지" width="100%" />
                      </div>
                      <div className="content">
                        <div
                          className="icon"
                          style={{ backgroundColor: "#FF9900" }}
                        >
                          FAQ
                        </div>
                        <p>스루의 정산 주기는 어떻게 되나요?</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <Link to={WebRoute.CEOTIPSUB}>
                  <div className="moreBtn">
                    더보기{" "}
                    <img
                      src={rightArrow}
                      alt="더보기버튼"
                      width="12px"
                      height="12px"
                    />
                  </div>
                </Link>
              </div>
              <div className="leftContent">
                <h2>공지사항</h2>
                {noticeLoad ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "30vh",
                      alignItems: "center",
                      border: "1px solid #ECEEF2",
                      borderRadius: 4,
                    }}
                  >
                    <Loader
                      type="BallTriangle"
                      color="#00BFFF"
                      height={80}
                      width={80}
                      timeout={9000000}
                    />
                  </div>
                ) : (
                  <Link to={WebRoute.NOTICE}>
                    <div className="notice">
                      <ul>
                        {nList.map((item, index) => {
                          return (
                            <li key={index}>
                              <ul
                                className="nTitle"
                                style={{
                                  marginBottom:
                                    nList.length - 1 === index ? "0" : "10",
                                }}
                              >
                                <li>{item.title}</li>
                                <li>{moment(item.created_at).format("LL")}</li>
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Link>
                )}
                <Link to={WebRoute.NOTICE}>
                  <div className="moreBtn">
                    공지사항 더보기{" "}
                    <img
                      src={rightArrow}
                      alt="더보기버튼"
                      width="12px"
                      height="12px"
                    />
                  </div>
                </Link>
              </div>
              <div className="leftContent">
                <h2>스루 이용가이드</h2>
                <PosGuide />
                <Link to={WebRoute.POSGUIDE}>
                  <div className="moreBtn">
                    이용가이드 더보기
                    <img
                      src={rightArrow}
                      alt="더보기버튼"
                      width="12px"
                      height="12px"
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className="right">
              <div className="rightContent">
                <h2>스루포스</h2>
                <Link to={WebRoute.DOWNLOAD}>
                  <div className="posDownload">
                    <div className="imgPos">
                      <img src={posImg} alt="포스이미지" />
                    </div>
                    <div className="posNotice">
                      <h3>포스 다운로드</h3>
                      <p>
                        우리 매장에 스루 포스를 설치
                        <br /> 해보세요.
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="rightContent">
                <div className="promotionContent">
                  <div className="promotion">
                    <h3>
                      드라이브스루를 시작하는
                      <br />
                      우리 매장을 고객에게 알려주세요!
                    </h3>
                    <div className="promotionImg">
                      <img
                        src={notice}
                        alt="알림 아이콘"
                        width="46px"
                        height="46px "
                      />
                    </div>
                  </div>
                  <div className="promotionBtn" onClick={() => goProductPath()}>
                    <p>홍보물 신청하러 가기</p>
                  </div>
                </div>
              </div>
              <div className="rightContent">
                <h2>궁금한 사항은 스루에게 물어보세요</h2>
                <div className="question">
                  <h3>1670-5324</h3>
                  <p>(운영시간 평일 09시-18시)</p>
                </div>
                <div className="questionBtn">
                  <button>
                    <Link to={WebRoute.SERVICE}>고객센터</Link>
                  </button>
                  <button onClick={openCEOKakao}>카카오톡 상담</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentsStyle>
  );
};

export default ContentsPage;

export const ContentsStyle = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .container {
    width: 1120px;
    margin: 0 auto;
  }
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  ul,
  button {
    font-family: "NanumSquare";
    transform: skew(-0.1deg);
    font-weight: 900;
  }
  ul,
  li {
    list-style: none;
  }

  /* contentPage */
  .contentPage {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 182px;
  }

  /* left */
  .left {
    flex: 0 0 65%;
    padding: 0 40px;
  }
  .leftContent {
    margin-bottom: 40px;

    h2 {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 900;
      color: #000;
    }
  }

  /* left - 스루 사장님이 꼭 알아야 할 정보 */
  .tip {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 17px 23px;
    border: 1px solid #eceef2;
    border-radius: 4px;

    .img img {
      width: 106px;
      min-height: 76px;
      max-height: 76px;
    }
    .content {
      background-color: #fff;
      width: 100%;
      text-align: left;
      margin-left: 11px;
      a {
      }

      .icon {
        width: 36px;
        height: 22px;
        padding-top: 1px;
        margin-bottom: 16px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "NanumSquare";
        transform: skew(-0.1deg);
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        background-color: #1a7cff;
        color: #fff;
      }
      p {
        font-size: 16px;
        font-weight: 700;
        color: #000;
      }
    }
  }

  /* left - 공지사항 */
  .notice {
    padding: 24px 16px;
    border: 1px solid #eceef2;
    border-radius: 4px;

    .nTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 48px;

      li {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: #888888;
      }
      li:nth-child(1) {
        max-width: 470px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 18px;
        color: #000;
      }
    }
  }
  .leftContent .moreBtn {
    width: 100%;
    margin-top: 16px;
    padding: 9px 0;
    border: 1px solid #d7dbe6;
    border-radius: 4px;
    background: none;
    font-size: 16px;
    font-weight: 900;
    color: #888888;
    outline: none;
    text-align: center;
    transform: skew(-0.1deg);
  }

  /* left - 문제가 발생하셨나요? */
  .guide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .guideContent {
      flex: 0 0 49%;

      .box img {
        width: 100%;
        min-height: 184px;
        max-height: 184px;
      }
      h3 {
        font-size: 18px;
        font-weight: 700;
        color: #000;
        margin: 16px 0 20px 0;
      }
    }
  }

  /* right */
  .right {
    flex: 0 0 35%;
    padding-right: 40px;
  }
  .rightContent {
    margin-bottom: 38px;

    h2 {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 900;
      color: #000;
    }
  }

  /* right-포스다운로드 */
  .posDownload {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #eceef2;
    border-radius: 4px;

    .imgPos {
      width: 104px;
      height: 104px;

      img {
        width: 100%;
      }
    }
    .posNotice {
      margin-left: 12px;

      h3 {
        font-size: 16px;
        font-weight: 900;
        margin-bottom: 8px;
      }
      p {
        font-size: 16px;
        font-weight: 700;
        color: #888888;
        line-height: 24px;
      }
    }
  }

  /* right-홍보물 신청 */
  .promotionContent {
    border: 1px solid #eceff2;
    border-radius: 4px;
  }
  .promotion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 20px 24px;

    h3 {
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
    }
  }
  .promotionBtn {
    cursor: pointer;
    padding: 0px 16px 24px 16px;
  }
  .promotionBtn p {
    width: 100%;
    padding: 12px 0;
    background-color: #1a7cff;
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    border: none;
    outline: none;
    border-radius: 4px;
    text-align: center;
  }

  /* right-고객센터 */
  .question {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 24px;

    h3 {
      font-size: 32px;
      font-weight: 900;
    }
    p {
      font-size: 14px;
      font-weight: 700;
      color: #888888;
    }
  }
  .questionBtn button {
    margin-right: 12px;
    padding: 4px 16px;
    background-color: #191f28;
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    border: none;
    outline: none;

    a {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }
  }
`;
