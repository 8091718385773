/*global kakao*/
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Result, Typography } from "antd";
import Loader from "react-loader-spinner";
import 'antd/dist/antd.css';

import KakaoRoute from './AppKakaoRoute';

let checkTime;
const { Paragraph } = Typography;
const UserLocation = ({ oProps }) => {
   const [wrongAccess, setWrongAccess] = useState(true);
   const [storeId, setStoreId] = useState("");
   const [orderId, setOrderId] = useState("");

   const [pickUp, setPickUp] = useState([]);
   const [userLat, setUserLat] = useState(37.56637919891677);
   const [userLng, setUserLng] = useState(126.97914589375286);

   const navigate = useNavigate();
   const location = useLocation()
   const getLocation = async (sIndex, aIndex) => {
      //await clearInterval(checkTime);
      //checkTime = setInterval(() => {
      asyncData(sIndex, aIndex)
      //}, 5000)
   }

   const asyncData = async (sIndex, aIndex) => {
      let store_id = storeId
      let order_id = orderId
      if (sIndex !== undefined && sIndex !== null) {
         store_id = sIndex
      }
      if (aIndex !== undefined && aIndex !== null) {
         order_id = aIndex
      }
      const oData = {
         store_id,
         order_id,
      }
      const oResponse = await oProps.oManager.fDoAxios("/app/user/location/v2", "post", null, oData);
      if (oResponse !== undefined && oResponse !== null) {
         setPickUp(oResponse.pickUpRoute);
         setUserLat(oResponse.originLat);
         setUserLng(oResponse.originLng);
         setWrongAccess(false);
      }
   }

   const validate = async () => {
      if (wrongAccess) {
         if (location.search !== null && location.search !== undefined && location.search !== "") {
            let str = await location.search;
            str = await str.substr(2, str.length);
            str = await str.split("?@=");
            if (str.length > 1) {
               setStoreId(str[0]);
               setOrderId(str[1]);
               getLocation(str[0], str[1]);
            } else {
               setWrongAccess(true);
            }

         } else {
            setWrongAccess(true);
         }
      }
   }

   useEffect(() => {
      validate();
   }, [wrongAccess, oProps]);

   return (
      <div>
         {wrongAccess ?
            <div style={{ display: "flex", justifyContent: "center", height: "8vh", alignItems: "center" }}>
               <Loader type="BallTriangle" color="#13166b" height={40} width={40} timeout={9000000} />
            </div>
            :
            <KakaoRoute
               sUserLat={userLat}
               sUserLng={userLng}
               sPickUp={pickUp}
            />
         }
      </div>
   )
}

export default UserLocation;