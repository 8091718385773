import React from 'react';
import styled from 'styled-components';

// 이미지
import walkThroo01 from '../../../../assets/img/guide/walkThroo01.png';
import walkThroo02 from '../../../../assets/img/guide/walkThroo02.png';
import walkThroo03 from '../../../../assets/img/guide/walkThroo03.png';
const OnlyGuide = (oProps) => {

    return (
        <D_onlyGuide>
            <div className="ceoTip">
                <div className="container">
                    <div className="cTip">
                        <h3>스루 가이드</h3>
                        <h1>매장 방문포장 서비스 워크 스루 오픈 안내</h1>
                        <p>
                            안녕하세요 사장님 <br />
                            스루입니다.<br /><br />

                            바쁜 시간대 차까지 전달이 어려운 매장을 위해 워크 스루 서비스가 오픈됨을 알려드립니다.<br />
                            워크 스루 서비스를 이용하면 고객이 도착 전 주문과 결제를 미리 받기 때문에 회전율과 영업효율이 높아집니다.<br /><br />

                            워크 스루 설정 방법 및 주요 내용은 아래를 참고해 주세요.
                        </p>
                        <div className="tip">
                            <h2>영업시간 설정</h2>
                            <img src={walkThroo01} alt="얼리어탑터 사장님 프로모션 이미지" width="100%" />
                            <p>상품 준비 시간 설정에서 고 객차까지 전달이 불가능한 시간대를 ‘워크스루만’으로 설정하시면 됩니다.</p>
                            <h2>주문서</h2>
                            <img src={walkThroo02} alt="얼리어탑터 사장님 프로모션 이미지" width="100%" />
                            <p>주문 접수 화면뿐만 아니라 프린트된 주문서에서 드라이브스루와 워크 스루를 쉽게 구분하실 수 있습니다.</p>
                            <h2>앱 화면</h2>
                            <img src={walkThroo03} alt="얼리어탑터 사장님 프로모션 이미지" width="100%" />
                            <p>고객 앱에서는 위 화면처럼 보이며, 드라이브스루 사용자와는 다른 이용 환경이 제공됩니다.</p>
                        </div>
                        <p style={{ marginTop: "34px", paddingTop: "20px", borderTop: "2px solid #EEEEEE" }}>😊 지금까지 피크시간 대처 및 1인 운영 때문에 입점을 고민하시던 사장님들은 바쁜 시간에는 워크 스루를 이용해 일손을 덜고 비수 시간에는 더 많은 운전자 고객을 만나서 추가 매출을 올려보세요.</p>
                        <div className="notice">
                            <p style={{color: "#F45452"}}>
                                *유의사항 <br />
                                -워크 스루 서비스는 스루 앱 최신 업데이트 버전부터 순차적으로 반영됩니다. 사용자의 앱 업데이트 여부에 따라 일부 노출되지 않을 수 있습니다.
                            </p>
                            <p>-워크 스루 고객은 픽업 존이 아닌 매장에서 상품을 전달해 주세요.</p>
                        </div>
                    </div>
                </div>
            </div>
        </D_onlyGuide>
    )
}

export default OnlyGuide;
export const D_onlyGuide = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 702px;
        margin: 0 auto;
    }
    img {
        margin: 12px 0;
    }
    .cTip {
        margin-top: 106px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 26px 0;
            font-size: 32px;
            font-weight: 900;
            line-height: 48px;
        }
        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .tip {
        h2 {
            margin: 34px 0 10px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;

            span {
                color: #8E929F;
            }
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .notice {
        padding-top: 20px;
        border-top: 2px solid #EEEEEE;
    }
`