import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_posGuide from "./mobile/M_posGuide";
import D_posGuide from "./desktop/D_posGuide";

const MainPosGuide = (oProps) => {
    const PosDownRender = () => {
        if (isMobile) {
            return <M_posGuide />
        }

        return <D_posGuide />
    }

    return (
        <>
            {PosDownRender()}
        </>
    )
}

export default MainPosGuide;