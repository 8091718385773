import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import '../../../../assets/fonts/font.css'
import { Link } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';

// img
import ceoLandingBanner from '../../../../assets/img/ceoscreen/landing/walkThroo/walkThrooBanner.jpg';
import walkThroo from '../../../../assets/img/ceoscreen/landing/walkThroo/walkThroo.png';
import driveThroo from '../../../../assets/img/ceoscreen/landing/walkThroo/driveThroo.png';
import benefit01 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit01.png';
import benefit02 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit02.png';
import benefit03 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit03.png';
import benefit04 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit04.png';
import benefit05 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit05.png';
import benefit06 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit06.png';
import review01 from '../../../../assets/img/ceoscreen/landing/walkThroo/review01.png';
import review02 from '../../../../assets/img/ceoscreen/landing/walkThroo/review02.png';
import review03 from '../../../../assets/img/ceoscreen/landing/walkThroo/review03.png';
import orderGuide01 from '../../../../assets/img/ceoscreen/landing/walkThroo/orderGuide01.png';
import orderGuide02 from '../../../../assets/img/ceoscreen/landing/walkThroo/orderGuide02.png';
import orderGuide03 from '../../../../assets/img/ceoscreen/landing/walkThroo/orderGuide03.png';
import throoOnly from '../../../../assets/img/ceoscreen/landing/m_throoOnly.png';
import throoNow from '../../../../assets/img/ceoscreen/landing/m_throoNow.png';
import throoDiscount from '../../../../assets/img/ceoscreen/landing/m_throoDiscount.png';
import storeTop from '../../../../assets/img/ceoscreen/landing/walkThroo/m_storeTop.png';
import storeBottom from '../../../../assets/img/ceoscreen/landing/walkThroo/m_storeBottom.png';
import contactBanner from '../../../../assets/img/ceoscreen/landing/walkThroo/m_openEvent.png';
import moreBtn from '../../../../assets/img/ceoscreen/landing/walkThroo/m_openEventButton.png';

const LandingPage = (oProps) => {

    useEffect(() => {
        window.scrollSection();
        window.setScreenSize();
        window.scrollPoint();
        window.sliderImage();
    }, []);
    
    return (
        <M_CeoLanding>
            <div className="MobileLanding">
                <div className="banner">
                    <div className="hide fade_up">
                        <h1>스루, 새로운 고객을<br />만나는 방법</h1>
                        <p>스루는 기존 포장 주문 플랫폼을 넘어,<br /> 보다 진화된 '스마트 픽업 서비스'로<br /> 다양한 고객과 매장을 연결합니다.</p>
                        {/* <button><Link to={WebRoute.JOINFORM}>스루 입점신청</Link></button> */}
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <div className="orderType">
                            <h1 className="hide fade_right" style={{ transitionDelay: '1s' }}>워크스루부터<br />드라이브스루까지</h1>
                            <div className="walkThroo hide fade_right">
                                <img src={walkThroo} alt="워크스루 이미지" width="100%" />
                                <h2>바쁠때는 워크 스루!</h2>
                                <p>결제까지 완료된 포장 주문,<br />사장님은 매장에서 전달만 해주세요.</p>
                            </div>
                            <div className="driveThroo hide fade_right">
                                <img src={driveThroo} alt="드라이브스루 이미지" width="100%" />
                                <h2>한가할 때는 드라이브스루!</h2>
                                <p>매장 앞 고객의 차까지만 전달해 주시면 됩니다.<br />그동안 놓치고 있었던 운전자 고객을 만나보세요.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <div className="benefit">
                            <h1 className="hide fade_up" style={{ transitionDelay: '.5s' }}>사장님 매장에 딱 맞는<br />효과를 기대할 수 있어요</h1>
                            <div className="benefitContent hide fade_up">
                                <img src={benefit01} alt="스루기대효과" width="100%" />
                                <div className="title">
                                    <h3>더 많은 고객 확보</h3>
                                    <p>방문포장 고객 뿐만아니라 새로운 운전자 고객을 확보할 수 있어요.</p>
                                </div>
                            </div>
                            <div className="benefitContent hide fade_up">
                                <img src={benefit02} alt="스루기대효과" width="100%" />
                                <div className="title">
                                    <h3>인건비 절감</h3>
                                    <p>앱으로 주문, 결제 완료!<br />고객 응대시간을 단축할 수 있어요.</p>
                                </div>
                            </div>
                            <div className="benefitContent hide fade_up">
                                <img src={benefit03} alt="스루기대효과" width="100%" />
                                <div className="title">
                                    <h3>편리한 매장 관리</h3>
                                    <p>터치한번으로 메뉴설정,재고관리,매출통계 확인이 가능해요.</p>
                                </div>
                            </div>
                            <div className="benefitContent hide fade_up">
                                <img src={benefit04} alt="스루기대효과" width="100%" />
                                <div className="title">
                                    <h3>단골고객 증가</h3>
                                    <p>스루 앱 내 스탬프, 쿠폰, 할인 기능을 통해 단골고객이 증가해요.</p>
                                </div>
                            </div>
                            <div className="benefitContent hide fade_up">
                                <img src={benefit05} alt="스루기대효과" width="100%" />
                                <div className="title">
                                    <h3>고객 만족도 상승</h3>
                                    <p>실시간 위치 확인으로 사장님도 고객도 기다림없이 픽업이 가능해요.</p>
                                </div>
                            </div>
                            <div className="benefitContent hide fade_up">
                                <img src={benefit06} alt="스루기대효과" width="100%" />
                                <div className="title">
                                    <h3>매장광고효과</h3>
                                    <p>다양한 마케팅 툴을 활용해 더 많은 고객에게 우리 매장을 홍보할 수 있도록 지원해 드려요.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="throoStore">
                    <div className="textAnimation">
                        <div className="textAnimation__container">
                            <ul className="textAnimation__container__list">
                                <li className="textAnimation__container__list__item">음식점도</li>
                                <li className="textAnimation__container__list__item">카페도</li>
                                <li className="textAnimation__container__list__item">편의점도</li>
                                <li className="textAnimation__container__list__item">꽃집도</li>
                                <li className="textAnimation__container__list__item">밀키트도</li>
                            </ul>
                        </div>
                        <p>포장만 가능하다면</p>
                        <p>오늘부터 드라이브스루!</p>
                    </div>
                </div>
                <div className="content">
                    <div className="trooGuide">
                        <div className="container">
                            <h1 className="hide fade_right" style={{ transitionDelay: '.5s' }}>쉽고 편한<br /> 스루 이용방법</h1>
                        </div>
                        <div className="guide">
                            <div className="container">
                                <div>
                                    <div className="img"><img src={orderGuide01} alt="스루이용방법" width="100%" /></div>
                                    <h3 className="hide fade_right" style={{ transitionDelay: '.3s' }}>고객이 <span>앱으로 주문 결제 완료!</span><br />포스나 앱으로 주문 확인이 가능해요.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="guide">
                            <div className="container">
                                <div>
                                    <div className="img"><img src={orderGuide02} alt="스루이용방법" width="100%" /></div>
                                    <h3 className="hide fade_right" style={{ transitionDelay: '.3s' }}><span>고객 위치 실시간 확인 가능!</span><br />매장 근처에 도착하면 알람이 울려요.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="guide">
                            <div className="container">
                                <div>
                                    <div className="img"><img src={orderGuide03} alt="스루이용방법" width="100%" /></div>
                                    <h3 className="hide fade_right" style={{ transitionDelay: '.3s' }}><span>드라이브스루 고객은 픽업존</span>에서,<br />워크스루 고객은 매장에서<br />상품을 전달해주세요.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="throoSpecial">
                        <div className="container">
                            <h1 className="hide fade_in" style={{ transitionDelay: '.5s' }}>사장님을 위한<br />스루만의 특별한 기능</h1>
                            <ul class="bxslider">
                                <li>
                                    <div className="title"><p>오직 스루에서만! 스루온리</p></div>
                                    <h3>스루에서만 만나볼 수 있는<br />특별한 메뉴!</h3>
                                    <Link to={{ pathname: '/ceotip', page_id: 'mob_only', search: 'mob_only' }}>
                                        <img src={throoOnly} alt="스루온리설명이미지" width="100%" />
                                    </Link>
                                </li>
                                <li>
                                    <div className="title"><p>여유시간에 추가 매출! 스루나우</p></div>
                                    <h3>준비시간을 줄일수록<br />주문은 UP!</h3>
                                    <Link to={{ pathname: '/ceotip', page_id: 'mob_now', search: 'mob_now' }}>
                                        <img src={throoNow} alt="스루나우설명이미지" width="100%" />
                                    </Link>
                                </li>
                                <li>
                                    <div className="title"><p>단골 고객 사로잡기! 할인</p></div>
                                    <h3>할인 기능을 통해<br />우리 매장에 단골손님 UP!</h3>
                                    <img src={throoDiscount} alt="스루할인설명이미지" width="100%" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="partner">
                        <h1 className="hide fade_in" style={{ transitionDelay: '.3s' }}>전국 많은 매장들이<br />스루를 이용하고 있어요.</h1>
                        <div className="logo-slider-top">
                            <div>
                                <img src={storeTop} alt="스루 가입 매장" />
                            </div>
                            <div>
                                <img src={storeTop} alt="스루 가입 매장" />
                            </div>
                        </div>
                        <div className="logo-slider-bottoom">
                            <div>
                                <img src={storeBottom} alt="스루 가입 매장" />
                            </div>
                            <div>
                                <img src={storeBottom} alt="스루 가입 매장" />
                            </div>
                        </div>
                        <div className="review">
                            <div className="container">
                                <div className="partnerReview">
                                    <img src={review01} alt="스루사장님후기" width="100%" />
                                    <img src={review02} alt="스루사장님후기" width="100%" />
                                    <img src={review03} alt="스루사장님후기" width="100%" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <img src={contactBanner} alt="정식서비스 오픈 이벤트" width="100%" />
                    <button>
                        <Link to={{ pathname: '/ceotip', page_id: 'mob_throoCeoPromotion', search: 'mob_throoCeoPromotion' }}>
                            <img src={moreBtn} alt="오픈 이벤트 자세히보기 버튼" width="100%" />
                        </Link>
                    </button>
                </div>
                <div className="content">
                    <div className="contact hide fade_up" style={{ transitionDuration: '3s' }}>
                        <h2><span>스루,</span> 지금 바로<br />시작해 보세요!</h2>
                        <button><Link to={WebRoute.JOINFORM}><p>스루 입점신청</p></Link></button>
                    </div>
                </div>
                <div className="floatingButton">
                    <button><Link to={WebRoute.JOINFORM}>스루 입점 신청하러 가기</Link></button>
                </div>
            </div>
        </M_CeoLanding >
    )
}

export default LandingPage;

export const M_CeoLanding = styled.div`

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Pretendard';
        color: #191F28;
        text-align: center;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .MobileLanding {
        width: 100%;
    }
    .container {
        width: 100%;
        padding: 0 5vw;
        margin: 0 auto;
    }
    a {
        color: #fff;
    }

    /* 텍스트 애니메이션 */
    .in_view {
        opacity: 1;
        transition: all 1s ease-in-out;
    }
    .fade_in {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
    .fade_up {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }
    .fade_left {
        opacity: 0;
        transform: translate3d(15%, 0, 0);
    }
    .fade_right {
        opacity: 0;
        transform: translate3d(-15%, 0, 0);
    }
    .fade_sparkle {
        opacity: 0;
    }
    .in_view.fade_sparkle {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-animation:blink 1s ease-in-out 4 alternate; 
        -moz-animation:blink 1s ease-in-out 4 alternate; 
        animation:blink 1s ease-in-out 4 alternate;
    }
    @-webkit-keyframes blink{ 0% {opacity:0;} 100% {opacity:1;} }
    @-moz-keyframes blink{ 0% {opacity:0;} 100% {opacity:1;} } 
    @keyframes blink{ 0% {opacity:0;} 100% {opacity:1;} }

    .in_view.fade_in,
    .in_view.fade_up,
    .in_view.fade_down,
    .in_view.fade_left,
    .in_view.fade_right {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    /* 공통css */
    h1 {
        font-size: 35px;
        font-weight: 700;
        line-height: 51px;
        text-align: left;
    }
    h2 {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
    }
    h3 {
        font-size: 24px;
        font-weight: 600;
        line-height: 25px;
    }
    p {
        font-size: 17px;
        font-weight: 500;
        line-height: 26px;
    }
    .content {
        padding: 100px 0;
    }

    /* banner */
    .banner {
        width: 100%;
        min-height: calc(var(--vh, 1vh) * 100);
        max-height: calc(var(--vh, 1vh) * 100);
        background-image: url(${ceoLandingBanner});
        background-size: cover;
        height: auto;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        
        h1 {
            margin-top: 60px;
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            text-align: center;
        }
        p {
            margin: 24px 0 155px 0;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
        }
        button {
            padding: 18px 42px;
            background-color: #1A7CFF;
            font-size: 21px;
            font-weight: 600;
            color: #fff;
            border: none;
            border-radius: 6px;
            outline: none;
        }
    }

    /* orderType */
    .orderType {
        h1 {
            margin-bottom: 24px;
            text-align: left;
        }
        h2 {
            font-weight: 600;
            margin: 31px 0 24px 0;
            text-align: left;
        }
        p {
            text-align: left;
        }
        img {
            margin-top: 60px;
        }
    }

    /* benefit */
    .content:nth-child(3) {
        background-color: #F9FAFB;
    }
    .benefit {
        h1 {
            margin-bottom: 30px;
            font-size: 28px;
            font-weight: 700;
            line-height: 41px;
        }
        img {
            width: 60px;
            height: 60px;
            margin-right: 16px;
        }
    }
    .benefitContent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;
        padding: 26px 20px;
        background: #fff;
        border-radius: 10px;

        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 25px;
            text-align: left;
            color: #333D4B;
        }
        p {
            margin-top: 8px;
            text-align: left;
            color: #79828C;
            white-space: break-all;
        }
    }

    /* throoStore */
    .throoStore {
        background-color: #171b26;
        min-height: 328px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
   
        p {
            margin: 0;
            padding: 0;
            font-size: 28px;
            font-weight: 700;
            color: #fff;
            padding-top: 20px;
        }
    }
    .textAnimation {
        position: absolute;
        overflow: hidden;
        line-height: 41px;
        color: #ecf0f1;
    }

    .textAnimation__container {
        font-weight: 700;
        overflow: hidden;
        height: 32px;
    }

    .textAnimation__container__text {
        display: inline;
        float: left;
        margin: 0;
    }

    .textAnimation__container__list {
        margin-top: 0;
        line-height: 41px;
        -webkit-animation-name: change;
        -webkit-animation-duration: 10s;
        -webkit-animation-iteration-count: infinite;
        animation-name: change;
        animation-duration: 7s;
        animation-iteration-count: infinite;
    }

    .textAnimation__container__list__item {
        line-height: 41px;
        margin: 0;
        padding-bottom: 7px;
        font-size: 28px;
        font-weight: 800;
        color: #00E4BB;
    }

    @keyframes opacity {

        0%,
        100% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }
    }

    @keyframes change {
        0%,90%{
            transform: translate3d(0, 0, 0); 
        }

        10%,80% {
            transform: translate3d(0, -20%, 0);
        }

        20%,70% {
            transform: translate3d(0, -40%, 0);
        }

        30%,60% {
            transform: translate3d(0, -60%, 0);
        }

        40%,50% {
            transform: translate3d(0, -80%, 0);
        }
    }

    /* trooGuide */
    .content:nth-child(5) {
        padding-bottom: 0;
        h1 {
            margin-bottom: 54px;
        }
        h3 {
            margin-top: 14px;
            text-align: left;
            line-height: 32px;

            span {
                color: #1A7CFF;
            }
        }
    }
    .guide:nth-child(even) {
        background: #F9FAFB;
        padding: 64px 0 40px 0;
    }
    .guide:nth-child(odd) {
        padding: 64px 0 40px 0;
    }
    .guide:nth-child(4) {
        padding-bottom: 100px;
    }

    /* throoSpecial */
    .content:nth-child(6) {
        background-color: #212634;
    }
    .throoSpecial {
        h1 {
            margin-bottom: 48px;
            font-size: 36px;
            font-weight: 700;
            line-height: 52px;
            text-align: center;
            color: #fff;
        }
        h3 {
            margin: 50px 0;
            color: #fff;
            line-height: 32px;
        }
        .title {
            
            margin:0 auto;
            width: 80%;

            p {
                padding: 14px 26px;
                font-size: 16px;
                font-weight: 900;
                color: #000;
                background-color: #00E4BB;
                border-radius: 30px;
            }
        }
    }
    .bx-wrapper {
        border-color: #212634;
        background:none;
        box-shadow:none;
    }
    .bx-wrapper .bx-pager.bx-default-pager a.active {
        background: #00E4BB;
    }
    .bx-wrapper .bx-pager.bx-default-pager a {
        background: #454F68;
    }

    /* partner */
    .content:nth-child(7) {
        background-color: #000;
    }
    .partner {
        width: 100%;

        h1 {
            padding-bottom: 44px;
            font-size: 34px;
            text-align: center;
            color: #fff;
        }

        .partnerReview {
            display: flex;
            overflow: auto;

            img {
                width: 100%;
                max-width: 254px;
            }
        }
    }
    .logo-slider-top, .logo-slider-bottoom {
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
        position: relative;
        display: flex;
    }
    .logo-slider-top div {
        display: flex;
        position: relative;
        animation: marquee 50s linear infinite;
        justify-content: space-between;
    }
    .logo-slider-bottoom div {
        display: flex;
        position: relative;
        animation: marquee 50s linear infinite;
        justify-content: space-around;
        animation-direction: reverse;
    }
    .logo-slider-top img, .logo-slider-bottoom img {
        display: block;
        margin: 0 10px;
        width: 1670px;
        height: 70px;
    }
    .partnerReview img {
        margin-right: 20px;
        margin-top: 50px;
    }

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    /* promotion */
    .content:nth-child(8) {
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        
        button {
            width: 40%;
            margin:0 auto;
            position: absolute;
            bottom: 32%;
            left: 0;
            right: 0;
            background: none;
            outline: none;
            border: none;
        }
    }

    /* contact */
    .content:nth-child(9) {
        padding: 79px 0;
        background: #212634;
    }
    .contact {
        h2 {
            font-size: 30px;
            font-weight: 600;
            line-height: 40px;
            color: #fff;

            span {
                color: #1A7CFF;
                font-weight: 700;
            }
        }

        button {
            margin-top: 42px;
            padding: 18px 42px;
            background: #1a7cff;
            border: none;
            border-radius: 6px;

            p {
                font-size: 21px;
                font-weight: 600;
                line-height: 21px;
                color: #fff;
            }
        }
    }

    /* floatingButton */
    .floatingButton {
        width: 100%;
        background: #1A7CFF;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 99999;
        
        button {
            padding: 17px 0;
            background: none;
            outline: none;
            border: none;
            font-size: 18px;
            font-weight: 700;
        }
    }
`
