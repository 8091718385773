import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Banner = ({ iList }) => {
    const [sList] = useState(iList);

    useEffect(() => {
        window.sliderImage();
    }, [])

    return (
        <BannerStyle>
            <div className="banner">
                <div className="bannerSlide">
                    <ul class="bxslider">
                        {sList.map((item, index) => {
                            return (
                                <li key={index}>
                                    {(item.move_path !== undefined && item.move_path !== null && item.move_path !== "") ?
                                        <>
                                            {(item.param !== undefined && item.param !== null && item.param !== "") ?
                                                <Link to={{ pathname: item.move_path, page_id: item.param, search: item.param }}><img src={item.url_path} width="100%" /></Link>
                                                :
                                                <Link to={item.move_path}><img src={item.url_path} width="100%" /></Link>
                                            }
                                        </>
                                        :
                                        <img src={item.url_path} width="100%" />
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </BannerStyle>
    )
}

export default Banner;

export const BannerStyle = styled.div`
    * {
        margin:0;
        padding:0;
        /* box-sizing: border-box; */
        cursor: pointer;
    }
    .banner {
        width: 1120px;
        padding: 64px 40px 0 40px;
        margin: 0 auto;
        position: relative;
    }
    .bannerSlide {
        min-width: 100%;
        min-width: 100%;
        margin-top: 60px;
    }

    .bx-wrapper {
       border: 0;
       box-shadow:none;
    }
    .bx-pager.bx-default-pager{
        position: absolute;
        bottom: 5%;

    }
    .bx-wrapper .bx-pager.bx-default-pager a.active, .bx-wrapper .bx-pager.bx-default-pager a:focus, .bx-wrapper .bx-pager.bx-default-pager a:hover {
    background: #fff;
}
`;