import React, { useState, useRef, Fragment, useEffect } from 'react';
import ReactDragListView from "react-drag-listview";
import { Popconfirm, Table, message, Select } from 'antd';
import { OrderedListOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import Style from "../../../../assets/styledComponents/OptionStyle"
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import SelectReact from 'react-select';
import InsertProduct from './insert_Product';
const { Option } = Select;

const sMaxList = [];
const sMinList = [];
for (let i = 1; i < 21; i++) {
    sMaxList.push({
        key: i,
        value: i,
        name: `${i} 개`,
    });
};
for (let i = 1; i < 21; i++) {
    sMinList.push({
        key: i,
        value: i,
        name: `${i} 개`,
    });
};

const InsertForm = ({ fAddList, oProps }) => {
    const [sNm, setNm] = useState("");
    const [sMin, setMin] = useState(1 + " 개");
    const [sMax, setMax] = useState(1 + " 개");
    const [pOptionType, set_pOptionType] = useState("original");
    const [sOptionType, setOptionType] = useState("radio");
    const [sOptionNm, setOptionNm] = useState("");
    const [sOptionPrice, setOptionPrice] = useState(0);

    const [classNm, setClassNm] = useState(null);
    const [classOptionNm, setClassOptionNm] = useState(null);
    const [classOptionPrice, setClassOptionPrice] = useState(null);

    const [iData, setData] = useState([]);
    const sData = useRef([]);
    const [option_state, set_option_state] = useState('product_option');

    {/*----일반옵션----*/ }
    // 옵션 그룹명
    const onChangeStoreNameValue = (e) => {
        setClassNm("input_action");
        setNm(e.target.value);
    }
    // 옵션 추가
    const insertList = async () => {
        let temp = false;

        if (sOptionNm === "") {
            setClassOptionNm("input_wrong");
            temp = true;
        }
        if (sOptionPrice === "") {
            setClassOptionPrice("input_wrong");
            temp = true;
        }

        if (!temp) {
            const xData = {
                key: parseInt(sData.current.length) + 1,
                name: sOptionNm,
                price: parseInt(sOptionPrice)
            }
            setData([...sData.current, xData]);
            sData.current = [...sData.current, xData];
            classNm !== "input_wrong" && setClassNm(null);
            classOptionNm !== "input_wrong" && setClassOptionNm(null);
            classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
            setOptionNm('');
            setOptionPrice('')
        }
    }
    // 옵션 추가 목록
    const sColumns = useRef([
        {
            title: "순서",
            key: "key",
            render: (text, record, index) =>
                <a className="drag-handle"><OrderedListOutlined /></a>
        },
        {
            title: "옵션명",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "가격",
            key: "price",
            dataIndex: "price",
        },
        {
            title: "삭제",
            key: "action",
            render: (text, record, index) =>
                <Popconfirm
                    title="정말로 이 옵션을 삭제하시겠습니까?"
                    onConfirm={() => deleteList(text.key)}
                    okText="예"
                    cancelText="아니오"
                >
                    <div className="delete_box"><p>삭제</p></div>
                </Popconfirm>
        }
    ]);
    // 옵션 추가 목록 삭제
    const deleteList = (key) => {
        sData.current = sData.current.filter((item) => item.key !== key);
        setData(sData.current.filter((item) => item.key !== key));
    }
    // 옵션명
    const onChangeClassOptionNm = (event) => {
        setClassOptionNm("input_action");
        setOptionNm(event.target.value);
    }
    // 옵션 가격
    const onChangeClassOptionPrice = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setClassOptionPrice("input_action");
            setOptionPrice(value);
        }
    }
    //필수 선택 
    const setChangeMin = (event) => {
        classNm !== "input_wrong" && setClassNm(null);
        classOptionNm !== "input_wrong" && setClassOptionNm(null);
        classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
        // if (isMobile) {
        //     setMin(event);
        // } else {
        //     setMin(event.target.value);
        //     setMax(event.target.value);

        // }
        setMin(event.target.value);
        setMax(event.target.value);
    }
    // 최대 선택
    const setChangeMax = (event) => {
        classNm !== "input_wrong" && setClassNm(null);
        classOptionNm !== "input_wrong" && setClassOptionNm(null);
        classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
        // if (isMobile) {
        //     setMax(event);
        // } else {
        //     setMax(event.target.value);
        // }
        setMax(event.target.value);
        setMin(0+"개")
    }
    // 순서 변경
    const dragProps = (fromIndex, toIndex) => {
        let tempColumns = sData.current;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        sData.current = columnsCopy;
        setData(columnsCopy);
    }

    const optionType = (sIndex) => {
        classNm !== "input_wrong" && setClassNm(null);
        classOptionNm !== "input_wrong" && setClassOptionNm(null);
        classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
        setOptionType(sIndex);
    }
    const selectOptionType = (sIndex) => {
        if (sIndex === "radio") {
            setMax(1 + " 개");
        } else {
            setMin(1 + " 개");
        }
        optionType(sIndex);
    }

    // submit 등록하기 버튼
    const registerOption = async () => {
        if (sNm === "") {
            setClassNm("input_wrong");
            message.error("옵션 그룹명을 입력해주세요");
        } else if (iData.length < 1) {
            message.error("옵션을 지정해주세요.");
        } else {
            let oData = {
                sNm,
                sMin: parseInt(sMin),
                sMax: parseInt(sMax),
                sData: sData.current,
                type: sOptionType.toString()
            };
            if (sOptionType !== "checkbox") {
                if (sData.current.length < parseInt(sMin)) {
                    message.error("선택가능한 옵션이 필수선택횟수보다 작습니다.");
                    return false;
                }else if (fAddList !== undefined && typeof fAddList === "function") {
                    await fAddList(oData);
    
                    // setNm("");
                    setMin(1 + " 개");
                    setMax(1 + " 개");
                    // setData('');
                    // sData.current = ''
    
                    console.log('최종보냄****', oData)
                }
            }
            if (sOptionType !== "radio") {
                if (sData.current.length < parseInt(sMax.substr(0, 2))) {
                    message.error("선택가능한 옵션이 최소선택횟수보다 작습니다.");
                    return false;
                }else if (fAddList !== undefined && typeof fAddList === "function") {
                    await fAddList(oData);
    
                    // setNm("");
                    setMin(1 + " 개");
                    setMax(1 + " 개");
                    // setData('');
                    // sData.current = ''
    
                    console.log('최종보냄****', oData)
                }
            } 
            
        }
    }

    useEffect(() => {
        if (sOptionType === "checkbox") {
            setMin(0)
        }
    }, [sOptionType]);

    return (
        <Fragment>
            <BrowserView>
                <Style>
                    <div>
                        <div className="option_type_wrap">
                            <h3>옵션 종류 선택</h3>
                            <div className="option_type">
                                <button className={pOptionType === "original" ? "selected_type" : "unselect_selected_type"} onClick={() => set_pOptionType("original")}>
                                    <p>일반 옵션 만들기</p>
                                </button>
                                {/* 추후 오픈 */}
                                {/* <button className={pOptionType === "product" ? "selected_type" : "unselect_selected_type"} onClick={() => set_pOptionType("product")}>
                                    <p>상품인 옵션 만들기<span>(재고관리가 필요할때)</span></p>
                                </button> */}
                            </div>
                        </div>
                        {/* 선택옵션 > 일반옵션 */}
                        {pOptionType === "original" &&
                            <>
                                <div className="essential_wrap">
                                    <h3>필수 선택 여부</h3>
                                    <div className="select_essential_box">
                                        <button className={sOptionType === "radio" ? "selected_wrapper" : "unselect_wrapper"} style={{ marginRight: '12px' }} onClick={() => selectOptionType("radio")}>
                                            <p>예</p>
                                        </button>
                                        <button className={sOptionType === "checkbox" ? "selected_wrapper" : "unselect_wrapper"} onClick={() => selectOptionType("checkbox")}>
                                            <p>아니요</p>
                                        </button>
                                    </div>
                                </div>
                                <div className="category_title">
                                    <h3>옵션 그룹명</h3>
                                    <input
                                        type="text"
                                        placeholder="예) 사이즈, 맵기 선택"
                                        className={classNm}
                                        value={sNm}
                                        onChange={onChangeStoreNameValue}
                                    />
                                </div>
                                <div className="category_num">
                                    {sOptionType === "radio" &&
                                        <>
                                            <h3>필수 선택 개수</h3>
                                            <select value={sMin} onChange={setChangeMin}  >
                                                {sMinList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })};
                                            </select>
                                        </>
                                    }
                                    {sOptionType === "checkbox" &&
                                        <>
                                            <h3>최대 선택 개수</h3>
                                            <select value={sMax} onChange={setChangeMax}  >
                                                {sMaxList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })};
                                            </select>
                                        </>
                                    }
                                </div>
                                <div className="category_title">
                                    <h3>옵션명</h3>
                                    <div className="option_subTitle">
                                        <input
                                            type="text"
                                            placeholder="예) 매운맛같은 옵션의 세부사항"
                                            className={classOptionNm}
                                            value={sOptionNm}
                                            onChange={onChangeClassOptionNm}
                                        />
                                        <div className='option_price'>
                                            <input
                                                type="text"
                                                className={classOptionPrice}
                                                value={sOptionPrice}
                                                onChange={onChangeClassOptionPrice}
                                            />
                                            <p className="pay_box">원</p>
                                        </div>
                                        <div className="insert_box" onClick={insertList}>
                                            <h3>옵션 추가</h3>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "5%" }}>
                                    <ReactDragListView.DragColumn
                                        onDragEnd={dragProps}
                                        handleSelector="a"
                                    >
                                        <Table columns={sColumns.current} dataSource={iData} pagination={false} />
                                    </ReactDragListView.DragColumn>
                                </div>
                                <div className="location_box_line">
                                    <div className="how_box">
                                        <p>[순서 변경 방법] 순서 아이콘을 클릭한 상태로 원하는 위치로 이동하세요</p>
                                    </div>
                                </div>
                                <div className="register_box" onClick={registerOption}>
                                    <p>등록완료</p>
                                </div>
                            </>
                        }
                        {/* 선택옵션 > 상품인 옵션 */}
                        {pOptionType === "product" && <InsertProduct option_state={option_state} oProps={oProps} />}
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <Style>
                    <div>
                        <div className="option_type_wrap" style={{ height: 0, margin: 0 }}>
                            <h3 style={{ color: '#fff', margin: 0 }}>옵션 종류 선택</h3>
                            <div className="option_type_mobile" style={{ display: 'none' }}>
                                <button className={pOptionType === "original" ? "selected_type left" : "unselect_selected_type"} onClick={() => set_pOptionType("original")}>
                                    <p>일반 옵션 만들기</p>
                                </button>
                            </div>
                            {/* <div className="option_type_mobile">
                                <button className={pOptionType === "original" ? "selected_type left" : "unselect_selected_type"} onClick={() => set_pOptionType("original")}>
                                    <p>일반 옵션 만들기</p>
                                </button>
                                <button className={pOptionType === "product" ? "selected_type right" : "unselect_selected_type"} onClick={() => set_pOptionType("product")}>
                                    <p>상품인 옵션 만들기<br /><span>(재고관리가 필요할때)</span></p>
                                </button>
                            </div> */}
                        </div>
                        {/* 선택옵션 > 일반옵션 */}
                        {pOptionType === "original" &&
                            <>
                                <div className="essential_wrap_mobile">
                                    <h3>필수 선택 여부</h3>
                                    <div className="select_essential_box">
                                        <button className={sOptionType === "radio" ? "selected_wrapper" : "unselect_wrapper"} style={{ marginRight: '12px' }} onClick={() => selectOptionType("radio")}>
                                            <p>예</p>
                                        </button>
                                        <button className={sOptionType === "checkbox" ? "selected_wrapper" : "unselect_wrapper"} onClick={() => selectOptionType("checkbox")}>
                                            <p>아니요</p>
                                        </button>
                                    </div>
                                </div>
                                <div className="category_title_mobile">
                                    <h3>옵션 그룹명</h3>
                                    <input
                                        type="text"
                                        placeholder="예) 사이즈, 맵기 선택"
                                        className={classNm}
                                        value={sNm}
                                        onChange={onChangeStoreNameValue}
                                    />
                                </div>
                                <div className="category_num">

                                    {sOptionType === "radio" &&
                                        <>
                                            <h3>필수 선택 개수</h3>
                                            <select value={sMin} onChange={setChangeMin}  >
                                                {sMinList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })};
                                            </select>
                                        </>
                                    }
                                    {sOptionType === "checkbox" &&
                                        <>
                                            <h3>최대 선택 개수</h3>
                                            <select value={sMax} onChange={setChangeMax} style={{ background: '#fff' }}>
                                                {sMaxList.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })};
                                            </select>
                                        </>
                                    }
                                </div>
                                <div className="category_title_mobile">
                                    <h3>옵션명</h3>
                                    <div className="option_subTitle">
                                        <input
                                            type="text"
                                            placeholder="예) 맵기 1단계, 소스명"
                                            className={classOptionNm}
                                            value={sOptionNm}
                                            onChange={onChangeClassOptionNm}
                                        />
                                        <div className='option_price'>
                                            <input
                                                type="text"
                                                className={classOptionPrice}
                                                value={sOptionPrice}
                                                onChange={onChangeClassOptionPrice}
                                            />
                                            <p className="pay_box">원</p>
                                        </div>
                                        <div className="insert_box" onClick={insertList}>
                                            <h3>옵션추가</h3>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "5%" }}>
                                    <ReactDragListView.DragColumn
                                        onDragEnd={dragProps}
                                        handleSelector="a"
                                    >
                                        <Table columns={sColumns.current} dataSource={iData} pagination={false} />
                                    </ReactDragListView.DragColumn>
                                </div>
                                <div className="location_box_line">
                                    <div className="how_box" style={{ padding: '8px 4px' }}>
                                        <p style={{ fontSize: '12px' }}>[순서 변경 방법] <br />순서 아이콘을 클릭한 상태로 원하는 위치로 이동하세요</p>
                                    </div>
                                </div>
                                <div className="register_box" onClick={registerOption}>
                                    <p>등록완료</p>
                                </div>
                            </>
                        }
                        {/* 선택옵션 > 상품인 옵션 */}
                        {pOptionType === "product" && <InsertProduct option_state={option_state} oProps={oProps} />}
                    </div>
                </Style>
            </MobileView>
        </Fragment>
    )
}

export default InsertForm;


