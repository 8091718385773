import React from 'react';
import {
    isMobile
} from "react-device-detect";

// 데스크
import D_estGuide from "./desktop/D_estGuide";
import D_posGuide from "./desktop/D_posGuide";
import D_printGuide from "./desktop/D_printGuide";
import D_v3Guide from "./desktop/D_v3Guide";
import D_cancelGuid from "./desktop/D_cancelGuid";
import D_soundGuide from "./desktop/D_soundGuide";
import D_timeSettingGuide from "./desktop/D_timeSettingGuide";
import D_autoGuide from "./desktop/D_autoGuide";

// 모바일
import M_estGuide from "./mobile/M_estGuide";
import M_posGuide from "./mobile/M_posGuide";
import M_printGuide from "./mobile/M_printGuide";
import M_v3Guide from "./mobile/M_v3Guide";
import M_cancelGuide from "./mobile/M_cancelGuide";
import M_soundGuide from "./mobile/M_soundGuide";
import M_timeSettingGuide from "./mobile/M_timeSettingGuide";
import M_autoGuide from "./mobile/M_autoGuide";
import { useLocation } from 'react-router';

const MainGuideForm = (oProps, oParam) => {
    const location = useLocation()
    if (isMobile) {
        if (location.pathname !== undefined && location.search === '?mob_posguide') {
            return (
                <M_posGuide />
            )
        } if (location.pathname !== undefined && location.search === '?mob_printguide') {
            return (
                <M_printGuide />
            )
        } if (location.pathname !== undefined && location.search === '?mob_estguide') {
            return (
                <M_estGuide />
            )
        } if (location.pathname !== undefined && location.search === '?mob_v3guide') {
            return (
                <M_v3Guide />
            )
        } if (location.pathname !== undefined && location.search === '?mob_cancelguide') {
            return (
                <M_cancelGuide />
            )
        } if (location.pathname !== undefined && location.search === '?mob_soundguide') {
            return (
                <M_soundGuide />
            )
        } if (location.pathname !== undefined && location.search === '?mob_timesettingguide') {
            return (
                <M_timeSettingGuide />
            )
        } if (location.pathname !== undefined && location.search === '?mob_autoguide') {
            return (
                <M_autoGuide />
            )
        }
    } else {
        if (location.pathname !== undefined && location.search === '?posguide') {
            return (
                <D_posGuide />
            )

        } if (location.pathname !== undefined && location.search === '?printguide') {
            return (
                <D_printGuide />
            )
        } if (location.pathname !== undefined && location.search === '?estguide') {
            return (
                <D_estGuide />
            )
        } if (location.pathname !== undefined && location.search === '?v3guide') {
            return (
                <D_v3Guide />
            )
        } if (location.pathname !== undefined && location.search === '?cancelguide') {
            return (
                <D_cancelGuid />
            )
        } if (location.pathname !== undefined && location.search === '?soundguide') {
            return (
                <D_soundGuide />
            )
        } if (location.pathname !== undefined && location.search === '?timesettingguide') {
            return (
                <D_timeSettingGuide />
            )
        } if (location.pathname !== undefined && location.search === '?autoguide') {
            return (
                <D_autoGuide />
            )
        }
    }
}

export default MainGuideForm;

