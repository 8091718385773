import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { Modal, message, Select } from 'antd';
import DaumPostcode from 'react-daum-postcode';
import "antd/dist/antd.css";

import addressSearch from '../../../../assets/svg/addressSearch.svg';
import manual from '../../../../assets/svg/manual.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';

import { GuideBusiness } from '../../../modal/AppModal';
import Layout from '../../Layout'
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { WebRoute } from '../../../../navigation/WebRoutes';
import layoutHeaderLists from '../../config';
import { useNavigate } from 'react-router';
const { Option } = Select;

const Business = ({ nStatus, oProps, fnMoveToDashBoard }) => {

    const [isRegister, setRegister] = useState(false);
    const [storeName, setStoreName] = useState("");
    const [storeOwner, setStoreOwner] = useState("");
    const [sBusinessNm, setBusinessNm] = useState("");
    const [sAddress, setAddress] = useState("");
    const [sExtraAddress, setExAddress] = useState("");
    const [sPhoneNm, setPhoneNm] = useState("");
    const [sEmail, setEmail] = useState("");
    const [sAccountNm, setAccountNm] = useState("");
    const [sBank, setBank] = useState("산업은행");
    const [sCompany, setCompany] = useState("미지정");
    const [coperationList, setCoperationList] = useState([]);

    const [errorMessageEmail, setErrorMessageEmail] = useState("");

    const [addressModal, setAddressModal] = useState(false);

    const [classStoreNm, setClassStoreNm] = useState(null);
    const [classStoreOwner, setClassStoreOwner] = useState(null);
    const [classBusinessNm, setClassBusinessNm] = useState(null);
    const [classExtraAddress, setClassExtraAddress] = useState(null);
    const [classAddress, setClassAddress] = useState(null);
    const [classPhoneNm, setClassPhoneNm] = useState(null);
    const [classEmail, setClassEmail] = useState(null);
    const [classAccountNm, setClassAccountNm] = useState(null);
    const navigate = useNavigate()
    const [modal, contextHolder] = Modal.useModal();

    const modalOpenGuide = {
        width: "50vw",
        content: (
            <GuideBusiness />
        )
    };

    const moveToDashBoard = async () => {
        if (fnMoveToDashBoard !== undefined && typeof fnMoveToDashBoard === "function") {
            await fnMoveToDashBoard();
        }
    }

    const onChangeBankValue = (event) => {
        if (isMobile) {
            setBank(event);

        } else
            setBank(event.target.value);
        if (sEmail !== "") {
            emailValidation();
        }
    }

    const onChangeCompanyValue = (event) => {
        setCompany(event.target.value);
    }

    const onChangeStoreNameValue = (event) => {
        setClassStoreNm("input_action");
        setStoreName(event.target.value);
        if (sEmail !== "") {
            emailValidation();
        }
    }
    const onChangeStoreOwnerValue = (event) => {
        setClassStoreOwner("input_action");
        setStoreOwner(event.target.value);
        if (sEmail !== "") {
            emailValidation();
        }
    }
    const onChangeExAddressValue = (event) => {
        setClassExtraAddress("input_action");
        setExAddress(event.target.value);
        if (sEmail !== "") {
            emailValidation();
        }
    }
    const onChangeEmailValue = (event) => {
        setClassEmail("input_action");
        setEmail(event.target.value);
        if (sEmail !== "") {
            emailValidation();
        }
    }

    const onChangeNmValue = (event) => {
        let value = event.target.value;
        if (value.indexOf("-")) {
            value = value.toString();
            value = value.replace(/\-/g, "");
        }

        if (value.toString().length < 11) {
            const reg = /^-?\d*(\.\d*)?$/;
            if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
                setClassBusinessNm("input_action");
                setBusinessNm(value);
            }
        }
    }

    const onChangePhoneNmValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setClassPhoneNm("input_action");
            setPhoneNm(value);
        }
    }

    const onChangeAccountNmValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setClassAccountNm("input_action");
            setAccountNm(value);
        }
    }

    const emailValidation = () => {
        const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

        if (regExp.test(sEmail)) {
            setClassEmail(null);
            setErrorMessageEmail("");
        } else {
            setClassEmail("input_wrong");
            setErrorMessageEmail("이메일 형식에 맞지 않습니다");
        }
    }

    const inputClassStoreNm = () => {
        validationErr("classStoreNm");
    }
    const inputClassStoreOwner = () => {
        validationErr("classStoreOwner");
    }
    const inputClassBusinessNm = () => {
        validationErr("classBusinessNm");
    }
    const inputClassExtraAddress = () => {
        validationErr("classExtraAddress");
    }
    const inputClassPhoneNm = () => {
        validationErr("classPhoneNm");
    }
    const inputClassEmail = () => {
        validationErr("classEmail");
    }
    const inputClassAccountNm = () => {
        validationErr("classAccountNm");
    }

    const validationErr = async (sIndex) => {
        const classNameBox = [
            { name: "classStoreNm", value: classStoreNm },
            { name: "classStoreOwner", value: classStoreOwner },
            { name: "classBusinessNm", value: classBusinessNm },
            { name: "classExtraAddress", value: classExtraAddress },
            { name: "classPhoneNm", value: classPhoneNm },
            { name: "classEmail", value: classEmail },
            { name: "classAccountNm", value: classAccountNm },
        ];

        for await (const iterator of classNameBox) {
            if (iterator.value !== "input_wrong") {
                if (sIndex === iterator.name) {
                    if (iterator.name === "classStoreNm") {
                        setClassStoreNm("input_action");
                    } else if (iterator.name === "classStoreOwner") {
                        setClassStoreOwner("input_action");
                    } else if (iterator.name === "classBusinessNm") {
                        setClassBusinessNm("input_action");
                    } else if (iterator.name === "classExtraAddress") {
                        setClassExtraAddress("input_action");
                    } else if (iterator.name === "classPhoneNm") {
                        setClassPhoneNm("input_action");
                    } else if (iterator.name === "classEmail") {
                        setClassEmail("input_action");
                    } else if (iterator.name === "classAccountNm") {
                        setClassAccountNm("input_action");
                    }
                } else {
                    if (iterator.name === "classStoreNm") {
                        setClassStoreNm(null);
                    }
                    if (iterator.name === "classStoreOwner") {
                        setClassStoreOwner(null);
                    }
                    if (iterator.name === "classBusinessNm") {
                        setClassBusinessNm(null);
                    }
                    if (iterator.name === "classExtraAddress") {
                        setClassExtraAddress(null);
                    }
                    if (iterator.name === "classPhoneNm") {
                        setClassPhoneNm(null);
                    }
                    if (iterator.name === "classEmail") {
                        setClassEmail(null);
                    }
                    if (iterator.name === "classAccountNm") {
                        setClassAccountNm(null);
                    }
                }
            }
        }

        if (sEmail !== "") {
            emailValidation();
        }
    }

    const handleComplete = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setClassAddress(null);
        setAddress(fullAddress);
        setAddressModal(false);
        message.success('주소입력이 완료되었습니다');
    }

    const uploadData = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        let oData = {
            store_id,
            storeName,
            storeOwner,
            sBusinessNm,
            sAddress,
            sPhoneNm,
            sEmail,
            sAccountNm,
            sBank,
            sExtraAddress
        }
        const oResponse = await oProps.oManager.fDoAxios("/proprietorship/register/v2", "post", "login", oData);
        if (oResponse !== undefined && oResponse !== null) {
            if (oResponse.resultCd === "0000") {
                message.success(oResponse.resultMsg);
                let oUserConfig = {};
                oUserConfig['isPOS'] = false;
                oUserConfig['LoggedIn'] = true;
                oUserConfig['StoreID'] = store_id;
                oUserConfig['StoreName'] = storeName;
                oUserConfig['StorePhone'] = sPhoneNm;
                oUserConfig['StoreOwner'] = storeOwner;
                oUserConfig['Address'] = sAddress;
                oProps.reduxSetUserConfig(oUserConfig);
                // moveToDashBoard();
            } else {
                message.error(oResponse.resultMsg);
            }
        }
    }

    const registerCompany = async () => {
        if (sCompany !== "") {
            const oData = {
                store_id: oProps.UserConfigReducer.StoreID,
                sCompany,
                salesId: oProps.UserConfigReducer.SalesId,
            }
            const oResponse = await oProps.oManager.fDoAxios("/proprietorship/coperation/register", "post", "login", oData);
            if (oResponse !== undefined && oResponse !== null) {
                if (oResponse.resultCd === "0000") {
                    message.success(oResponse.resultMsg);
                    // moveToDashBoard();
                    navigate(WebRoute.ADMIN_SALES_DASHBOARD)
                } else {
                    message.error(oResponse.resultMsg);
                }
            }
        }
    }

    const onRegister = () => {
        let temp = false;
        if (storeName === "") {
            setClassStoreNm("input_wrong");
            temp = true;
        }
        if (storeOwner === "") {
            setClassStoreOwner("input_wrong");
            temp = true;
        }
        if (sBusinessNm === "") {
            setClassBusinessNm("input_wrong");
            temp = true;
        }
        if (sAddress === "") {
            setClassAddress("input_wrong");
            temp = true;
        }
        if (sPhoneNm === "") {
            setClassPhoneNm("input_wrong");
            temp = true;
        }
        if (sEmail === "") {
            setClassEmail("input_wrong");
            temp = true;
        }
        if (sAccountNm === "") {
            setClassAccountNm("input_wrong");
            temp = true;
        }

        if (!temp) {
            uploadData();
        }
    }

    const getData = async () => {
        const sParam = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/proprietorship/userBusinessInfo-" + sParam, "get", "login", null);
        if (oResponse !== undefined && oResponse !== null) {
            if (oResponse.resultCd === "0000") {
                if (oResponse.lock) {
                    setRegister(oResponse.lock);
                }
                setStoreName(oResponse.store_name);
                setStoreOwner(oResponse.full_name);
                setBusinessNm(oResponse.business_number);
                setAddress(oResponse.address1);
                setExAddress(oResponse.address2);
                setPhoneNm(oResponse.userPhone);
                setEmail(oResponse.email);
                setAccountNm(oResponse.account_nm);
                setBank(oResponse.bank_name);
                setCoperationList(oResponse.groupList);
                if (oResponse.companyName !== undefined && oResponse.companyName !== null && oResponse.companyName !== "") {
                    setCompany(oResponse.companyName);
                }
            }
        }
    }

    useEffect(() => {
        getData();
    }, [nStatus]);

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.business}>
            <BrowserView>
                <Style>
                    <div className="content_container">
                        <div className="business">
                            <div className="businessTitle">
                                <h2>사업자 정보</h2>
                                <div className="tip">
                                    <SVG src={manual} width="18px" height="18px" />
                                    <span><strong>사업자 인증을 통해 </strong>판매자격 및<br />정산정보를 최종 승인 받습니다.</span>
                                </div>
                            </div>
                            <div className="inputText">
                                <h3>상호명</h3>
                                <input
                                    type="text"
                                    placeholder="프랜차이즈는 ‘ 브랜드명 + 지점명 ’"
                                    className={classStoreNm}
                                    onClick={inputClassStoreNm}
                                    value={storeName}
                                    onChange={onChangeStoreNameValue}
                                />
                            </div>
                            <div className="inputText">
                                <h3>대표자명</h3>
                                <input
                                    type="text"
                                    placeholder="사업자 등록증상의 대표자와 동일해야 합니다."
                                    value={storeOwner}
                                    onChange={onChangeStoreOwnerValue}
                                    className={classStoreOwner}
                                    onClick={inputClassStoreOwner}
                                />
                            </div>
                            <div className="inputText">
                                <h3>사업자번호</h3>
                                <input
                                    type="text"
                                    disabled={isRegister ? true : false}
                                    placeholder="‘ - ’ 하이픈 없이 숫자만 입력해주세요."
                                    value={sBusinessNm}
                                    onChange={onChangeNmValue}
                                    className={classBusinessNm}
                                    onClick={inputClassBusinessNm}
                                />
                            </div>
                            <div className="inputText">
                                <h3>사업장 소재지</h3>
                                <div className="address">
                                    <input type="text" disabled className={classAddress} value={sAddress} placeholder="검색을 통해서 입력해주세요." style={{ marginBottom: "8px", backgroundColor: "#F1F3F7" }} />
                                    <button className="search_box" onClick={() => { setAddressModal(true) }}><img src={addressSearch} /></button>
                                </div>
                                <input
                                    type="text"
                                    placeholder="나머지 주소를 입력하세요."
                                    value={sExtraAddress}
                                    onChange={onChangeExAddressValue}
                                    className={classExtraAddress}
                                    onClick={inputClassExtraAddress}
                                />
                            </div>
                            <div className="inputText">
                                <h3>대표자 전화번호</h3>
                                <input
                                    type="text"
                                    placeholder="대표자 핸드폰번호를 입력해주세요."
                                    value={sPhoneNm}
                                    onChange={onChangePhoneNmValue}
                                    className={classPhoneNm}
                                    onClick={inputClassPhoneNm}
                                />
                            </div>
                            <div className="businessTitle" style={{ marginTop: "5%" }}>
                                <h2>계좌정보</h2>
                                <div className="tip">
                                    <SVG src={manual} width="18px" height="18px" />
                                    <span><strong>정산에 필요한</strong>계좌정보를 받습니다.</span>
                                </div>
                            </div>
                            <div className="inputText">
                                <h3>이메일</h3>
                                <input
                                    type="text"
                                    placeholder="세금계산서 및 공지 등을 수신할 메일입니다."
                                    value={sEmail}
                                    onChange={onChangeEmailValue}
                                    className={classEmail}
                                    onClick={inputClassEmail}
                                />
                            </div>
                            <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageEmail}</p>
                            <div className="inputText">
                                <h3>입금은행</h3>
                                <select value={sBank} onChange={onChangeBankValue} placeholder="계좌 은행을 선택해주세요.">
                                    {bankTypeList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.name}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            <div className="inputText">
                                <h3>계좌번호</h3>
                                <input
                                    type="text"
                                    placeholder="대표자명과 동일한 계좌번호를 입력해주세요."
                                    value={sAccountNm}
                                    onChange={onChangeAccountNmValue}
                                    className={classAccountNm}
                                    onClick={inputClassAccountNm}
                                />
                            </div>
                            <button className='active_button' onClick={onRegister}>
                                {isRegister ? "수정하기" : "등록하기"}
                            </button>
                        </div>
                        {(oProps.UserConfigReducer.SalesId !== undefined && oProps.UserConfigReducer.SalesId !== null && oProps.UserConfigReducer.SalesId > 0) &&
                            <div className="number">
                                <h2>매장 소속 관리</h2>
                                <div className="inputText" style={{ marginTop: "5%" }}>
                                    <h3>소속선택</h3>
                                    <select value={sCompany} onChange={onChangeCompanyValue} placeholder="매장이 소속된 업체를 선택해주세요.">
                                        {coperationList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.key}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <button className='active_button' onClick={registerCompany}>
                                    등록하기
                                </button>
                            </div>
                        }

                    </div>
                    <div className="side_help_content">
                        <div className="app_guide_modal_container" onClick={() => modal.success(modalOpenGuide)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={guide_question} alt="none" />
                                    <p>간편도움말</p>
                                </div>
                                <div className="detail_box">
                                    <p>입력예시를 통해 <br />입력방법을 알려드립니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal visible={addressModal} centered={true} closable={false} onOk={() => { setAddressModal(false) }} onCancel={() => { setAddressModal(false) }} okText="확인" cancelText="취소">
                        <AddressBox>
                            <DaumPostcode onComplete={handleComplete} />
                        </AddressBox>
                    </Modal>
                    {contextHolder}
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    <div className="buisness">
                        <div className="businessTitle">
                            <p>사업자 정보</p>
                            <div className="tip">
                                <SVG src={manual} width="18px" style={{}} />
                                <span><strong>사업자 인증을 통해 </strong>판매자격 및<br />정산정보를 최종 승인 받습니다.</span>
                            </div>
                        </div>
                        <div className="inputText">
                            <h3>상호명</h3>
                            <input
                                type="text"
                                placeholder="프랜차이즈는 ‘ 브랜드명 + 지점명 ’"
                                className={classStoreNm}
                                onClick={inputClassStoreNm}
                                value={storeName}
                                onChange={onChangeStoreNameValue}
                            />
                        </div>
                        <div className="inputText">
                            <h3>대표자명</h3>
                            <input
                                type="text"
                                placeholder="사업자 등록증상의 대표자와 동일해야 합니다."
                                value={storeOwner}
                                onChange={onChangeStoreOwnerValue}
                                className={classStoreOwner}
                                onClick={inputClassStoreOwner}
                            />
                        </div>
                        <div className="inputText">
                            <h3>사업자번호</h3>
                            <input
                                type="text"
                                placeholder="‘ - ’ 하이픈 없이 숫자만 입력해주세요."
                                disabled={isRegister ? true : false}
                                value={sBusinessNm}
                                onChange={onChangeNmValue}
                                className={classBusinessNm}
                                onClick={inputClassBusinessNm}
                            />
                        </div>
                        <div className="inputText">
                            <h3>사업장 소재지</h3>
                            <div className="address">
                                <input type="text" disabled className={classAddress} value={sAddress} placeholder="검색을 통해서 입력해주세요." style={{ marginBottom: "8px", backgroundColor: "#F1F3F7" }} />
                                <button className="search_box" onClick={() => { setAddressModal(true) }}><img src={addressSearch} /></button>
                            </div>
                            <input
                                type="text"
                                placeholder="나머지 주소를 입력하세요."
                                value={sExtraAddress}
                                onChange={onChangeExAddressValue}
                                className={classExtraAddress}
                                onClick={inputClassExtraAddress}
                            />
                        </div>
                        <div className="inputText">
                            <h3>대표자 전화번호</h3>
                            <input
                                type="text"
                                placeholder="대표자 핸드폰번호를 입력해주세요."
                                value={sPhoneNm}
                                onChange={onChangePhoneNmValue}
                                className={classPhoneNm}
                                onClick={inputClassPhoneNm}
                            />
                        </div>
                    </div>
                    <div className="number">
                        <h2>계좌정보</h2>
                        <div className="inputText">
                            <h3>이메일</h3>
                            <input
                                type="text"
                                placeholder="세금계산서 및 공지 등을 수신할 메일입니다."
                                value={sEmail}
                                onChange={onChangeEmailValue}
                                className={classEmail}
                                onClick={inputClassEmail}
                            />
                        </div>
                        <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageEmail}</p>
                        <div className="inputText">
                            <h3>입금은행</h3>
                            <Select value={sBank} onChange={onChangeBankValue} className="select_bank" placeholder="계좌 은행을 선택해주세요." bordered={false}>
                                {bankTypeList.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.name}>{item.name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="inputText">
                            <h3>계좌번호</h3>
                            <input
                                type="text"
                                placeholder="대표자명과 동일한 계좌번호를 입력해주세요."
                                value={sAccountNm}
                                onChange={onChangeAccountNmValue}
                                className={classAccountNm}
                                onClick={inputClassAccountNm}
                            />
                        </div>
                    </div>
                    <div className="last">
                        <button className="finishButoon" onClick={onRegister}>
                            {isRegister ? "수정하기" : "등록하기"}
                        </button>
                    </div>
                    <Modal visible={addressModal} centered={true} closable={false} onOk={() => { setAddressModal(false) }} onCancel={() => { setAddressModal(false) }} okText="확인" cancelText="취소">
                        <AddressBox>
                            <DaumPostcode onComplete={handleComplete} />
                        </AddressBox>
                    </Modal>
                </Mstyle>

            </MobileView>

        </Layout>
    )

}

export default Business;


const bankTypeList = [
    { name: "산업은행" },
    { name: "기업은행" },
    { name: "KB국민은행" },
    { name: "수협은행" },
    { name: "NH농협은행" },
    { name: "우리은행" },
    { name: "SC제일은행" },
    { name: "한국씨티은행" },
    { name: "대구은행" },
    { name: "부산은행" },
    { name: "광주은행" },
    { name: "제주은행" },
    { name: "전북은행" },
    { name: "경남은행" },
    { name: "새마을금고" },
    { name: "신협" },
    { name: "하나은행" },
    { name: "신한은행" },
    { name: "케이뱅크" },
    { name: "카카오뱅크" },
    { name: "우체국" }
];

const AddressBox = styled.div`
    margin-left: 2%;
`;

const Style = styled.div`
    position: relative;

    /* 간편도움말 공통css */
    .side_help_content {
        position: absolute;
        left: 121%;
        top: 0;
    }
    .app_guide_modal_container{
        padding: 13px 13px 40px 13px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #FBFBFB;
        border: 1px solid #DFDFE3;
        border-radius: 8px;
        
        .arrow_box img {
            width: 5px;
        }
        .text_box {
            margin-left: 5px;
            width: 220px;

            .title_box {
                height: auto;
                margin-bottom: 9px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                }
            }
            .help_content_img {
                width: 18px;
            }
        }
    }

    .content_container {
        width: 42vw; 
        border-radius: 4px;
        margin-bottom: 4%;
        background-color: #F1F3F7;

        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 180px;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }

        

        .inputText {
            h3 {
                margin-top: 16px;
                margin-bottom: 8px;
                font-size: 0.9rem;
                font-weight: 400;
            }
            input {
                padding: 12px;
                width: 100%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
                outline: none;
            }

            .input_action {
                border: solid 1px #000000;
                transition: 0.1s ease-out;
            }

            .input_wrong {
                border: solid 1px #DD1212;
                transition: 0.1s ease-out;
            }

            input::placeholder {
                font-size: 0.9rem;
                font-weight: 400;
                color: #BBBBBB;
            }
            select {
                padding: 12px;
                width: 100%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
                outline: none;
            }
            select::placeholder {
                font-size: 0.9rem;
                font-weight: 400;
                color: #BBBBBB;
            }
            .address {
                position: relative;
                margin-bottom: 8px;

                .search_box {
                    width: 78px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    background: #617BE3;
                }
            }
            .address input {
                width: 100%;
                position: absolute;
            }
            .address button {
                display: flex;
                align-items: flex-end;
                margin-left: auto;
                position: relative;
                top: 0;
                bottom: 0;
                right: 0%;
                border: 0;
                outline: 0;
            }
        }

        .menu {
            width: 100%;
            margin-bottom: 16px;
            padding: 32px;
            font-size: 16px;
            font-weight: 700;
            background-color: #fff;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            border-radius: 4px;

            button {
                background-color: #fff;
                outline: none;
                border: none;
                margin-right: 24px;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .businessTitle {
            display: flex;
            align-items: center;

            h2 {
                font-size: 1rem;
                font-weight: 700;
            }

            svg {
                margin-left: 7px;
            }
        }

        .business, .number {
            padding: 32px;
            margin-bottom: 16px;
            background-color: #fff;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            border-radius: 4px;
        }

        .number {
            margin: 0;

            h2 {
                font-size: 1rem;
                font-weight: 700;
            }
            
            button {
                width: 100%;
                padding: 17px 0;
                margin-top: 32px;
                background-color: #617be3;
                font-size: 1rem;
                font-weight: 700;
                color: #fff;
                border-radius: 4px;
                border: none;
                outline: none;
            }
        }
    }

    .active_button {
        width: 100%;
        padding: 17px 0;
        margin-top: 32px;
        background-color: #617be3;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        border-radius: 4px;
        border: none;
        outline: none;
    }
`;

const Mstyle = styled.div`
    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .businessTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 78px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }
    }

    .buisness, .number {
        padding: 20px 16px;
        margin-bottom: 8px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .last {
        width: 100%;
        padding: 24px 16px 32px 16px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  
   
`;