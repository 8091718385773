import React from 'react';
import { Column } from '@ant-design/charts';

interface ConfigData {
  iChart: any;
}

const ColumnChart: React.FC<ConfigData> = props => {
  return <Column {...props.iChart}/>;
};
export default ColumnChart;
