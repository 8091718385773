import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PosGuide = (oProps) => {
    useEffect(() => {
        window.tabMenuAction();
    }, []);

    return (
        <PosGuideStyle>
            <div className="notice">
                <div className="container">
                    <div className="guide">
                        <h1>이용가이드</h1>
                        <div className="content">
                            <div className="tab-container">
                                <div className="tab-menu">
                                    <ul>
                                        <li><a href="#" className="tab-a active-a" data-id="tab1">스루 주문접수</a></li>
                                        <li><a href="#" className="tab-a" data-id="tab2">프린트 가이드</a></li>
                                        <li><a href="#" className="tab-a" data-id="tab3">포스 가이드</a></li>
                                    </ul>
                                </div>
                                <div className="tab tab-active" data-id="tab1">
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'posguide', search: 'posguide' }}>
                                            <div className="guideTitle">
                                                <h3>스루 주문접수</h3>
                                                <h2>스루 주문접수 가이드</h2>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'autoguide', search: 'autoguide' }}>
                                            <div className="guideTitle">
                                                <h3>스루 주문접수</h3>
                                                <h2>자동으로 주문 접수 가능하게 설정하는 방법</h2>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'cancelguide', search: 'cancelguide' }}>
                                            <div className="guideTitle">
                                                <h3>스루 주문접수</h3>
                                                <h2>스루 주문 취소 방법</h2>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="tab " data-id="tab2">
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'printguide', search: 'printguide' }}>
                                            <div className="guideTitle">
                                                <h3>프린트 가이드</h3>
                                                <h2>스루 프린트 설정 방법</h2>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="tab " data-id="tab3">
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'v3guide', search: 'v3guide' }}>
                                            <div className="guideTitle">
                                                <h3>포스 가이드</h3>
                                                <h2>포스 설치 후 실행이 안돼요 (안랩 V3 버전)</h2>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'estguide', search: 'estguide' }}>
                                            <div className="guideTitle">
                                                <h3>포스 가이드</h3>
                                                <h2>포스 설치 후 실행이 안돼요 (알약 버전)</h2>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'soundguide', search: 'soundguide' }}>
                                            <div className="guideTitle">
                                                <h3>포스 가이드</h3>
                                                <h2>고객 근접, 도착 알림 설정</h2>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="guideContent">
                                        <Link to={{ pathname: '/ceoguide', page_id: 'timesettingguide', search: 'timesettingguide' }}>
                                            <div className="guideTitle">
                                                <h3>포스 가이드</h3>
                                                <h2>포스에서 매장 준비 시간 설정 방법</h2>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PosGuideStyle>
    )
}

export default PosGuide;

export const PosGuideStyle = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .container {
        width: 1120px;
        margin: 0 auto;
    }
    p, a, h1, h2, h3, h4, h5, h6, li, ul, button {
        font-family: 'NanumSquare';
        transform: skew(-0.1deg);
    }
    h1 {
        margin-bottom: 48px;
        font-size: 54px;
        font-weight: 900;
        text-align: center;  
        color: #212329;
    }
    h2 {
        padding: 5px 0 22px 0;
        font-size: 18px;
        font-weight: 900;
        line-height: 36px;

    }
    h3 {
        font-size: 14px;
        font-weight: 700;
        color: #888;
    }
    ul,li {
        list-style: none;
    }

    /* tabMenu */
    .tab-menu ul {
        margin: 0 0 40px 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 900;
        border-bottom: 1px solid #ECEEF2;
    }

    .tab-menu ul li {
        list-style-type: none;
        display: inline-block;
        padding-bottom: 14px;
    }

    .tab-menu ul li a {
        text-decoration: none;
        font-size: 14px;
        font-weight: 900;
        margin-right: 42px;
        color: #888888;
        font-weight: 700;
        padding-bottom: 16px;
    }

    .tab-menu ul li a.active-a {
        border-bottom: 3px solid #000;
        color: #000;
        font-size: 14px;
        font-weight: 900;
        
    }

    .tab {
        display: none;
    }

    .tab-active {
        display: block;
    }


    .guide {
        margin-top: 150px;
    }
    .guideContent {
        margin-top: 40px;
        border-bottom: 1px solid #ECEEF2;
    }
    .guideContent:last-child {
        margin-bottom: 180px;
        border-bottom: none;
    }
`