import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { isMobile, osName } from "react-device-detect";
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { message, DatePicker, Checkbox } from 'antd';
import Loader from "react-loader-spinner";
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment-timezone';
import '../../../assets/fonts/font.css'
import "antd/dist/antd.css";
import 'moment/locale/ko';

import closeButton from '../../../assets/img/modal/closeBtn.png';

export const ModalInventory = ({ sProps, fnClose, fnComplete, dataBox }) => {
    const [modalIsOpen, setIsOpen] = useState(true);
    const [list, setList] = useState([]);

    const closeModal = () => {
        if(typeof fnClose === "function" && fnClose !== undefined){
            fnClose();
        }
    }

    const alertMessage = (sIndex) => {
        message.info({
            content: sIndex,
            className: 'custom-class',
            style:{
                fontSize: "0.9rem",
                fontWeight: "500"
            }
        });
    };

    const changeOptionStatus = async (hItem,sItem) => {
        let tempProgress = false;
        let tempMessage = "";

        if(parseInt(sItem.itemStatus) > 0){
            if(parseInt(hItem.minNm) > 0){
                tempMessage = `해당옵션그룹은 옵션이 최소한 ${hItem.minNm}개가 필요합니다`;
                if(parseInt(hItem.minNm) <= (parseInt(hItem.totalCount) - parseInt(hItem.soldOutCount) - 1)){
                    tempProgress = true;
                }
            } else {
                tempMessage = `옵션그룹에 옵션이 1개이상 존재해야합니다`;
                if(parseInt(hItem.totalCount) > (parseInt(hItem.soldOutCount) + 1)){
                    console.log("4");
                    tempProgress = true;
                }
            }
        } else {
            tempProgress = true;
        }

        if(tempProgress){
            const oData = {
                sIndex: sItem.baseId,
                aIndex: sItem.itemStatus,
                iIndex: sItem.itemId
            };
            const oResponse = await sProps.oManager.fDoAxios("/inventory/option/changeStatus", "post", "login", oData);
            if(oResponse !== undefined){
                let tempText = "";
                if(oResponse){
                    tempText = "처리되었습니다.";
                } else {
                    tempText = "네트워크에러입니다 잠시 후 다시 시도바랍니다";
                }
                alertMessage(tempText);
                asyncData();
            }
        } else {
            alertMessage(tempMessage);
        }
    }

    const changeStatus = async () => {
        const oData = {
            sIndex: dataBox.id,
            aIndex: dataBox.status
        };
        const oResponse = await sProps.oManager.fDoAxios("/inventory/changeStatus", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse === "0000"){
                if(typeof fnComplete === "function" && fnComplete !== undefined){
                    fnComplete();
                }
            } else {
                alertMessage("네트워크에러입니다 잠시 후 다시 시도바랍니다");
            }
        }
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const asyncData = async () => {
        const product_id = dataBox.id;
        const oResponse = await sProps.oManager.fDoAxios("/inventory/product/optionList-" + product_id, "get", "login", null);
        if(oResponse !== undefined){
            if(oResponse.length > 0){
                setList(oResponse);
            }
        }
    }

    useEffect(() => {
        asyncData();
    }, []);

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '10%',
                    left: '35%',
                    border: 'none',
                    width: "30vw",
                    heigth: "50vh",
                    bottom: "15%",
                    background: '#fff',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <ModalInventoryStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>관리</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className='content_box'>
                        <div className='menuContent'>
                            <div className="box">
                                <img src={dataBox.url} className="box_image" alt="none"/>
                            </div>
                            <div className="box_content">
                                <div className='title'>
                                    <p>{dataBox.title}</p>
                                </div>
                            </div>
                        </div>
                        {list.length > 0 && 
                            <>
                            {list.map((item,index) => {
                                return (
                                    <div key={index}>
                                        <div className='option_group_box'>
                                            <p>{item.groupNm}</p>
                                        </div>
                                        {(item.list !== undefined && item.list !== null && item.list.length > 0) && 
                                            <>
                                            {item.list.map((kItem,kIndex) => {
                                                return (
                                                    <div className='option_box' key={kIndex}>
                                                        <div className='option_title'>
                                                            <p>{kItem.itemNm}</p>
                                                        </div>
                                                        <div className='option_button'>
                                                            <div className={!kItem.itemStatus ? "stock" : "sold_out"} onClick={() => changeOptionStatus(item,kItem)}>
                                                                <p>{kItem.itemStatus ? "일시품절로 변경": "판매재개"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            </>
                                        }
                                    </div>
                                )
                            })}
                            </>
                        }
                    </div>
                    <div className='button_box'>
                        <div className={dataBox.status ? "stock" : "sold_out"} onClick={() => changeStatus()}>
                            <p>{!dataBox.status ? `${dataBox.title} 일시품절로 변경`: `${dataBox.title} 판매재개`}</p>
                        </div>
                    </div>
                </div>

            </ModalInventoryStyle>
        </Modal>

    )
}

const ModalInventoryStyle = styled.div`
.container {
    font-family: 'Pretendard';

    .title_box {
        display: flex;
        height: 5vh;
        width: 100%;
        align-items: center;
        justify-content: center;

        p {
            padding-top: 2vh;
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .content_box::-webkit-scrollbar {
        display: none;
    }
    .content_box {
        padding-top: 3vh;
        height: 55vh;
        width: 100%;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        .option_group_box {
            border-radius: 10px;
            padding: 15px;
            height: 5vh;
            background : #f6f6f6;
            p {
                font-size: 0.9rem;
                font-weight: 600;
            }
        }
        .option_box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 8vh;

            .option_title {
                display: flex;
                align-items: center;
                height: 8vh;
                width: 60%;

                p {
                    padding-top: 2vh;
                    padding-left: 1vw;
                    font-size: 0.9rem;
                    font-weight: 400;
                }
            }

            .option_button {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 8vh;
                width: 40%;

                .sold_out {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 4vh;
                    width: 7vw;
                    border-radius: 5px;
                    background: #FFB031;
                    cursor: pointer;
                    
                    p {
                        padding-top: 1.5vh;
                        font-size: 0.8rem;
                        font-weight: 500;
                        color: #fff;
                    }
                }

                .stock {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 4vh;
                    width: 7vw;
                    border-radius: 5px;
                    background: #E8F3FF;
                    cursor: pointer;
                    
                    p {
                        padding: 15px 0px 0px 0px;
                        font-size: 0.8rem;
                        font-weight: 600;
                        color: #353D4E;
                    }
                }
            }
        }

        .menuContent {
            display: flex;
            height: 16vh;

            .box {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 15vh;
                height: 15vh;
                border-radius: 4px;
    
                .box_image {
                    width: 15vh;
                    height: 15vh;
                    border-radius: 5%;
                }
            }

            .box_content {
                height: 15vh;
                width: 100%;
                margin-left: 5%;

                .title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 15vh;
                    width: 100%;
        
                    p {
                        margin-top: 5%;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .button_box {
        display: flex;
        height: 10vh;
        width: 100%;
        align-items: center;
        justify-content: center;
        
        .sold_out {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5vh;
            width: 100%;
            border-radius: 10px;
            background: #DD1212;
            cursor: pointer;
            
            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
                color: #fff;
            }
        }

        .stock {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5vh;
            width: 100%;
            border-radius: 10px;
            background: #617be3;
            cursor: pointer;

            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
                color: #fff;
            }
        }
    }
}
`;