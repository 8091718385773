import React, { useState } from 'react';
import {
    isMobile
} from "react-device-detect";

import M_ceoLanding from "./mobile/M_ceoLanding";
import D_ceoLanding from "./desktop/D_ceoLanding";

const MainCeoLanding = (oProps) => {
    const LendingRender = () => {
        if (isMobile) {
            return <M_ceoLanding />
        }

        return <D_ceoLanding />
    }

    return (
        <>
            {LendingRender()}
        </>
    )
}

export default MainCeoLanding;