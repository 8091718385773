import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from "react-router-dom";
import SVG from 'react-inlinesvg';
import '../../../assets/css/font.css';
import './HeaderSliderbar';

import { WebRoute } from '../../../navigation/WebRoutes';

// SVG
import logo from "../../../assets/svg/mainPage/throoCeoLogo.svg";
import hamburger from "../../../assets/svg/mainPage/hamburgerBtn.svg";


const HeaderPage = ({ toggle, oProps }) => {

    const navigate = useNavigate();
    const moveToPath = async (sIndex) => {
        if (sIndex === "HOME") {
            navigate(WebRoute.HOME);
        }
    }

    return (
        <M_headerStyle>
            <div className="header">
                <div className="hContainer">
                    <div className="heaerContent">
                        <SVG onClick={() => moveToPath("HOME")} src={logo} title="스루사장님창구로고" style={{ marginRight: 'auto' }} />
                        <button><Link to={WebRoute.JOINFORM} >입점신청</Link></button>
                        <MobileIcon onClick={toggle} >
                            <SVG src={hamburger} width="26px" height="20px" title="메뉴더보기버튼" />
                        </MobileIcon>
                    </div>
                </div>
            </div>
        </M_headerStyle>
    )
}

export default HeaderPage;

export const M_headerStyle = styled.aside`
    /* reset */
    ul,ol,li {
        text-decoration: none;
        list-style: none;
    }

    html,
    body {
        display: block;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    
    /* container */
    .hContainer {
        max-width: 100%;
        height: 60px;
        margin: 0 auto;
    }

    /* header */
    .header {
        position: fixed;
        max-width: 100%;
        min-width: 100%;
        height: 60px;
        padding: 0 5vw;
        background-color: #fff;
        top: 0;
        border-bottom: 1px solid rgba(238, 238, 238, .3);
        z-index: 10;
    }
    .heaerContent {
        max-width: 100%;
        min-width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    button {
        margin-right: 10px;
        background: none;
        border: none;
        outline: none; 
    }
    button a {
        padding: 10px 12px;
        background-color: #1A7CFF;
        position: relative!important;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        
    }
`

export const MobileIcon = styled.div`
    
    
`;