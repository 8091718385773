import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';

import promotionBanner from '../../../../assets/img/guide/m_20220601_3.jpg';
import promotion01 from '../../../../assets/img/guide/m_20220601_1.png';
import promotion02 from '../../../../assets/img/guide/m_20220601_2.png';
import promotionButton from '../../../../assets/img/guide/20220601_button.png';
const promotionBenefits = (oProps) => {

    return (
        <M_benefits>
            <div className="ceoTip">
                <img src={promotionBanner} alt="얼리어탑터 사장님 프로모션 이미지" width="100%" />
                <div className="promotionBenefits">
                    <img src={promotion01} alt="얼리어탑터 사장님 프로모션 이미지" width="100%" />
                    <Link to={{ pathname: '/ceotip', page_id: 'mob_appBanner', search: 'mob_appBanner' }}><img className='benefitButton' src={promotionButton} alt="광고포인트내용 자세히보기 버튼" width="100%" /></Link>
                </div>
                <div className="throoContact">
                    <img src={promotion02} alt="얼리어탑터 사장님 프로모션 이미지" width="100%" />
                    <Link to={WebRoute.JOINFORM}><button className='throoButton'>스루 입점 신청하기</button></Link>
                </div>
            </div>
        </M_benefits>
    )
}

export default promotionBenefits;
export const M_benefits = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    img {
        margin-top: -3px;
    }
    .ceoTip {
        margin-top: 60px;
    }
    .promotionBenefits {
        position: relative;

        .benefitButton {
            position: absolute;
            bottom: 23%;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 50%;
        }
    }
    .throoContact {
        position: relative;

        .throoButton {
            position: absolute;
            bottom: 10%;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 80%;
            padding: 18px 60px;
            background: #1A7CFF;
            border: none;
            outline: none;
            border-radius: 6px;
            font-size: 20px;
            font-weight: 900;
            color: #fff;
        }
    }
`