import styled from 'styled-components';

const Style = styled.div`

/* 공통 */
h3 {
    font-size: 15px;
    font-weight: 500;
    margin: 25px 0 10px 0;
    color: #000;
}
p {
    margin-top: 1px;
    font-size: 15px;
    font-weight: 400;
}

/* ---일반옵션/상품인 옵션--- */
.option_type {
    width: 100%;
    padding: 7px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(241, 243, 247);
    border-radius: 4px;

    .selected_type,.unselect_selected_type {
        flex: 0 0 45%;
        padding: 6px 16px;
        background-color: #fff;
        border-radius: 4px;
        border: none;
        outline: none;

        p,span {
            color: #617BE3;
            font-weight: 700;
        }
        span {
            font-weight: 500;
        }
    }    
    .unselect_selected_type {
        background: none;

        p, span {
            color: rgb(161,161,161);
            font-weight: 400;
        }
    }
}

/* 필수 선택 여부 */
.essential_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0 10px 0;

    h3 {
        flex: 0 0 20%;
        margin: 0;
    }
    .select_essential_box {
        flex: 0 0 80%;

        button {
            padding: 6px 70px;
            background: #617BE3;
            outline: none;
            border-radius: 4px;
            border: 1px solid #617BE3;

            p {
                color: #fff;
            }
        }
        .unselect_wrapper{
            background: rgb(241, 243, 247);
            border: 1px solid rgb(161, 161, 161);

            p {
                color: rgb(161, 161, 161);
            }
        }
        .disable_wrapper {
            border: 1px solid #BBBBBB;
            border-radius: 5px;
            background: #fff;
            cursor: not-allowed;

            p {
                color: #bbbbbb;
            }
        }

    }
}


/* ---재고/품절 관리가 불필요한 선택 옵션 상품--- */
/* 옵션 안내사항 */
.notice_option {
    padding: 10px 15px;
    border-radius: 8px;
    background: #F1F3F7;
    margin-top: 10px;

    p {
        margin: 0;
        font-size: 12px;
        font-weight: 600;
        color: #DD1212;
    }
    
}
/* text input */
.category_title, .option_subTitle {
    input {
        width: 100%;
        padding: 10px;
        border: 1px solid #bbb;
        border-radius: 4px;
        outline: none;
    }
    .input_action {
        border: solid 1px rgb(161,161,161);
        transition: 0.1s ease-out;
    }
    .input_wrong {
        border: solid 1px #DD1212;
        transition: 0.1s ease-out;
    }
    input::placeholder {
        font-size: 14px;
        font-weight: 300;
        color: #BBBBBB;
    }
} 
.option_subTitle {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    select {
        background: red;
    }
    input:nth-child(1){
        flex: 0 0 55%;
    }
    .option_price {
        flex: 0 0 23%;
        position: relative;
        margin: 0 2%;
    }
    .pay_box {
        position: absolute;
        margin: 0;
        right: 5%;
        top: 25%;
    }
}

/* 필수 선택 개수 */
.category_num select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
}

/* 옵션 추가하기 버튼 */
.insert_box {
    flex: 0 0 18%;
    margin-left: auto;
    border : 1px solid #617BE3;
    border-radius: 4px;
    cursor: pointer;

    h3 {
        margin: 0;
        padding: 10px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: #617BE3;
    }
}
.insert_box:hover {
    background : #617BE3;

    h3 {
        color: #fff;
    }
}
/* 테이블 리스트 삭제 */
.delete_box {
    border: 1px solid #DD1212;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;

    p {
        margin: 4px 0;
        color: #DD1212;
    }
}
.delete_box:hover {
    background: #DD1212;
    p{
        color: #fff;
    }
}
/* 순서변경 안내문구 */
.location_box_line {
    margin-bottom: 50px;
    margin-top: 10px;

    .how_box {
        padding: 15px 10px;
        display: flex;
        align-items: center;
        background: #f1f3f7;
        border-radius: 4px;

        p {
            margin: 0;
            padding-left: 15px; 
            font-weight: 400;
            color: #666666;
        }
    }
} 

/* 등록 완료 */
.register_box, .submitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 15px 0;
    background: #617BE3;
    border-radius: 5px;
    cursor: pointer;

    p {
        margin: 0;
        font-size: 16px;
        font-weight: 500; 
        color: #fff;
    }
} 

/* 수정css */
.option_list {
    margin-bottom: 5%;
    margin-top: 5%;

    .pay_box {
        position: absolute;
        right: 33%;
        top: 30%;
    }

    .option_list_box {
        height: 7vh;
        margin-top: 5%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .option_name_list {
            height: 6vh;
            padding: 12px;
            width: 40%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
        }
        .option_price_list {
            height: 6vh;
            padding: 12px;
            width: 30%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
        }
        .option_order {
            width: 5%;
            justify-content: center;
            display:flex;
            path {
                fill:black!important
            }
        }
        
        .option_delete_box {
            height: 6vh;
            padding: 12px;
            width: 17%;

            p {
                font-size: 0.8rem;
                font-weight: 400;
                color: #dd1212;
                text-decoration: underline;
            }
        }

    }
} 

/* ---재고/품절 관리가 필요한 선택 옵션 상품 수정페이지--- */
/* 필수여부 */
.select_line_box {
    display: flex;  
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    .small_size {
        font-size: 0.7rem;
        font-weight: 400;
        color: #fff;
    }
    .selected_wrapper, .disable_wrapper {
        flex: 0 0 48%;
        padding: 10px 0;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        p {
            color: #fff;
        }
    }
    .disable_wrapper {
        background: #fff;
        border: 1px solid #BBBBBB;
        // cursor: not-allowed;

        p {
            color: #bbbbbb;
        }
    }

    h3 {
        flex: 0 0 20%;
        margin: 0;
    }
    .select_essential_box {
        flex: 0 0 80%;

        button {
            padding: 6px 70px;
            background: #617BE3;
            outline: none;
            border-radius: 4px;
            border: 1px solid #617BE3;

            p {
                color: #fff;
            }
        }
        .unselect_wrapper{
            background: rgb(241, 243, 247);
            border: 1px solid rgb(161, 161, 161);

            p {
                color: rgb(161, 161, 161);
            }
        }
        .disable_wrapper {
            border: 1px solid #BBBBBB;
            border-radius: 5px;
            background: #fff;
            cursor: not-allowed;

            p {
                color: #bbbbbb;
            }
        }

    }

}
/* 상품 선택&검색 */
.selectSearch {
    flex: 0 0 35%;
}
.default_price {
    display: flex;
    align-items: center;
    margin: 0 2%;

    p {
        margin: 0 3px;
        font-size: 16px;
        font-weight: 600;
        color: #000;
    }
}
.surcharge {
    flex: 0 0 25%;

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input::placeholder { 
        color: #888;
        font-weight: 400;
        opacity: 1;
    }
    input:-ms-input-placeholder,input::-ms-input-placeholder { 
        color: #888;
        font-weight: 400;
    }

}
/* 최소,최대 선택수 */
.category_title Select {
    width: 100%;
    padding: 10px 5px;
}
.option_box {
    max-width: 100%;
}

/* svg */
.drag-handle {
    path {
        fill: black !important;
        color: black !important;
    }
}

/* 모바일 */
.option_type_mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border: 1px solid #617BE3;
    border-radius: 8px;
    
    
    .selected_type,.unselect_selected_type {
        background: none;
        border: none;
        outline: none;
        width: 100%;
        padding: 4px;
        
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            line-height: 16px;

            span {
                font-weight: 500;
                font-size: 12px;
            }
        } 
    }
    .selected_type {
        background: #617BE3;
        min-height: 100%;

        p {
            font-weight: 700;
            color: #fff;
            max-height: 35px;
            min-height: 35px;
            vertical-align: center;

            span {
                color: #fff;
            }
        }
    }
    .selected_type.right {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .selected_type.left {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}
.essential_wrap_mobile {
    .select_essential_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .selected_wrapper, .unselect_wrapper,.disable_wrapper {
            width: 100%;
            border-radius: 4px;
            border: none;
            outline: none;
            background: #617BE3;

            p {
                padding: 8px;
                font-size: 14px;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselect_wrapper {
            background: rgb(241,243,247);
            border: 1px solid rgb(161,161,161);

            p {
                font-weight: 400;
                color: rgb(161,161,161);
            }
        }
        .disable_wrapper {
            border: 1px solid #BBBBBB;
            border-radius: 5px;
            background: #fff;
            cursor: not-allowed;

            p {
                color: #bbbbbb;
            }
        }
    }
}
.category_title_mobile {
    .option_subTitle input:nth-child(1){
        flex: 0 0 50%;
    }
    input {
        width: 100%;
        padding: 10px;
        border: 1px solid #bbb;
        border-radius: 4px;
        outline: none;
    }
    .insert_box {
        flex: 0 0 23%;
        padding: 3px 0;

        h3 {
            font-size: 13px;
        }   
    }
    .default_price {
        flex: 0 0 25%;
        p {
            font-size: 15px;
        }
    }
    .surcharge {
        flex: 0 0 43%;
    }
    .insert_box {
        flex: 0 0 25%;
    }
}


/* 모바일 */
.mobile_container {
    background: #fff;
    padding: 15px 3vw;

    p {
        margin-bottom: 5px;
    }
    .title_box {
       display: flex;
       align-items: center;
       justify-content: space-between;

       h2 {
        font-size: 16px;
        font-weight: 700;
        color: #000;
       }
       .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 14px;
            border: 1px solid #DD1212;
            border-radius: 5px;
            
            p {
                margin: 0;
                font-size: 14px;
                font-weight: 700;
                color: #DD1212;
            }
        }
    }
}
/* 모바일 옵션리스트 */
.option_list.moblie {
    
    .option_list_box_header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 15px 10px;
        text-align: left;
        background-color: #fafafa;
        border-bottom: 1px solid #f0f0f0;
    }
    .option_list_index {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height:auto!important;
        margin: 0!important;
        padding: 15px 10px;
        text-align: left;
        border-bottom: 1px solid #f0f0f0;

        p {
            margin: 0;
            padding: 0!important;
        }

    }
    .option_list_box:nth-of-type(even){
        background: lightgray;
    }
    .option_name_list {
        flex: 0 0 33%;
        display: flex;
        align-items: center;
        height: auto!important;
        max-width: 33%;
        margin-right: 2%;
        border: none;
    }
    .option_price_list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 0 0 25%;
        max-width: 25%;
        margin-right: 2%;
        border: none;
    }
    .option_order_title{
        flex: 0 0 7%;
        white-space: nowrap;
    }
    .option_order {
        width: 100%;
        flex: 0 0 7%;
        margin-right: 2%;

        path {
            fill:black!important
        }
    }
    .option_delete_box {
        padding: 0!important;
        height: auto!important;
        margin-left: auto;
        flex: 0 0 15%;
        text-align: center;
        border-radius: 4px;
    }
    .option_delete_box.button {
        padding: 4px 1px!important;
        height: auto!important;
        margin-left: auto;
        flex: 0 0 15%;
        text-align: center;
        background: none;
        border: 1px solid #DD1212;
        border-radius: 4px;
        p {
            font-size: 12px;
            font-weight: 700;
            color: #DD1212;
            text-decoration:none;
        }
    }
}
.product_option_add {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

/* 고정 수정완료 버튼 */
.bottom_fixed {
    width: 100%;
    position: sticky;
    bottom: 15px;
    .finishButton {
        width: 100%;
        padding: 12px 0;
        background-color: #617be3;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
        border-radius: 4px;
        border: none;
        outline: none;
        z-index:10;
    }
}  

`
export default Style;
