import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import { Pagination } from 'antd';
import '../../../assets/css/font.css';

import Accordion from "../accordion";
import Layout from '../Layout'
import layoutHeaderLists from '../config';
import { BrowserView, MobileView } from 'react-device-detect';
const StyledAccordionContent = styled(Accordion.AccordionContent)`
    display: flex;
`;

const Notice = ({ sProps }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [sCurrent, setCurrent] = useState(1);

    const [startPage, setStartPage] = useState(0);
    const [endPage, setEndPage] = useState(5);

    const [sList, setList] = useState([]);
    const [currentlyOpened] = useState([]);

    const onChangePage = page => {
        let tempStart = 0;
        let tempEnd = 5;

        if (parseInt(page) !== 1) {
            tempEnd = tempEnd * page;
            tempStart = tempEnd - 5;
        }
        setStartPage(tempStart);
        setEndPage(tempEnd);
        setCurrent(page);
    };

    const getList = async () => {
        const oResponse = await sProps.oManager.fDoAxios("/notice/noticeList/v2", "get", null, null);
        console.log(oResponse)
        if (oResponse !== undefined) {
            setList(oResponse);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getList();
    }, [])

    return (
        <Layout sProps={sProps} routeList={layoutHeaderLists.notice}>
            <BrowserView>
                <Style>
                    <div className="content_container">
                        {!isLoading ?
                            <Accordion.AccordionGroup currentlyOpened={currentlyOpened}>
                                {sList.slice(startPage, endPage).map((item, index) => (
                                    <Accordion.AccordionItem id={item.id} key={"listing-" + index.toString()}>
                                        <Accordion.AccordionHeader className="notice_line">
                                            {(accordionOpen) => (
                                                <>
                                                    <div className="notice_title" open={accordionOpen}>
                                                        {item.late &&
                                                            <div className="notice_flag" style={{ marginRight: "2%" }}><p>최신</p></div>
                                                        }
                                                        <div className="notice_header">
                                                            <p>{item.title}</p>
                                                        </div>
                                                    </div>
                                                    <div className="notice_date"><p>{item.date}</p></div>
                                                </>
                                            )}
                                        </Accordion.AccordionHeader>
                                        <StyledAccordionContent>
                                            <div className="notice_content">
                                                <p>{item.content}</p>
                                            </div>
                                        </StyledAccordionContent>
                                    </Accordion.AccordionItem>
                                ))}
                            </Accordion.AccordionGroup>
                            :
                            <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                        }
                        <div className="page_nation">
                            <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={sList.length} />
                        </div>
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    <div className="totalPage">
                        {!isLoading ?
                            <Accordion.AccordionGroup currentlyOpened={currentlyOpened}>
                                {sList.slice(startPage, endPage).map((item, index) => (
                                    <Accordion.AccordionItem id={item.id} key={"listing-" + index.toString()}>
                                        <Accordion.AccordionHeader className="menuContent">
                                            {(accordionOpen) => (
                                                <>
                                                    <div className="notice_title" open={accordionOpen}>
                                                        {item.late &&
                                                            <div className="notice_flag" style={{ marginRight: "2%" }}><p>최신</p></div>
                                                        }
                                                        <div className="notice_header">
                                                            <p>{item.title}</p>
                                                        </div>
                                                    </div>
                                                    <div className="notice_date"><p>{item.date}</p></div>
                                                </>
                                            )}
                                        </Accordion.AccordionHeader>
                                        <StyledAccordionContent>
                                            <div className="notice_content">
                                                <p>{item.content}</p>
                                            </div>
                                        </StyledAccordionContent>
                                    </Accordion.AccordionItem>
                                ))}
                            </Accordion.AccordionGroup>
                            :
                            <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                        }
                        <div className="page_nation">
                            <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={sList.length} />
                        </div>
                    </div>
                </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default Notice;


const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .page_nation {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 7vh;
    }

    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }
    .menu_container {
        margin-top: 10px;
        border-radius: 4px;

        .tab {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw; 
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .content_container {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 
        padding: 5%;

        .notice_content {
            padding: 24px;
            background: #F1F3F7;
            width: 100%;

            p {
                width: 100%;
                text-align: left;
                font-size: 0.8rem;
                font-weight: 400;
                color: #000;
                white-space: pre-wrap;
            }
        }

        .notice_line {
            border-bottom: 1px solid #dfdfdf;
            width: 100%;
            height: 9vh;

            .notice_title {
                display: flex;
                align-items: flex-end;
                height: 4vh;

                .notice_flag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3vw;
                    height: 2.5vh;
                    background: #1A2FC6;
                    border-radius: 5px;

                    p {
                        font-size: 0.7rem;
                        font-weight: 700;
                        color: #fff;
                    }
                }
                .notice_header {
                    height: 3vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    font-weight: 700;
                    color: #000;
                }
            }
            .notice_date {
                margin-top: 1vh;
                display: flex;
                align-items: flex-start;
                height: 5vh;

                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #000;
                }
            }
        }
    }

    .noticeContent {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: calc(100vw - 258px);
        min-height: calc(100vh - 102px - 72px);
        padding : 48px;
        background-color: #F1F3F7;
    }
    .left {
        width: 576px;

        .tab {
            width: 100%;
            padding: 32px;
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);

            button {
                margin-right: 24px;
                background-color: #fff;
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: 400;
                color: #000;
                border-bottom: 2px solid #000;
            }
        }
    }
    .notice {
        width: 100%;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
    }
    .pagination {
        padding: 12px 0;
        width: 100%;
        display: flex;
        justify-content: center;

        button {
            display: flex;
            align-items: center;
        }
    }


    /* 광고 */
    .right {
        margin-left: 16px;
        width: 349px;
        min-height: 700px;
        background-color: orange;
    }
    
`



const Mstyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }   

    .page_nation {
        margin-top: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 5vh;
        background: #fff;
    }

    .stockPage {
        background-color: #f1f1f1;
        padding-bottom: 5%;
    }

    .tapPage {
        background-color: #F1F3F7;
        padding-bottom: 8px;
    }

    .notice_content {
        background: #F1F3F7;
        padding: 24px;
        width: 100%;
        
        p {
            width: 100%;
            text-align: left;
            font-size: 0.8rem;
            font-weight: 400;
            color: #000;
            white-space: pre-wrap;
        }
    }
    .menu_container {
        background: #fff;
        height: 10vh;

        .tab {
            display: flex;
            align-items: center;
            height: 10vh;
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .totalPage {
        width: 100%;
        background-color: #fff;
        padding: 18px;
        .menuContent {
            height: 78px;
            border-bottom: 1px solid #dfdfdf;

            .notice_title {
                display: flex;
                align-items: flex-end;
                height: 39px;
                width:100%;
                .notice_flag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 31px;
                    height: 25px;
                    background: #1A2FC6;
                    border-radius: 5px;

                    p {
                        font-size: 0.7rem;
                        font-weight: 700;
                        color: #fff;
                    }
                }
                .notice_header {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #000;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                    width: 80%;
                }
            }
            .notice_date {
                display: flex;
                align-items: flex-start;
                height: 49px;

                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #000;
                }
            }
        }
        
    }
    
`

