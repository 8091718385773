import React from 'react';
import styled from 'styled-components';
import "antd/dist/antd.css"; 
import Layout from '../../Layout'
import layoutHeaderLists from '../../config';
import { BrowserView, MobileView } from 'react-device-detect';
const PrinterGuide = ({ sProps }) => {
    const windowPos = () => {
        window.open('https://api-pos.throo.kr/throopos/download.html','_blank',"height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
    }
    const androidPos = () => {
        window.open('https://play.google.com/store/apps/details?id=com.throo_ceo','_blank',"height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
    }

    return (
        <Layout sProps={sProps} routeList={layoutHeaderLists.guide}>
            <BrowserView>
        <PrintStyle>
            <div className="content_container">
                <div className="printGuide">
                    <div className="printImg">
                        <img src="https://api.throo.kr/img/guide/print01.png" width="100%" alt="프린트가이드" />
                    </div>
                    <div className="printImg">
                        <img src="https://api.throo.kr/img/guide/print02.png" width="100%" alt="프린트가이드" />
                    </div>
                    <div className="printImg">
                        <img src="https://api.throo.kr/img/guide/print03.png" width="100%" alt="프린트가이드" />
                    </div>
                    <div className="printImg">
                        <img src="https://api.throo.kr/img/guide/print04.png" width="100%" alt="프린트가이드" />
                    </div>
                    <div className="printImg">
                        <img src="https://api.throo.kr/img/guide/printTip.png" width="100%" alt="프린트가이드" />
                    </div>
                    <div className="printImg">
                        <img src="https://api.throo.kr/img/guide/print05.png" width="100%" alt="프린트가이드" />
                    </div>
                    <div className="btn">
                        <p>
                            ✅ <strong>스루 포스 다운로드</strong>   
                            <br />주문 받을 POS에서 아래 다운로드 버튼을 눌러주세요.
                        </p>
                        <div className="posAndroid">
                            <button onClick={androidPos} style={{padding: "20px 55.5px", display:"flex", justifyContent:"center", alignItems:"center"}}><p>안드로이드 포스 다운로드</p></button>
                        </div>
                        <div className="posWindow">
                            <button onClick={windowPos} style={{padding: "20px 71px", display:"flex", justifyContent:"center", alignItems:"center" }}><p>윈도우 포스 다운로드</p></button>
                        </div>
                    </div>
                </div>
            </div>
        </PrintStyle>
            </BrowserView>
            <MobileView>
                <Mstyle>
        <div className="category_title_line">
            <div className="inputText">
                <div className="content_container">
                    <table className="header" style={{borderSpacing: 0, margin: "0 auto"}}>
                        <thead className="logo">
                            <tr style={{width: "100%", display: "inline-block"}}>
                                <th style={{padding: "0 20px"}}>
                                    <img src="https://api.throo.kr/img/guide/ps01.png" width="100%" alt="포스가이드" />
                                </th>
                            </tr>
                            <tr>
                                <th style={{padding: "0 20px"}}>
                                    <img src="https://api.throo.kr/img/guide/ps02.png" width="100%" alt="포스가이드" />
                                </th>
                            </tr>
                            <tr>
                                <th style={{padding: "0 20px"}}>
                                    <img src="https://api.throo.kr/img/guide/ps03.png" width="100%" alt="포스가이드" />
                                </th>
                            </tr>
                            <tr>
                                <th style={{padding: "0 20px"}}>
                                    <img src="https://api.throo.kr/img/guide/ps04.png" width="100%" alt="포스가이드" />
                                </th>
                            </tr>
                            <tr>
                                <th style={{padding: "0 20px"}}>
                                    <img src="https://api.throo.kr/img/guide/ps05.png" width="100%" alt="포스가이드" />
                                </th>
                            </tr>
                            <tr>
                                <th style={{padding: "0 20px"}}>
                                    <img src="https://api.throo.kr/img/guide/ps06.png" width="100%" alt="포스가이드" />
                                </th>
                            </tr>
                        </thead>
                    <tbody className="text" style={{textAlign: "left", width: "90%"}}>
                        <tr>
                            <td style={{padding: "40px 20px 10px 20px", fontWeight: "bold",color:"#212329"}}>✅ 스루 포스 다운로드</td>
                        </tr>
                        <tr>
                            <td style={{padding: "0 20px 20px 20px", fontWeight: 500, color:"#212329"}}>주문 받을 POS에서 아래 다운로드 버튼을 눌러주세요.</td>
                        </tr>
                        <tr>
                            <td style={{padding: "40px 0 60px 20px"}} onClick={androidPos}>
                                <a href="#" style={{padding: "20px 54px", backgroundColor: "#5671DA", color: "#fff", fontWeight: "bold", textDecoration: "none"}}>안드로이드 포스 다운로드</a>
                            </td>
                        </tr>
                        <tr style={{display: "block", margin: "0px 20px 0 20px", borderBottom: "2px solid #EEEEEE"}}>
                            <td style={{padding: "0px 0 60px 0"}} onClick={windowPos}>
                                <a href="#" style={{padding: "20px 70px" ,backgroundColor: "#5671DA", color: "#fff", fontWeight: "bold", textDecoration: "none"}}>윈도우 포스 다운로드</a>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
        </Mstyle>
            </MobileView>

        </Layout>
    )
}

export default PrinterGuide;

export const PrintStyle = styled.div`
    * {
        font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif'; 
        color: #212329;
    }
    .content_container {
        padding: 0 20px;
    }
    .printGuide {
        width: 100%;
        padding: 0 20px;
    }
    .btn {
        width: 100%;
        text-align: left;
        padding: 40px 0;

        p {
            font-size: 17px;
        }
        button {
            background-color: #5671DA;
            margin-top: 20px;
            border: none;
            outline: none;
            
            p {
                font-size: 17px;
                font-weight: 600;
                color: #ffffff;
            }
        }
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }

    .menu_container {
        margin-top: 10px;
        border-radius: 4px;

        .tab {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw; 
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    

    .content_container {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;

            h2 {
                margin-left: 5%;
                font-size: 1.1rem;
                font-weight: 700;
            }
        }

        .menuContent {
            border-bottom: 1px solid #DFDFDF;
            height: 10vh;
            margin-right: 5%;
            margin-left: 3%;
            .menu {
                display: flex;
                align-items: center;
                padding: 2%;

                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4vw;
                    height: 7vh;
                    margin-right: 16px;
                    border-radius: 4px;

                    .box_image {
                        width: 4vw;
                        height: 7vh;
                        border-radius: 5%;
                    }
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;

                    h3 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
                
            }
            .menu:nth-of-type(1) {
                border-top: 1px solid #DFDFDF;
            }

            .sold_out {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 6vw;
                height: 4vh;
                border-radius: 5%;
                border: 1px solid #DD1212;
                margin-left: auto;
                cursor: pointer;
                transition: 0.3s;

                h2 {
                    padding: 0;
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }
            .sold_out:hover {
                border: none;
                background: #DD1212;
                h2 {
                    color: #fff;
                }
            }

            .stock {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 6vw;
                height: 4vh;
                border-radius: 5%;
                background: #617be3;
                margin-left: auto;
                cursor: pointer;
                transition: 0.3s;

                h2 {
                    padding: 0;
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .stock:hover {
                border: 1px solid #617be3;
                background: #fff;
                h2 {
                    color: #617be3;
                }
            }
        }

        .page_nation {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 7vh;
        }
    }
    }

    .secondLine {
        margin-left: 4%;
        border-radius: 4px;
        height: 75vh;
    }
`

const Mstyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .slaesMobilePage {
        background-color: #f1f1f1;
    }

    .tapPage {
        background-color: #F1F3F7;
        padding-bottom: 8px;
    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .menu_container {
        background: #fff;
        height: 10vh;

        .tab {
            display: flex;
            align-items: center;
            height: 10vh;
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    
`


