/*global kakao*/
import React, { useState, useEffect, useRef } from 'react';
import Loader from "react-loader-spinner";
import SVG from 'react-inlinesvg';
import { Modal, message } from 'antd';
import "antd/dist/antd.css";

import manual from '../../../../assets/svg/manual.svg';
import noneImageBox from '../../../../assets/svg/none_image.svg';

import KakaoMap from './KakaoMap';
import { BrowserView, MobileView } from 'react-device-detect';

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const PickUpZone = ({ sData, sProps }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [modalVisible, setModalVisible] = useState(false);

    const [userLat, setUserLat] = useState(37.56637919891677);
    const [userLng, setUserLng] = useState(126.97914589375286);
    const [sPreviewImage, setPreviewImage] = useState("");

    const newUserLat = useRef(null);
    const newUserLng = useRef(null);
    const anglePoint = useRef(null);

    const uploadLogoImage = async (e, sIndex) => {
        let origin = e.target.files[0];
        if (origin !== undefined) {
            if (origin.type !== undefined && origin.type !== null) {
                if (origin.type === "image/png" || origin.type === "image/jpeg") {
                    let tempImage = await getBase64(origin);
                    setIsLoading(true);
                    await uploadAllFunc(tempImage, origin, sIndex);

                } else {
                    message.error("파일형식은 jpg나 png만 가능합니다.");
                }
            }
        }
    }
    const uploadAllFunc = async (nIndex, sIndex, kIndex) => {
        let isProcess = true;
        let uploadFile = {};

        if (sIndex !== "") {
            const upload = await imageProcess(sIndex);
            if (upload === "") {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                isProcess = false;
            } else {
                uploadFile = upload;
            }
        }

        if (sIndex !== "") {
            if (isProcess) {
                const oData = {
                    store_id: sProps.UserConfigReducer.StoreID,
                    imgData: uploadFile,
                    imageType: "insert"
                }
                const oResponse = await sProps.oManager.fDoAxios("/store/pickup/parkingImage/v2", "post", "login", oData);
                if (oResponse !== undefined) {
                    if (!oResponse) {
                        message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                    } else {
                        message.info("정상적으로 저장이 완료되었습니다.");
                        setPreviewImage(nIndex);
                    }
                }
            }
        }
        setIsLoading(false);
    }

    const deleteMediaData = async () => {
        const oData = {
            store_id: sProps.UserConfigReducer.StoreID,
            imageType: "delete",
            imgData: "",
        }
        const oResponse = await sProps.oManager.fDoAxios("/store/pickup/parkingImage/v2", "post", "login", oData);
        if (oResponse !== undefined) {
            if (!oResponse) {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                message.info("삭제 처리되었습니다.");
                setPreviewImage("");
            }
        }
        setIsLoading(false);
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};
        const formData = new FormData();
        formData.append('photo', fileList);

        const oResponse = await sProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
        if (oResponse !== undefined) {
            if (oResponse.file_name !== undefined) {
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }

    const setPickUpZone = async () => {
        const oData = {
            store_id: sProps.UserConfigReducer.StoreID,
            lat: newUserLat.current,
            lng: newUserLng.current,
            viewPoint: anglePoint.current,
        }
        const oResponse = await sProps.oManager.fDoAxios("/store/pickup/setPickUpZone/v2", "post", "login", oData);
        if (oResponse !== undefined) {
            if (!oResponse) {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                setUserLat(newUserLat.current);
                setUserLng(newUserLng.current);
                setModalVisible(false);
                message.info("픽업존 설정이 완료되었습니다");
            }
        }
        setIsLoading(false);
    }

    const cancelLogoImage = () => {
        setIsLoading(true);
        deleteMediaData();
    }

    const setLatLng = async (set) => {
        newUserLat.current = set.Ma;
        newUserLng.current = set.La;
    }

    const setViewPointPan = async (set) => {
        anglePoint.current = set;
    }

    const onConfirmMap = () => {
        setPickUpZone();
    }

    const onDeleteMap = () => {
        setModalVisible(false);
        message.info("취소되었습니다");
    }

    useEffect(() => {
        setUserLat(sData.lat);
        setUserLng(sData.lng);
        setPreviewImage(sData.parkingImg);
        anglePoint.current = sData.pointView;
        setIsLoading(false);
    }, [sData]);

    return (
        <>
        <BrowserView>
            {isLoading ?
                <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                </div>
                :
                <div className="container" style={{ marginTop: "2vh", marginBottom: "10vh" }}>
                    <div className="title_box">
                        <h2>픽업존</h2>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span><strong>고객님이 찾아가는 장소입니다</strong><br />정확한 정보를 입력해주세요.</span>
                        </div>
                    </div>
                    <div className="category_title">
                        <p >픽업존 위치 설정<span className="essential_form" style={{ color: 'red', fontSize: '16px', fontWeight: 'bold' }}>(필수)</span></p>
                        <div className="set_location_box">
                            <div className="set_location_button" onClick={() => setModalVisible(true)} style={{ width: '100%', height:'5vh' }}>
                                <p style={{ fontSize: '14px' }}>2.픽업 로드뷰 설정하기(필수)</p>
                            </div>
                        </div>
                        <div className="location_box_line">
                            <div className="how_box">
                                <p>고객이 쉽게 픽업존을 알 수 있도록 각도를 설정해 주세요.<br />초기설정은 매장주소 기준이나 픽업존이 매장에서 떨어진경우 지도에서 선택/변경 가능합니다.<br />설정시 로드뷰로 픽업존 위치가 노출됩니다.</p>
                            </div>
                        </div>
                    </div>

                    <div className="pictures_title" style={{ marginBottom: "13%", marginTop: "2%" }}>
                        <p>픽업존 이미지<span>(선택)</span></p>
                        <div className="image_line">
                            {sPreviewImage !== "" ?
                                <div className="image_box">
                                    <img src={sPreviewImage} alt="none_image" />
                                </div>
                                :
                                <div className="none_image_box">
                                    <img src={noneImageBox} alt="none_image" />
                                </div>
                            }
                            <div className="image_box_button">
                                <div className="filebox">
                                    <label htmlFor="logo_file">
                                        {sPreviewImage === "" ?
                                            <div className="register_image">
                                                <p>등록</p>
                                            </div>
                                            :
                                            <div className="edit_image">
                                                <p>변경</p>
                                            </div>
                                        }
                                        <input type="file" id="logo_file" onChange={(e) => uploadLogoImage(e, "logo_file")} />
                                    </label>
                                </div>
                                <div className={sPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage()}><p>삭제</p></div>
                            </div>
                        </div>
                        <div className="location_box_line" style={{ marginTop: "10px" }}>
                            <div className="how_box">
                                <p>로드뷰보다 더 좋은 사진을 가지고 계신다면 등록해주세요</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal
                width={"60vw"}
                visible={modalVisible}
                centered={true}
                closable={false}
                onOk={() => onConfirmMap()}
                onCancel={() => onDeleteMap()}
                okText="확인"
                cancelText="취소"
            >
                <KakaoMap
                    fnSetLatLng={(sIndex) => setLatLng(sIndex)}
                    fnSetViewPointPan={(sIndex) => setViewPointPan(sIndex)}
                    sUserLat={userLat}
                    sUserLng={userLng}
                    iViewPoint={anglePoint.current}
                />
            </Modal>
        </BrowserView>
        <MobileView>
            {isLoading ? 
                <div style={{display: "flex", justifyContent: "center", height: "30vh",alignItems: "center"}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
            :
                <div className="category_title_line" style={{marginTop: "8px"}}>
                    <div className="category_title">
                        <div className="title_box">
                            <p className="title_line">픽업존</p>
                        </div>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span><strong>고객님이 찾아가는 장소입니다</strong><br />정확한 정보를 입력해주세요.</span>
                        </div>
                    </div>

                    <div className="pickUpZone_box">
                        <p style={{marginTop: "2%"}}>픽업존 위치 설정<span className="detail_line" style={{color:'red', fontSize: '14px'}}>(필수)</span></p>
                        <div className="set_location_box">
                            <div className="set_location_button" onClick={() => setModalVisible(true)} style={{width:'100%', height:'6vh', padding: 0}}>
                                <p>2.픽업 로드뷰 설정하기(필수)</p>
                            </div>
                        </div>
                        <div className="location_box_line">
                            <div className="how_box">
                                <p>고객이 쉽게 픽업존을 알 수 있도록 각도를 설정해 주세요.<br/>초기설정은 매장주소 기준이나 픽업존이 매장에서 떨어진경우 지도에서 선택/변경 가능합니다. </p>
                            </div>
                        </div>
                    </div>
                    <div className="inputText">
                        <p>픽업존 이미지<span>(선택)</span></p>
                        <div className="image_line" style={{marginBottom: "3%"}}>
                            {sPreviewImage !== "" ?
                                <div className="image_box">
                                    <img src={sPreviewImage} alt="none_image" />
                                </div>
                            :
                                <div className="none_image_box">
                                    <img src={noneImageBox} alt="none_image" />
                                </div>
                            }
                            <div className="image_box_button">
                                <div className="filebox">
                                    <label htmlFor="logo_file">
                                        {sPreviewImage === "" ?
                                            <div className="register_image">
                                                <p>등록</p>
                                            </div>
                                            :
                                            <div className="edit_image">
                                                <p>변경</p>
                                            </div>
                                        }
                                        <input type="file" id="logo_file" onChange={(e) => uploadLogoImage(e,"logo_file")}/>
                                    </label>
                                </div>
                                <div className={sPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage()}><p>삭제</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Modal 
                width={"90vw"} 
                visible={modalVisible} 
                centered={true} 
                closable={false} 
                onOk={() => onConfirmMap()} 
                onCancel={() => onDeleteMap()} 
                okText="확인" 
                cancelText="취소"
            >
                <KakaoMap 
                    fnSetLatLng={(sIndex) => setLatLng(sIndex)}
                    fnSetViewPointPan={(sIndex) => setViewPointPan(sIndex)}
                    sUserLat={userLat}
                    sUserLng={userLng}
                    iViewPoint={anglePoint.current}
                />
            </Modal>

        </MobileView>
        </>
    )
}

export default PickUpZone;

