import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Table, DatePicker, Select, Pagination } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import manual from '../../../../assets/svg/manual.svg';
import addressSearch from '../../../../assets/svg/addressSearch.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import Layout from '../../Layout'
import layoutHeaderLists from '../../config';
const defaultFormat = "YYYY/MM/DD";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Payment = ({ oProps }) => {
    const [isLoading, setLoading] = useState(true);

    const [todayPay, setTodayPay] = useState(0);
    const [exDayPay, setExDayPay] = useState(0);
    const [periodPay, setPeriodPay] = useState(0);
    
    const [fromDate, setFromDate] = useState(moment().format(defaultFormat));
    const [toDate, setToDate] = useState(moment().add(1, 'days').format(defaultFormat));
    const [selectValue, setSelectValue] = useState("total");
    const [sList, setList] = useState([]);
    const [sCurrent, setCurrent] = useState(1);

    const [startPage, setStartPage] = useState(0);
    const [endPage, setEndPage] = useState(5);
    const [iList, setIList] = useState([]);

    const [sPagination, setPagination] = useState({
        current: 1,
        pageSize: 100,
    });
    const [columns] = useState([
        {
            title: '거래기간',
            dataIndex: 'date',
            key: 'date',
            fixed: 'left',
        },
        {
            title: '차량번호',
            dataIndex: 'carNm',
            key: 'carNm',
        },
        {
            title: '전화번호',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '주문내역',
            dataIndex: 'list',
            key: 'list',
        },
        {
            title: '총 거래금액',
            dataIndex: 'totalPay',
            key: 'totalPay',
        },
        {
            title: '할인금액',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: '결제금액',
            dataIndex: 'payment',
            key: 'payment',
        },
        {
            title: '주문상태',
            key: 'status',
            dataIndex: 'status',
            render: tags => (
                <p style={{fontSize: "0.8rem", color: tags !== "cancel" ? '#617BE3' : '#dd1212'}}>
                    { tags !== "cancel" ? "완료": "취소"}
                </p>
            ),
            fixed: 'right',
        }
    ]);
      

    const selectValueChange = (value) => {
        setSelectValue(value);
    }

    const searchList = async () => {
        setLoading(true);
        getList(fromDate,toDate,selectValue);
    }
    
    const onOperatingDate = (value, dateString) => {
        if(dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== ""){
            if(fromDate === moment().format(defaultFormat)){
                if(dateString[1] !== undefined && dateString[1] !== null && dateString[1] !== ""){
                    setFromDate(dateString[0]);
                    setToDate(dateString[1]);
                } else {
                    setFromDate(dateString[0]);
                }
            } else {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
            }
        } else {
            setFromDate(moment().format(defaultFormat));
            setToDate(moment().add(1, 'days').format(defaultFormat));
        }
    }

    const getList = async (iIndex,aIndex,bIndex) => {
        let storeId = oProps.UserConfigReducer.StoreID;
        let iValue = "total";
        let from_Date = moment().format(defaultFormat);
        let to_Date = moment().add(1, 'days').format(defaultFormat);

        if(iIndex !== undefined && iIndex != null){
            from_Date = iIndex;
        }
        if(aIndex !== undefined && aIndex != null){
            to_Date = aIndex;
        }
        if(bIndex !== undefined && bIndex != null){
            iValue = bIndex;
        }

        const oData = {
            storeId,
            iValue,
            fromDate: from_Date,
            toDate: to_Date,
        }
        const oResponse = await oProps.oManager.fDoAxios(`/payment/listing/v2`, "post", "login", oData);
        
        if(oResponse !== undefined){
            setList(oResponse.list);
            setTodayPay(oResponse.todayPay);
            setExDayPay(oResponse.yesterdayPay);
            setPeriodPay(oResponse.periodPay);
            setIList(oResponse.list)
        }
        setLoading(false);
    }

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    }

    useEffect(() => {
        getList();
    },[]);
// mobile func
    const onChangePage = page => {
        let tempStart = 0;
        let tempEnd = 5;
        
        if(parseInt(page) !== 1){
            tempEnd = tempEnd * page;
            tempStart = tempEnd - 5;
        }
        setStartPage(tempStart);
        setEndPage(tempEnd);
        setCurrent(page);
    };
    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.payment}>
            <BrowserView>
        <Style>
            <div className="container">
                <div className="title_box">
                    <h2>매출 조회</h2>
                    <div className="tip">
                        <SVG src={manual} width="18px" height="18px" />
                        <span><strong>매출내역</strong>을 날짜별로<br />확인이 가능합니다.</span>
                    </div>
                </div>
                {isLoading ?
                    <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center"}}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                    </div>
                :
                <>
                    <div className="title_line">
                        <div style={{width: "33%"}}>
                            <p>오늘 매출</p>
                        </div>
                        <div style={{width: "33%"}}>
                            <p>어제 매출</p>
                        </div>
                        <div style={{width: "33%"}}>
                            <p>조회기간 매출합계</p>
                        </div>                            
                    </div>
                    <div className="last_line">
                        <div style={{width: "33%"}}>
                            <div className="today_box">
                                <p>{todayPay} 원</p>
                            </div>
                        </div>
                        <div style={{width: "33%"}}>
                            <div className="yesterday_box">
                                <p>{exDayPay} 원</p>
                            </div>
                        </div>
                        <div style={{width: "33%"}}>
                            <div className="period_box">
                                <p>{periodPay} 원</p>
                            </div>
                        </div>
                    </div>
                    <div className="searching_line">
                        <div style={{width: "13%"}}>
                            <Select value={selectValue} style={{ width: "100%" }} bordered={false} className="search_selectOption" onChange={selectValueChange}>
                                <Option key="total" value="total">전체</Option>
                                <Option key="complete" value="complete">완료</Option>
                                <Option key="cancel" value="cancel">취소</Option>
                            </Select>
                        </div>
                        <div style={{width: "37%"}}>
                            <div className="search_date">
                                <RangePicker 
                                    bordered={false} 
                                    className="date_picker"
                                    style={{marginLeft: "5%",fontSize: "0rem", width: "90%"}}
                                    format={defaultFormat}
                                    value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                                    onChange={onOperatingDate}
                                />
                            </div>
                        </div>
                        <div style={{width: "50%"}}>
                            <div className="search_bar" onClick={searchList} >
                                <SVG src={addressSearch} />
                                <p>조회</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Table 
                            columns={columns} 
                            pagination={sPagination} 
                            // dataSource={getData(sPagination.current,sPagination.pageSize)} 
                            dataSource={sList} 
                            onChange={handleTableChange} 
                            bordered
                            scroll={{ x: 'calc(700px + 50%)', y: 2400 }}
                        />
                    </div>
                </>
                }
            </div>
        </Style>
            </BrowserView>
            <MobileView><Mstyle>
            {isLoading ?
                <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center"}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
            :
                <>
                <div className="payment_title_line">
                    <div className="payment_title">
                        <h2>매출조회</h2>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span><strong>자세한 매출내역 조회는</strong>PC에서<br />확인이 가능합니다.</span>
                        </div>
                    </div>
                    <div className="first_line">
                        <div className="payment_line">
                            <h3 style={{width: "33%",marginRight: "5%"}}>오늘 매출</h3>
                            <h3 style={{width: "33%"}}>어제 매출</h3>
                            <h3 style={{width: "33%"}}>조회기간 매출합계</h3>
                        </div>
                        <div className="payment_line">
                            <div className="today_box">
                                <p>{todayPay} 원</p>
                            </div>
                            <div className="yesterday_box">
                                <p>{exDayPay} 원</p>
                            </div>
                            <div className="period_box">
                                <p>{periodPay} 원</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="number">
                    <div className="select_value">
                        <Select value={selectValue} style={{ width: "100%" }} bordered={false} className="search_selectOption" onChange={selectValueChange}>
                            <Option key="total" value="total">전체</Option>
                            <Option key="complete" value="complete">완료</Option>
                            <Option key="cancel" value="cancel">취소</Option>
                        </Select>
                    </div>
                    <div className="date_picker">
                        <RangePicker 
                            bordered={false} 
                            style={{marginLeft: "5%"}}
                            format={defaultFormat}
                            value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                            onChange={onOperatingDate}
                        />
                    </div>
                    <div className="search_line" onClick={searchList}>
                        <img src={addressSearch} />
                        <p>조회</p>
                    </div>
                </div>
                {iList.length > 0 ?
                    <>
                    {iList.slice(startPage,endPage).map((item,index) => {
                        return (
                            <div key={"listing" + index.toString()} className="table_box">
                                <div className="content_line">
                                    <div className="content1">
                                        <p>{item.carNm}</p>
                                        <div className="state_box" style={{background: item.status === "cancel" ? "#666666" : "#001E62"}}>
                                            <p>{item.status === "cancel" ? "취소" : "정상"}</p>
                                        </div>
                                    </div>
                                    <div className="content2">
                                        <p>{item.list}</p>
                                    </div>
                                </div>
                                <div className="price_line">
                                    <p>{item.totalPay}</p>
                                </div>
                            </div>
                        )
                    })}
                    </>
                :
                    <div className="table_box_none">
                        <p>데이터가 없습니다.</p>
                    </div>
                }
                <div className="pagination_box">
                    <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={iList.length} />
                </div>
                </>
            }
        </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default Payment;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  

    .title_box {
        display: flex;
        align-items: center;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 10vw;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }
    }

    .container {
        width: 42vw; 
        padding: 32px;
        margin-bottom: 16px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;


    }

    .title_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3vh;
        width: 100%;

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }
    }
    .last_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 8vh;
        padding-bottom: 1%;
        border-bottom: 1px solid #dfdfdf;
        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 24px;
            color: #617BE3;
        }

        .today_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 5vh;
            border-bottom : 1px solid #617BE3;
            
        }

        .yesterday_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 5vh;
            border-bottom : 1px solid #617BE3;
        }

        .period_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 5vh;
            border-bottom : 1px solid #617BE3;
        }
    }

    .searching_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;
    } 

    .search_bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        min-height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            color: #fff;
            text-align: center;
        }
        svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }

    .search_selectOption {
        display: flex;
        align-items: center;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
        font-size: 0.8rem;
    }

    .search_date {
        display: flex;
        align-items: center;
        margin-left: 5%;
        margin-right: 5%;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .data_box {
        margin-top: 2%;
    }

    .nodata_box {
        margin-top: 2%;
        height: 50vh;
    }
    
`



const Mstyle = styled.div`
    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .payment_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .payment_title_line, .number {
        padding: 20px 16px;
        background-color: #fff;

        h2 {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .first_line {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        .payment_line {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                font-style: normal;
                font-weight: normal;
                font-size: 0.8rem;
                line-height: 21px;
            }

            .today_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 33%;
                height: 6vh;
                border-bottom: 1px solid #617BE3;
                margin-top: 2%;
                margin-right: 5%;

            }
            .yesterday_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 33%;
                height: 6vh;
                border-bottom: 1px solid #617BE3;
                margin-top: 2%;
                margin-right: 5%;
            }
            .period_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 33%;
                height: 6vh;
                border-bottom: 1px solid #617BE3;
                margin-top: 2%;
            }

            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #617BE3;
            }
        }
    }

    .number {
        border-bottom: 1px solid #dfdfdf;

        .select_value {
            height: 10vh;

            .search_selectOption {
                display: flex;
                align-items: center;
                height: 6vh;
                border : 1px solid #000;
                border-radius: 5px;
                font-size: 0.8rem;
            }

        }

        .date_picker {
            display: flex;
            align-items: center;
            height: 6vh;
            border : 1px solid #000;
            border-radius: 5px;
            font-size: 0.8rem;
            margin-bottom: 10%;
        }


        .search_line {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            border-radius: 5px;
            background-color: #617BE3;

            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                margin-left: 3%;
                color: #fff; 
            }
        }

    }

    .table_box_none {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vh;
        background: #fff;
        border-bottom: 1px solid #dfdfdf;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 24px;
        }
    }
    .table_box {
        display: flex;
        align-items: center;
        height: 13vh;
        background: #fff;
        border-bottom: 1px solid #dfdfdf;

        .content_line {
            width: 65%;
            
            .content1 {
                display: flex;
                align-items: flex-end;
                margin-left: 7%;
                width: 100%;
                height: 7vh;

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.1rem;
                    line-height: 24px;
                }
                .state_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 3vh;
                    width: 37px;
                    margin-left: 5%;
                    background: #666666;
                    border-radius: 5px;

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.7rem;
                        color: #fff; 
                    }
                }
            }
            .content2 {
                display: flex;
                align-items: flex-start;
                margin-left: 7%;
                width: 100%;
                height: 7vh;

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.9rem;
                    line-height: 24px;
                }
            }
        }

        .price_line {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35%;
            height: 14vh;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                margin-right: 10%;
                color: #000; 
            }
        }

    }

    .pagination_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7vh;
        background: #fff;
    }


`


