import React from 'react';
import styled from 'styled-components';

import v301 from '../../../../assets/img/guide/v3/v301.png';
import v302 from '../../../../assets/img/guide/v3/v302.png';
import v303 from '../../../../assets/img/guide/v3/v303.png';
import v304 from '../../../../assets/img/guide/v3/v304.png';
import v305 from '../../../../assets/img/guide/v3/v305.png';
import v306 from '../../../../assets/img/guide/v3/v306.png';
import v307 from '../../../../assets/img/guide/v3/v307.png';
import v308 from '../../../../assets/img/guide/v3/v308.png';
import v309 from '../../../../assets/img/guide/v3/v309.png';

const V3Guide = (oProps) => {
  
    return (
        <M_v3Guide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>포스 가이드</h3>
                        <h1>POS 설치 후 실행이 안 돼요 (안랩 V3 버전)</h1>
                        <p>최근 백신 프로그램 업데이트 이후 스루 POS가 실행되지 않는 현상이 발생하고 있어요 😢<br />만약 매장 포스에 <span>안랩 V3 백신 프로그램</span>을 사용 중이시라면 아래 가이드를 단계별로 진행해 주세요.</p>
                        <h2><span>STEP 1</span><br />안랩 V3 환경설정을 클릭해 주세요.</h2>
                        <img src={v301} alt="V3환경설정이미지" width="500px" />
                        <h2><span>STEP 2</span><br />이미지에 보이는 하단에 아이콘을 클릭해 주세요.</h2>
                        <img src={v302} alt="V3환경설정이미지" width="500px" />
                        <h2><span>STEP 3</span><br />검사 예외 설정에 들어가서 폴더 추가 버튼을 클릭해 주세요.</h2>
                        <img src={v303} alt="V3환경설정이미지" width="500px" />
                        <p>✅ c:\User\(사용자 이름)\ThrooPOS<br />✅ c:\User\(사용자 이름)\내 문서\ThrooPOS<br /><br />두 개 중에 해당하는 <span style={{fontWeight:"900", color: '#212329'}}>파일 한 개</span>를 선택해 주세요</p>
                        <h2><span>STEP 4</span><br />목록에 해당 스루 파일이 있는지 확인 후 확인 버튼을 눌러주세요.</h2>
                        <img src={v304} alt="V3환경설정이미지" width="500px" />
                        <h2><span>STEP 5</span><br />윈도우 디펜더 [열기] 버튼을 더블클릭해 주세요.</h2>
                        <img src={v305} alt="V3환경설정이미지" width="500px" />
                        <h2><span>STEP 6</span><br />windows 보안 영역에서 바이러스 및 위협 방지를 클릭해 주세요.</h2>
                        <img src={v306} alt="V3환경설정이미지" width="500px" />
                        <h2><span>STEP 7</span><br />하단에 windows defender 바이러스 백신 옵션 클릭하여 주기적 검사를 <span style={{color: '#F45452'}}>끔으로 변경</span>해 주세요.</h2>
                        <img src={v307} alt="V3환경설정이미지" width="500px" />
                        <h2><span>STEP 8</span><br />우측 카테고리에서 앱 및 브라우저 컨트롤 <span style={{color: '#212329'}}>&#8594;</span> 평판 기반 보호 설정을 클릭하여 노출되는 이미지에 선택란에 대하여 <span style={{color: '#F45452'}}>끄기로 선택</span>해 주세요.</h2>
                        <img src={v308} alt="V3환경설정이미지" width="500px" style={{marginBottom:"10px"}} />
                        <img src={v309} alt="V3환경설정이미지" width="500px" />
                    </div>
                </div>
            </div>
        </M_v3Guide>
    )
}

export default V3Guide;

export const M_v3Guide = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .pGuideCont {
        margin-top: 110px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;
        word-break: keep-all;

        span {
            color: #8E929F;
        }
    }
    h2 span:nth-child(3) {
        color: #F45452;
        font-weight: 900;
    }
    p {
        margin: 15px 0 25px 0;
        font-size: 16px;
        font-weight: 700;

        span {
            color: #F45452;
            font-weight: 900;
        }
    }
`