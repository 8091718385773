import { useState } from "react";
import styled from "styled-components";
import SVG from 'react-inlinesvg';
import { isMobile } from "react-device-detect";
import pagenationLeftArrow from "../../../assets/svg/pagination_left_arrow.svg";
import pagenationRightArrow from "../../../assets/svg/pagination_right_arrow.svg";

function Pagination({ total, limit, page, setPage }) {
    const numPages = Math.ceil(total / limit);
    const [arr] = useState(Array(numPages).fill().map((e, i) => i))

    if (isMobile) {
        return (
            <>
                <Nav>
                    <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
                        <SVG src={pagenationLeftArrow} />
                    </Button>
                    {arr.slice(6 + page - 1 < arr.length ? 0 + page - 1 : arr.length - 6, 6 + page - 1)
                        .map((e, i) => (
                            <Button
                                key={i + 1}
                                onClick={() => setPage(e + 1)}
                                aria-current={page === e + 1 ? "page" : null}
                                style={{ background: '#fff' }}
                            >
                                {e + 1}
                            </Button>
                        ))}
                    <Button onClick={() => setPage(page + 1)} disabled={page === numPages}>
                        <SVG src={pagenationRightArrow} />
                    </Button>
                </Nav>
            </>
        );
    }
    else {
        return (
            <>
                <Nav>
                    <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
                        <SVG src={pagenationLeftArrow} />
                    </Button>
                    {arr.slice(8 + page - 1 < arr.length ? 0 + page - 1 : arr.length - 8, 8 + page - 1)
                        .map((e, i) => (
                            <Button
                                key={i + 1}
                                onClick={() => setPage(e + 1)}
                                aria-current={page === e + 1 ? "page" : null}
                                style={{ background: '#fff' }}
                            >
                                {e + 1}
                            </Button>
                        ))}
                    <Button onClick={() => setPage(page + 1)} disabled={page === numPages}>
                        <SVG src={pagenationRightArrow} />
                    </Button>
                </Nav>
            </>
        );
    }

}

const Nav = styled.nav`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 24px!important;
    overflow: hidden;
`;

const Button = styled.button`
    padding: 5px 11px!important;
    margin: 0 4px!important;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 14px;
    font-weight: 700;
    color:rgba(0, 0, 0, 0.7);
    outline: none!important;   
    background: #7D7D7D;

    &[disabled] {
        background: #D2D2D2;
        cursor: revert;
        transform: revert;

        svg path {
            fill:rgba(255, 255, 255, 0.4)
        }
    }

    &[aria-current] {
        font-weight: bold;
        cursor: revert;
        transform: revert;
        border: 1px solid #6490E7;
    } 

    svg {
        vertical-align: text-bottom;
    }
`;

export default Pagination;
