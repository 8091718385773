import React from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { message } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import ReducerSetter from '../../reducers/ReducerSetter';

import { WebRoute } from '../../navigation/WebRoutes';

const WebManager = (oProps) => {
   const oReducerSetter = React.useRef();
   const navigate = useNavigate();

   const _doAxios = async (sUrl, sMethod, sHeader, oData, bIsSalesRequest) => {
      let oUserConfig = oReducerSetter?.current?._refGetUserConfig();
      //console.log('_doAxios oUserConfig', oUserConfig);
      //console.log('_doAxios sUrl', sUrl);
      if (process.env !== undefined && process.env.REACT_APP_ENV !== undefined && process.env.REACT_APP_ENV === 'DEV') {
         console.log('_doAxios sUrl', sUrl);
      }
      let oDefHeader = {
         'Content-Type': 'application/json'
      };

      if (sHeader !== undefined && sHeader != null && typeof sHeader === 'string') {
         if (sHeader === 'login') {

            let sUserToken = oUserConfig.Token;
            let sRefreshToken = oUserConfig.RefreshToken;

            if (oUserConfig['SalesLoggedIn'] !== undefined && oUserConfig['SalesLoggedIn'] === true && bIsSalesRequest !== undefined && bIsSalesRequest === true) {
               sUserToken = oUserConfig.SalesToken;
               sRefreshToken = oUserConfig.SalesRefreshToken;
            }

            oDefHeader = {
               'Authorization': sUserToken,
               'Content-Type': 'application/json',
               'refresh-token': sRefreshToken
            };
         } else if (sHeader === 'multipart') {
            let iStoreId = oUserConfig?.StoreID | 0;
            oDefHeader = {
               'Content-Type': 'multipart/form-data',
               'store-id': iStoreId
            };
         }
      }

      try {
         const oResponse = await axios({
            url: process.env.REACT_APP_INIT_URL + sUrl,
            method: sMethod,
            timeout: (15 * 10000),
            headers: oDefHeader,
            data: oData,
            transformResponse: [(data) => {
               return data;
            }],
         });
         if (oResponse !== undefined && oResponse.name != undefined && oResponse.name.toLowerCase() === 'error') {
            if (oResponse.message !== undefined && oResponse.message.toLowerCase().indexOf('401') !== -1) {
               await unauthorized();
               navigate(WebRoute.ADMIN_SIGNIN);
            }
         }

         if (oResponse.headers !== undefined && oResponse.headers['refreshed-token'] !== undefined) {
            oUserConfig['Token'] = oResponse.headers['refreshed-token'];
            oReducerSetter.current._refSetUserConfig(oUserConfig);
         }

         if (oResponse !== undefined && oResponse.data !== undefined) {
            return JSON.parse(oResponse.data);
         } else {
            return false;
         }
      } catch (err) {
         if (err.message.toLowerCase().indexOf('401') !== -1) {
            await unauthorized();
            navigate(WebRoute.ADMIN_SIGNIN);
         }
      }
   }

   const _doAxiosBehind = async (sUrl, sMethod, oData) => {
      let oUserConfig = oReducerSetter?.current?._refGetUserConfig();
      let sRefreshToken = oUserConfig.RefreshToken;
      let sUserToken = oUserConfig.Token;
      console.log(sUserToken);
      console.log(sRefreshToken);
      const header = {
         Accept: "application/json",
         "Content-Type": "application/json",
         Authorization: sUserToken,
         'refresh-token': sRefreshToken
     };

     console.log(process.env.REACT_APP_BEHIND_URL + sUrl);

      const oResponse = await axios({
         url: process.env.REACT_APP_BEHIND_URL + sUrl,
         method: sMethod,
         timeout: (15 * 10000),
         headers: header,
         data: oData,
         transformResponse: [(data) => {
            return data;
         }],
      });

      return oResponse;
   }

   const unauthorized = async () => {
      let oUserConfig = oReducerSetter?.current?._refGetUserConfig();
      let tempId = "";
      let tempKey = "";
      let sIndex = "";
      if (oUserConfig.UserId !== undefined && oUserConfig.UserId !== null && oUserConfig.UserId !== "") {
         tempId = oUserConfig.UserId;
         sIndex = "store";
      } else if (oUserConfig.SalesEmail !== undefined && oUserConfig.SalesEmail !== null && oUserConfig.SalesEmail !== "") {
         tempId = oUserConfig.SalesEmail;
         sIndex = "sales";
      }
      if (oUserConfig.UserKey !== undefined && oUserConfig.UserKey !== null && oUserConfig.UserKey !== "") {
         tempKey = oUserConfig.UserKey;
         sIndex = "store";
      } else if (oUserConfig.SalesKey !== undefined && oUserConfig.SalesKey !== null && oUserConfig.SalesKey !== "") {
         tempKey = oUserConfig.SalesKey;
         sIndex = "sales";
      }

      if (tempId !== "" && tempKey !== "") {
         let randomDeviceId = (Math.random() * (10 - 1)) + 1;
         let oData = {
            id: tempId,
            password: tempKey,
            type: sIndex,
            deviceuuid: randomDeviceId.toString()
         }
         const oResponse = await _doAxios("/authenticate/auto/v2", "post", null, oData);
         if (oResponse !== undefined && oResponse !== null) {
            const new_date = moment().add(1, "days");
            if (oResponse.resultId === "user") {

               oUserConfig['isPOS'] = false;
               oUserConfig['LoggedIn'] = true;
               oUserConfig['Token'] = await oResponse.token;
               oUserConfig['RefreshToken'] = await oResponse.refresh_token;
               oUserConfig['UUID'] = await oResponse.uuid;

               oUserConfig['StoreID'] = await oResponse.store_id;
               oUserConfig['StoreName'] = await oResponse.store_name;
               oUserConfig['StorePhone'] = await oResponse.store_phone_number;
               oUserConfig['StoreOwner'] = await oResponse.store_merc_full_name;
               oUserConfig['Address'] = await oResponse.store_address1;
               oUserConfig['StoreLat'] = await oResponse.store_lat;
               oUserConfig['StoreLng'] = await oResponse.store_lng;

               oUserConfig['accessible'] = await oResponse.status;
               oUserConfig['Commercial'] = await oResponse.commercial;
               oUserConfig['LoginTime'] = await moment(new_date).format('YYYY-MM-DD');

               oReducerSetter.current._refSetUserConfig(oUserConfig);

            } else if (oResponse.resultId === "sales") {

               oUserConfig['isPOS'] = false;
               oUserConfig['SalesLoggedIn'] = true;
               oUserConfig['SalesId'] = oResponse.salesId;
               oUserConfig['SalesUserName'] = oResponse.salesName;
               oUserConfig['SalesLoginTime'] = await moment(new_date).format('YYYY-MM-DD');
               oUserConfig['Token'] = await oResponse.token;
               oUserConfig['RefreshToken'] = await oResponse.refresh_token;

               oReducerSetter.current._refSetUserConfig(oUserConfig);

            } else {
               message.error(oResponse.resultMsg);
               _forceLogOut();
            }
         } else {
            message.error("네트워크 에러가 발생했습니다 나중에 다시 시도 바랍니다.");
            _forceLogOut();
         }
      } else {
         _forceLogOut();
      }
   }

   const _forceLogOut = () => {
      let oUserConfig = oReducerSetter?.current?._refGetUserConfig();

      oUserConfig['LoggedIn'] = false;
      oUserConfig['isPOS'] = false;
      oUserConfig['Token'] = "";
      oUserConfig['RefreshToken'] = "";
      oUserConfig['UUID'] = "";

      oReducerSetter.current._refSetUserConfig(oUserConfig);
   }

   const _setReduxUserConfig = (oUserConfigData) => {
      if (oReducerSetter.current !== undefined) {
         oReducerSetter.current._refSetUserConfig(oUserConfigData);
      }
      return false;
   }

   const _getReduxUserConfig = () => {
      if (oReducerSetter.current !== undefined) {
         return oReducerSetter.current._refGetUserConfig();
      }
      return false;
   }

   const _getReduxUserBasket = () => {
      if (oReducerSetter.current !== undefined) {
         return oReducerSetter.current._refGetUserBasket();
      }
      return false;
   }

   const _setReduxUserBasket = (oUserBasketData) => {
      oReducerSetter.current._refSetUserBasket(oUserBasketData);
   }

   const oThisManager = {
      fDoAxios: _doAxios,
      fDoBehideAxios: _doAxiosBehind,

      fSetReduxUserConfig: _setReduxUserConfig,
      fGetReduxUserConfig: _getReduxUserConfig,
      fGetReduxUserBasket: _getReduxUserBasket,
      fSetReduxUserBasket: _setReduxUserBasket,

   };
   return (
      <>
         {React.cloneElement(oProps.children, { oManager: oThisManager })}
         <ReducerSetter {...oProps} ref={oReducerSetter} />
      </>
   );
}

export default WebManager;