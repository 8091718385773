import React from 'react';
import {
   isMobile
} from "react-device-detect";

import M_FooterPage from "./mobile/M_footer";
import D_FooterPage from "./desktop/D_footer";

const MainFooter = (oProps) => {
   const footerRender = () => {
      if (isMobile) {
         return <M_FooterPage {...oProps} />
      }

      return <D_FooterPage {...oProps} />
   }

   return (
      <>
         {footerRender()}
      </>
   )
}

export default MainFooter;