import React from 'react';
import { Line } from '@ant-design/charts';

interface ConfigData {
  vChart: any;
}

const DoubleLine: React.FC<ConfigData> = props => {
  
  return <Line {...props.vChart} />;
  
};

export default DoubleLine;