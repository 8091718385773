import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { Modal, message, Select } from 'antd';
import DaumPostcode from 'react-daum-postcode';
import "antd/dist/antd.css";

import addressSearch from '../../../../assets/svg/addressSearch.svg';
import manual from '../../../../assets/svg/manual.svg';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
const { Option } = Select;

const nearByList = [];
const parkingList = [
    {
        key: 1,
        value: 1,
        name: `1 분`,
    }
];
for (let i = 1; i < 15; i++) {
    nearByList.push({
        key: i,
        value: i * 50,
        name: `${i * 50} M`,
    });
};
for (let k = 1; k < 13; k++) {
    parkingList.push({
        key: k,
        value: k * 5,
        name: `${k * 5} 분`,
    });
};

const SettingInfo = ({ fnInsertForm, iData }) => {
    const [sAddress, setAddress] = useState("");
    const [sExtraAddress, setExAddress] = useState("");
    const [sPhoneNm, setPhoneNm] = useState("");
    const [sNearByDistance, setNearByDistance] = useState(50);
    const [sParkingTime, setParkingTime] = useState(5);

    const [addressModal, setAddressModal] = useState(false);

    const [classAddress, setClassAddress] = useState(null);
    const [classExtraAddress, setClassExtraAddress] = useState(null);
    const [classPhoneNm, setClassPhoneNm] = useState(null);
    const [sErrAddress, setErrAddress] = useState("");
    const [sErrPhone, setErrPhone] = useState("");

    const storeDetailInput = useRef();

    const onChangeExAddressValue = (event) => {
        setClassExtraAddress("input_action");
        setExAddress(event.target.value);
    }

    const onChangePhone = (event) => {
        setClassPhoneNm("input_action");
        setPhoneNm(event.target.value);
    }

    const setChangeDistance = (event) => {
        if (isMobile) {
            setNearByDistance(event)
        } else
            setNearByDistance(event.target.value);
        if (classExtraAddress !== "input_wrong") {
            setClassExtraAddress(null);
        }
        if (classPhoneNm !== "input_wrong") {
            setClassPhoneNm(null);
        }
        if (classAddress !== "input_wrong") {
            setClassAddress(null);
        }
    }

    const setChangeParkingTime = (event) => {
        if (isMobile) {
            setParkingTime(event);
        } else
            setParkingTime(event.target.value);
        if (classExtraAddress !== "input_wrong") {
            setClassExtraAddress(null);
        }
        if (classPhoneNm !== "input_wrong") {
            setClassPhoneNm(null);
        }
        if (classAddress !== "input_wrong") {
            setClassAddress(null);
        }
    }

    const inputClassExtraAddress = () => {
        if (classExtraAddress !== "input_wrong") {
            setClassExtraAddress("input_action");
        }
        if (classPhoneNm !== "input_wrong") {
            setClassPhoneNm(null);
        }
        if (classAddress !== "input_wrong") {
            setClassAddress(null);
        }
    }
    const inputClassPhoneNm = () => {
        if (classExtraAddress !== "input_wrong") {
            setClassExtraAddress(null);
        }
        if (classPhoneNm !== "input_wrong") {
            setClassPhoneNm("input_action");
        }
        if (classAddress !== "input_wrong") {
            setClassAddress(null);
        }
    }

    const handleComplete = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setClassAddress(null);
        setAddress(fullAddress);
        setAddressModal(false);
        setErrAddress("");
        if (classExtraAddress !== "input_wrong") {
            setClassExtraAddress(null);
        }
        if (classPhoneNm !== "input_wrong") {
            setClassPhoneNm(null);
        }
        if (classAddress !== "input_wrong") {
            setClassAddress(null);
        }
        message.success('주소입력이 완료되었습니다');
    }

    const insertForm = async () => {
        let temp = false;
        const oData = {
            sAddress,
            sExtraAddress,
            sPhoneNm,
            sNearByDistance,
            sParkingTime
        }
        if (sAddress === "") {
            setClassAddress("input_wrong");
            setErrAddress("매장주소는 필수입니다.");
            temp = true;
        }
        if (sPhoneNm === "") {
            setClassPhoneNm("input_wrong");
            setErrPhone("매장전화번호는 필수입니다.");
            temp = true;
        }

        if (!temp) {
            await fnInsertForm(oData);
        }
    }

    useEffect(() => {
        setAddress(iData.address);
        setExAddress(iData.extraAddress);
        setPhoneNm(iData.sNm);
        setNearByDistance(iData.sNoti);
        setParkingTime(iData.parkingTime);
    }, [iData]);

    return (
        <>
            <BrowserView>
                <div className="container">
                    <div className="title_box">
                        <h2>픽업정보</h2>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span><strong>고객님에게 상품을 전달하기 위한 정보입니다</strong><br />정확한 정보를 입력해주세요.</span>
                        </div>
                    </div>
                    <div className="category_address_box">
                        <p>매장 주소<span className="essential_form">(필수)</span></p>
                        <input type="text" disabled className={classAddress} value={sAddress} placeholder="돋보기아이콘을 클릭해 주소를 검색해주세요" style={{ marginBottom: "8px", marginTop: "8px", backgroundColor: "#F1F3F7" }} />
                        <div className="address">
                            <button className="search_box" onClick={() => { setAddressModal(true) }}><img src={addressSearch} /></button>
                        </div>
                        <input
                            type="text"
                            placeholder="나머지 주소를 입력하세요."
                            value={sExtraAddress}
                            onChange={onChangeExAddressValue}
                            className={classExtraAddress}
                            onClick={inputClassExtraAddress}
                        />
                        <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{sErrAddress}</p>
                    </div>
                    <div className="category_title">
                        <p>매장 전화번호<span className="essential_form">(필수)</span></p>
                        <input
                            type="text"
                            ref={storeDetailInput}
                            value={sPhoneNm}
                            onChange={onChangePhone}
                            className={classPhoneNm}
                            onClick={inputClassPhoneNm}
                        />
                        <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{sErrPhone}</p>
                    </div>
                    <div className="category_title">
                        <p style={{ marginTop: "2%" }}>픽업존 근접 알림<span className="essential_form">(필수)</span><span></span></p>
                        <select value={sNearByDistance} onChange={setChangeDistance} >
                            {nearByList.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>
                        <div className="location_box_line2" style={{ marginTop: "10px" }}>
                            <div className="how_box">
                                <p>설정한 픽업존 거리내에 고객이 진입할 경우 포스에서 알림이 울립니다.</p>
                            </div>
                        </div>
                    </div>
                    <div className="category_title">
                        <p style={{ marginTop: "2%" }}>픽업존 정차가능 시간<span className="essential_form">(필수)</span></p>
                        <select value={sParkingTime} onChange={setChangeParkingTime}>
                            {parkingList.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>
                        <div className="location_box_line2" style={{ marginTop: "10px" }}>
                            <div className="how_box">
                                <p>픽업존이 해당하는 구역이 주정차단속구역인지 확인 후, 정차 가능 시간을 정해주세요. <br />고객이 너무 일찍 도착하여 설정한 시간을 초과할 경우 주차단속 알람이 고객에게 보내집니다.</p>
                            </div>
                        </div>
                    </div>
                    <div className="insert_box" onClick={insertForm}>
                        <p>1. 픽업정보 등록하기(필수)</p>
                    </div>
                </div>

                <Modal visible={addressModal} centered={true} closable={false} onOk={() => { setAddressModal(false) }} onCancel={() => { setAddressModal(false) }} okText="확인" cancelText="취소">
                    <AddressBox>
                        <DaumPostcode onComplete={handleComplete} />
                    </AddressBox>
                </Modal>
            </BrowserView>
            <MobileView>
                <div className="category_title_line">
                    <div className="category_title">
                        <div className="title_box">
                            <p className="title_line">픽업정보</p>
                        </div>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span><strong>고객님에게 상품을 전달하기 위한 정보입니다</strong><br />정확한 정보를 입력해주세요.</span>
                        </div>
                    </div>
                    <div className="inputText">
                        <h3>사업장 소재지<span className="detail_line">(필수)</span></h3>
                        <div className="address">
                            <input type="text" disabled className={classAddress} value={sAddress} placeholder="검색을 통해서 입력해주세요." style={{ marginBottom: "8px", backgroundColor: "#F1F3F7" }} />
                            <button className="search_box" onClick={() => { setAddressModal(true) }}><img src={addressSearch} /></button>
                        </div>
                        <input
                            type="text"
                            placeholder="나머지 주소를 입력하세요."
                            value={sExtraAddress}
                            onChange={onChangeExAddressValue}
                            className={classExtraAddress}
                            onClick={inputClassExtraAddress}
                        />
                    </div>
                    <div className="inputText">
                        <h3>매장 전화번호<span className="detail_line">(필수)</span></h3>
                        <input
                            type="text"
                            placeholder="매장 소개글을 입력해주세요."
                            ref={storeDetailInput}
                            value={sPhoneNm}
                            onChange={onChangePhone}
                            className={classPhoneNm}
                            onClick={inputClassPhoneNm}
                        />
                        <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{sErrPhone}</p>
                    </div>
                    <div className="inputText">
                        <h3>픽업존 근접 알림<span className="detail_line">(필수)</span></h3>
                        <Select value={sNearByDistance} onChange={setChangeDistance} className="select_bank" bordered={false}>
                            {nearByList.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="inputText">
                        <h3>픽업존 정차가능 시간<span className="detail_line">(필수)</span></h3>
                        <Select value={sParkingTime} onChange={setChangeParkingTime} className="select_bank" bordered={false}>
                            {parkingList.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="last">
                        <button className="finishButoon" onClick={insertForm} style={{ height: '6vh', padding: 0 }}>1.픽업정보 등록하기(필수)</button>
                    </div>
                </div>
                <Modal visible={addressModal} centered={true} closable={false} onOk={() => { setAddressModal(false) }} onCancel={() => { setAddressModal(false) }} okText="확인" cancelText="취소">
                    <AddressBox>
                        <DaumPostcode onComplete={handleComplete} />
                    </AddressBox>
                </Modal>

            </MobileView>
        </>
    )
}

export default SettingInfo;


const AddressBox = styled.div`
    margin-left: 2%;
`;
