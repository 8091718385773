import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { message, Modal, Pagination, Table, DatePicker } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import DoubleLineChart from '../../../../variables/DoubleLine';
import Layout from '../../Layout'
import layoutHeaderLists from '../../config';
import { BrowserView, MobileView } from 'react-device-detect';
let checkTime;

const MyPage = ({ oProps }) => {
    const [isLoading, setLoading] = useState(false);

    const [sTitle, setTitle] = useState("");
    const [payList, setPayList] = useState([]);
    const [iConfig, setIConfig] = useState({
        data: [],
        xField: 'date',
        yField: 'scales',
        seriesField: 'name',
        yAxis: {},
        smooth: true,
        animation: {
            appear: {
                animation: 'path-in',
                duration: 5000,
            },
        },
    });

    const iCategoryColumns = [
        {
            title: "",
        },
        {
            title: "광고명",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "날짜",
            key: "date",
            dataIndex: "date",
        },
        {
            title: "차트",
            key: "action",
            render: (text, record, index) => <div className="date_box2" onClick={() => changeChartStyle(text)} ><p>확인하기</p></div>
        }
    ];

    const changeChartStyle = (sIndex) => {
        setLoading(true);
        let temp = {
            data: sIndex.chart,
            xField: 'date',
            yField: 'scales',
            seriesField: 'name',
            yAxis: {},
            smooth: true,
            animation: {
                appear: {
                    animation: 'path-in',
                    duration: 5000,
                },
            },
        }
        setIConfig(temp);
        setTitle(sIndex.name);
        if (checkTime) clearTimeout(checkTime);
        checkTime = setTimeout(() => {
            setLoading(false);
        }, 300);
    }

    const asyncData = async () => {
        setLoading(true);
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/commercial/chart/getCommercial/-" + store_id, "get", "login", null);
        if (oResponse !== undefined) {
            if (oResponse.length > 0) {
                let temp = {
                    data: oResponse[0].chart,
                    xField: 'date',
                    yField: 'scales',
                    seriesField: 'name',
                    yAxis: {},
                    smooth: true,
                    animation: {
                        appear: {
                            animation: 'path-in',
                            duration: 5000,
                        },
                    },
                }
                setIConfig(temp);
                setTitle(oResponse[0].name);
                setPayList(oResponse);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        asyncData();
    }, []);

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.commercial}>
            <>
                <BrowserView><Style>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <>
                            {sTitle !== "" &&
                                <div className="content_container4">
                                    <div className="title_box">
                                        <p>{sTitle} 차트</p>
                                    </div>
                                    <div className='data_contain'>
                                        <div className="chartBox">
                                            <DoubleLineChart vChart={iConfig} />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="content_container4" style={{ marginBottom: "10%" }}>
                                <div className="title_box">
                                    <p>진행중인 광고상품</p>
                                </div>
                                <div className='data_contain'>
                                    <Table columns={iCategoryColumns} dataSource={payList} pagination={false} />

                                </div>
                            </div>
                        </>
                    }</Style>
                </BrowserView>
                <MobileView>

                    <Mstyle>
                        {isLoading ?
                            <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                            :
                            <>
                                <div className="totalPage" style={{ marginBottom: "2%" }}>
                                    <div className="menuContent">
                                        <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf" }}>
                                            <div className="title">
                                                <h3>{sTitle} 차트</h3>
                                            </div>
                                        </div>
                                        <div className='data_contain'>
                                            <div className='chart_data'>
                                                <DoubleLineChart vChart={iConfig} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="totalPage" style={{ marginBottom: "2%" }}>
                                    <div className="menuContent">
                                        <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf" }}>
                                            <div className="title">
                                                <h3>진행중인 광고상품</h3>
                                            </div>
                                        </div>
                                        {payList.length > 0 &&
                                            <>
                                                {payList.map((item, index) => {
                                                    return (
                                                        <div className='commercial_box' key={index}>
                                                            <div className='commercial_container'>
                                                                <div className='commercial_detail'>
                                                                    <div className='title'>
                                                                        <p>{item.name}</p>
                                                                    </div>
                                                                    <div className='detail'>
                                                                        <p>{item.date}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='select_commercial'>
                                                                    <div className='button_box' onClick={() => changeChartStyle(item)}>
                                                                        <p>확인하기</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </>

                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </Mstyle>
                </MobileView>


            </>
        </Layout>
    )
}

export default MyPage;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .essential_form {
        font-size: 0.8rem;
        font-weight: 400;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }
    
    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }

    .menu_container {
        margin-top: 10px;
        border-radius: 4px;

        .tab {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw; 
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .pointMore button {
        margin-left: 10%;
        height: 3vh;
        width: 4.5vw;
        background-color: #E8EFFC;
        color: #6490E7;
        font-size: 0.7rem;
        font-weight: 600;
        border: none;
        outline: none;
        border-radius: 8px;
    }
    .pointCharge button {
        padding: 15px 28px;
        background-color: #6490E7;
        color: #fff;
        border: none;
        outline: none;
        border-radius: 4px;
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    
    .content_container1 {
        display: flex;
        justify-content: space-between;
        width: 42vw;
        
        .commercial_body1 {
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            background: #fff;
            height: 30vh;
            width: 24vw;

            .title {
                display: flex;
                align-items: center;
                height: 4vh;
                width: 16vw;

                h2 {
                    margin-left: 5%;
                    font-size: 0.8rem;
                    font-weight: 700;
                    line-height: 21px;
                    color: #617BE3;
                }
            }
            
            .chartBox {
                height: 24vh;
                margin-bottom: 5%;
                margin-left: 5%;
                margin-right: 5%;
            }
        }
        
        .commercial_body2 {
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            background: #fff;
            height: 30vh;
            width: 16vw; 

            .common_body {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 16vh;
                margin 2% 0 0 0;

                .line_body {
                    width: 14vw;
                    height: 15vh;
                    border : 1px solid #BBBBBB;
                    border-radius: 5px;

                    .line_title {
                        width: 14vw;
                        height: 5vh;
                        justify-content: space-around;
                        align-items: center;
                        display: flex;

                        h2 {
                            font-size: 0.8rem;
                            font-weight: 500;
                            color: #666666;
                        }
                        p {
                            font-size: 0.9rem;
                            font-weight: 600;
                            color: #000;
                        }
                    }
                }
            }

            .insert_box {
                margin: 5% 5% 5% 5%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                height: 4vh;
                background : #617BE3;
                border-radius: 4px;
                p {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .title {
                display: flex;
                align-items: center;
                height: 4vh;
                width: 16vw;
                
                h2 {
                    margin-left: 5%;
                    font-size: 0.8rem;
                    font-weight: 700;
                    line-height: 21px;
                    color: #617BE3;
                }
            }
            .subTitle { 
                display: flex;
                align-items: center;
                height: 2vh;
                width: 16vw;
    
                p {
                    margin-left: 5%;
                    font-size: 0.9rem;
                    font-weight: 600;
                    line-height: 29px;
                    color: #6B7583;
                }
                
            }
           
        }
    }
    
    .commercial_container1 {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 
        height: 26vh;
        margin-top: 5%;

        .title_box {
            display: flex;
            align-items: center;
            height: 6vh;
            width: 42vw;
            border-bottom: 1px solid #dfdfdf;

            p {
                margin-left: 5%;
                font-size: 1rem;
                font-weight: 700;
            }
        }

        .subTitle { 
            display: flex;
            align-items: center;
            height: 6vh;
            width: 42vw;
            padding-top: 2vh;

            p {
                margin-left: 5%;
                font-size: 0.8rem;
                font-weight: 600;
                line-height: 29px;
                color: #6B7583;
            }
            
        }
        .box_separate {
            width: 38vw;
            height: 8vh;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 5%;
            margin-right: 5%;
            
            .left_box {
                width: 20vw;
                height: 7vh;
                display: flex;
                align-items: center;

                p {
                    font-size: 0.8rem;
                    font-weight: 600;
                    line-height: 29px;
                    color: #6B7583;
                }
            }
            .right_box {
                width: 20vw;
                height: 7vh;
                display: flex;
                justify-content: flex-end;
                
                .insert_box {
                    margin: 5% 5% 5% 5%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    height: 4vh;
                    width: 7vw;
                    background : #6490E7;
                    border-radius: 4px;
                    p {
                        font-size: 0.8rem;
                        font-weight: 600;
                        color: #fff;
                    }
                }
            }
        }
    }
    
    .commercial_container2 {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 
        height: 42vh;
        margin-top: 5%;

        .chartBox {
            height: 32vh;
            margin-left: 5%;
            margin-right: 5%;
        }

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw;

            p {
                margin-left: 5%;
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }

    .content_container2 {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 
        margin-top: 5%;

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw;

            p {
                margin-left: 5%;
                font-size: 1rem;
                font-weight: 700;
            }
        }

        .none_data {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 10vh;

            p {
                font-size: 0.9rem;
                font-weight: 600;
                color: #BBBBBB;
            }
        }


        .table_wrapper {
            padding: 32px;
            margin-bottom: 1px;
            background-color: #fff;
            width: 42vw; 
        }
    }
    
    .content_container4 {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 
        margin-top: 5%;

        .title_box {
            display: flex;
            align-items: center;
            height: 6vh;
            width: 42vw;
            border-bottom: 1px solid #dfdfdf;

            p {
                margin-left: 5%;
                font-size: 1rem;
                font-weight: 700;
            }
        }

        .total_amount {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42vw;
            height: 28vh;
            background : #fff;

            .pay_box {
                height: 23vh;
                width: 38vw;
                background : #F2F3F5;
                justify-content: center;
                border-radius: 5px;

                .first_line {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 5%;
                    padding-right: 5%;
                    height: 6vh;
                    width: 38vw;

                    .pay_box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 4vh;
                        width: 38vw;
                        background : #6490E7;
                        cursor: pointer;
                        
                        p {
                            font-size: 1rem;
                            font-weight: 600;
                            color: #fff;
                        }
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 600;
                        color: #000;
                    }
                }

                .last_line {
                    justify-content: center;
                    align-items: center;
                    height: 15vh;
                    width: 38vw;
                    margin-top: 1vh;
                   
                    .box_line {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 3vh;
                        padding-left: 5%;
                        padding-right: 5%;

                        p {
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: #4E5867;
                        }
                    }
                }
                
            }
        }

        .data_contain {
            padding: 32px;
            width: 42vw;

            .data_title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid #000;
                border-bottom: 1px solid #dfdfdf;
                height: 5vh;
                width: 38vw;

                .pay_img {
                    display: flex;
                    align-items: center;
                    width: 15%;
                }

                .pay_date {
                    display: flex;
                    align-items: center;
                    width: 15%;
                }

                .pay_status {
                    width: 15%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .pay_period {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .pay_amount {
                    width: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .pay_commercial_period {
                    width: 40%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .pay_box {
                    width: 15%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
    
                p{
                    font-weight: 500;
                    font-size: 0.8rem;
                    text-align: center;
                    color: #666;
                }
            }
        }
        
        .data_box_map {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #dfdfdf;
            height: 7vh;
            width: 38vw;

            .pay_img {
                display: flex;
                align-items: center;
                width: 15%;

                img {
                    width: 70%;
                }
            }

            .pay_date {
                display: flex;
                align-items: center;
                width: 15%;

                p {
                    font-weight: 600;
                    font-size: 0.8rem;
                    color: #000;
                }
            }

            .pay_status {
                width: 15%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .pay_period {
                width: 30%;
                display: flex;
                justify-content: flex-start;
            }

            .pay_commercial_period {
                width: 40%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .pay_box {
                width: 15%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .pay_amount {
                width: 10%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p{
                font-style: normal;
                font-weight: 500;
                font-size: 0.8rem;
                text-align: center;
                color: #3B3B46;
            }
        }
    }
    
    .content_container3 {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 
        margin-top: 5%;

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 40vw;
            margin-left: 3%;

            p {
                margin-left: 2%;
                font-size: 1rem;
                font-weight: 700;
            }
        }
        .total_amount {
            display: flex;
            align-items: center;
            height: 5vh;
            width: 40vw;
            margin-left: 3%;
            p {
                margin-left: 2%;
                font-size: 1rem;
                font-weight: 700;
            }
        }

        .total_pay {
            display: flex;
            align-items: center;
            height: 5vh;
            width: 40vw;
            margin-left: 8%;
            p {
                margin-left: 2%;
                font-size: 0.9rem;
                font-weight: 500;
            }
        }
        .data_contain {
            padding: 35px;
            width: 40vw;

            .data_title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 4vh;
                width: 38vw;

                .pay_date {
                    display: flex;
                    align-items: center;
                    width: 30%;
                }
                .pay_status {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .pay_period {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .pay_amount {
                    width: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
    
                p{
                    font-weight: 400;
                    font-size: 0.8rem;
                    text-align: center;
                    color: #000;
                }
            }
        }

        .data_box_map {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 4vh;
            width: 38vw;

            
            .pay_date {
                display: flex;
                align-items: center;
                width: 30%;
            }
            .pay_status {
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .pay_period {
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .pay_amount {
                cursor: pointer;
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            p{
                font-weight: 600;
                font-size: 0.8rem;
                text-align: center;
                color: #000;
            }
        }

        .insert_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42vw;

            .insert_box {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-top: 25px;
                margin-bottom: 25px;
                height: 5vh;
                width: 39vw;
                background : #617BE3;
                border-radius: 4px;
                p {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2vw;
            height: 3vh;
            border: 1px solid #DD1212;
            border-radius: 5px;
            cursor: pointer;

            p {
                font-size: 0.8rem;
                font-weight: 400;
                color: #DD1212
            }
        }


        .cancel_box:hover {
            background: #DD1212;
            p {
                font-size: 0.8rem;
                font-weight: 400;
                color: #fff
            }
        }

    }

    .pictures_title {
        height: 80px;

        .location_box_line {
            height: 50px;

            .how_box {
                display: flex;
                align-items: center;
                height: 50px;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
    .pictures_list_title {
        height: 25vh;
    }
    
    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .category_title {
        position: relative;
        margin-bottom: 3%;

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        input {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        
        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 72px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            position: relative;
            top: 14px;
            bottom: 0;
            right: 0;
            border: 0;
            outline: 0;
            left: 90%;
        }

        textarea {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            min-height: 15vh;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        textarea::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .set_location_box {
            display: flex;
            align-items: center;
            height: 106px;

            .set_location_button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 6vw;
                height: 6vh;
                border-radius: 4px;
                background: #617BE3;
                cursor: pointer;
                p {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }


    }
    .location_box_line {
        height: 60px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 60px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }
    
    .location_box_line2 {
        height: 50px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .category_array {
        margin-bottom: 3%;

        .search_date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2%;

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 9%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }

            input {
                padding: 12px;
            }
        }

    }

    .category_price_line {
        position: "relative";
        margin-bottom: 5%;

        .discount_detail_box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            height: 40px;
            background: #F1F3F7;
            border-radius: 5px;
             
            p {
                margin-left: 15px;
                color: #DD1212;
                font-size: 0.6rem;
                font-weight: 500;
            }
        }

        .product_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 0;
        }
        .product_discount_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 25%;
        }

        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
            
        }

        .classDiscountPrice {
            background: #F1F3F7;
            border: 1px solid #617BE3;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 1px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .discount_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right:0;
            background: #001E62;
            padding: 11px;
            width: 8vw;

            .discount_text {
                font-size: 16px;
                font-weight: bold;
                color: #fff; 
            }

            p {
                font-size: 10px;
                font-weight: normal;
                color: #dfdfe3; 
            }

        }
    }

    .select_box {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .start_hour_box_null {
            width: 49%;
        }
        .start_minute_box_null {
            width: 49%;
        }
        .start_hour_box {
            width: 28%;
        }
        .start_minute_box {
            width: 19%;
        }
        .time_arrange_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4%;
        }
        
        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }


    .option_select_box {
        margin-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 50px;
        border : 1px solid #617BE3;
        border-radius: 4px;
        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .option_select_box:hover {
        background : #617BE3;

        p {
            color: #fff;
        }
    }

    .limit_select_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .delete_selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2%;
            width: 20%;
            height: 50px;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #DD1212;
                text-decoration: underline;
            }
        }

        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }

    .detail_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3vw;
        height: 3vh;

        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #617BE3;
        }
    }

    .date_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5vw;
        height: 3vh;
        background:  #E8EFFC;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 500;
            color: #617BE3;
        }
    }

    .date_box2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7vw;
        height: 3vh;
        background:  #E8EFFC;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 500;
            color: #617BE3;
        }
    }


    .delete_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DD1212;
        border-radius: 5px;
        cursor: pointer;
        height: 30px;
        width: 70px;
        font-size: 0.8rem;
        font-weight: 400;
        color: #DD1212;

        p {
            color: #DD1212;
        }
    }

    .delete_box:hover {
        background: #DD1212;
        color: #fff
    }
`
const Mstyle = styled.div`
    .totalPage {
        width: 100%;
        background-color: #fff;

        .menuContent {
            .menu {
                display: flex;
                align-items: center;
                padding: 5% 5% 5% 5%;

                .title {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;

                    h3 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .data_contain {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5% 5% 5% 5%;
                height: 40vh;

                .chart_data {
                    width: 95%;
                    height: 35vh;
                }
            }

            .commercial_box {
                height: 12vh;

                .commercial_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: 12vh;
                    margin-left: 5%;
                    margin-right: 5%;
                    
                    .commercial_detail {
                        width: 65%;
                        height: 12vh;

                        .title {
                            display: flex;
                            align-items: center;
                            height: 5vh;
                            color: #666;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                        .detail {
                            display: flex;
                            align-items: center;
                            height: 5vh;
                            color: #6B7583;
                            font-size: 0.7rem;
                            font-weight: 500;
                        }
                    }

                    .select_commercial {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 12vh;
                        width: 35%;

                        .button_box {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 5vh;
                            width: 90%;
                            background : #E8EFFC;
                            border-radius: 5px;
    
                            p {
                                font-size: 0.9rem;
                                font-weight: 600;
                                color: #6490E7;
                            }
                        }
                    }
                }

            }
        }
    }

    
`


