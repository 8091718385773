import React, { useState, useEffect } from 'react';
import { Button, Modal, message, Space, Spin } from 'antd';
import styled from 'styled-components';
import '../../assets/fonts/font.css'
import "antd/dist/antd.css";
import { WindowsOutlined, AndroidOutlined, AppleOutlined, SyncOutlined } from '@ant-design/icons';

export const ConfirmTestOrder = (oProps) => {

   const [bShowModal, set_bShowModal] = useState(oProps.bShowTestOrderModal);

   let sDomainUrl = "https://ceo.throo.co.kr";
   if (process.env !== undefined && process.env.REACT_APP_CEO_WEBURL !== undefined) {
      sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
   }

   useEffect(() => {
      if (bShowModal === true) {
         checkOrderCompletion();
      }
   }, [bShowModal])

   const checkOrderCompletion = async () => {
      const oResponse = await oProps.oManager.fDoAxios("/store/order/createTestOrder", "post", null, { store_id: parseInt(oProps.iStoreId) });
      if (oResponse !== undefined && oResponse !== null) {
         if (oResponse.resultCd === "0000") {
            oProps.f_operationFinished();
            oProps.fset_bShowTestOrderModal(false);
         } else {
            alertMessage("테스트 주문을 수락해 주세요");
         }
      }
   }

   const downloadWindowsPosLink = () => {
      window.open(sDomainUrl + '/posdownload', '_blank');
   }

   const downloadAndroidPosLink = () => {
      window.open('https://play.google.com/store/apps/details?id=com.throo_ceo', '_blank');
   }

   const alertMessage = (sIndex) => {
      message.warning({
         content: sIndex,
         className: 'custom-class',
         style: {
            fontSize: "0.9rem",
            fontWeight: "500"
         }
      });
   };

   return (
      <Modal visible={oProps.bShowTestOrderModal} centered={true} closable={false} onOk={() => { }} onCancel={() => { }} okText="확인" cancelText="취소" icon={null} footer={null}
         bodyStyle={{ padding: 0 }}
      >
         <ModalStyle>
            <div className='container'>
               <div className='title_box'>
                  <p>매장 오픈이 가능하다면 테스트 주문을 수락해 주세요</p>
               </div>
               <div className="loading-bar">
                  <Space size="middle">
                     <SyncOutlined spin style={{ fontSize: 34, color: '#1890ff' }} />
                  </Space>
               </div>
               <div className="text-content">
                  <h3>매장에 맞는 포스를 다운로드한 후 테스트 주문을 수락해 주세요.<br />
                     <span>주문 확인</span> > <span>준비 완료</span> > <span>전달 완료</span> 버튼을 순차적으로 누른 후<br />
아래의 확인 버튼을 클릭해 주세요.</h3>
               </div>
            </div>
            <div className="download-list">
               <Button type="" shape="round" icon={<WindowsOutlined />} onClick={downloadWindowsPosLink}> 윈도우 POS 다운로드</Button>
               <div className="spacer"></div>
               <Button type="" shape="round" icon={<><AndroidOutlined /></>} onClick={downloadAndroidPosLink}> 모바일 POS 다운로드</Button>
            </div>
            <div className="hor-splitter"></div>
            <div className="footer">
               <Button type="primary" onClick={checkOrderCompletion}>확인</Button>
            </div>
         </ModalStyle>
      </Modal>
   )
}


const ModalStyle = styled.div`

   .hor-splitter {
      border-top: 1px solid rgba(0,0,0,.06);
   }

   .footer {
      padding: 10px 16px;
      display: flex;
      justify-content: center;
   }

   .download-list {
      padding: 0 24px 24px 24px;
      display: flex;
      justify-content: center;

      .anticon-windows, .anticon-android, .anticon-apple {
         position: relative !important;
         top: -2px !important;
      }
      .ant-btn-round {
         border-radius: 8px !important;
      }
      .spacer {
         width: 10px
      }
   }

   .button-layer {
      position:absolute;
      background:red;
      bottom:20px;
      right:20;
      z-index:10;
   }

    .container {
        font-family: 'Pretendard';
        padding: 24px;

        .title_box {
            display: flex;
            height: 5vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            p {
                font-size: 1.1rem;
                font-weight: 600;
            }
        }

        .loading-bar {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.9rem;
            padding-bottom:0.4rem;
        }

        .text-content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.9rem;

            h3 {
               display: block;
               clear: both;
               font-size: 1rem;
               line-height: 1.4rem;
               text-align:center;
            }

            span {
               color: #1890ff;
               font-weight: 600;
            }
        }
    }
`;
