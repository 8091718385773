import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { message, Select, Modal } from 'antd';
import "antd/dist/antd.css";

import noneImageBox from '../../../assets/svg/none_image.svg';
import searchBlack from '../../../assets/svg/search_black.svg';

import InsertPage from '../../manage/product/Category/InsertForm';
import EditPage from '../../manage/product/Category/EditForm';

import { ModalCategoryPage } from '../../modal/AppModal';

const { Option } = Select;

const Cateogry = ({ oProps }) => {
    const [sCategory, setCategory] = useState("");
    
    const [isLoading, setLoading] = useState(true);
    const [iStatus, setStatus] = useState("");

    const [sCategoryList, setCategoryList] = useState([]);
    const [sProductList, setProductList] = useState([]);
    const [sDetail, setDetail] = useState({});
    const categoryIdNm = useRef(0);
    
    const [modal, contextHolder] = Modal.useModal();

    const changeNormal = () => {
        setStatus("new");
    }

    const onChangeProductValue = (value) => {
        setLoading(true);
        setCategory(value);
        getMenuList(parseInt(value));
        categoryIdNm.current = parseInt(value);
    }

    const deleteRow = async (sIndex) => {
        setLoading(true);
        const oData = {
            sIndex
        };
        const oResponse = await oProps.oManager.fDoAxios("/store/register/deleteMenu/v2", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse){
                message.info("삭제되었습니다");
            } else {
                message.error("삭제에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        await getMenuList(parseInt(categoryIdNm.current));
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};
    
        const formData = new FormData();
        formData.append('photo', fileList);
    
        const oResponse = await oProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
        if(oResponse !== undefined){
            if(oResponse.file_name !== undefined){
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }

    const addList = async (sIndex) => {
        let isProcess = true;
        let logoUpload = {};
        const store_id = oProps.UserConfigReducer.StoreID;
    
        if(sIndex.sFileList !== ""){
            const upload = await imageProcess(sIndex.sFileList);
            if(upload === undefined && upload === null && upload.url_path === ""){
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                isProcess = false;
            } else {
                logoUpload = upload;
            }
        }
    
        if(isProcess){
            const oData = {
                sFileList: logoUpload,
                sTitle: sIndex.productNm,
                sCategory: parseInt(sIndex.sCategory),
                iPrice: sIndex.sPrice,
                dPrice: sIndex.dPrice,
                option: sIndex.sOptionBox,
                sDesc: sIndex.productDetail,
                store_id
            }
        
            const oResponse = await oProps.oManager.fDoAxios("/store/register/registerMenu/v2", "post", "login", oData);
            if(oResponse !== undefined){
                if(oResponse.resultCd === "0000"){
                    message.info("등록되었습니다");
                } else {
                    message.error(oResponse.resultMsg);
                }
            }
            if(sCategory !== ""){
                await getMenuList(parseInt(sIndex.sCategory));
            }
        }
    }

    const editList = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        let isProcess = true;
        let logoUpload = {};

        if(sIndex.sFileList !== ""){
            const upload = await imageProcess(sIndex.sFileList);
            if(upload === undefined && upload === null && upload.url_path === ""){
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                isProcess = false;
            } else {
                sIndex.sPreviewImage = upload.url_path;
                logoUpload = upload;
            }
        }
        
        if(isProcess){
            const oData = {
                sFileList: logoUpload,
                sTitle: sIndex.productNm,
                sCategory: sIndex.sCategory,
                iPrice: sIndex.sPrice,
                dPrice: sIndex.dPrice,
                options: sIndex.sOptionBox,
                sDesc: sIndex.productDetail,
                product_id : sIndex.sProductId,
                media_id : sIndex.mediaId,
                sProductPic: sIndex.sPreviewImage,
                pre_option_list : sIndex.preOptionList,
                store_id
            }
            const oResponse = await oProps.oManager.fDoAxios("/store/register/editMenu/v2", "post", "login", oData);
            if(oResponse !== undefined){
                if(oResponse.resultCd === "0000"){
                    message.info("수정되었습니다");
                } else {
                    message.error(oResponse.resultMsg);
                }
            }
        }
        if(sIndex.sCategory !== sCategory){
            await getMenuList(parseInt(sCategory));
        }
    }

    const openEditPage = async (sIndex) => {
        const menu_id = sIndex.id;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/getMenuDetail/v2-" + menu_id, "get", "login", null);
        if(oResponse !== undefined){
            setDetail(oResponse);
        }
        setStatus("edit");
    }

    const getMenuList = async (sIndex) => {
        const category_id = sIndex;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/menuList-" + category_id, "get", "login", null);
        if(oResponse !== undefined){
            setProductList(oResponse);
        }
        setLoading(false);
    }

    const categoryList = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/detailMenuList-" + store_id, "get", "login", null);
        if(oResponse !== undefined){
            setCategoryList(oResponse.sResult);
            if(oResponse.sResult.length > 0){
                categoryIdNm.current = oResponse.sResult[0].id;
                setCategory(parseInt(oResponse.sResult[0].id));
                getMenuList(parseInt(oResponse.sResult[0].id));
            }
        }
        setLoading(false);
    }

    const modalOpen = {
        content: (
            <ModalCategoryPage sProps={oProps} />
        )
    };

    useEffect(() => {
        categoryList();
    },[])

    return (
        <Style>
            {iStatus !== "edit" ?
                <InsertPage 
                    iProps={oProps} 
                    iCategoryList={sCategoryList} 
                    fAddList={(sIndex) => addList(sIndex)}
                />
            :
                <EditPage 
                    iProps={oProps} 
                    iDetail={sDetail} 
                    iCategoryList={sCategoryList} 
                    fnChangeNormal={() => changeNormal()}
                    fnEdit={(sIndex) => editList(sIndex)}
                />
            }
            <div className="category_line_box">
                <Select value={sCategory} onChange={onChangeProductValue} className="select_bank" bordered={false}>
                    {sCategoryList.map((item, index) => {
                        return (
                            <Option key={index} value={item.id}>{item.name}</Option>
                        )
                    })};
                </Select>
            </div>
            {isLoading ?
                <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center"}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
                :
                <>
                    {sProductList.map((item,index) => {
                        return (
                            <div key={"listing-" + index.toString()} className="category_content_line">
                                <div className="table_box">
                                    <div className="table_content">
                                        <div className="image_box">
                                            <img src={noneImageBox} alt="no_image" />
                                        </div>
                                        <div>
                                            <p>{item.name}</p>
                                            <p className="main_title">{item.soldOut}</p>
                                        </div>
                                    </div>
                                    <div className="table_action">
                                        <div className="edit_box" onClick={() => openEditPage(item)}>
                                            <p>수정</p>
                                        </div>
                                        <div className="delete_box" onClick={() => deleteRow(item.id)}>
                                            <p>삭제</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            }
            <div className="app_modal_container">
                <div className="app_modal" onClick={() => modal.success(modalOpen)}>
                    <p>앱 미리보기</p>
                    <img src={searchBlack} alt="search" style={{marginLeft: "5%"}}/>
                </div>
                <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
            </div>
            {contextHolder}
        </Style>
    )
}

export default Cateogry;

const Style = styled.div`

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .option_table_wrapper {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            .detail_line {
                margin-left: 5px;
                font-size: 0.6rem;
                font-weight: 700;
                color: #617BE3;
            }

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }
    .category_line_box {
        padding: 10px 16px;
        background-color: #617BE3;

        .select_bank {
            padding: 6px;
            width: 100%;
            background: #fff;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table_content {
            display: flex;
            align-items: center;
            width: 65%;
            height: 7vh;

            .image_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15vw;
                height: 8vh;
                background: #F1F3F7;
                border-radius: 5px;
                margin-right: 5%;

                img {
                    height: 7vh;
                    width: 10vw;
                }
            }

            .main_title {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
                height: 3vh;
            }
            p {
                font-size: 0.8rem;
                font-weight: 700;
                height: 3.5vh;
            }
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;
            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }
    .category_price_line {
        position: "relative";

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        .classDiscountPrice {
            background: #F1F3F7;
            border: 1px solid #617BE3;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 1px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        .discount_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right:0;
            background: #001E62;
            padding: 11px;
            width: 120px;

            .discount_text {
                font-size: 1rem;
                font-weight: bold;
                color: #fff; 
            }

            p {
                font-size: 0.7rem;
                font-weight: normal;
                color: #dfdfe3; 
            }

        }
        .discount_detail_box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            height: 45px;
            background: #F1F3F7;
            border-radius: 5px;
             
            p {
                margin-left: 15px;
                color: #DD1212;
                font-size: 0.7rem;
                font-weight: 400;
            }
        }

        .product_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 0;
        }
        .product_discount_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 35%;
        }
        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
            
        }
    }

    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }

        textarea {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }
    
    .last {
        width: 100%;
        padding: 24px 0px 32px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  

    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 11vh;
            width: 22vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 7vw;
                height: 12vh;
            }
        }

        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 11vh;
            width: 22vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                height: 11vh;
                width: 22vw;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 6vh;
                width: 20vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .option_title {
        margin-top: 3%;

        .selected_option_box {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 5vh;
            margin-top: 1%;
            border: 1px solid #BBBBBB;
            border-radius: 5px;

            p {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
        .option_select_box {
            margin-top: 20px;
            display: flex;
            align-items: center;
            height: 3vh;
            cursor: pointer;

            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #617BE3;
            }
        }

    }
`


