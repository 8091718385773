import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import Accordion from "../accordion/Accordion";
import "../../../../assets/css/font.css";

const faq = [
  {
    id: 0,
    title: "스루 이용 요금이 어떻게 되나요?",
    text: `2022년 7월 1일 입점 매장부터 월 매출 30만 원 이상이거나 주문건수가 30건 이상일 경우에만 S/W요금이 부과되며, 한 조건이라도 해당되지 않을 경우 요금이 부과되지 않습니다. 또한 설치비용은 전액 무료입니다.`,
  },
  {
    id: 1,
    title: "스루를 이용하고 싶은데 업종 제한이 있나요?",
    text: `스루는 매장 앞에 잠시 정차만 가능하다면 모든 업종이 가능합니다.
        음식점, 카페뿐만 아니라 꽃 가게, 밀키트, 반찬가게, 편의점 등 다양한 업종들이 드라이브스루 플랫폼을 이용하실 수 있습니다.`,
  },
  {
    id: 2,
    title: "지역, 위치 제한이 있나요?",
    text: `스루는 지역 위치 제한이 없으며 매장 앞에 정차만 가능하다면 어디든 이용 가능합니다.`,
  },
  {
    id: 3,
    title: "주문 누락, 결제 오류 등이 발생하면 어떻게 되나요? ",
    text: `스루의 시스템 오류로 인한 주문 누락, 결제 오류가 발생하셨다면 100% 보상해드립니다.
        단, 정상 주문/결제 후 고객 이 방문, 판매자 과실, 상품 누락 등은 과실책임자가 상대방에게 변상을 해드립니다.`,
  },
];

const ServiceCenterPage = (oProps) => {
  const openCEOKakao = () => {
    if (isMobile) {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    } else {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    }
  };

  return (
    <TotalStyle>
      <Service>
        <div className="sContent">
          <div className="container">
            <h2>
              스루 사용 중<br />
              문제가 발생하셨나요?
            </h2>
            <p>
              사장님 걱정마세요!
              <br />
              시스템의 오류나 장애로 인한 피해라면 반드시 보상해드립니다.
            </p>
            <div className="serviceLink">
              <div className="sLink">
                <h3>전화 상담</h3>
                <a href="tel:1670-5324">
                  <p>1670-5324</p>
                </a>
              </div>
              <div className="line"></div>
              <div className="sLink">
                <h3>카톡 상담</h3>
                <p onClick={openCEOKakao}>스루 사장님 창구</p>
              </div>
            </div>
            <div className="time">
              <p>평일 09시-18시</p>
            </div>
          </div>
        </div>
      </Service>
      <AccordionStyle>
        <div className="aContent">
          <div className="container">
            <h2>자주 묻는 질문</h2>
            {faq.map((noticeContent) => {
              return (
                <Accordion
                  key={noticeContent.id}
                  title={noticeContent.title}
                  content={noticeContent.text}
                />
              );
            })}
          </div>
        </div>
      </AccordionStyle>
    </TotalStyle>
  );
};

export default ServiceCenterPage;

export const TotalStyle = styled.div`
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  input,
  label {
    transform: skew(-0.1deg);
    color: #212329;
    font-family: "NanumSquare";
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }
  .container {
    width: 1080px;
    margin: 0 auto;
  }
`;
export const Service = styled.div`
  .sContent {
    padding: 200px 0 98px 0;
    background-color: #eef6f3;
    text-align: center;
  }
  h2 {
    font-size: 60px;
    font-weight: 900;
    line-height: 78px;
  }
  p {
    margin: 26px 0 72px 0;
    font-size: 22px;
    font-weight: 700;
  }

  .serviceLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 500px;
    margin: 0 auto;
    cursor: pointer;

    .line {
      border: 1px solid #d6e0e0;
      max-height: 52px;
      min-height: 52px;
    }
    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 36px;
    }
    p {
      margin: 0;
      font-size: 26px;
      font-weight: 900;
    }
  }
  .time {
    margin-top: 60px;
    p {
      margin: 0 auto;
      max-width: 260px;
      padding: 21px 51px;
      font-size: 22px;
      font-weight: 900;
      line-height: 34px;
      background-color: #e3eeef;
      border-radius: 50px;
    }
  }
`;
export const AccordionStyle = styled.div`
  .aContent {
    padding: 96px 0 180px 0;
  }
  h2 {
    margin-bottom: 48px;
    text-align: center;
    font-size: 54px;
    font-weight: 900;
    line-height: 68px;
  }
`;
