import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Pagination, message, Popconfirm, Modal } from 'antd';
import "antd/dist/antd.css";

import addressSearch from '../../../../assets/svg/search_black.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';
import searchBlack from '../../../../assets/svg/search_black.svg';

import EditPage from './EditForm';
import ProductEditPage from './EditForm_product';
import InsertForm from './InsertForm';

import { ModalCategoryPage, GuideOptionList, GuideProductOptionList, GuideSetOptionList, } from '../../../modal/AppModal';
import Layout from '../../Layout'
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';
const Option = ({ oProps }) => {
    const [isLoading, setLoading] = useState(true);
    const [sCurrent, setCurrent] = useState(1);
    const [iStatus, setStatus] = useState("");
    const [visible, setVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('option');

    const [noDataText] = useState("데이터가 없습니다.");

    const [iList, setIList] = useState([]);

    const [startPage, setStartPage] = useState(0);
    const [endPage, setEndPage] = useState(5);

    const [sOptionList, setOptionList] = useState({});
    const [sOptionId, setOptionId] = useState(0);
    const iOptionId = useRef({});

    const [modal, contextHolder] = Modal.useModal();

    const [currentPage, setCurrentPage] = useState(1);

    const showModal = (sIndex) => {
        iOptionId.current = sIndex;
        setVisible(true);
    };
    const changeNormal = () => {
        setStatus("");
    }

    const registerOption = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        let oData = {
            sGroupTitle: sIndex.sNm,
            type: sIndex.type,
            sData: sIndex.sData,
            maxCount: 1,
            minCount: 1,
            store_id,
        }

        if (sIndex.type === "checkbox") {
            oData = {
                sGroupTitle: sIndex.sNm,
                type: sIndex.type,
                sData: sIndex.sData,
                maxCount: sIndex.sMax,
                store_id,
            }
        } else {
            if (parseInt(sIndex.sMin) > 1) {
                oData = {
                    sGroupTitle: sIndex.sNm,
                    type: "radioDouble",
                    sData: sIndex.sData,
                    maxCount: sIndex.sMin,
                    minCount: sIndex.sMin,
                    store_id,
                }
            }
        }
        // 일반옵션등록
        const oResponse = await oProps.oManager.fDoAxios("/store/register/insertOption/v2", "post", "login", oData);

        if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
                message.info("등록되었습니다");
            } else {
                message.error(oResponse.resultMsg);
            }
        }
        setLoading(true);
        await getList();
    }


    const detailRow = async (sIndex) => {
        const option_id = sIndex.id;
        console.log('option_id',option_id)
        const oResponse = await oProps.oManager.fDoAxios("/store/register/detailOptionRow-" + option_id, "get", "login", null);

        if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
                setOptionList(oResponse.resultData);
                setOptionId(option_id);
                setStatus("edit");
            } else {
                message.error(oResponse.resultMsg);
            }
        }
    }

    const deleteRow = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;

        console.log('sIndex',sIndex)
        let oData = {

        }
        if (isMobile) {
            oData = {
                sIndex: iOptionId.current,
                store_id
            }
        } else
            oData = {
                sIndex,
                store_id
            }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/deleteOption", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse) {
                setVisible(false)
                message.info("삭제되었습니다");
            } else {
                message.error("삭제에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        setLoading(true);
        await getList();
    }

    const editOption = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        console.log('sIndex.sOptionId',sIndex.sOptionId)
        let oData = {
            sGroupTitle: sIndex.sNm,
            option_id: sIndex.sOptionId,
            sData: sIndex.sData,
            store_id,
        }

        // if (sIndex.sOptionType === "checkbox") {
        //     oData = {
        //         sGroupTitle: sIndex.sNm,
        //         option_id: sIndex.sOptionId,
        //         sData: sIndex.sData,
        //         maxCount: sIndex.sMax,
        //         store_id,
        //     }
        // } else {
        //     if (parseInt(sIndex.sMin) > 1) {
        //         oData = {
        //             sGroupTitle: sIndex.sNm,
        //             option_id: sIndex.sOptionId,
        //             sData: sIndex.sData,
        //             minCount: sIndex.sMin,
        //             store_id,
        //         }
        //     }
        // }

        
        oData = {
            sGroupTitle: sIndex.sNm,
            option_id: sIndex.sOptionId,
            sData: sIndex.sData,
            maxCount: sIndex.sMax,
            minCount: sIndex.sMin,
            store_id,
        }

        const oResponse = await oProps.oManager.fDoAxios("/store/register/editOption/v2", "post", "login", oData);

        if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
                message.info("변경되었습니다");
                setLoading(true);
                await getList();
            } else {
                message.error(oResponse.resultMsg);
            }
        }
    }


    const getList = async () => {

        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/optionList-" + store_id, "get", "login", null);

        try {
            if (oResponse !== undefined) {
                setIList(oResponse);
            }
        } catch (err) {
            console.log("err-->", err);
        }
        setLoading(false);


    }
    const iOption = iList.filter(option => (option.option_type === 0) || (option.option_type === 1 && option.input_min === 0));
    const sOption = iList.filter(option => option.option_type === 1 && option.input_min !== 0);

    const modalOpen = {
        content: (
            <ModalCategoryPage sProps={oProps} />
        )
    };

    const modalOpenGuide = {
        width: "30vw",
        content: (
            <GuideOptionList sProps={oProps} />
        )
    };
    const modal_productOption_guide = {
        width: "30vw",
        content: (
            <GuideProductOptionList sProps={oProps} />
        )
    };
    const modal_setMenuOption_guide = {
        width: "30vw",
        content: (
            <GuideSetOptionList sProps={oProps} />
        )
    };

    // 페이지네이션
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const sCurrentItems = sOption.slice(indexOfFirstItem, indexOfLastItem);
    const iCurrentItems = iOption.slice(indexOfFirstItem, indexOfLastItem);

    const handlePaginationChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const onChangePage = page => {
        let tempStart = 0;
        let tempEnd = 5;

        if (parseInt(page) !== 1) {
            tempEnd = tempEnd * page;
            tempStart = tempEnd - 5;
        }
        setStartPage(tempStart);
        setEndPage(tempEnd);
        setCurrent(page);
    };

    useEffect(() => {
        getList();

    }, []);
    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.product}>
            <BrowserView>
                <Style>
                    {iStatus !== "edit" ?
                        <InsertForm
                            fAddList={(sIndex) => registerOption(sIndex)}
                            oProps={oProps}
                        />
                        :
                        <>
                            {sOptionList.option_type === 0 ?
                                //옵션수정
                                <EditPage
                                    fnChangeNormal={() => changeNormal()}
                                    fEditList={(sIndex) => editOption(sIndex)}
                                    iOptionList={sOptionList}
                                    iOptionId={sOptionId}
                                />
                                :
                                // 선택 옵션 수정
                                <ProductEditPage
                                    fnChangeNormal={() => changeNormal()}
                                    fEditList={(sIndex) => editOption(sIndex)}
                                    iOptionList={sOptionList}
                                    iOptionId={sOptionId}
                                    oProps={oProps}
                                />
                            }
                        </>
                    }

                    <div className="table_line">
                        {!isLoading ?
                            <>
                                {iList.length > 0 ?
                                    <>
                                        <div className="tab" style={{ width: '100%' }}>
                                            <button
                                                className={activeTab === 'option' ? "active" : "nActive"}
                                                onClick={() => setActiveTab('option')}
                                                style={{ width: '100%', flex: '0 0 95%', background: '#fff', border: '1px solid #617BE3', color: '#617be3' }}
                                            >
                                                옵션 목록
                                            </button>
                                            {/*추후 오픈*/}
                                            {/* <button
                                                className={activeTab === 'setOption' ? "active" : "nActive"}
                                                onClick={() => setActiveTab('setOption')}
                                            >
                                                세트 메뉴 구성 옵션
                                            </button> */}
                                        </div>
                                        {activeTab === 'option' &&
                                            <>
                                                {iCurrentItems.map((option, index) => (
                                                    <div className="menuContent" key={option.id}>
                                                        <div className="menu">
                                                            <div className="left_box">
                                                                <p>{option.name}</p>
                                                                {/* {option.option_type === 0 && <div className='iOption'>선택 옵션</div>}
                                                                {option.option_type === 1 && <div className='iOption'>추가 상품 옵션</div>} */}
                                                            </div>
                                                            <div className="right_box">
                                                                <div className="stock" onClick={() => detailRow(option)}>
                                                                    <h2>수정</h2>
                                                                </div>
                                                                <Popconfirm
                                                                    title="정말로 이 옵션그룹을 삭제하시겠습니까?"
                                                                    onConfirm={() => deleteRow(option)}
                                                                    okText="예"
                                                                    cancelText="아니오"
                                                                >
                                                                    <div className="sold_out">
                                                                        <h2>삭제</h2>
                                                                    </div>
                                                                </Popconfirm>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                        {activeTab === 'setOption' &&
                                            <>
                                                {
                                                    sCurrentItems.map((option) => (
                                                        <div className="menuContent" key={option.id}>
                                                            <div className="menu">
                                                                <div className="left_box">
                                                                    <p>{option.name}</p>
                                                                    <div className='sOption'>세트 메뉴 구성 옵션</div>
                                                                </div>
                                                                <div className="right_box">
                                                                    <div className="stock" onClick={() => detailRow(option)}>
                                                                        <h2>수정</h2>
                                                                    </div>
                                                                    <Popconfirm
                                                                        title="정말로 이 옵션그룹을 삭제하시겠습니까?"
                                                                        onConfirm={() => deleteRow(option)}
                                                                        okText="예"
                                                                        cancelText="아니오"
                                                                    >
                                                                        <div className="sold_out">
                                                                            <h2>삭제</h2>
                                                                        </div>
                                                                    </Popconfirm>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </>
                                        }

                                    </>
                                    :
                                    <div className="noData_box">
                                        <h2>{noDataText}</h2>
                                    </div>
                                }
                                <div className="page_nation">
                                    {activeTab === 'setOption' &&
                                        <Pagination
                                            total={sOption.length}
                                            pageSize={itemsPerPage}
                                            current={currentPage}
                                            onChange={handlePaginationChange}
                                        />
                                    }
                                    {activeTab === 'option' &&
                                        <Pagination
                                            total={iOption.length}
                                            pageSize={itemsPerPage}
                                            current={currentPage}
                                            onChange={handlePaginationChange}
                                        />
                                    }
                                    {/* {activeTab === 'option' &&
                                        <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={iOption.length} />
                                    }
                                    {activeTab === 'setOption' &&
                                        <Pagination size="small" pageSize={5} current={sCurrent} onChange={onChangePage} total={sOption.length} />
                                    } */}
                                    {/* <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={iList.length} /> */}
                                </div>
                            </>
                            :
                            <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                        }
                    </div>
                    <div className="side_help_content">
                        <div className="app_modal_container" onClick={() => modal.success(modalOpen)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={addressSearch} alt="none" />
                                    <p>앱 미리보기</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>내 입력정보의 예상화면을 <br />미리 확인해보세요</p>
                                </div>
                            </div>
                        </div>
                        <div className="app_guide_modal_container" onClick={() => modal.success(modalOpenGuide)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={guide_question} alt="none" />
                                    <p>일반옵션 만들기란</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>일반옵션 만들기에 대해 알려드립니다.</p>
                                    <p>자세한 내용을 확인하시려면<br /> 클릭해주세요!</p>
                                </div>
                            </div>
                        </div>
                        {/* 추후 오픈 */}
                        {/* <div className="app_guide_modal_container" onClick={() => modal.success(modal_productOption_guide)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={guide_question} alt="none" />
                                    <p>상품인 옵션 만들기란</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>상품인 옵션 만들기에 대해 알려드립니다.</p>
                                    <p>자세한 내용을 확인하시려면<br /> 클릭해주세요!</p>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="app_guide_modal_container" onClick={() => modal.success(modal_setMenuOption_guide)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={guide_question} alt="none" />
                                    <p>세트 메뉴 구성 옵션 만들기란</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>세트 메뉴 구성 옵션 만들기에 대해 알려드립니다.</p>
                                    <p>자세한 내용을 확인하시려면<br /> 클릭해주세요!</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {contextHolder}
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    {iStatus !== "edit" ?
                        <InsertForm
                            fAddList={(sIndex) => registerOption(sIndex)}
                            oProps={oProps}
                        />
                        :
                        <>
                            {sOptionList.option_type === 0 ?
                                //옵션수정
                                <EditPage
                                    fnChangeNormal={() => changeNormal()}
                                    fEditList={(sIndex) => editOption(sIndex)}
                                    iOptionList={sOptionList}
                                    iOptionId={sOptionId}
                                />
                                :
                                // 선택 옵션 수정
                                <ProductEditPage
                                    fnChangeNormal={() => changeNormal()}
                                    fEditList={(sIndex) => editOption(sIndex)}
                                    iOptionList={sOptionList}
                                    iOptionId={sOptionId}
                                    oProps={oProps}
                                />
                            }
                        </>
                    }
                    {!isLoading ?
                        <>
                            {iList.length > 0 ?
                                <div className='table_line_mobile'>
                                    <div className="tab" style={{ width: '100%' }}>
                                        <button
                                            className={activeTab === 'option' ? "active" : "nActive"}
                                            onClick={() => setActiveTab('option')}
                                            style={{  flex: '0 0 95%', background:'#fff', border: '1px solid #617BE3', color: '#617be3'}}
                                        >
                                            옵션 목록
                                        </button>
                                        {/* <button
                                            className={activeTab === 'setOption' ? "active" : "nActive"}
                                            onClick={() => setActiveTab('setOption')}
                                        >
                                            세트 메뉴 구성 옵션
                                        </button> */}
                                    </div>
                                    {/* 추후 오픈 */}
                                    {/* <div className="tab">
                                        <button
                                            className={activeTab === 'option' ? "active" : "nActive"}
                                            onClick={() => setActiveTab('option')}
                                        >
                                            옵션
                                        </button>
                                        <button
                                            className={activeTab === 'setOption' ? "active" : "nActive"}
                                            onClick={() => setActiveTab('setOption')}
                                        >
                                            세트 메뉴 구성 옵션
                                        </button>
                                    </div> */}
                                    {activeTab === 'option' &&
                                        <>
                                            {iCurrentItems.map((item, index) => {
                                                return (
                                                    <div className="category_content_line" key={"listing-" + index.toString()}>
                                                        <div className="table_box">
                                                            <div className="table_content">
                                                                <p>{item.name}</p>
                                                                {/* {item.option_type === 0 && <div className='iOption'>선택옵션</div>}
                                                                {item.option_type === 1 && <div className='iOption'>추가상품옵션</div>} */}
                                                            </div>
                                                            <div className="table_action">
                                                                <div className="edit_box" onClick={() => detailRow(item)}>
                                                                    <p>수정</p>
                                                                </div>
                                                                <div className="delete_box" onClick={() => showModal(item)}>
                                                                    <p>삭제</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                    {activeTab === 'setOption' &&
                                        <>
                                            {
                                                sCurrentItems.map((item) => (
                                                    <div className="category_content_line" key={item.id}>
                                                        <div className="table_box">
                                                            <div className="table_content">
                                                                <p>{item.name}</p>
                                                                <div className='sOption'>세트 메뉴<br /> 구성 옵션</div>
                                                            </div>
                                                            <div className="table_action">
                                                                <div className="edit_box" onClick={() => detailRow(item)}>
                                                                    <p>수정</p>
                                                                </div>
                                                                <div className="delete_box" onClick={() => showModal(item)}>
                                                                    <p>삭제</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }
                                </div>
                                :
                                <div className="page_nation">
                                    <p>{noDataText}</p>
                                </div>
                            }
                            <div className="page_nation">
                                {activeTab === 'setOption' &&
                                    <Pagination
                                        total={sOption.length}
                                        pageSize={itemsPerPage}
                                        current={currentPage}
                                        onChange={handlePaginationChange}
                                    />
                                }
                                {activeTab === 'option' &&
                                    <Pagination
                                        total={iOption.length}
                                        pageSize={itemsPerPage}
                                        current={currentPage}
                                        onChange={handlePaginationChange}
                                    />
                                }
                            </div>

                        </>
                        :
                        <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                    }
                    <div className="app_modal_container">
                        <div className="app_modal" onClick={() => modal.success(modalOpen)}>
                            <p>앱 미리보기</p>
                            <img src={searchBlack} alt="search" style={{ marginLeft: "5%" }} />
                        </div>
                        <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
                    </div>
                    <Modal
                        title="주의사항"
                        visible={visible}
                        onOk={deleteRow}
                        onCancel={() => setVisible(false)}
                        okText="네"
                        cancelText="아니오"
                    >
                        <p>이 옵션그룹을 삭제하시겠습니까?</p>
                    </Modal>
                    {contextHolder}
                </Mstyle>
            </MobileView>
        </Layout >
    )
}

export default Option;

const Style = styled.div`
    /* 공통 css */
    *, h1, h2, h3, p, button, ul, li, a {
        font-family: 'Pretendard';
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    /* 페이지 네이션 */
    .page_nation button {
        display: flex;
        align-items: center;
        justify-content: center;

        svg path{
            fill: #aaa!important;
        }
    }
    position: relative;
    width: 42vw;

    /* .location_box_line {
        align-items: center;
        margin-bottom: 50px;
        margin-top: 10px;
        height: 50px;
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    } */

    /* 앱미리보기/간편도움말 공통css */
    .side_help_content {
        position: absolute;
        left: 103%;
        top: 0;
    }
    .app_guide_modal_container, .app_modal_container {
        padding: 13px 13px 40px 13px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #FBFBFB;
        border: 1px solid #DFDFE3;
        border-radius: 8px;
        
        .arrow_box img {
            width: 5px;
        }
        .text_box {
            margin-left: 5px;
            min-width: 220px;

            .title_box {
                height: auto;
                margin-bottom: 9px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                }
            }
            .help_content_img {
                width: 18px;
            }
        }
    }

    /* 데이터 없을때 문구 */
    .noData_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10vh;

        h2 {
            font-size: 0.9rem;
            font-weight: 700;
            color: #888888;
        }
    }

    /* 옵션관리 */
    .container {
        width: 42vw; 
        padding: 32px;
        margin-bottom: 1px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
    }
    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 16px;
            font-weight: 700;
            color: #000;
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 14px;
            border: 1px solid #DD1212;
            border-radius: 5px;
            cursor: pointer;

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 700;
                color: #DD1212;
            }
        }
        .cancel_box:hover {
            border: none;
            background: #DD1212;
            p {
                color: #fff;
            }
        }

        .new_category_box {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 7px 14px;
            background: #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                margin: 0;
                font-size: 14px;
                font-weight: 700;
                color: #fff;
            }
        }

       
    }
    
    /* 등록 옵션 관리 */
    .table_line {
        width: 42vw; 
        margin-bottom: 20%;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        border-radius: 4px;

        .tab {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        .active, .nActive {
            flex: 0 0 45%;
            margin: 20px 0 35px 0;
            padding: 10px 0;
            background: #617BE3;
            color: #fff;
            border: none;
            outline: none;
            font-size: 16px;
            font-weight: 700;
            border-radius: 8px;
        }
        .nActive {
            background: rgb(241,243,247);
            border: 1px solid rgb(161,161,161);
            color: rgb(161,161,161);
        }
        .menuContent {
            border-bottom: 1px solid #DFDFDF;
            .menu {
                display: flex;
                align-items: center;
                padding: 18px 25px;

                .left_box {
                    display: flex;
                    align-items: center;
                    flex: 0 0 65%;

                    p {
                        font-size: 0.9rem;
                        font-weight: 700;
                        color: #000;
                    }
                }

                .right_box {
                    flex: 0 0 35%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-right: auto;
                }
            }


            .stock, .sold_out {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 5vw;
                padding: 10px 0;
                border-radius: 4px;
                background: #617be3;
                cursor: pointer;

                h2 {
                    padding: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #fff;
                }
            }

            .stock:hover {
                border: 1px solid #617be3;
                background: #fff;
                h2 {
                    color: #617be3;
                }
            }
            .sold_out {
                margin-left: 20px;
                border: 1px solid #DD1212;
                background: #fff;

                h2 {
                    color: #DD1212;
                }
            }
            .sold_out:hover {
                border: none;
                background: #DD1212;
                h2 {
                    color: #fff;
                }
            }
        }
        /* 선택옵션, 세트메뉴옵션 마크 */
        .iOption,.sOption {
            margin-left : 10px;
            padding: 2px 6px;
            background: #FBFBFB;
            border: 1px solid gray;
            color: gray;
            border-radius: 4px;
            font-size: 12px;
            
        }
        .sOption {
            background: #E8EFFC;
            border: 1px solid #6490E7;
            color: #6490E7;
        }

        /* 페이지네이션 */
        .page_nation {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 7vh;
        }
    }

    /* .delete_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3vw;
        height: 3vh;
        border: 1px solid #DD1212;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #DD1212;
        }
    } */

    /* .table_wrapper {
        margin-top: 10%;
    } */

    /* .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 25px;
        height: 50px;
        border : 1px solid #617BE3;
        border-radius: 4px;
        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .insert_box:hover {
        background : #617BE3;

        p {
            color: #fff;
        }
    } */

    /* .register_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        height: 50px;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;
        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    } */

    /* .category_box {
        height: 7vh;
        margin-bottom: 5%;

        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
    } */

    /* .essential_box {
        height: 70px;
        margin-bottom: 5%;

        .select_line_box {
            margin-top: 12px;
            display: flex;


            .selected_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10vw;
                height: 60px;
                background: #617BE3;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }

                .small_size {
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #fff;
                }
            }
            .unselect_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10vw;
                height: 60px;
                border: 1px solid #617BE3;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #617BE3;
                }
                .small_size {
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #617BE3;
                }
            }

            .disable_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10vw;
                height: 60px;
                border: 1px solid #BBBBBB;
                border-radius: 5px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #bbbbbb;
                }
            }

        }
    } */

    /* .option_box {
        height: 70px;
        margin-bottom: 20px;

        .pay_box {
            position: absolute;
            right: 5%;
            top: 36%;
        }

        .option_input {
            position: relative;
            display: flex;
            width: 60%;

            input {
                margin-top: 2%;
                padding: 12px;
                width: 100%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
                outline: none;
            }

            .input_action {
                border: solid 1px #000000;
                transition: 0.1s ease-out;
            }

            .input_wrong {
                border: solid 1px #DD1212;
                transition: 0.1s ease-out;
            }

            input::placeholder {
                font-size: 0.9rem;
                font-weight: 400;
                color: #BBBBBB;
            }
        }
    } */

    /* .category_title {
        height: 70px;
        margin-bottom: 5%;

        input {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        select {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        
    } */

    
`



const Mstyle = styled.div`
    /* 공통 css */
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
    /* 페이지 네이션 */
    .page_nation button {
        display: flex;
        align-items: center;
        justify-content: center;

        svg path{
            fill: #aaa!important;
        }
    }

    /* tab */
    .table_line_mobile {
        width: 100%; 
        background: #fff;
        border-radius: 4px;

        .tab {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        .active, .nActive {
            flex: 0 0 45%;
            margin: 20px 0 35px 0;
            padding: 10px 0;
            background: #617BE3;
            color: #fff;
            border: none;
            outline: none;
            font-size: 16px;
            font-weight: 700;
            border-radius: 8px;
        }
        .nActive {
            background: rgb(241,243,247);
            border: 1px solid rgb(161,161,161);
            color: rgb(161,161,161);
        }
    }

    /* 선택옵션, 세트메뉴옵션 마크 */
    .iOption,.sOption {
        margin-left : 10px;
        padding: 2px 6px;
        background: #FBFBFB;
        border: 1px solid gray;
        color: gray;
        border-radius: 4px;
        font-size: 10px;
        white-space: nowrap;
        
    }
    .sOption {
        background: #E8EFFC;
        border: 1px solid #6490E7;
        color: #6490E7;
        line-height: 1.3;
    }

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    .insert_option_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15%;
        margin-bottom: 5%;
        height: 50px;
        cursor: pointer;
        border : 1px solid #617BE3;
        border-radius: 4px;

        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .page_nation {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 9vh;
        background: #fff;
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            .detail_line {
                margin-left: 5px;
                font-size: 0.6rem;
                font-weight: 700;
                color: #617BE3;
            }

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table_content {
            display: flex;
            align-items: center;
            width: 60%;
            height: 7vh;

            p {
                font-size: 0.9rem;
                font-weight: 700;
            }
            
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;

            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    /* .essential_box {
        height: 70px;
        margin-top: 3%;
        margin-bottom: 10%;

        .select_line_box {
            margin-top: 2%;
            display: flex;


            .selected_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 50px;
                background: #617BE3;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .unselect_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 50px;
                border: 1px solid #617BE3;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }

            .disable_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 50px;
                border: 1px solid #BBBBBB;
                border-radius: 5px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #bbbbbb;
                }
            }

        }
    } */

    /* 수정css */
    .option_list {
        margin-bottom: 10%;
        margin-top: 5%;

        .pay_box {
            position: absolute;
            right: 33%;
            top: 30%;
        }

        .option_list_box {
            height: 7vh;
            margin-top: 5%;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .option_name_list {
                height: 6vh;
                padding: 12px;
                width: 40%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
            }
            .option_price_list {
                height: 6vh;
                padding: 12px;
                width: 30%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
            }
            .option_order {
                width: 5%;
                justify-content: center;
                display:flex;
                path {
                    fill:black!important
                }
            }
            
            .option_delete_box {
                height: 6vh;
                padding: 12px;
                width: 17%;

                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #dd1212;
                    text-decoration: underline;
                }
            }

        }
    } 

    /* .option_box {
        height: 7vh;
        margin-bottom: 10%;
        margin-top: 5%;

        .pay_box {
            position: absolute;
            right: 5%;
            top: 36%;
        }

        .option_input {
            position: relative;
            display: flex;

            input {
                margin-top: 2%;
                padding: 12px;
                width: 100%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
                outline: none;
            }

            .input_action {
                border: solid 1px #000000;
                transition: 0.1s ease-out;
            }

            .input_wrong {
                border: solid 1px #DD1212;
                transition: 0.1s ease-out;
            }

            input::placeholder {
                font-size: 0.9rem;
                font-weight: 400;
                color: #BBBBBB;
            }
        }
    } */

    /* .inputText {
        height: 70px;
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            margin-top: 2%;
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }
    } */
    
    /* .last {
        width: 100%;
        padding: 0px 0px 24px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  
    .bottom_fixed { */
        /* width: 100%;
        position: sticky;
        bottom: 15px;
        .finishButton {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
            z-index:10;
        }
    }   */

`


