import React from 'react';
import styled from 'styled-components';

// 이미지
import appBanner01 from '../../../../assets/img/guide/appBanner01.png';
import appBanner02 from '../../../../assets/img/guide/appBanner02.png';
import appBanner03 from '../../../../assets/img/guide/appBanner03.png';
import appBanner04 from '../../../../assets/img/guide/appBanner04.png';
import appBanner05 from '../../../../assets/img/guide/appBanner05.png';
import appBanner06 from '../../../../assets/img/guide/appBanner06.png';
import appBanner07 from '../../../../assets/img/guide/appBanner07.png';
import appBanner08 from '../../../../assets/img/guide/appBanner08.png';
import appBanner09 from '../../../../assets/img/guide/appBanner09.png';
const OnlyGuide = (oProps) => {

    return (
        <D_onlyGuide>
            <div className="ceoTip">
                <div className="container">
                    <div className="cTip">
                        <h3>스루 가이드</h3>
                        <h1>앱 내 매장 광고 서비스 오픈 예정 안내</h1>
                        <p>
                            안녕하세요 사장님,<br /><br />

                            더 많은 고객에게 사장님의 스루 입점을 알리고 매출을 높일 수 있도록 광고 서비스 오픈을 안내드립니다.
                            베타서비스 참여 사장님, 얼리어답터 사장님을 대상으로 사장님 매장을 광고할 수 있도록 광고 서비스 오픈 일 기준 3달간 광고 포인트 매달 15만 포인트씩 총 45만 포인트를 지원해 드립니다.<br /><br />

                            고객 위치 기반 2km 이내 매장만 노출이 되기 때문에 효율적인 광고효과를 기대할 수 있습니다. 광고를 통해 추가 매출을 올려보세요.
                        </p>
                        <div className="tip">
                            <h2>1. 메인 배너광고</h2>
                            <img src={appBanner01} alt="메인 배너광고 이미지" width="100%" />
                            <p>스루 앱 가장 처음 상단에 나타나는 메인 배너광고입니다. 고객에게 가장 먼저 보이기 때문에 노출도가 가장 높습니다.</p>

                            <h2>2. 내 주변 쿠폰 광고</h2>
                            <img src={appBanner02} alt="내 주변 쿠폰 광고 이미지" width="100%" />
                            <p>매장에 쿠폰이 등록되어 있을 경우에만 노출됩니다. 쿠폰을 등록한 뒤 광고를 통해 쿠폰 효과를 올려보세요.</p>

                            <h2>3. 스루 온리 광고</h2>
                            <img src={appBanner03} alt="스루 온리 광고 이미지" width="100%" />
                            <p>스루 온리 광고는 한시적으로 무료이며 최상단에 노출됩니다. 스루에서만 구매할 수 있는 상품을 등록하셨다면 자동으로 광고가 적용되어 더 많은 고객에게 상품이 노출됩니다. 적극 활용해 보세요!</p>

                            <h2>4. 신규 입점 광고</h2>
                            <img src={appBanner04} alt="신규 입점 광고 이미지" width="100%" />
                            <p>입점일 기준 6개월 이내의 매장들만 노출됩니다. 스루 영업 시작을 더 많은 고객에게 알려보세요.</p>

                            <h2>5. 핫메뉴 광고</h2>
                            <img src={appBanner05} alt="핫메뉴 광고 이미지" width="100%" />
                            <p>우리 매장의 인기 상품을 광고해 보세요. 우리 매장의 인기상품이 여러 개라면 개수 제한 없이 모두 고객에게 알릴 수 있습니다.</p>
                        </div>
                        <div className="tip">
                            <p>
                                <span style={{fontWeight:"900"}}>😊 오프라인 광고</span><br /><br />

                                오프라인 광고물을 통해 매장에 방문하시는 고객에게 스루 사용을 홍보할 수 있습니다.
                            </p>
                            <h2>오프라인 홍보 KIT</h2>
                            <img src={appBanner06} alt="오프라인 홍보 KIT 이미지" width="100%" />
                            <p>a2 포스터, a3 포스터, a5 전단지, 테이블 텐트, 스티 커(매장 부착용 2종), 테이블 텐트도 2종 구성으로 이루어져 있습니다. 매장을 방문하는 고객에게 스루를 홍보할 수 있어요.</p>
                        </div>
                        <div className="tip">
                            <h2>😊 광고 구매 안내</h2>
                            <img src={appBanner07} alt="광고 구매 안내 이미지" width="100%" />
                            <p>광고 구매는 광고 포인트로 구매가 가능합니다. 광고 포인트 확인은 매장관리 <span>&#62;</span> 광고 포인트 페이지에서 확인 가능합니다.</p>

                            <img src={appBanner08} alt="광고 구매 안내 이미지" width="100%" style={{marginTop:"34px"}} />
                            <img src={appBanner09} alt="광고 구매 안내 이미지" width="100%"  />
                            <p>광고와 광고 노출 날짜를 사장님이 직접 선택하여 구매할 수 있으며, 광고 구매일 기준 첫날은 무료로 적용됩니다.</p>

                            <h2 style={{fontSize:"16px", marginTop: "50px"}}>😊 광고 포인트 지원 안내</h2>
                            <p>
                                영업 시작일 기준 매달 15만 포인트씩 3달을 지원해 드리며, 총 45만 포인트의 혜택을 제공합니다.<br /><br />

                                기존 사장님은 광고 오픈 시작일을 기준으로 동일한 조건으로 지원해 드립니다.
                            </p>
                        </div>
                        <p style={{ marginTop: "34px"}}>효율적인 광고 관리를 통해 더 많은 고객에게 우리 매장을 알리고 매출을 높여보세요</p>

                    </div>
                </div>
            </div>
        </D_onlyGuide>
    )
}

export default OnlyGuide;
export const D_onlyGuide = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 702px;
        margin: 0 auto;
    }
    img {
        margin: 12px 0;
    }
    .cTip {
        margin-top: 106px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 26px 0;
            font-size: 32px;
            font-weight: 900;
            line-height: 48px;
        }
        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .tip {
        padding: 38px 0;
        border-bottom: 2px solid #EEEEEE;

        h2 {
            margin: 34px 0 10px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;

            span {
                color: #8E929F;
            }
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
`