import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import rightArrow from '../../../../assets/img/ceoscreen/rightArrow.png';

const PosGuidContent = (oProps) => {
    return (
        <GuideStyle>
            <div className="posGuide">
                <div className="guideContent">
                    <Link to={{ pathname: '/ceoguide', page_id: 'mob_posguide', search: 'mob_posguide' }}>
                        <div className="gContent">
                            <div className="title">
                                <h3>스루 주문접수</h3>
                                <h2>스루 주문접수 가이드</h2>
                            </div>
                            <button><img src={rightArrow} alt="더보기 버튼" /></button>
                        </div>
                    </Link>
                </div>
                <div className="guideContent">
                    <Link to={{ pathname: '/ceoguide', page_id: 'mob_autoguide', search: 'mob_autoguide' }}>
                        <div className="gContent">
                            <div className="title">
                                <h3>스루 주문접수</h3>
                                <h2>자동으로 주문 접수 가능하게 설정하는 방법</h2>
                            </div>
                            <button><img src={rightArrow} alt="더보기 버튼" /></button>
                        </div>
                    </Link>
                </div>
                <div className="guideContent">
                    <Link to={{ pathname: '/ceoguide', page_id: 'mob_cancelguide', search: 'mob_cancelguide' }}>
                        <div className="gContent">
                            <div className="title">
                                <h3>스루 주문접수</h3>
                                <h2>스루 주문 취소 방법</h2>
                            </div>
                            <button><img src={rightArrow} alt="더보기 버튼" /></button>
                        </div>
                    </Link>
                </div>
            </div>
        </GuideStyle>
    )
}

export default PosGuidContent;

export const GuideStyle = styled.div`
    .posGuide {
        width: 100%;
        padding: 26px 30px 0 16px;
        border: 1px solid #ECEEF2;
        border-radius: 4px;
    }
    .guideContent {
        margin-bottom: 26px;
    }
    .gContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title {
        h2 {
            margin: 0;
            font-size: 16px!important;
            font-weight: 600;
            color: #000;
        }
        h3 {
            margin-bottom: 6px;
            font-size: 14px;
            font-weight: 700;
            color: #888;
        }
    }
    button {
        border: none;
        background: none;
        outline: none;

        img {
            width: 16px;
            height: 16px;
        }
    }
`
