import React, { useState, useRef } from 'react';
import SVG from 'react-inlinesvg';
import randtoken from 'rand-token';
import { message } from 'antd';
import "antd/dist/antd.css";
import { useNavigate, useLocation, Link } from 'react-router-dom'

import { SignUpStyle } from '../../assets/styledComponents/SignInStyle';

import loginLogo from '../../assets/svg/loginlogo.svg';
import { WebRoute } from '../../navigation/WebRoutes';

const FindInfo = ({ fModeChange, sProps }) => {
   const [sId, setId] = useState("");
   const [sPhone, setPhone] = useState("");
   const [sOwner, setOwner] = useState("");
   const [sPhoneCheck, setPhoneCheck] = useState("");
   const [userId, setUserId] = useState("");
   const [sToken, setToken] = useState("");

   const [minLimit, setMinLimit] = useState(4);
   const [secondsLimit, setSecondsLimit] = useState(59);

   const [errorMessageNm, setErrorMessageNm] = useState("");
   const [errorMessageOwner, setErrorMessageOwner] = useState("");
   const [errorMessagePhone, setErrorMessagePhone] = useState("");

   const [classNameNm, setClassNameNm] = useState(null);
   const [classNameOnwer, setClassNameOwner] = useState(null);
   const [classNamePhone, setClassNamePhone] = useState(null);
   const [classNamePhoneCheck, setClassNamePhoneCheck] = useState(null);

   const [isClear, setClear] = useState(false);
   const [isDisabled, setIsDisabled] = useState(false);
   const [isCheck, setCheck] = useState(false);

   const minutes = useRef(4);
   const seconds = useRef(59);
   const intervalId = useRef(null);
   const navigate = useNavigate()

   const queryString = window.location.search;
   let bIsThrooStore = false;
   if (queryString.indexOf('source=throostore') !== -1) {
      bIsThrooStore = true;
   }

   const onChangeNm = async (e) => {
      setClassNameNm("input_action");
      setErrorMessageNm("");
      setId(e.target.value);
   }

   const inputUserOwner = (e) => {
      setClassNameOwner("input_action");
      setErrorMessageOwner("");
      setOwner(e.target.value);
   }

   const inputUserPhone = (e) => {
      setClassNamePhone("input_action");
      setErrorMessagePhone("");
      setPhone(e.target.value);
   }

   const onChangeCheck = e => {
      const sTemp = e.target.value;
      const regex = /[0-9]+$/gi;
      if (sTemp === "" || sTemp === null) {
         setClassNamePhoneCheck("input_action");
         setPhoneCheck(sTemp);
      } else {
         if (regex.test(sTemp)) {
            setClassNamePhoneCheck("input_action");
            setPhoneCheck(sTemp);
         } else {
            setClassNamePhoneCheck("input_wrong");
         }
      }
   };

   const confirmOwnerInfo = async () => {
      if (sPhoneCheck === "") {
         setClassNamePhoneCheck("input_wrong");
      } else {
         const oData = {
            userPhone: sPhone,
            userId,
            sCount: sPhoneCheck,
            smsToken: sToken
         }
         const oResponse = await sProps.oManager.fDoAxios("/register/verifySms/v2", "post", null, oData);
         if (oResponse !== undefined && oResponse !== null) {
            if (oResponse.resultCd) {
               await stopCounter();
               setIsDisabled(true);
               setCheck(true);
               setSecondsLimit(59);
               setMinLimit(4);
               setClear(true);
            } else {
               setClassNamePhoneCheck("input_wrong");
            }
         }
      }
   }

   const checkPhoneNm = async (e) => {
      if (sId === "") {
         setClassNameNm("input_wrong");
         setErrorMessageNm("매장명을 입력해주세요.");
      } else if (sOwner === "") {
         setClassNameOwner("input_wrong");
         setErrorMessageOwner("대표자명을 입력해주세요.");

      } else if (sPhone === "") {
         setClassNamePhone("input_wrong");
         setErrorMessagePhone("전화번호를 입력해주세요.");

      } else {
         const regex = /[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
         if (regex.test(sPhone)) {
            const oData = {
               sId,
               sPhone,
               sOwner,
            }
            const oResponse = await sProps.oManager.fDoAxios("/app/ceo/authenticate/check/checkId/v2", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
               if (oResponse.resultCd === "0000") {
                  let token = randtoken.generate(32);
                  setToken(token);
                  setUserId(oResponse.id);
                  await sendSMS(token, oResponse.id);
               } else {
                  message.error(oResponse.resultMsg);
               }
            }
         } else {
            setClassNamePhone("input_wrong");
            setErrorMessagePhone(`'-'을 제외한 숫자 및 올바른 번호가 필요합니다`);
         }
      }
   }

   const validationErr = async (sIndex) => {

   }

   const stopCounter = async () => {
      await clearInterval(intervalId.current);
   };

   const startCounter = () => {
      minutes.current = 4;
      seconds.current = 59;

      intervalId.current = setInterval(async () => {
         seconds.current -= 1;

         let tempMinute = parseInt(minutes.current);
         let tempSeconds = parseInt(seconds.current);
         if (tempSeconds < 0) {
            if (tempMinute === 0) {
               minutes.current = 0;
               seconds.current = 0;

               tempMinute = 0;
               tempSeconds = "00";

               stopCounter();
            } else {
               minutes.current -= 1;
               seconds.current = 59;

               tempMinute = minutes.current;
               tempSeconds = 59;
            }

         } else if (tempSeconds < 10) {
            tempSeconds = "0" + tempSeconds.toString();
         }

         setSecondsLimit(tempSeconds);
         setMinLimit(tempMinute);

         if (tempMinute < 1 && tempSeconds < 1) {
            setSecondsLimit(59);
            setMinLimit(4);
         }
      }, 1000);
   };

   const sendSMS = async (aIndex, sIndex) => {
      const oData = {
         token: aIndex,
         userPhone: sPhone,
         userId: sIndex,
      }
      const oResponse = await sProps.oManager.fDoAxios("/app/ceo/authenticate/check/sms", "post", null, oData);
      if (oResponse !== undefined && oResponse !== null) {
         if (oResponse.resultCd === "0000") {
            await stopCounter();
            setIsDisabled(true);
            setCheck(true);
            setSecondsLimit(59);
            setMinLimit(4);
            await startCounter();
         } else {
            message.error(oResponse.resultMsg);
         }
      }
   }

   const modeChange = async () => {
      if (fModeChange !== undefined && typeof fModeChange === "function") {
         // await fModeChange("signIn");
      }
   }

   const doCancel = async () => {
      if (window.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage(
            JSON.stringify({ action: 'cancel' })
         );
      }
   }

   return (
      <SignUpStyle>
         <div className="loginPage">
            <div className="finInfoBox">
               <SVG src={loginLogo} width="54px" height="18px" title="로그인페이지로고" />
               <h2>아이디 찾기</h2>
               {isClear ?
                  <>
                     <div className="inputBox">
                        <input type="text" disabled value={userId} />
                     </div>
                     {bIsThrooStore === true &&
                        <button onClick={doCancel} className="back_out" style={{ border: "1px solid #617BE3", marginTop: "5%" }}>로그인하기</button>
                     }
                     {bIsThrooStore === false &&
                        <Link to={WebRoute.ADMIN_SIGNIN}>
                           <button className="join" onClick={modeChange} style={{ backgroundColor: "#001E62", marginTop: "5%" }}>로그인하기</button>
                        </Link>
                     }
                  </>
                  :
                  <>
                     <div className="inputBox">
                        <input
                           type="text"
                           placeholder="매장명"
                           value={sId}
                           onChange={onChangeNm}
                           className={classNameNm}
                           onClick={() => validationErr("classNameNm")}
                        />
                     </div>
                     <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageNm}</p>
                     <div className="inputBox">
                        <input
                           type="text"
                           placeholder="대표자명"
                           value={sOwner}
                           onChange={inputUserOwner}
                           className={classNameOnwer}
                           onClick={() => validationErr("classNameOwner")}
                        />
                     </div>
                     <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageOwner}</p>
                     <label>
                        <input
                           type='number'
                           disabled={isDisabled}
                           placeholder="휴대폰번호( ‘ - ’제외 )"
                           value={sPhone}
                           onChange={inputUserPhone}
                           className={classNamePhone}
                           onClick={() => validationErr("classNamePhone")}
                        />
                        {isCheck ?
                           <button onClick={checkPhoneNm} style={{ backgroundColor: "#fff", border: "1px solid #001E62", color: "#001E62" }}>재요청</button>
                           :
                           <button onClick={checkPhoneNm} style={{ backgroundColor: "#617BE3", border: "1px solid #617BE3" }}>인증요청</button>
                        }
                        <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessagePhone}</p>
                     </label>
                     {isCheck &&
                        <label>
                           <div className="inputBox">
                              <p style={{ position: "absolute", right: "100px", zIndex: "100", color: "#DD1212", fontSize: "12px", fontWeight: "400", marginTop: "12px" }}>{minLimit}:{secondsLimit}</p>
                              <input
                                 type="number"
                                 placeholder="인증번호"
                                 value={sPhoneCheck}
                                 onChange={onChangeCheck}
                                 className={classNamePhoneCheck}
                                 style={{ display: "flex", position: "absolute", margin: "-3px 0 12px 0" }}
                              />
                           </div>
                           <button className="btn" onClick={confirmOwnerInfo} style={{ backgroundColor: "#617BE3", position: "relative", marginTop: "-3px", outline: "none" }}>확인</button>
                        </label>
                     }
                     {bIsThrooStore === true &&
                        <button onClick={doCancel} className="back_out" style={{ border: "1px solid #617BE3", marginTop: "5%" }}>취소</button>
                     }
                     {bIsThrooStore === false &&
                        <Link to={WebRoute.ADMIN_SIGNIN}>
                           <button className="back_out" style={{ border: "1px solid #617BE3", marginTop: "5%" }}>취소</button>
                        </Link>
                     }
                  </>
               }
            </div>
         </div>
      </SignUpStyle>
   )
}

export default FindInfo;
