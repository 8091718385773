import React, { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import Loader from "react-loader-spinner";

import Payment from './Payment';

const Main = ({ oProps }) => {
   const [iStatus, setStatus] = useState(1);
   const [sCategory, setCategory] = useState("매출조회");

   const [sList] = useState([
      { id: 1, name: "매출조회", value: "payment" },
   ]);

   const moveCategory = async (aIndex, sIndex) => {
      setStatus(aIndex);
      setCategory(sIndex);
   }

   return (
      <Style>
         <div className="slaesMobilePage">
            {/* <div className="tapPage">
                    <div className="menu_container">
                        <div className="tab">
                            <ScrollContainer style={{width: "100%"}}>
                                {sList.map((item, index) => {
                                    return (
                                        <div className={item.name === sCategory ? "scroll_menu_active": "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.id,item.name)}>
                                            {item.name}
                                        </div>
                                    )
                                })}
                            </ScrollContainer>
                        </div>
                    </div>
                </div> */}
            {parseInt(iStatus) === 0 &&
               <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                  <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
               </div>
            }
            {parseInt(iStatus) === 1 &&
               <Payment oProps={oProps} />
            }

         </div>
      </Style>
   )
}

export default Main;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }

`


