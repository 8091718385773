import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { message, Modal } from 'antd';
import "antd/dist/antd.css";

import InsertPage from '../../manage/product/Category/InsertForm';
import EditPage from '../../manage/product/Category/EditForm';
import searchBlack from '../../../assets/svg/search_black.svg';

import { ModalCategoryPage } from '../../modal/AppModal';

const Cateogry = ({ oProps }) => {
    const [isLoading, setLoading] = useState(true);

    const [iStatus, setStatus] = useState("");

    const [hasList, setHasList] = useState("none");
    const [iLimit, setLimit] = useState(false);

    const [sMainId, setMainId] = useState(0);

    const [sData, setData] = useState({});
    const [sCategoryList, setCategoryList] = useState([]);

    const [modal, contextHolder] = Modal.useModal();

    const editRow = (sIndex) => {
        setData(sIndex);
        setStatus("edit");
    }

    const changeNormal = () => {
        setStatus("new");
    }

    const modalPopUp = () => {
        message.info("준비중입니다.");
    }

    const editCategoryList = async (sIndex) => {
        const storeId = oProps.UserConfigReducer.StoreID;
        const oData = {
            sContent : sIndex.sNm,
            isMain : sIndex.isMain,
            isUse : sIndex.selectValue,
            menuId : sIndex.sId,
            storeId
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/editCategoryList", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse){
                message.info("변경되었습니다");
            } else {
                message.error("변경에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        setStatus("");
        await getList();
    }

    const addList = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const storeName = oProps.UserConfigReducer.StoreName;
        const oData = {
            sContent : sIndex.sNm,
            isMain : sIndex.isMain,
            isUse : sIndex.isUse,
            menuId : sIndex.menuId,
            sCategoryList,
            storeName,
            store_id,
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/insertCategory", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse.resultCd === "0000"){
                message.info("등록되었습니다");
            } else {
                message.error(oResponse.resultMsg);
            }
        }
        await getList();
    }

    const deleteRow = async (category_id) => {
        const storeId = oProps.UserConfigReducer.StoreID;
        const oData = {
            category_id,
            storeId
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/deleteCategory", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse){
                message.info("삭제되었습니다");
            } else {
                message.error("삭제에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        setStatus("");
        await getList();
    }

    const getList = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/categoryList/v2-" + store_id, "get", "login", null);
        if(oResponse !== undefined){
            if(oResponse.noList !== "none"){
                setCategoryList(oResponse.sResult);
                setHasList(true);
            }
            setMainId(oResponse.mainId);
            setHasList(oResponse.noList);
            setLimit(oResponse.sLimit);
        }
        setLoading(false);
    }

    const modalOpen = {
        content: (
            <ModalCategoryPage sProps={oProps} />
        )
    };

    useEffect(() => {
        getList();
    },[])

    return (
        <Style>
            {iStatus !== "edit" ?
                <InsertPage 
                    sHasList={hasList}
                    sLimit={iLimit}
                    fnAddList={(sIndex) => addList(sIndex)}
                />
            :
                <EditPage 
                    iMainId={sMainId}
                    iProps={oProps} 
                    iDetail={sData} 
                    sLimit={iLimit}
                    fnChangeNormal={() => changeNormal()}
                    fnEditCategoryList={(sIndex) => editCategoryList(sIndex)}
                />
            }
            {isLoading ?
                <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center"}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
                :
                <>
                    {sCategoryList.map((item,index) => {
                        return (
                            <div key={"listing-" + index.toString()} className="category_content_line">
                                <div className="table_box">
                                    <div className="table_content">
                                        <div className="title">
                                            <p>{item.name}</p>
                                        </div>
                                        <div className="content">
                                            {item.isMain === "예" &&
                                                <div className="main_title">
                                                    <p>대표메뉴</p>
                                                </div>
                                            }
                                            <p>{item.useYn}</p>
                                        </div>
                                    </div>
                                    <div className="table_action">
                                        <div className="edit_box" onClick={() => editRow(item)}>
                                            <p>수정</p>
                                        </div>
                                        <div className="delete_box" onClick={() => deleteRow(item.id)}>
                                            <p>삭제</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            }
            <div className="app_modal_container">
                <div className="app_modal" onClick={() => modal.success(modalOpen)}>
                    <p>앱 미리보기</p>
                    <img src={searchBlack} alt="search" style={{marginLeft: "5%"}}/>
                </div>
                <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
            </div>
            {contextHolder}
        </Style>
    )
}

export default Cateogry;

const Style = styled.div`

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            .detail_line {
                margin-left: 5px;
                font-size: 0.6rem;
                font-weight: 700;
                color: #617BE3;
            }

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table_content {
            width: 60%;
            height: 7vh;

            .title {
                display: flex;
                height: 3.5vh;
                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                }
            }

            .content {
                display: flex;
                height: 3.5vh;

                .main_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 17vw;
                    height: 3vh;
                    background: #001E62;
                    border-radius: 5px;
                    margin-right: 5%;

                    p {
                        font-size: 0.7rem;
                        font-weight: 400;
                        color: #fff;
                    }
                }
                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                }
            }
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;

            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }
    }
    
    .last {
        width: 100%;
        padding: 24px 0px 32px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  

`


