import React, { useState, useEffect } from 'react';

import Header from "./mobile/Header";
import Side from "../side/MobileSide";
import { useLocation } from 'react-router-dom';

const MobileHeader = ({ activeStatus, oProps, iStoreUnReviewCount, bManualPaused, fOperateStore, fChangeStoreCloseStatus }) => {
   const [isOpen, setIsOpen] = useState(false);
   const location = useLocation();


   const toggle = () => {
      setIsOpen(!isOpen);
      if (isOpen === true) {
         document.body.classList.remove('hide-scroll');
      } else if (isOpen === false) {
         document.body.classList.add('hide-scroll');
      }
   }
   const closeSide = () => {
      setIsOpen(false);
      document.body.classList.remove('hide-scroll');
   }

   useEffect(() => {
      closeSide();
   }, [location.pathname]);

   return (
      <>
         <Side activeStatus={activeStatus} bManualPaused={bManualPaused} fOperateStore={fOperateStore} fChangeStoreCloseStatus={fChangeStoreCloseStatus} isOpen={isOpen} toggle={toggle} sProps={oProps} iStoreUnReviewCount={iStoreUnReviewCount} />
         <Header toggle={toggle} activeStatus={activeStatus} bManualPaused={bManualPaused} sProps={oProps} />
      </>
   )
}

export default MobileHeader;