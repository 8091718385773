import React from 'react';
import { isMobile } from "react-device-detect";

import M_promotion from "./mobile/M_promotion";
import D_promotion from "./desktop/D_promotion";

const MainPromotion = oProps => {

    return (
        <>
        {isMobile ? <M_promotion {...oProps} /> : <D_promotion {...oProps} /> }
        </>
    )
        

}

export default MainPromotion;