import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import Loader from "react-loader-spinner";
import { message, DatePicker, Select } from "antd";
import moment from "moment-timezone";
import "antd/dist/antd.css";
import "moment/locale/ko";

import Layout from "../../Layout";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import layoutHeaderLists from "../../config";
import plusIcon from "../../../../assets/svg/ic_plus.svg";
import minusIcon from "../../../../assets/svg/ic_minus.svg";
import betaIcon from "../../../../assets/svg/betaicon.svg";

const Delivery = ({ oProps }) => {
  const [basicDistance, setBasicDistance] = useState([]);
  const [surchargeDistance, setSurchargeDistance] = useState([]);
  for (let i = 1.5; i <= 2000; i += 0.5) {
    basicDistance.push({
      value: i,
      label: `${i}km`,
    });
  }
  basicDistance.unshift({ value: 0, label: "기본거리", disabled: true });

  for (let i = 100; i <= 20000; i += 100) {
    surchargeDistance.push({
      value: i,
      label: `${i}m`,
    });
  }
  surchargeDistance.unshift({ value: 0, label: "할증단위", disabled: true });

  const [orderPrice, setOrderPrice] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState("");
  const [surchargeDeliveryPrice, setSurchargeDeliveryPrice] = useState(0);
  const [deliveryTime, setDeliveryTime] = useState(45);
  const [distance, setDistance] = useState(3.0);
  const [selectBasicDistance, setSelectBasicDistance] = useState(
    basicDistance[0].value
  );
  const [selectSurchargeDistance, setSelectSurchargeDistance] = useState(
    surchargeDistance[0].value
  );
  const [moreDeliveryPrice, setMoreDeliveryPrice] = useState([
    { more_order_price: "", more_delivery_price: "" },
  ]);

  const onChangeOrderPriceValue = (event) => {
    setOrderPrice(event.target.value);
  };
  const onChangeDeliveryPriceValue = (event) => {
    setDeliveryPrice(event.target.value);
  };
  const onChangesurchargePriceValue = (event) => {
    setSurchargeDeliveryPrice(event.target.value);
  };

  // 배달 가능 거리 지정
  const plusDistance = () => {
    setDistance(distance + 0.5);
  };
  const minusDistance = () => {
    if (distance > 1.5) {
      setDistance(distance - 0.5);
    }
  };
  const plusDeliveryTime = () => {
    setDeliveryTime(deliveryTime + 1);
  };
  const minusDeliveryTime = () => {
    setDeliveryTime(deliveryTime - 1);
  };

  // 추가 거리당 할증 배달팁
  const basicDistanceChange = (selected) => {
    setSelectBasicDistance(selected);
  };
  const surchargeDistanceChange = (selected) => {
    setSelectSurchargeDistance(selected);
  };

  // 주문 금액별 배달팁 추가
  const onAddDetailDiv = () => {
    let moreDeliveryPriceArr = [...moreDeliveryPrice];
    moreDeliveryPriceArr.push({
      more_order_price: "",
      more_delivery_price: "",
    });
    setMoreDeliveryPrice(moreDeliveryPriceArr);
  };
  // 주문 금액별 배달팁 삭제
  const onRemoveDetailDiv = (index) => {
    let moreDeliveryPriceArr = [...moreDeliveryPrice];
    moreDeliveryPriceArr.splice(index, 1);
    setMoreDeliveryPrice(moreDeliveryPriceArr);
  };
  const filteredMoreDeliveryPrice = moreDeliveryPrice.filter(
    (item) => item.more_order_price !== "" && item.more_delivery_price !== ""
  );

  //데이터 가져오기
  const getData = async () => {
    try {
      const oResponse = await oProps.oManager.fDoAxios(
        "/store/getDeliveryManage",
        "post",
        "login",
        null
      );
      if (oResponse && oResponse.data) {
        const oResp = oResponse.data;

        setOrderPrice(oResp.limit_order_price);
        setDistance(Number(oResp.max_delivery_distance));
        setDeliveryPrice(oResp.base_delivery_price);
        setSelectBasicDistance(oResp.base_delivery_distance);
        setSelectSurchargeDistance(oResp.surcharge_delivery_distance);
        setSurchargeDeliveryPrice(oResp.surcharge_delivery_price);
        setDeliveryTime(oResp.delivery_prepare_time);
        setMoreDeliveryPrice(oResp.more);
      }
    } catch (err) {
      console.log("err-->", err);
    }
  };
  
  // 등록하기버튼(submit)
  const insertDelivery = async () => {
    try {
      const data = {
        limit_order_price: orderPrice,
        max_delivery_distance: distance,
        base_delivery_price: deliveryPrice,
        base_delivery_distance: selectBasicDistance,
        surcharge_delivery_distance: selectSurchargeDistance,
        surcharge_delivery_price: surchargeDeliveryPrice,
        delivery_prepare_time: deliveryTime,
        more: filteredMoreDeliveryPrice,
        // more: moreDeliveryPrice,
      };

      if (orderPrice === "") {
        message.error("최소 주문금액을 입력해주세요.");
      } else if (deliveryPrice === "") {
        message.error("기본 배달팁을 입력해주세요.");
      } else {
        const oResponse = await oProps.oManager.fDoAxios(
          "/store/setDeliveryManage",
          "post",
          "login",
          data
        );
        if (oResponse !== undefined || oResponse !== "") {
          message.success("저장되었습니다.");
        } else {
          message.error("오류가 발생했습니다. 다시 시도해 주세요");
        }
      }
    } catch (err) {
      console.log("err-->", err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //등록화면
  return (
    <Layout sProps={oProps} routeList={layoutHeaderLists.store}>
      <BrowserView>
        <Style>
          <div className="container">
            <div className="title_box">
              <h2>배달 정보</h2>
              <SVG
                src={betaIcon}
                width="40px"
                height="40px"
                style={{ marginTop: "3px", marginLeft: "3px" }}
              />
            </div>
            <div className="contentContainer">
              <div className="inputText">
                <h2>최소 주문 금액</h2>
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    placeholder="최소 주문 금액을 입력해주세요"
                    value={orderPrice}
                    onChange={onChangeOrderPriceValue}
                  />
                  <p
                    className="priceBox"
                    style={{ position: "absolute", right: "23%", bottom: 15 }}
                  >
                    원
                  </p>
                </div>
              </div>
              <div className="inputText">
                <h2>배달 예상 시간</h2>
                <p style={{ color: "#aaa" }}>
                  평균적인 배달 예상시간을 입력해 주세요.
                </p>
                <div className="distanceButton">
                  <button onClick={minusDeliveryTime}>
                    <SVG src={minusIcon} width="30px" height="30px" />
                  </button>
                  <h2>{deliveryTime}분</h2>
                  <button onClick={plusDeliveryTime}>
                    <SVG src={plusIcon} width="30px" height="30px" />
                  </button>
                </div>
              </div>
              <div className="inputText">
                <h2>배달 가능 거리 지정</h2>
                <p style={{ color: "#aaa" }}>
                  고객위치가 설정한 거리를 벗어날 경우, 배달이 불가능한 지역으로
                  안내됩니다.
                </p>
                <div className="distanceButton">
                  <button onClick={minusDistance}>
                    <SVG src={minusIcon} width="30px" height="30px" />
                  </button>
                  <h2>{distance}km</h2>
                  <button onClick={plusDistance}>
                    <SVG src={plusIcon} width="30px" height="30px" />
                  </button>
                </div>
              </div>
              <div className="inputText">
                <h2>기본 배달팁</h2>
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    placeholder="기본 배달팁을 입력해주세요"
                    value={deliveryPrice}
                    onChange={onChangeDeliveryPriceValue}
                  />
                  <p
                    className="priceBox"
                    style={{ position: "absolute", right: "23%", bottom: 15 }}
                  >
                    원
                  </p>
                </div>
              </div>
              <div className="inputText">
                <h2>추가 거리당 할증 배달팁</h2>
                <div className="surchargeWrap">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      options={basicDistance}
                      defaultValue={basicDistance[0]}
                      value={selectBasicDistance}
                      onChange={basicDistanceChange}
                      placeholder={"기본거리"}
                      style={{ width: "30%" }}
                    />
                    <p>를 초과하면</p>
                    <Select
                      options={surchargeDistance}
                      defaultValue={surchargeDistance[0]}
                      value={selectSurchargeDistance}
                      onChange={surchargeDistanceChange}
                      placeholder={"할증단위"}
                      style={{ width: "30%" }}
                    />
                    <p>마다</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "6px",
                    }}
                  >
                    <div style={{ position: "relative", width: "25%" }}>
                      <input
                        type="number"
                        placeholder="추가요금"
                        style={{ width: "100%", padding: "6px" }}
                        value={surchargeDeliveryPrice}
                        onChange={onChangesurchargePriceValue}
                      />
                      <p
                        className="priceBox"
                        style={{
                          position: "absolute",
                          right: "2%",
                          bottom: 7,
                        }}
                      >
                        원
                      </p>
                    </div>
                    <p>이 추가됩니다.</p>
                  </div>
                </div>
              </div>
              <div className="inputText">
                <h2>주문 금액별 배달팁</h2>
                <p style={{ color: "#aaa" }}>
                  주문 금액에 따라 할인된 배달비가 적용됩니다.
                </p>
                {moreDeliveryPrice &&
                  moreDeliveryPrice.map((item, i) => (
                    <div
                      key={i}
                      className="orderPriceDeliveryPrice"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="number"
                          placeholder="주문금액"
                          style={{ width: "70%", marginRight: "6px" }}
                          value={item.more_order_price}
                          onChange={(e) => {
                            let moreDeliveryPriceArr = [...moreDeliveryPrice];
                            moreDeliveryPriceArr[i].more_order_price =
                              e.target.value;
                            setMoreDeliveryPrice(moreDeliveryPriceArr);
                          }}
                        />
                        <p
                          style={{ position: "absolute", right: "34%", top: 7 }}
                        >
                          원
                        </p>
                        <p className="priceBox">이상부터</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="number"
                          placeholder="할인이 적용된 배달팁"
                          style={{ width: "60%", marginRight: "6px" }}
                          value={item.more_delivery_price}
                          onChange={(e) => {
                            let moreDeliveryPriceArr = [...moreDeliveryPrice];
                            moreDeliveryPriceArr[i].more_delivery_price =
                              e.target.value;
                            setMoreDeliveryPrice(moreDeliveryPriceArr);
                          }}
                        />
                        <p
                          style={{ position: "absolute", right: "44%", top: 7 }}
                        >
                          원
                        </p>
                        <p className="priceBox">이 적용됩니다.</p>
                      </div>
                      <button
                        className="removeButton"
                        onClick={() => onRemoveDetailDiv(i)}
                      >
                        삭제
                      </button>
                    </div>
                  ))}
                <button className="plusButton" onClick={onAddDetailDiv}>
                  + 주문 금액별 배달팁 추가하기
                </button>
              </div>
              <div className="submitButtonWrap">
                <button className="submitButton" onClick={insertDelivery}>
                  등록하기
                </button>
              </div>
            </div>
          </div>
        </Style>
      </BrowserView>
      <MobileView>
        <Mstyle>
          <div className="container">
            <div className="title_box">
              <h2>배달 정보</h2>
              <SVG
                src={betaIcon}
                width="40px"
                height="40px"
                style={{ marginTop: "3px", marginLeft: "3px" }}
              />
            </div>
            <div className="contentContainer">
              <div className="inputText">
                <h2>최소 주문 금액</h2>
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    placeholder="최소 주문 금액을 입력해주세요"
                    value={orderPrice}
                    onChange={onChangeOrderPriceValue}
                  />
                  <p
                    className="priceBox"
                    style={{ position: "absolute", right: "23%", bottom: 15 }}
                  >
                    원
                  </p>
                </div>
              </div>
              <div className="inputText">
                <h2>배달 예상 시간</h2>
                <p style={{ color: "#aaa" }}>
                  평균적인 배달 예상시간을 입력해 주세요.
                </p>
                <div className="distanceButton">
                  <button onClick={minusDeliveryTime}>
                    <SVG src={minusIcon} width="30px" height="30px" />
                  </button>
                  <h2>{deliveryTime}분</h2>
                  <button onClick={plusDeliveryTime}>
                    <SVG src={plusIcon} width="30px" height="30px" />
                  </button>
                </div>
              </div>
              <div className="inputText">
                <h2>배달 가능 거리 지정</h2>
                <p style={{ color: "#aaa" }}>
                  고객위치가 설정한 거리를 벗어날 경우, 배달이 불가능한 지역으로
                  안내됩니다.
                </p>
                <div className="distanceButton">
                  <button onClick={minusDistance}>
                    <SVG src={minusIcon} width="30px" height="30px" />
                  </button>
                  <h2>{distance}km</h2>
                  <button onClick={plusDistance}>
                    <SVG src={plusIcon} width="30px" height="30px" />
                  </button>
                </div>
              </div>
              <div className="inputText">
                <h2>기본 배달팁</h2>
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    placeholder="기본 배달팁을 입력해주세요"
                    value={deliveryPrice}
                    onChange={onChangeDeliveryPriceValue}
                  />
                  <p
                    className="priceBox"
                    style={{ position: "absolute", right: "23%", bottom: 15 }}
                  >
                    원
                  </p>
                </div>
              </div>
              <div className="inputText">
                <h2>추가 거리당 할증 배달팁</h2>
                <div className="surchargeWrap">
                  <div className="surchargeContent">
                    <Select
                      options={basicDistance}
                      defaultValue={basicDistance[0]}
                      value={selectBasicDistance}
                      onChange={basicDistanceChange}
                      placeholder={"기본거리"}
                      style={{ width: "50%" }}
                    />
                    <p>를 초과하면</p>
                  </div>
                  <div className="surchargeContent">
                    <Select
                      options={surchargeDistance}
                      defaultValue={surchargeDistance[0]}
                      value={selectSurchargeDistance}
                      onChange={surchargeDistanceChange}
                      placeholder={"할증단위"}
                      style={{ width: "50%" }}
                    />
                    <p>마다</p>
                  </div>

                  <div
                    className="surchargeContent"
                    style={{ position: "relative" }}
                  >
                    <input
                      type="number"
                      placeholder="추가요금"
                      style={{ width: "50%", padding: "10px" }}
                      value={surchargeDeliveryPrice}
                      onChange={onChangesurchargePriceValue}
                    />
                    <p
                      className="priceBox"
                      style={{
                        position: "absolute",
                        right: "50%",
                        bottom: 12,
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      원
                    </p>
                    <p>이 추가됩니다.</p>
                  </div>
                </div>
              </div>
              <div className="inputText">
                <h2>주문 금액별 배달팁</h2>
                <p style={{ color: "#aaa" }}>
                  주문 금액에 따라 할인된 배달비가 적용됩니다.
                </p>
                {moreDeliveryPrice &&
                  moreDeliveryPrice.map((item, i) => (
                    <div
                      key={i}
                      className="orderPriceDeliveryPrice"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="number"
                          placeholder="주문금액"
                          style={{ width: "55%", marginRight: "4px" }}
                          value={item.more_order_price}
                          onChange={(e) => {
                            let moreDeliveryPriceArr = [...moreDeliveryPrice];
                            moreDeliveryPriceArr[i].more_order_price =
                              e.target.value;
                            setMoreDeliveryPrice(moreDeliveryPriceArr);
                          }}
                        />
                        <p
                          style={{ position: "absolute", right: "48%", top: 5 }}
                        >
                          원
                        </p>
                        <p className="priceBox">이상부터</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="number"
                          placeholder="배달팁"
                          style={{ width: "40%", marginRight: "4px" }}
                          value={item.more_delivery_price}
                          onChange={(e) => {
                            let moreDeliveryPriceArr = [...moreDeliveryPrice];
                            moreDeliveryPriceArr[i].more_delivery_price =
                              e.target.value;
                            setMoreDeliveryPrice(moreDeliveryPriceArr);
                          }}
                        />
                        <p
                          style={{ position: "absolute", right: "62%", top: 5 }}
                        >
                          원
                        </p>
                        <p className="priceBox">이 적용됩니다.</p>
                      </div>
                      <button
                        className="removeButton"
                        onClick={() => onRemoveDetailDiv(i)}
                      >
                        삭제
                      </button>
                    </div>
                  ))}
                <button className="plusButton" onClick={onAddDetailDiv}>
                  + 주문 금액별 배달팁 추가하기
                </button>
              </div>
              <div className="submitButtonWrap">
                <button className="submitButton" onClick={insertDelivery}>
                  등록하기
                </button>
              </div>
            </div>
          </div>
        </Mstyle>
      </MobileView>
    </Layout>
  );
};

export default Delivery;

const Style = styled.div`
  position: relative;
  width: 42vw;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
  }

  .container {
    padding: 32px;
    margin-bottom: 1px;
    background-color: #fff;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 42vw;
  }
  /* 공통 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .title_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h2 {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .inputText {
    margin-bottom: 24px;

    h2 {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      line-height: 21px;
    }
    input {
      width: 80%;
      margin-top: 2%;
      padding: 12px;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
    }
  }

  .distanceButton {
    display: flex;
    align-items: center;
    margin-top: 16px;

    h2 {
      margin: 0 16px;
      font-size: 22px;
      font-weight: 700;
    }
    button {
      background: none;
      outline: none;
      border: none;
    }
  }

  .surchargeWrap {
    margin-top: 16px;

    p {
      margin: 0 12px 0 6px;
    }
  }

  .submitButton {
    width: 100%;
    padding: 16px 0;
    background: #617be3;
    color: #fff;
    margin-top: 30px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 0.9rem;
    font-weight: 700;
  }

  /* 주문 금액별 배달팁 */
  .orderPriceDeliveryPrice {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;

    input {
      margin: 0;
      padding: 6px;
    }
  }
  .plusButton {
    margin-top: 12px;
    background: none;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color: #617be3;
  }
  .removeButton {
    background: red;
    padding: 4px 12px;
    border: none;
    outline: none;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
`;

const Mstyle = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
  }

  .container {
    padding: 5vw;
    margin-bottom: 1px;
    background-color: #fff;
    border-radius: 0;
    width: 100vw;
  }
  /* 공통 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .title_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h2 {
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .inputText {
    margin-bottom: 24px;

    h2 {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      line-height: 21px;
    }
    input {
      width: 80%;
      margin-top: 2%;
      padding: 12px;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
    }
  }

  .distanceButton {
    display: flex;
    align-items: center;
    margin-top: 16px;

    h2 {
      margin: 0 16px;
      font-size: 22px;
      font-weight: 700;
    }
    button {
      background: none;
      outline: none;
      border: none;
    }
  }

  .surchargeWrap {
    margin-top: 16px;

    .surchargeContent {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0 12px 0 6px;
    }
  }

  /* 주문 금액별 배달팁 */
  .orderPriceDeliveryPrice {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;

    input {
      margin: 0;
      padding: 4px;
      font-size: 14px;
    }
  }
  .plusButton {
    margin-top: 12px;
    background: none;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color: #617be3;
  }
  .removeButton {
    border: none;
    background: none;
    border-bottom: 1px solid red;
    white-space: nowrap;
    outline: none;
    color: red;
    font-size: 12px;
    font-weight: 500;
  }

  .submitButton {
    width: 100%;
    padding: 16px 0;
    background: #617be3;
    color: #fff;
    margin-top: 30px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 0.9rem;
    font-weight: 700;
  }
`;
