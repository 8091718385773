import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import '../../../assets/css/font.css';

import { WebRoute } from '../../../navigation/WebRoutes';

// svg
import SVG from 'react-inlinesvg';
import logo from "../../../assets/svg/mainPage/throoCeoLogo.svg";
import closeIcon from "../../../assets/svg/mainPage/closeIcon.svg";
import hIcon01 from "../../../assets/img/ceoscreen/header/hIcon01.png";
import hIcon02 from "../../../assets/img/ceoscreen/header/hIcon02.png";
import hIcon03 from "../../../assets/img/ceoscreen/header/hIcon03.png";
import hIcon04 from "../../../assets/img/ceoscreen/header/hIcon04.png";
import hIcon06 from "../../../assets/img/ceoscreen/header/hIcon06.png";

const HeaderSliderbar = ({ isOpen, toggle }) => {

   const navigate = useNavigate();

   // 링크
   const openStoremanage = () => {
      let sDomainUrl = "https://ceo.throo.co.kr";
      if (process.env !== undefined && process.env.REACT_APP_CEO_WEBURL !== undefined) {
         sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
      }
      window.open(sDomainUrl + '/selfmanage/signin', '_blank');
   }
   const throoHomepage = () => {
      let sDomainUrl = "https://throo.co.kr";
      if (process.env !== undefined && process.env.REACT_APP_THROO_WEBURL !== undefined) {
         sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
      }
      window.open(sDomainUrl, '_blank');
   }
   const moveToPath = async (sIndex) => {
      if (sIndex === "HOME") {
         navigate(WebRoute.HOME);
      }
   }

   return (
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
         <div className="container">
            <Icon onClick={toggle}>
               <div className="closeIcon">
                  <SVG src={logo} className="logo" width="154px" height="24px" title="스루사장님창구로고" onClick={() => moveToPath("HOME")} />
                  <SVG src={closeIcon} className="icon" width="22px" height="22px" title="닫기 버튼" />
               </div>
            </Icon>
            <SidebarText>
               <ul>
                  <li onClick={openStoremanage}><img src={hIcon01} alt="매장관리이미지" width="46px" height="46px" />매장관리</li>
                  <li><Link to={WebRoute.DOWNLOAD}><img src={hIcon02} alt="포스설치이미지" width="46px" height="46px" />포스설치</Link></li>
                  <li><Link to={WebRoute.SERVICE}><img src={hIcon03} alt="고객센터이미지" width="46px" height="46px" />고객센터</Link></li>
                  <li><Link to={WebRoute.LANDING}><img src={hIcon04} alt="스루소개이미지" width="46px" height="46px" />스루소개</Link></li>
                  <li><Link to={{ pathname: '/ceotip', page_id: 'mob_sw', search: 'mob_sw' }}><img src={hIcon06} alt="스루소개이미지" width="46px" height="46px" />이용요금</Link></li>
               </ul>
            </SidebarText>
         </div>
      </SidebarContainer>
   )
}

export default HeaderSliderbar;

export const SidebarContainer = styled.aside`
    * {
        font-family: 'Pretendard-Bold';
        font-weight: 700;
    }

    position: fixed;
    z-index: 99999999;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    background: #fff;
    transition: 0.5s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    right: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    color: #333;

    .container {
        min-width: 100%;
        padding: 0 5vw;
    }
`;


export const Icon = styled.div`
    .closeIcon {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
    }
`;
export const SidebarText = styled.div`
    a {
        text-decoration: none;
        color: #333;
    }
    ul,li {
        list-style: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }
    ul {
        padding-bottom: 30px;
    }
    li {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 26px;
        font-weight: 700;
        padding: 18px 0;
        color: #333333;

        img {
            margin-right: 10px;
        }
    }
    li:nth-of-type(1) {
        padding-top: 18px;
        margin-top: 10px;
    }
    li:hover {
        background-color: rgba(196, 196, 196, .5);
    }
    p {
        margin: 0;
    }
`;
