import React, { useEffect } from 'react';
import styled from 'styled-components';
import '../../../../assets/fonts/font.css'
import { Link } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';

// img
import mainBanner from '../../../../assets/img/ceoscreen/landing/walkThroo/landingBanner.jpg';
import driveThroo from '../../../../assets/img/ceoscreen/landing/walkThroo/driveThroo.png';
import walkThroo from '../../../../assets/img/ceoscreen/landing/walkThroo/walkThroo.png';
import benefit01 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit01.png';
import benefit02 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit02.png';
import benefit03 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit03.png';
import benefit04 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit04.png';
import benefit05 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit05.png';
import benefit06 from '../../../../assets/img/ceoscreen/landing/walkThroo/benefit06.png';
import orderGuide01 from '../../../../assets/img/ceoscreen/landing/walkThroo/orderGuide01.png';
import orderGuide02 from '../../../../assets/img/ceoscreen/landing/walkThroo/orderGuide02.png';
import orderGuide03 from '../../../../assets/img/ceoscreen/landing/walkThroo/orderGuide03.png';
import rightArrow from '../../../../assets/img/ceoscreen/landing/rightArrow.png';
import now01 from '../../../../assets/img/ceoscreen/landing/now01.png';
import now02 from '../../../../assets/img/ceoscreen/landing/now02.png';
import only01 from '../../../../assets/img/ceoscreen/landing/only01.png';
import only02 from '../../../../assets/img/ceoscreen/landing/only02.png'
import discount01 from '../../../../assets/img/ceoscreen/landing/discount01.png';
import discount02 from '../../../../assets/img/ceoscreen/landing/discount02.png';
import storeTop from '../../../../assets/img/ceoscreen/landing/walkThroo/storeTop.png';
import storeBottom from '../../../../assets/img/ceoscreen/landing/walkThroo/storeBottom.png';
import partner from '../../../../assets/img/ceoscreen/landing/walkThroo/partner.png';
import contactBanner from '../../../../assets/img/ceoscreen/landing/walkThroo/openEvent.png';
import moreBtn from '../../../../assets/img/ceoscreen/landing/walkThroo/openEventButton.png';

const LandingPage = (oProps) => {

    useEffect(() => {
        window.scrollSection();
        window.setScreenSize();
        window.scrollPoint();
        window.sliderImage();
        window.tabMenuAction();
    }, []);

    return (
        <D_CeoLanding>
            <div className="landing">
                <div className="banner">
                    <div className="container">
                        <div className="hide fade_up">
                            <h1>스루, 새로운 고객을<br />만나는 방법</h1>
                        </div>
                        <div className="hide fade_up">
                            <p>스루는 일반적인 비대면 픽업 플랫폼을 넘어,<br />보다 진화한 스마트한 픽업 서비스로<br />다양한 고객과 매장을 연결합니다.</p>
                        </div>
                        <div className="hide fade_up">
                            <button><Link to={WebRoute.JOINFORM}>스루 입점신청</Link></button>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <h1>워크스루부터<br />드라이브스루까지</h1>
                        <div className="hide fade_in">
                            <div className="orderType">
                                <div className="walkThroo">
                                    <img src={walkThroo} alt="워크스루" />
                                    <h3>바쁠 때는 워크 스루!</h3>
                                    <h4>결제까지 완료된 포장 주문,<br />사장님은 매장에서 전달만 해주세요.</h4>
                                </div>
                                <div className="driveThroo">
                                    <img src={driveThroo} alt="드라이브스루" />
                                    <h3>한가할 때는 드라이브스루</h3>
                                    <h4>매장 앞 고객의 차까지만 전달해 주시면 됩니다!<br />그동안 놓치고 있었던 운전자 고객을 만날 수 있어요.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <h1>사장님 매장에 딱! 맞는<br />효과를 기대할 수 있어요</h1>
                        <div className="hide fade_down">
                            <div className="benefit">
                                <div className="benefitContent">
                                    <img src={benefit01} alt="스루기대효과" />
                                    <h3>더 많은 고객 확보</h3>
                                    <h4>방문포장 고객 뿐만아니라<br />새로운 운전자 고객을 확보할 수 있어요.</h4>
                                </div>
                                <div className="benefitContent">
                                    <img src={benefit02} alt="스루기대효과" />
                                    <h3>인건비 절감</h3>
                                    <h4>앱으로 주문, 결제 완료!<br />고객 응대시간을 단축할 수 있어요.</h4>
                                </div>
                                <div className="benefitContent">
                                    <img src={benefit03} alt="스루기대효과" />
                                    <h3>편리한 매장 관리</h3>
                                    <h4>터치한번으로 메뉴설정,재고관리,<br />매출통계 확인이 가능해요.</h4>
                                </div>
                                <div className="benefitContent">
                                    <img src={benefit04} alt="스루기대효과" />
                                    <h3>단골고객 증가</h3>
                                    <h4>스루 앱 내 스탬프, 쿠폰, 할인 기능을<br />통해 단골고객이 증가해요.</h4>
                                </div>
                                <div className="benefitContent">
                                    <img src={benefit05} alt="스루기대효과" />
                                    <h3>고객 만족도 상승</h3>
                                    <h4>실시간 위치 확인으로 사장님도<br />고객도 기다림없이 픽업이 가능해요.</h4>
                                </div>
                                <div className="benefitContent">
                                    <img src={benefit06} alt="스루기대효과" />
                                    <h3>매장광고효과</h3>
                                    <h4>다양한 마케팅 툴을 활용해 더 많은 고객에게<br />우리 매장을 홍보할 수 있도록 지원해 드려요.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="throoStore">
                    <div className="textAnimation">
                        <div className="textAnimation__container">
                            <ul className="textAnimation__container__list">
                                <li className="textAnimation__container__list__item">음식점도</li>
                                <li className="textAnimation__container__list__item">카페도</li>
                                <li className="textAnimation__container__list__item">편의점도</li>
                                <li className="textAnimation__container__list__item">꽃집도</li>
                                <li className="textAnimation__container__list__item">밀키트도</li>
                            </ul>
                        </div>
                        <p>포장만 가능하다면</p>
                        <p>오늘부터 드라이브스루!</p>
                    </div>
                </div>
                <div className="content">
                    <div className="trooGuide">
                        <h1>쉽고 편한 스루 이용방법!</h1>
                        <div className="orderGuide">
                            <div className="container">
                                <div className="guide">
                                    <img src={orderGuide01} alt="고객이 차에서 주문하는 이미지" width="100%" />
                                    <div className="eText hide fade_left">
                                        <h2>고객이 미리 <span>앱으로 주문 결제 완료!</span><br />포스나 앱으로 주문 확인이 가능해요.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="orderGuide">
                            <div className="container">
                                <div className="guide">
                                    <img src={orderGuide02} alt="고객도착 알람 이미지" width="100%" />
                                    <div className="eText hide fade_right">
                                        <h2><span>고객 위치 실시간 확인 가능!</span><br />매장 근처에 도착하면 알람이 울려요.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="orderGuide">
                            <div className="container">
                                <div className="guide">
                                    <img src={orderGuide03} alt="상품을 차로 전달하는 이미지" />
                                    <div className="eText hide  fade_left">
                                        <h2><span>드라이브스루 고객은 픽업존</span>에서,<br />워크스루 고객은 매장에서<br />상품을 전달해주세요.</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container">
                        <h1 className="hide fade_in">사장님을 위한 스루만의 특별한 기능</h1>
                        <div className="tab-container">
                            <div className="tab-menu">
                                <ul>
                                    <li><a href="#" className="tab-a active-a" data-id="tab1">오직 스루에서만! 스루온리</a></li>
                                    <li><a href="#" className="tab-a" data-id="tab2">내 근처에서 바로! 스루나우</a></li>
                                    <li><a href="#" className="tab-a" data-id="tab3">단골 고객 사로잡기! 할인</a></li>
                                </ul>
                            </div>
                            <div className="tab tab-active" data-id="tab1">
                                <div className="throoSpecial">
                                    <div className="left">
                                        <h2><span>스루에서만 만나볼 수 있는<br />특별한 메뉴</span></h2>
                                        <p>스루 ONLY 상품은 앱 내 상품 상단에 우선 노출됩니다.<br />스루에서만 판매하는 상품을 만들어보세요</p>
                                        <Link to={{ pathname: '/ceotip', page_id: 'only', search: 'only' }}>
                                            <div className="btn">더알아보기 <img src={rightArrow} alt="더보기 버튼" width="18px" height="18px" /></div>
                                        </Link>
                                        <img src={only01} alt="스루온니 설정 이미지" width="100%" style={{ maxHeight: "226px", maxWidth: "480px" }} />
                                    </div>
                                    <div className="right">
                                        <img src={only02} alt="스루온니 앱 이미지" width="100%" style={{ maxHeight: "821px", maxWidth: "404px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="tab " data-id="tab2">
                                <div className="throoSpecial">
                                    <div className="left">
                                        <h2>준비시간을 줄일수록<br /><span>주문은 UP!</span></h2>
                                        <p>여유로운 시간에 준비 시간을 줄여 고객을 사로잡아보세요.<br />준비 시간을 짧게 잡을수록 더 많은 고객에게 스루 NOW가 표시됩니다.</p>
                                        <Link to={{ pathname: '/ceotip', page_id: 'now', search: 'now' }}>
                                            <div className="btn">더알아보기 <img src={rightArrow} alt="더보기 버튼" width="18px" height="18px" /></div>
                                        </Link>
                                        <img src={now01} alt="스루온니 설정 이미지" width="100%" style={{ maxHeight: "246px", maxWidth: "647px" }} />
                                    </div>
                                    <div className="right">
                                        <img src={now02} alt="스루온니 앱 이미지" width="100%" style={{ maxHeight: "821px", maxWidth: "404px" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="tab " data-id="tab3">
                                <div className="throoSpecial">
                                    <div className="left">
                                        <h2>할인 기능을 통해<br /><span>우리 매장에 단골손님 UP!</span></h2>
                                        <p>마감 직전, 특별행사 등 원하는 시간에 할인 기능을 이용해 보세요.<br />편리하게 이용 가능한 할인 기능을 통해 단골 고객을 사로잡아보세요.</p>
                                        <img src={discount01} alt="스루온니 설정 이미지" width="100%" style={{ maxHeight: "422px", maxWidth: "480px" }} />
                                    </div>
                                    <div className="right">
                                        <img src={discount02} alt="스루온니 앱 이미지" width="100%" style={{ maxHeight: "821px", maxWidth: "404px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="partner">
                        <h1 className="hide fade_in">전국 많은 매장들이<br />스루를 이용하고 있어요.</h1>
                        <div className="logo-slider-top">
                            <div>
                                <img src={storeTop} alt="스루 가입 매장" />
                            </div>
                            <div>
                                <img src={storeTop} alt="스루 가입 매장" />
                            </div>
                        </div>
                        <div className="logo-slider-bottoom">
                            <div>
                                <img src={storeBottom} alt="스루 가입 매장" />
                            </div>
                            <div>
                                <img src={storeBottom} alt="스루 가입 매장" />
                            </div>
                        </div>
                        <div className="partnerReview hide fade_up">
                            <div className="container">
                                <img src={partner} alt="스루사장님후기" width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <img src={contactBanner} alt="정식서비스 오픈 이벤트" width="100%" />
                    <button>
                        <Link to={{ pathname: '/ceotip', page_id: 'throoCeoPromotion', search: 'throoCeoPromotion' }}>
                            <img src={moreBtn} alt="오픈 이벤트 자세히보기 버튼" width="100%"/>
                        </Link>
                    </button>
                </div>
                <div className="content">
                    <div className="contact hide fade_in" style={{ transitionDuration: '.5s' }}>
                        <h2><span>스루,</span>지금 바로 시작해 보세요!</h2>
                        <button><Link to={WebRoute.JOINFORM}><p>스루 입점신청</p></Link></button>
                    </div>
                </div>
            </div>
        </D_CeoLanding >
    )
}

export default LandingPage;

export const D_CeoLanding = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Pretendard';
        color: #191F28;
    }
    .landing {
        width: 100%;
    }
    .container {
        width: 1120px;
        margin: 0 auto;
    }
    a {
        color: #fff;
    }

    /* 텍스트 애니메이션 */
    .in_view {
        opacity: 1;
        transition: all 1s ease-in-out;
    }
    .fade_in {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
    .fade_up {
        opacity: 0;
        transform: translate3d(0, 30%, 0);
    }
    .fade_down {
        opacity: 0;
        transform: translate3d(0, -5%, 0);
    }
    .fade_left {
        opacity: 0;
        transform: translate3d(15%, 0, 0);
    }
    .fade_right {
        opacity: 0;
        transform: translate3d(-15%, 0, 0);
    }
    .fade_sparkle {
        opacity: 0;
       
    }
    .in_view.fade_in,
    .in_view.fade_up,
    .in_view.fade_down,
    .in_view.fade_left,
    .in_view.fade_right {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    .in_view.fade_sparkle {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-animation:blink 1s ease-in-out 3 alternate; 
        -moz-animation:blink 1s ease-in-out 3 alternate; 
        animation:blink 1s ease-in-out 3 alternate;
    }
    @-webkit-keyframes blink{ 0% {opacity:0;} 100% {opacity:1;} }
    @-moz-keyframes blink{ 0% {opacity:0;} 100% {opacity:1;} } 
    @keyframes blink{ 0% {opacity:0;} 100% {opacity:1;} }

    /* 공통 css */
    .content {
        text-align: center;

        h1 {
            font-size: 60px;
            font-weight: 700;
            line-height: 82px;
        }
        h2 {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
        }
        h3 {
            font-size: 25px;
            font-weight: 600;
            line-height: 25px;
        }
        h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
        }
    }

    /* button */
    button {
        padding: 18px 42px;
        font-size: 21px;
        font-weight: 600;
        line-height: 21px;
        border-radius: 6px;
        background-color: #1a7cff;
        color: #fff;
        border: none;
        outline: none;
        cursor: pointer;
    }

    /* banner */
    .banner {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 242px 0 206px 0;
        text-align: center;
        min-height: 760px;
        height: auto;
        background-image: url(${mainBanner});
        background-size: cover ;
        background-repeat: no-repeat;
    
        h1 {
            font-size: 65px;
            font-weight: 700;
            line-height: 84px;
        }
        p {
            padding: 34px 0 48px 0;
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
        }
        button {
            transition-duration: 2s
        }
    }

    
    /* orderType */
    .content:nth-child(2) {
        padding: 180px 0; 

        h3 {
            margin-bottom: 25px;
        }
    }
    .orderType {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            margin: 122px 0 36px 0;
            width: 534px;
            height: 320px;
            border-radius: 16px;
            background: #FAFAFB;
        }
    }

    /* benefit */
    .content:nth-child(3) {
        padding: 100px 0 180px 0; 
        background: #F9FAFB;
    }
    .benefit {
        margin-top: 100px;
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 33%;
        justify-content: space-between;
        align-items: center;
        
        h3 {
            margin-bottom: 20px;
        }
        img {
            margin-bottom: 40px;
            max-width: 120px;
            max-height: 120px;
        }
        .benefitContent {
            width: 33%;
            margin-bottom: 184px;
        }
        .benefitContent:nth-child(4),
        .benefitContent:nth-child(5),
        .benefitContent:nth-child(6){
            margin-bottom: 0px;
        }
    }

    /* throoStore */
    .throoStore {
        background-color: #171b26;
        min-height: 536px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
   
        p {
            margin: 0;
            padding: 0;
            font-size: 54px;
            font-weight: 600;
            color: #fff;
        }
    }
    .textAnimation {
        position: absolute;
        overflow: hidden;
        line-height: 72px;
        color: #ecf0f1;
    }

    .textAnimation__container {
        font-weight: 600;
        overflow: hidden;
        height: 72px;
    }

    .textAnimation__container__text {
        display: inline;
        float: left;
        margin: 0;
    }

    .textAnimation__container__list {
        margin-top: 0;
        line-height: 72px;
        -webkit-animation-name: change;
        -webkit-animation-duration: 10s;
        -webkit-animation-iteration-count: infinite;
        animation-name: change;
        animation-duration: 7s;
        animation-iteration-count: infinite;
    }

    .textAnimation__container__list__item {
        line-height: 72px;
        margin: 0;
        padding-bottom: 7px;
        font-size: 54px;
        font-weight: 700;
        color: #00E4BB;
    }

    @keyframes opacity {

        0%,
        100% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }
    }

    @keyframes change {
        0%,90%{
            transform: translate3d(0, 0, 0); 
        }

        10%,80% {
            transform: translate3d(0, -20%, 0);
        }

        20%,70% {
            transform: translate3d(0, -40%, 0);
        }

        30%,60% {
            transform: translate3d(0, -60%, 0);
        }

        40%,50% {
            transform: translate3d(0, -80%, 0);
        }
    }

    /* trooGuide */
    .trooGuide h1 {
        padding: 120px 0 60px 0;s 
    }
    .orderGuide:nth-child(even) {
        background: #F9FAFB;
    }
    .orderGuide:nth-child(odd) .guide {
        flex-direction: row;

        h2 {
            margin-left: 60px;
            text-align: left;
        }
    }
    .orderGuide {
        padding: 102px 0 112px 0;
    }
    .guide {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row-reverse;

        img {
            max-width: 436px;
            max-height: 338px;
        }
        h2 {
            margin-right: 60px;
            font-size: 38px;
            font-weight: 600;
            line-height: 52px;
            text-align: right;

            span {
                color: #1a7cff;
            }
        }
    }
    
    /* throoSpecial */
    .content:nth-child(6) {
        background-color: #212634;
        text-align: center;
        padding-bottom: 100px;

        h1 {
            padding: 150px 0 80px 0;
            color: #fff;
        }
        img {
            margin-top: 80px;
        }
    }
    .throoSpecial {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
        .left {
            display: flex;
            flex-direction: column;
        }

        h2 {
            padding: 120px 0 0 0;
            font-size: 38px;
            line-height: 52px;
            color: #fff;

            span {
                color: #00E4BB;
            }
        }
        p {
           margin: 30px 0;
           font-size: 23px;
           font-weight: 600;
           color: #fff;
           line-height: 36px;
        }
        .btn {
            display: flex;
            align-items: center;
            margin-bottom: 143px;
            font-size: 23px;
            font-weight: 600;
            text-align: left;
            color: #00E4BB;
            background: none;
            border: none;
            outline: none;

            img {
                margin:0;
            }
        }
    }
    /* tabMenu */
    .tab-menu ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tab-menu ul li {
        list-style-type: none;
        display: inline-block;
    }

    .tab-menu ul li a {
        text-decoration: none;
        background-color: #212634;
        border: 2px dashed #00E4BB;
        padding: 22px 34px;
        border-radius: 50px;
        color: #00E4BB;
        font-size: 23px;
        font-weight: 900;
    }

    .tab-menu ul li a.active-a {
        background-color: #00E4BB ;
        color: #000;
    }

    .tab {
        display: none;
    }

    .tab-active {
        display: block;
    }

    
    /* partner */
    .content:nth-child(7) {
        padding: 180px 0;
        background-color: #000;

        h1 {
            margin-bottom: 120px;
            color: #fff;
        }
    }
    .logo-slider-top, .logo-slider-bottoom {
        overflow: hidden;
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
        position: relative;
        display: flex;
    }
    .logo-slider-top div {
        display: flex;
        position: relative;
        animation: marquee 50s linear infinite;
        justify-content: space-around;
    }
    .logo-slider-bottoom div {
        display: flex;
        position: relative;
        animation: marquee 50s linear infinite;
        justify-content: space-around;
        animation-direction: reverse;
    }
    .logo-slider-top img, .logo-slider-bottoom img {
        display: block;
        margin: 0 10px;
        width: 2170px;
        height: 90px;
    }
    .partnerReview img {
        margin-top: 104px;
    }

    @keyframes marquee {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-100%);
        }
    } 

    /* main-contact */
    .content:nth-child(8) {
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;

        button {
            width: 15%;
            margin:0 auto;
            position: absolute;
            bottom: 25%;
            left: 0;
            right: 0;
            background: none;
            outline: none;
            border: none;
        }
    }
    
    /* contact */
    .content:nth-child(9) {
        padding: 120px 0 86px 0;
        background: #212634;
        
    }
    .contact {
        h2 {
            font-size: 32px;
            font-weight: 600;
            line-height: 40px;
            color: #fff;

            span {
                color: #1A7CFF;
                font-weight: 700;
            }
        }

        button {
            margin-top: 40px;
            padding: 23px 49px;
            background: #1a7cff;
            border: none;
            border-radius: 10px;

            p {
                font-size: 33px;
                font-weight: 600;
                line-height: 36px;
                color: #fff;
            }
        }
    }


`