import React, { useState, useEffect, useRef } from 'react';
import Loader from "react-loader-spinner";
import "antd/dist/antd.css";
import styled from 'styled-components';

import {
    ModalChargedComamercialPoint
} from '../../../modal/commercial/pc/CommercialModal';
import {
    ModalChargedComamercialPoint as ModalChargedComamercialPointMobile
} from '../../../modal/commercial/mobile/CommercialModal';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

const List = ({ iProps, iPoint, iPointWon, fnOtherPage, fnRefreshData, aPoint, aPointWon }) => {
    const [isLoading, setLoading] = useState(false);
    const [sModal, setSModal] = useState(false);

    const [myPoint, setMyPoint] = useState(0);
    const [myPointWon, setMyPointWon] = useState("0");
    const [myPointCharged, setMyPointCharged] = useState(0);
    const [myPointChargedWon, setMyPointChargedWon] = useState("0");
    const [sumAmountWon, setSumAmountWon] = useState("");

    const modalClose = async () => {
        setSModal(false);
    }

    const priceToString = async (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const goToMyPage = async () => {
        if (fnOtherPage !== undefined && typeof fnOtherPage === "function") {
            await fnOtherPage();
        }
    }

    const openChargePoint = async () => {
        let tempSum = parseInt(myPointCharged) + 11000;
        tempSum = await priceToString(tempSum);
        setSumAmountWon(tempSum);
        setSModal(true);
    }

    const chargedPoint = async () => {
        if (fnRefreshData !== undefined && typeof fnRefreshData === "function") {
            setSModal(false);
            await fnRefreshData();
        }
    }

    const asyncData = async () => {
        setLoading(true);
        setMyPoint(iPoint);
        setMyPointWon(iPointWon);
        setMyPointCharged(aPoint);
        setMyPointChargedWon(aPointWon);
        setLoading(false);
    }

    useEffect(() => {
        asyncData();
    }, []);

    return (
        <>
            <BrowserView>
                {isLoading ?
                    <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                    </div>
                    :
                    <div className="commercial_container1">
                        <div className="title_box">
                            <p>광고 계정 정보</p>
                        </div>
                        <div className="subTitle">
                            <p>광고주명 : <span style={{ fontWeight: 600, fontSize: "0.9rem", color: "#3B3B46" }}>{iProps.UserConfigReducer.StoreOwner}</span></p>
                            <div className='pointMore'>
                                <button onClick={() => goToMyPage()}>내역보기</button>
                            </div>
                        </div>
                        <div className="subTitle">
                            <p>무상 포인트 : <span style={{ fontWeight: 600, fontSize: "0.9rem", color: "#001E62" }}>{myPointWon} 원 </span></p>
                        </div>

                        <div className='box_separate'>
                            <div className='left_box'>
                                <p>유상 포인트 : <span style={{ fontWeight: 600, fontSize: "0.9rem", color: "#6490E7" }}>{myPointChargedWon} 원 </span></p>
                            </div>
                            <div className='right_box'>
                                <div className="insert_box" onClick={() => openChargePoint()}>
                                    <p>광고 포인트 충전</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {sModal &&
                    <ModalChargedComamercialPoint
                        sProps={iProps}
                        iPoint={myPointCharged}
                        iPointWon={myPointChargedWon}
                        iAmountWon={sumAmountWon}
                        fnClose={() => modalClose()}
                        fnChargedPoint={(sIndex) => chargedPoint(sIndex)}
                    />
                }
            </BrowserView>
            <MobileView>
                <Mstyle>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "10vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={40} width={40} timeout={9000000} />
                        </div>
                        :
                        <div className="totalPage" style={{ marginBottom: "2%" }}>
                            <div className="menuContent">
                                <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf" }}>
                                    <div className="title">
                                        <h3>광고 계정 정보</h3>
                                    </div>
                                </div>
                                <div className='content_detail'>
                                    <div className="title">
                                        <p>광고주명 : </p>
                                    </div>
                                    <div className="data">
                                        <p>{iProps.UserConfigReducer.StoreOwner}</p>
                                    </div>
                                    <div className="box" onClick={() => goToMyPage()}>
                                        <p>내역보기</p>
                                    </div>
                                </div>
                                <div className='content_detail'>
                                    <div className="title">
                                        <p>무상 포인트 : </p>
                                    </div>
                                    <div className="data">
                                        <p>{myPointWon} 원 </p>
                                    </div>
                                </div>
                                <div className='content_detail'>
                                    <div className="title">
                                        <p>유상 포인트 : </p>
                                    </div>
                                    <div className="data">
                                        <p>{myPointChargedWon} 원 </p>
                                    </div>
                                    <div className="charge_box" onClick={() => openChargePoint()}>
                                        <p>광고 포인트 충전</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {sModal && !isMobile ?
                        <ModalChargedComamercialPoint
                            sProps={iProps}
                            iPoint={myPointCharged}
                            iPointWon={myPointChargedWon}
                            iAmountWon={sumAmountWon}
                            fnClose={() => modalClose()}
                            fnChargedPoint={(sIndex) => chargedPoint(sIndex)}
                        /> : sModal &&
                        <ModalChargedComamercialPointMobile
                            sProps={iProps}
                            iPoint={myPointCharged}
                            iPointWon={myPointChargedWon}
                            iAmountWon={sumAmountWon}
                            fnClose={() => modalClose()}
                            fnChargedPoint={(sIndex) => chargedPoint(sIndex)}
                        />
                    }
                </Mstyle>

            </MobileView>
        </>
    )
}

export default List;

const Mstyle = styled.div`
    .totalPage {
        width: 100%;
        background-color: #fff;

        .menuContent {
            padding-bottom:5%;

            .menu {
                display: flex;
                align-items: center;
                padding: 5% 5% 5% 5%;

                .title {
                    display: flex;
                    height: 100%;
                    width: 30%;
                    align-items: center;

                    h3 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .content_detail {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 7vh;
                margin-left: 5%;

                .title {
                    p {
                        color: #4E5867;
                        font-size: 0.8rem;
                        font-weight: 500;
                    }
                }
                .data {
                    margin-left: 5%;
                    p {
                        font-size: 0.9rem;
                        font-weight: 600;
                    }
                }
                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 4vh;
                    width: 23vw;
                    border-radius: 5px;
                    background :#E8EFFC;
                    margin-left: 5%;

                    p {
                        font-size: 0.9rem;
                        font-weight: 600;
                        color: #6490E7;
                    }
                }

                .charge_box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 4vh;
                    width: 30vw;
                    border-radius: 5px;
                    background :#6490E7;
                    margin-left: 5%;

                    p {
                        font-size: 0.9rem;
                        font-weight: 600;
                        color: #fff;
                    }
                }
            }
        }
    }
`;