import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { isMobile } from "react-device-detect";
import styled from 'styled-components';
import { useTrail, animated as a } from "react-spring";
import Loader from "react-loader-spinner";
import { TeamOutlined, SearchOutlined, UserOutlined, LogoutOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { message, Drawer, Modal, notification, Button, Progress } from 'antd';
import "antd/dist/antd.css";
import "../../assets/css/custom.css";

import loginLogo from '../../assets/svg/loginlogo.svg';

import { ProductCopyPage } from '../modal/SalesModal';
import MenuCopy from './manage/MenuCopy';
import { Link, useNavigate } from 'react-router-dom';
import { WebRoute } from '../../navigation/WebRoutes';

const config = { mass: 5, tension: 1000, friction: 200 };

const StoreList = ({ fModeChange, sProps }) => {
   const [salesLoading, setSalesLoading] = useState(true);
   const [iLoading, setILoading] = useState(true);

   const [sList, setList] = useState([]);
   const [tList, setTList] = useState([]);
   const [iList, setIList] = useState({});
   const [kList, setKList] = useState({});

   const [visible, setVisible] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);
   const [childrenDrawer, setChildrenDrawer] = useState(false);
   const [sLimited, setLimited] = useState(false);

   const [userName, setUserName] = useState("");
   const [storeName, setStoreName] = useState("");

   const [modal, contextHolder] = Modal.useModal();
   const [menuCopyModal, setMenuCopyModal] = useState(false);
   const [copyProgress, set_copyProgress] = useState(false);
   const [syncProgress, set_syncProgress] = useState(false);
   const [copyProgressPercent, set_copyProgressPercent] = useState(0);
   const [truncateProgress, set_truncateProgress] = useState(false);
   const navigate = useNavigate()
   const infoModal = (sIndex) => {
      Modal.info({
         title: '상품복사 주의사항',
         content: (
            <div>
               <p>복사할 상품이 있는 매장에서 상품의 삭제가 정확하게 진행되었는지 확인해주세요</p>
               <p>상품복사는 선택한 상품의 카테고리 및 옵션을 전부 복사하는 기능입니다</p>
               <p>카테고리만 삭제하고 상품을 삭제하지 않거나 옵션을 삭제하지 않은 상태로 복사를 진행시</p>
               <p>상품은 삭제되어있지 않은 상태로 복사가 진행됩니다 해당 카테고리 및 상품 그리고 옵션은 전부 확인 후 진행바랍니다</p>
            </div>
         ),
         onOk() {
            getDesignateStore();
         },
      });
   };

   const trail = useTrail(sList.length, {
      config,
      opacity: 1,
      x: 0,
      height: 80,
      from: { opacity: 0, x: 20, height: 0 }
   });
   const sTrail = useTrail(tList.length, {
      config,
      opacity: 1,
      x: 0,
      height: 80,
      from: { opacity: 0, x: 20, height: 0 }
   });

   const searchSalesStoreExcept = async () => {
      if (storeName !== "") {
         setILoading(false);

         const checkId = sProps.UserConfigReducer.SalesGroupId;
         const oData = {
            sales_id: sProps.UserConfigReducer.SalesId,
            sParam: storeName,
            aParam: iList.key,
            type: sProps.UserConfigReducer.SalesUserType,
         }
         if (checkId.toString() === "100") {
            const oResponse = await sProps.oManager.fDoAxios("/sales/manager/searchSalesStore", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) setTList(oResponse);
         } else {
            const oResponse = await sProps.oManager.fDoAxios("/sales/searchSalesStore", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) setTList(oResponse);
         }
         setILoading(false);
      }
   }

   const getDesignateStore = async () => {
      if (iList !== undefined) {
         const checkId = sProps.UserConfigReducer.SalesGroupId;
         const oData = {
            sales_id: sProps.UserConfigReducer.SalesId,
            sParam: iList.key,
            type: sProps.UserConfigReducer.SalesUserType,
         }
         if (checkId.toString() === "100") {
            setTList([]);
         } else {
            const oResponse = await sProps.oManager.fDoAxios("/sales/getSalesTeamDataExceptOne", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) setTList(oResponse);
         }
         setChildrenDrawer(true);
         setILoading(false);
      }
   }

   const moveToSite = async (sIndex) => {
      navigate(WebRoute.ADMIN_SALES_HOME)
      // if (typeof fModeChange === "function") {
      //    await fModeChange(sIndex);
      // }
   }

   const inputStoreName = (e) => {
      const iNm = e.target.value
      setStoreName(iNm);
      if (iNm === "") {
         setILoading(true);
         getDesignateStore();
      }
   }
   const designate = (sIndex) => {
      setKList(sIndex);
      setModalVisible(true);
   }

   const designateStore = (item) => {
      setIList(item);
      setVisible(true);
   }

   const searchStore = async () => {
      if (userName !== "") {
         setSalesLoading(true);

         const checkId = sProps.UserConfigReducer.SalesGroupId;
         const oData = {
            sales_id: sProps.UserConfigReducer.SalesId,
            sParam: userName,
            type: sProps.UserConfigReducer.SalesUserType,
         }
         if (checkId.toString() === "100") {
            const oResponse = await sProps.oManager.fDoAxios("/sales/manager/searchSalesStore", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) setList(oResponse);
         } else {
            const oResponse = await sProps.oManager.fDoAxios("/sales/searchSalesStore", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) setList(oResponse);
         }
         setSalesLoading(false);
      }
   }

   const closeModal = () => {
      setModalVisible(false);
   }

   const inputUserName = async (e) => {
      setUserName(e.target.value);
      if (e.target.value === "") {
         setSalesLoading(true);
         await asyncData();
      }
   }

   const searchOnKeyEnter = async (e) => {
      if (e.key) {
         searchStore();
      }
   }

   const searchCopyOnKeyEnter = async (e) => {
      if (e.key) {
         searchSalesStoreExcept();
      }
   }

   const authenticationSMS = async (sIndex) => {
      const oData = {
         store_id: sIndex.key,
         phone_number: sIndex.phoneNm,
         sales_id: sProps.UserConfigReducer.SalesId,
      }
      const oResponse = await sProps.oManager.fDoAxios("/sales/authenticate/sms", "post", null, oData);
      if (oResponse !== undefined && oResponse !== null) {
         if (oResponse) {
            message.info("등록된 번호로 인증요청이 완료되었습니다.");
         } else {
            message.error("네트워크 에러가 발생했습니다 나중에 다시 시도 바랍니다.");
         }
      }
   }

   const storeLogin = async (sIndex) => {
      let randomDeviceId = (Math.random() * (10 - 1)) + 1;
      let oResponse = null;
      let checkId = sProps.UserConfigReducer.SalesGroupId;
      let oData = {
         id: sIndex.key,
         key: sProps.UserConfigReducer.SalesId,
         type: sProps.UserConfigReducer.SalesUserType,
         deviceuuid: randomDeviceId.toString()
      }
      if (checkId.toString() === "100") {
         oResponse = await sProps.oManager.fDoAxios("/sales/manager/authenticate/v2", "post", null, oData);
      } else {
         oResponse = await sProps.oManager.fDoAxios("/sales/authenticate/v2", "post", null, oData);
      }

      if (oResponse !== undefined && oResponse !== null) {
         if (oResponse.resultId === "0000") {
            let oUserConfig = {};

            oUserConfig['isPOS'] = false;
            oUserConfig['LoggedIn'] = true;
            oUserConfig['Token'] = await oResponse.token;
            oUserConfig['RefreshToken'] = await oResponse.refresh_token;
            oUserConfig['UUID'] = await oResponse.uuid;

            oUserConfig['StoreID'] = await oResponse.store_id;
            oUserConfig['StoreName'] = await oResponse.store_name;
            oUserConfig['StorePhone'] = await oResponse.store_phone_number;
            oUserConfig['StoreOwner'] = await oResponse.store_merc_full_name;
            oUserConfig['Address'] = await oResponse.store_address1;
            oUserConfig['StoreLat'] = await oResponse.store_lat;
            oUserConfig['StoreLng'] = await oResponse.store_lng;

            oUserConfig['accessible'] = await oResponse.status;

            oUserConfig['Commercial'] = await oResponse.commercial;

            await sProps.reduxSetUserConfig(oUserConfig);
            navigate(WebRoute.ADMIN_SALES_DASHBOARD)
            // if (fModeChange !== undefined && typeof fModeChange === "function") {
            //    await fModeChange("dashboard");
            // }
         } else {
            message.error("네트워크 에러가 발생했습니다 나중에 다시 시도 바랍니다.");
         }
      }
   }

   const asyncData = async () => {
      const checkId = sProps.UserConfigReducer.SalesGroupId;
      const oData = {
         sales_id: sProps.UserConfigReducer.SalesId,
         type: sProps.UserConfigReducer.SalesUserType,
      }
      const oResponse = await sProps.oManager.fDoAxios("/sales/getSalesTeamDataV2", "post", null, oData);
      if (oResponse !== undefined && oResponse !== null) setList(oResponse);
      if (checkId !== undefined && checkId.toString() === "100") {
         setLimited(true);
      }
      setSalesLoading(false);
   }

   const logOut = async () => {
      let oUserConfig = {};

      oUserConfig['isPOS'] = false;
      oUserConfig['LoggedIn'] = false;
      oUserConfig['SalesLoggedIn'] = false;
      oUserConfig['SalesAutoLogin'] = false;
      oUserConfig['AutoLogin'] = false;

      oUserConfig['Token'] = "";
      oUserConfig['RefreshToken'] = "";
      oUserConfig['UUID'] = "";
      oUserConfig['LocalToken'] = "";
      oUserConfig['LoginTime'] = "";
      oUserConfig['SalesLoginTime'] = "";

      oUserConfig['UserId'] = "";
      oUserConfig['UserKey'] = "";

      oUserConfig['SalesId'] = 0;
      oUserConfig['SalesGroupId'] = 0;
      if (!sProps.UserConfigReducer.RememberId) {
         oUserConfig['SalesEmail'] = ""
      }
      oUserConfig['SalesKey'] = "";
      oUserConfig['SalesUserName'] = "";
      oUserConfig['SalesStoreList'] = [];

      oUserConfig['StoreID'] = 0;
      oUserConfig['StoreName'] = "";
      oUserConfig['StorePhone'] = "";
      oUserConfig['Address'] = "";
      oUserConfig['StoreLat'] = 37.4959310000;
      oUserConfig['StoreLng'] = 127.0266190000;
      oUserConfig['StoreOwner'] = "";

      oUserConfig['accessible'] = "inaccessible";

      await sProps.reduxSetUserConfig(oUserConfig);
      navigate(WebRoute.ADMIN_SIGNIN)
   }

   const startCopyingMenu = () => {
      //const oTable = document.querySelector(".ant-transfer-customize-list").firstChild;
      //const oTablesAll = oTable.querySelector(".ant-table-wrapper table");
      const oRows = document.querySelector(".ant-table-left-js").rows;

      let iSourceStoreId = 0;
      for (var i = 0; i < oRows.length; i++) {
         if (oRows[i].classList.contains('ant-table-row-selected') === true) {
            iSourceStoreId = parseInt(oRows[i].getAttribute('data-row-key'));
         }
      }

      if (iSourceStoreId === 0) {
         message.error("매장을 선택해주세요.");
         return;
      }

      //const oTableTarget = document.querySelector(".ant-transfer-customize-list div:nth-child(3)");
      //const oTablesAllTarget = oTableTarget.querySelector(".ant-table-wrapper table");
      const oRowsTarget = document.querySelector(".ant-table-right-js").rows;
      let aTargetStoreId = [];
      for (var i = 0; i < oRowsTarget.length; i++) {
         if (oRowsTarget[i].classList.contains('ant-table-row-selected') === true) {
            let iTargetStoreId = parseInt(oRowsTarget[i].getAttribute('data-row-key').replaceAll('copy-', ''));
            aTargetStoreId.push(iTargetStoreId);
         }
      }

      if (aTargetStoreId.length === 0) {
         message.error("매장을 선택해주세요.");
         return;
      }

      //console.log('iSourceStoreId', iSourceStoreId);
      //console.log('aTargetStoreId', aTargetStoreId);
      doCopyingMenu(iSourceStoreId, aTargetStoreId);
   }

   const startSyncMenu = () => {
      //const oTable = document.querySelector(".ant-transfer-customize-list").firstChild;
      //const oTablesAll = oTable.querySelector(".ant-table-wrapper table");
      const oRows = document.querySelector(".ant-table-left-js").rows;

      let iSourceStoreId = 0;
      for (var i = 0; i < oRows.length; i++) {
         if (oRows[i].classList.contains('ant-table-row-selected') === true) {
            iSourceStoreId = parseInt(oRows[i].getAttribute('data-row-key'));
            console.log("source-store_id=>", iSourceStoreId);
         }
      }

      if (iSourceStoreId === 0) {
         message.error("매장을 선택해주세요.");
         return;
      }

      const oTableTarget = document.querySelector(".ant-transfer-customize-list div:nth-child(3)");
      const oTablesAllTarget = oTableTarget.querySelector(".ant-table-wrapper table");
      const oRowsTarget = document.querySelector(".ant-table-right-js").rows;
      let aTargetStoreId = [];
      for (var i = 0; i < oRowsTarget.length; i++) {
         if (oRowsTarget[i].classList.contains('ant-table-row-selected') === true) {
            let iTargetStoreId = parseInt(oRowsTarget[i].getAttribute('data-row-key').replaceAll('copy-', ''));
            aTargetStoreId.push(iTargetStoreId);
         }
      }

      console.log("target-store_id=>", aTargetStoreId);

      if (aTargetStoreId.length === 0) {
         message.error("매장을 선택해주세요.");
         return;
      }

      // //console.log('iSourceStoreId', iSourceStoreId);
      // //console.log('aTargetStoreId', aTargetStoreId);
      doSyncMenu(iSourceStoreId, aTargetStoreId);
   }

   const oTimer = ms => new Promise(res => setTimeout(res, ms))
   const doCopyingMenu = async (iSourceStoreId, aTargetStoreId) => {

      if (aTargetStoreId !== undefined && aTargetStoreId.length > 0) {
         set_copyProgress(true);

         /*
         for (let iTargetStoreId of aTargetStoreId) {
            let oRowOneCopyElement = document.querySelector(".complete-one-row-right-" + iTargetStoreId + "-js");
            let oRowTwoCopyElement = document.querySelector(".complete-two-row-right-" + iTargetStoreId + "-js");
            let oRowTwoLoadingElement = document.querySelector(".loading-two-row-right-" + iTargetStoreId + "-js");
            if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
               oRowTwoLoadingElement.classList.remove("hidden");
            }
            await oTimer(5000);
            
            if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
               oRowTwoLoadingElement.classList.add("hidden");
            }
            if (oRowOneCopyElement && oRowOneCopyElement.classList) {
               oRowOneCopyElement.classList.remove("hidden");
            }
            if (oRowTwoCopyElement && oRowTwoCopyElement.classList) {
               oRowTwoCopyElement.classList.remove("hidden");
            }
         }
         */

         for (let iTargetStoreId of aTargetStoreId) {
            console.log('iTargetStoreId', iTargetStoreId);

            let oRowOneCopyElement = document.querySelector(".complete-one-row-right-" + iTargetStoreId + "-js");
            let oRowTwoCopyElement = document.querySelector(".complete-two-row-right-" + iTargetStoreId + "-js");
            let oRowTwoLoadingElement = document.querySelector(".loading-two-row-right-" + iTargetStoreId + "-js");
            if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
               oRowTwoLoadingElement.classList.remove("hidden");
            }

            let oData = {
               source_store_id: iSourceStoreId,
               target_store_id: iTargetStoreId
            }

            const oResponse = await sProps.oManager.fDoAxios("/sales/menu/copyMenu", "post", 'login', oData, true);
            if (oResponse !== undefined && oResponse.success === true) {
               if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
                  oRowTwoLoadingElement.classList.add("hidden");
               }
               if (oRowOneCopyElement && oRowOneCopyElement.classList) {
                  oRowOneCopyElement.classList.remove("hidden");
               }
               if (oRowTwoCopyElement && oRowTwoCopyElement.classList) {
                  oRowTwoCopyElement.classList.remove("hidden");
                  oRowTwoCopyElement.querySelector("span").innerHTML = "복사완료";
               }
            }
            else if (oResponse !== undefined && oResponse.success === false) {
               if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
                  oRowTwoLoadingElement.classList.add("hidden");
               }
               if (oRowOneCopyElement && oRowOneCopyElement.classList) {
                  oRowOneCopyElement.classList.remove("hidden");
               }
               if (oRowTwoCopyElement && oRowTwoCopyElement.classList) {
                  oRowTwoCopyElement.classList.remove("hidden");
                  oRowTwoCopyElement.querySelector("span").innerHTML = "복사실패";
               }

               message.error(oResponse.msg);
            }
         }

         set_copyProgress(false);
      }
   }

   const doSyncMenu = async (iSourceStoreId, aTargetStoreId) => {

      if (aTargetStoreId !== undefined && aTargetStoreId.length > 0) {
         set_syncProgress(true); //ui


         for (let iTargetStoreId of aTargetStoreId) {
            console.log('iTargetStoreId', iTargetStoreId);

            let oRowOneCopyElement = document.querySelector(".complete-one-row-right-" + iTargetStoreId + "-js");
            let oRowTwoCopyElement = document.querySelector(".complete-two-row-right-" + iTargetStoreId + "-js");
            let oRowTwoLoadingElement = document.querySelector(".loading-two-row-right-" + iTargetStoreId + "-js");
            if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
               oRowTwoLoadingElement.classList.remove("hidden");
            }

            let oData = {
               source_store_id: iSourceStoreId,
               target_store_id: iTargetStoreId
            }

            const oResponse = await sProps.oManager.fDoAxios("/sales/menu/syncMenu", "post", 'login', oData, true);
            if (oResponse !== undefined && oResponse.success === true) {
               if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
                  oRowTwoLoadingElement.classList.add("hidden");
               }
               if (oRowOneCopyElement && oRowOneCopyElement.classList) {
                  oRowOneCopyElement.classList.remove("hidden");
               }
               if (oRowTwoCopyElement && oRowTwoCopyElement.classList) {
                  oRowTwoCopyElement.classList.remove("hidden");
                  oRowTwoCopyElement.querySelector("span").innerHTML = "연동완료";
               }
            }
            else if (oResponse !== undefined && oResponse.success === false) {
               if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
                  oRowTwoLoadingElement.classList.add("hidden");
               }
               if (oRowOneCopyElement && oRowOneCopyElement.classList) {
                  oRowOneCopyElement.classList.remove("hidden");
               }
               if (oRowTwoCopyElement && oRowTwoCopyElement.classList) {
                  oRowTwoCopyElement.classList.remove("hidden");
                  oRowTwoCopyElement.querySelector("span").innerHTML = "연동실패";
               }


               message.error(oResponse.msg);

            }
         }

         set_syncProgress(false);
      }
   }

   const startTruncatingMenu = () => {

      //const oTableTarget = document.querySelector(".ant-transfer-customize-list div:nth-child(3)");
      //const oTablesAllTarget = oTableTarget.querySelector(".ant-table-wrapper table");
      const oRowsTarget = document.querySelector(".ant-table-right-js").rows;
      let aTargetStoreId = [];
      for (var i = 0; i < oRowsTarget.length; i++) {
         if (oRowsTarget[i].classList.contains('ant-table-row-selected') === true) {
            let iTargetStoreId = parseInt(oRowsTarget[i].getAttribute('data-row-key').replaceAll('copy-', ''));
            aTargetStoreId.push(iTargetStoreId);
         }
      }

      if (aTargetStoreId.length === 0) {
         message.error("매장을 선택해주세요 (오른쪽).");
         return;
      }

      //console.log('aTargetStoreId', aTargetStoreId);
      doTruncatingMenu(aTargetStoreId);
   }

   const doTruncatingMenu = async (aTargetStoreId) => {

      if (aTargetStoreId !== undefined && aTargetStoreId.length > 0) {
         set_truncateProgress(true);

         for (let iTargetStoreId of aTargetStoreId) {
            //console.log('iTargetStoreId', iTargetStoreId);

            let oRowTwoLoadingElement = document.querySelector(".loading-two-row-right-" + iTargetStoreId + "-js");
            let oRowOneCopyElement = document.querySelector(".complete-one-row-right-" + iTargetStoreId + "-js");
            let oRowTwoCopyElement = document.querySelector(".complete-two-row-right-" + iTargetStoreId + "-js");
            if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
               oRowTwoLoadingElement.classList.remove("hidden");
            }

            let oData = {
               target_store_id: iTargetStoreId
            }

            const oResponse = await sProps.oManager.fDoAxios("/sales/menu/truncateMenu", "post", 'login', oData, true);
            if (oRowTwoLoadingElement && oRowTwoLoadingElement.classList) {
               oRowTwoLoadingElement.classList.add("hidden");
            }
            if (oRowOneCopyElement && oRowOneCopyElement.classList) {
               oRowOneCopyElement.classList.remove("hidden");
            }
            if (oResponse !== undefined && oResponse.success === true) {
               if (oRowTwoCopyElement && oRowTwoCopyElement.classList) {
                  oRowTwoCopyElement.classList.remove("hidden");
                  oRowTwoCopyElement.querySelector("span").innerHTML = "비우기 완료";
               }
            } else {
               if (oRowTwoCopyElement && oRowTwoCopyElement.classList) {
                  oRowTwoCopyElement.classList.remove("hidden");
                  oRowTwoCopyElement.querySelector("span").innerHTML = "비우기 실패";
               }
            }
         }
         set_truncateProgress(false);
      }
   }

   useEffect(() => {
      asyncData();
   }, []);

   const script = document.createElement('script');
   const fireScript = document.createElement('script');
   useEffect(() => {

      if (menuCopyModal === true) {
         document.querySelector(".copy-menu-modal .ant-modal-footer").style.display = "flex";
         document.querySelector(".copy-menu-modal .ant-modal-footer").style.justifyContent = "center";

         setTimeout(() => {
            if (document !== undefined && document.body !== undefined) {
               script.innerHTML = `

                  $("body").on("click", ".row-copy-menu-left-js", function() {
                     let iCurrKey = ".row-right-" + $(this).attr("data-row-key") + "-js";
                     let iKey = $(this).attr("data-row-key");
                     if($(this).hasClass("ant-table-row-selected")){
                        $(".row-copy-menu-left-js").removeClass("ant-table-row-selected");
                        $(".row-copy-menu-left-js label").removeClass("ant-checkbox-wrapper-checked");
                        $(".row-copy-menu-left-js span.ant-checkbox-js").removeClass("ant-checkbox-checked");
                           
                        $(".row-copy-menu-right-js").removeClass('ant-table-row-disabled');
                        $(".row-copy-menu-right-js").find(".ant-checkbox-wrapper").removeClass('ant-checkbox-wrapper-disabled');
                        $(".row-copy-menu-right-js").find(".ant-checkbox").removeClass('ant-checkbox-disabled');
                        $(".row-copy-menu-right-js").find(".ant-checkbox input").attr('disabled', false);
                        $(".row-copy-menu-right-js").find(".ant-table-cell:nth-child(2)").css("color", "rgba(0, 0, 0, 1)");

                        return;
                     }

                     $(".row-copy-menu-left-js").removeClass("ant-table-row-selected");
                     $(".row-copy-menu-left-js label").removeClass("ant-checkbox-wrapper-checked");
                     $(".row-copy-menu-left-js span.ant-checkbox-js").removeClass("ant-checkbox-checked");

                     // Remove Disabled
                     $(".row-copy-menu-right-js").removeClass('ant-table-row-disabled');
                     $(".row-copy-menu-right-js").find(".ant-checkbox-wrapper").removeClass('ant-checkbox-wrapper-disabled');
                     $(".row-copy-menu-right-js").find(".ant-checkbox").removeClass('ant-checkbox-disabled');
                     $(".row-copy-menu-right-js").find(".ant-checkbox input").attr('disabled', false);
                     $(".row-copy-menu-right-js").find(".ant-table-cell:nth-child(2)").css("color", "rgba(0, 0, 0, 1)");


                     $(this).addClass("ant-table-row-selected");
                     $(this).find("label").addClass("ant-checkbox-wrapper-checked");
                     $(this).find("span.ant-checkbox-js").addClass("ant-checkbox-checked");

                     //Add Disabled
                     $(iCurrKey).removeClass('ant-table-row-selected');
                     $(iCurrKey).find(".ant-checkbox-wrapper").removeClass('ant-checkbox-wrapper-checked');
                     $(iCurrKey).find(".ant-checkbox").removeClass('ant-checkbox-checked');
                     $(iCurrKey).find(".ant-checkbox-wrapper").addClass('ant-checkbox-wrapper-disabled');
                     $(iCurrKey).find(".ant-checkbox").addClass('ant-checkbox-disabled');
                     $(iCurrKey).find(".ant-checkbox input").attr('disabled', true);
                     $(iCurrKey).find(".ant-table-cell:nth-child(2)").css("color","rgba(0, 0, 0, 0.2)");
                     $(iCurrKey).addClass("ant-table-row-disabled");
                  });
                  
                  $("body").on("click", ".row-copy-menu-right-js", function() {

                     if($(this).hasClass("ant-table-row-disabled")){
                        return;
                     }

                     if($(this).hasClass("ant-table-row-selected") === true){
                        $(this).removeClass("ant-table-row-selected");
                        $(this).find("label").removeClass("ant-checkbox-wrapper-checked");
                        $(this).find("span.ant-checkbox-js").removeClass("ant-checkbox-checked");
                        return;
                     }

                     $(this).addClass("ant-table-row-selected");
                     $(this).find("label").addClass("ant-checkbox-wrapper-checked");
                     $(this).find("span.ant-checkbox-js").addClass("ant-checkbox-checked");
                  });

                  $('body').on('keyup', '.ant-input-right-js', function(){
                     let iLeftSideSelectedRowKey = $(".ant-table-left-js tr.ant-table-row-selected").attr("data-row-key");
                     if(iLeftSideSelectedRowKey !== undefined && iLeftSideSelectedRowKey > 0){
                        let iCurrKey = ".row-right-" + iLeftSideSelectedRowKey + "-js";
                        $(iCurrKey).removeClass('ant-table-row-selected');
                        $(iCurrKey).find(".ant-checkbox-wrapper").removeClass('ant-checkbox-wrapper-checked');
                        $(iCurrKey).find(".ant-checkbox").removeClass('ant-checkbox-checked');
                        $(iCurrKey).find(".ant-checkbox-wrapper").addClass('ant-checkbox-wrapper-disabled');
                        $(iCurrKey).find(".ant-checkbox").addClass('ant-checkbox-disabled');
                        $(iCurrKey).find(".ant-checkbox input").attr('disabled', true);
                        $(iCurrKey).find(".ant-table-cell:nth-child(2)").css("color","rgba(0, 0, 0, 0.2)");
                        $(iCurrKey).addClass("ant-table-row-disabled");
                     }
                  });
               `;
               if (document.body.classList.contains("menu-copy-js-added") === false) {
                  document.body.appendChild(script);
                  document.body.classList.add('menu-copy-js-added');
               }

               fireScript.id = "menu-copy-script-fire-once";
               fireScript.innerHTML = `
                  setTimeout(() => {
                     $(".left-table-col-js").closest('table').addClass("ant-table-left-js");
                     $(".right-table-col-js").closest('table').addClass("ant-table-right-js");
                     $(".right-table-col-js").closest('table').find(".ant-input").addClass("ant-input-right-js");
                  }, 250);
               `;
               document.body.appendChild(fireScript);
            }
         }, 250);
      } else if (menuCopyModal === false) {
         let oScript = document.getElementById('menu-copy-script-fire-once');
         if (oScript != null) {
            document.getElementsByTagName('body')[0].removeChild(oScript);
         }
      }

   }, [menuCopyModal]);




   return (
      <Style>
         <div className="loginPage">
            <div className="loginBox">
               <SVG src={loginLogo} width="54px" height="30px" title="로그인페이지로고" />
               <div className="sales_user_box">
                  <div className="info">
                     <h2><span style={{ color: "#617be3", fontWeight: 700 }}>{sProps.UserConfigReducer.SalesUserName} 파트너님</span></h2>
                  </div>
                  {sProps.UserConfigReducer.SalesUserType === "owner" ?
                     <div className="button_box">
                        <Link to={WebRoute.ADMIN_SALES_HOME}>
                           <div className="search_button" >
                              <UserOutlined className='saleshome_icon' style={{ color: "#13166b" }} />
                           </div>
                        </Link>
                        <div className="search_button" style={{ border: "1px solid #DD1212", marginLeft: '15px' }} onClick={logOut}>
                           <LogoutOutlined className='sales_icon' style={{ color: "#DD1212" }} />
                        </div>
                     </div>
                     :
                     <div className="button_right">
                        <div className="logout_button" style={{ border: "1px solid #DD1212" }} onClick={logOut}>
                           <LogoutOutlined className='sales_icon' style={{ color: "#DD1212 !important", fill: "#DD1212 !important", }} />
                        </div>
                     </div>
                  }
               </div>
               <Link to={WebRoute.ADMIN_STORE_ADD}>
                  <div className="sales_store_box">
                     <div className="join" >
                        <p>새로운 매장 추가하기</p>
                        <div className="insert_sales_store">
                           <PlusOutlined style={{ color: "#fff" }} />
                        </div>
                     </div>
                  </div>
               </Link>

               <div className="sales_store_box menu-copy-box">
                  <div className="join" onClick={() => setMenuCopyModal(true)}>
                     <p>메뉴 복사하기</p>
                     <div className="insert_sales_store">
                        <CopyOutlined style={{ color: "#fff" }} />
                     </div>
                  </div>
               </div>

               <div className="search_box">
                  <input
                     type="text"
                     placeholder="매장명을 입력해주세요"
                     value={userName}
                     onChange={inputUserName}
                     onKeyPress={searchOnKeyEnter}
                  />
                  <div className="search_button" onClick={searchStore}>
                     <SearchOutlined className='sales_icon' />
                  </div>
               </div>
               {salesLoading ?
                  <div style={{ display: "flex", justifyContent: "center", height: "39vh", alignItems: "center" }}>
                     <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                  </div>
                  :
                  <>
                     {sList.length > 0 ?
                        <div className="store_list_box">
                           {trail.map(({ x, height, ...rest }, index) => (
                              <a.div
                                 className="list_box"
                                 key={index}
                                 onClick={() => designateStore(sList[index])}
                                 style={{
                                    ...rest,
                                    transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                                 }}
                              >
                                 <a.div className="triangle_box">
                                    {sList[index].smsAuthenticate ?
                                       <a.div className="validated">
                                          <TeamOutlined className='sales_icon' style={{ fontSize: "1.2rem", color: "#3d5a80" }} />
                                       </a.div>
                                       :
                                       <a.div className="un_validated">
                                          <TeamOutlined className='sales_icon_unvalidate' style={{ fontSize: "1.2rem", color: "#ce6a6b" }} />
                                       </a.div>
                                    }
                                 </a.div>
                                 <a.div className="store_box">
                                    <a.div>
                                       <p className="store_title">{sList[index].storeName}</p>
                                       {sList[index].smsAuthenticate ?
                                          <p className="authenticate_content" style={{ color: "#666666" }}>인증이 완료되었습니다</p>
                                          :
                                          <p className="authenticate_content" style={{ color: "#DD1212" }}>인증이 필요합니다</p>
                                       }
                                    </a.div>
                                 </a.div>
                              </a.div>

                           ))}
                        </div>
                        :
                        <>
                           {sLimited ?
                              <div className="none_store_list_box">
                                 <p>찾으시는 매장을 검색하세요.</p>
                              </div>
                              :
                              <div className="none_store_list_box">
                                 <p>매장이 없습니다.</p>
                              </div>
                           }
                        </>
                     }
                  </>
               }

            </div>
         </div>
         <Drawer
            title={<p style={{ height: 20, display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "5%", fontSize: "1rem", fontWeight: 600 }}>{iList.storeName}</p>}
            placement="right"
            closable={false}
            onClose={() => setVisible(false)}
            visible={visible}
         >
            {!iList.smsAuthenticate &&
               <div className="sms_box" style={{ height: "40px", borderBottom: "1px solid #13166b", display: 'flex', justifyContent: "center", alignItems: "center", marginBottom: "10%" }}>
                  <div className="sms" style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "7%", cursor: "pointer" }} onClick={() => authenticationSMS(iList)}>
                     <p style={{ fontSize: "0.8rem", fontWeight: 700, color: "#13166b" }}>인증 요청하기</p>
                  </div>
               </div>
            }
            <div className="login_box" style={{ height: "40px", borderBottom: "1px solid #617be3", display: 'flex', justifyContent: "center", alignItems: "center" }}>
               <div className="stock" onClick={() => storeLogin(iList)} style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "7%", cursor: "pointer" }}>
                  <p style={{ fontSize: "0.8rem", fontWeight: 700, color: "#617be3" }}>접속하기</p>
               </div>
            </div>
            {/*
            <div className="login_box" style={{ marginTop: "10%", height: "40px", borderBottom: "1px solid #000", display: 'flex', justifyContent: "center", alignItems: "center" }}>
               <div className="stock" onClick={() => infoModal(iList)} style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "7%", cursor: "pointer" }}>
                  <p style={{ fontSize: "0.8rem", fontWeight: 700 }}>상품 복사</p>
               </div>
            </div>
            */}
            {iList.smsAuthenticate &&
               <div className="sms_box" style={{ marginTop: "10%", height: "40px", display: 'flex', justifyContent: "center", alignItems: "center" }}>
                  <div className="sms_done" style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "7%", cursor: "pointer" }} onClick={() => setVisible(false)}>
                     <p style={{ fontSize: "0.8rem", fontWeight: 700, color: "#666666" }}>인증 완료</p>
                  </div>
               </div>
            }
            <Drawer
               title={<p style={{ height: 20, display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "5%", fontSize: "1rem", fontWeight: 600 }}>상품복사</p>}
               width={320}
               closable={false}
               onClose={() => setChildrenDrawer(false)}
               visible={childrenDrawer}
            >
               <div className="store_list">
                  <p style={{ fontWeight: 600, fontSize: "0.8rem" }}>복사할 상품이 있는 매장을 선택해주세요</p>
                  <div className="search_box" style={{ height: 50, display: 'flex', justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
                     <input
                        style={{ width: "78%", height: 44, margin: "3px 0", padding: "12px 14px", border: "1px solid #000", fontSize: 14, fontWeight: 400, outline: "none" }}
                        type="text"
                        placeholder="매장명을 입력해주세요"
                        value={storeName}
                        onChange={inputStoreName}
                        onKeyPress={searchCopyOnKeyEnter}
                     />
                     <div
                        className="search_button"
                        onClick={() => searchSalesStoreExcept()}
                        style={{ display: 'flex', justifyContent: "center", alignItems: "center", margin: "3px 0", width: "20%", height: 44, background: "#13166b", borderRadius: "5px", cursor: 'pointer', paddingTop: "15px" }}
                     >
                        <p style={{ fontSize: "0.8rem", fontWeight: 700, color: "#fff" }}>검색</p>
                     </div>
                  </div>
                  {iLoading ?
                     <div style={{ display: "flex", justifyContent: "center", height: "39vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                     </div>
                     :
                     <>
                        {sTrail.length > 0 ?
                           <div
                              className="store_list_box"
                              style={{ width: "100%", height: 350, marginBottom: "10%", border: "1px solid #666666", position: "relative", overflowY: "scroll", overflowX: "hidden" }}
                           >
                              {sTrail.map(({ x, height, ...rest }, index) => (
                                 <a.div
                                    key={index}
                                    className="list_box"
                                    style={{
                                       ...rest,
                                       transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
                                       display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", height: 60, borderBottom: "1px solid #dfdfdf"
                                    }}
                                 >
                                    <a.div
                                       className="store_box"
                                       style={{ display: "flex", alignItems: "center", width: "70%", height: 60, paddingLeft: 20, paddingTop: 10 }}
                                    >
                                       <p
                                          className="store_title"
                                          style={{ padding: 0, fontSize: "0.9rem", fontWeight: 700 }}
                                       >
                                          {tList[index].storeName}
                                       </p>
                                    </a.div>
                                    <a.div
                                       className="store_button_box"
                                       style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "30%", height: 60, paddingRight: 10 }}
                                    >
                                       <a.div
                                          className="line"
                                          onClick={() => designate(tList[index])}
                                          style={{ padding: 10, width: "100%", border: "1px solid #617be3", borderRadius: 5, height: 40, cursor: "pointer" }}
                                       >
                                          <p style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "0.8rem", fontWeight: 700, color: "#617be3" }}>선택</p>
                                       </a.div>
                                    </a.div>
                                 </a.div>
                              ))}
                           </div>
                           :
                           <div
                              className="store_list_box"
                              style={{ width: "100%", height: 350, marginBottom: "10%", border: "1px solid #666666", position: "relative", justifyContent: "center", alignItems: "center", display: "flex" }}
                           >
                              <p>찾으시는 매장을 검색하세요.</p>
                           </div>
                        }
                     </>
                  }
               </div>
            </Drawer>
         </Drawer>
         <Modal visible={modalVisible} centered={true} closable={false} onOk={() => { setModalVisible(false) }} onCancel={() => { setModalVisible(false) }} okText="확인" cancelText="취소">
            <ProductCopyPage
               iProps={sProps}
               sKey={iList.key}
               sName={iList.storeName}
               iKey={kList.key}
               iName={kList.storeName}
               fnCloseModal={() => closeModal()}
            />
         </Modal>
         {contextHolder}
         <Modal
            centered
            visible={menuCopyModal}
            //onOk={() => setMenuCopyModal(false)}
            //onCancel={() => setMenuCopyModal(false)}
            width="80vw"
            style={{ minHeight: "80vh", minWidth: "50vw" }}
            closable={false}
            wrapClassName="copy-menu-modal"
            footer={[
               <Button key="back" onClick={() => setMenuCopyModal(false)}>취소</Button>,
               <Button key="submit" type="primary" onClick={() => { startSyncMenu(); }} loading={syncProgress === true ? true : false}>{syncProgress === true ? '연동중' : '연동 하기'}</Button>,
               <Button key="submit" type="primary" onClick={() => { startCopyingMenu(); }} loading={copyProgress === true ? true : false}>{copyProgress === true ? '복사중' : '복사 하기'}</Button>,
               <Button key="truncate" type="primary" danger onClick={() => { startTruncatingMenu(); }} loading={truncateProgress === true ? true : false}>{truncateProgress === true ? '비우기중' : '메뉴 비우기'}</Button>
            ]}
         >
            <MenuCopy {...sProps} />
         </Modal>

         {/*
         <Modal
            centered
            visible={copyProgress}
            closable={false}
            footer={null}
            wrapClassName="progress-menu-modal"
         >
            <Progress type="circle" percent={copyProgressPercent} />
         </Modal>*/}
      </Style >
   )
}

export default StoreList;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .store_list {
        margin-top: 30px;
        height: 440px;
        background: red;

        .search_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;

            input {
                width: 78%;
                height: 44px;
                margin: 3px 0;
                padding: 12px 14px;
                border: 1px solid #000;
                font-size: 14px;
                font-weight: 400;
                outline: none;
            }

            .search_button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 3px 0;
                width: 20%;
                height: 44px;
                background: #13166b;
                border-radius: 5px;
                cursor: pointer;
                padding-top: 15px;

                p {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }

    }

    .loginPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        background-color: #E5E5E5;
    }

    .none_store_list_box{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 39vh;
        margin-bottom: 10%;
        
        p {
            font-size: 0.8rem;
            font-weight: 400;
        }
    }
    .store_list_box {
        width: 100%;
        height: 39vh;
        margin-bottom: 10%;
        position: "relative";
        overflow-y: scroll;
        overflow-x: hidden;

        .list_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 10vh;
            border-bottom: 1px solid #dfdfdf;

            .triangle_box {
                display: flex;
                align-items: center;
                width: 30%;
                height: 10vh;
                .sales_icon path {
                   background-color: unset!important;
                   fill: #3d5a80!important;
                }
                .sales_icon_unvalidate path {
                   background-color: unset!important;
                   fill: #ce6a6b!important;
                }
                .validated {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60%;
                    height: 60%;
                    background: #DBE8F9; 
                    border-radius: 15px;
                }

                .un_validated {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60%;
                    height: 60%;
                    background: #fff1e6;
                    border-radius: 15px;
                }
            }

            .store_box  {
                display: flex;
                align-items: center;
                width: 70%;
                height: 10vh;

                .store_title {
                    padding: 0;
                    font-size: 0.8rem;
                    font-weight: 700;
                }
                .authenticate_content {
                    padding: 0;
                    font-size: 0.6rem;
                    font-weight: 700;
                }
            }
            .store_button_box  {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30%;
                height: 10vh;

            }
        }
    }

    
    .loginBox {
        width: 314px;
        padding: 24px;
        background-color: #fff; 
        border-radius: 4px;

        .sales_user_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 90px;
            .sales_icon path {
               background-color: unset !important;
               fill: rgb(221, 18, 18) !important;
            }
            .saleshome_icon path {
               background-color: unset !important;
               fill: #13166b !important;
            }
            .info {
                display: flex;
                align-items: center;
                width: 65%;
                height: 100%;
            }

            .button_right {
                display: flex;
                align-items: center;
                display: flex;
                width: 19%;
                height: 100%;

                .logout_button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 3px 0;
                    width: 100%;
                    height: 40px;
                    margin-left: 10px;
                    border: 1px solid #13166b;
                    border-radius: 30%;
                    cursor: pointer;

                    p {
                        font-size: 0.8rem;
                        font-weight: 700;
                        color: #fff;
                    }
                }
            }
            .button_box {
                display: flex;
                align-items: center;
                display: flex;
                width: 30%;
                height: 100%;


                .search_button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 3px 0;
                    width: 100%;
                    height: 40px;
                    margin-left: 10px;
                    border: 1px solid #13166b;
                    border-radius: 30%;
                    cursor: pointer;

                    p {
                        font-size: 0.8rem;
                        font-weight: 700;
                        color: #fff;
                    }
                }
            }
        }

        .sales_store_box {
            display: flex;
            justify-content: center;
            height: 78px;

            .join {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 60px;
                border-radius: 15px;
                background: #001E62;
                cursor: pointer;

                p {
                    width: 70%;
                    font-size: 0.8rem;
                    font-weight: 500;
                    color: #fff;
                }

                .insert_sales_store {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 15%;
                    height: 35px;
                    margin-right: 5%;
                    background: #617be3;
                    border-radius: 15px;
                }
            }
        }

        .menu-copy-box {
           height: 82px;
           .join {
               height: 50px;
               background: #001E62;
               border: #001E62;

               .insert_sales_store {
                  background: #001E62;
               }
           }
        }

        .search_box {
            display: flex;
            justify-content: space-between;
            height: 60px;

            .sales_icon path {
               background-color: unset !important;
               fill: #13166b !important;
            }
            input {
                width: 77%;
                height: 44px;
                margin: 3px 0;
                padding: 12px 14px;
                border: none;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                border-bottom: 1px solid #dfdfdf;
            }

            .search_button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 3px 0;
                width: 15%;
                height: 40px;
                border: 1px solid #13166b;
                border-radius: 30%;
                cursor: pointer;

                p {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
        h2 {
            margin: 16px 0 8px 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
        }
        
        .join {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            border: 1px solid #001E62;
            background: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            color: #001E62;
            outline: none;
        }

        .join:hover {
            background: #001E62;
            color: #fff;
        }

        .back_out {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            background: #fff;
            color: #DD1212;
            border: none;
            outline: none;
        }
        .back_out:hover {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            background: #DD1212;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
        }
        
    }

    .drawer_box {
        background:red;
        .login_box {
            padding: 5%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 7vh;
            
            .stock {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 50px;
                height: 4vh;
                border-radius: 5%;
                border: 1px solid #617be3;
                
                margin-left: auto;
                cursor: pointer;
                transition: 0.3s;

                p {
                    padding: 0;
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #617be3;
                }
            }
        }

        .sms_box {
            padding: 5%;
            .sms {
                display: flex;
                justify-content: center;
                width: 50px;
                margin-left: auto;
                cursor: pointer;
                height: 3vh;

                p {
                    text-decoration: underline;
                    font-size: 0.7rem;
                    font-weight: 500;
                    color: #617be3;
                }
            }

            .sms_done {
                display: flex;
                justify-content: center;
                width: 50px;
                margin-left: auto;
                cursor: pointer;
                height: 3vh;

                p {
                    font-size: 0.7rem;
                    font-weight: 500;
                    color: #666666;
                }
            }
        }


        .stock:hover {
            background: #617be3;
            p {
                color: #fff;
            }
        }
    }

    @media (max-width: 550px){
        .loginPage {
            background-color: #fff;
            height: auto;
        }
        .loginBox {
            width: 90vw;
        }
        .store_list_box {
            height: 40vh;
        }
`;