/*global kakao*/
import React from 'react';
import styled from 'styled-components';

const KakaoMap = ({ sUserLat, sUserLng }) => {
   const mapScript = document.createElement("script");

   mapScript.async = true;
   mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_KEY}&autoload=false`;

   document.head.appendChild(mapScript);

   const onLoadMap = async () => {
      window.kakao.maps.load(async () => {
         const container = await document.getElementById("kakaomap");
         const options = {
            center: await new window.kakao.maps.LatLng(sUserLat, sUserLng),
            level: 3
         };

         const map = await new window.kakao.maps.Map(container, options);
         await map.addOverlayMapTypeId(kakao.maps.MapTypeId.TRAFFIC);

         const latlng = new window.kakao.maps.LatLng(sUserLat, sUserLat);
         const imageSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";
         const imageSize = new window.kakao.maps.Size(24, 35);
         const markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize);

         const marker = new window.kakao.maps.Marker({
            map: map,
            position: latlng,
            image: markerImage
         });

         map.setDraggable(false);
      });
   };

   mapScript.addEventListener("load", onLoadMap);

   return (
      <MapContents id="kakaomap"></MapContents>
   )
}

export default KakaoMap;

const MapContents = styled.div`
    border-radius : 30px;
    width: 100%;
    height: 25vh;
`;
