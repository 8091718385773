import React, { useState, useEffect, Fragment, useLayoutEffect } from 'react';
import { Route, Switch, BrowserRouter, Routes, Redirect, Navigate } from "react-router-dom";
import { connect } from 'react-redux';

import { WebRoute } from './WebRoutes';

import AdminHome from '../screens/manager/home/AdminHome';

import SignUp from '../components/login/SignUp';
import SignIn from '../components/login/SignIn';
import FindId from '../components/login/findId';
import FindPw from '../components/login/findPw';
import DashBoard from '../components/manage/Main';
import StoreList from '../components/sales/StoreList';
import AddStore from '../components/sales/AddStore';
import SalesHome from '../components/sales/SalesHome';

import ManageLayout from '../components/manage/ManageLayout'
// import Store from '../components/manage/store/pc/Main';
// import Guide from '../components/manage/guide/PosGuide';
// import Business from '../components/manage/business/pc/Main';
import DefaultInfo from '../components/manage/store/DefaultInfo'
import PickupInfo from '../components/manage/store/PickupInfo'
import OperationInfo from '../components/manage/store/OperationInfo'
import SetDuvetDay from '../components/manage/store/SetDuvetDay'
import SetPicture from '../components/manage/store/SetPicture'
import Delivery from '../components/manage/store/Delivery'


import PProduct from '../components/manage/product/Product'
import PCategory from '../components/manage/product/Category'
import POption from '../components/manage/product/Option'
import PBrand from '../components/manage/product/Brand'

import Business from '../components/manage/business/Business'

import Payment from '../components/manage/payment/Payment';
import Calculate from '../components/manage/payment/Calculate'
import Chart from '../components/manage/payment/Chart'
import Tax from '../components/manage/payment/Tax'
import Inventory from '../components/manage/inventory/manage';
import Coupon from '../components/manage/coupon/List';
import CouponAdd from '../components/manage/coupon/Insert';
import Stamp from '../components/manage/stamp/List';
import StampAdd from '../components/manage/stamp/Insert';
import StampEdit from '../components/manage/stamp/Edit';
import Commercial from '../components/manage/commercial/Mypage';
import CommercialChart from '../components/manage/commercial/Chart';
import CommercialAdd from '../components/manage/commercial/Add';
import CommercialPList from '../components/manage/commercial/PaymentList';
import PosGuide from '../components/manage/guide/PosGuide';
import PrinterGuide from '../components/manage/guide/PrinterGuide';
import Notice from '../components/manage/notice';
import ErrorScreen from '../screens/error/Error';
import Review from '../components/manage/review/List'

import moment from 'moment';


const ManageRoutes = ({ oProps }) => {
   const [iStoreUnReviewCount, set_iStoreUnReviewCount] = React.useState('0');

   const parsingUrl = (routing_url) => {
      return routing_url.slice(12)
   }
   const now = new Date();
   const [start_Date, set_start_Date] = useState(new Date(now.setMonth(now.getMonth() - 1))); //한달전
   const [end_Date, set_end_Date] = useState(new Date());
   // status정보
   const getStats = async () => {

      let sId = false;

      if (oProps && oProps.UserConfigReducer) {
         sId = oProps.UserConfigReducer.StoreID;
      }

      if (sId) {

         try {
            const oParams = {
               store_id: sId,
               start_date: start_Date,
               end_date: end_Date
            }
            const oStatus = await oProps.oManager.fDoAxios("/store/review/getReviewStatByDate", "post", null, oParams);
            if (oStatus != undefined && oStatus != false) {
               set_iStoreUnReviewCount(oStatus.unanswered_review_count);
            }

         } catch (err) {
            console.log("err-->", err);
         }
      }
   }

   useLayoutEffect(() => {
      getStats()
   }, []);

   let bSalesLoggedIn = false;
   let bUserLoggedIn = false;

   if (oProps && oProps.UserConfigReducer) {
      if (oProps.UserConfigReducer.LoggedIn === true) {
         bUserLoggedIn = true;
      }
      if (oProps.UserConfigReducer.SalesLoggedIn === true) {
         bSalesLoggedIn = true;
      }
   }

   return (
      <Routes>
         {/* SELFMANAGE */}
         {/* <Route exact path={parsingUrl(WebRoute.ADMIN_LOGIN)} element={<AdminHome oProps={oProps} />} /> */}
         {/* 회원가입 및 로그인 */}
         <Route exact path={parsingUrl(WebRoute.ADMIN_SIGNUP)} element={<SignUp sProps={oProps} />} />
         <Route exact path={parsingUrl(WebRoute.ADMIN_SIGNIN)} element={<SignIn sProps={oProps} />} />
         <Route exact path={parsingUrl(WebRoute.ADMIN_FIND_ID)} element={<FindId sProps={oProps} />} />
         <Route exact path={parsingUrl(WebRoute.ADMIN_FIND_PW)} element={<FindPw sProps={oProps} />} />

         {/* 세일즈 로그인 라우팅 */}
         {bSalesLoggedIn ?
            <Route path="/">
               <Route exact path={parsingUrl(WebRoute.ADMIN_SALES_HOME)} element={<SalesHome sProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_LIST)} element={<StoreList sProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_ADD)} element={<AddStore sProps={oProps} />} />
            </Route> : undefined}
         {/* 여기부터 셀프어드민 route */}
         {bUserLoggedIn || bSalesLoggedIn ?
            <Route path="/" element={<ManageLayout oProps={oProps} iStoreUnReviewCount={iStoreUnReviewCount} />}>
               <Route exact path={'/'} element={<DashBoard iProps={oProps} />} />

               <Route exact path={parsingUrl(WebRoute.ADMIN_SALES_DASHBOARD)} element={<DashBoard iProps={oProps} />} />
               {/* <Route exact path={WebRoute.ADMIN_PROPUCT} element={<Product sProps={oProps} />}/> */}
               {/* <Route exact path={WebRoute.ADMIN_BUSINESS} element={<Business sProps={oProps} />}/> */}
               {/* <Route exact path={WebRoute.ADMIN_STORE} element={<Store sProps={oProps} />}/> */}
               {/* <Route exact path={WebRoute.ADMIN_GUIDE} element={<Guide sProps={oProps} />}/> */}

               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_INFO)} element={<DefaultInfo oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_PICKUP_INFO)} element={<PickupInfo oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_OPERATION_INFO)} element={<OperationInfo oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_DUVET)} element={<SetDuvetDay oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_PICTURE)} element={<SetPicture oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STORE_DELIVERY)} element={<Delivery oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PRODUCT_PRODUCT)} element={<PProduct oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PRODUCT_CATEGORY)} element={<PCategory oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PRODUCT_OPTION)} element={<POption oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PRODUCT_BRAND)} element={<PBrand oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_BUSINESS)} element={<Business oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PAYMENT)} element={<Payment oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PAYMENT_CALCULATE)} element={<Calculate oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PAYMENT_CHART)} element={<Chart oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_PAYMENT_TAX)} element={<Tax oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_INVENTORY)} element={<Inventory sProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_COUPON)} element={<Coupon oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_COUPON_ADD)} element={<CouponAdd oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STAMP)} element={<Stamp oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STAMP_ADD)} element={<StampAdd oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_STAMP_EDIT)} element={<StampEdit oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_COMMERCIAL)} element={<Commercial oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_COMMERCIAL_CHART)} element={<CommercialChart oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_COMMERCIAL_ADD)} element={<CommercialAdd oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_COMMERCIAL_PAYMENT_LIST)} element={<CommercialPList oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_GUIDE_POS)} element={<PosGuide oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_GUIDE_PRINTER)} element={<PrinterGuide oProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_NOTICE)} element={<Notice sProps={oProps} />} />
               <Route exact path={parsingUrl(WebRoute.ADMIN_REVIEW)} element={<Review sProps={oProps} set_iStoreUnReviewCount={set_iStoreUnReviewCount} set_start_Date={set_start_Date} set_end_Date={set_end_Date} />} />
            </Route> :
            <Route path="/*" element={
               <Navigate to={WebRoute.ADMIN_SIGNIN} replace />} />}
         {/* not found */}
         {/* <Route path="/*" element={<ErrorScreen {...oProps}/>}/> */}
         <Route path="/*" element={<ErrorScreen {...oProps} />} />


      </Routes>

   );
};

export default ManageRoutes;