import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Result, Typography } from "antd";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CloseCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import AppModalKakaoMap from './AppModalKakaoMap';

const { Paragraph } = Typography;

const ManagementPosItem = (oProps) => {
    const [wrongAccess, setWrongAccess] = useState(true);
    
    const navigate = useNavigate();
    const anglePoint = useRef(null);
    const userLat = useRef(37.56637919891677);
    const userLng = useRef(126.97914589375286);
    const location = useLocation()

    const validate = async () => {
        if(wrongAccess){
            if(location.search !== null && location.search !== undefined && location.search !== ""){
                let str = await location.search;
                str = await str.substr(2, str.length);
                str = await str.split("?@=");
                if(str.length > 2){
                    const oData = {
                        pan: parseFloat(str[2]),
                        tilt: parseFloat(str[3]),
                        zoom: parseFloat(str[4])
                    }
                    userLat.current = parseFloat(str[0]);
                    userLng.current = parseFloat(str[1]);
                    anglePoint.current = oData;
                    setWrongAccess(false); 
                } else {
                    setWrongAccess(true);
                }
            } else {
                setWrongAccess(true);
            }
        }
    }

    useEffect(() => {
        validate();
    },[wrongAccess,oProps]);

    return (
        <div>
            {wrongAccess ?
                <Row className="justify-content-md-center" style={{marginTop: "10%", marginBottom: "10%"}}>
                    <Col md="6">
                        <Result
                            status="error"
                            title="잘못된 접근입니다"
                        >
                        <div className="desc">
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" style={{marginRight: "1%"}}/> 
                                혹시 잘못된 주소를 입력하셨나요? 정확한 주소로 접근해주세요
                            </Paragraph>
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" style={{marginRight: "1%"}}/> 
                                네트워크에러입니다 잠시 후 다시 시도바랍니다
                            </Paragraph>
                        </div>
                        </Result>
                    </Col>
                </Row>
                :
                <AppModalKakaoMap 
                    sUserLat={userLat.current}
                    sUserLng={userLng.current}
                    iViewPoint={anglePoint.current}
                />
            }

        </div>
    )
}

export default ManagementPosItem;