import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_posDownload from "./mobile/M_posDownload";
import D_posDownload from "./desktop/D_posDownload";

const MainPosDownload = (oProps) => {
    const PosDownRender = () => {
        if (isMobile) {
            return <M_posDownload />
        }

        return <D_posDownload />
    }

    return (
        <>
            {PosDownRender()}
        </>
    )
}

export default MainPosDownload;