import React, { useState, useRef } from "react";
import SVG from 'react-inlinesvg';
import moreBtn from '../../../../assets/svg/moreBtn.svg'
import closeBtn from '../../../../assets/svg/closeBtn.svg'
import styled from 'styled-components';


function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
  }

  return (

    <AccordionStyle>
      <div className="accordionSection">
        <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <div className="aTitle">
            <div className="title">
              <p>{props.date}</p>
              <p className="mainTitle">{props.title}</p>
            </div>
            {setActive === "" ?
              <SVG src={moreBtn} width="10px" height="17px" />
              :
              <SVG src={closeBtn} width="10px" height="17px" />
            }

          </div>

          <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordionContent">
            <div
              className="accordionText"
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          </div>
        </button>

      </div>

    </AccordionStyle>
  )
}

export default Accordion

export const AccordionStyle = styled.div`
  p, a, h1, h2, h3, h4, h5, h6, button, input, label {
    transform: skew(-0.1deg);
    color: #000;
    font-family: 'NanumSquare';
  }
  .accordionContent {
    overflow: hidden;
  }

  /* 제목 */
  .aTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 40px;
    width: 100%;

    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      color: #888;
    }
    .mainTitle{
      margin: 0;
      font-size: 18px;
      font-weight: 900;
      line-height: 36px;
      color: #000!important;
      max-width: 970px;
    }
  }
  .accordionSection button {
    width: 100%;
    margin-bottom: 12px;
    padding: 0;
    outline: none;
    border: none;
    background-color: #fff;
    border: 1px solid #ECEEF2;  
    text-align: left;
    border-radius: 4px;
  }
  .accordionSection button.active {
    background-color: #F8F9FA;
  }

  /* 내용 */
  .accordionText  {
    font-family: 'NanumSquare';
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: #000;
    background-color: #F8F9FA;
    padding: 0px 40px 26px 40px;
  }
  .accordionContent {
    width: 100%;
    white-space: pre-line;
    word-break: keep-all;
    margin: 0;
  }


   
`