import React, { useState, useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Table, DatePicker } from 'antd';
import ReactExport from "react-data-export";
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import manual from '../../../assets/svg/manual.svg';
import addressSearch from '../../../assets/svg/addressSearch.svg';
import download from '../../../assets/svg/download-cloud.svg';

const defaultFormat = "YYYY/MM/DD";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const { RangePicker } = DatePicker;

const Payment = ({ oProps, parentList }) => {
    const [isExcel, setExcel] = useState(false);
    const [eList, setEList] = useState([]);

    const [isLoading, setLoading] = useState(true);

    const [fromDate, setFromDate] = useState(moment().add( -1, "months").format(defaultFormat));
    const [toDate, setToDate] = useState(moment().add( 0, "months").format(defaultFormat));
    const [sList, setList] = useState([]);

    const [sPagination, setPagination] = useState({
        current: 1,
        pageSize: 100,
    });

    const [columns] = useState([
        {
            title: '매장명',
            dataIndex: 'storeNm',
            key: 'storeNm',
            width: "30%",
        },
        {
            title: '총주문건수',
            dataIndex: 'total',
            key: 'total',
            width: "15%",
        },
        {
            title: '완료건수',
            dataIndex: 'order',
            key: 'order',
            width: "15%",
        },
        {
            title: '취소건수',
            dataIndex: 'cancel',
            key: 'cancel',
            width: "15%",
        },
        {
            title: '총 거래금액',
            dataIndex: 'totalPay',
            key: 'totalPay',
            width: "25%",
        }
    ]);

    const searchList = async () => {
        setLoading(true);
        getList(fromDate,toDate);
    }

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    }

    const onOperatingDate = (value, dateString) => {
        if(dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== ""){
            if(fromDate === moment().format(defaultFormat)){
                setFromDate(dateString[0]);
            } else {
                setFromDate(dateString[0]);
                setToDate(dateString[1]);
            }
        } else {
            setFromDate(moment().format(defaultFormat));
            setToDate(moment().add(1, 'days').format(defaultFormat));
        }
    }

    const getList = async (iIndex,aIndex) => {
        let from_Date = moment().add( -1, "months").format(defaultFormat);
        let to_Date = moment().add( 0, "months").format(defaultFormat);

        if(iIndex !== undefined && iIndex != null){
            from_Date = iIndex;
        }
        if(aIndex !== undefined && aIndex != null){
            to_Date = aIndex;
        }
        const oData = {
            sList: parentList,
            fromDate: from_Date,
            toDate: to_Date,
        }

        const oResponse = await oProps.oManager.fDoAxios(`/sales/calculate/listing/v2`, "post", null, oData);
        if(oResponse !== undefined){
            setList(oResponse);
            if(oResponse.eList !== undefined && oResponse.eList !== null && oResponse.eList.length > 0){
                setExcel(true);
                setEList(oResponse.eList);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        getList();
    },[]);

    return (
        <Style>
            <div className="container">
                <div className="title_box">
                    <h2>매출 조회</h2>
                    <div className="tip">
                        <SVG src={manual} width="18px" height="18px" />
                        <span><strong>매출내역</strong>을 날짜별로<br />확인이 가능합니다.</span>
                    </div>
                </div>
                {isLoading ?
                    <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center"}}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                    </div>
                :
                <>
                    <div className="searching_line">
                        <div style={{width: "75%"}}>
                            <div className="search_date">
                                <RangePicker 
                                    bordered={false} 
                                    className="date_picker"
                                    style={{marginLeft: "5%",fontSize: "0rem", width: "90%"}}
                                    format={defaultFormat}
                                    value={[moment(fromDate, defaultFormat),moment(toDate, defaultFormat)]} 
                                    onChange={onOperatingDate}
                                />
                            </div>
                        </div>
                        <div style={{width: isExcel ? "15%" : "25%"}}>
                            <div className="search_bar" onClick={searchList} >
                                <SVG src={addressSearch} />
                                <p>조회</p>
                            </div>
                        </div>
                        {isExcel &&
                            <div style={{width: "8%"}}>
                                <div className="cloud">
                                    <ExcelFile 
                                        filename={`스루-부가가치세 신고자료`} 
                                        element={
                                            <SVG src={download} />
                                        }
                                    >
                                        <ExcelSheet dataSet={eList} name="요약"/>
                                    </ExcelFile>
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{height: sList.length > 0 ? null : 500}}>
                        <Table 
                            columns={columns} 
                            dataSource={sList} 
                            bordered
                            pagination={sPagination} 
                            onChange={handleTableChange} 
                        />
                    </div>
                </>
                }
            </div>
        </Style>
    )
}

export default Payment;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  

    .title_box {
        display: flex;
        align-items: center;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 10vw;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }
    }

    .container {
        width: 42vw; 
        padding: 32px;
        margin-bottom: 100px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
    }

    .title_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3vh;
        width: 100%;

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }
    }
    .last_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 8vh;
        padding-bottom: 1%;
        border-bottom: 1px solid #dfdfdf;
        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 24px;
            color: #617BE3;
        }

        .today_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
            
        }

        .yesterday_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
        }
    }

    .searching_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;
    } 

    .cloud {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        min-height: 5vh;
        border : 1px solid #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            color: #617BE3;
            text-align: center;
        }
        svg {
            width: 18px;
            height: 18px;
        }
    }

    .search_bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        min-height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            color: #fff;
            text-align: center;
        }
        svg {

            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }

    .search_selectOption {
        display: flex;
        align-items: center;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
        font-size: 0.8rem;
    }

    .search_date {
        display: flex;
        align-items: center;
        margin-right: 5%;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .data_box {
        margin-top: 2%;
    }

    .nodata_box {
        margin-top: 2%;
        height: 50vh;
    }
    
    .category_title {
        height: 70px;
        margin-top: 30px;
        margin-bottom: 30px;

        input {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .address input {
            width: 90%;
        }
        .address_button {
            cursor: pointer;
            margin-top: 2%;
            width: 12%;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 1px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        textarea {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }

`


