import React, { useState, useEffect, useRef, Fragment } from 'react';
import Loader from "react-loader-spinner";
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { BrowserView, isMobile, MobileView, osName } from "react-device-detect";
import { message } from 'antd';
import "antd/dist/antd.css";

import closeBtn from '../../../../assets/svg/closeIcon.svg';
import Cookies from 'js-cookie'
let checkPayment;
let checkTime;

const CommercialCart = ({ iProps, iCartList, fndeleteCartItem, iMyPoint, aPoint, aPointWon, fnComplete }) => {
    const [isLoading, setLoading] = useState(false);

    const [picketNotice, setPicketNotice] = useState(false);

    const [myPointAmountWon, setMyPointAmountWon] = useState("0");
    const [myPointChargedAmountWon, setMyPointChargedAmountWon] = useState("0");
    const [productAmountWon, setProductAmountWon] = useState("0");
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [paymentAmountWon, setPaymentAmountWon] = useState("0");
    const [deliverAmount, setDeliverAmount] = useState(0);
    // const [cartList, setcartList] = useState([]);

    const priceToString = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const pointCheck = async (sIndex) => {
        let result = {
            tempPoint: parseInt(sIndex) - 15000,
            tempPointCharged: 0,
            tempPay: 15000
        }

        if ((parseInt(iMyPoint) - 15000) < parseInt(sIndex)) {
            result.tempPay = parseInt(sIndex) - (parseInt(sIndex) - 15000);
        }

        return result;
    }

    const pointSumCheck = async (sIndex) => {
        let totalPay = parseInt(sIndex);
        let result = {
            tempPoint: 0,
            tempPointCharged: 0,
            tempPay: 0
        }

        if (parseInt(iMyPoint) >= 15000) {
            if (totalPay >= (parseInt(iMyPoint) + 15000)) {
                result.tempPoint = parseInt(iMyPoint);
                result.tempPointCharged = totalPay - parseInt(iMyPoint);
                result.tempPay = totalPay - (parseInt(iMyPoint) + (totalPay - parseInt(iMyPoint)));
            } else {
                if (parseInt(aPoint) >= (totalPay - 15000)) {
                    result.tempPoint = 15000;
                    result.tempPointCharged = totalPay - 15000;
                    result.tempPay = totalPay - (15000 + (totalPay - 15000));
                } else {
                    if (parseInt(iMyPoint) >= parseInt(totalPay)) {
                        const pResult = await pointCheck(totalPay);
                        result.tempPoint = pResult.tempPoint;
                        result.tempPointCharged = pResult.tempPointCharged;
                        result.tempPay = pResult.tempPay;
                    } else {
                        result.tempPoint = parseInt(iMyPoint) - 15000;
                        if (parseInt(aPoint) >= (totalPay - (parseInt(iMyPoint) - 15000))) {
                            result.tempPointCharged = totalPay - (parseInt(iMyPoint) - 15000);
                            result.tempPay = totalPay - ((parseInt(iMyPoint) - 15000) + (totalPay - (parseInt(iMyPoint) - 15000)));
                        } else {
                            result.tempPointCharged = parseInt(aPoint);
                            result.tempPay = totalPay - (parseInt(aPoint) + (parseInt(iMyPoint) - 15000));
                        }
                    }
                }
            }
        } else {
            result.tempPoint = parseInt(iMyPoint);
            result.tempPointCharged = totalPay - parseInt(iMyPoint);
            result.tempPay = totalPay - (parseInt(iMyPoint) + (totalPay - parseInt(iMyPoint)));
        }
        return result;
    }

    const calcaulateCheck = async (sIndex) => {
        let totalPay = parseInt(sIndex);
        let result = {
            tempPoint: 0,
            tempPointCharged: 0,
            tempPay: 0
        }
        if ((parseInt(iMyPoint) + 15000) >= totalPay) {
            result.tempPoint = parseInt(totalPay) - 15000;
            result.tempPointCharged = parseInt(aPoint);
            result.tempPay = totalPay - ((parseInt(totalPay) - 15000) + parseInt(aPoint));
        } else {
            result.tempPoint = parseInt(iMyPoint);
            result.tempPointCharged = parseInt(aPoint);
            result.tempPay = totalPay - (parseInt(iMyPoint) + parseInt(aPoint));
        }

        return result;
    }

    const calculateCart = async (sIndex) => {
        let temp = 0;
        let tempPoint = 0;
        let tempPointWon = 0;
        let tempPointCharged = 0;
        let tempPointWonCharged = 0;
        let tempCartWon = 0;
        let tempPay = 0;
        let tempPayWon = 0;
        let tempPicket = false;
        let deliver = 0;
        if (sIndex.length > 0) {
            for await (const iterator of sIndex) {
                if (iterator.param === "picket") {
                    tempPicket = true;
                }
                temp += parseInt(iterator.price);
                tempPay += parseInt(iterator.deliver_price);
                deliver += parseInt(iterator.deliver_price);
            }
        }

        setDeliverAmount(deliver)
        if (tempPicket) {
            if ((parseInt(iMyPoint) + parseInt(aPoint)) >= parseInt(temp)) {
                const pResult = await pointSumCheck(temp);
                tempPoint = pResult.tempPoint;
                tempPointCharged = pResult.tempPointCharged;
                tempPay += pResult.tempPay;
            } else if (parseInt(iMyPoint) >= parseInt(temp)) {
                const pResult = await pointCheck(temp);
                tempPoint = pResult.tempPoint;
                tempPointCharged = pResult.tempPointCharged;
                tempPay += pResult.tempPay;
            } else if (parseInt(aPoint) >= parseInt(temp)) {
                tempPointCharged = parseInt(temp);
            } else {
                const pResult = await calcaulateCheck(temp);
                tempPoint = pResult.tempPoint;
                tempPointCharged = pResult.tempPointCharged;
                tempPay += pResult.tempPay;
            }
        } else {
            if (parseInt(iMyPoint) >= parseInt(temp)) {
                tempPoint = parseInt(temp);
            } else if (parseInt(aPoint) >= parseInt(temp)) {
                tempPointCharged = parseInt(temp);
            } else if ((parseInt(iMyPoint) + parseInt(aPoint)) >= parseInt(temp)) {
                tempPoint = parseInt(iMyPoint);
                tempPointCharged = parseInt(temp) - parseInt(iMyPoint);
            } else {
                tempPoint = parseInt(iMyPoint);
                tempPointCharged = parseInt(aPoint);
                tempPay += parseInt(temp) - parseInt(iMyPoint) - parseInt(aPoint);
            }
        }

        if (tempPoint < 0) {
            tempPoint = 0;
        }
        if (tempPointCharged < 0) {
            tempPointCharged = 0;
        }
        if (tempPay < 0) {
            tempPay = 0;
        }

        tempPointWon = await priceToString(tempPoint);
        console.log(tempPointWon)
        tempPointWonCharged = await priceToString(tempPointCharged);
        tempCartWon = await priceToString(temp);
        tempPayWon = await priceToString(tempPay);

        setPicketNotice(tempPicket);
        setMyPointAmountWon(tempPointWon);
        setMyPointChargedAmountWon(tempPointWonCharged);
        setProductAmountWon(tempCartWon);
        setPaymentAmount(tempPay);
        setPaymentAmountWon(tempPayWon);
    }

    const deleteItemList = async (sIndex) => {
        setLoading(true);
        if (fndeleteCartItem !== undefined && typeof fndeleteCartItem === "function") {
            let tempList = Array.from(iCartList).filter((item) => item.param !== sIndex);
            if (!tempList.length) Cookies.remove("cartList")
            await fndeleteCartItem(tempList);
            calculateCart(tempList);

        }
        setLoading(false);
    }

    const payLastStep = async (aIndex) => {
        const oData = {
            store_id: iProps.UserConfigReducer.StoreID,
            orderId: aIndex,
        }
        const oResponse = await iProps.oManager.fDoAxios("/store/commercial/payCommercial/step2", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
                setLoading(false);
                message.success("결제가 완료되었습니다.");
                Cookies.remove("cartList")
                asyncData();
                // window.location.reload()
                if (fnComplete !== undefined && typeof fnComplete === "function") {
                    await fnComplete();
                }
            } else {
                setLoading(false);
                message.error(oResponse.resultMsg);
            }
        }
    }

    const connectionPay = async (sIndex) => {
        const oData = {
            store_id: iProps.UserConfigReducer.StoreID,
            order_id: sIndex,
        }
        const oResponse = await iProps.oManager.fDoAxios("/store/commercial/chargedPoint/check", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse) {
                clearInterval(checkPayment);
                await payLastStep(sIndex);
            }
        }
    }

    const startCheckPay = async (sIndex) => {
        clearInterval(checkPayment);
        checkPayment = setInterval(() => {
            connectionPay(sIndex);
        }, 5000)
    }

    const paymentCommercial = async () => {
        const oData = {
            store_id: iProps.UserConfigReducer.StoreID,
            cartList: iCartList,
            paymentAmount,
            osInfo: "os:" + osName + ",mobile:" + isMobile,
        }
        const oResponse = await iProps.oManager.fDoAxios("/web/store/commercial/payCommercial/step1", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
                message.success(oResponse.resultMsg);
                // window.location.reload()
                asyncData();
                if (fnComplete !== undefined && typeof fnComplete === "function") {
                    await fnComplete();
                    Cookies.remove('cartList')
                }
            } else if (oResponse.resultCd === "0101") {

                let oUserData = iProps.UserConfigReducer;
                if (oUserData && oUserData.isPOSStore === true) {
                    window.open(`${process.env.REACT_APP_TPAY_MOBILE}${oResponse.orderId}?mobile=true`, '_blank', "height=600,width=800,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
                    await startCheckPay(oResponse.orderId);
                    return;
                }

                if (isMobile) {
                    window.open(`${process.env.REACT_APP_TPAY_MOBILE}${oResponse.orderId}?mobile=true`, '_blank', "height=600,width=800,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
                    await startCheckPay(oResponse.orderId);
                } else {
                    const rel = window.open(`${process.env.REACT_APP_TPAY}${oResponse.orderId}`, '_blank', "height=600,width=800,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
                    await startCheckPay(oResponse.orderId);
                    /*
                    window.addEventListener('message', async (res) => {
                        if (res.data !== undefined && res.data !== null) {
                            const jsonData = JSON.parse(res.data);
                            if (jsonData.resultCd === "3001") {
                                rel.close();
                                await payLastStep(oResponse.orderId);
                            } else {
                                rel.close();
                                message.error(jsonData.resultMsg);
                            }
                        }
                    })
                    */
                }
            } else {
                setLoading(false);
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            }
        } else {
            setLoading(false);
        }
    }

    const startComercial = async () => {
        setLoading(true);
        if (iCartList.length > 0) {
            await paymentCommercial();
        } else {
            setLoading(false);
        }
    }

    const asyncData = async () => {
        setLoading(true);
        calculateCart(iCartList);
        // setcartList(iCartList)
        if (checkTime) clearTimeout(checkTime);
        checkTime = setTimeout(() => {
            setLoading(false);
        }, 300);
    }

    useEffect(() => {
        asyncData();
    }, [iCartList.length]);
    useEffect(() => {
        Cookies.set("cartList", JSON.stringify(iCartList))
    }, [iCartList])
    return (
        <Fragment>
            <BrowserView>
                <Style>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <>
                            <div className="totalPage" style={{ marginBottom: "1%" }}>
                                <div className="menuContent">
                                    <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf" }}>
                                        <div className="title">
                                            <h3>장바구니</h3>
                                        </div>
                                    </div>
                                    {iCartList.map((item, index) => {
                                        return (
                                            <div className='commercial_box' key={index}>
                                                <div className='delete_box' onClick={() => deleteItemList(item.param)}>
                                                    <SVG src={closeBtn} width="3vw" height="2vh" />
                                                </div>
                                                <div className='commercial_container'>
                                                    <div className='img_box'>
                                                        <img src={item.img} alt="스루키트 이미지" width="100%" height="100%" />
                                                    </div>
                                                    <div className='commercial_detail'>
                                                        <div className='title'>
                                                            <p>{item.title}</p>
                                                        </div>
                                                        <div className='detail'>
                                                            <p>{(item.exec_period && item.fromDate !== undefined && item.fromDate !== null) ? item.fromDate + "부터 30일동안" : "-"}</p>
                                                        </div>
                                                        <div className='price'>
                                                            <p>{priceToString(parseInt(item.price) + parseInt(item.deliver_price))} 원</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='total_amount'>
                                    <div className="pay_box">
                                        <div className='last_line'>
                                            <div className='box_line'>
                                                <p>총 상품금액</p>
                                                <p style={{ fontSize: "1rem", color: "#191F28", fontWeight: 500 }}>{productAmountWon} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>유상 포인트 사용금액</p>
                                                <p style={{ fontSize: "1rem", color: "#F45552", fontWeight: 500 }}>- {myPointChargedAmountWon} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>무상 포인트 사용금액</p>
                                                <p style={{ fontSize: "1rem", color: "#F45552", fontWeight: 500 }}>- {myPointAmountWon} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>배송비</p>
                                                <p style={{ fontSize: "1rem", color: "#191F28", fontWeight: 500 }}>{priceToString(deliverAmount)} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>결제금액</p>
                                                <p style={{ fontSize: "1rem", color: "#2459C1", fontWeight: 500 }}>{paymentAmountWon} 원</p>
                                            </div>
                                        </div>
                                        <div className='first_line'>
                                            <div className='pay_box' onClick={startComercial}>
                                                <p>{parseInt(paymentAmount) > 0 ? "결제하기" : "광고 사용하기"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Style>
            </BrowserView>
            <MobileView>
                <Style>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <>
                            <div className="totalPage_mob" style={{ marginBottom: "1%" }}>
                                <div className="menuContent">
                                    <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf" }}>
                                        <div className="title">
                                            <h3>장바구니</h3>
                                        </div>
                                    </div>
                                    {iCartList.map((item, index) => {
                                        return (
                                            <div className='commercial_box' key={index}>
                                                <div className='delete_box_mob' onClick={() => deleteItemList(item.param)}>
                                                    <SVG src={closeBtn} width="3vw" height="5vh" />
                                                </div>
                                                <div className='commercial_container'>
                                                    <div className='img_box'>
                                                        <img src={item.img} alt="스루키트 이미지" width="100%" height="100%" />
                                                    </div>
                                                    <div className='commercial_detail'>
                                                        <div className='title'>
                                                            <p>{item.title}</p>
                                                        </div>
                                                        <div className='detail'>
                                                            <p>{(item.exec_period && item.fromDate !== undefined && item.fromDate !== null) ? item.fromDate + "부터 30일동안" : "-"}</p>
                                                        </div>
                                                        <div className='price'>
                                                            <p>{priceToString(parseInt(item.price) + parseInt(item.deliver_price))} 원</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='total_amount'>
                                    <div className="pay_box">
                                        <div className='last_line'>
                                            <div className='box_line'>
                                                <p>총 상품금액</p>
                                                <p style={{ fontSize: "1rem", color: "#191F28", fontWeight: 500 }}>{productAmountWon} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>유상 포인트 사용금액</p>
                                                <p style={{ fontSize: "1rem", color: "#F45552", fontWeight: 500 }}>- {myPointChargedAmountWon} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>무상 포인트 사용금액</p>
                                                <p style={{ fontSize: "1rem", color: "#F45552", fontWeight: 500 }}>- {myPointAmountWon} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>배송비</p>
                                                <p style={{ fontSize: "1rem", color: "#191F28", fontWeight: 500 }}>{priceToString(deliverAmount)} 원</p>
                                            </div>
                                            <div className='box_line'>
                                                <p>결제금액</p>
                                                <p style={{ fontSize: "1rem", color: "#2459C1", fontWeight: 500 }}>{paymentAmountWon} 원</p>
                                            </div>
                                        </div>
                                        <div className='first_line'>
                                            <div className='pay_box' onClick={startComercial}>
                                                <p>{parseInt(paymentAmount) > 0 ? "결제하기" : "광고 사용하기"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Style>

            </MobileView>
        </Fragment>
    )
}

export default CommercialCart;


const Style = styled.div`
    .totalPage {
        width: 42vw!important;
    }
    .totalPage, .totalPage_mob {
        width: 100%;
        margin-top: 5%;
        background-color: #fff;
        border-radius: 4px;
    

        .total_amount {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 30vh;
            background : #fff;

            .pay_box {
                height: 27vh;
                width: 100%;
                border-top: 1px solid #dfdfdf;
                background : #fff;
                justify-content: center;

                .first_line {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 10vh;

                    .pay_box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 6vh;
                        width: 90%;
                        background : #6490E7;
                        cursor: pointer;
                        border-radius: 5px;
                        
                        p {
                            font-size: 0.9rem;
                            font-weight: 600;
                            color: #fff;
                        }
                    }

                    p {
                        font-size: 1rem;
                        font-weight: 600;
                        color: #000;
                    }
                }

                .last_line {
                    justify-content: center;
                    align-items: center;
                    height: 15vh;
                    margin-top: 1vh;

                    .box_line {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 3vh;
                        padding-left: 5%;
                        padding-right: 5%;

                        p {
                            font-size: 0.9rem;
                            font-weight: 500;
                            color: #4E5867;
                        }
                    }
                }
            }
        }

        .menuContent {
            .menu {
                display: flex;
                align-items: center;
                padding: 5% 5% 5% 5%;

                .title {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;

                    h3 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .commercial_box {
                height: 22vh;
                
                .delete_box {
                    margin-bottom: 5px;
                    border: none;
                }
                .delete_box:hover {
                    background: none;
                }
                .delete_box_mob {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-right: 5vw;
                }

                .commercial_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: 13vh;
                    margin-left: 5%;
                    margin-right: 5%;

                    .img_box {
                        width: 30%;
                        height: 13vh;

                        img {
                            height: 100%;
                            width: 100%;
                            border-radius: 5px;
                        }
                    }
                    
                    .commercial_detail {
                        width: 65%;
                        height: 13vh;

                        .title {
                            height: 3vh;
                            color: #000;
                            font-size: 1rem;
                            font-weight: 600;
                        }
                        .detail {
                            display: flex;
                            align-items: center;
                            height: 7vh;
                            color: #666;
                            font-size: 0.7rem;
                            font-weight: 500;
                        }
                        .price {
                            height: 3vh;
                            color: #000;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                    }
                }

                .select_commercial {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 10vh;

                    .button_box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 5vh;
                        width: 90%;
                        background : #E8EFFC;
                        border-radius: 5px;

                        p {
                            font-size: 0.9rem;
                            font-weight: 600;
                            color: #6490E7;
                        }
                    }
                }
            }
        }
    }
`;