import React, { useState, useEffect, useRef, Fragment } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { message, Table } from 'antd';
import moment from 'moment-timezone';
import "antd/dist/antd.css";
import 'moment/locale/ko';

import manual from '../../../../assets/svg/manual.svg';
import { BrowserView, MobileView } from 'react-device-detect';

const ListForm = ({ fnEdit, iSchedule }) => {
    const [schedule, setSchedule] = useState([]);

    const onChangeEdit = async () => {
        if (fnEdit !== undefined && typeof fnEdit === "function") {
            await fnEdit();
        }
    }

    useEffect(() => {
        setSchedule(iSchedule);
    }, [iSchedule]);

    return (
        <Fragment>
            <BrowserView>
                <div className="container">
                    <div className="title_box">
                        <h2>설정한 영업시간을 한눈에 확인해보세요</h2>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span>매장의<strong>영업일</strong>을 등록하거나<br />수정이 가능합니다</span>
                        </div>
                    </div>
                    <div className="category_title" style={{ marginTop: "2%" }}>
                        {schedule.map((item, index) => (
                            <div className={(index === 0 && "weekly_date_top_box") || (index === 6 && "weekly_date_top_box") || (index === 12 && "weekly_date_top_box") || (index === 18 && "weekly_date_top_box") || (index === 23 && "weekly_date_bottom_box") || "weekly_date_box"} key={index}>
                                {item.value.map((data, sIndex) => {
                                    if (data.value === "easy") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#617BE3" }}></div>
                                        )
                                    } else if (data.value === "normal") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#001E62" }}></div>
                                        )
                                    } else if (data.value === "busy") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#DD1212" }}></div>
                                        )
                                    } else if (data.value === "walk") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#16C47E" }}></div>
                                        )
                                    } else {
                                        return (
                                            <div key={sIndex} className="operation_color_box"></div>
                                        )
                                    }
                                })}
                            </div>
                        ))}
                        <div className="weekly_date_box">
                            {dayList.map((item, index) => (
                                <div className="date_name_box" key={index}>
                                    <p>{item.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="category_title" style={{ marginTop: "2%" }}>
                        <div className="time_separate_line">
                            <div className="light_box" style={{ background: "#617BE3" }} />
                            <p>여유</p>
                            <div className="light_box" style={{ background: "#001E62" }} />
                            <p>보통</p>
                            <div className="light_box" style={{ background: "#DD1212" }} />
                            <p>바쁨</p>
                            <div className="light_box" style={{ background: "#16C47E" }} />
                            <p>워크스루</p>
                        </div>
                    </div>
                    <div className="edit_box" onClick={onChangeEdit}>
                        <p>변경하기</p>
                    </div>

                </div>
            </BrowserView>
            <MobileView>
                <div className="category_title_line">
                    <div className="title_box_container">
                        <div className="title_box">
                            <p className="title_line">매장 영업일</p>
                        </div>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span>매장의<strong>영업일</strong>을 등록하거나<br />수정이 가능합니다</span>
                        </div>
                    </div>
                    <div className="category_title" style={{ marginTop: "2%" }}>
                        {schedule.map((item, index) => (
                            <div className={(index === 0 && "weekly_date_top_box") || (index === 6 && "weekly_date_top_box") || (index === 12 && "weekly_date_top_box") || (index === 18 && "weekly_date_top_box") || (index === 23 && "weekly_date_bottom_box") || "weekly_date_box"} key={index}>
                                {item.value.map((data, sIndex) => {
                                    if (data.value === "easy") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#617BE3" }}></div>
                                        )
                                    } else if (data.value === "normal") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#001E62" }}></div>
                                        )
                                    } else if (data.value === "busy") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#DD1212" }}></div>
                                        )
                                    } else if (data.value === "walk") {
                                        return (
                                            <div key={sIndex} className="operation_color_box" style={{ background: "#16C47E" }}></div>
                                        )
                                    } else {
                                        return (
                                            <div key={sIndex} className="operation_color_box"></div>
                                        )
                                    }
                                })}
                            </div>
                        ))}
                        <div className="weekly_date_box">
                            {dayList.map((item, index) => (
                                <div className="date_name_box" key={index}>
                                    <p>{item.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="category_title" style={{ marginTop: "2%" }}>
                        <div className="time_separate_line">
                            <div className="light_box" style={{ background: "#617BE3" }} />
                            <p>여유</p>
                            <div className="light_box" style={{ background: "#001E62" }} />
                            <p>보통</p>
                            <div className="light_box" style={{ background: "#DD1212" }} />
                            <p>바쁨</p>
                            <div className="light_box" style={{ background: "#16C47E" }} />
                            <p>워크스루</p>
                        </div>
                    </div>
                    <div className="edit_box" onClick={onChangeEdit}>
                        <p>변경하기</p>
                    </div>

                </div>

            </MobileView>
        </Fragment>
    )
}

export default ListForm;

const minuteList = [];
for (let i = 0; i < 24; i++) {
    minuteList.push({
        key: i,
    });
};
const dayList = [
    { name: "월" },
    { name: "화" },
    { name: "수" },
    { name: "목" },
    { name: "금" },
    { name: "토" },
    { name: "일" },
]