import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import '../../../../assets/fonts/font.css';
import ModalCeoAnswerReview from '../../../modal/review/AnswerReview';
import Pagination from '../pagination';
import DatePicker from "react-datepicker";
import { confirm } from "react-confirm-box";
import moment from 'moment';
import 'moment/locale/ko';
import "react-datepicker/dist/react-datepicker.css";
import { BrowserView, MobileView } from 'react-device-detect';

const Review = ({ sProps, set_iStoreUnReviewCount, set_start_Date, set_end_Date }) => {
    const now = new Date();
    const itemsPerPage = 10;
    const [reviewData, set_reviewData] = useState([]);
    const [completeReviewData, setCompleteReviewData] = useState([]);
    const [allReviewData, setAllReviewData] = useState([]);

    const [currentTab, clickTab] = useState(0);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalTime, setModalTime] = useState(Date.now());
    const [currentData, setCurrentData] = useState({})
    const [startDate, setStartDate] = useState(new Date(now.setMonth(now.getMonth() - 1)));
    const [endDate, setEndDate] = useState(new Date());
    const [answerText, set_answerText] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    console.log('최종', answerText)

    //페이지네이션
    const limit = 10;
    const [page, setPage] = useState(1);
    const offset = (page - 1) * limit;

    // 날짜format
    const todayTime = moment().format('YYYYMMDD');
    const yesterdayTime = moment().subtract(1, 'day').format('YYYYMMDD');

    useEffect(() => {
        setModalTime(Date.now());
        set_iStoreUnReviewCount(reviewData.length)
    }, [modalIsOpen]);

    useEffect(() => {
        getReview(startDate, endDate);
    }, []);

    // 모달
    function openModal(data) {
        setIsOpen(true);
        setCurrentData(data)
        console.log('dddd', currentData)
    }

    // 리뷰삭제
    const removeReviewButton = async (data) => {
        const options = {
            render: (message, onConfirm, onCancel) => {
                return (
                    <>
                        <BrowserView>
                            <div
                                style={{
                                    position: 'fixed',
                                    background: '#fff',
                                    padding: '26px 32px',
                                    borderRadius: '8px',
                                    left: '38%'
                                }}
                            >
                                <h1 style={{ fontSize: '20px', fontWeight: '700', marginBottom: '40px' }}>{message}</h1>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <button onClick={onCancel} style={{ marginRight: '18px', fontSize: '16px', fontWeight: '600', color: 'gray', background: 'none', border: 'none', padding: '8px 12px 6px 12px', outline: 'none' }}> 취소 </button>
                                    <button onClick={onConfirm} style={{ fontSize: '16px', fontWeight: '600', color: '#fff', background: '#6490E7', border: 'none', outline: 'none', padding: '8px 12px 6px 12px', borderRadius: '8px' }}> 삭제하기 </button>
                                </div>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div
                                style={{
                                    position: 'fixed',
                                    background: '#fff',
                                    padding: '26px 32px',
                                    borderRadius: '8px',
                                    left: '5%',
                                    right: '5%'
                                }}
                            >
                                <h1 style={{ fontSize: '18px', fontWeight: '700', marginBottom: '40px', textAlign: 'center', lineHeight: '24px', marginBottom: '20px' }}>작성하신 답변을<br /> 삭제하시겠습니까?</h1>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <button onClick={onCancel} style={{ marginRight: '18px', fontSize: '16px', fontWeight: '600', color: 'gray', background: 'none', border: 'none', padding: '8px 12px 6px 12px', outline: 'none' }}> 취소 </button>
                                    <button onClick={onConfirm} style={{ fontSize: '16px', fontWeight: '600', color: '#fff', background: '#6490E7', border: 'none', outline: 'none', padding: '8px 12px 6px 12px', borderRadius: '8px' }}> 삭제하기 </button>
                                </div>
                            </div>
                        </MobileView>
                    </>

                );
            }
        }
        const tmp_review = Array.from(reviewData)
        const tmp_reviewComplete = Array.from(completeReviewData)
        const tmp_data = Object.assign({}, data)
        const tmp_all_review = Array.from(allReviewData)
        console.log('rrrrr=>>rr-->', allReviewData)

        tmp_data.ceoComment = answerText;
        tmp_data.ceoReviewTime = null;
        tmp_data.have_store_comment = 0

        const result = await confirm("작성하신 답변을 삭제하시겠습니까? ", options);
        if (result) {
            try {
                const oParams = {
                    review_id: tmp_data.reviewId,
                    iUserId: tmp_data.userId
                }
                const oResponse = await sProps.oManager.fDoAxios("/store/review/deleteStoreReview", "post", null, oParams);

                if (oResponse != undefined && oResponse != null) {
                    if (oResponse.success) {
                        console.log("사장님 답변 삭제를 완료했습니다.")
                        set_answerText('');
                        set_reviewData([tmp_data].concat(tmp_review.filter(e => (e.reviewId !== data.reviewId))))
                        setCompleteReviewData(tmp_reviewComplete.filter((e, i) => e.reviewId !== data.reviewId))
                        setAllReviewData(tmp_all_review.map(e => (e.reviewId === data.reviewId) ? tmp_data : e))

                    }
                    else {
                        console.log("삭제실패 사유:", oResponse.message);
                    }
                }
            } catch (err) {
                console.log("err-->", err);
            }
            set_iStoreUnReviewCount(reviewData.length + 1)
            // return;
        }
        console.log("You click No!");
    }

    // 리뷰 등록/수정
    const onSubmit = async (data, type) => {
        const tmp_review = Array.from(reviewData)
        const tmp_reviewComplete = Array.from(completeReviewData)
        const tmp_all_review = Array.from(allReviewData)
        const tmp_data = Object.assign({}, data)

        tmp_data.ceoReviewTime = Date.now();
        tmp_data.ceoComment = answerText;

        try {
            const oParams = {
                review_id: tmp_data.reviewId,
                user_id: tmp_data.masking_nick,
                store_comment: answerText
            }
            const oResponse = await sProps.oManager.fDoAxios("/store/review/updateStoreReview", "post", null, oParams);

            if (oResponse != undefined && oResponse != null) {
                if (oResponse.success) {
                    console.log("등록성공")
                    set_reviewData(tmp_review.filter((e, i) => e.reviewId !== data.reviewId))
                    setCompleteReviewData([tmp_data].concat(tmp_reviewComplete.filter(e => (e.reviewId !== data.reviewId))))
                    setAllReviewData(tmp_all_review.map(e => (e.reviewId === data.reviewId) ? tmp_data : e))
                    tmp_data.have_store_comment = 1

                    setIsOpen(false);

                    console.log('tmp_review', reviewData)
                }
                else {
                    console.log("등록실패 사유:", oResponse.message);
                }
            }

        } catch (err) {
            console.log("err-->", err);
        }
    }

    // 리뷰정보
    const getReview = async (startDate, endDate) => {
        let aData = [];
        let aDataReview = [];
        let aDataReviewStore = [];
        let sId = sProps.UserConfigReducer.StoreID;
        set_start_Date(startDate);
        set_end_Date(endDate);

        try {
            const oParams = {
                store_id: sId,
                start_date: startDate,
                end_date: endDate
            }
            const oResponse = await sProps.oManager.fDoAxios("/store/review/getReviewByDate", "post", null, oParams);
            if (oResponse != undefined && oResponse != false) {

                for (let i = 0; i < oResponse.reviews.length; i++) {
                    const reviewId = oResponse.reviews[i].review_id;
                    const userId = oResponse.reviews[i].user_id;
                    const created_at = oResponse.reviews[i].created_at;
                    const review_content = oResponse.reviews[i].review_content;
                    const store_updated_at = oResponse.reviews[i].store_updated_at;
                    const store_comment = oResponse.reviews[i].store_comment;
                    const view_status = oResponse.reviews[i].view_status;
                    const img_list = oResponse.reviews[i].img_list;
                    const have_store_comment = oResponse.reviews[i].have_store_comment;
                    const masking_nick = oResponse.reviews[i].masking_nick;

                    const oParams = {
                        reviewId: reviewId,
                        userId: userId,
                        createdTime: created_at,
                        reviewContent: review_content,
                        ceoReviewTime: store_updated_at,
                        ceoComment: store_comment,
                        viewStatus: view_status,
                        imgList: img_list,
                        masking_nick:masking_nick,
                        have_store_comment: have_store_comment
                    };

                    aData.push(oParams)

                    if (have_store_comment === 0) {
                        aDataReview.push(oParams)
                    }
                    else if (have_store_comment === 1) {
                        aDataReviewStore.push(oParams);
                    }
                }

                // 미답변
                set_reviewData(aDataReview);
                // 답변
                setCompleteReviewData(aDataReviewStore);
                //전체
                setAllReviewData(aData);
                //미답변 개수 
                set_iStoreUnReviewCount(aDataReview.length)
            }
        } catch (err) {
            console.log("err-->", err);
        }
    }

    // 리뷰tab제목
    const reviewTabTitle = [
        { name: '전체(' + allReviewData.length + ')' },
        { name: '답변완료(' + completeReviewData.length + ')' },
        { name: '미답변(' + reviewData.length + ')' },
    ];
    const selectReviewHandler = (index) => {
        clickTab(index);
        setPage(1);
    };

    // 리뷰내용확인
    const ReviewTabContent = () => {
        return (
            <div>
                {currentTab === 0 ?
                    <div>
                        <div className="review_tab_content">
                            {allReviewData.slice(offset, offset + limit).map((data, index, array) =>
                                <div className='review_view'>
                                    <h2>{data.masking_nick}</h2>
                                    {
                                        moment(data.createdTime).format('YYYYMMDD') === todayTime ? <h3>오늘</h3>

                                            :
                                            moment(data.createdTime).format('YYYYMMDD') === yesterdayTime ? <h3>어제</h3>
                                                :
                                                <h3>{moment(data.createdTime).format('YYYY-MM-DD HH:mm:ss')}</h3>
                                    }
                                    {data.viewStatus === 0 &&
                                        <p>{data.reviewContent}</p>
                                    }
                                    {data.viewStatus === 1 &&
                                        <div>
                                            <p className='ceo_view'>사장님에게만 보이는 리뷰내용입니다.</p>
                                            <p>{data.reviewContent}</p>
                                        </div>
                                    }
                                    {data.imgList.length === 1 &&
                                        <div className='image_list'>
                                            <img src={data.imgList[0]} alt="리뷰사진1" />
                                        </div>
                                    }
                                    {data.imgList.length === 2 &&
                                        <div className='image_list'>
                                            <img src={data.imgList[0]} alt="리뷰사진1" />
                                            <img src={data.imgList[1]} alt="리뷰사진2" />
                                        </div>
                                    }
                                    {data.imgList.length === 3 &&
                                        <div className='image_list'>
                                            <img src={data.imgList[0]} alt="리뷰사진1" />
                                            <img src={data.imgList[1]} alt="리뷰사진2" />
                                            <img src={data.imgList[2]} alt="리뷰사진3" />
                                        </div>
                                    }
                                    {data.have_store_comment === 0 &&
                                        <div>
                                            <button onClick={() => openModal(data)}>사장님 답변 추가하기</button>
                                        </div>
                                    }
                                    {data.have_store_comment !== 0 &&
                                        <div className="ceo_review">
                                            <div className="title">
                                                <h2>사장님</h2>
                                                {
                                                    moment(data.ceoReviewTime).format('YYYYMMDD') === todayTime ? <h3 style={{ marginLeft: '8px' }}>오늘</h3>
                                                        :
                                                        moment(data.ceoReviewTime).format('YYYYMMDD') === yesterdayTime ? <h3 style={{ marginLeft: '8px' }}>어제</h3>
                                                            :
                                                            <h3 style={{ marginLeft: '8px' }}>{moment(data.ceoReviewTime).format('YYYY-MM-DD HH:mm:ss')}</h3>
                                                }
                                            </div>
                                            <p>{data.ceoComment}</p>
                                            <div className="ceo_review_button">
                                                <button className='remove_review' onClick={() => removeReviewButton(data)}><p>삭제</p></button>
                                                <button className='change_review' onClick={() => openModal(data)}><p>수정</p></button>
                                            </div>
                                        </div>
                                    }

                                </div>
                            )}
                        </div>
                        <Pagination
                            total={allReviewData.length}
                            limit={limit}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                    : undefined
                }
                {currentTab === 1 ?
                    <div>
                        <div className="review_tab_content">
                            {completeReviewData.slice(offset, offset + limit).map((data, index, array) =>
                                <div>
                                    {data.ceoReviewTime !== null &&
                                        <div className='review_view'>
                                            <h2>{data.masking_nick}</h2>
                                            {
                                                moment(data.createdTime).format('YYYYMMDD') === todayTime ? <div>오늘</div>
                                                    :
                                                    moment(data.createdTime).format('YYYYMMDD') === yesterdayTime ? <div>어제</div>
                                                        :
                                                        <h3>{moment(data.createdTime).format('YYYY-MM-DD HH:mm:ss')}</h3>
                                            }
                                            {data.viewStatus === 0 &&
                                                <p>{data.reviewContent}</p>
                                            }
                                            {data.viewStatus === 1 &&
                                                <div>
                                                    <p className='ceo_view'>사장님에게만 보이는 리뷰내용입니다.</p>
                                                    <p>{data.reviewContent}</p>
                                                </div>
                                            }
                                            {data.imgList.length === 1 &&
                                                <div className='image_list'>
                                                    <img src={data.imgList[0]} alt="리뷰사진1" />
                                                </div>
                                            }
                                            {data.imgList.length === 2 &&
                                                <div className='image_list'>
                                                    <img src={data.imgList[0]} alt="리뷰사진1" />
                                                    <img src={data.imgList[1]} alt="리뷰사진2" />
                                                </div>
                                            }
                                            {data.imgList.length === 3 &&
                                                <div className='image_list'>
                                                    <img src={data.imgList[0]} alt="리뷰사진1" />
                                                    <img src={data.imgList[1]} alt="리뷰사진2" />
                                                    <img src={data.imgList[2]} alt="리뷰사진3" />
                                                </div>
                                            }
                                            <div className="ceo_review">
                                                <div className="title">
                                                    <h2>사장님</h2>
                                                    {
                                                        moment(data.ceoReviewTime).format('YYYYMMDD') === todayTime ? <h3 style={{ marginLeft: '8px' }}>오늘</h3>
                                                            :
                                                            moment(data.ceoReviewTime).format('YYYYMMDD') === yesterdayTime ? <h3 style={{ marginLeft: '8px' }}>어제</h3>
                                                                :
                                                                <h3 style={{ marginLeft: '8px' }}>{moment(data.ceoReviewTime).format('YYYY-MM-DD HH:mm:ss')}</h3>
                                                    }
                                                </div>
                                                <p>{data.ceoComment}</p>
                                                <div className="ceo_review_button">
                                                    <button className='remove_review' onClick={() => removeReviewButton(data)}><p>삭제</p></button>
                                                    <button className='change_review' onClick={() => openModal(data)}><p>수정</p></button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            )}

                        </div>
                        <Pagination
                            total={completeReviewData.length}
                            limit={limit}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                    : undefined}
                {currentTab === 2 ?
                    <div>
                        <div className="review_tab_content">
                            {reviewData.slice(itemsPerPage * currentPage, itemsPerPage * (currentPage + 1)).map((data, index, array) =>
                                <div className='review_view'>
                                    <h2>{data.masking_nick}</h2>
                                    {
                                        moment(data.createdTime).format('YYYYMMDD') === todayTime ? <div>오늘</div>
                                            :
                                            moment(data.createdTime).format('YYYYMMDD') === yesterdayTime ? <div>어제</div>
                                                :
                                                <h3>{moment(data.createdTime).format('YYYY-MM-DD HH:mm:ss')}</h3>
                                    }
                                    {data.viewStatus === 0 &&
                                        <p>{data.reviewContent}</p>
                                    }
                                    {data.viewStatus === 1 &&
                                        <div>
                                            <p className='ceo_view'>사장님에게만 보이는 리뷰내용입니다.</p>
                                            <p>{data.reviewContent}</p>
                                        </div>
                                    }
                                    {data.imgList.length === 1  &&
                                        <div className='image_list'>
                                            <img src={data.imgList[0]} alt="리뷰사진1" />
                                        </div>
                                    }
                                    {data.imgList.length === 2  &&
                                        <div className='image_list'>
                                            <img src={data.imgList[0]} alt="리뷰사진1" />
                                            <img src={data.imgList[1]} alt="리뷰사진2" />
                                        </div>
                                    }
                                    {data.imgList.length === 3  &&
                                        <div className='image_list'>
                                            <img src={data.imgList[0]} alt="리뷰사진1" />
                                            <img src={data.imgList[1]} alt="리뷰사진2" />
                                            <img src={data.imgList[2]} alt="리뷰사진3" />
                                        </div>
                                    }
                                    <button onClick={() => openModal(data)}>사장님 답변 추가하기</button>
                                </div>
                            )}

                        </div>
                        <Pagination
                            total={reviewData.length}
                            limit={limit}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                    : undefined}
            </div>

        )
    }

    return (
        <>
            <BrowserView>
                <ReviewStyle>
                    <div className="wrapper">
                        <div className="tab_container">
                            <div className="tab">
                                <h2>리뷰관리</h2>
                            </div>
                        </div>
                        <div className="content_container">
                            <div className="review">
                                <div className="date_search">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat="yyyy.MM.dd"
                                        dateFormatCalendar="yyyy년 MM월"
                                        locale="ko"
                                    />
                                    <span>~</span>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat="yyyy.MM.dd"
                                        dateFormatCalendar="yyyy년 MM월"
                                        locale="ko"
                                    />
                                    <button className='search_button' onClick={() => getReview(startDate, endDate)}>조회</button>
                                </div>
                            </div>
                            <div>
                                <div className="review_tab">
                                    {reviewTabTitle.map((el, index) => (
                                        <div className={index === currentTab ? "submenu focus" : "submenu"} onClick={() => selectReviewHandler(index)}>
                                            {index === 2 &&
                                                <div className={index === currentTab ? "clicked" : "unClicked"}
                                                    style={{ display: 'flex', justifyContent: 'center' }}>
                                                    미답변<span style={{ color: 'red' }}>({reviewData.length})</span>
                                                </div>
                                            }
                                            {index !== 2 && <p>{el.name}</p>}
                                        </div>
                                    ))}
                                </div>
                                {((reviewData.length || completeReviewData.length) && currentTab === 0) || ((completeReviewData.length) && currentTab === 1) || ((reviewData.length) && currentTab === 2)
                                    ?
                                    <div>
                                        <ReviewTabContent />
                                    </div>
                                    :
                                    <div className="review_tab_content">
                                        <div className="non_review">
                                            <h2>조회하신 기간에 확인된 리뷰가 없습니다</h2>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <ModalCeoAnswerReview isVisible={modalIsOpen} set_answerText={set_answerText} setIsOpen={setIsOpen} key={modalTime} onSubmit={onSubmit} currentData={currentData} message={answerText} type={currentTab} set_iStoreUnReviewCount={set_iStoreUnReviewCount} />
                </ReviewStyle>
            </BrowserView>
            <MobileView>
                <MReviewStyle>
                    <div className="wrapper">
                        <div className="tab">
                            <h2>리뷰관리</h2>
                        </div>
                        <div className="content_container">
                            <div className="date_search">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="yyyy.MM.dd"
                                    dateFormatCalendar="yyyy년 MM월"
                                    locale="ko"
                                />
                                <span>~</span>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    dateFormat="yyyy.MM.dd"
                                    dateFormatCalendar="yyyy년 MM월"
                                    locale="ko"
                                />
                                <button className='search_button' onClick={() => getReview(startDate, endDate)} >조회</button>
                            </div>
                            <div>
                                <div className="review_tab">
                                    {reviewTabTitle.map((el, index) => (
                                        <div className={index === currentTab ? "submenu focus" : "submenu"} onClick={() => selectReviewHandler(index)}>
                                            {index === 2 &&
                                                <div className={index === currentTab ? "clicked" : "unClicked"}
                                                    style={{ display: 'flex', justifyContent: 'center' }}>
                                                    미답변<span style={{ color: 'red' }}>({reviewData.length})</span>
                                                </div>
                                            }
                                            {index !== 2 && <p>{el.name}</p>}
                                        </div>
                                    ))}
                                </div>
                                {((reviewData.length || completeReviewData.length) && currentTab === 0) || ((completeReviewData.length) && currentTab === 1) || ((reviewData.length) && currentTab === 2)
                                    ?
                                    <div>
                                        <ReviewTabContent />
                                    </div>
                                    :
                                    <div className="review_tab_content">
                                        <div className="non_review">
                                            <h2>조회하신 기간에 확인된 리뷰가 없습니다</h2>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <ModalCeoAnswerReview isVisible={modalIsOpen} set_answerText={set_answerText} setIsOpen={setIsOpen} key={modalTime} onSubmit={onSubmit} currentData={currentData} message={answerText} type={currentTab} set_iStoreUnReviewCount={set_iStoreUnReviewCount} />
                </MReviewStyle>
            </MobileView>
        </>
    )
}

export default Review;

const ReviewStyle = styled.div`  
    /* 공통 css */
    *, h1, h2, h3, h4, ul, li, p, input, a, button {
        font-family: ' Pretendard';
        color: #000;
        list-style: none;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box; 
    }  
    .wrapper { 
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
        margin-bottom: 50px;
    }

    /* tab */
    .tab {
        width: 42vw;
        padding: 24px 44px; 
        margin: 3px 0 24px 0;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.08);

        h2 {
            font-size: 16px;
            font-weight: 700;
            color: #6490e7;
        }
    }

    /* reveiw_content */
    .content_container {
        width: 42vw; 
        padding: 24px;
        background: #fff;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
    }

    /* 날짜검색 */
    .date_search {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        
        span {
            margin: 0 10px;
        }
        .react-datepicker-, .react-datepicker-wrapper, .react-datepicker__input-container {
            all: unset;
            text-align: center;
            max-width: 150px;
        }
        input {
            all: unset;
            width: 100%;
            padding: 10px 0;
            font-weight: 600;
            font-size: 14px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 8px;
            cursor: default;
        }
        .search_button {
            margin-left: 16px;
            padding: 7px 24px;
            background: none;
            border: 2px solid #6490E7;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 700;
            color: #6490e7;
        }
        .search_button:hover {
            background: #E9EFFA;
        }
        .search_button:active { 	
            background: #D2DDF4;
        }
        .search_button:focus { 	
            outline: none;
        }
        
    }

    /* 리뷰탭 */
    .review_tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;
        text-align: center;
        border: 2px solid #6490E7;
        border-radius: 8px;
        cursor: pointer;
    }
    .submenu {
        text-align: center;
        width: 100%;
        padding: 10px;
        color:rgba(0, 0, 0, 0.7);
        border-right: 1px solid #DFDFE3;
    }
    .submenu p, .unClicked {
        font-size: 16px;
        font-weight: 600;
    }
    .unClicked {
        font-weight: 600!important;
        font-family: ' Pretendard';
    }
    .submenu:last-child {
        border: none;
    }

    .focus {
        background: #F7F9FE;
    }
    .focus > p, .clicked, .clicked span {
        font-size: 16px;
        color: #6490E7!important;
        font-weight: 600;
    }
    .focus:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .focus:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .clicked {
        color: #6490E7;
    }

    /* 리뷰내용 */
    .review_tab_content {
        padding: 8px 8px 1px 8px;
        background: #F5F5F5;
        border: 1px solid #DFDFE3;
        border-radius: 12px;

        .review_view {
            margin-bottom: 12px;
            padding: 16px;
            border: 1px solid #DFDFE3;
            border-radius: 8px;
            background: #fff;

            h2 {
                font-size: 16px;
                font-weight: 700;
                line-height: 24px
            }
            h3 {
                padding: 4px 0 16px 0;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.7)
            }
            .ceo_view {
                padding:8px 0;
                font-size: 12px;
                font-weight: 400;
                color: #ef4643;
            }
            p {
                font-size: 16px;
                font-weight: 400;
            }
            button {
                width: 100%;
                margin-top: 32px;
                padding: 16px 0;
                line-height: 20px;
                background: #6490E7;
                color: #fff;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                border: none;
                outline: none;
            }
        }
        
    }
    .non_review {
        width: 100%;
        margin-bottom: 8px;
        padding: 80px 0;
        border-radius: 8px;
        text-align: center;
        background: #fff;

        h2 {
            font-size: 16px;
            font-weight: 500;
            color: rgba(17, 17, 17, 0.7);
            line-height: 28px;
            letter-spacing: 0.6px;
        }
    }

    /* 리뷰이미지 */
    .image_list {
        display: flex;
        align-items: center;
        justify-content: flex-start
        height: 99px;
        margin-top: 20px;
        overflow: hidden;
    }
    img {
        max-width: 152px;
        min-width: 152px;
        max-height: 90px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 8px;
        overflow: hidden;
    }

    /* 사장님 답변 */
    .ceo_review {
        margin: 16px 0;
        padding: 16px;
        background: #F5F5F5;
        border-radius: 8px;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 8px;

            h2 {
                font-size: 16px;
                font-weight: 600;
                color: #138290;
            }
            h3 {
                padding: 0;
                margin: 0 0 0 3px;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.7);
            }
        }
        .ceo_review_button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            button {
                padding: 12px 0;
                max-width: 80px;
                margin-left: 10px;
                
                p {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
            .remove_review {
                background: none;
                border: 2px solid #B00020;
                border-radius: 8px;
                
                p {
                    color: #B00020;
                }   
            }
            .change_review {
                background: #4C4C4F;
                border-radius: 8px;
                
                p {
                    color: #fff;
                }   
            }
        }
    }   
`



const MReviewStyle = styled.div`  
    /* 공통 css */
    *, h1, h2, h3, h4, ul, li, p, input, a, button {
        font-family: ' Pretendard';
        color: #000;
        list-style: none;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box; 
    }  
    .content_container {
        margin-bottom: 48px;
        padding: 16px 3vw; 
    }
    
    .tab {
        padding: 20px 32px;
        border-bottom:  1px solid #DFDFE3;

        h2 {
            font-size: 14px;
            font-weight: 700;
        }
    }

    /* 날짜 조회 */
    .date_search {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .react-datepicker-wrapper, input {
            max-width: 110px;
            text-align: center;
            border: none;
        }
        input {
            padding: 12px 0;
            border-radius: 8px;
            border: 1px solid rgba(0,0,0,0.3)!important;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }
        button {
            margin-left: auto;
            padding: 10px 20px;
            border: 1px solid #6490E7;
            border-radius: 8px;
            background: none;
            font-size: 18px;
            font-weight: 700;
            color: #6490E7;
        }
        span {
            margin: 0 4px;
        }
    }

    /* 리뷰 조회 탭 */
    .review_tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 16px 0;
        text-align: center;
        border: 1px solid #6490E7;
        border-radius: 8px;
        cursor: default;
    }
    .submenu {
        text-align: center;
        width: 100%;
        padding: 10px;
        color:rgba(0, 0, 0, 0.7);
        border-right: 1px solid #DFDFE3;
    }
    .submenu p, .unClicked {
        font-size: 14px;
        font-weight: 500;
    }
    .unClicked {
        font-weight: 500!important;
        font-family: ' Pretendard';
    }
    .submenu:last-child {
        border: none;
    }

    .focus {
        background: #F7F9FE;
    }
    .focus > p, .clicked, .clicked span {
        font-size: 14px;
        color: #6490E7!important;
        font-weight: 600;
    }
    .focus:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    .focus:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    .clicked {
        color: #6490E7;
    }
    .search_button:active { 	
        background: #D2DDF4;
    }
    .search_button:focus { 	
        outline: none;
    }

    /* 리뷰내용 */
    .review_view {
        margin-bottom: 12px;
        padding: 16px;
        border: 1px solid #DFDFE3;
        border-radius: 8px;
        background: #fff;

        h2 {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px
        }
        h3 {
            padding: 4px 0 16px 0;
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.7)
        }
        .ceo_view {
            padding:8px 0;
            font-size: 12px;
            font-weight: 400;
            color: #ef4643;
        }
        p {
            font-size: 16px;
            font-weight: 400;
        }
        button {
            width: 100%;
            margin-top: 32px;
            padding: 16px 0;
            line-height: 20px;
            background: #6490E7;
            color: #fff;
            border-radius: 8px;
            font-size: 16px;
            font-weight: 600;
            border: none;
            outline: none;
        }
    }
        
    .non_review {
        width: 100%;
        margin-bottom: 8px;
        padding: 80px 0;
        border-radius: 8px;
        text-align: center;
        background: #fff;
        border: 1px solid #DFDFE3;

        h2 {
            font-size: 16px;
            font-weight: 500;
            color: rgba(17, 17, 17, 0.7);
            line-height: 28px;
            letter-spacing: 0.6px;
        }
    }

    /* 리뷰이미지 */
    .image_list {
        display: flex;
        align-items: center;
        justify-content: flex-start
        height: 99px;
        margin-top: 20px;
        overflow: hidden;
    }
    img {
        max-width: 76px;
        min-width: 76px;
        max-height: 45px;
        object-fit: cover;
        margin-right: 8px;
        border-radius: 8px;
        overflow: hidden;
    }

    /* 사장님 답변 */
    .ceo_review {
        margin: 16px 0;
        padding: 16px;
        background: #F5F5F5;
        border-radius: 8px;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 8px;

            h2 {
                font-size: 16px;
                font-weight: 600;
                color: #138290;
            }
            h3 {
                padding: 0;
                margin: 0 0 0 3px;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.7);
            }
        }
        .ceo_review_button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            button {
                padding: 12px 0;
                max-width: 80px;
                margin-left: 10px;
                
                p {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
            .remove_review {
                background: none;
                border: 2px solid #B00020;
                border-radius: 8px;
                
                p {
                    color: #B00020;
                }   
            }
            .change_review {
                background: #4C4C4F;
                border-radius: 8px;
                
                p {
                    color: #fff;
                }   
            }
        }
    } 


    

`


