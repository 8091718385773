import React, { useState, useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import styled from 'styled-components';
import { message } from 'antd';
import Loader from "react-loader-spinner";
import stopIcon from '../../../assets/img/stopIcon.png';

const Inventory = (oProps) => {
   const [sKey, setKey] = useState(null);
   const [sCategory, setCategory] = useState("");

   const [sList, setSList] = useState([]);
   const [iList, setIList] = useState([]);
   const [oSharedMenuData, set_oSharedMenuData] = useState('');

   const alertMessage = (sIndex) => {
      message.warning({
         content: sIndex,
         className: 'custom-class',
         style: {
            fontSize: "0.9rem",
            fontWeight: "500"
         }
      });
   };

   const changeStatus = async (sIndex, aIndex) => {
      const oData = {
         sIndex,
         aIndex
      };
      const oResponse = await oProps.oManager.fDoAxios("/inventory/changeStatus", "post", "login", oData);
      if (oResponse !== undefined) {
         if (oResponse === "0000") {
            alertMessage("정상적으로 변경되었습니다");
            getData(sKey);
         } else {
            alertMessage("네트워크에러입니다 잠시 후 다시 시도바랍니다");
         }
      }
   }

   const moveCategory = async (sIndex, aIndex) => {
      setCategory(aIndex);
      setKey(sIndex);
      getData(sIndex);
   }

   const getData = async (aIndex) => {
      const sIndex = aIndex;
      const oResponse = await oProps.oManager.fDoAxios("/inventory/menuList-" + sIndex, "get", "login", null);
      if (oResponse !== undefined) {
         if (oResponse.length > 0) {
            setIList(oResponse);
         }
      }
   }

   const asyncData = async () => {
      const store_id = oProps.UserConfigReducer.StoreID;
      const oResponse = await oProps.oManager.fDoAxios("/store/register/categoryList-" + store_id, "get", "login", null);
      if (oResponse !== undefined) {
         if (oResponse.sResult !== undefined) {
            if (oResponse.sResult.length > 0) {
               setSList(oResponse.sResult);
               setCategory(oResponse.sResult[0].name);
               setKey(oResponse.sResult[0].id);
               getData(oResponse.sResult[0].id);
            } else {
               alertMessage("상품등록이 필요합니다.");
            }
         }
      }
   }

   const checkForSharedMenu = async () => {
      const store_id = oProps.UserConfigReducer.StoreID;
      const oResponse = await oProps.oManager.fDoAxios("/store/menu/checkShared", "post", null, { store_id: store_id });
      if (oResponse !== undefined) {
         if (oResponse.has_shared_menu !== undefined && oResponse.has_shared_menu === 1) {
            set_oSharedMenuData(oResponse);
            return;
         }
      }
      asyncData();
   }

   useEffect(() => {
      checkForSharedMenu();

      setTimeout(() => {
         window.location.replace('https://ceo.throo.co.kr');
      }, 500);
   }, []);

   // return (
   //    <Style>
   //       
   //    </Style>
   // )
   return (
      <Style>
         {/* {oSharedMenuData !== '' && oSharedMenuData.has_shared_menu !== undefined &&
            <div className="stockPage">
               <div className="menu_container" style={{ height: '12vh' }}>
                  <p style={{ fontSize: 14, color: 'red', paddingLeft: 16, paddingRight: 16, paddingTop: 10, fontWeight: 600 }}>공유 메뉴 사용하고 있습니다. 재고관리 불가합니다.</p>
               </div>
            </div>
         }
         {oSharedMenuData === '' &&
            <div className="stockPage">
               <div className="tapPage">
                  <div className="menu_container">
                     {sList.length > 0 ?
                        <div className="tab">
                           <ScrollContainer style={{ width: "100%" }}>
                              {sList.map((item, index) => {
                                 return (
                                    <div className={item.name === sCategory ? "scroll_menu_active" : "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.id, item.name)}>
                                       {item.name}
                                    </div>
                                 )
                              })}
                           </ScrollContainer>
                        </div>
                        :
                        <div style={{ display: "flex", justifyContent: "center", height: "10vh", alignItems: "center" }}>
                           <Loader type="BallTriangle" color="#13166b" height={40} width={40} timeout={9000000} />
                        </div>
                     }
                  </div>
               </div>
               <div className="totalPage">
                  {iList.length > 0 ?
                     <>
                        <div className="title_box">
                           <h2>{sCategory}</h2>
                        </div>
                        {iList.map((item, index) => {
                           return (
                              <div className="menuContent" key={"listing-" + index.toString()}>
                                 <div className="menu">
                                    <div className="box">
                                       <img src={item.url} className="box_image" alt="none" />
                                    </div>
                                    <div className="title">
                                       <h3>{item.title}</h3>
                                       <p>{item.price}</p>
                                    </div>
                                    <div className={item.status ? "stock" : "sold_out"} onClick={() => changeStatus(item.id, item.status)}>
                                       <h2>{!item.status ? "일시품절" : "판매재개"}</h2>
                                    </div>
                                 </div>
                              </div>
                           )
                        })}
                     </>
                     :
                     <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                     </div>
                  }
               </div>
            </div>
         } */}
         <div className='renewalPage'>
            <img src={stopIcon} alt="페이지 준비중 아이콘" width={280} />
            <h2><strong>페이지 이동중입니다...</strong></h2>
            {/*
            <p>이용에 불편을 드려 죄송합니다.</p>
            <p>보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.</p>
            <p>빠른시일내에 준비하여 찾아뵙겠습니다.</p>
            */}
         </div>
      </Style>
   )
}

export default Inventory;

const Style = styled.div`
* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Noto Sans KR', sans-serif;
}   
.renewalPage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        margin: 10px 0 40px 0;
        font-size: 40px;
    }
    p {
        font-size: 20px;
        color: #888;
    }
}
   /* * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Noto Sans KR', sans-serif;
      color: #000;
   }   

   .stockPage {
      background-color: #f1f1f1;
      padding-bottom: 5%;
   }

   .tapPage {
      background-color: #F1F3F7;
      padding-bottom: 8px;
   }

   .menu_container {
      background: #fff;
      height: 10vh;

      .tab {
         display: flex;
         align-items: center;
         height: 10vh;
         margin-bottom: 24px;
         background-color: #fff;
         border-radius: 4px;
         overflow: auto;
         white-space: nowrap;
      }

      .scroll_menu {
         display: inline-block;
         text-align: center;
         text-decoration: none;
         margin: 5%;
         cursor: pointer;
         *display:inline;
         *zoom:1;
         vertical-align:top;
         font-size: 0.9rem;
         font-weight: 400;
         color: #666;
      }

      .scroll_menu_active {
         border-bottom: 1px solid #000;
         display: inline-block;
         text-align: center;
         text-decoration: none;
         margin: 5%;
         cursor: pointer;
         *display:inline;
         *zoom:1;
         vertical-align:top;
         font-size: 0.9rem;
         font-weight: 500;
         color: #000;
      }
   }

   .tab {
      -ms-overflow-style: none; 
      scrollbar-width: none; 
   }
   .tab::-webkit-scrollbar {
      display: none; 
   }
   .totalPage {
      width: 100%;
      background-color: #fff;

      .title_box {
         display: flex;
         align-items: center;
         height: 8vh;

         h2 {
               margin-left: 5%;
               font-size: 1.1rem;
               font-weight: 700;
         }
      }
      .menuContent {
         border-bottom: 1px solid #DFDFDF;
         margin-right: 5%;
         margin-left: 3%;

         .menu {
               display: flex;
               align-items: center;
               padding: 3%;

               .box {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 11vw;
                  height: 5vh;
                  margin-right: 16px;
                  border-radius: 4px;
                  border : 1px solid #dfdfdf;

                  .box_image {
                     border-radius: 4px;
                     width: 11vw;
                     height: 5vh;
                  }
               }

               .title {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: flex-start;

                  h3 {
                     font-size: 0.8rem;
                     font-weight: 700;
                  }
                  p {
                     font-size: 0.8rem;
                     font-weight: 400;
                     color: #666;
                  }
               }
               
         }
      }

      .menu:nth-of-type(1) {
         border-top: 1px solid #DFDFDF;
      }

      .sold_out {
         display: flex;
         justify-content: center;
         align-items: center;
         text-align: center;
         width: 16vw;
         height: 6vh;
         border-radius: 4px;
         border: 1px solid #DD1212;
         margin-left: auto;
         cursor: pointer;
         transition: 0.3s;

         h2 {
            padding: 0;
            font-size: 0.7rem;
            font-weight: 700;
            color: #DD1212;
         }
      }
      

      .stock {
         display: flex;
         justify-content: center;
         align-items: center;
         text-align: center;
         width: 16vw;
         height: 6vh;
         border-radius: 4px;
         background: #617be3;
         margin-left: auto;
         cursor: pointer;
         transition: 0.3s;

         h2 {
            padding: 0;
            font-size: 0.7rem;
            font-weight: 700;
            color: #fff;
         }
      }
   } */
`


