import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Table, DatePicker, Select, Switch, Tag, Transfer } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => {

   return (
      <Transfer {...restProps}>
         {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            disabled: listDisabled,
            selectedKeys: aSelectedKeys
         }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;


            let rowSelection = {
               selectedRowKeys: aSelectedKeys
            };

            return (
               <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={filteredItems}
                  size="small"
                  style={{ pointerEvents: listDisabled ? 'none' : undefined, minHeight: "60vh" }}
                  onRow={({ key, disabled: itemDisabled }) => ({
                     onClick: () => {
                        /*
                        //if (itemDisabled || listDisabled) return;
                        //listSelectedKeys = [];
                        //console.log('key', key);
                        //console.log('listSelectedKeys', listSelectedKeys);
                        //onItemSelect(key, !listSelectedKeys.includes(key));
                        if (itemDisabled || listDisabled) return;
                        //console.log('aSelectedKeys', aSelectedKeys);
                        //onItemSelect(key, !aSelectedKeys.includes(key));

                        if (direction === 'left') {
                           restProps.deselectAllLeftColum(key);
                        } else if (direction === 'right') {
                           let bCheckDisabled = false;

                           const oTableTarget = document.querySelector(".ant-transfer-customize-list div:nth-child(3)");
                           const oTablesAllTarget = oTableTarget.querySelector(".ant-table-wrapper table");
                           const oRowsTarget = oTablesAllTarget.rows;
                           for (var i = 0; i < oRowsTarget.length; i++) {
                              if (oRowsTarget[i].getAttribute('data-row-key') === key) {
                                 if (oRowsTarget[i].querySelector(".ant-checkbox-wrapper").classList.contains('ant-checkbox-wrapper-disabled') === true) {
                                    bCheckDisabled = true;
                                 } else if (oRowsTarget[i].classList.contains('ant-table-row-selected') === true) {
                                    restProps.removeCheckedRow(oRowsTarget[i]);
                                 } else {
                                    restProps.addCheckedRow(oRowsTarget[i]);
                                 }
                              }
                           }
                        }
                        */
                     },
                  })}
                  pagination={false}
               />
            );
         }}
      </Transfer >
   )
};


const leftTableColumns = [
   {
      dataIndex: 'title',
      title: '매장명',
      className: 'left-table-col-js'
   }
];

const rightTableColumns = [
   {
      dataIndex: 'title',
      title: '매장명',
      className: 'right-table-col-js'
   },
];

const MenuCopy = (oProps) => {

   const [targetKeys, setTargetKeys] = useState();
   const [disabled, setDisabled] = useState(false);
   const [showSearch, setShowSearch] = useState(true);
   const [aStoreData, set_aStoreData] = useState([]);

   const onChange = (nextTargetKeys) => {
   };

   const triggerDisable = (checked) => {
   };

   const triggerShowSearch = (checked) => {
   };

   const searchStore = async (sSearchValue, sDirection) => {

      if (sDirection === 'left' && sSearchValue !== undefined && sSearchValue.trim() === '') {
         showEmptyRow(sDirection);
         return;
      }

      if (sDirection === 'right' && sSearchValue !== undefined && sSearchValue.trim() === '') {
         showEmptyRow(sDirection);
         return;
      }

      const checkId = oProps.UserConfigReducer.SalesGroupId;
      const oData = {
         sales_id: oProps.UserConfigReducer.SalesId,
         sParam: sSearchValue,
         type: oProps.UserConfigReducer.SalesUserType,
      }
      let oStoreData = [];
      if (checkId.toString() === "100") {
         const oResponse = await oProps.oManager.fDoAxios("/sales/manager/searchSalesStore", "post", null, oData);
         if (oResponse !== undefined && oResponse !== null) {
            oStoreData = oResponse;
         };
      } else {
         const oResponse = await oProps.oManager.fDoAxios("/sales/searchSalesStore", "post", null, oData);
         if (oResponse !== undefined && oResponse !== null) {
            oStoreData = oResponse;
         };
      }

      if (oStoreData.length > 0) {

         /*
         const mockData = Array.from({ length: 50 }).map((_, i) => ({
            key: i.toString(),
            title: `content${i + 1}`,
            description: `description of content${i + 1}`,
            //disabled: i % 4 === 0
         }));
         
         const originTargetKeys = mockData.filter(item => Number(item.key) % 3 > 1).map(item => item.key);
         */
         let aFinalData = [];
         for (let oData of oStoreData) {
            let oModData = { ...oData };
            let sKey = oData['key'];
            oModData['key'] = sKey;
            oModData['title'] = oData['storeName'];
            aFinalData.push(oModData);
         }
         /*
         aFinalData.push({
            'key': 'hidden:0',
            'email': '',
            'phoneNm': '',
            'smsAuthenticate': '',
            'storeName': '',
            'title': ''
         });
         */

         /*
         let aTargetDataKeys = [];
         for (let oData of oStoreData) {
            let oModData = { ...oData };
            let sKey = 'copy-' + oData['key'];
            oModData['key'] = sKey;
            oModData['title'] = oData['storeName'];

            aFinalData.push(oModData);
            aTargetDataKeys.push(sKey);
         }
         */
         addDataToTable(sDirection, aFinalData);
         //setTargetKeys(aTargetDataKeys);
      } else {
         showEmptyRow(sDirection);
      }
   }

   const addDataToTable = (sDirection, aData) => {
      setTimeout(() => {

         let oTableTarget = document.querySelector("table.ant-table-left-js tbody");
         if (sDirection === 'right') {
            oTableTarget = document.querySelector("table.ant-table-right-js tbody");
         }
         let sRowHtml = '';

         if (oTableTarget) {
            oTableTarget.innerHTML = sRowHtml;

            for (let oRowData of aData) {
               let sKey = oRowData.key + '';

               let sStoreStatusHtml = '';
               if (oRowData.status === 1) {
                  sStoreStatusHtml = `
                  <span class="ant-tag ant-tag-green">영업중</span>
               `;
               } else if (oRowData.status === 0) {
                  sStoreStatusHtml = `
                  <span class="ant-tag ant-tag-volcano">영업종료</span>
               `;
               }

               sRowHtml += `
            <tr data-row-key="${oRowData.key}" class="ant-table-row ant-table-row-level-0 row-copy-menu-${sDirection}-js row-${sDirection}-${oRowData.key}-js" data-direction="${sDirection}">
               <td class="ant-table-cell ant-table-selection-column">
                  <label class="ant-checkbox-wrapper" style="pointer-events:none;">
                     <span class="ant-checkbox ant-checkbox-js" style="pointer-events:none;">
                        <input type="checkbox" class="ant-checkbox-input" value="" style="pointer-events:none;">
                        <span class="ant-checkbox-inner ant-checkbox-inner-ow">
                        </span>
                     </span>
                  </label>
                  <div class="complete-copy-row complete-one-row-${sDirection}-${oRowData.key}-js hidden"></div>
               </td>
               <td class="ant-table-cell">
                  ${oRowData.title}
                  ${sStoreStatusHtml}
                  <div class="complete-copy-row complete-two-row-${sDirection}-${oRowData.key}-js hidden">
                     <span>복사완료</span>
                  </div>
                  <div class="loading-copy-row loading-two-row-${sDirection}-${oRowData.key}-js hidden">
                     <span class="ant-btn-loading-icon">
                        <span role="img" aria-label="loading" class="anticon anticon-loading anticon-spin">
                        <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
                        </span>
                     </span>
                  </div>
               </td>
            </tr>      
         `;
            }
            oTableTarget.innerHTML = sRowHtml;
         }
      }, 500);
   }

   const showEmptyRow = (sDirection) => {
      setTimeout(() => {
         let oTableTarget = document.querySelector("table.ant-table-left-js tbody");
         if (sDirection === 'right') {
            oTableTarget = document.querySelector("table.ant-table-right-js tbody");
         }
         let sRowHtml = '';
         sRowHtml += `
      <tr class="ant-table-placeholder"><td colspan="2" class="ant-table-cell"><div class="ant-empty ant-empty-normal"><div class="ant-empty-image"><svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg></div><div class="ant-empty-description">데이터 없음</div></div></td></tr>
      `;
         if (oTableTarget) {
            oTableTarget.innerHTML = sRowHtml;
         }
      }, 500);
   }

   /*
   const removeDisabledRow = (oTargetRow) => {
      if (oTargetRow) {
         oTargetRow.classList.remove('ant-table-row-selected');
         oTargetRow.querySelector(".ant-checkbox-wrapper").classList.remove('ant-checkbox-wrapper-disabled');
         oTargetRow.querySelector(".ant-checkbox-wrapper").classList.remove('ant-checkbox-wrapper-checked');
         oTargetRow.querySelector(".ant-checkbox").classList.remove('ant-checkbox-disabled');
         oTargetRow.querySelector(".ant-checkbox").classList.remove('ant-checkbox-checked');
         oTargetRow.querySelector(".ant-checkbox input").setAttribute('disabled', false);
         oTargetRow.querySelector(".ant-table-cell:nth-child(2)").style.color = "rgba(0, 0, 0, 1)";
      }
   }

   const addDisabledRow = (oTargetRow) => {
      if (oTargetRow) {
         oTargetRow.classList.remove('ant-table-row-selected');
         oTargetRow.querySelector(".ant-checkbox-wrapper").classList.remove('ant-checkbox-wrapper-checked');
         oTargetRow.querySelector(".ant-checkbox").classList.remove('ant-checkbox-checked');

         oTargetRow.querySelector(".ant-checkbox-wrapper").classList.add('ant-checkbox-wrapper-disabled');
         oTargetRow.querySelector(".ant-checkbox").classList.add('ant-checkbox-disabled');
         oTargetRow.querySelector(".ant-checkbox input").setAttribute('disabled', true);
         oTargetRow.querySelector(".ant-table-cell:nth-child(2)").style.color = "rgba(0, 0, 0, 0.2)";
      }
   }

   const deselectAllLeftColum = (iKey) => {
      //console.log('deselectAllLeftColum', 111);
      const oTable = document.querySelector(".ant-transfer-customize-list").firstChild;
      const oTablesAll = oTable.querySelector(".ant-table-wrapper table");
      const oRows = oTablesAll.rows;

      if (oRows !== undefined && oRows.length > 0) {
         for (var i = 0; i < oRows.length; i++) {

            if (oRows[i].classList.contains('ant-table-row-selected') === true && parseInt(oRows[i].getAttribute('data-row-key')) !== parseInt(iKey)) {
               removeCheckedRow(oRows[i]);
            } else if (oRows[i].classList.contains('ant-table-row-selected') === true && parseInt(oRows[i].getAttribute('data-row-key')) === parseInt(iKey)) {
               removeCheckedRow(oRows[i]);
            } else if (parseInt(oRows[i].getAttribute('data-row-key')) === parseInt(iKey)) {
               addCheckedRow(oRows[i]);
            }
         }
      }

      // Disable on the target side
      const oTableTarget = document.querySelector(".ant-transfer-customize-list div:nth-child(3)");
      const oTablesAllTarget = oTableTarget.querySelector(".ant-table-wrapper table");
      const oRowsTarget = oTablesAllTarget.rows;

      if (oRowsTarget !== undefined && oRowsTarget.length > 0) {
         for (var i = 0; i < oRowsTarget.length; i++) {
            let oWrapperEl = oRowsTarget[i].querySelector(".ant-checkbox-wrapper");
            if (oRowsTarget[i].getAttribute('data-row-key') === ('copy-' + iKey)) {
               if (oWrapperEl && oWrapperEl.classList.contains('ant-checkbox-wrapper-disabled') === false) {
                  addDisabledRow(oRowsTarget[i]);
               } else {
                  removeDisabledRow(oRowsTarget[i]);
               }

            } else if (oWrapperEl && oWrapperEl.classList.contains('ant-checkbox-wrapper-disabled') === true) {
               removeDisabledRow(oRowsTarget[i]);
            }
         }
      }
   }
   */

   useEffect(() => {
      document.querySelector('.ant-transfer-operation').innerHTML = `
      <span role="img" aria-label="arrow-right" class="anticon anticon-arrow-right" style="font-size: 20px;">
         <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
            <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path>
         </svg>
      </span>`;
   }, []);

   const TableTransferOnSearch = (sDirection, sValue) => {
      searchStore(sValue, sDirection);
   }

   return (
      <Style>
         <div style={{ minHeight: "60vh" }}>
            <TableTransfer
               dataSource={aStoreData}
               targetKeys={targetKeys}
               disabled={disabled}
               showSearch={showSearch}
               filterOption={(inputValue, item) => {
                  //item.title.indexOf(inputValue) !== -1 || item.title.indexOf(inputValue) !== -1
                  return true;
               }}
               onChange={onChange}
               leftColumns={leftTableColumns}
               rightColumns={rightTableColumns}
               oneWay={true}
               onSearch={TableTransferOnSearch}
            />
         </div>
      </Style>
   )

}

export default MenuCopy;

const Style = styled.div`
   .ant-transfer-list-body-customize-wrapper {
      overflow-y: scroll;
      max-height: 70vh;
   }
   .ant-transfer-list-header {

      display: none;

      .ant-checkbox-wrapper {
         display: none;
      }
      .ant-transfer-list-header-dropdown {
         display: none;
      }
   }
   .ant-table-wrapper {
      .ant-table-content {
         .ant-checkbox-input {
            pointer-events: none;
         }
      }
   }
   .ant-transfer-list-search-action {
      right: 16px;
      line-height: 26px;
   }
`