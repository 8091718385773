import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { pulse } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { message, Switch, Modal } from "antd";
import "antd/dist/antd.css";
import '../../../../assets/css/font.css';

import hamburger from "../../../../assets/svg/Mhamburger.svg";
import mainLogo from '../../../../assets/svg/loginlogo.svg';
import manual from '../../../../assets/svg/exclamationMark.svg';
import throoSelfLogo from '../../../../assets/img/throoSelfLogo.png';

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { WebRoute } from '../../../../navigation/WebRoutes';
const styles = {
   pulse: {
      animation: 'infinite 1s',
      animationName: Radium.keyframes(pulse, 'pulse')
   }
}

const HeaderMobilePage = ({ toggle, sProps, activeStatus, bManualPaused }) => {
   const navigate = useNavigate()
   const [bIsPosStore, set_bIsPosStore] = useState(false);

   const goDashBoard = () => {
      navigate(WebRoute.ADMIN_SALES_DASHBOARD)
   }

   const closeNav = () => {
      if (window.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage(
            JSON.stringify({ close: true })
         );
      }
   }

   useEffect(() => {
      let oUserData = sProps.UserConfigReducer;
      if (oUserData && oUserData.isPOSStore === true) {
         set_bIsPosStore(true);
      }
   }, []);

   if (bIsPosStore === true) {
      return (
         <MobileHeader>
            <div className="posStoreHeaderSpacer"></div>
            <div className="posStoreHeader" >
               <div className="side_menu left-col">
                  <MobileIcon onClick={() => { navigate(WebRoute.APP_SIDEBAR) }}>
                     <SVG src={hamburger} width="24px" height="24px" title="사이드메뉴버튼" />
                  </MobileIcon>
               </div>
               <div className="close_button right-col">
                  <button onClick={closeNav}>닫기</button>
               </div>
            </div>
         </MobileHeader>
      )
   }

   return (
      <MobileHeader>
         <div className="headerContent" >
            <div className="side_menu">
               <MobileIcon onClick={toggle}>
                  <SVG src={hamburger} width="24px" height="24px" title="사이드메뉴버튼" />
               </MobileIcon>
            </div>

            <img onClick={goDashBoard} src={throoSelfLogo} alt="스루셀프매니저로고" width="120px" />
            {
            /* 
            <div className="logo">
               {location.pathname.indexOf(WebRoute.ADMIN_SALES_DASHBOARD) >= 0 || location.pathname === '/' && <img className="mainLogo" src={throoSelfLogo} width="133px" title="대시보드로고" />}
               {location.pathname.indexOf(WebRoute.ADMIN_INVENTORY) >= 0 && <p className="title_logo">재고관리</p>}
               {location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0 && <p className="title_logo">상품관리</p>}
               {location.pathname.indexOf(WebRoute.ADMIN_PAYMENT) >= 0 && <p className="title_logo">매출관리</p>}
               {location.pathname.indexOf(WebRoute.ADMIN_NOTICE) >= 0 && <p className="title_logo">공지사항</p>}
               {location.pathname.indexOf(WebRoute.ADMIN_STORE) >= 0 && <p className="title_logo">매장관리</p>}
               {location.pathname.indexOf(WebRoute.ADMIN_BUSINESS) >= 0 && <p className="title_logo">사장님정보</p>}
            </div>
            */}
            {/* {activeStatus === false && bManualPaused === false &&
               <div className="tip_text" style={{ minWidth: 140 }}>
                  <p>주문 프로그램에서 로그인이 되어야<br />정상영업이 가능합니다</p>
                  <span className="triangle-up"></span>
               </div>
            }
            {bManualPaused === true &&
               <div className="tip_text" style={{ minWidth: 140 }}>
                  <p>일시정지를 해제해야<br />정상 영업이 가능합니다</p>
                  <span className="triangle-up"></span>
               </div>
            } */}
         </div>
      </MobileHeader>
   )
}

export default HeaderMobilePage;

const MapContents = styled.div`
  border-radius : 30px;
  width: 100%;
  height: 40vh;
`;

export const MobileHeader = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        
    } 

    .posStoreHeaderSpacer {
      height: 52px;
    }

    .posStoreHeader {
       display: flex;
       align-items: center;
       background: #f1f1f1;  
       position: fixed;
       top: 0;
       z-index: 20;
       width: 100vw;
    }

    .posStoreHeader .left-col {
       padding-left: 14px;
       flex:1;
    }

    .posStoreHeader .right-col {
       padding-right: 14px;
    }

    /* 닫기버튼 */
    .close_button {
        padding: 8px 24px 8px 24px; 
        text-align: right;
        background: #f1f1f1;  

        button {
            padding: 6px 16px;
            font-size: 14px;
            font-weight: 400;
            background: #f1f1f1;     
            color: #666;
            border: 1px solid #ccc;
            border-radius: 8px;
            outline: none;
        }
    }

    
    .triangle-up {
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 16px solid black;
      position: absolute;
      left: 8px;
      top: -10px;
    }

    .tip_text {
       position: absolute;
       margin-top: -2px;
       padding: 8px 16px;
       top: 100%;
       left: 13px;
       background: #000;
       border: 1px solid #262626;
       border-radius: 8px;
       box-shadow: 0px, 9px rgba(0, 0, 0, 0.08);
       cursor: pointer;
 
       p {
          color: #fff;
       }
       
    }

    .headerContent { 
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 0 ;
        position: relative;

        .side_menu {
            margin-left: 13px; 
        }
        
        img {
            width: 100%;
            max-width: 155px;
            margin: 0 auto;
            padding-right: 13px;
        }
    }

    /* h2 {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #000;
    } */

    /* hover문구 */
    /* .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        left: -130px;
        width: 50vw;
        min-height: 52px;
        padding: 8px 12px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        border: 1px solid rgba(223, 223, 223, 1);
        font-size: 0.8rem;
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }
    .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 48px;
        padding: 0 19px 0 16px; 

        p {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 15px;

            span {
                margin-right: 4px;
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                border-bottom: 1px solid #000;
            }
        }
        .right {
            display: flex;
            align-items: center;
        }
        .left {
            display: flex;
            align-items: center;
        }
        .left p {
            margin-right: 8px;
        }

        .operation_wrapper{
            width: 15vw;
            height: 30px;
            border-radius: 4px;
            background: #617be3;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            
            p {
                color: #fff;
                font-size: 11px;
                font-weight: 700;
                line-height: 16.5px;
            }
        }

        .box {
            width: 15vw;
            height: 3vh;
            border-radius: 4px;
            background-color: rgba(102, 102, 102, 1);
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                color: #fff;
                font-size: 11px;
                font-weight: 700;
                line-height: 16.5px;
            }
        }

        .switchBox {
            height: 3vh;
            border-radius: 4px;
            display: flex;
            align-items: center;
            margin-left: 5%;

            p {
                color: #fff;
                font-size: 0.7rem;
                font-weight: 700;
                line-height: 16.5px;
            }
        }
    } */
`
export const MobileIcon = styled.div`
    display: block;
    
`;