import React from 'react';
import styled from 'styled-components';

// 이미지
import cancel01 from '../../../../assets/img/guide/pos/cancel01.png';
import cancel02 from '../../../../assets/img/guide/pos/cancel02.png';
import cancel03 from '../../../../assets/img/guide/pos/cancel03.png';
import cancel04 from '../../../../assets/img/guide/pos/cancel04.png';
import cancel05 from '../../../../assets/img/guide/pos/cancel05.png';

const CancelGuide = (oProps) => {

    return (
        <D_cancelGuide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>스루 주문접수</h3>
                        <h1>스루 주문 취소 방법</h1>
                        <p>스루 주문 취소는 주문이 들어오자마자 주문을 취소하는 방법과 당일 전달 완료된 주문을 취소하는 방법<br /> 이렇게 두 가지가 있습니다.</p>
                        <h2>✨주문이 들어왔을 때 취소하는 방법✨</h2>
                        <p>재료 소진, 매장 혼잡으로 인한 어려움 등 피치 못할 사정으로 주문 접수가 불가능할 경우에 취소가 가능합니다.</p>
                        <h2><span>STEP 1</span><br />주문서에서 접수 불가 버튼을 클릭해 주세요.</h2>
                        <img src={cancel01} alt="주문 취소 가이드 이미지" width="400px" />
                        <h2><span>STEP 2</span><br />고객에게 전달될 주문취소 사유를 선택해 주세요.<br /><span style={{color:'#f45452'}}>😳 한번 누르면 다시 되돌릴 수 없어요!  신중하게 눌러주세요!</span></h2>
                        <img src={cancel02} alt="주문 취소 가이드 이미지" width="400px" />
                        <h2>✨상품 전달 완료 후 취소하는 방법✨</h2>
                        <p>불가피한 경우 또는 고객 요청으로 취소해야 할 때 이미 완료된 주문이어도 주문을 취소할 수 있습니다.<br /><span>상품 전달 완료 당일</span>에만 취소가 가능하며 해당 기능은 윈도우 포스만 지원됩니다.</p>
                        <h2><span>STEP 1</span><br />접수 완료된 주문에서 취소를 원하는 주문을 선택해 주세요.</h2>
                        <img src={cancel03} alt="주문 취소 가이드 이미지" width="400px" />
                        <h2><span>STEP 2</span><br />주문 취소 버튼을 눌러주세요.</h2>
                        <img src={cancel04} alt="주문 취소 가이드 이미지" width="400px" />
                        <h2><span>STEP 3</span><br />😳 <sapn style={{color:'#f45452'}}>한번 누르면 다시 되돌릴 수 없어요!</sapn> <br />확인 버튼은 신중하게 눌러주세요!</h2>
                        <img src={cancel02} alt="주문 취소 가이드 이미지" width="400px" />
                        <h2><span>STEP 4</span><br />취소가 완료되면 매장 취소로 변해요.</h2>
                        <img src={cancel05} alt="주문 취소 가이드 이미지" width="400px" />
                        <p style={{marginTop: "50px", fontWeight:"900"}}>주문취소 기능은 취소 전으로 다시 되돌릴 수 없어요 😢<br />정말 불가피하게 취소해야 할 경우일 때에만 사용해 주세요!!</p>
                    </div>
                </div>
            </div>
        </D_cancelGuide>
    )
}
export default CancelGuide;

export const D_cancelGuide = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .pGuideCont {
        margin-top: 110px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;
        word-break: keep-all;

        span {
            color: #8E929F;
        }
    }
    h2 span:nth-child(3) {
        color: #F45452;
        font-weight: 900;
    }
    p {
        margin: 15px 0 25px 0;
        font-size: 16px;
        font-weight: 700;

        span {
            color: #F45452;
            font-weight: 900;
        }
    }
`