import React, { useState, useEffect, useRef } from 'react';
import { Carousel, Checkbox, message, Select } from "antd";
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import SVG from 'react-inlinesvg';
import "antd/dist/antd.css";
import ScrollContainer from 'react-indiana-drag-scroll';
import { Link, } from 'react-router-dom';

import { WebRoute } from '../../navigation/WebRoutes';

import StoreinfoStyle from '../../assets/styledComponents/StoreinfoStyle';
import Posdownload from '../../assets/img/marketbanner01.png';
import KaKaoMap from './ModalKaKaoMap';

import ClockSvg from '../../assets/svg/clock.svg';
import XGroup from '../../assets/svg/xGroup.svg';
import StreetSvg from '../../assets/svg/street.svg';
import WiatingSvg from '../../assets/svg/waiting.svg';
import ParkSvg from '../../assets/svg/park.svg';
import CancelSvg from '../../assets/svg/closeIcon.svg';
import noneImageBox from '../../assets/svg/none_image.svg';
import creditCardBtn from '../../assets/svg/credit-card.svg';

import option_guide_01 from '../../assets/img/option_guide_01.jpg';
import option_guide_02 from '../../assets/img/option_guide_02.jpg';

import storeInfo1 from '../../assets/img/storeInfo1.png';
import storeInfo2 from '../../assets/img/storeInfo2.png';
import storeInfo3 from '../../assets/img/storeInfo3.png';
import storeInfo4 from '../../assets/img/storeInfo4.png';
import storeInfo5 from '../../assets/img/storeInfo5.png';
import storeInfo6 from '../../assets/img/storeInfo6.png';
import ordertime1 from '../../assets/img/ordertime1.png';
import ordertime2 from '../../assets/img/ordertime2.png';
import pickup1 from '../../assets/img/pickup1.png';
import pickup2 from '../../assets/img/pickup2.png';
import pickup3 from '../../assets/img/pickup3.png';
import pickup4 from '../../assets/img/pickup4.png';
import pickup5 from '../../assets/img/pickup5.png';
import pickupzone1 from '../../assets/img/pickupzone1.png';
import pickupzone2 from '../../assets/img/pickupzone2.png';
import pickupzone3 from '../../assets/img/pickupzone3.png';
import pickupzone6 from '../../assets/img/pickupzone6.png';
import business1 from '../../assets/img/business1.png';
import business2 from '../../assets/img/business2.png';
import business3 from '../../assets/img/business3.png';
import business4 from '../../assets/img/business4.png';
import business5 from '../../assets/img/business5.png';
import business6 from '../../assets/img/business6.png';
import operation1 from '../../assets/img/operation1.png';
import operation3 from '../../assets/img/operation3.png';
import operation4 from '../../assets/img/operation4.png';
import operation5 from '../../assets/img/operation5.png';
import operation7 from '../../assets/img/operation7.png';
import operation8 from '../../assets/img/operation8.png';
import operation9 from '../../assets/img/operation9.png';
import category1 from '../../assets/img/category1.png';
import category2 from '../../assets/img/category2.png';
import category3 from '../../assets/img/category3.png';
import category4 from '../../assets/img/category4.png';
import category5 from '../../assets/img/category5.png';
import category6 from '../../assets/img/category6.png';
import product1 from '../../assets/img/product1.png';
import product2 from '../../assets/img/product2.png';
import product3 from '../../assets/img/product3.png';
import product4 from '../../assets/img/product4.png';
import product5 from '../../assets/img/product5.png';
import product6 from '../../assets/img/product6.png';
import product7 from '../../assets/img/product7.png';
import product8 from '../../assets/img/product8.png';
import product9 from '../../assets/img/product9.png';
import product10 from '../../assets/img/product10.png';
import product11 from '../../assets/img/product11.png';
import product12 from '../../assets/img/product12.png';
import product13 from '../../assets/img/product13.png';
import product14 from '../../assets/img/product14.png';
import product17 from '../../assets/img/product17.png';
import product19 from '../../assets/img/product19.png';
import product20 from '../../assets/img/product20.png';
import option1 from '../../assets/img/option1.png';
import option2 from '../../assets/img/option2.png';
import option3 from '../../assets/img/option3.png';
import option4 from '../../assets/img/option4.png';
import option5 from '../../assets/img/option5.png';
import option6 from '../../assets/img/option6.png';
import option7 from '../../assets/img/option7.png';
import appBanner01 from '../../assets/img/guide/event_banner.png';
import appBanner02 from '../../assets/img/guide/appBanner02.png';
import appBanner03 from '../../assets/img/guide/appBanner03.png';
import appBanner04 from '../../assets/img/guide/appBanner04.png';
import appBanner05 from '../../assets/img/guide/appBanner05.png';
import appBanner06 from '../../assets/img/guide/appBanner06.png';
import appBanner07 from '../../assets/img/guide/appBanner07.png';
import appBanner08 from '../../assets/img/guide/appBanner08.png';

import closeButton from '../../assets/img/modal/closeBtn.png';
import uploadImage from '../../assets/img/uploadImage.png';
import removeButton from '../../assets/img/modal/removeButton.png';

const { Option } = Select;
const sMaxList = [];
const sMinList = [];
for (let i = 1; i < 11; i++) {
   sMaxList.push({
      key: i,
      value: i,
      name: `${i} 개`,
   });
};
for (let i = 1; i < 10; i++) {
   sMinList.push({
      key: i,
      value: i,
      name: `${i} 개`,
   });
};

const getBase64 = (file) => {
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
   });
}


const CategoryLine = ({ iLoading, sLoading, categoryList, menuList, fnModalMenu, fnGetMenu }) => {

   const modalMenu = async (sIndex) => {
      if (fnModalMenu !== undefined && typeof fnModalMenu === "function") {
         await fnModalMenu(sIndex);
      }
   }

   const changeMode = async (sIndex) => {
      if (fnGetMenu !== undefined && typeof fnGetMenu === "function") {
         await fnGetMenu(sIndex);
      }
   }

   return (
      <div className="storemenu">
         <div className="menu">
            <div style={{ height: "5vh" }}></div>
            {!iLoading ?
               <>
                  {categoryList !== undefined && categoryList.length > 0 ?
                     <>
                        <ScrollContainer className="menu_title">
                           {categoryList.map((titleContent, index) => {
                              return (
                                 <li key={index} onClick={() => modalMenu(titleContent.key)}>
                                    {titleContent.name}
                                 </li>
                              )
                           })}
                        </ScrollContainer>
                        <ul className="menu_content">
                           {!sLoading ?
                              <>
                                 {menuList !== undefined && menuList.length > 0 ?
                                    <>
                                       {menuList.map((menuContent, index) => {
                                          return (
                                             <li key={index} onClick={() => changeMode(menuContent)}>
                                                {menuContent.name} <br />
                                                {menuContent.price}
                                                <img src={menuContent.img} width='100px' height='100px' alt='img' />
                                             </li>
                                          )
                                       })}
                                    </>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", height: "20vh", alignItems: "center" }}>
                                       <p>등록된 상품이 없습니다.</p>
                                    </div>
                                 }
                              </>
                              :
                              <div style={{ display: "flex", justifyContent: "center", height: "10vh", alignItems: "center" }}>
                                 <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                              </div>
                           }
                        </ul>
                     </>
                     :
                     <p>분류를 설정해주세요</p>
                  }
               </>
               :
               <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                  <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
               </div>
            }
         </div>
      </div>
   )
}

const MenuLine = ({ menuInfo, fnChangeMode }) => {

   const changeMode = async () => {
      if (fnChangeMode !== undefined && typeof fnChangeMode === "function") {
         await fnChangeMode();
      }
   }

   return (
      <div className="storechoice">
         <div style={{ height: "5vh", position: "absolute", cursor: "pointer", background: "#fff", width: "100px" }} onClick={() => changeMode()}>
            <img src={XGroup} alt="none" />
         </div>
         <div className="choice">
            <div className="menucheck">
               <div>
                  <img src={menuInfo.url_path} width='100%' height='200vh' alt='menu' />
                  <h2>{menuInfo.product_name}</h2>
                  <ul className="price">
                     <li>가격</li>
                     <li>{menuInfo.base_price}원</li>
                  </ul>
                  <ul className="amount">
                     <li className="atitle">수량</li>
                     <li className="abutton"><button>-</button></li>
                     <li className="anumber">1</li>
                     <li className="abutton"><button>+</button></li>
                  </ul>
               </div>
            </div>
            {menuInfo.optionYn !== undefined && menuInfo.optionYn ?
               <div className="menuchoice">
                  {menuInfo.optionList.map((data, index) => {
                     return (
                        <div key={index}>
                           <h2>{data.mainTitle}</h2>
                           {data.contents.map((item, indexKey) => {
                              return (
                                 <div key={indexKey} className="menu">
                                    {item.type === "checkbox" ?
                                       <div className="checkbox" >
                                          <input type="checkbox" id="is-subscription" style={{ width: "20px", height: "20px" }} />
                                       </div>
                                       :
                                       <div className="circleCheckbox" >
                                          <input type="radio" id="ex_rd" name="ex_rds" style={{ width: "20px", height: "20px" }} />
                                       </div>
                                    }
                                    <p>{item.title}</p>
                                 </div>
                              )
                           })}
                        </div>
                     )
                  })}
               </div>
               :
               <div style={{ height: "10vh" }} />
            }
            <div className="order">
               <button className="putbutton">트레이에 담기</button>
               <button className="orderbutton">바로 주문하기</button>
            </div>
         </div>
      </div>
   )
}

const SetMenuBox = ({ fChangeData, sData, iHasOption }) => {
   const [isOption, setIsOption] = useState(false);
   const [isDetail, setIsDetail] = useState(true);

   const selectOption = async (e) => {
      setIsOption(e.target.checked);
      console.log("====", e.target.checked, sData.id, sData.name)
      if (typeof (fChangeData) === 'function') {
         await fChangeData(e.target.checked, sData.option_type_id, sData.name, sData.min_price);
      }
   }

   const getParam = async () => {
      if (iHasOption !== undefined && iHasOption.length > 0) {
         for await (const iterator of iHasOption) {
            if (parseInt(iterator.key) === parseInt(sData.option_type_id)) {
               setIsOption(true);
            }
         }
      }
   }

   useEffect(() => {
      getParam();
   }, [])

   console.log('sData', sData)
   return (
      <div className="option_box_data">
         <div className="option_title">
            <Checkbox defaultChecked={isOption} checked={isOption} onChange={selectOption} style={{ fontSize: "0.9rem", fontWeight: 400 }}>{sData.name}</Checkbox>
         </div>
         {isDetail &&
            <div className="border_box">
               <div key={sData} className="option_list_Data">
                  <p>{sData.name}</p>
                  <p>최저금액: {Math.floor(sData.min_price)} 원</p>
               </div>
            </div>
         }
      </div>
   )
}

const OptionContentBox = ({ fChangeData, sData, iHasOption }) => {
   const [isOption, setIsOption] = useState(false);
   const [isDetail, setIsDetail] = useState(true);

   const selectOption = async (e) => {
      setIsOption(e.target.checked);
      if (typeof (fChangeData) === 'function') {
         await fChangeData(e.target.checked, sData.id, sData.name, sData.option_type);
      }
   }

   const getParam = async () => {
      if (iHasOption !== undefined && iHasOption.length > 0) {
         for await (const iterator of iHasOption) {
            if (parseInt(iterator.key) === parseInt(sData.id)) {
               setIsOption(true);
            }
         }
      }
   }

   useEffect(() => {
      getParam();
   }, [])
   return (
      <div className="option_box_data">
         <div className="option_title">
            <Checkbox defaultChecked={isOption} checked={isOption} onChange={selectOption} style={{ fontSize: "0.9rem", fontWeight: 400 }}>{sData.name}</Checkbox>
            {/* {sData.option_type === 0
               ?
               <div className='cOption'>선택 옵션</div>
               :
               <div className='cOption'>추가 상품 옵션</div>
            } */}
         </div>
         {isDetail &&
            <div className="border_box">
               {sData.lists.map((item, index) => {
                  return (
                     <div key={index} className="option_list_Data">
                        <p>{item.name}</p>
                        <p>{item.price} 원</p>
                     </div>
                  )
               })}
            </div>
         }
      </div>
   )
}

const CommercialHotMenuBox = ({ fChangeData, sData, iHasOption }) => {
   const [isOption, setIsOption] = useState(false);
   const [isDetail, setIsDetail] = useState(true);

   const selectOption = async (e) => {
      setIsOption(e.target.checked);
      if (typeof (fChangeData) === 'function') {
         await fChangeData(e.target.checked, sData.id, sData.name);
      }
   }

   const getParam = async () => {
      if (iHasOption !== undefined && iHasOption.length > 0) {
         for await (const iterator of iHasOption) {
            if (parseInt(iterator.key) === parseInt(sData.id)) {
               setIsOption(true);
            }
         }
      }
   }

   useEffect(() => {
      getParam();
   }, [])

   return (
      <div className="option_box_data">
         <div className="option_title">
            <Checkbox defaultChecked={isOption} checked={isOption} onChange={selectOption} style={{ fontSize: "0.9rem", fontWeight: 400 }}>{sData.name}</Checkbox>
         </div>
      </div>
   )
}

const InsertOptionGroup = ({ oProps, fnBack, fnComplete }) => {
   const [isGuide, setGuide] = useState(false);
   const [currentPage, setCurrentPage] = useState(0);

   const [sNm, setNm] = useState("");
   const [sOptionType, setOptionType] = useState("radio");
   const [sMin, setMin] = useState(1 + " 개");
   const [sMax, setMax] = useState(1 + " 개");
   const [sOptionNm, setOptionNm] = useState("");
   const [sOptionPrice, setOptionPrice] = useState(0);
   const [iData, setData] = useState([]);

   const [classNm, setClassNm] = useState(null);
   const [classOptionNm, setClassOptionNm] = useState(null);
   const [classOptionPrice, setClassOptionPrice] = useState(null);

   const sData = useRef([]);

   const backToStart = async () => {
      if (typeof (fnBack) === 'function') {
         await fnBack();
      }
   }

   const registerOption = async (sIndex) => {
      const store_id = oProps.UserConfigReducer.StoreID;
      let oData = {
         sGroupTitle: sIndex.sNm,
         type: sIndex.type,
         sData: sIndex.sData,
         maxCount: 1,
         minCount: 1,
         store_id,
      }

      if (sIndex.type === "checkbox") {
         oData = {
            sGroupTitle: sIndex.sNm,
            type: sIndex.type,
            sData: sIndex.sData,
            maxCount: sIndex.sMax,
            store_id,
         }
      } else {
         if (parseInt(sIndex.sMin) > 1) {
            oData = {
               sGroupTitle: sIndex.sNm,
               type: "radioDouble",
               sData: sIndex.sData,
               maxCount: sIndex.sMin,
               minCount: sIndex.sMin,
               store_id,
            }
         }
      }

      const oResponse = await oProps.oManager.fDoAxios("/store/register/insertOption/v2", "post", "login", oData);
      if (oResponse !== undefined) {
         if (oResponse.resultCd === "0000") {
            message.info("등록되었습니다");
            if (fnComplete !== undefined && typeof fnComplete === "function") {
               await fnComplete();
            }
         } else {
            message.error(oResponse.resultMsg);
         }
      }
   }

   const complete = async () => {
      if (sNm === "") {
         setClassNm("input_wrong");
      } else if (iData.length < 1) {
         message.error("옵션을 지정해주세요.");
      } else {
         let oData = {
            sNm,
            sMin: parseInt(sMin.substr(0, 2)),
            sMax: parseInt(sMax.substr(0, 2)),
            sData: sData.current,
            type: sOptionType.toString()
         };

         if (sOptionType !== "checkbox") {
            if (sData.current.length < parseInt(sMin.substr(0, 2))) {
               message.error("선택가능한 옵션이 최소선택횟수보다 작습니다.");
               return false;
            }
         }
         registerOption(oData);
      }
   }

   const inputClassStoreNm = () => {
      classNm !== "input_wrong" && setClassNm("input_action");
   }

   const onChangeStoreNameValue = (event) => {
      setClassNm("input_action");
      setNm(event.target.value);
   }

   const optionType = (sIndex) => {
      classNm !== "input_wrong" && setClassNm(null);
      setOptionType(sIndex);
   }

   const setChangeMax = (event) => {
      classNm !== "input_wrong" && setClassNm(null);
      setMax(event.target.value);
   }

   const setChangeMin = (event) => {
      classNm !== "input_wrong" && setClassNm(null);
      setMin(event.target.value);
   }

   const inputClassOptionNm = () => {
      classOptionNm !== "input_wrong" && setClassOptionNm("input_action");
      setClassNm(null);
      setClassOptionPrice(null);
   }
   const inputClassOptionPrice = () => {
      classOptionPrice !== "input_wrong" && setClassOptionPrice("input_action");
      setClassOptionNm(null);
      setClassNm(null);
   }

   const onChangeClassOptionNm = (event) => {
      setClassOptionNm("input_action");
      setOptionNm(event.target.value);
   }

   const onChangeClassOptionPrice = (event) => {
      const value = event.target.value;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
         setClassOptionPrice("input_action");
         setOptionPrice(value);
      }
   }

   const insertList = async () => {
      let temp = false;

      if (sOptionNm === "") {
         setClassOptionNm("input_wrong");
         temp = true;
      }
      if (sOptionPrice === "") {
         setClassOptionPrice("input_wrong");
         temp = true;
      }

      if (!temp) {
         const xData = {
            key: parseInt(sData.current.length) + 1,
            name: sOptionNm,
            price: parseInt(sOptionPrice)
         }
         setData([...sData.current, xData]);
         sData.current = [...sData.current, xData];
         classNm !== "input_wrong" && setClassNm(null);
         classOptionNm !== "input_wrong" && setClassOptionNm(null);
         classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
      }
   }

   const deleteList = (key) => {
      sData.current = sData.current.filter((item) => item.key !== key);
      setData(sData.current.filter((item) => item.key !== key));
   }

   const nextPage = () => {
      if (sNm === "") {
         setClassNm("input_wrong");
         message.error("옵션그룹명을 입력해주세요.");
         return false;
      } else {
         setCurrentPage(currentPage + 1);
      }
   }

   return (
      <>
         {isGuide ?
            <div className="option_group_list_box">
               <div className="eraser_box">
                  <div className="cancel_add_option" onClick={() => setGuide(!isGuide)}>
                     <img src={CancelSvg} alt="none" />
                  </div>
                  <p>옵션 설정 방법</p>
               </div>
               <div className="option_guide_pic">
                  <img src={option_guide_02} alt="none" />
               </div>
               <p className="guide_text">
                  커피의 경우 뜨거운 커피인지 아니면 차가운 커피인지 결정하는경우처럼
                  반드시 선택해야하는 옵션의 경우에는 <strong>'선택해야 주문가능'</strong>을 선택해주세요
               </p>
               <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
               <div className="option_guide_pic">
                  <img src={option_guide_01} alt="none" />
               </div>
               <p className="guide_text">
                  필수로 선택하는것이 아니고 추가적으로 시럽이나 샷을 추가하는경우처럼
                  반드시 선택해야하는 옵션이 아닌 경우에는 <strong>'선택안해도 주문가능'</strong>을 선택해주세요
               </p>
               <div className="modal_footer">
                  <div className="button_box" onClick={() => setGuide(!isGuide)}>
                     <p>닫기</p>
                  </div>
               </div>
            </div>
            :
            <div className="option_group_list_box">
               <div className="eraser_box">
                  <div className="cancel_add_option" onClick={() => backToStart()}>
                     <img src={CancelSvg} alt="none" />
                  </div>
                  <p>옵션그룹 추가</p>
                  {currentPage !== 0 &&
                     <div className="back_add_option" onClick={() => setCurrentPage(currentPage - 1)}>
                        <p>이전단계</p>
                     </div>
                  }
               </div>
               {currentPage === 0 ?
                  <>
                     <div className="option_group_title">
                        <p>옵션그룹명</p>
                        <input
                           type="text"
                           placeholder="예) 사이드메뉴 추가"
                           className={classNm}
                           onClick={inputClassStoreNm}
                           value={sNm}
                           onChange={onChangeStoreNameValue}
                        />
                     </div>
                     <div className="essential_box">
                        <p>필수여부</p>
                        <div className="select_line_box">
                           <div className={sOptionType === "radio" ? "selected_wrapper" : "unselect_wrapper"} onClick={() => optionType("radio")}>
                              <p>선택해야 주문가능</p>
                           </div>
                           <div className={sOptionType === "checkbox" ? "selected_wrapper" : "unselect_wrapper"} style={{ marginLeft: "2%" }} onClick={() => optionType("checkbox")}>
                              <p>선택안해도 주문가능</p>
                           </div>
                        </div>
                     </div>
                     {sOptionType !== "checkbox" ?
                        <div className="category_title" style={{ marginBottom: "10%" }}>
                           <p>최소선택수</p>
                           <select value={sMin} onChange={setChangeMin}  >
                              {sMinList.map((item, index) => {
                                 return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                 )
                              })};
                           </select>
                        </div>
                        :
                        <div className="category_title">
                           <p>최대선택수</p>
                           <select value={sMax} onChange={setChangeMax}  >
                              {sMaxList.map((item, index) => {
                                 return (
                                    <option key={index} value={item.id}>{item.name}</option>
                                 )
                              })};
                           </select>
                        </div>
                     }
                     <div className="option_group_guide">
                        <div className="title_box">
                           <p className="title">스루의 옵션그룹 설정방법?</p>
                           <p className="content">옵션그룹의 설정은 반드시 선택해야하는지 아닌지에 따라서 결정됩니다</p>
                        </div>
                        <div className="take_box" onClick={() => setGuide(!isGuide)}>
                           <p>살펴보기</p>
                        </div>
                     </div>
                     <div className="modal_footer">
                        <div className="button_box" onClick={() => nextPage()}>
                           <p>다음</p>
                        </div>
                     </div>
                  </>
                  :
                  <>
                     <div className="option_list_write_box">
                        <p>옵션</p>
                        <input
                           type="text"
                           placeholder="예) ICE"
                           className={classOptionNm}
                           onClick={inputClassOptionNm}
                           value={sOptionNm}
                           onChange={onChangeClassOptionNm}
                        />
                        <input
                           type="text"
                           placeholder="예) 1,000"
                           className={classOptionPrice}
                           onClick={inputClassOptionPrice}
                           value={sOptionPrice}
                           onChange={onChangeClassOptionPrice}
                        />
                        <p className="plus_option" onClick={insertList}>+ 추가하기</p>
                     </div>
                     <div className="option_list_decide">
                        {iData.map((item, index) => (
                           <div className="list_option_wrapper" key={index}>
                              <p>{item.name}</p>
                              <p>{item.price}원</p>
                              <p style={{ color: "#DD1212", cursor: "pointer" }} onClick={() => deleteList(item.key)}>삭제</p>
                           </div>
                        ))}
                     </div>

                     <div className="modal_footer">
                        <div className="button_box" onClick={() => complete()}>
                           <p>완료</p>
                        </div>
                     </div>
                  </>
               }
            </div>
         }
      </>
   )
}

export const GuideCategory = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>매장설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={storeInfo1} alt="none" />
            </div>
            <p className="guide_text_line">
               매장의 업종을 선택합니다, 만약 매장이 음식점에 더 가깝다면 <strong>'음식점'</strong>을 선택해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo2} alt="none" />
            </div>
            <p className="guide_text_line">
               해당하는 업종을 선택해주세요 만약 딱 한가지만 해당한다면 <strong>한개만</strong> 여러가지 업종에 해당한다면 <strong>원하시는 만큼</strong> 선택이 가능합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo3} alt="none" />
            </div>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo4} alt="none" />
            </div>
            <p className="guide_text_line">
               추가적으로 만약 주업종이 아닌 다른 업종도 같이 하고 계시다면 부업종에서도 선택이 가능합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo5} alt="none" />
            </div>
            <p className="guide_text_line">
               <strong>매장소개글과, 매장공지사항 그리고 원산지표시사항</strong>을 입력합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo6} alt="none" />
            </div>
            <p className="guide_text_line">
               모두 입력하셨다면 <strong>'매장정보 등록하기'</strong> 버튼을 클릭해주세요.
            </p>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideOptionList = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>일반옵션 등록 방법</p>
            </div>
            {/* <div className="option_guide_pic">
               <img src={option1} alt="none" />
            </div> */}
            <div className='guide_option'>
               <h2>1.옵션 그룹명 입력</h2>
               <p >사이즈선택, 맵기 선택 등 옵션 그룹명을 입력해주세요.</p>
               <h2>2.필수 선택 여부 설정</h2>
               <p >
                  필수 선택 여부를 설정할 수 있으며, '예’를 선택한 경우 옵션을 선택해야 주문이 가능합니다. <br />
                  옵션 수는 최소 1개부터 최대 20개까지 선택 가능합니다. <br />
                  '아니요’를 선택한 경우 옵션 메뉴를 선택하지 않아도 주문이 가능합니다.
               </p>
               <h2>3.옵션 추가</h2>
               <p >
                  옵션 추가는 옵션명과 옵션 금액을 입력한 후 옵션 추가하기 버튼을 눌러서 할 수 있습니다.
               </p>
               <h2>4.옵션 등록</h2>
               <p >
                  리스트 안에 모든 정보가 올바르게 저장되어 있는지 확인한 후 등록 완료 버튼을 누르면 상품 등록 화면에서 옵션 추가하기를 클릭하면 바로 등록이 가능합니다.
               </p>
            </div>
         </div>
      </StoreinfoStyle>
   )
}
export const GuideProductOptionList = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>상품인 옵션 등록 방법</p>
            </div>
            {/* <div className="option_guide_pic">
               <img src={option1} alt="none" />
            </div> */}
            <div className='guide_option'>
               <h1>
                  상품인 옵션은 <span>상품으로 등록된 제품으로만 구성</span>될 수 있으며,<br /> 이는 재고 및 품절 관리가 용이하게 됩니다.<br />
                  다만, 필수 선택 옵션이 있는 경우, 세트 메뉴 구성 옵션을 활용해야 한다는 점을 유의해주세요!
               </h1>
               <h2>1.옵션 그룹명 입력</h2>
               <p>곁들이기 좋은 메뉴, 추가 선택 메뉴 등 옵션 그룹명을 입력해주세요.</p>
               <h2>2.상품 옵션 최대 선택수</h2>
               <p >
                  고객이 선택 가능한 옵션의 최대 선택 수를 설정할 수 있으며, <br />
                  필수 선택이 아니기 때문에 선택하지 않아도 됩니다. 만약 선택하지 않을 경우 최대 선택 수의 기본값은 20개입니다.
               </p>
               <h2>3.상품추가</h2>
               <p >
                  옵션으로 구성할 상품을 검색하거나 선택해주세요.<br />
                  그리고 해당 옵션을 선택하시면 추가 결제가 필요한 경우, 추가될 금액을 입력해주세요. <br />
                  <span>만약 추가 금액을 입력하지 않으시면 0원으로 등록됩니다. </span><br />
                  추가 금액이 결제 시 고객님의 결제 금액에 추가될 사항임을 알려드리며, 이 점 참고해주시기 바랍니다.
               </p>
               <h2>4.옵션 등록</h2>
               <p >
                  리스트 안에 모든 정보가 올바르게 저장되어 있는지 확인한 후 등록 완료 버튼을 누르면 상품 등록 화면에서 옵션 추가하기를 클릭하면 바로 등록이 가능합니다.
               </p>
            </div>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideSetOptionList = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>세트 메뉴 구성 옵션 등록 방법</p>
            </div>
            {/* <div className="option_guide_pic">
               <img src={option1} alt="none" />
            </div> */}
            <div className='guide_option'>
               <h1>
                  등록된 상품을 이용해 세트메뉴 구성 옵션을 만들 수 있습니다. <br />한번 옵션을 만들면 다양한 상품에서 여러번 사용 가능해 편리합니다.
               </h1>
               <h2>1.옵션 그룹명 입력</h2>
               <p> "파스타를 선택해주세요"와 같은 옵션 그룹명을 입력해주세요.</p>
               <h2>2.필수 선택 여부 설정</h2>
               <p>
                  필수로 최소 1개 이상은 선택해야 주문이 가능하므로 고객이 선택해야하는 필수 선택 개수를 설정합니다. <br />
                  설정을 따로 하지 않을 경우 1개로 등록됩니다.
               </p>
               <h2>3.상품 추가</h2>
               <p >
                  옵션으로 구성할 상품을 검색하거나 선택해주세요.<br />
                  그리고 해당 옵션을 선택하시면 추가 결제가 필요한 경우, 추가될 금액을 입력해주세요. <br />
                  <span>만약 추가 금액을 입력하지 않으시면 0원으로 등록됩니다. </span><br />
                  추가 금액이 결제 시 고객님의 결제 금액에 추가될 사항임을 알려드리며, 이 점 참고해주시기 바랍니다.
               </p>
               <h2>4.옵션 등록</h2>
               <p >
                  리스트 안에 모든 정보가 올바르게 저장되어 있는지 확인한 후 등록 완료 버튼을 누르면 상품 등록 화면에서 세트 메뉴 상품 추가하기를 클릭하면 바로 등록이 가능합니다.
               </p>
            </div>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideSettingForm = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>매장설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={storeInfo1} alt="none" />
            </div>
            <p className="guide_text_line">
               매장의 업종을 선택합니다, 만약 매장이 음식점에 더 가깝다면 <strong>'음식점'</strong>을 선택해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo2} alt="none" />
            </div>
            <p className="guide_text_line">
               해당하는 업종을 선택해주세요 만약 딱 한가지만 해당한다면 <strong>한개만</strong> 여러가지 업종에 해당한다면 <strong>원하시는 만큼</strong> 선택이 가능합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo3} alt="none" />
            </div>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo4} alt="none" />
            </div>
            <p className="guide_text_line">
               추가적으로 만약 주업종이 아닌 다른 업종도 같이 하고 계시다면 부업종에서도 선택이 가능합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo5} alt="none" />
            </div>
            <p className="guide_text_line">
               <strong>매장소개글과, 매장공지사항 그리고 원산지표시사항</strong>을 입력합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={storeInfo6} alt="none" />
            </div>
            <p className="guide_text_line">
               모두 입력하셨다면 <strong>'매장정보 등록하기'</strong> 버튼을 클릭해주세요.
            </p>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideOperation = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>영업시간 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={operation1} alt="none" />
            </div>
            <p className="guide_text_line">
               영업일을 설정할때 매일 시간이 일정하거나, 평일,주말만 다른경우에는 <strong>"항상같거나 주말,평일만 달라요"</strong>를 선택해주세요<br />
               만약 요일별로 영업시간이 다르거나 일정하지 않은 경우 <strong>"매일 달라요"</strong>를 선택해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={operation9} alt="none" />
            </div>
            <p className="guide_text_line">
               만약 매일일정한 시간이라면 <strong>"매일"</strong>을 클릭해주세요<br />
               평일은 동일한 시간이고 주말 혹은 토요일,일요일이 다른 경우에는 <strong>"평일"</strong>을 클릭해주세요<br />
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={operation3} alt="none" />
            </div>
            <p className="guide_text_line">
               선택한 요일 또는 날짜에 시간을 설정합니다 시작시간과 끝나는 시간을 선택해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={operation4} alt="none" />
            </div>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={operation5} alt="none" />
            </div>
            <p className="guide_text_line">
               설정된 시간의 알맞는 준비시간을 선택해주세요 <br />상품준비시간을 설정하지 못했다면 <strong>"픽업정보"</strong>란에 가서 설정해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={operation7} alt="none" />
            </div>
            <p className="guide_text_line">
               상품준비시간까지 설정하셨다면 <strong>"+시간 추가하기"</strong>를 클릭해주세요,<br />
               클릭하시면 밑에 리스트에 설정하신 시간과 상품준비시간 그리고 요일이 보입니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={operation8} alt="none" />
            </div>
            <p className="guide_text_line">
               이어서 원하시는 시간을 더 추가하실수있습니다<br />
               모든 영업시간을 추가하셨다면 밑에 있는 <strong>"시간 설정 완료"</strong>을 클릭하시면 영업시간 설정이 완료됩니다.
            </p>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideProductList = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>상품등록 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={product1} alt="none" />
            </div>
            <p className="guide_text_line">
               상품관리는 스루에서 판매할 상품을 등록,수정하기 위한 절차입니다
               상품을 등록하기 위해서 <strong> "상품등록"</strong>을 클릭해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product2} alt="none" />
            </div>
            <p className="guide_text_line">
               상품의 분류를 설정해주세요 이전에 등록하신 상품분류중 알맞는 분류를 선택해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product3} alt="none" />
            </div>
            <p className="guide_text_line">
               등록하실 상품의 이름을 입력해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product4} alt="none" />
            </div>
            <p className="guide_text_line">
               등록하실 상품의 가격을 입력해주세요.
               <br />만약 등록하실 상품을 할인가격으로 판매하고 싶으시면 할인가격을 입력해주세요
               <br />할인가격입력시 등록하실 상품은 할인가격으로 판매됩니다 즉 결제시 최종판매가격입니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product5} alt="none" />
            </div>
            <p className="guide_text_line">
               상품의 이미지가 있으시면 <strong> "등록"</strong>을 클릭하신 후 이미지를 올려주세요
               <br />만약 상품의 설명을 입력하시면 고객님이 상품을 선택하는데 도움이 됩니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product6} alt="none" />
            </div>
            <p className="guide_text_line">
               등록하실 상품의 옵션을 추가하고 싶으시면 <strong> "+옵션추가하기"</strong>을 클릭해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product7} alt="none" />
            </div>
            <p className="guide_text_line">
               처음 등록하실 경우 등록된 옵션그룹이 없기때문에 위 그림과 같이 나타납니다
               <br /><strong> "새로운 옵션그룹 추가하기"</strong>를 클릭해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product8} alt="none" />
            </div>
            <p className="guide_text_line">
               옵션그룹명을 입력하신후 원하시는 옵션그룹의 필수여부를 선택해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product9} alt="none" />
            </div>
            <p className="guide_text_line">
               옵션그룹명을 입력하신후 원하시는 옵션그룹의 필수여부를 선택해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product10} alt="none" />
            </div>
            <p className="guide_text_line">
               등록하실 옵션그룹의 옵션을 등록해주세요 옵션명,가격을 입력하시고 <strong> "+ 추가하기"</strong>를 클릭해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product11} alt="none" />
            </div>
            <p className="guide_text_line">
               옵션을 모두 입력하시고<strong> "완료"</strong>를 클릭해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product12} alt="none" />
            </div>
            <p className="guide_text_line">
               입력하신 옵션그룹이 나타납니다
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product13} alt="none" />
            </div>
            <p className="guide_text_line">
               원하시는 옵션그룹을 선택하시고 <strong> "확인"</strong>을 클릭해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product14} alt="none" />
            </div>
            <p className="guide_text_line">
               옵션리스트에 선택하신 옵션그룹이 나타납니다
               <br />모두 입력하셨다면 <strong> "상품등록하기"</strong>을 클릭해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product17} alt="none" />
            </div>
            <p className="guide_text_line">
               만약 해당 상품분류의 상품 순서를 변경하고 싶으시다면 리스트의 순서의 숫자를 잡고 끌어당겨주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product19} alt="none" />
            </div>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={product20} alt="none" />
            </div>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideCategoryList = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>상품분류 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={category1} alt="none" />
            </div>
            <p className="guide_text_line">
               상품분류는 판매하실 상품의 분류를 의미합니다<br />
               상품분류를 등록하기 위해 <strong>"분류등록"</strong>을 클릭해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="guide_eraser_box">
               <p>상품분류 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={category2} alt="none" />
            </div>
            <p className="guide_text_line">
               상품분류의 명칭을 입력해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="guide_eraser_box">
               <p>상품분류 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={category3} alt="none" />
            </div>
            <p className="guide_text_line">
               이어서 대표메뉴를 선택해주세요 대표메뉴는 한번만 등록가능합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="guide_eraser_box">
               <p>상품분류 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={category4} alt="none" />
            </div>
            <p className="guide_text_line">
               모두 입력하셨다면 <strong>"분류등록하기"</strong>를 클릭하시면 밑에 리스트에 입력하신 분류가 추가됩니다.<br />
               같은 방법으로 분류를 추가하실수있습니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="guide_eraser_box">
               <p>상품분류 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={category6} alt="none" />
            </div>
            <p className="guide_text_line">
               만약 분류 순서를 변경하고 싶으시다면 리스트에 순서의 숫자를 클릭한상태에서 원하시는 순서로 끌어당기면 <br />
               원하시는 분류 순서로 변경됩니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="guide_eraser_box">
               <p>상품분류 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={category5} alt="none" />
            </div>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideOrderTime = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>상품준비시간 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={ordertime1} alt="none" />
            </div>
            <p className="guide_text_line">
               <strong>상품준비시간은 </strong>스루에서 주문이 들어왔을때 해당 주문을 준비하는데 <strong>걸리는 시간</strong>을 의미합니다
               <br />
               매장의 운영시간에 따라서 매장이 <strong>여유로울때, 일반적인 시간때 그리고 매우 바쁜시간대</strong>로 나누어집니다
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={ordertime2} alt="none" />
            </div>
            <p className="guide_text_line">
               매장이 여유로울떄 가장 빠르게 준비할수 있는 시간을 <strong>여유</strong>에서 선택하고<br />
               일반적인 시간에 준비할수 있는 시간을 <strong>보통</strong>에서 선택하고<br />
               매장이 가장 바쁜시간에 준비하는 시간을 <strong>바쁨</strong>에서 선택합니다.<br />
               그리고 <strong>'상품 준비시간 등록'</strong>버튼을 클릭해주세요.
            </p>
         </div>
      </StoreinfoStyle>
   )
}

export const GuidePickUpInfo = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>픽업정보 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={pickup1} alt="none" />
            </div>
            <p className="guide_text_line">
               고객님이 찾아갈 매장의 주소를 입력하기 위해서 먼저 돋보기 버튼을 클릭해주세요
               <br />
               <span style={{ fontSize: "13px", color: "#666666" }}>(만약 사업자인증을 마치고 오셨다면 해당 주소로 초기값이 설정되어있습니다 만약 사업자 인증에 입력한 주소와 매장주소가 동일할경우 입력하지 않아도 괜찮아요!)</span>
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickup2} alt="none" />
            </div>
            <p className="guide_text_line">
               해당 창에서 주소를 검색해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickup3} alt="none" />
            </div>
            <p className="guide_text_line">
               검색결과중 올바른 주소를 클릭하시면 완료됩니다 이후 나머지 주소가 있다면 나머지 주소도 입력해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickup4} alt="none" />
            </div>
            <p className="guide_text_line">
               매장 전화번호는 <strong>매장번호나 매장의 관리자의 전화번호</strong>를 입력해주세요<br />
               <strong>픽업존 근접알림</strong>은 고객님이 설정된 거리에 도착할때 알리는 기능입니다 만약 100m로 설정하신다면<br />
               고객님이 매장의 100m 반경에 근접할경우 매장포스에 <strong>알림</strong>이 울리게됩니다<br />
               <strong>픽업존 정차가능 시간</strong>은 고객님이 설정된 매장에 도착하고 해당 장소에 정차가능한 최대시간을 의미합니다 <br />
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickup5} alt="none" />
            </div>
            <p className="guide_text_line">
               <strong>매장전화번호, 픽업존 근접알림, 픽업존 정차가능 시간</strong>을 입력하고 <strong>'픽업정보 등록하기'</strong>버튼을 클릭해주세요.
            </p>
         </div>
      </StoreinfoStyle>
   )
}

export const GuidePickUpZone = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>픽업존 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={pickupzone1} alt="none" />
            </div>
            <p className="guide_text_line">
               픽업존은 매장에서 고객님이 도착시 상품을 전달하고 차량접근이 어려운 매장의 경우
               <br />
               근처장소를 지정하여 고객님이 찾아갈수있도록 지정하는 장소입니다
               <br />
               픽업존을 설정하시면 고객님께 더 정확한 위치와 장소를 제공하여 드라이브 스루를 가능하게합니다
               <br />
               픽업존을 설정하기 위해 <strong>'위치검색'</strong>을 선택해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickupzone2} alt="none" />
            </div>
            <p className="guide_text_line">
               초기지정값은 매장주소에 입력된 주소로 설정되어있습니다 고객님과 만나는 장소를 왼쪽 지도에서 클릭하면 장소를 변경 가능합니다
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickupzone3} alt="none" />
            </div>
            <p className="guide_text_line">
               오른쪽에 있는 로드뷰에서 원하시는 위치가 잘보이도록 각도를 조절해주세요. 360도로 설정이 가능합니다.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickupzone6} alt="none" />
            </div>
            <p className="guide_text_line">
               픽업존 이미지의 경우 선택사항입니다 설정된 픽업존의 사진이 있으시면 <strong>'등록'</strong>버튼을 클릭해주세요<br />
               <span style={{ fontSize: "13px", color: "#666666" }}>(픽업존을 설정할때 로드뷰로 위치를 설정하셨다면 따로 지정하실 필요는 없습니다)</span>
            </p>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideBusiness = () => {

   return (
      <StoreinfoStyle>
         <div className="guide_modal_container">
            <div className="guide_eraser_box">
               <p>사업자 정보 설정 방법</p>
            </div>
            <div className="option_guide_pic">
               <img src={business1} alt="none" />
            </div>
            <p className="guide_text_line">
               스루에 등록할 <strong>매장의 상호명</strong>을 입력해주세요 스루앱에 노출되는 매장의 명칭입니다
               <br />
               등록할 <strong>매장의 대표자명</strong>을 입력해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={business2} alt="none" />
            </div>
            <p className="guide_text_line">
               사업자번호를 입력해주세요 사업자번호는 10자리 전부입력해주세요
               <br />
               <span style={{ fontSize: "13px", color: "#666666" }}>(한번등록하면 변경이 불가합니다 만약 변경을 원하시면 스루 고객센터로 연락바랍니다.)</span>
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={business3} alt="none" />
            </div>
            <p className="guide_text_line">
               사업장소재지를 입력하기 위해서 먼저 돋보기 버튼을 클릭해주세요
               <br />
               <span style={{ fontSize: "13px", color: "#666666" }}>(만약 픽업정보를 마치고 오셨다면 해당 주소로 초기값이 설정되어있습니다 만약 픽업정보에 입력한 주소와 사업장소재지가 동일할경우 입력하지 않아도 괜찮아요!)</span>
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickup2} alt="none" />
            </div>
            <p className="guide_text_line">
               해당 창에서 주소를 검색해주세요
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={pickup3} alt="none" />
            </div>
            <p className="guide_text_line">
               검색결과중 올바른 주소를 클릭하시면 완료됩니다 이후 나머지 주소가 있다면 나머지 주소도 입력해주세요.
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={business4} alt="none" />
            </div>
            <p className="guide_text_line">
               대표자 전화번호는 사장님 본인의 전화번호를 입력해주세요.
               <br />
               <span style={{ fontSize: "13px", color: "#666666" }}>(이후 정산 또는 기타 서비스로 스루에 문의하거나 스루에서 사장님께 전달드릴 사항을 받을 번호입니다.)</span>
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={business5} alt="none" />
            </div>
            <p className="guide_text_line">
               <strong>이메일</strong>의 경우 <strong>정산</strong>이나 기타 서비스를 제공받기 위한 정보입니다 사용하시고 계시는 이메일을 <strong>정확하게</strong> 입력해주세요.<br />
               <strong>입금은행</strong>을 선택하시고 <strong>계좌번호</strong>를 정확하기 입력해주세요<br />
               <span style={{ fontSize: "13px", color: "#666666" }}>(매출발생시 정산받을 계좌정보입니다.)</span>
            </p>
            <div style={{ height: "1px", background: "#dfdfdf", marginTop: "5%", marginBottom: "5%" }} />
            <div className="option_guide_pic">
               <img src={business6} alt="none" />
            </div>
            <p className="guide_text_line">
               전부 입력하셨다면 <strong>'등록하기'</strong>버튼을 클릭해주세요.
            </p>
         </div>
      </StoreinfoStyle>
   )
}

export const GuideStock = () => {

   return (
      <StoreinfoStyle>
         <div className="stock_guide_modal_container">
            <br />
            <h2>📢 재고 무제한 사용하기 📢</h2>
            <p>1. 재고 관리 사용 여부 체크 해제</p>
            <p>2. 버튼 판매중으로 변경</p>
            <h3>🔔 처음상태에서 아무것도 변경하지 않는다면(재고 관리 사용 여부 미체크시) <span style={{ lineHeight: '0' }}>재고 무제한 사용상태</span>입니다. 🔔</h3>
            <h2>📢 품절 처리 하기 📢</h2>
            <p>1. 품절 버튼 클릭</p>
            <p>2. 재고관리 사용 여부 클릭되어 있다면 해제</p>
            <p>3. 전체 저장하기 버튼 클릭</p>
            <h2>📢 특정 시간 재고 리셋을 원하지 않고 재고 사용만하기 📢</h2>
            <p>1. 재고 관리 사용 여부 체크(꼭 체크하기!)</p>
            <p>2. 실재고량 입력</p>
            <p>3. 전체 저장하기 버튼 클릭</p>
            <h3>
               <span>🔔 실재고량이란 🔔</span><br />
               공산품처럼 한번 입고된 수량이 판매량 만큼 감소되고 다시 입고전까지 채워지지 않는 수량입니다.<br />
               일일재고량과 실재고량이 같이 입력하면 금일만 실재고량이 적용되며,<br /> 일일재고량이 채워지는 시간을 기준으로 일일재고량이 적용됩니다.<br />
               매일 채워지는 상품이 오늘만 특별하게 수량이 다른 경우에 사용 할 수 있습니다.
            </h3>
            <h2>📢 특정 시간에 재고 리셋해서 관리하기 📢</h2>
            <p>1. 재고 관리 사용 여부 체크(꼭 체크하기!)</p>
            <p>2. 일일 재고량 입력</p>
            <p>3. 일일 재고량 채워지는 시간 선택 (초기 설정값은 매장 오픈 시간입니다.)</p>
            <p>4. 일일 재고량과 동일한 실재고량 입력</p>
            <p>5. 전체 저장하기 버튼 클릭</p>
            <h2>📢 특정 시간에 재고가 리셋되는데 오늘만 특수하게 재고를 다르게 하고 싶은 경우 📢</h2>
            <p>1. 실재고량 수정</p>
            <p>2. 전체 저장하기 버튼 클릭</p>
            <h3 style={{ marginTop: '15px', marginBottom: '5px' }}>
               <span>🔔 일일 재고량 채워지는 시간 설정 방법 🔔</span><br />
               재고 관리 사용 여부 체크 시 일일 재고량 채워지는 시간이 자동으로 매장 오픈 시간으로 변경됩니다.<br />
               매장 오픈 시간은 매장관리에서 설정한 시간이며, 요일별로 매장 오픈 시간이 다른 경우 가장 빠른 시간으로 설정됩니다.<br />
               다른 시간에 재고가 채워지길 원한다면 직접 시간 선택도 가능합니다.
            </h3>
            <p style={{ fontWeight: '400', color: '#6490E7', fontSize: "14px" }}>일일 재고량 채워지는 시간은 선택되어 있으나 일일 재고량의 값이 0인 경우에는 일일 재고량을 사용할 수 없으니 사용을 원하는 경우 일일 재고량에 재고량을 입력해 주세요.</p>

         </div>
      </StoreinfoStyle>
   )
}

export const ModalOptionChoiceMobile = ({ sProps, fUploadData }) => {
   const [optionList, setOptionList] = useState([]);
   const sList = useRef([]);

   const changeData = async (sIndex, sId, sName) => {
      if (sIndex) {
         let tempList = sList.current;
         let temp = {};
         temp.key = sId;
         temp.name = sName;
         tempList.push(temp);
         sList.current = tempList;
      } else {
         sList.current = sList.current.filter((item) => item.key !== sId);
      }
      if (typeof (fUploadData) === 'function') {
         await fUploadData(sList.current);
      }
   };

   const getParam = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      const oResponse = await sProps.oManager.fDoAxios('/modal/getOptionListModal-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         if (oResponse.resultCd === "0000") {
            setOptionList(oResponse.result);
         }
      }
   };

   useEffect(() => {
      getParam();
   }, [])

   const OptionNullPage = () => {
      return (
         <div className="option_group_list_box">
            <div className="eraser_box">
               <p>옵션그룹 추가</p>
            </div>
            <div className="option_group_guide_null">
               <div className="title_box">
                  <p className="title">등록된 옵션이 없습니다.</p>
                  <p className="content">옵션관리에서 옵션그룹을 추가해주세요.</p>
               </div>
            </div>
         </div>
      )
   }

   const OptionItemPage = () => {
      return (
         <>
            <div className="eraser_box">
               <p>옵션그룹 추가</p>
            </div>
            <div className="option_group_list_box">
               <div className="option_text_mobile">
                  <p className="optionDivider">※ 원하시는 옵션을 선택하고 '확인' 버튼을 눌러주세요</p>
               </div>
               {optionList.map((item, index) => {
                  return (
                     <OptionContentBox
                        key={index}
                        sData={item}
                        fChangeData={(sIndex, sId, sName) => changeData(sIndex, sId, sName)}
                     />
                  )
               })}
            </div>
         </>
      )
   }

   return (
      <StoreinfoStyle>
         {optionList.length > 0 ?
            <OptionItemPage />
            :
            <OptionNullPage />
         }
      </StoreinfoStyle>
   )
}

export const ModalOptionReleaseMobile = ({ sProps, fUploadData, iOptions }) => {
   const [addOption, setAddOption] = useState(false);

   const [optionList, setOptionList] = useState([]);
   const [hasOption, setHasOption] = useState([]);
   const sList = useRef([]);

   const changeData = async (sIndex, sId, sName) => {
      if (sIndex) {
         let tempList = sList.current;
         let temp = {};
         temp.key = sId;
         temp.name = sName;
         tempList.push(temp);
         sList.current = tempList;
      } else {
         sList.current = sList.current.filter((item) => item.key !== sId);
      }
      if (typeof (fUploadData) === 'function') {
         await fUploadData(sList.current);
      }
   };

   const getParam = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      if (iOptions !== undefined && iOptions.length > 0) {
         let tempList = [];
         for await (const iterator of iOptions) {
            let temp = {};
            temp.key = parseInt(iterator.key);
            temp.name = iterator.name.toString();
            tempList.push(temp);
         }
         sList.current = tempList;
         setHasOption(iOptions);
      }

      const oResponse = await sProps.oManager.fDoAxios('/modal/getOptionListModal-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         if (oResponse.resultCd === "0000") {
            setOptionList(oResponse.result);
         }
      }
   };

   const addOptionGroup = () => {
      setAddOption(!addOption)
      getParam();
   }


   useEffect(() => {
      getParam();
   }, [])

   const OptionItemPage = () => {
      return (
         <>
            <div className="eraser_box">
               <p>옵션그룹 추가</p>
            </div>
            <div className="option_group_list_box">
               <div className="option_text_mobile">
                  <p className="optionDivider">※ 원하시는 옵션을 선택하고 '확인' 버튼을 눌러주세요</p>
               </div>
               {optionList.map((item, index) => {
                  return (
                     <OptionContentBox
                        key={index}
                        sData={item}
                        iHasOption={hasOption}
                        fChangeData={(sIndex, sId, sName) => changeData(sIndex, sId, sName)}
                     />
                  )
               })}
            </div>
         </>
      )
   }

   const OptionNullPage = () => {
      return (
         <div className="option_group_list_box">
            <div className="eraser_box">
               <p>옵션그룹 추가</p>
            </div>
            <div className="option_group_guide_null">
               <div className="title_box">
                  <p className="title">등롣된 옵션이 없습니다.</p>
                  <p className="content">옵션관리에서 옵션그룹을 추가해주세요.</p>
               </div>
            </div>
         </div>
      )
   }

   return (
      <StoreinfoStyle>
         {optionList.length > 0 ?
            <OptionItemPage />
            :
            <OptionNullPage />
         }
      </StoreinfoStyle>
   )
}

export const ModalOptionChoice = ({ sProps, fUploadData }) => {
   const [addOption, setAddOption] = useState(false);

   const [optionList, setOptionList] = useState([]);
   const sList = useRef([]);

   const changeData = async (sIndex, sId, sName, option_type) => {
      if (sIndex) {
         let tempList = sList.current;
         let temp = {};
         temp.key = sId;
         temp.name = sName;
         temp.option_type = option_type;
         tempList.push(temp);
         sList.current = tempList;
      } else {
         sList.current = sList.current.filter((item) => item.key !== sId);
      }
      if (typeof (fUploadData) === 'function') {
         await fUploadData(sList.current);
      }
   };

   const getParam = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      const oResponse = await sProps.oManager.fDoAxios('/modal/getOptionListModalList-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         if (oResponse.resultCd === "0000") {
            setOptionList(oResponse.result);
         }
      }
   };

   const addOptionGroup = () => {
      setAddOption(!addOption)
      getParam();
   }

   useEffect(() => {
      getParam();
   }, [])

   return (
      <StoreinfoStyle>
         {addOption ?
            <InsertOptionGroup
               oProps={sProps}
               fnBack={() => setAddOption(!addOption)}
               fnComplete={() => addOptionGroup()}
            />
            :
            <div className="option_group_list_box">
               <div className="eraser_box">
                  <p>옵션그룹 추가</p>
               </div>
               {optionList.length > 0 ?
                  <>
                     <div className="option_text">
                        <p className="optionDivider">※ 원하시는 옵션을 선택하고 '확인' 버튼을 눌러주세요</p>
                        <p className="optionDivider">※ 새로운 옵션그룹을 추가하시려면 <strong style={{ color: "#617BE3" }} onClick={() => setAddOption(!addOption)}>'새 옵션그룹 추가'</strong>를 선택하세요.</p>
                     </div>
                     {optionList.map((item, index) => {
                        return (
                           <OptionContentBox
                              key={index}
                              sData={item}
                              fChangeData={(sIndex, sId, sName, option_type) => changeData(sIndex, sId, sName, option_type)}
                           />
                        )
                     })}
                  </>
                  :
                  // <div className="insert_option_box" onClick={() => setAddOption(!addOption)}>
                  //    <p>새로운 옵션그룹 추가하기</p>
                  // </div>
                  <Link to={WebRoute.ADMIN_PRODUCT_OPTION}>
                     <div className="insert_option_box" >
                        <p>새로운 옵션그룹 추가하기</p>
                     </div>
                  </Link>
               }
            </div>
         }

      </StoreinfoStyle>
   )
};

export const ModalSetProductChoice = ({ sProps, fUploadData }) => {
   const [addMenu, setAddMenu] = useState(false);

   const [optionList, setOptionList] = useState([]);
   const sList = useRef([]);

   const changeData = async (sIndex, sId, sName, min_price) => {
      if (sIndex) {
         let tempList = sList.current;
         let temp = {};
         temp.key = sId;
         temp.name = sName;
         temp.price = Math.floor(min_price) + '원';
         tempList.push(temp);
         sList.current = tempList;
      } else {
         sList.current = sList.current.filter((item) => item.key !== sId);
      }
      if (typeof (fUploadData) === 'function') {
         await fUploadData(sList.current);
      }
   };

   const getParam = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      const oResponse = await sProps.oManager.fDoAxios('/store/register/menuList/type-set/ProductOptionType-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         console.log('oResponse', oResponse)
         if (oResponse.success === true) {
            setOptionList(oResponse.product_option_list);
         }
      }
   };

   const addOptionGroup = () => {
      setAddMenu(!addMenu)
      getParam();
   }

   useEffect(() => {
      getParam();
   }, [])

   return (
      <StoreinfoStyle>
         {addMenu ?
            <InsertOptionGroup
               oProps={sProps}
               fnBack={() => setAddMenu(!addMenu)}
               fnComplete={() => addOptionGroup()}
            />
            :
            <div className="option_group_list_box">
               <div className="eraser_box">
                  <p>옵션그룹 추가</p>
               </div>
               {optionList.length > 0 ?
                  <>
                     <div className="option_text">
                        <p className="optionDivider">※ 원하시는 옵션을 선택하고 '확인' 버튼을 눌러주세요</p>
                        <p className="optionDivider">※ 새로운 옵션그룹을 추가하시려면 <strong style={{ color: "#617BE3" }} onClick={() => setAddMenu(!addMenu)}>'새 옵션그룹 추가'</strong>를 선택하세요.</p>
                     </div>
                     {optionList.map((item, index) => {
                        console.log("optionList : ", item)
                        return (
                           <SetMenuBox
                              key={index}
                              sData={item}
                              fChangeData={(sIndex, sId, sName, min_price) => changeData(sIndex, sId, sName, min_price)}
                           />
                        )
                     })}
                  </>
                  :
                  <>
                     <Link to={WebRoute.ADMIN_PRODUCT_OPTION}>
                        <div className="insert_option_box" >
                           <p>새로운 옵션그룹 추가하기</p>
                        </div>
                     </Link>
                     <p style={{ marginTop: '20px' }}>읍션관리화면 <span>&#62;</span> 상품으로 구성된 세트메뉴 옵션에서 등록 후 이용해주세요. </p>
                  </>
               }
            </div>
         }

      </StoreinfoStyle>
   )
};

export const ModalCouponComamercial = ({ fDeleteData, fUploadData, iCouponList }) => {
   const [sNm, setNm] = useState("");

   const changeData = async (item) => {
      let temp = sNm;
      if (sNm.toString() === item.id.toString()) {
         setNm("");
         temp = "";
      } else {
         setNm(item.id);
         temp = item.id;
      }
      uploadData(temp);
   };

   const uploadData = async (sIndex) => {
      if (fUploadData !== undefined && typeof fUploadData === "function") {
         await fUploadData(sIndex);
      }
   }

   return (
      <StoreinfoStyle>
         <div className="option_group_list_box">
            <div className="eraser_box">
               <p>쿠폰 선택</p>
            </div>
            {iCouponList.length > 0 ?
               <>
                  <div className="commercial_banner_text">
                     <p className="optionDivider">※ 광고에 사용하실 쿠폰을 선택하고 '확인' 버튼을 눌러주세요</p>
                  </div>
                  {iCouponList.map((item, index) => {
                     return (
                        <div className="option_box_data" key={index}>
                           <div className="option_title">
                              <Checkbox defaultChecked={false} checked={(item.id.toString() === sNm.toString()) ? true : false} onChange={() => changeData(item)} style={{ fontSize: "0.9rem", fontWeight: 400 }}>{item.name}</Checkbox>
                           </div>
                        </div>
                     )
                  })}
               </>
               :
               <div className="none_commercial_box">
                  <p>등록된 쿠폰이 없습니다.</p>
               </div>
            }
         </div>
      </StoreinfoStyle>
   )
}

export const ModalHotMenuComamercial = ({ fUploadData, iProductList }) => {
   const [sNm, setNm] = useState("");

   const changeData = async (item) => {
      let temp = sNm;
      if (sNm.toString() === item.id.toString()) {
         setNm("");
         temp = "";
      } else {
         setNm(item.id);
         temp = item.id;
      }
      uploadData(temp);
   };

   const uploadData = async (sIndex) => {
      if (fUploadData !== undefined && typeof fUploadData === "function") {
         await fUploadData(sIndex);
      }
   }

   return (
      <StoreinfoStyle>
         <div className="option_group_list_box">
            <div className="eraser_box">
               <p>인기 상품 선택</p>
            </div>
            {iProductList.length > 0 ?
               <>
                  <div className="commercial_banner_text">
                     <p className="optionDivider">※ 광고에 사용하실 우리매장의 인기상품을 선택하고 '확인' 버튼을 눌러주세요</p>
                  </div>
                  {iProductList.map((item, index) => {
                     return (
                        <div className="option_box_data" key={index}>
                           <div className="option_title">
                              <Checkbox defaultChecked={false} checked={(item.id.toString() === sNm.toString()) ? true : false} onChange={() => changeData(item)} style={{ fontSize: "0.9rem", fontWeight: 400 }}>{item.name}</Checkbox>
                           </div>
                        </div>
                     )
                  })}
               </>
               :
               <div className="none_commercial_box">
                  <p>등록된 상품이 없습니다.</p>
               </div>
            }
         </div>
      </StoreinfoStyle>
   )
}

export const ModalChargedPointComamercial = ({ fnClose, fnChargedPoint }) => {
   const [iPrice, setPrice] = useState(10000);
   const [iPriceWon, setPriceWon] = useState("10,000");

   const onChangeSetPrice = (sIndex) => {
      if (sIndex === "10,000") {
         setPrice(10000);
         setPriceWon("10,000");
      } else if (sIndex === "30,000") {
         setPrice(30000);
         setPriceWon("30,000");
      } else if (sIndex === "50,000") {
         setPrice(50000);
         setPriceWon("50,000");
      } else if (sIndex === "100,000") {
         setPrice(100000);
         setPriceWon("100,000");
      }
   }

   const close = async () => {
      if (fnClose !== undefined && typeof fnClose === "function") {
         await fnClose();
      }
   }

   const chargedPoint = async () => {
      if (fnChargedPoint !== undefined && typeof fnChargedPoint === "function") {
         await fnChargedPoint(iPrice);
      }
   }

   return (
      <StoreinfoStyle>
         <div className="charged_group_list_box">
            <div className="charged_eraser_box">
               <p>충전금액 선택</p>
            </div>
            <div style={{ height: "4vh" }} />
            <div className='charged_select'>
               <div className={iPriceWon === "10,000" ? "charged_selected_box" : "charged_select_box"} onClick={() => onChangeSetPrice("10,000")}>
                  <p>10,000 원</p>
               </div>
            </div>
            <div className='charged_select'>
               <div className={iPriceWon === "30,000" ? "charged_selected_box" : "charged_select_box"} onClick={() => onChangeSetPrice("30,000")}>
                  <p>30,000 원</p>
               </div>
            </div>
            <div className='charged_select'>
               <div className={iPriceWon === "50,000" ? "charged_selected_box" : "charged_select_box"} onClick={() => onChangeSetPrice("50,000")}>
                  <p>50,000 원</p>
               </div>
            </div>
            <div className='charged_select'>
               <div className={iPriceWon === "100,000" ? "charged_selected_box" : "charged_select_box"} onClick={() => onChangeSetPrice("100,000")}>
                  <p>100,000 원</p>
               </div>
            </div>
            <div className="total_amount">
               <SVG src={creditCardBtn} width="2vw" height="2vh" stroke="#001e62" />
               <p>합계 : </p>
               <p style={{ fontSize: "1rem", fontWeight: 800 }}>{iPriceWon} 원</p>
            </div>
            <div className="total_amount_box" onClick={chargedPoint}>
               <p>결제</p>
            </div>
         </div>
      </StoreinfoStyle>
   )
}

export const ModalThrooOnlyComamercial = ({ fDeleteData, fUploadData, iThrooOnlyList }) => {
   const [sNm, setNm] = useState("");

   const changeData = async (item) => {
      let temp = sNm;
      if (sNm.toString() === item.id.toString()) {
         setNm("");
         temp = "";
      } else {
         setNm(item.id);
         temp = item.id;
      }
      uploadData(temp);
   };

   const uploadData = async (sIndex) => {
      if (fUploadData !== undefined && typeof fUploadData === "function") {
         await fUploadData(sIndex);
      }
   }

   return (
      <StoreinfoStyle>
         <div className="option_group_list_box">
            <div className="eraser_box">
               <p>스루 온리 상품 선택</p>
            </div>
            {iThrooOnlyList.length > 0 ?
               <>
                  <div className="commercial_banner_text">
                     <p className="optionDivider">※ 광고에 사용하실 '스루 온리 상품'을 선택하고 '확인' 버튼을 눌러주세요</p>
                  </div>
                  {iThrooOnlyList.map((item, index) => {
                     return (
                        <div className="option_box_data" key={index}>
                           <div className="option_title">
                              <Checkbox defaultChecked={false} checked={(item.id.toString() === sNm.toString()) ? true : false} onChange={() => changeData(item)} style={{ fontSize: "0.9rem", fontWeight: 400 }}>{item.name}</Checkbox>
                           </div>
                        </div>
                     )
                  })}
               </>
               :
               <div className="none_commercial_box">
                  <p>등록된 상품이 없습니다.</p>
               </div>
            }
         </div>
      </StoreinfoStyle>
   )
}

export const ModalBannerComamercial = ({ fDeleteData, fUploadData, iProps, fUploadTitleData, fUploadSubTitleData }) => {
   const [sPreviewImage, setPreviewImage] = useState("");
   const [loading, setLoading] = useState(false);

   const [sTitle, setTitle] = useState("");
   const [sSubTitle, setSubTitle] = useState("");

   const uploadLogoImage = async (e) => {
      let origin = e.target.files[0];
      if (origin !== undefined) {
         if (origin.type !== undefined && origin.type !== null) {
            if (origin.type === "image/png" || origin.type === "image/jpeg" || origin.type === "image/jpg") {
               let tempImage = await getBase64(origin);
               uploadAllFunc(tempImage, origin);
            }
         }
      }
   }

   const imageProcess = async (fileList) => {
      let logoUpload = {};
      const formData = new FormData();
      formData.append('photo', fileList);

      const oResponse = await iProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
      if (oResponse !== undefined) {
         if (oResponse.file_name !== undefined) {
            logoUpload = oResponse;
         }
      }
      return logoUpload;
   }

   const uploadAllFunc = async (nIndex, sIndex) => {
      setLoading(true);
      let isProcess = false;
      let uploadFile = {};

      if (sIndex !== "") {
         const upload = await imageProcess(sIndex);
         if (upload === "") {
            message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
         } else {
            isProcess = true;
            uploadFile = upload;
         }

      }
      if (isProcess) {
         if (fUploadData !== undefined && typeof fUploadData === "function") {
            await fUploadData(uploadFile);
            setPreviewImage(nIndex);
         }
      }
      setLoading(false);
   }

   const cancelLogoImage = async () => {
      setPreviewImage("");
      if (fDeleteData !== undefined && typeof fDeleteData === "function") {
         await fDeleteData();
      }
   }

   const onChangeTitleValue = async (e) => {
      setTitle(e.target.value);
      if (fUploadTitleData !== undefined && typeof fUploadTitleData === "function") {
         await fUploadTitleData(e.target.value);
      }
   }

   const onChangeSubTitleValue = async (e) => {
      setSubTitle(e.target.value);
      if (fUploadSubTitleData !== undefined && typeof fUploadSubTitleData === "function") {
         await fUploadSubTitleData(e.target.value);
      }
   }

   return (
      <StoreinfoStyle>
         {loading ?
            <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
               <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
            </div>
            :
            <div className="commercial_main_banner_box">
               <div className="eraser_box">
                  <p>메인 배너광고 선택</p>
               </div>
               <div style={{ height: "5vh" }} />
               <div className="category_picture">
                  <p>배너 이미지</p>
                  <div className="image_line">
                     {sPreviewImage !== "" ?
                        <div className="image_box">
                           <img src={sPreviewImage} alt="none_image" />
                        </div>
                        :
                        <div className="none_image_box">
                           <img src={noneImageBox} alt="none_image" />
                        </div>
                     }
                     <div className="image_box_button">
                        <div className="filebox">
                           <label htmlFor="menu_file">
                              <div className="edit_image">
                                 <p>등록</p>
                              </div>
                              <input type="file" id="menu_file" onChange={(e) => uploadLogoImage(e)} />
                           </label>
                        </div>
                        <div className={sPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage()}><p>삭제</p></div>
                     </div>
                  </div>
               </div>
               <div className="option_group_title">
                  <p>배너광고 제목</p>
                  <input
                     type="text"
                     maxlength={16}
                     placeholder="예)스루에서 최대 10% 할인중"
                     value={sTitle}
                     onChange={onChangeTitleValue}
                  />
               </div>
               <div className="option_group_title">
                  <p>배너광고 부제목</p>
                  <input
                     type="text"
                     maxlength={8}
                     placeholder="예) 지금 바로 이동"
                     value={sSubTitle}
                     onChange={onChangeSubTitleValue}
                  />
               </div>
            </div>
         }
      </StoreinfoStyle>
   )
}
export const ModalSetProductRelease = ({ sProps, fUploadData, iOptions }) => {
   const [addOption, setAddOption] = useState(false);

   const [optionList, setOptionList] = useState([]);
   const [hasOption, setHasOption] = useState([]);
   const sList = useRef([]);
   const changeData = async (sIndex, sId, sName, min_price) => {
      if (sIndex) {
         let tempList = sList.current;
         let temp = {};
         temp.key = sId;
         temp.name = sName;
         temp.price = Math.floor(min_price) + '원';
         tempList.push(temp);
         sList.current = tempList;
      } else {
         sList.current = sList.current.filter((item) => item.key !== sId);
      }
      if (typeof (fUploadData) === 'function') {
         await fUploadData(sList.current);
      }
   };

   const getParam = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      console.log('iOptions>>>', iOptions)

      if (iOptions && iOptions.length > 0) {
         let tempList = [];
         for await (const iterator of iOptions) {
            let temp = {};
            temp.key = parseInt(iterator.key);
            temp.name = iterator.name ? iterator.name.toString() : '';
            temp.price = iterator.price ? iterator.price.toString() : '';
            tempList.push(temp);
         }
         sList.current = tempList;
         setHasOption(iOptions);
      }

      const oResponse = await sProps.oManager.fDoAxios('/store/register/menuList/type-set/ProductOptionType-' + store_id, "get", null, null);
      if (oResponse && oResponse.success === true) {
         setOptionList(oResponse.product_option_list);
      }
   }

   const addOptionGroup = () => {
      setAddOption(!addOption)
      getParam();
   }


   useEffect(() => {
      getParam();
   }, [])
   console.log()

   return (
      <StoreinfoStyle>
         {addOption ?
            <InsertOptionGroup
               oProps={sProps}
               fnBack={() => setAddOption(!addOption)}
               fnComplete={() => addOptionGroup()}
            />
            :
            <div className="option_group_list_box">
               <div className="eraser_box">
                  <p>옵션그룹 추가</p>
               </div>
               {optionList.length > 0 ?
                  <>
                     <div className="option_text">
                        <p className="optionDivider">※ 원하시는 옵션을 선택하고 '확인' 버튼을 눌러주세요</p>
                        <p className="optionDivider">※ 새로운 옵션그룹을 추가하시려면 <strong style={{ color: "#617BE3" }} onClick={() => setAddOption(!addOption)}>'새 옵션그룹 추가'</strong>를 선택하세요.</p>
                     </div>
                     {optionList.map((item, index) => {
                        return (
                           <SetMenuBox
                              key={index}
                              sData={item}
                              iHasOption={hasOption}
                              fChangeData={(sIndex, sId, sName, min_price) => changeData(sIndex, sId, sName, min_price)}
                           />
                        )
                     })}
                  </>
                  :
                  <div className="insert_option_box" onClick={() => setAddOption(!addOption)}>
                     <p>새로운 옵션그룹 추가하기</p>
                  </div>
                  // <Link to={WebRoute.ADMIN_PRODUCT_OPTION}>
                  //    <div className="insert_option_box" >
                  //       <p>새로운 옵션그룹 추가하기</p>
                  //    </div>
                  // </Link>
               }
            </div>
         }
      </StoreinfoStyle>
   )
};

export const ModalOptionRelease = ({ sProps, fUploadData, iOptions }) => {
   const [addOption, setAddOption] = useState(false);

   const [optionList, setOptionList] = useState([]);
   const [hasOption, setHasOption] = useState([]);
   const sList = useRef([]);


   const changeData = async (sIndex, sId, sName, option_type) => {
      if (sIndex) {
         // option_type
         let tempList = sList.current;
         let temp = {};


         temp.key = sId;
         temp.name = sName;
         temp.option_type = option_type;
         tempList.push(temp);
         sList.current = tempList;
      } else {
         sList.current = sList.current.filter((item) => item.key !== sId);
      }
      if (typeof (fUploadData) === 'function') {
         await fUploadData(sList.current);
      }
   };

   const getParam = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      if (iOptions !== undefined && iOptions.length > 0) {
         let tempList = [];
         for await (const iterator of iOptions) {
            console.log('iterator', iterator)
            let temp = {};
            temp.key = parseInt(iterator.key);
            temp.name = iterator.name.toString();
            temp.option_type = iterator.option_type;
            tempList.push(temp);
         }
         sList.current = tempList;
         setHasOption(iOptions);
      }

      const oResponse = await sProps.oManager.fDoAxios('/modal/getOptionListModalList-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         if (oResponse.resultCd === "0000") {
            setOptionList(oResponse.result);
         }
      }
   };

   const addOptionGroup = () => {
      setAddOption(!addOption)
      getParam();
   }


   useEffect(() => {
      getParam();
   }, [])

   return (
      <StoreinfoStyle>
         {addOption ?
            <InsertOptionGroup
               oProps={sProps}
               fnBack={() => setAddOption(!addOption)}
               fnComplete={() => addOptionGroup()}
            />
            :
            <div className="option_group_list_box">
               <div className="eraser_box">
                  <p>옵션그룹 추가</p>
               </div>
               {optionList.length > 0 ?
                  <>
                     <div className="option_text">
                        <p className="optionDivider">※ 원하시는 옵션을 선택하고 '확인' 버튼을 눌러주세요</p>
                        <p className="optionDivider">※ 새로운 옵션그룹을 추가하시려면 <strong style={{ color: "#617BE3" }} onClick={() => setAddOption(!addOption)}>'새 옵션그룹 추가'</strong>를 선택하세요.</p>
                     </div>
                     {optionList.map((item, index) => {
                        console.log('optionListoptionList>>', optionList)
                        return (
                           <OptionContentBox
                              key={index}
                              sData={item}
                              iHasOption={hasOption}
                              fChangeData={(sIndex, sId, sName, option_type) => changeData(sIndex, sId, sName, option_type)}
                           />
                        )
                     })}
                  </>
                  :
                  <div className="insert_option_box" onClick={() => setAddOption(!addOption)}>
                     <p>새로운 옵션그룹 추가하기</p>
                  </div>
               }
            </div>
         }
      </StoreinfoStyle>
   )
};


export const ModalFrontPage = ({ sProps, sType, logoImage, storeImage1, storeImage2, storeImage3 }) => {
   const [iStoreName, setStoreName] = useState();
   const [sImage, setImage] = useState([
      { key: "https://api-stg.throo.kr/img/no-image-new.png" }
   ]);

   const [sParkingTime, setParkingTime] = useState("");
   const [iStorePhone, setIStorePhone] = useState("");
   const [sAddress, setAddress] = useState("");

   const getInfo = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      const oResponse = await sProps.oManager.fDoAxios('/modal/modalFrontPage-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         const minuteList = [];
         if (sType === "img") {
            for (let i = 0; i < 4; i++) {
               if (i === 0 && logoImage !== "") {
                  minuteList.push({
                     key: logoImage,
                  });
               } else if (i === 1 && storeImage1 !== "") {
                  minuteList.push({
                     key: storeImage1,
                  });
               } else if (i === 2 && storeImage2 !== "") {
                  minuteList.push({
                     key: storeImage2,
                  });
               } else if (i === 3 && storeImage3 !== "") {
                  minuteList.push({
                     key: storeImage3,
                  });
               }
            }
         } else {
            for (let i = 0; i < 4; i++) {
               if (i === 0 && oResponse.logoImage !== null && oResponse.logoImage !== "") {
                  minuteList.push({
                     key: oResponse.logoImage,
                  });
               } else if (i === 1 && oResponse.storeImage1 !== null && oResponse.storeImage1 !== "") {
                  minuteList.push({
                     key: oResponse.storeImage1,
                  });
               } else if (i === 2 && oResponse.storeImage2 !== null && oResponse.storeImage2 !== "") {
                  minuteList.push({
                     key: oResponse.storeImage2,
                  });
               } else if (i === 3 && oResponse.storeImage3 !== null && oResponse.storeImage3 !== "") {
                  minuteList.push({
                     key: oResponse.storeImage3,
                  });
               }
            }
         }

         minuteList.length > 0 && setImage(minuteList);
         setStoreName(oResponse.storeName);
         setParkingTime(oResponse.parkingTime);
         setIStorePhone(oResponse.storePhone);
         setAddress(oResponse.address);
      }
   }

   useEffect(() => {
      getInfo();
   }, [])

   return (
      <StoreinfoStyle>
         <div className="storeintro">
            <p>고객님께 보여지는 미리보기화면입니다</p>
            <div className="introtitle">
               <Carousel autoplay style={{ marginTop: "5%", marginBottom: "5%" }}>
                  {sImage[0] !== undefined && sImage[0].key !== null && sImage[0].key !== "" &&
                     <div>
                        <img src={sImage[0].key} width='100%' height='300px' alt='logo' />
                     </div>
                  }
                  {sImage[1] !== undefined && sImage[1].key !== null && sImage[1].key !== "" &&
                     <div>
                        <img src={sImage[1].key} width='100%' height='300px' alt='logo' />
                     </div>
                  }
                  {sImage[2] !== undefined && sImage[2].key !== null && sImage[2].key !== "" &&
                     <div>
                        <img src={sImage[2].key} width='100%' height='300px' alt='logo' />
                     </div>
                  }
                  {sImage[3] !== undefined && sImage[3].key !== null && sImage[3].key !== "" &&
                     <div>
                        <img src={sImage[3].key} width='100%' height='300px' alt='logo' />
                     </div>
                  }
               </Carousel>
               <h2>{iStoreName}</h2>
               <div className="intro_time">
                  <SVG src={ClockSvg} width={15} height="100%" title="clock" fill="#6b7684" />
                  <p className="introtime">09 ~ 10 (예시)</p>
               </div>

               <div className="introcontent">
                  <div className="street">
                     <SVG src={StreetSvg} width={15} height="100%" title="street" stroke="#617be3" />
                     <p><span>거리</span> 90m(예시)</p>
                  </div>
                  <div className="waiting">
                     <SVG src={WiatingSvg} width={16} height="100%" title="wait" stroke="#617be3" />
                     <p><span>준비</span> 10분(예시)</p>
                  </div>
                  <div className="parking">
                     <SVG src={ParkSvg} width={15} height="100%" title="park" stroke="#617be3" />
                     <p><span>정차</span> {sParkingTime}분</p>
                  </div>
               </div>

               <ul className="introcefooter">
                  <li>전화번호: <span>{iStorePhone}</span></li>
                  <li>위치안내: {sAddress}</li>
               </ul>
            </div>
         </div>
      </StoreinfoStyle>
   )
};


export const ModalBackPage = ({ sProps }) => {
   const [userLat, setUserLat] = useState(null);
   const [userLng, setUserLng] = useState(null);
   const [storeOwner, setOwner] = useState("미정");
   const [storeBN, setStoreBN] = useState("미정");
   const [storeInfo, setStoreInfo] = useState("미정");
   const [storeNoti, setStoreNoti] = useState("미정");
   const [menuDetail, setMenuDetail] = useState("미정");
   const [storeTime, setStoreTime] = useState("미정");

   const getParam = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      const oResponse = await sProps.oManager.fDoAxios('/modal/modalBackPage-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         setUserLat(oResponse.userLat);
         setUserLng(oResponse.userLng);
         setOwner(oResponse.storeOwner);
         setStoreBN(oResponse.storeBN);
         setStoreInfo(oResponse.storeInfo);
         setStoreNoti(oResponse.storeNoti);
         setMenuDetail(oResponse.menuDetail);
         setStoreTime(oResponse.storeTime);
      }
   }

   useEffect(() => {
      getParam();
   }, [])

   return (
      <StoreinfoStyle>
         <div className="storeintro" >
            <p>고객님께 보여지는 미리보기화면입니다</p>
            <div className="intromore">
               {userLat !== null && userLat !== null ?
                  <KaKaoMap sUserLat={userLat} sUserLng={userLng} />
                  :
                  <div style={{ height: "25vh" }} />
               }
               <div className="store_title">
                  <h2>매장정보</h2>
                  <p>대표자명: {storeOwner} </p>
                  <p>사업자등록번호: {storeBN}</p>
               </div>
               <div className="store_time">
                  <h2>영업시간</h2>
                  <p>{storeTime}</p>
               </div>
               <div className="store_content">
                  <h2>매장소개</h2>
                  <p>{storeInfo}</p>
               </div>
               <div className="store_notice">
                  <h2>공지사항</h2>
                  <p>{storeNoti}</p>
               </div>
               <div className="store_food">
                  <h2>원산지정보</h2>
                  <p>{menuDetail}</p>
               </div>
            </div>
         </div>
      </StoreinfoStyle>
   )
};

export const ModalCommercialPage1 = ({ sProps }) => {

   return (
      <Style>
         <div className="main_top" />
         <div className="eraser_box">
            <p>메인 배너광고</p>
         </div>
         <div className="eraser_box1">
            <p>스루 앱 가장 처음 상단에 나타나는 메인 배너광고입니다. 고객에게 가장 먼저 보이기 때문에 노출도가 가장 높습니다.</p>
         </div>
         <div className="commercial_image_wrapper2">
            <img src={appBanner01} alt="none" />
         </div>
      </Style>
   )
};

export const ModalCommercialPage2 = ({ sProps }) => {

   return (
      <Style>
         <div className="main_top" />
         <div className="eraser_box">
            <p>내 주변 쿠폰 광고</p>
         </div>
         <div className="eraser_box1">
            <p>매장에 쿠폰이 등록되어 있을 경우에만 노출됩니다. 쿠폰을 등록한 뒤 광고를 통해 쿠폰 효과를 올려보세요.</p>
         </div>
         <div className="commercial_image_wrapper2">
            <img src={appBanner02} alt="none" width={"10vw"} />
         </div>
      </Style>
   )
};

export const ModalCommercialPage3 = ({ sProps }) => {

   return (
      <Style>
         <div className="main_top" />
         <div className="eraser_box">
            <p>스루 온리 광고</p>
         </div>
         <div className="eraser_box1">
            <p>스루 온리 광고는 한시적으로 무료이며 최상단에 노출됩니다. 스루에서만 구매할 수 있는 상품을 등록하셨다면 자동으로 광고가 적용되어 더 많은 고객에게 상품이 노출됩니다. 적극 활용해 보세요!</p>
         </div>
         <div className="commercial_image_wrapper2">
            <img src={appBanner03} alt="none" />
         </div>
      </Style>
   )
};

export const ModalCommercialPage4 = ({ sProps }) => {

   return (
      <Style>
         <div className="main_top" />
         <div className="eraser_box">
            <p>신규 입점 광고</p>
         </div>
         <div className="eraser_box1">
            <p>입점일 기준 6개월 이내의 매장들만 노출됩니다. 스루 영업 시작을 더 많은 고객에게 알려보세요.</p>
         </div>
         <div className="commercial_image_wrapper2">
            <img src={appBanner04} alt="none" />
         </div>
      </Style>
   )
};

export const ModalCommercialPage5 = ({ sProps }) => {

   return (
      <Style>
         <div className="main_top" />
         <div className="eraser_box">
            <p>핫메뉴 광고</p>
         </div>
         <div className="eraser_box1">
            <p>우리 매장의 인기 상품을 광고해 보세요. 우리 매장의 인기상품이 여러 개라면 개수 제한 없이 모두 고객에게 알릴 수 있습니다.</p>
         </div>
         <div className="commercial_image_wrapper2">
            <img src={appBanner05} alt="none" />
         </div>
      </Style>
   )
};

export const ModalCouponDeletePage = ({ sProps }) => {
   return (
      <Style>
         <div className="main_top" />
         <div className="notice_title">
            <p>주의사항</p>
         </div>
         <div className="notice_subTitle">
            <p>쿠폰삭제시 이미 해당 쿠폰을 </p>
            <p>다운받은 고객은 적용되지 않습니다</p>
            <p>삭제하시겠습니까?</p>
         </div>
      </Style>
   )
}

export const ModalCategoryPage = ({ sProps }) => {
   const [iLoading, setILoading] = useState(true);
   const [sLoading, setSLoading] = useState(true);

   const [isMenu, setIsMenu] = useState(false);

   const [categoryList, setCategoryList] = useState([]);
   const [menuList, setMenuList] = useState([]);
   const [menuInfo, setMenuInfo] = useState({});

   const changeMode = () => {
      setIsMenu(!isMenu);
   }

   const modalCategory = async () => {
      const store_id = sProps.UserConfigReducer.StoreID;
      const oResponse = await sProps.oManager.fDoAxios('/modal/modalCategory/v2-' + store_id, "get", null, null);
      if (oResponse !== undefined) {
         if (oResponse.length > 0) {
            setCategoryList(oResponse)
            modalMenu(oResponse[0].key);
         }
      }
      setILoading(false);
   }
   const modalMenu = async (sIndex) => {
      const category_id = sIndex;
      const oResponse = await sProps.oManager.fDoAxios('/modal/modalMenu/v2-' + category_id, "get", null, null);
      if (oResponse !== undefined) {
         setMenuList(oResponse);
      }
      setSLoading(false);
   }

   const getMenu = async (sIndex) => {
      const menu_id = sIndex.id;
      const oResponse = await sProps.oManager.fDoAxios('/modal/modalMenuDetail/v2-' + menu_id, "get", null, null);
      if (oResponse !== undefined) {
         const oData = {
            base_price: oResponse.base_price,
            optionYn: oResponse.optionYn,
            option_name: oResponse.option_name,
            product_name: oResponse.product_name,
            url_path: oResponse.url_path,
            optionList: oResponse.optionList,
         }
         setMenuInfo(oData);
      }
      setIsMenu(!isMenu);
   }

   useEffect(() => {
      modalCategory();
   }, [])

   return (
      <StoreinfoStyle>
         {!isMenu ?
            <CategoryLine
               iLoading={iLoading}
               sLoading={sLoading}
               categoryList={categoryList}
               menuList={menuList}
               fnModalMenu={(sIndex) => modalMenu(sIndex)}
               fnGetMenu={(sIndex) => getMenu(sIndex)}
            />
            :
            <MenuLine
               menuInfo={menuInfo}
               fnChangeMode={() => changeMode()}
            />
         }
      </StoreinfoStyle>
   )
};


export const WelcomePage = ({ sProps }) => {
   let sDomainUrl = "https://ceo.throo.co.kr";
   if (process.env !== undefined && process.env.REACT_APP_CEO_WEBURL !== undefined) {
      sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
   }
   const openPosPage = () => {
      window.open(sDomainUrl + '/download', '_blank');
   }
   const openEventPage = () => {
      window.open(sDomainUrl + '/promotion', '_blank');
   }

   return (
      <Style>
         <div className="welcome-title">
            <p>사장님, 수고하셨습니다.</p>
         </div>
         <div className="main_subTitle">
            <p>스루 영업을 위한 모든 등록과정을 완료하셨습니다.</p>
         </div>
      </Style>
   )

   return (
      <Style>
         <div className="main_top" />
         <div className="main_title">
            <p>사장님, 수고하셨습니다.</p>
         </div>
         <div className="main_subTitle">
            <p>스루 영업을 위한 모든 등록과정을 완료하셨습니다.</p>
         </div>
         <div className="image_wrapper">
            <div onClick={openPosPage}>
               <img src={Posdownload} alt="none" />
            </div>

         </div>
         <div className="img_subTitle">
            <p>*주문접수프로그램을 설치하고 로그인하면 스루앱에서 '영업중'으로 작동합니다.</p>
         </div>
      </Style>

   )
}

export const MobileWelcomePage = ({ sProps }) => {
   let sDomainUrl = "https://ceo.throo.co.kr";
   if (process.env !== undefined && process.env.REACT_APP_CEO_WEBURL !== undefined) {
      sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
   }
   const openPosPage = () => {
      window.open(sDomainUrl + '/download', '_blank');
   }
   const openEventPage = () => {
      window.open(sDomainUrl + '/promotion', '_blank');
   }

   return (
      <MobileStyle>
         <div className="main_top" />
         <div className="main_title">
            <p>사장님, 수고하셨습니다.</p>
         </div>
         <div className="main_subTitle">
            <p>스루 영업을 위한 모든 등록과정을 완료하셨습니다.</p>
         </div>
         <div className="pos_box" onClick={openPosPage}>
            <img src={Posdownload} alt="none" />
         </div>
         <div className="img_subTitle">
            <p>주문접수프로그램을 설치하고 로그인하면<br /> 스루앱에서 '영업중'으로 작동합니다.</p>
         </div>
      </MobileStyle>

   )
}

const MobileStyle = styled.div`
    * {
        top: 5%;
    }

    .main_top {
        position: absolute;
        height: 6vh;
        background: #fff;
        width: 100px;
    }
   
    .main_title {
        padding-top: 15%;
        height: 10vh;
        p {
            color: #001E62;
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 16.5px;
        }
    }

    .welcome-title {
      p {
         color: #001E62;
         font-size: 1.8rem;
         font-weight: 600;
         line-height: 16.5px;
      }
    }

    .main_subTitle {
        height: 5vh;
        p {
            color: #617BE3;
            font-size: 0.9rem;
            font-weight: 600;
        }

    }
    .event_box {
        img {
            width: 100%;
            height: 20vh;
        }
    }

    .pos_box {
        margin-top: 5%;
        img {
            width: 100%;
            height: 15vh;
        }
    }

    .img_subTitle {
        p {
            color: #617BE3;
            font-size: 0.8rem;
            font-weight: 400;
        }
    }
`;

const Style = styled.div`
    
    * {
        top: 5%;
    }

    .main_top {
        position: absolute;
        height: 6vh;
        background: #fff;
        width: 100px;
    }

    .eraser_box {
        width: 85%;
        position: absolute;
        background: #fff;
        height: 8vh;
        display: flex;
        justify-content: center; 
        text-align: center;

        p {
            font-size: 1rem;
            font-weight: 900;
        }
    }

    .eraser_box1 {
        width: 85%;
        background: #fff;
        margin-top: 5vh;
        height: 5vh;
        display: flex;
        justify-content: center; 
        text-align: center;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }

    .notice_title {
        padding-top: 10%;
        height: 10vh;
        p {
            color: #001E62;
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 16.5px;
        }
    }

    .notice_subTitle {
        height: 15vh;
        p {
            color: #617BE3;
            font-size: 1.1rem;
            font-weight: 600;
        }
    }
    .main_title {
        padding-top: 10%;
        height: 13vh;
        p {
            color: #001E62;
            font-size: 2rem;
            font-weight: 600;
            line-height: 16.5px;
        }
    }

    .welcome-title {
      p {
         color: #001E62;
         font-size: 2rem;
         font-weight: 600;
         line-height: 2.3px;
         padding-top: 1rem;
      }
    }

    .main_commercial_title {
        padding-top: 10%;
        height: 5vh;
        p {
            color: #001E62;
            font-size: 1rem;
            font-weight: 600;
            line-height: 16.5px;
        }
    }
    .main_subTitle {
        height: 5vh;
        p {
            color: #617BE3;
            font-size: 1.1rem;
            font-weight: 600;
        }

    }

    .img_subTitle {
        p {
            color: #617BE3;
            font-size: 0.8rem;
            font-weight: 400;
        }
    }

    .image_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        img {
            width: 100%;
        }
    }

    .commercial_image_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60vh;

        img {
            width: 80%;
            height: 90%;
        }
    }

    .commercial_image_wrapper2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60vh;

        img {
            width: 100%;
            height: 90%;
        }
    }
`;
