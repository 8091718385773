import React from "react";
import { Spring } from "react-spring/renderprops";

const AccordionContent = props => {
  const {
    open = false,
    children = null,
    style: contentStyles = {},
    ...restProps
  } = props;
  return (
    <Spring from={{ height: 0 }} to={{ height: open ? "auto" : 0 }}>
      {props => (
        <div style={{ ...props, ...contentStyles, overflow: "hidden" }} {...restProps}>
          {children}
        </div>
      )}
    </Spring>
  );
};

export default AccordionContent;
