import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Loader from "react-loader-spinner";
import { message, Modal } from 'antd';
import "antd/dist/antd.css";

import manual from '../../../../assets/svg/manual.svg';
import addressSearch from '../../../../assets/svg/search_black.svg';
import noneImageBox from '../../../../assets/svg/none_image.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';

import { ModalFrontPage } from '../../../modal/AppModal';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const Pictures = ({ oProps }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [sPreviewImage, setPreviewImage] = useState("");
    const [nPreviewImage, setNPreviewImage] = useState("");
    const [kPreviewImage, setKPreviewImage] = useState("");
    const [iPreviewImage, setIPreviewImage] = useState("");

    const [modal, contextHolder] = Modal.useModal();

    const uploadLogoImage = async (e, sIndex) => {
        let origin = e.target.files[0];
        if (origin !== undefined) {
            if (origin.type !== undefined && origin.type !== null) {
                if (origin.type === "image/png" || origin.type === "image/jpeg") {
                    let tempImage = await getBase64(origin);
                    setIsLoading(true);
                    await uploadAllFunc(tempImage, origin, sIndex);
                } else {
                    message.error("파일형식은 jpg나 png만 가능합니다.");
                }
            }
        }
    }

    const modalOpen = {
        content: (
            <ModalFrontPage sProps={oProps} sType={"img"} logoImage={sPreviewImage} storeImage1={nPreviewImage} storeImage2={kPreviewImage} storeImage3={iPreviewImage} />
        )
    };

    const cancelLogoImage = (kIndex) => {
        setIsLoading(true);
        deleteMediaData(kIndex);
    }

    const uploadAllFunc = async (nIndex, sIndex, kIndex) => {
        let isProcess = true;
        let uploadFile = {};

        if (sIndex !== "") {
            const upload = await imageProcess(sIndex);
            if (upload === "") {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                isProcess = false;
            } else {
                uploadFile = upload;
            }
        }

        if (sIndex !== "") {
            if (isProcess) {
                const oData = {
                    store_id: oProps.UserConfigReducer.StoreID,
                    imgData: uploadFile,
                    imageType: kIndex
                }
                const oResponse = await oProps.oManager.fDoAxios("/store/register/registerImage/v2", "post", "login", oData);
                if (oResponse !== undefined) {
                    if (!oResponse) {
                        message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                    } else {
                        message.info("정상적으로 저장이 완료되었습니다.");
                        if (kIndex.toString() === "logo_file") {
                            setPreviewImage(nIndex);
                        } else if (kIndex.toString() === "menu1_file") {
                            setNPreviewImage(nIndex);
                        } else if (kIndex.toString() === "menu2_file") {
                            setKPreviewImage(nIndex);
                        } else {
                            setIPreviewImage(nIndex);
                        }
                    }
                }
            }
        }
        getParam();
    }

    const deleteMediaData = async (kIndex) => {
        const oData = {
            store_id: oProps.UserConfigReducer.StoreID,
            imageType: kIndex
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/deleteImage/v2", "post", "login", oData);
        if (oResponse !== undefined) {
            if (!oResponse) {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                message.info("삭제 처리되었습니다.");
                if (kIndex.toString() === "logo_file") {
                    setPreviewImage("");
                } else if (kIndex.toString() === "menu1_file") {
                    setNPreviewImage("");
                } else if (kIndex.toString() === "menu2_file") {
                    setKPreviewImage("");
                } else {
                    setIPreviewImage("");
                }
            }
        }
        getParam();
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};
        const formData = new FormData();
        formData.append('photo', fileList);

        const oResponse = await oProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
        if (oResponse !== undefined) {
            if (oResponse.file_name !== undefined) {
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }

    const getParam = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/getStoreImage/v2-" + store_id, "get", "login", null);
        if (oResponse !== undefined && oResponse !== null) {
            setPreviewImage(oResponse.url_path_logo);
            setNPreviewImage(oResponse.url_path_first);
            setKPreviewImage(oResponse.url_path_second);
            setIPreviewImage(oResponse.url_path_third);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getParam();
    }, []);

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.store}>
            <BrowserView>
                <Style>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <div className="container">
                            <div className="title_box">
                                <h2>매장 사진</h2>
                                <div className="tip">
                                    <SVG src={manual} width="18px" height="18px" />
                                    <span><strong>매장의 사진</strong>또는 관련된<br />사진을 설정 및 확인합니다.</span>
                                </div>
                            </div>
                            <div className="pictures_title" style={{ marginBottom: "13%", marginTop: "2%" }}>
                                <p>로고 이미지</p>
                                <div className="image_line">
                                    {sPreviewImage !== "" ?
                                        <div className="image_box">
                                            <img src={sPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="none_image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="logo_file">
                                                {sPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="logo_file" onChange={(e) => uploadLogoImage(e, "logo_file")} />
                                            </label>
                                        </div>
                                        <div className={sPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("logo_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 1, backgroundColor: "#dfdfdf" }} />
                            <div className="pictures_list_title" style={{ marginBottom: "13%", marginTop: "5%" }}>
                                <p>매장 이미지</p>
                                <div className="image_line">
                                    {nPreviewImage !== "" ?
                                        <div className="image_box">
                                            <img src={nPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="none_image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="menu1_file">
                                                {nPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="menu1_file" onChange={(e) => uploadLogoImage(e, "menu1_file")} />
                                            </label>
                                        </div>
                                        <div className={nPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("menu1_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                                <div className="image_line">
                                    {kPreviewImage !== "" ?
                                        <div className="image_box">
                                            <img src={kPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="none_image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="menu2_file">
                                                {kPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="menu2_file" onChange={(e) => uploadLogoImage(e, "menu2_file")} />
                                            </label>
                                        </div>
                                        <div className={kPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("menu2_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                                <div className="image_line">
                                    {iPreviewImage !== "" ?
                                        <div className="image_box">
                                            <img src={iPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="none_image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="menu3_file">
                                                {iPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="menu3_file" onChange={(e) => uploadLogoImage(e, "menu3_file")} />
                                            </label>
                                        </div>
                                        <div className={iPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("menu3_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="side_help_content">
                        <div className="app_modal_container" onClick={() => modal.success(modalOpen)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img src={addressSearch} alt="none" />
                                    <p>앱 미리보기</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>내 입력정보의 예상화면을 <br />미리 확인해보세요</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {contextHolder}
                </Style>
            </BrowserView>
            <MobileView>

                <Mstyle>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <div className="category_title_line">
                            <div className="category_title">
                                <div className="title_box">
                                    <p className="title_line">매장사진</p>
                                </div>
                                <div className="tip">
                                    <SVG src={manual} width="18px" height="18px" />
                                    <span><strong>매장의 사진</strong>또는 관련된<br />사진을 설정 및 확인합니다.</span>
                                </div>
                            </div>
                            <div className="inputText">
                                <p>로고 이미지</p>
                                <div className="image_line" style={{ marginBottom: "3%" }}>
                                    {sPreviewImage !== "" ?
                                        <div className="none_image_box">
                                            <img src={sPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="logo_file">
                                                {sPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="logo_file" onChange={(e) => uploadLogoImage(e, "logo_file")} />
                                            </label>
                                        </div>
                                        <div className={sPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("logo_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 1, background: "#dfdfdf" }} />
                            <div className="inputText" style={{ marginTop: "7%" }}>
                                <p>로고 이미지</p>
                                <div className="image_line">
                                    {nPreviewImage !== "" ?
                                        <div className="none_image_box">
                                            <img src={nPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="menu1_file">
                                                {nPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="menu1_file" onChange={(e) => uploadLogoImage(e, "menu1_file")} />
                                            </label>
                                        </div>
                                        <div className={nPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("menu1_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                                <div className="image_line">
                                    {kPreviewImage !== "" ?
                                        <div className="none_image_box">
                                            <img src={kPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="menu2_file">
                                                {kPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="menu2_file" onChange={(e) => uploadLogoImage(e, "menu2_file")} />
                                            </label>
                                        </div>
                                        <div className={kPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("menu2_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                                <div className="image_line">
                                    {iPreviewImage !== "" ?
                                        <div className="none_image_box">
                                            <img src={iPreviewImage} alt="none_image" />
                                        </div>
                                        :
                                        <div className="image_box">
                                            <img src={noneImageBox} alt="none_image" />
                                        </div>
                                    }
                                    <div className="image_box_button">
                                        <div className="filebox">
                                            <label htmlFor="menu3_file">
                                                {iPreviewImage === "" ?
                                                    <div className="register_image">
                                                        <p>등록</p>
                                                    </div>
                                                    :
                                                    <div className="edit_image">
                                                        <p>변경</p>
                                                    </div>
                                                }
                                                <input type="file" id="menu3_file" onChange={(e) => uploadLogoImage(e, "menu3_file")} />
                                            </label>
                                        </div>
                                        <div className={iPreviewImage !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage("menu3_file")}><p>삭제</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className="app_modal_container">
                <div className="app_modal" onClick={modalPopUp}>
                    <p>앱 미리보기</p>
                    <img src={searchBlack} alt="search" style={{marginLeft: "5%"}}/>
                </div>
                <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
            </div> */}
                </Mstyle>
            </MobileView>
        </Layout>
    )
}

export default Pictures;

const Style = styled.div`
    position: relative;
    width: 42vw;

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  

    .essential_form {
        font-size: 0.8rem;
        font-weight: 400;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8%;
        height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    /* 앱미리보기/간편도움말 공통css */
    .side_help_content {
        position: absolute;
        left: 103%;
        top: 0;
    }
    .app_modal_container {
        padding: 13px 13px 40px 13px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #FBFBFB;
        border: 1px solid #DFDFE3;
        border-radius: 8px;
        
        .arrow_box img {
            width: 5px;
        }
        .text_box {
            margin-left: 5px;
            width: 220px;

            .title_box {
                height: auto;
                margin-bottom: 9px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                }
            }
            .help_content_img {
                width: 18px;
            }
        }
    }

    .pictures_title {
        height: 7vh;
    }
    .pictures_list_title {
        height: 25vh;
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            width: 10vw;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3vh;
            width: 5vw;;
            border: 1px solid #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 1px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        width: 42vw; 
    }


    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }
`



const Mstyle = styled.div`

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .category_title {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;
        margin-bottom: 5%;
        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }
    
    .inputText {
        background-color: #fff;

        h3 {
            font-size: 0.9rem;
            font-weight: 400;
        }
    }


    .image_line {
        height: 15vh;
        display: flex;
        align-items: center;
        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 11vh;
            width: 22vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 7vw;
                height: 12vh;
            }
        }

        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 11vh;
            width: 22vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                height: 11vh;
                width: 22vw;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 6vh;
                width: 20vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 6vh;
                width: 20vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }


`


