import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { flash } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { Switch, Modal } from "antd";
import "antd/dist/antd.css";

import throoSelfLogo from '../../../assets/img/throoSelfLogo.png';
import rightArrow from '../../../assets/svg/arrow_right.svg';
import manual from '../../../assets/svg/exclamation_mark.svg';

import { WelcomePage } from '../../modal/AppModal';
import { ConfirmTestOrder } from '../../modal/ConfirmTestOrder';
import { useNavigate, Link } from 'react-router-dom'
import { WebRoute } from '../../../navigation/WebRoutes';

const Header = ({ activeStatus, sProps, bManualPaused, fOperateStore, fChangeStoreCloseStatus }) => {
   const [tip_un_activate, set_tip_un_activate] = useState('true');
   const [unActivate, setUnActivate] = useState(true);
   const [bIsPosStore, set_bIsPosStore] = useState(false);

   const [modal, contextHolder] = Modal.useModal();
   let oStartStoreModal;

   const [bShowTestOrderModal, set_bShowTestOrderModal] = useState(false);

   // 영업시작 안내문구
   const tip_click = () => {
      set_tip_un_activate('false');
   }
   const tip_open_click = () => {
      set_tip_un_activate('true');
   }

   const operationFinished = async () => {
      oStartStoreModal = modal.success(modalOpen);

      /*
      // Create test order
      let iStoreId = parseInt(sProps.UserConfigReducer.StoreID);
      if (iStoreId > 0) {
         await sProps.oManager.fDoAxios("/store/order/createTestOrder", "post", null, { store_id: iStoreId });
      }
      */
   }

   const updateAccessible = async () => {
      let oUserConfig = {};
      oUserConfig['accessible'] = "accessible";

      await sProps.reduxSetUserConfig(oUserConfig);
      setUnActivate(false);
   }

   const modalOpen = {
      width: "44vw",
      footer: (<></>),
      icon: (<></>),
      content: (
         <WelcomePage />
      ),
      onOk() {
         oStartStoreModal.destroy();
         updateAccessible();
         //set_bShowTestOrderModal(true);
      },
      centered: true
   };

   useEffect(() => {

      let oUserData = sProps.UserConfigReducer;
      if (oUserData && oUserData.isPOSStore === true) {
         set_bIsPosStore(true);
      }
      sProps.UserConfigReducer.accessible !== "inaccessible" ? setUnActivate(false) : setUnActivate(true);
   }, []);

   if (bIsPosStore === true) {
      return (
         <div></div>
      )
   }

   return (
      <StyleRoot>
         <Style>
            <div className="headerPage">
               <Link to={WebRoute.ADMIN_SALES_DASHBOARD}>
                  <div className="logo" >
                     <img src={throoSelfLogo} alt="스루셀프어드민로고" />
                  </div>
               </Link>
               {unActivate ?
                  <div className="notice_header">
                     <div className="tip">
                        <SVG onClick={tip_open_click} src={manual} width="23px" height="23px" style={{ marginRight: '5px' }} />
                     </div>
                     <div className="operation_wrapper"
                        onClick={() => { fOperateStore(operationFinished); }}
                     >
                        <p>스루 시작하기</p>
                     </div>
                     {/* {tip_un_activate === 'true' &&
                        <div className="tip_text" onClick={tip_click}>
                           <p>영업에 필요한 ‘필수’ 정보를 모두<br />입력해야  ‘영업시작’ 버튼이 활성화 됩니다</p>
                        </div>
                     } */}
                  </div>
                  :
                  <div className="main_header">
                     <div className="box" style={{ backgroundColor: !activeStatus ? "#7D7D7D" : "#6490E7", position: 'relative' }}>
                        <p>{!activeStatus ? "준비중" : "영업중"}</p>
                        {/* {activeStatus === false && bManualPaused === false &&
                           <div className="tip_text" style={{ minWidth: 256 }}>
                              <p>주문 프로그램에서 로그인이 되어야<br />정상영업이 가능합니다</p>
                           </div>
                        }
                        {bManualPaused === true &&
                           <div className="tip_text" style={{ minWidth: 256 }}>
                              <p>일시정지를 해제해야<br />정상 영업이 가능합니다</p>
                           </div>
                        } */}
                     </div>

                     <div className="switchBox">
                        <Switch checked={activeStatus} onChange={fChangeStoreCloseStatus} />
                     </div>
                  </div>
               }
               <Link to={WebRoute.ADMIN_BUSINESS}>
                  <div className="user_box">
                     <p><span>{sProps.UserConfigReducer.StoreName}</span>&nbsp;사장님 <SVG src={rightArrow} width="20px" title="오른쪽 화살표" style={{ top: -1, left: -1, position: 'relative' }} /></p>
                  </div>
               </Link>
            </div>
            {contextHolder}
            <ConfirmTestOrder bShowTestOrderModal={bShowTestOrderModal} iStoreId={sProps.UserConfigReducer.StoreID} oManager={sProps.oManager} f_operationFinished={updateAccessible} fset_bShowTestOrderModal={set_bShowTestOrderModal} />
         </Style>
      </StyleRoot>
   )
}

export default Header;

const Style = styled.div`
   /* 공통css */
   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
   }
   h1, h2, h3, p, a, span, button {
      font-family: 'Pretendard'!important;
   }
   
   /* header */
   .headerPage {
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100vw;
      padding : 14px 24px;
      z-index: 999;
      background: #fff;
      border-bottom: 1px solid #DFDFE3;
      
      .logo {
         cursor: pointer;
         
         img {
            max-width: 162px;
         }
         
      }
   }

   /* 영업 시작 버튼 */
   .notice_header {
      margin-left: auto;
      margin-right: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      width: 20%;
   }
   .operation_wrapper {
      padding: 6px 16px;
      border-radius: 8px;
      background: #597EF7;
      cursor: pointer;

      p {
         color: #fff;
         font-size: 16px;
         font-weight: 600;
         line-height: 24px;
      }
   }
   .operation_wrapper:hover {
      background : #617BE3;
      p {
         color: #fff;
      }
   }
   .tip {
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
         text-decoration: underline;
         top: -18px;
         padding: 8px 12px;
         color: #000;
      }
      p strong {
         color: #617BE3;
      }
   }
   .tip_text {
      position: absolute;
      margin-top: 4px;
      padding: 8px 16px;
      top: 100%;
      right: 0;
      background: #000;
      border: 1px solid #262626;
      border-radius: 8px;
      box-shadow: 0px, 9px rgba(0, 0, 0, 0.08);
      cursor: pointer;

      p {
         color: #fff;
      }
      
   }


   /* 영업시작 후 매장 오픈 관리 */
   .main_header {
      width: 180px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
   }
   .box {
      margin-right: 6px;
      padding: 4px 16px;
      border-radius: 8px;
      text-align: center;

      p {
         font-size: 16px;
         font-weight: 600;
         line-height: 24px;
         color: #fff;
      }
   }
   .switchBox {
      width: 3vw;
      height: 2.5vh;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   /* 사장님 이름 */
   .user_box {
      cursor: pointer;
      p {
         margin-right: 8px;
         font-size: 16px;
         font-weight: 400;
         line-height: 24px;
         display: flex;
         align-items: center;
         vertical-align: bottom;
      }

      span {
         font-weight: 600;
      }
   }

   /* 미디어쿼리 */
   /* @media (max-width: 1800px){
      .headerPage {
         .logo {
               flex: 0 0 80%;
         }
      }
   }
   @media (max-width: 1500px){
      .headerPage {
         .logo {
               flex: 0 0 80%;
         }
      }
      .box {
         margin-right: -5px;
      }
   }
   @media (max-width: 1400px){
      .headerPage {
         .logo {
               flex: 0 0 75%;
         }
      }
   } */
`