import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import "antd/dist/antd.css";
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import NproductComponent from './insert_nProduct'
import ProductComponent from './insert_Product'

const sMaxList = [];
const sMinList = [];
for (let i = 1; i < 21; i++) {
    sMaxList.push({
        key: i,
        value: i,
        name: `${i} 개`,
    });
};
for (let i = 1; i < 21; i++) {
    sMinList.push({
        key: i,
        value: i,
        name: `${i} 개`,
    });
};

const InsertForm = ({ fAddList, oProps }) => {
    const [iStatus, setStatus] = useState("");
    const [OptionCategory, setOptionCategory] = useState("nProduct");

    const changeStatus = (sIndex) => {
        if (sIndex === "new") {
            setStatus("new");
        } else {
            setStatus("");
        }
    }

    return (
        <Fragment>
            <BrowserView>
                <Style>
                    <div className="container">
                        <div className="title_box">
                            <h2>옵션관리</h2>
                            {iStatus !== "" ?
                                <div className="cancel_box" onClick={() => changeStatus("")}>
                                    <p>등록취소</p>
                                </div>
                                :
                                <div className="new_category_box" onClick={() => changeStatus("new")}>
                                    <p>옵션등록</p>
                                </div>
                            }
                        </div>
                        {iStatus !== "" &&
                            <>
                                <div className="essential_box">
                                    <div className='option_type_tab'
                                        style={{
                                            justifyContent: 'flex-start',
                                            margin: '0'
                                        }}
                                    >
                                        <button
                                            className={OptionCategory === "nProduct" ? "selected_option_tab" : "unselect_option_tab"}
                                            onClick={() => setOptionCategory("nProduct")}
                                            style={{
                                                background: '#fff',
                                                color: '#000'
                                            }}
                                        >
                                            <p
                                                style={{
                                                    padding: '0',
                                                    color: '#fff',
                                                    textAlign: 'left'
                                                }}
                                            >선택 옵션 만들기</p>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {OptionCategory === "nProduct" && <NproductComponent oProps={oProps} fAddList={fAddList} />}
                                </div>
                            </>
                        }
                        {/* 추후 오픈 */}
                        {/* {iStatus !== "" && 
                            <>
                                <div className="essential_box">
                                    <div className='option_type_tab'>
                                        <button className={OptionCategory === "nProduct" ? "selected_option_tab" : "unselect_option_tab"} onClick={() => setOptionCategory("nProduct")}>
                                            <p>선택 옵션 만들기</p>
                                        </button>
                                        <button className={OptionCategory === "product" ? "selected_option_tab" : "unselect_option_tab"} style={{ marginLeft: "2%" }} onClick={() => setOptionCategory("product")}>
                                            <p>세트 메뉴 구성 옵션 만들기</p>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    // 선택 옵션 
                                    {OptionCategory === "nProduct" && <NproductComponent oProps={oProps} fAddList={fAddList} />}
                                    // 세트 메뉴 구성 옵션
                                    {OptionCategory === "product" && <ProductComponent oProps={oProps} />}
                                </div>
                            </>
                        }*/}
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <Style>
                    <div className="category_title_line">
                        <div className="category_title">
                            <div className="title_box">
                                <p className="title_line">옵션관리</p>
                                <p className="detail_line"></p>
                            </div>
                            {iStatus !== "" ?
                                <div className="cancel_box" onClick={() => changeStatus("")}>
                                    <p>등록취소</p>
                                </div>
                                :
                                <div className="insert_box" onClick={() => changeStatus("new")}>
                                    <p>+ 옵션등록</p>
                                </div>
                            }
                        </div>
                        {iStatus !== "" &&
                            <>
                                <div className="essential_box_mobile">
                                    <div className='option_type_tab_mobile'style={{background:'#fff', borderBottom:'1px solid #EAEAEA'}}>
                                        <button
                                            style={{flex: ' 0 0 50%'}}
                                            className={OptionCategory === "nProduct" ? "selected_option_tab" : "unselect_option_tab"} onClick={() => setOptionCategory("nProduct")}>
                                            <p>선택 옵션 만들기</p>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {OptionCategory === "nProduct" && <NproductComponent oProps={oProps} fAddList={fAddList} />}
                                </div>
                                {/* 추후 오픈 */}
                                {/* <div className="essential_box_mobile">
                                    <div className='option_type_tab_mobile'>
                                        <button className={OptionCategory === "nProduct" ? "selected_option_tab" : "unselect_option_tab"} onClick={() => setOptionCategory("nProduct")}>
                                            <p>선택 옵션 만들기</p>
                                        </button>
                                        <button className={OptionCategory === "product" ? "selected_option_tab" : "unselect_option_tab"} style={{ marginLeft: "2%" }} onClick={() => setOptionCategory("product")}>
                                            <p>세트 메뉴 구성 옵션 만들기</p>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    // 선택 옵션 
                                    {OptionCategory === "nProduct" && <NproductComponent oProps={oProps} fAddList={fAddList} />}
                                    // 세트 메뉴 구성 옵션 
                                    {OptionCategory === "product" && <ProductComponent oProps={oProps} />}
                                </div> */}
                            </>
                        }
                        {/* {iStatus !== "" &&
                        <>
                            <div className="inputText">
                                <h3>옵션그룹명</h3>
                                <input
                                    type="text"
                                    placeholder="예) 사이드메뉴 추가"
                                    className={classNm}
                                    onClick={inputClassStoreNm}
                                    value={sNm}
                                // onChange={onChangeStoreNameValue}
                                />
                            </div>
                            <div className="essential_box">
                                <p>필수 선택 여부</p>
                                <div className="select_line_box">
                                    <div className={sOptionType === "radio" ? "selected_wrapper" : "unselect_wrapper"} onClick={() => selectOptionType("radio")}>
                                        <p>선택해야 주문가능</p>
                                    </div>
                                    <div className={sOptionType === "checkbox" ? "selected_wrapper" : "unselect_wrapper"} style={{ marginLeft: "2%" }} onClick={() => selectOptionType("checkbox")}>
                                        <p>선택안해도 주문가능</p>
                                    </div>
                                </div>
                            </div>
                            {sOptionType === "checkbox" ?
                                <div className="inputText">
                                    <p>최대선택수</p>
                                    <Select value={sMax} onChange={setChangeMax} className="select_bank" bordered={false}>
                                        {sMaxList.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.id}>{item.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                :
                                <div className="inputText">
                                    <p>최소선택수</p>
                                    <Select value={sMin} onChange={setChangeMin} className="select_bank" bordered={false}>
                                        {sMinList.map((item, index) => {
                                            return (
                                                <Option key={index} value={item.id}>{item.name}</Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            }
                            <div className="option_box">
                                <p>옵션</p>
                                <div className="option_input">
                                    <input
                                        style={{ marginRight: "3%" }}
                                        type="text"
                                        placeholder="예) 사이드메뉴 추가"
                                        className={classOptionNm}
                                        onClick={inputClassOptionNm}
                                        value={sOptionNm}
                                        onChange={onChangeClassOptionNm}
                                    />
                                    <input
                                        type="text"
                                        placeholder="예) 사이드메뉴 추가"
                                        className={classOptionPrice}
                                        onClick={inputClassOptionPrice}
                                        value={sOptionPrice}
                                        onChange={onChangeClassOptionPrice}
                                    />
                                    <p className="pay_box">원</p>
                                </div>
                            </div>
                            <div className="insert_option_box" onClick={insertList}>
                                <p>+ 옵션적용하기</p>
                            </div>
                            {iData !== undefined && iData !== null && iData.length > 0 &&
                                <div className="option_list">
                                    <p>옵션 리스트</p>
                                    {iData.map((item, index) => {
                                        return (
                                            <div key={index} className="option_list_box">
                                                <div className="option_name_list">
                                                    <p>{item.name}</p>
                                                </div>
                                                <div className="option_price_list">
                                                    <p>{item.price}</p>
                                                </div>
                                                <p className="pay_box">원</p>
                                                <div className="option_delete_box" onClick={() => deleteList(item.key)}>
                                                    <p>삭제</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            <div className="bottom_fixed">
                                <button className="finishButton" onClick={registerOption}>등록완료</button>
                            </div>
                        </>
                    } */}
                    </div>
                </Style>
            </MobileView>
        </Fragment>
    )
}

export default InsertForm;

const Style = styled.div`
    /* 공통 css */
    p {
        font - size: 16px;
    }

    /* 옵션 타입 선택 */
    .option_type_tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0;
        background:  #EAEAEA;
        border-radius: 50px;

        .selected_option_tab, .unselect_option_tab {
            background: #617BE3;
            border-radius: 50px;
            border: none;
            outline: none;
            width: 100%;

            p {
                margin: 0;
                padding: 15px 0;
                font-size: 14px;
                font-weight: 600;
                color: #fff;
            }

        }
        .unselect_option_tab {
            background:  #EAEAEA;
            width: 80%;

            p {
                color: #7C8297;
                font-weight: 400;
            }
        }
    
    }

    /* 모바일 */
    .option_type_tab_mobile {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #EAEAEA;

        .selected_option_tab, .unselect_option_tab {
            width: 100%;
            background: #EAEAEA;
            border:none;
            outline: none;
            font-size: 13px;
        }
        .selected_option_tab {
            padding: 10px 5px;
            background: #fff;
            border: 1px solid #EAEAEA;
            border-bottom: 1px solid #FFF;
            border-top: 1px solid #617BE3;

            p {
                color: #617BE3;
                font-weight: 700;
            }
        }
        .unselect_option_tab p {
            color: #7C8297;
        }
    }


/* 필수 선택 여부 버튼 */
/* .select_line_box {
                    width: 100%;
                display: flex;
                justify-content: space-between;

                .selected_wrapper, .unselect_wrapper {
                    width: 48%;
                text-align: center;
                border-radius: 5px;
                cursor: pointer;
                outline: none;
                border: none;
                p {
                    margin: 0;
                padding: 12px 0;
                font-size: 14px;
                font-weight: 600;
                color: #fff;
                line-height: 17px;
        }
                .small_size {
                    font - size: 11px;
                font-weight: 400;
                color: #fff;
        }
    }
                .selected_wrapper {
                    background: #617BE3;
    }
                .unselect_wrapper {
                    background: rgb(241, 243, 247);
                border: 1px solid rgb(161, 161, 161);

                p, .small_size {
                    color: rgb(161, 161, 161);
        }
    }
} */
`
