import React, { useState, useRef, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import randtoken from 'rand-token';
import { isMobile } from "react-device-detect";
import moment from 'moment-timezone';
import { Modal, AutoComplete, Select, message } from 'antd';
import "antd/dist/antd.css";
import 'moment/locale/ko';

import { SignUpStyle } from '../../assets/styledComponents/SignInStyle';
import loginLogo from '../../assets/svg/loginlogo.svg';
import kakaoLogin from '../../assets/svg/kakaoLogin.svg';
import cancel from '../../assets/svg/cancel.svg';
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { WebRoute } from '../../navigation/WebRoutes';

let passwordCheckTime;

const { Option } = Select;
const SignIn = ({ fModeChange, sProps }) => {
   const [userId, setUserId] = useState("");
   const [userPwd, setUserPwd] = useState("");
   const [userPwdT, setUserPwdT] = useState("");
   const [userName, setUserName] = useState("");
   const [userEmail, setUserEmail] = useState("");
   const [userPhone, setUserPhone] = useState("");

   const [errorMessageID, setErrorMessageID] = useState("");
   const [errorMessagePw, setErrorMessagePw] = useState("");
   const [errorMessagePwC, setErrorMessagePwC] = useState("");
   const [errorMessageUserNm, setErrorMessageUserNm] = useState("");
   const [errorMessageEmail, setErrorMessageEmail] = useState("");
   const [errorMessagePhone, setErrorMessagePhone] = useState("");

   const [classNameId, setClassNameId] = useState(null);
   const [classNamePw, setClassNamePw] = useState(null);
   const [classNamePwCheck, setClassNamePwCheck] = useState(null);
   const [classNameUserName, setClassNameUserName] = useState(null);
   const [classNameEmail, setClassNameEmail] = useState("emailBoxContainer");
   const [classNamePhone, setClassNamePhone] = useState(null);

   const [phoneValidation, setPhoneValidation] = useState(false);
   const navigate = useNavigate()
   const [emailList, setEmailList] = useState([]);

   const [oUserDevice, set_oUserDevice] = useState("");

   const [modal, contextHolder] = Modal.useModal();

   const queryString = window.location.search;
   let bIsThrooStore = false;
   if (queryString.indexOf('source=throostore') !== -1) {
      bIsThrooStore = true;
   }

   const handleSearch = (value) => {
      let res = [];

      if (!value || value.indexOf('@') >= 0) {
         res = [];
      } else {
         res = ['gmail.com', 'naver.com', 'daum.net', "hotmail.com", "nate.com"].map((domain) => `${value}@${domain}`);
      }
      setEmailList(res);
   };

   const checkId = async (sIndex) => {
      let hasId = true;

      const oResponse = await sProps.oManager.fDoAxios("/register/findId-" + sIndex, "get", null, null);
      if (oResponse !== undefined && oResponse !== null) {
         if (!oResponse) {
            hasId = false;
         }
      }
      return hasId;
   }

   const inputUserId = async (e) => {
      let sUserId = e.target.value;
      const regex = /[a-z0-9]+$/gi;
      if (sUserId === "" || sUserId === null) {
         setErrorMessageID("");
         setClassNameId("input_action");
         setUserId(sUserId);
      } else {
         if (regex.test(sUserId)) {
            const result = await checkId(sUserId);
            if (result) {
               setClassNameId("input_wrong");
               setErrorMessageID("이미 사용중인 아이디입니다");
            } else {
               setClassNameId("input_action");
               setErrorMessageID("");
            }
            setUserId(sUserId);
         } else {
            setClassNameId("input_wrong");
            setErrorMessageID("영어나 숫자로만 입력이 가능합니다");
         }
      }
   }

   const completeSignUp = async () => {
      if (userId !== "" && userPwd !== "" && userPwdT !== "" && userName !== "" && userEmail !== "" && userPhone !== "") {
         const validate = emailValidation();
         if (!validate) {
            const oData = {
               userId,
               userPwd,
               userPwdT,
               userName,
               userEmail,
               userPhone,
               user_device_info: oUserDevice
            }
            const oResponse = await sProps.oManager.fDoAxios("/authenticate/signUp/v3", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
               if (oResponse.resultId === "0000") {

                  // modeChange("dashboard");

                  const new_date = moment().add(1, "days");
                  let oUserConfig = {};

                  oUserConfig['isPOS'] = false;
                  oUserConfig['LoggedIn'] = true;
                  oUserConfig['LoginId'] = userId;
                  oUserConfig['Token'] = await oResponse.token;
                  oUserConfig['RefreshToken'] = await oResponse.refresh_token;
                  oUserConfig['UUID'] = await oResponse.uuid;

                  oUserConfig['StoreID'] = oResponse.store_id;
                  oUserConfig['UserID'] = oResponse.user_id;
                  oUserConfig['StorePhone'] = userPhone;
                  oUserConfig['StoreOwner'] = userName;
                  oUserConfig['Commercial'] = oResponse.commercial;
                  oUserConfig['StoreLat'] = parseFloat(37.5657);
                  oUserConfig['StoreLng'] = parseFloat(126.9769);

                  oUserConfig['accessible'] = oResponse.status;
                  oUserConfig['LoginTime'] = await moment(new_date).format('YYYY-MM-DD');

                  if (bIsThrooStore === true) {
                     if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                           JSON.stringify(oUserConfig)
                        );
                     }
                     return;
                  }

                  await sProps.reduxSetUserConfig(oUserConfig);
                  navigate(WebRoute.ADMIN_SIGNIN)
               } else {
                  if (!oResponse.userId) {
                     setClassNameId("input_wrong");
                  }
                  if (!oResponse.userPwd) {
                     setClassNamePw("input_wrong");
                     setClassNamePwCheck("input_wrong");
                  }
                  if (!oResponse.userName) {
                     setClassNameUserName("input_wrong");
                  }
                  if (!oResponse.userEmail) {
                     setClassNameEmail("emailBoxContainer_input_wrong");
                  }
                  if (!oResponse.userPhone) {
                     setClassNamePhone("input_wrong");
                  }
                  if (oResponse.resultMsg !== "") {
                     message.info(oResponse.resultMsg);
                  }
               }
            }
         }
      } else {
         setClassNameId("input_wrong");
         setClassNamePw("input_wrong");
         setClassNamePwCheck("input_wrong");
         setClassNameUserName("input_wrong");
         setClassNameEmail("emailBoxContainer_input_wrong");
         setClassNamePhone("input_wrong");
      }
   }

   const kakaoTalk = () => {
      if (isMobile) {
         window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
      } else {
         window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank', "height=900,width=500,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
      }
   }

   const modeChange = async (sIndex) => {
      if (fModeChange !== undefined && typeof fModeChange === "function") {
         await fModeChange(sIndex);
      }
   }

   const emailValidation = () => {
      let oResult = false;
      const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

      if (regExp.test(userEmail)) {
         setClassNameEmail("emailBoxContainer");
         setErrorMessageEmail("");
      } else {
         oResult = true;
         setClassNameEmail("emailBoxContainer_input_wrong");
         setErrorMessageEmail("이메일 형식에 맞지 않습니다");
      }

      return oResult;
   }

   const checkPhoneNm = async () => {
      if (userId !== undefined && userId !== null && userId !== "") {
         const regex = /[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
         if (userPhone === "" || userPhone === null) {
            setErrorMessagePhone(`'-'을 제외한 숫자 및 올바른 번호가 필요합니다`);
            setClassNamePhone("input_wrong");
         } else {
            if (regex.test(userPhone)) {
               authenticationSMS();
            } else {
               setClassNamePhone("input_wrong");
               setErrorMessagePhone(`'-'을 제외한 숫자 및 올바른 번호가 필요합니다`);
            }
         }
      } else {
         setClassNameId("input_wrong");
         setErrorMessageID("아이디를 먼저 입력해주세요.");
      }
   }

   const inputUserPwdT = (e) => {
      const sValue = e.target.value;
      setUserPwdT(sValue);

      if (passwordCheckTime) clearTimeout(passwordCheckTime);
      passwordCheckTime = setTimeout(() => {
         if (sValue !== userPwd) {
            setClassNamePwCheck("input_wrong");
            setErrorMessagePwC("비밀번호를 다시 확인해주세요");
         } else {
            setClassNamePwCheck("input_action");
            setErrorMessagePwC("");
         }
      }, 300);
   }

   const inputUserPwd = (e) => {
      setUserPwd(e.target.value);
      setErrorMessagePw("");
      setClassNamePw("input_action");
   }

   const inputUserName = (e) => {
      setUserName(e.target.value);
      setErrorMessageUserNm("");
      setClassNameUserName("input_action");
   }

   const inputUserEmail = (value) => {
      const sValue = value;
      setUserEmail(sValue);
      setErrorMessageEmail("");
   }

   const inputUserPhone = (e) => {
      setUserPhone(e.target.value);
      setErrorMessagePhone("");
   }

   const inputClassNameId = () => {
      validationErr("classNameId");
   }
   const inputClassNamePw = () => {
      validationErr("classNamePw");
   }
   const inputClassNamePwCheck = () => {
      validationErr("classNamePwCheck");
   }
   const inputClassNameUserName = () => {
      validationErr("classNameUserName");
   }
   const inputClassNameEmail = () => {
      validationErr("classNameEmail");
   }
   const inputClassNamePhone = () => {
      validationErr("classNamePhone");
   }

   const validationErr = async (sIndex) => {
      const classNameBox = [
         { name: "classNameId", value: classNameId },
         { name: "classNamePw", value: classNamePw },
         { name: "classNamePwCheck", value: classNamePwCheck },
         { name: "classNameUserName", value: classNameUserName },
         { name: "classNameEmail", value: classNameEmail },
         { name: "classNamePhone", value: classNamePhone },
      ];
      for await (const iterator of classNameBox) {
         if (iterator.value !== "input_wrong") {
            if (sIndex === iterator.name) {
               if (iterator.name === "classNameId") {
                  setClassNameId("input_action");
                  setErrorMessageID("");
               } else if (iterator.name === "classNamePw") {
                  setClassNamePw("input_action");
                  setErrorMessagePw("");
               } else if (iterator.name === "classNamePwCheck") {
                  setClassNamePwCheck("input_action");
                  setErrorMessagePwC("");
               } else if (iterator.name === "classNameUserName") {
                  setClassNameUserName("input_action");
                  setErrorMessageUserNm("");
               } else if (iterator.name === "classNameEmail") {
                  setClassNameEmail("emailBoxContainer_input");
                  setErrorMessageEmail("");
               } else if (iterator.name === "classNamePhone") {
                  setClassNamePhone("input_action");
                  setErrorMessagePhone("");
               }
            } else {
               if (iterator.name === "classNameId") {
                  setClassNameId(null);
               }
               if (iterator.name === "classNamePw") {
                  setClassNamePw(null);
               }
               if (iterator.name === "classNamePwCheck") {
                  setClassNamePwCheck(null);
               }
               if (iterator.name === "classNameUserName") {
                  setClassNameUserName(null);
               }
               if (iterator.name === "classNameEmail") {
                  setClassNameEmail("emailBoxContainer");
               }
               if (iterator.name === "classNamePhone") {
                  setClassNamePhone(null);
               }
            }
         }
      }

   }

   const removeItem = async (sIndex) => {
      const classNameBox = ["id", "password", "pwCheck", "userNm", "email"];
      for await (const iterator of classNameBox) {
         if (sIndex === iterator) {
            if (sIndex === "id" && classNameId !== "input_action") {
               setClassNameId(null);
            }
            if (sIndex === "password" && classNamePw !== "input_action") {
               setClassNamePw(null);
            }
            if (sIndex === "pwCheck" && classNamePwCheck !== "input_action") {
               setClassNamePwCheck(null);
            }
            if (sIndex === "userNm" && classNameUserName !== "input_action") {
               setClassNameUserName(null);
            }
            if (sIndex === "email" && classNameEmail !== "input_action") {
               setClassNameEmail("emailBoxContainer");
            }

            if (sIndex === "id") {
               setUserId("");
               setErrorMessageID("");
            } else if (sIndex === "password") {
               setUserPwd("");
               setErrorMessagePw("");
            } else if (sIndex === "pwCheck") {
               setUserPwdT("");
               setErrorMessagePwC("");
            } else if (sIndex === "userNm") {
               setUserName("");
               setErrorMessageUserNm("");
            } else if (sIndex === "email") {
               setUserEmail("");
               setErrorMessageEmail("");
            }
         }
      }
   }

   const authenticationSMS = async () => {
      let token = randtoken.generate(32);
      const oData = {
         userPhone,
         userId,
         userName,
         token
      }
      const oResponse = await sProps.oManager.fDoAxios("/authenticate/signUp/sms/v2", "post", null, oData);
      if (oResponse !== undefined && oResponse !== null) {
         if (oResponse) {
            setPhoneValidation(true);
            message.info("입력한 번호로 인증요청이 완료되었습니다.");
         } else {
            message.error("네트워크 에러가 발생했습니다 나중에 다시 시도 바랍니다.");
         }
      }
   }

   const doCancel = async () => {
      if (window.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage(
            JSON.stringify({ action: 'cancel' })
         );
      }
   }

   useEffect(() => {
      document.addEventListener('message', function WebViewListener({ data }) {
         try {
            set_oUserDevice(data);
         } catch (err) {
            //alert(err)
         }
      })
      window.addEventListener('message', function WebViewListener({ data }) {
         try {
            set_oUserDevice(data);
         } catch (err) {
            //alert(err)
         }
      })
   }, [])

   return (
      <SignUpStyle>
         <div className="loginPage">
            <div className="loginBox">
               <SVG src={loginLogo} width="54px" height="18px" title="로그인페이지로고" />
               <h2>간편등록으로 드라이브 스루가 가능해집니다.</h2>
               <div className="inputBox">
                  <input
                     type="text"
                     placeholder="아이디"
                     value={userId}
                     onChange={inputUserId}
                     className={classNameId}
                     onClick={inputClassNameId}
                  />
                  {userId !== '' ? <button className="cancelBtn" onClick={() => removeItem("id")}><SVG src={cancel} width="14px" height="14px" title="전체삭제" /></button> : null}
               </div>
               <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageID}</p>
               <div className="inputBox">
                  <input
                     type="password"
                     placeholder="비밀번호"
                     value={userPwd}
                     onChange={inputUserPwd}
                     className={classNamePw}
                     onClick={inputClassNamePw}
                  />
                  {userPwd !== '' ? <button className="cancelBtn" onClick={() => removeItem("password")}><SVG src={cancel} width="14px" height="14px" title="전체삭제" /></button> : null}
               </div>
               <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessagePw}</p>
               <div className="inputBox">
                  <input
                     type="password"
                     placeholder="비밀번호 확인"
                     value={userPwdT}
                     onChange={inputUserPwdT}
                     className={classNamePwCheck}
                     onClick={inputClassNamePwCheck}
                  />
                  {userPwdT !== '' ? <button className="cancelBtn" onClick={() => removeItem("pwCheck")}><SVG src={cancel} width="14px" height="14px" title="전체삭제" /></button> : null}
               </div>
               <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessagePwC}</p>
               <div className="inputBox">
                  <input
                     type="text"
                     placeholder="대표자명"
                     value={userName}
                     onChange={inputUserName}
                     className={classNameUserName}
                     onClick={inputClassNameUserName}
                  />
                  {userName !== '' ? <button className="cancelBtn" onClick={() => removeItem("userNm")}><SVG src={cancel} width="14px" height="14px" title="전체삭제" /></button> : null}
               </div>
               <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageUserNm}</p>
               <div className="inputBox">
                  <div className={classNameEmail} onClick={inputClassNameEmail}>
                     <AutoComplete style={{ width: "100%", bottom: "7px", right: "12px" }} placeholder="이메일" bordered={false} value={userEmail} onChange={inputUserEmail} onSearch={handleSearch}>
                        {emailList.map((email) => (
                           <Option key={email} value={email}>{email}</Option>
                        ))}
                     </AutoComplete>
                  </div>
                  {userEmail !== '' ? <button className="cancelBtn" onClick={() => removeItem("email")}><SVG src={cancel} width="14px" height="14px" title="전체삭제" /></button> : null}
               </div>
               <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageEmail}</p>

               <label>
                  <input
                     type='number'
                     placeholder="휴대폰번호( ‘ - ’제외 )"
                     value={userPhone}
                     onChange={inputUserPhone}
                     className={classNamePhone}
                     onClick={inputClassNamePhone}
                  />
                  {phoneValidation ?
                     <button onClick={checkPhoneNm} style={{ backgroundColor: "#fff", border: "1px solid #001E62", color: "#001E62" }}>재요청</button>
                     :
                     <button onClick={checkPhoneNm} style={{ backgroundColor: "#001E62", border: "1px solid #001E62" }}>인증요청</button>
                  }
                  <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessagePhone}</p>
               </label>
               <button className="join" onClick={completeSignUp} style={{ backgroundColor: "#001E62" }}>가입하기</button>
               {bIsThrooStore === true &&
                  <div style={{ paddingTop: "20%" }}>
                     <button onClick={doCancel} className="back_out" style={{ border: "1px solid #617BE3", marginTop: "5%" }}>취소</button>
                  </div>
               }
               {bIsThrooStore === false &&
                  <div className="memberLogin">
                     <Link to={WebRoute.ADMIN_SIGNIN}>
                        <p >이미 가입하셨나요? <span className="chk_signup">로그인</span></p>
                     </Link>
                  </div>
               }
            </div>
            {bIsThrooStore === false &&
               <button className="kakao" onClick={kakaoTalk}>
                  <SVG src={kakaoLogin} title="카카오톡 상담하기" />
               </button>
            }
         </div>
         {contextHolder}
      </SignUpStyle>
   )
}

export default SignIn;
