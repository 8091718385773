import React, { useState, useEffect, Fragment } from 'react';
import { message, Select } from 'antd';
import "antd/dist/antd.css";
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import AlcoholModal from '../../../modal/AlcoholModal';

const { Option } = Select;

const EditForm = ({ fnEditCategoryList, sLimit, fnChangeNormal, iDetail, iMainId, fnAddList, alcoholList }) => {
   const iAlcoholList = alcoholList;
   const [iStatus, setStatus] = useState("");

   const [isMain, setIsMain] = useState("no");
   const [selectValue, setSelectValue] = useState("use");
   const [sNm, setNm] = useState("");

   const [iLimit, setLimit] = useState(false);

   const [sId, setId] = useState(0);
   const [sMainId, setMainId] = useState(0);

   const [indexId, setIndexId] = useState(0);

   const [classNm, setClassNm] = useState(null);
   const [errClassNm, setErrClassNm] = useState("");

   const [isChecked, setIsChecked] = useState(false);
   const [checkValue, set_checkValue] = useState('');
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [successMessage, set_successMessage] = useState('');

   // usePrompt()
   const changeStatus = async () => {
      setStatus("");
      setId(0);
      setSelectValue("yes");
      setNm("");
      setErrClassNm("");
      setClassNm(null);
      if (fnChangeNormal !== undefined && typeof fnChangeNormal === "function") {
         await fnChangeNormal();
      }
   }

   const inputClassStoreNm = () => {
      setClassNm("input_action");
   }

   const onChangeStoreNameValue = (event) => {
      setClassNm("input_action");
      setErrClassNm("");
      setNm(event.target.value);
   }

   const setChangeMain = (event) => {
      if (isMobile) {
         setIsMain(event);
      } else setIsMain(event.target.value);
      setClassNm(null);
   }

   const onChangeValue = (event) => {
      if (isMobile) {
         setSelectValue(event)
      } else setSelectValue(event.target.value);
      setClassNm(null);
   }

   const editCategory = async () => {
      if (fnEditCategoryList !== undefined && typeof fnEditCategoryList === "function") {
         if (sNm === "") {
            setErrClassNm("분류명을 입력해주세요");
            setClassNm("input_wrong");
         } else {
            let temp = true;
            if (iLimit) {
               if (isMain === "yes") {
                  if (parseInt(sMainId) !== parseInt(sId)) {
                     await message.error("이미 등록된 대표메뉴가 있습니다");
                     temp = false;
                  }
               }
            }

            let oData = {
               sNm,
               isMain,
               selectValue,
               sId,
               is_alcohol: checkValue
            }

            temp && fnEditCategoryList(oData);
         }
      }
   }

   // 주류 안내 모달
   function handleCheckboxChange() {
      setIsChecked(!isChecked);
      if (isChecked === false) {
         setIsChecked(!isChecked);
      } else {
         set_checkValue(0);
      }
   }

   function handlePopupOpen() {
      if (isChecked === true) {
         setModalIsOpen(false);

      } else if (isChecked === false) {
         setModalIsOpen(true);
      }
   }

   useEffect(() => {
      setLimit(sLimit);
      setMainId(iMainId);
   }, [sLimit, iMainId]);

   useEffect(() => {
      
      if (iStatus === "" || parseInt(indexId) !== parseInt(iDetail.id)) {
         setStatus("edit");
         setClassNm(null);
         setId(iDetail.id);
         setIndexId(iDetail.id);
         setNm(iDetail.name);
         if (iDetail.useYn === "미사용") {
            setSelectValue("unused");
         } else {
            setSelectValue("use");
         }
         if (iDetail.isMain === "예") {
            setIsMain("yes");
         } else {
            setIsMain("no");
         }

         if (iDetail.store_confirmed === 1) {
            set_checkValue(1);
            setIsChecked(true);
            set_successMessage('인증완료');
         } else {
            set_checkValue(0);
            setIsChecked(false);
            set_successMessage('');
         }
      }
   }, [iDetail]);

   return (
      <Fragment>
         <BrowserView>
            <Style>
               <div className="container">
                  <div className="title_box">
                     <h2>상품분류</h2>
                     <div className="cancel_box" onClick={() => changeStatus()}>
                        <p>수정취소</p>
                     </div>
                  </div>
                  <div className="category_title">
                     <p>분류명</p>
                     <input
                        type="text"
                        placeholder="그룹명을 입력해주세요."
                        className={classNm}
                        onClick={inputClassStoreNm}
                        value={sNm}
                        onChange={onChangeStoreNameValue}
                     />
                     <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errClassNm}</p>
                  </div>
                  <div className="alcohol">
                     <label htmlFor="alcohol-check">
                        <input id="alcohol-check" type="checkbox" style={{ width: '18px', height: '18px', marginRight: '5px' }} onChange={handleCheckboxChange} checked={isChecked} onClick={handlePopupOpen} />
                        <p>주류 메뉴를 등록하시겠습니까? </p>
                     </label>
                     <p className={successMessage === "" ? 'nSuccessText' : 'successText'}>{successMessage}</p>
                  </div>
                  <div className="category_title">
                     <p>대표메뉴 설정</p>
                     <select value={isMain} onChange={setChangeMain}  >
                        <option value="yes">예</option>
                        <option value="no">아니오</option>
                     </select>
                  </div>
                  <div className="category_title">
                     <p>사용여부</p>
                     <select value={selectValue} onChange={onChangeValue}>
                        <option value="unused">미사용</option>
                        <option value="use">사용</option>
                     </select>
                  </div>
                  <div className="insert_box" onClick={editCategory}>
                     <p>분류수정하기</p>
                  </div>
                  <AlcoholModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} setIsChecked={setIsChecked} fnAddList={fnAddList} iAlcoholList={iAlcoholList} set_successMessage={set_successMessage} set_checkValue={set_checkValue} />
               </div>
            </Style>
         </BrowserView>
         <MobileView>
            <Mstyle>
               <div className="category_title_line">
                  <div className="category_title">
                     <div className="title_box">
                        <p className="title_line">상품분류</p>
                        <p className="detail_line">(최소 1개 필수)</p>
                     </div>
                     <div className="cancel_box" onClick={() => changeStatus()}>
                        <p>등록취소</p>
                     </div>
                  </div>
                  <div className="inputText">
                     <h3>분류명</h3>
                     <input
                        type="text"
                        placeholder="그룹명을 입력해주세요."
                        className={classNm}
                        onClick={inputClassStoreNm}
                        value={sNm}
                        onChange={onChangeStoreNameValue}
                     />
                     <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errClassNm}</p>
                  </div>
                  <div className="alcohol">
                     <label htmlFor="alcohol-check">
                        <input id="alcohol-check" type="checkbox" style={{ width: '18px', height: '18px', marginRight: '5px' }} onChange={handleCheckboxChange} checked={isChecked} onClick={handlePopupOpen} />
                        <p>주류 메뉴를 등록하시겠습니까? </p>
                     </label>
                     <p className={successMessage === "" ? 'nSuccessText' : 'successText'}>{successMessage}</p>
                  </div>
                  <div className="inputText">
                     <h3>대표메뉴설정</h3>
                     <Select value={isMain} onChange={setChangeMain} className="select_bank" bordered={false}>
                        <Option value="yes">예</Option>
                        <Option value="no">아니오</Option>
                     </Select>
                  </div>
                  <div className="inputText">
                     <h3>사용여부</h3>
                     <Select value={selectValue} onChange={onChangeValue} className="select_bank" bordered={false}>
                        <Option value="unused">미사용</Option>
                        <Option value="use">사용</Option>
                     </Select>
                  </div>
                  <div className="last">
                     <button className="finishButoon" onClick={editCategory}>수정하기</button>
                  </div>
                  <AlcoholModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} setIsChecked={setIsChecked} fnAddList={fnAddList} iAlcoholList={iAlcoholList} set_successMessage={set_successMessage} set_checkValue={set_checkValue} />
               </div>
            </Mstyle>
         </MobileView>
      </Fragment>
   )
}

const Style = styled.div`
    .alcohol {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        p {
            font-weight: 700;
        }

        label {
          display: flex;
          align-items: center;
        }
    }
    .successText{
        margin-left: 5px;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 400!important;
        border: 1px solid #6490E7;
        border-radius: 4px;
        background: #E8EFFC;
        color: #6490E7
    }
`

const Mstyle = styled.div`
    .alcohol {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 15px;

        p {
            font-weight: 700;
        }

        label {
          display: flex;
          align-items: center;
        }
    }
    .successText{
        margin-left: 5px;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 400!important;
        border: 1px solid #6490E7;
        border-radius: 4px;
        background: #E8EFFC;
        color: #6490E7
    }
`

export default EditForm;
