import React, { useState } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { DatePicker } from 'antd';
import ReactExport from "react-data-export";
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import manual from '../../../../assets/svg/manual.svg';
import addressSearch from '../../../../assets/svg/addressSearch.svg';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const defaultFormat = "YYYY";

const TaxPage = ({ oProps }) => {
    const [isLoading, setLoading] = useState(false);

    const [dateValue, setDateValue] = useState(moment().format(defaultFormat));
    const [q1, setQ1] = useState(false);
    const [q2, setQ2] = useState(false);
    const [q3, setQ3] = useState(false);
    const [q4, setQ4] = useState(false);

    const [sList, setSList] = useState([]);
    const [iList, setIList] = useState([]);
    const [eList, setEList] = useState([]);

    const [isExcel, setExcel] = useState(false);

    const onOperatingDate = async (date, dateString) => {
        if (date != null) {
            setDateValue(dateString);
        } else {
            setDateValue(moment().format(defaultFormat));
        }
    }

    const searchList = () => {
        setLoading(true);
        getList();
    }

    const onOffQuarter = (sIndex) => {
        if (sIndex === "q1") {
            setQ1(!q1);
        } else if (sIndex === "q2") {
            setQ2(!q2);
        } else if (sIndex === "q3") {
            setQ3(!q3);
        } else if (sIndex === "q4") {
            setQ4(!q4);
        }
    }

    const getList = async () => {
        const oData = {
            storeId: oProps.UserConfigReducer.StoreID,
            dateValue,
            q1,
            q2,
            q3,
            q4,
        }
        const oResponse = await oProps.oManager.fDoAxios(`/payment/taxCalculate/v2`, "post", "login", oData);
        if (oResponse !== undefined) {
            setSList(oResponse.sList);
            setIList(oResponse.iList);
            if (oResponse.eList !== undefined && oResponse.eList !== null && oResponse.eList.length > 0) {
                setExcel(true);
                setEList(oResponse.eList);
            }
        }
        setLoading(false);
    }

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.payment}>
            <BrowserView>
                <Style>
                    <div className="content_container">
                        <div className="container">
                            <div className="title_box">
                                <h2>부가가치세 신고자료(참고용)</h2>
                                <div className="tip">
                                    <SVG src={manual} width="18px" height="18px" />
                                    <span><strong>부가가치세</strong>을 분기별 확인하고<br /><strong>엑셀파일로</strong>다운로드 가능합니다</span>
                                </div>
                            </div>
                            {isLoading ?
                                <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                                </div>
                                :
                                <>
                                    <div className="searching_line">
                                        <div style={{ width: "40%" }}>
                                            <div className="search_date">
                                                <DatePicker
                                                    picker="year"
                                                    bordered={false}
                                                    style={{ marginLeft: "7%", fontSize: "0.7rem" }}
                                                    value={moment(dateValue, defaultFormat)}
                                                    onChange={onOperatingDate}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ width: "15%" }} onClick={() => onOffQuarter("q1")}>
                                            <div className={q1 ? "select_rate" : "unselect_rate"}>
                                                <p>1분기</p>
                                            </div>
                                        </div>
                                        <div style={{ width: "15%" }} onClick={() => onOffQuarter("q2")}>
                                            <div className={q2 ? "select_rate" : "unselect_rate"}>
                                                <p>2분기</p>
                                            </div>
                                        </div>
                                        <div style={{ width: "15%" }} onClick={() => onOffQuarter("q3")}>
                                            <div className={q3 ? "select_rate" : "unselect_rate"}>
                                                <p>3분기</p>
                                            </div>
                                        </div>
                                        <div style={{ width: "15%" }} onClick={() => onOffQuarter("q4")}>
                                            <div className={q4 ? "select_rate" : "unselect_rate"}>
                                                <p>4분기</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="search_bar" onClick={searchList}>
                                        <img src={addressSearch} alt="none" />
                                        <p>조회</p>
                                    </div>
                                    <div className="payment_box">
                                        <p>매출자료</p>
                                    </div>
                                    <div className="data_title">
                                        <div className="pay_date">
                                            <p>기간</p>
                                        </div>
                                        <div className="pay_status">
                                            <p>매출분류</p>
                                        </div>
                                        <div className="pay_period">
                                            <p>매출</p>
                                        </div>
                                        <div className="pay_period">
                                            <p>부가세</p>
                                        </div>
                                        <div className="pay_amount">
                                            <p>합계</p>
                                        </div>
                                    </div>
                                    {iList !== undefined && iList !== null && iList.length > 0 ?
                                        <>
                                            {iList.map((item, index) => {
                                                return (
                                                    <div className="data_box_map" key={index}>
                                                        <div className="pay_date">
                                                            <p>{item.date}</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p style={{ color: "#617BE3" }}>기타매출</p>
                                                        </div>
                                                        <div className="pay_period">
                                                            <p>{item.pay} 원</p>
                                                        </div>
                                                        <div className="pay_period">
                                                            <p>{item.fee} 원</p>
                                                        </div>
                                                        <div className="pay_amount">
                                                            <p>{item.total} 원</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <div className="none_data_box_map">
                                            <p>데이터가 없습니다.</p>
                                        </div>
                                    }
                                    <div className="location_box_line" style={{ height: "4vh", marginBottom: "8%" }}>
                                        <div className="how_box2">
                                            <p>*본자료는 부가세 신고를 위한 참고자료입니다.
                                                <br />*매출분류는 각 사업자의 기준대로 신고하시면 됩니다.
                                                <br />*합계액은 사장님이 부담하신 할인액을 제외한 상품가액입니다.(스루에서 부담하는 할인 금액은 포함됩니다)
                                                <br />*면세상품판매자의 경우 부가세가 포함된 합계액이 매출신고액이 되어야 합니다.</p>
                                        </div>
                                    </div>
                                    <div className="payment_box">
                                        <p>매입자료</p>
                                    </div>
                                    <div className="data_title">
                                        <div className="pay_date">
                                            <p>기간</p>
                                        </div>
                                        <div className="pay_status">
                                            <p>매입분류</p>
                                        </div>
                                        <div className="pay_period">
                                            <p>건수</p>
                                        </div>
                                        <div className="pay_period">
                                            <p>공급가액</p>
                                        </div>
                                        <div className="pay_period">
                                            <p>부가세</p>
                                        </div>
                                        <div className="pay_amount">
                                            <p>합계</p>
                                        </div>
                                    </div>
                                    {iList !== undefined && iList !== null && iList.length > 0 ?
                                        <>
                                            {sList.map((item, index) => {
                                                return (
                                                    <div className="data_box_map" key={index}>
                                                        <div className="pay_date">
                                                            <p>{item.date}</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p style={{ color: "#617BE3" }}>결제수수료</p>
                                                        </div>
                                                        <div className="pay_period">
                                                            <p>{item.count}건</p>
                                                        </div>
                                                        <div className="pay_period">
                                                            <p>{item.pay} 원</p>
                                                        </div>
                                                        <div className="pay_period">
                                                            <p>{item.fee} 원</p>
                                                        </div>
                                                        <div className="pay_amount">
                                                            <p>{item.total} 원</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        :
                                        <div className="none_data_box_map">
                                            <p>데이터가 없습니다.</p>
                                        </div>
                                    }
                                    <div className="location_box_line" style={{ height: "7vh" }}>
                                        <div className="how_box" style={{ height: "6vh" }}>
                                            <p>*결제 수수료는 고객이 결제한 카드매출액의 결제수수료입니다.<br />*전자계산서로 발행된 내역이며 따로 신고하실 필요는 없습니다.</p>
                                        </div>
                                    </div>
                                    {isExcel &&
                                        <div className="excel_download">
                                            <div className="excel_download_box">
                                                <ExcelFile
                                                    filename={`스루-부가가치세 신고자료`}
                                                    element={
                                                        <p>파일 다운로드</p>
                                                    }
                                                >
                                                    <ExcelSheet dataSet={eList} name="요약" />
                                                </ExcelFile>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    <div className="payment_title_line">
                        <div className="payment_title">
                            <p>부가가치세 신고자료(참고용)</p>
                            <div className="tip">
                                <SVG src={manual} width="18px" height="18px" />
                                <span><strong>부가가치세</strong>을 분기별 확인하고<br /><strong>엑셀파일로</strong>다운로드 가능합니다</span>
                            </div>
                        </div>
                        {isLoading ?
                            <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                            :
                            <>
                                <div className="search_date">
                                    <DatePicker
                                        picker="year"
                                        bordered={false}
                                        style={{ marginLeft: "7%", fontSize: "0.7rem" }}
                                        value={moment(dateValue, defaultFormat)}
                                        onChange={onOperatingDate}
                                    />
                                </div>
                                <div className="select_quarter_line">
                                    <div style={{ width: "25%" }} onClick={() => onOffQuarter("q1")}>
                                        <div className={q1 ? "select_rate" : "unselect_rate"}>
                                            <p>1분기</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "25%" }} onClick={() => onOffQuarter("q2")}>
                                        <div className={q2 ? "select_rate" : "unselect_rate"}>
                                            <p>2분기</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "25%" }} onClick={() => onOffQuarter("q3")}>
                                        <div className={q3 ? "select_rate" : "unselect_rate"}>
                                            <p>3분기</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "25%" }} onClick={() => onOffQuarter("q4")}>
                                        <div className={q4 ? "select_rate" : "unselect_rate"}>
                                            <p>4분기</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="search_bar" onClick={searchList}>
                                    <img src={addressSearch} alt="none" />
                                    <p>조회</p>
                                </div>
                                <div className="payment_box">
                                    <p>매출자료</p>
                                </div>
                                {iList !== undefined && iList !== null && iList.length > 0 ?
                                    <>
                                        {iList.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>기간</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.date}</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>매출분류</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>기타매출</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>매출</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.pay} 원</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>부가세</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.fee} 원</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_total">
                                                        <div className="pay_status">
                                                            <p>합계</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.total} 원</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>
                                    :
                                    <div className="none_data_box_map">
                                        <p>데이터가 없습니다.</p>
                                    </div>
                                }
                                <div className="location_box_line" style={{ height: "5vh" }}>
                                    <div className="how_box" style={{ height: "5vh" }}>
                                        <p>*매출은 고객이 결제한 금액 + 스루에서 부담하는 할인 금액을 포함합니다</p>
                                    </div>
                                </div>
                                <div className="payment_box">
                                    <p>매입자료</p>
                                </div>
                                {iList !== undefined && iList !== null && iList.length > 0 ?
                                    <>
                                        {sList.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>기간</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.date}</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>매입분류</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>결제수수료</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>건수</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>1건</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>공급가액</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.pay} 원</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_title">
                                                        <div className="pay_status">
                                                            <p>부가세</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.fee} 원</p>
                                                        </div>
                                                    </div>
                                                    <div className="data_total">
                                                        <div className="pay_status">
                                                            <p>합계</p>
                                                        </div>
                                                        <div className="pay_status">
                                                            <p>{item.total} 원</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </>
                                    :
                                    <div className="none_data_box_map">
                                        <p>데이터가 없습니다.</p>
                                    </div>
                                }
                                <div className="location_box_line" style={{ height: "7vh" }}>
                                    <div className="how_box" style={{ height: "7vh" }}>
                                        <p>*결제 수수료는 고객이 결제한 카드매출액의 결제수수료입니다.<br />*전자계산서로 발행된 내역이며 따로 신고하실 필요는 없습니다.</p>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default TaxPage;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .location_box_line {
        margin-top: 5%;
        margin-bottom: 5%;
        
        .how_box {
            display: flex;
            align-items: center;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }

        .how_box2 {
            display: flex;
            align-items: center;
            height: 9vh;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }

    }

    .excel_download {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 10vh;
        margin-bottom: 5%;

        .excel_download_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 7vw;
            height: 4vh;
            border: 1px solid #617be3;
            border-radius: 10px;
            cursor: pointer;

            p {
                color: #617be3;
                font-size: 0.8rem;
                font-weight: 400;
            }
        }

        .excel_download_box:hover {
            background: #617be3;

            p {
                color: #fff;
            }
        }
    }

    .payment_box {
        margin-top: 5%;
        display: flex;
        align-items: center;
        height: 5vh;
        
        p {
            font-size: 0.9rem;
            font-weight: 400;
        }
    }

    .content_container {
        width: 42vw; 
        border-radius: 4px;
        margin-bottom: 4%;
        background-color: #F1F3F7;

        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 180px;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }
    }

    .title_box {
        display: flex;
        align-items: center;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 16px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
    }

    .title_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3vh;
        width: 100%;

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }
    }
    .last_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 8vh;
        padding-bottom: 1%;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 24px;
            color: #617BE3;
        }

        .today_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
            
        }

        .yesterday_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
        }
    }

    .searching_line {
        margin-top: 3%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;
    }

    .search_bar {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #fff;
        }
    }

    .select_rate {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #fff;
        }
    }

    .unselect_rate {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 5vh;
        border: 1px solid #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #617BE3;
        }
    }

    .search_file {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        width: 70%;
        height: 5vh;
        
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.7rem;
            text-align: center;
            color: #fff;
        }
    }

    .search_no_file {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        width: 70%;
        height: 5vh;
        
        background: #dfdfdf;
        border-radius: 5px;
        cursor: not-allowed;

        p {
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .search_selectOption {
        display: flex;
        align-items: center;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .search_date {
        display: flex;
        align-items: center;
        margin-right: 5%;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .data_box {
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 20%;
    }

    .nodata_box {
        margin-top: 3%;
    }

    .data_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #000;
        border-bottom: 1px solid #dfdfdf;
        height: 3vh;

        .pay_date {
            display: flex;
            align-items: center;
            width: 25%;
        }
        .pay_status {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .pay_period {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .pay_amount {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        p{
            font-style: normal;
            font-weight: 400;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .none_data_box_map {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dfdfdf;
        height: 7vh;

        p{
            font-style: normal;
            font-weight: 500;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .data_box_map {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dfdfdf;
        height: 7vh;

        .pay_date {
            display: flex;
            align-items: center;
            width: 25%;
        }
        .pay_status {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
        .pay_period {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .pay_amount {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        p{
            font-style: normal;
            font-weight: 500;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }
    
`



const Mstyle = styled.div`

    .location_box_line {
        margin-top: 5%;
        
        .how_box {
            display: flex;
            align-items: center;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.6rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .payment_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .payment_title_line {
        padding: 20px 16px;
        margin-bottom: 5%;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .search_date {
        margin-top: 5%;
        display: flex;
        align-items: center;
        height: 6vh;
        width: 60%;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .select_quarter_line {
        margin-top: 3%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 7vh;
    }

    .select_rate {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 6vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #fff;
        }
    }

    .unselect_rate {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        height: 6vh;
        border: 1px solid #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #617BE3;
        }
    }

    .search_bar {
        margin-top: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 6vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #fff;
        }
    }

    .payment_box {
        margin-top: 5%;
        display: flex;
        align-items: center;
        height: 6vh;
        border-bottom: 1px solid #000;
        p {
            font-size: 0.9rem;
            font-weight: 400;
        }
    }

    .data_total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 6vh;
        border-bottom: 1px solid #dfdfdf;

        p{
            font-style: normal;
            font-weight: 400;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .data_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 6vh;

        p{
            font-style: normal;
            font-weight: 400;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .none_data_box_map {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dfdfdf;
        height: 7vh;

        p{
            font-style: normal;
            font-weight: 500;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

`


