export const Types = {
   SETUSERCONFIG: "user/SETUSERCONFIG"
};

// Initial State
const initialState = {
   isPOS: false,
   LoggedIn: false,
   SalesLoggedIn: false,
   SalesAutoLogin: false,
   AutoLogin: false,
   RememberId: false,
   Token: '',
   RefreshToken: '',
   UUID: "",
   LocalToken: "",
   LoginTime: "",
   SalesLoginTime: "",

   Commercial: false,
   
   UserId: "",
   UserKey: "",

   SalesId: 0,
   SalesGroupId: 0,
   SalesEmail: "",
   SalesKey: "",
   SalesUserName: "",
   SalesUserType: "",
   SalesStoreList: [],

   tempPath: "",

   StoreID: 0,
   StoreName: '',
   StorePhone: '',
   Address: "",
   StoreLat: 37.4959310000,
   StoreLng: 127.0266190000,
   StoreOwner: "",

   accessible: "inaccessible",
};

export function setUserConfig(oResult) {
   return {
      type: Types.SETUSERCONFIG,
      payload: {
         result: oResult
      }
   };
}

const UserConfigReducer = (state = initialState, action) => {
   switch (action.type) {
      case Types.SETUSERCONFIG: {
         let oMerged = { ...state, ...action.payload.result };
         return oMerged;
      }
      default: {
         return state;
      }
   }
};

// Exports
export default UserConfigReducer;