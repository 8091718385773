import React, { useState, useEffect, useRef, Fragment } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import { Pagination, message, Modal } from "antd";
import Loader from "react-loader-spinner";
import Select from "react-select";
import { GuideStock } from "../../../modal/AppModal";
import guide_arrow from "../../../../assets/svg/guide_arrow.svg";
import guide_question from "../../../../assets/svg/guide_question.svg";

import { ModalInventory } from "../../../modal/inventory/InventoryModal";
import { BrowserView, isMobile, MobileView } from "react-device-detect";

const Inventory = ({ sProps, fnMoveCategory }) => {
  const [sKey, setKey] = useState(null);
  const [sCurrent, setCurrent] = useState(1);
  const [sCategory, setCategory] = useState("");
  const [option, set_option] = useState("");

  const [itemModal, setItemModal] = useState(false);

  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(5);

  const [sList, setSList] = useState([]);
  const [iList, setIList] = useState([]);
  const [optionList, set_optionList] = useState([]);
  const [optionTypeList, set_optionTypeList] = useState([]);

  const itemRef = useRef({});
  const [oSharedMenuData, set_oSharedMenuData] = useState("");

  const [stock_refreshed_time_value, set_stock_refreshed_time_value] = useState(
    []
  );
  const [checked, setChecked] = useState([false]);
  const [modal, contextHolder] = Modal.useModal();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // input 변경값 체크

  useEffect(() => {
    function handleBeforeUnload(event) {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const modalOpenGuide = {
    width: "50vw",
    bodyStyle: {
      height: "70vh",
      overflow: "hidden",
      overflowY: "scroll",
      paddingRight: "10px",
    },
    maskClosable: true,
    content: <GuideStock sProps={sProps} />,
  };
  const modalMobileOpenGuide = {
    width: "90vw",
    maskClosable: true,
    bodyStyle: {
      height: "55vh",
      overflow: "hidden",
      overflowY: "scroll",
      paddingRight: "10px",
    },
    content: <GuideStock sProps={sProps} />,
  };
  const stock_time = [
    { value: 0, label: "시간 선택 안함" },
    { value: 1, label: "1시" },
    { value: 2, label: "2시" },
    { value: 3, label: "3시" },
    { value: 4, label: "4시" },
    { value: 5, label: "5시" },
    { value: 6, label: "6시" },
    { value: 7, label: "7시" },
    { value: 8, label: "8시" },
    { value: 9, label: "9시" },
    { value: 10, label: "10시" },
    { value: 11, label: "11시" },
    { value: 12, label: "12시" },
    { value: 13, label: "13시" },
    { value: 14, label: "14시" },
    { value: 15, label: "15시" },
    { value: 16, label: "16시" },
    { value: 17, label: "17시" },
    { value: 18, label: "18시" },
    { value: 19, label: "19시" },
    { value: 20, label: "20시" },
    { value: 21, label: "21시" },
    { value: 22, label: "22시" },
    { value: 23, label: "23시" },
    { value: 24, label: "24시" },
    { value: 25, label: "매장 오픈 시간" },
  ];

  const alertMessage = (sIndex) => {
    message.warning({
      content: sIndex,
      className: "custom-class",
      style: {
        fontSize: "0.9rem",
        fontWeight: "500",
      },
    });
  };

  const changedModal = async () => {
    setItemModal(false);
    alertMessage("정상적으로 변경되었습니다");
    getData(sKey);
  };

  const modalClose = async () => {
    setItemModal(false);
  };

  const checkItem = async (sIndex) => {
    itemRef.current = sIndex;
    setItemModal(true);
  };

  const onChangePage = (page) => {
    let tempStart = 0;
    let tempEnd = 5;

    if (parseInt(page) !== 1) {
      tempEnd = tempEnd * page;
      tempStart = tempEnd - 5;
    }
    setStartPage(tempStart);
    setEndPage(tempEnd);
    setCurrent(page);
  };

  const changeStatus = async (item) => {
    const oData = {
      sIndex: item.id,
      aIndex: item.status,
    };
    const oResponse = await sProps.oManager.fDoAxios(
      "/inventory/changeStatus",
      "post",
      "login",
      oData
    );
    if (oResponse !== undefined) {
      if (oResponse === "0000") {
        changedModal();
      } else {
        alertMessage("네트워크에러입니다 잠시 후 다시 시도바랍니다");
      }
    }
  };

  const changeOptionStatus = async (item) => {
    const store_id = sProps.UserConfigReducer.StoreID;
    const iOptionId = item.option_id;
    const iOptionTypeId = item.option_type_id;
    const bSoldOut = !item.sold;

    try {
      const oResponse = await sProps.oManager.fDoAxios(
        "/inventory/option/changeSold",
        "post",
        "login",
        {
          store_id: store_id,
          sIndex: iOptionId,
          sTypeIndex: iOptionTypeId,
          bIndex: bSoldOut,
        }
      );

      if (oResponse === "1111") {
        message.error("옵션그룹 최소 선택수 이상 품절할 수 없습니다.");
      }

      moveOptionType(item.option_type_id);
    } catch (err) {
      message.error("옵션 재고관리 오류입니다.");
    }
  };

  const moveCategory = async (sIndex, aIndex) => {
    if (isMobile) {
      if (hasUnsavedChanges) {
        const confirmDiscardChanges = window.confirm(
          "변경사항이 있습니다. 저장하시겠습니까?"
        );
        if (!confirmDiscardChanges) {
        } else {
          onSubmit();
        }
      } else {
        setHasUnsavedChanges(false);
        setCategory(aIndex);
        setKey(sIndex);
        getData(sIndex);
        set_option("");
        set_optionTypeList([]);
        set_optionList([]);
      }
    } else {
      // 변경사항체크
      if (hasUnsavedChanges) {
        const confirmDiscardChanges = window.confirm(
          "변경사항이 있습니다. 저장하시겠습니까?"
        );
        if (!confirmDiscardChanges) {
        } else {
          onSubmit();
        }
      } else {
        setHasUnsavedChanges(false);
        setCategory(aIndex);
        setKey(sIndex);
        setCurrent(1);
        setStartPage(0);
        setEndPage(5);
        getData(sIndex);
        set_option("");
        set_optionTypeList([]);
        set_optionList([]);
      }
    }
  };

  const moveOptionType = async (item) => {
    try {
      const oResponse = await sProps.oManager.fDoAxios(
        "/store/register/detailOptionRow-" + item,
        "get",
        "login",
        null
      );
      set_optionList(oResponse.resultData.list);
    } catch (err) {
      message.error("옵션 재고관리 오류입니다.");
    }
  };

  const moveOption = async () => {
    const store_id = sProps.UserConfigReducer.StoreID;
    set_option("optionOpen");
    const oResponse = await sProps.oManager.fDoAxios(
      "/store/register/optionList-" + store_id,
      "get",
      "login",
      null
    );

    if (oResponse !== undefined) {
      if (oResponse.length > 0) {
        for (let i = 0; i < oResponse.length; i++) {
          oResponse[i].value = oResponse[i].id;
          oResponse[i].label = oResponse[i].name;
        }
        set_optionTypeList(oResponse);
      }
    }
  };

  const asyncData = async () => {
    const store_id = sProps.UserConfigReducer.StoreID;
    const oResponse = await sProps.oManager.fDoAxios(
      "/store/register/categoryList-" + store_id,
      "get",
      "login",
      null
    );
    if (oResponse !== undefined) {
      if (oResponse.sResult !== undefined) {
        if (oResponse.sResult.length > 0) {
          setSList(oResponse.sResult);
          setCategory(oResponse.sResult[0].name);
          setKey(oResponse.sResult[0].id);
          getData(oResponse.sResult[0].id);
        } else {
          alertMessage("상품등록이 필요합니다.");
          if (
            fnMoveCategory !== undefined &&
            typeof fnMoveCategory === "function"
          ) {
            await fnMoveCategory("dashboard");
          }
        }
      }
    }
  };

  const checkForSharedMenu = async () => {
    const store_id = sProps.UserConfigReducer.StoreID;
    const oResponse = await sProps.oManager.fDoAxios(
      "/store/menu/checkShared",
      "post",
      null,
      { store_id: store_id }
    );
    if (oResponse !== undefined) {
      if (
        oResponse.has_shared_menu !== undefined &&
        oResponse.has_shared_menu === 1
      ) {
        set_oSharedMenuData(oResponse);
        // return;
      }
    }
    asyncData();
  };

  // 재고관리 데이터
  const getData = async (aIndex) => {
    let aData = [];

    const sIndex = aIndex;
    const store_id = sProps.UserConfigReducer.StoreID;
    const oResponse = await sProps.oManager.fDoAxios(
      "/inventory/menuSupplyList",
      "post",
      "login",
      { store_id, category_id: sIndex }
    );
    console.log("oResponse>>>>>>>", oResponse);
    try {
      if (
        oResponse != undefined &&
        oResponse != false &&
        oResponse.success === true
      ) {
        for (let i = 0; i < oResponse.stocks.length; i++) {
          const default_stock = oResponse.stocks[i].default_stock;
          const in_stock = oResponse.stocks[i].in_stock;
          const name = oResponse.stocks[i].name;
          const product_id = oResponse.stocks[i].product_id;
          const sold = oResponse.stocks[i].sold;
          const stock_refreshed_time = oResponse.stocks[i].stock_refreshed_time;
          const stock_status = oResponse.stocks[i].stock_status;

          const oParams = {
            default_stock: default_stock,
            in_stock: in_stock,
            name: name,
            product_id: product_id,
            sold: sold,
            stock_refreshed_time: stock_refreshed_time,
            stock_status: stock_status,
          };
          aData.push(oParams);
        }

        setIList(aData);
      } else {
        setIList([]);
      }
    } catch (err) {
      console.log("err-->", err);
    }
  };
  const oReducerSetter = React.useRef();

  // 저장하기
  const onSubmit = async () => {
    let temp = [];
    let check = false;
    let tmp = Array.from(iList);
    for (let i = 0; i < iList.length; i++) {
      if (tmp[i].in_stock > 100000) {
        tmp[i].in_stock = 0;
        check = true;
        message.error("입력 가능한 실재고량의 수는 100,000개 이하입니다.");
        break;
      } else if (tmp[i].default_stock > 100000) {
        tmp[i].default_stock = 0;
        check = true;
        message.error("입력 가능한 일일재고량의 수는 100,000개 이하입니다.");
        break;
      } else if (tmp[i].in_stock > 0 && tmp[i].stock_status === 0) {
        check = true;
        message.error("재고관리를 사용하실 경우 사용 여부를 체크해 주세요.");
        break;
      } else if (
        tmp[i].default_stock === null ||
        tmp[i].default_stock === undefined ||
        tmp[i].default_stock === ""
      ) {
        tmp[i].default_stock = "0";
      } else if (
        tmp[i].in_stock === null ||
        tmp[i].in_stock === undefined ||
        tmp[i].in_stock === ""
      ) {
        tmp[i].in_stock = "0";
      } else if (tmp[i].stock_status === 1 && tmp[i].in_stock === "0") {
        tmp[i].sold = "1";
      }
      let tObj = { ...iList[i] };
      delete tObj.name;
      delete tObj.selected_refresh_object;

      temp.push(tObj);
    }

    if (check) return setIList(tmp);

    try {
      const oParams = {
        store_id: sProps.UserConfigReducer.StoreID,
        stocks: temp,
      };
      if (
        iList.filter(
          (e) =>
            e.stock_status === 1 &&
            e.stock_refreshed_time === 0 &&
            e.default_stock > 0
        ).length
      ) {
        message.error(
          "일일 재고량을 사용하는 경우 매일 일일 재고량이 채워지는 시간을 선택해 주세요."
        );
      } else {
        const oResponse = await sProps.oManager.fDoAxios(
          "/inventory/changeSupply",
          "post",
          null,
          oParams
        );
        if (oResponse != undefined && oResponse != null) {
          console.log("temp>>>>", temp);
          console.log("oResponse", oResponse);
          if (oResponse.success) {
            setIList(tmp);
            message.success("저장되었습니다.");
            setHasUnsavedChanges(false);
          } else {
            message.error("오류가 발생했습니다. 다시 시도해 주세요.");
          }
        }
      }
    } catch (err) {
      console.log("err-->", err);
      message.error("오류가 발생했습니다. 다시 시도해 주세요.");
    }
  };

  // 품절처리
  const change_stock_status = (item, index) => {
    let aTemp = Array.from(iList);

    if (aTemp[index].sold === "0") {
      aTemp[index].sold = "1";
      item.sold = "1";
    } else if (aTemp[index].sold === "1") {
      aTemp[index].sold = "0";
      item.sold = "0";
    }
    setIList(aTemp);
    setHasUnsavedChanges(true);
  };

  // 일일 재고량
  const change_default_stock_value = (e, index) => {
    let default_stock_count = Array.from(iList);
    const { value } = e.target;
    if (isNaN(value)) {
      return;
    }

    default_stock_count[index].default_stock = parseInt(value);
    setIList(default_stock_count);
    setHasUnsavedChanges(true);
  };
  // 실 재고량
  const change_in_stock_value = (e, index) => {
    let in_stock_count = Array.from(iList);
    const { value } = e.target;
    in_stock_count[index].in_stock = value;
    setIList(in_stock_count);
    setHasUnsavedChanges(true);
  };
  // 재고량 채워지는 시간
  const StockTimeComponent = ({ index }) => (
    <Select
      options={stock_time}
      defaultValue={stock_time[0]}
      value={stock_time[iList[index].stock_refreshed_time]}
      onChange={(e) => {
        let in_stock_count = Array.from(iList);
        in_stock_count[index].stock_refreshed_time = e.value;
        in_stock_count[index].selected_refresh_object = e;
        set_stock_refreshed_time_value(
          in_stock_count[index].stock_refreshed_time
        );
        setIList(in_stock_count);
        setHasUnsavedChanges(true);
      }}
      styles={{ width: "50%" }}
    />
  );
  // 재고 관리 사용 여부
  const handleCheck = (e, index) => {
    let sStatus = Array.from(iList);
    const { value } = e.target;
    sStatus[index].stock_status = value;

    const isChecked = e.target.checked;
    const _checked = [...checked];
    _checked[index] = e.target.checked;
    setChecked(_checked);

    if (isChecked) {
      sStatus[index].stock_status = 1;
      sStatus[index].stock_refreshed_time = 25;
    } else {
      sStatus[index].stock_status = 0;
    }
    setIList(sStatus);
    setHasUnsavedChanges(true);
  };

  useEffect(() => {
    checkForSharedMenu();
  }, []);
  return (
    <Fragment>
      <BrowserView>
        <Style>
          <div className="wrapper">
            {oSharedMenuData !== "" &&
              oSharedMenuData.has_shared_menu !== undefined && (
                <Row>
                  <Col>
                    <div className="menu_container">
                      <div className="tab" style={{ height: "10vh" }}>
                        <div className="title_box">
                          <p
                            style={{
                              paddingLeft: 30,
                              fontSize: 14,
                              color: "red",
                              fontWeight: 600,
                            }}
                          >
                            공유 메뉴를 사용하고 있지만 품절상태는 공유 되지
                            않습니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            {
              //oSharedMenuData === '' &&
              <Row>
                <Col>
                  <div className="firstLine">
                    <div className="menu_container">
                      {sList.length > 0 ? (
                        <div className="tab">
                          <ScrollContainer
                            className="scrollBar"
                            hideScrollbars={false}
                            style={{ width: "100%" }}
                          >
                            {sList.map((item, index) => {
                              return (
                                <div
                                  className={
                                    item.name === sCategory
                                      ? "scroll_menu_active"
                                      : "scroll_menu"
                                  }
                                  key={"listing-" + index.toString()}
                                  onClick={() =>
                                    moveCategory(item.id, item.name)
                                  }
                                >
                                  {item.name}
                                </div>
                              );
                            })}
                          </ScrollContainer>
                        </div>
                      ) : (
                        <div
                          className="tab"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "8vh",
                            alignItems: "center",
                          }}
                        >
                          {/* <Loader type="BallTriangle" color="#13166b" height={40} width={40} timeout={9000000} /> */}
                          <p>
                            등록된 상품분류가 없습니다. 상품정보에서 상품분류를
                            등록해주세요.
                          </p>
                        </div>
                      )}
                    </div>
                    <div
                      className="option_wrap"
                      style={{ position: "relative" }}
                    >
                      <div
                        className="content_container"
                        style={{ marginBottom: "100px" }}
                      >
                        {option === "optionOpen" ? (
                          <div className="optionContent">
                            <div className="title">
                              <h2>옵션 재고관리</h2>
                            </div>
                            <Select
                              defaultValue="옵션을 선택해주세요"
                              style={{ width: "100%", marginTop: "20px" }}
                              onChange={moveOptionType}
                              options={optionTypeList}
                            />
                            <div className="option_list">
                              {optionList.map((item, index) => {
                                return (
                                  <div className="menuContent">
                                    <div className="menu">
                                      <div className="oList">
                                        <h3>{item.name}</h3>
                                        <p>{item.price}원</p>
                                        <div className="menuButton">
                                          <div
                                            className={
                                              !item.sold ? "stock" : "sold_out"
                                            }
                                            onClick={() =>
                                              changeOptionStatus(item)
                                            }
                                          >
                                            <h2>
                                              {!item.sold
                                                ? "판매중"
                                                : "일시품절"}
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div>
                            {iList.length > 0 ? (
                              <div style={{ height: "auto" }}>
                                <div className="title_box">
                                  <h2>{sCategory}</h2>
                                </div>
                                <table className="stock_table">
                                  <thead className="stock_title">
                                    <tr>
                                      <th>상품명</th>
                                      <th>
                                        재고 관리
                                        <br /> 사용 여부
                                      </th>
                                      <th>현재수량</th>
                                      <th>일일 재고량</th>
                                      <th>일일 재고량 채워지는 시간</th>
                                      <th>실재고량</th>
                                      <th>품절</th>
                                    </tr>
                                  </thead>
                                  {iList.map((item, index) => {
                                    return (
                                      <tbody
                                        className="stock_content"
                                        key={"listing-" + index.toString()}
                                      >
                                        <tr>
                                          <td>{item.name}</td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              style={{
                                                width: "18px",
                                                height: "18px",
                                              }}
                                              value={item.stock_status}
                                              checked={item.stock_status}
                                              onChange={(e) =>
                                                handleCheck(e, index)
                                              }
                                            />
                                          </td>
                                          <td>{item.in_stock}개</td>
                                          <td>
                                            <input
                                              type="number"
                                              placeholder="수량입력"
                                              value={item.default_stock}
                                              onChange={(e) =>
                                                change_default_stock_value(
                                                  e,
                                                  index
                                                )
                                              }
                                              min="0"
                                            />
                                          </td>
                                          <td>
                                            <StockTimeComponent
                                              item={item}
                                              index={index}
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              placeholder="수량입력"
                                              value={item.in_stock}
                                              onChange={(e) =>
                                                change_in_stock_value(e, index)
                                              }
                                              min="0"
                                            />
                                          </td>
                                          <td
                                            className={
                                              item.sold === "0"
                                                ? "stock"
                                                : "sold_out"
                                            }
                                            onClick={() =>
                                              change_stock_status(item, index)
                                            }
                                          >
                                            <h2>
                                              {item.sold === "0"
                                                ? "판매중"
                                                : "일시품절"}
                                            </h2>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })}
                                </table>
                                <button
                                  className="stock_submit"
                                  onClick={onSubmit}
                                >
                                  전체 저장하기
                                </button>

                                {/* <div className="page_nation">
                                                   <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={iList.length} />
                                                </div> */}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "30vh",
                                  alignItems: "center",
                                }}
                              >
                                {/* <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} /> */}
                                <p>
                                  등록된 상품이 없습니다. 상품정보에서 상품을
                                  등록해주세요
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="side_help_content">
                        <div
                          className="app_guide_modal_container"
                          onClick={() => modal.success(modalOpenGuide)}
                        >
                          <div className="arrow_box">
                            <img src={guide_arrow} alt="none" />
                          </div>
                          <div className="text_box">
                            <div className="title_box">
                              <img
                                className="help_content_img"
                                src={guide_question}
                                alt="none"
                              />
                              <p>간편도움말</p>
                            </div>
                            <div className="detail_box_line">
                              <p>
                                재고관리 설정방법에 대해 <br />
                                자세하게 알려드립니다.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {contextHolder}
                    </div>
                  </div>
                </Col>
              </Row>
            }
          </div>
          {itemModal && (
            <ModalInventory
              sProps={sProps}
              dataBox={itemRef.current}
              iBannerModal={itemModal}
              fnClose={() => modalClose()}
              fnComplete={() => changedModal()}
            />
          )}
        </Style>
        <PosStyle>
          <div className="posWebView">
            <Mstyle>
              {oSharedMenuData !== "" &&
                oSharedMenuData.has_shared_menu !== undefined && (
                  <div className="stockPage">
                    <div className="menu_container" style={{ height: "12vh" }}>
                      <p
                        style={{
                          fontSize: 14,
                          color: "red",
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 10,
                          fontWeight: 600,
                        }}
                      >
                        공유 메뉴를 사용하고 있지만 품절상태는 공유 되지
                        않습니다.
                      </p>
                    </div>
                  </div>
                )}
              {
                //oSharedMenuData === '' &&
                <div
                  className="stockPage"
                  style={{
                    background: "#F1F3F7",
                    width: "75vw",
                    padding: "3vw",
                  }}
                >
                  <div className="tapPage">
                    <div className="menu_container">
                      {sList.length > 0 ? (
                        <div className="tab">
                          <ScrollContainer
                            className="scrollBar"
                            hideScrollbars={false}
                            style={{ width: "100%" }}
                          >
                            {sList.map((item, index) => {
                              return (
                                <div
                                  className={
                                    item.name === sCategory
                                      ? "scroll_menu_active"
                                      : "scroll_menu"
                                  }
                                  key={"listing-" + index.toString()}
                                  onClick={() =>
                                    moveCategory(item.id, item.name)
                                  }
                                >
                                  {item.name}
                                </div>
                              );
                            })}
                          </ScrollContainer>
                          {/* <button className='optionGroup' onClick={() => moveOption()}>옵션재고관리</button> */}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "10vh",
                            alignItems: "center",
                          }}
                        >
                          <Loader
                            type="BallTriangle"
                            color="#13166b"
                            height={40}
                            width={40}
                            timeout={9000000}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {option === "optionOpen" ? (
                    <div className="optionContent">
                      <div className="title">
                        <h2>옵션 재고관리</h2>
                      </div>
                      <Select
                        defaultValue="옵션을 선택해주세요"
                        style={{
                          width: "100%",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                        onChange={moveOptionType}
                        options={optionTypeList}
                      />
                      <div className="option_list">
                        {optionList.map((item, index) => {
                          return (
                            <div className="optionSelect">
                              <div className="menu">
                                <div className="oList">
                                  <h3>{item.name}</h3>
                                  <p>{item.price}원</p>
                                  <div className="menuButton">
                                    <div
                                      className={
                                        !item.sold ? "stock" : "sold_out"
                                      }
                                      onClick={() => changeOptionStatus(item)}
                                    >
                                      <h2>
                                        {!item.sold ? "판매중" : "일시품절"}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="totalPage">
                        {iList.length > 0 ? (
                          <>
                            <div
                              className="app_guide_modal_container"
                              onClick={() =>
                                modal.success(modalMobileOpenGuide)
                              }
                            >
                              <div className="stock_manual">
                                <h2>
                                  🔮 이곳을 클릭해 재고관리 사용법을 알아보세요
                                  🔮
                                </h2>
                              </div>
                            </div>
                            {contextHolder}
                            <div className="title_box">
                              <h2>{sCategory}</h2>
                            </div>
                            {iList.map((item, index) => {
                              return (
                                <div className="mob_stock_wrap">
                                  <div className="title">
                                    <h2>{item.name}</h2>
                                    <h3
                                      style={{
                                        marginLeft: "8px",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {item.in_stock}개
                                    </h3>
                                    <button
                                      className={
                                        item.sold === "0" ? "stock" : "sold_out"
                                      }
                                      onClick={() =>
                                        change_stock_status(item, index)
                                      }
                                    >
                                      <p>
                                        {item.sold === "0"
                                          ? "판매중"
                                          : "일시품절"}
                                      </p>
                                    </button>
                                  </div>
                                  <div className="stock_content">
                                    <div className="stock_checkbox">
                                      <input
                                        type="checkbox"
                                        style={{
                                          width: "18px",
                                          height: "18px",
                                        }}
                                        value={item.stock_status}
                                        checked={item.stock_status}
                                        onChange={(e) => handleCheck(e, index)}
                                      />
                                      <h2>재고 사용 여부</h2>
                                    </div>
                                    <div className="default_stock">
                                      <h3>일일 재고량</h3>
                                      <input
                                        type="number"
                                        placeholder="수량입력"
                                        value={item.default_stock}
                                        onChange={(e) =>
                                          change_default_stock_value(e, index)
                                        }
                                        min="0"
                                      />
                                    </div>
                                    <div className="stock_time">
                                      <h3>
                                        일일 재고량
                                        <br /> 채워지는 시간
                                      </h3>
                                      <StockTimeComponent
                                        item={item}
                                        index={index}
                                      />
                                    </div>
                                    <div className="in_stock">
                                      <h3>실재고량</h3>
                                      <input
                                        type="number"
                                        placeholder="수량입력"
                                        value={item.in_stock}
                                        onChange={(e) =>
                                          change_in_stock_value(e, index)
                                        }
                                        min="0"
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            <button className="stock_submit" onClick={onSubmit}>
                              전체 저장하기
                            </button>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              height: "30vh",
                              alignItems: "center",
                            }}
                          >
                            <Loader
                              type="BallTriangle"
                              color="#13166b"
                              height={80}
                              width={80}
                              timeout={9000000}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              }
            </Mstyle>
          </div>
        </PosStyle>
      </BrowserView>
      <MobileView>
        <Mstyle>
          {oSharedMenuData !== "" &&
            oSharedMenuData.has_shared_menu !== undefined && (
              <div className="stockPage">
                <div className="menu_container" style={{ height: "12vh" }}>
                  <p
                    style={{
                      fontSize: 14,
                      color: "red",
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 10,
                      fontWeight: 600,
                    }}
                  >
                    공유 메뉴를 사용하고 있지만 품절상태는 공유 되지 않습니다.
                  </p>
                </div>
              </div>
            )}
          {
            //oSharedMenuData === '' &&
            <div className="stockPage">
              <div className="tapPage">
                <div className="menu_container">
                  {sList.length > 0 ? (
                    <div className="tab">
                      <ScrollContainer
                        className="scrollBar"
                        hideScrollbars={false}
                        style={{ width: "100%" }}
                      >
                        {sList.map((item, index) => {
                          return (
                            <div
                              className={
                                item.name === sCategory
                                  ? "scroll_menu_active"
                                  : "scroll_menu"
                              }
                              key={"listing-" + index.toString()}
                              onClick={() => moveCategory(item.id, item.name)}
                            >
                              {item.name}
                            </div>
                          );
                        })}
                      </ScrollContainer>
                      {/* <button className='optionGroup' onClick={() => moveOption()}>옵션재고관리</button> */}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "10vh",
                        alignItems: "center",
                      }}
                    >
                      <Loader
                        type="BallTriangle"
                        color="#13166b"
                        height={40}
                        width={40}
                        timeout={9000000}
                      />
                    </div>
                  )}
                </div>
              </div>
              {option === "optionOpen" ? (
                <div className="optionContent">
                  <div className="title">
                    <h2>옵션 재고관리</h2>
                  </div>
                  <Select
                    defaultValue="옵션을 선택해주세요"
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    onChange={moveOptionType}
                    options={optionTypeList}
                  />
                  <div className="option_list">
                    {optionList.map((item, index) => {
                      return (
                        <div className="optionSelect">
                          <div className="menu">
                            <div className="oList">
                              <h3>{item.name}</h3>
                              <p>{item.price}원</p>
                              <div className="menuButton">
                                <div
                                  className={!item.sold ? "stock" : "sold_out"}
                                  onClick={() => changeOptionStatus(item)}
                                >
                                  <h2>{!item.sold ? "판매중" : "일시품절"}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <>
                  <div className="totalPage">
                    {iList.length > 0 ? (
                      <>
                        <div
                          className="app_guide_modal_container"
                          onClick={() => modal.success(modalMobileOpenGuide)}
                        >
                          <div className="stock_manual">
                            <h2>
                              🔮 이곳을 클릭해 재고관리 사용법을 알아보세요 🔮
                            </h2>
                          </div>
                        </div>
                        {contextHolder}
                        <div className="title_box">
                          <h2>{sCategory}</h2>
                        </div>
                        {iList.map((item, index) => {
                          return (
                            <div className="mob_stock_wrap">
                              <div className="title">
                                <h2>{item.name}</h2>
                                <h3
                                  style={{
                                    marginLeft: "8px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.in_stock}개
                                </h3>
                                <button
                                  className={
                                    item.sold === "0" ? "stock" : "sold_out"
                                  }
                                  onClick={() =>
                                    change_stock_status(item, index)
                                  }
                                >
                                  <p>
                                    {item.sold === "0" ? "판매중" : "일시품절"}
                                  </p>
                                </button>
                              </div>
                              <div className="stock_content">
                                <div className="stock_checkbox">
                                  <input
                                    type="checkbox"
                                    style={{ width: "18px", height: "18px" }}
                                    value={item.stock_status}
                                    checked={item.stock_status}
                                    onChange={(e) => handleCheck(e, index)}
                                  />
                                  <h2>재고 사용 여부</h2>
                                </div>
                                <div className="default_stock">
                                  <h3>일일 재고량</h3>
                                  <input
                                    type="number"
                                    placeholder="수량입력"
                                    value={item.default_stock}
                                    onChange={(e) =>
                                      change_default_stock_value(e, index)
                                    }
                                    min="0"
                                  />
                                </div>
                                <div className="stock_time">
                                  <h3>
                                    일일 재고량
                                    <br /> 채워지는 시간
                                  </h3>
                                  <StockTimeComponent
                                    item={item}
                                    index={index}
                                  />
                                </div>
                                <div className="in_stock">
                                  <h3>실재고량</h3>
                                  <input
                                    type="number"
                                    placeholder="수량입력"
                                    value={item.in_stock}
                                    onChange={(e) =>
                                      change_in_stock_value(e, index)
                                    }
                                    min="0"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <button className="stock_submit" onClick={onSubmit}>
                          전체 저장하기
                        </button>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "30vh",
                          alignItems: "center",
                        }}
                      >
                        <Loader
                          type="BallTriangle"
                          color="#13166b"
                          height={80}
                          width={80}
                          timeout={9000000}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          }
        </Mstyle>
      </MobileView>
    </Fragment>
  );
};

export default Inventory;

const PosStyle = styled.div`
  @media (min-width: 1201px) {
    .posWebView {
      display: none !important;
    }
  }
`;
const Style = styled.div`
  @media (max-width: 1200px) {
    .wrapper {
      display: none !important;
    }
    .posWebView {
      display: block !important;
      padding: 5vw !important;

      .stockPage {
        background: #f1f3f7 !important;
      }
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
    color: #000;
  }

  .wrapper {
    background-color: #f1f3f7;
    padding-top: 6%;
    padding-left: 15%;
    padding-bottom: 2.2%;
  }

  .menu_container {
    margin-top: 10px;
    border-radius: 4px;

    .tab {
      display: flex;
      align-items: center;
      padding: 0;
      width: 42vw;
      margin-bottom: 24px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
      white-space: nowrap;
    }
    .scroll_menu {
      display: inline-block;
      text-align: center;
      text-decoration: none;
      margin: 30px 3%;
      cursor: pointer;
      *display: inline;
      *zoom: 1;
      vertical-align: top;
      font-size: 0.9rem;
      font-weight: 400;
      color: #666;
    }

    .scroll_menu_active {
      border-bottom: 1px solid #000;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      margin: 30px 3%;
      cursor: pointer;
      *display: inline;
      *zoom: 1;
      vertical-align: top;
      font-size: 0.9rem;
      font-weight: 500;
      color: #000;
    }
  }
  /* scrollbar 디자인 */
  .scrollBar::-webkit-scrollbar {
    width: 3px;
    height: 6px !important;
  }
  /* Track */
  .scrollBar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  .scrollBar::-webkit-scrollbar-thumb {
    background: #6490e7;
  }
  /* Handle on hover */
  .scrollBar::-webkit-scrollbar-thumb:hover {
    background: #6490e7;
  }

  .content_container {
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
    background: #fff;
    width: 42vw;
    padding: 4%;

    .title_box {
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 700;
      }
    }

    /* css확인필요 */
    .menuContent {
      border-bottom: 1px solid #dfdfdf;
      margin-right: 5%;
      margin-left: 3%;
      .menu {
        display: flex;
        align-items: center;
        padding: 2%;
        width: 100%;

        .box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4vw;
          height: 7vh;
          margin-right: 16px;
          border-radius: 4px;

          .box_image {
            width: 4vw;
            height: 7vh;
            border-radius: 5%;
          }
        }

        .title,
        .count {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          h3 {
            font-size: 1rem;
            font-weight: 700;
          }
          p {
            font-size: 0.8rem;
            font-weight: 400;
            color: #666;
          }
        }
      }

      .sold_out {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 4vw;
        height: 4vh;
        border-radius: 5%;
        border: 1px solid #dd1212;
        margin-left: auto;
        cursor: pointer;
        transition: 0.3s;

        h2 {
          padding: 0;
          font-size: 0.7rem;
          font-weight: 500;
          color: #dd1212;
        }
      }
      .sold_out:hover {
        border: none;
        background: #dd1212;
        h2 {
          color: #fff;
        }
      }

      .stock {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 4vw;
        height: 4vh;
        border-radius: 5px;
        background: #e8effc;
        cursor: pointer;
        transition: 0.3s;
        margin-left: auto;

        h2 {
          padding: 0;
          font-size: 0.9rem;
          font-weight: 500;
          color: #6490e7;
        }
      }
      .menuButton {
        margin-left: auto;
      }

      .stock:hover {
        background: #6490e7;
        h2 {
          color: #fff;
        }
      }
    }

    .page_nation {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 7vh;
    }
  }

  .secondLine {
    margin-left: 4%;
    border-radius: 4px;
    height: 75vh;
  }

  /* optionGroup */
  .optionGroup {
    margin-left: 40px;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    background: rgb(0, 30, 98);
    color: #fff;
    outline: none;
    font-weight: 700;
  }
  .optionContent {
    padding: 5%;
    .title {
      padding-bottom: 20px;
      border-bottom: 1px solid #dfdfdf;

      h2 {
        font-size: 1.1rem;
        font-weight: 700;
      }
    }
  }
  .option_list {
    margin-top: 30px;
  }
  .oList {
    width: 100%;
    max-width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h3 {
      font-size: 20px;
    }
    p {
      margin-left: 15px;
      font-size: 15px;
    }
    button {
      margin-left: auto;
      padding: 10px 25px;
      min-width: 150px;
      background: #e8effc;
      color: #6490e7;
      font-size: 15px;
      font-weight: 500;
      border: none;
      border-radius: 4px;
      outline: none;
    }
  }

  /* 재고관리 */
  table {
    width: 100%;
  }
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th,
  td {
    padding: 6px 8px;
  }

  thead tr {
    background: #6490e7;
    th {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      line-height: 26px;
    }
  }
  .stock_content {
    width: 100%;

    input {
      padding: 0 5px;
      max-width: 77px;
      border: none;
      border-bottom: 1px solid #000;
      outline: none;
    }

    td:nth-child(1) {
      width: 21%;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    td:nth-child(2) {
      width: 10%;
      text-align: center;
    }
    td:nth-child(3) {
      width: 10%;
      text-align: center;
      font-weight: 700;
    }
    td:nth-child(4) {
      width: 12%;
    }
    td:nth-child(5) {
      width: 23%;
    }
    td:nth-child(6) {
      width: 12%;
    }
    td:nth-child(7) {
      width: 12%;
    }

    .css-qbdosj-Input input {
      background: #fff !important;
      color: #fff !important;
      z-index: -99999;
    }
  }

  /* 품절버튼 */
  .stock {
    cursor: pointer;
    text-align: center;

    h2 {
      padding: 6px 4px;
      background: #e8effc;
      font-size: 14px;
      font-weight: 500;
      color: #6490e7;
      border: 1px solid #6490e7;
      border-radius: 5px;
    }
    h2:hover {
      background: #6490e7;
      color: #fff;
    }
  }

  .sold_out h2 {
    padding: 6px 4px;
    border-radius: 6px;
    border: 1px solid #dd1212;
    font-size: 14px;
    font-weight: 500;
    color: #dd1212;
    text-align: center;
    cursor: pointer;
  }
  .sold_out h2:hover {
    border: none;
    background: #dd1212;
    color: #fff;
  }

  /* 전체 저장하기 버튼 */
  .stock_submit {
    width: 100%;
    position: sticky;
    bottom: 1%;
    margin-right: auto;
    margin-top: 20px;
    padding: 15px 20px;
    background: #6490e7;
    border-radius: 4px;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 600;
  }

  /* 앱미리보기/간편도움말 공통css */
  .side_help_content {
    position: absolute;
    left: 121%;
    top: 0;
  }
  .app_guide_modal_container,
  .app_modal_container {
    padding: 13px 13px 40px 13px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: #fbfbfb;
    border: 1px solid #dfdfe3;
    border-radius: 8px;

    .arrow_box img {
      width: 5px;
    }
    .text_box {
      margin-left: 5px;
      min-width: 220px;

      .title_box {
        height: auto;
        margin-bottom: 9px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 700;
          color: #617be3;
        }
      }
      .detail_box_line {
        p {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
        }
      }
      .help_content_img {
        width: 18px;
      }
    }
  }
`;

const Mstyle = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
    color: #000;
  }

  .stockPage {
    background-color: #f1f1f1;
    padding-bottom: 5%;
  }

  .tapPage {
    background-color: #f1f3f7;
    padding-bottom: 8px;
  }

  .menu_container {
    background: #fff;

    .tab {
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 4px;
      overflow-x: auto;
      white-space: nowrap;
    }

    .scroll_menu {
      display: inline-block;
      text-align: center;
      text-decoration: none;
      margin: 5%;
      cursor: pointer;
      *display: inline;
      *zoom: 1;
      vertical-align: top;
      font-size: 0.9rem;
      font-weight: 400;
      color: #666;
    }

    .scroll_menu_active {
      border-bottom: 1px solid #000;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      margin: 5%;
      cursor: pointer;
      *display: inline;
      *zoom: 1;
      vertical-align: top;
      font-size: 0.9rem;
      font-weight: 500;
      color: #000;
    }
  }

  /* scrollbar 디자인 */
  .scrollBar::-webkit-scrollbar {
    width: 3px;
    height: 6px !important;
  }
  /* Track */
  .scrollBar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  .scrollBar::-webkit-scrollbar-thumb {
    background: #6490e7;
  }
  /* Handle on hover */
  .scrollBar::-webkit-scrollbar-thumb:hover {
    background: #6490e7;
  }
  /* 재고관리 사용법 */
  .stock_manual {
    margin-top: 5px;
    margin-bottom: 20px;
    background: #e5d1ff;
    border: 2px solid #8777e9;
    text-align: center;
    padding: 13px 0;
    border-radius: 8px;

    h2 {
      font-size: 14.5px;
      font-weight: 600;
      color: #30006d;
    }
  }

  /* 재고관리 */
  .title_box h2 {
    font-size: 18px;
    font-weight: 700;
  }
  .totalPage {
    width: 100%;
    padding: 4vw;
    background-color: #fff;
  }
  .mob_stock_wrap {
    h2 {
      font-size: 16px;
      font-weight: 600;
    }
    h3 {
      font-size: 14px;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;

    button {
      margin-left: auto;
      border: none;
      border-radius: 4px;
      outline: none;

      p {
        padding: 5px 15px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
      }
    }
    .stock {
      background: #fff;
      border: 1px solid #6490e7;

      p {
        color: #6490e7;
      }
    }
    .sold_out {
      background: #fff;
      border: 1px solid #dd1212;

      p {
        color: #dd1212 !important;
      }
    }
  }
  .stock_content {
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    background: #e8effc;
  }
  .stock_checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h2 {
      margin-left: 6px;
    }
  }
  .default_stock,
  .stock_time,
  .in_stock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;

    input {
      min-width: 50%;
      max-width: 50%;
      padding: 4px 8px;
      border: none;
      border-bottom: 1px solid #ddd;
      border-radius: 4px;
    }
  }
  .stock_time {
    h3 {
      flex: 0 0 50%;
    }
    .css-b62m3t-container {
      flex: 0 0 50%;
    }
    .css-qbdosj-Input input {
      background: #fff !important;
      color: #fff !important;
      z-index: -99999;
    }
  }

  /* 전체 저장하기 버튼 */
  .stock_submit {
    width: 100%;
    position: sticky;
    bottom: 1%;
    margin-right: auto;
    margin-top: 20px;
    padding: 15px 20px;
    background: #6490e7;
    border-radius: 4px;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 600;
    outline: none;
  }

  /* .totalPage {
      width: 100%;
      background-color: #fff;

      .title_box {
         display: flex;
         align-items: center;
         height: 8vh;

         h2 {
            margin-left: 5%;
            font-size: 1.1rem;
            font-weight: 700;
         }
      }
      .menuContent {
         border-bottom: 1px solid #DFDFDF;
         margin-right: 5%;
         margin-left: 3%;

      .menu {
         display: flex;
         align-items: center;
         padding: 3%;

         .box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 11vw;
            height: 5vh;
            margin-right: 16px;
            border-radius: 4px;
            border : 1px solid #dfdfdf;

            .box_image {
               border-radius: 5%;
               width: 11vw;
               height: 5vh;
            }
         }

         .title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;

            h3 {
               font-size: 0.8rem;
               font-weight: 700;
            }
            p {
               font-size: 0.8rem;
               font-weight: 400;
               color: #666;
            }
         }   
      }
   }

   .menu:nth-of-type(1) {
      border-top: 1px solid #DFDFDF;
   }

   .sold_out {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 16vw;
      height: 4vh;
      border-radius: 5%;
      border: 1px solid #DD1212;
      margin-left: auto;
      cursor: pointer;
      transition: 0.3s;

      h2 {
            padding: 0;
            font-size: 0.7rem;
            font-weight: 700;
            color: #DD1212;
      }
   }
   

      .stock {
         display: flex;
         justify-content: center;
         align-items: center;
         text-align: center;
         width: 16vw;
         height: 4vh;
         border-radius: 5%;
         background: #617be3;
         margin-left: auto;
         cursor: pointer;
         transition: 0.3s;

         h2 {
            padding: 0;
            font-size: 0.7rem;
            font-weight: 700;
            color: #fff;
         }
      }
   } */

  /* optionGroup */
  .optionGroup {
    margin: 0 15px;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    background: rgb(0, 30, 98);
    color: #fff;
    outline: none;
    font-weight: 700;
  }
  .optionContent {
    width: 100%;
    padding: 3%;
    background: #fff;

    .title h2 {
      font-size: 17px;
    }
  }
  .optionSelect {
    width: 100%;

    .oList {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #dfdfdf;

      h3 {
        font-size: 18px;
        font-weight: 700;
      }
      p {
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #666;
      }
      .menuButton {
        margin-left: auto;

        .sold_out {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 16vw;
          height: 4vh;
          border-radius: 5%;
          border: 1px solid #dd1212;
          margin-left: auto;
          cursor: pointer;
          transition: 0.3s;

          h2 {
            padding: 0;
            font-size: 0.7rem;
            font-weight: 700;
            color: #dd1212;
          }
        }
        .stock {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 16vw;
          height: 4vh;
          border-radius: 5%;
          background: #617be3;
          margin-left: auto;
          cursor: pointer;
          transition: 0.3s;

          h2 {
            padding: 0;
            font-size: 0.7rem;
            font-weight: 700;
            color: #fff;
          }
        }
      }
    }
  }
`;
