import React, { useState, useRef, useEffect, Fragment } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { Checkbox, Select } from "antd";
import "antd/dist/antd.css";

import manual from "../../../../assets/svg/manual.svg";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
const { Option } = Select;

const SettingForm = ({
  fnInsertForm,
  iData,
  iRegister,
  iCafeOptions,
  iPlainOptions,
  iShopOptions,
}) => {
  const [isType, setIsType] = useState("");

  const [plainOptions, setPlainOptions] = useState([]);
  const [cafeOptions, setCafeOptions] = useState([]);
  const [shopOptions, setShopOptions] = useState([]);

  const [mainType, setMainType] = useState([]);
  const [subType, setSubType] = useState([]);
  const [isMain, setIsMain] = useState("");
  const [isSub, setIsSub] = useState("");
  const [storeDetail, setStoreDetail] = useState("");
  const [storeNotice, setStoreNotice] = useState("");
  const [storeOrigin, setStoreOrigin] = useState("");

  const [classStoreMainType, setClassClassStoreMainType] = useState(null);
  const [classStoreDetail, setClassStoreDetail] = useState(null);
  const [classStoreNotice, setClassStoreNotice] = useState(null);
  const [classStoreOrigin, setClassStoreOrigin] = useState(null);
  const [errClassDetail, setErrClassDetail] = useState("");
  const [errClassNotice, setErrClassNotice] = useState("");
  const [errClassMainType, setErrClassMainType] = useState("");

  const storeDetailInput = useRef();

  const setChangeMain = (event) => {
    if (isSub !== "") {
      if (
        (isMobile ? event.toString() : event.target.value.toString()) ===
        isSub.toString()
      ) {
        setIsSub("4");
      }
    }
    setIsMain(isMobile ? event.toString() : event.target.value);
    if (classStoreDetail !== "input_wrong") {
      setClassStoreDetail(null);
    }
    if (classStoreNotice !== "input_wrong") {
      setClassStoreNotice(null);
    }
    if (classStoreOrigin !== "input_wrong") {
      setClassStoreOrigin(null);
    }
  };
  const setChangeSub = (event) => {
    if (isMobile) {
      setIsSub(event);
    } else setIsSub(event.target.value);
    if (
      (isMobile && event.toString() === "4") ||
      event?.target?.value?.toString() === "4"
    ) {
      if (classStoreDetail !== "input_wrong") {
        setClassStoreDetail("input_action");
      }
      setClassStoreNotice(null);
      setClassStoreOrigin(null);
      storeDetailInput.current.focus();
    } else {
      if (classStoreDetail !== "input_wrong") {
        setClassStoreDetail(null);
      }
      if (classStoreNotice !== "input_wrong") {
        setClassStoreNotice(null);
      }
      if (classStoreOrigin !== "input_wrong") {
        setClassStoreOrigin(null);
      }
    }
  };

  const onChange1 = (e) => {
    setMainType(e);
  };

  const onChange2 = (e) => {
    setSubType(e);
    if (classStoreDetail !== "input_wrong") {
      setClassStoreDetail("input_action");
    }
    if (classStoreNotice !== "input_wrong") {
      setClassStoreNotice(null);
    }
    if (classStoreOrigin !== "input_wrong") {
      setClassStoreOrigin(null);
    }
    storeDetailInput.current.focus();
  };

  const inputClassStoreDetail = () => {
    if (classStoreDetail !== "input_wrong") {
      setClassStoreDetail("input_action");
    }
    setClassStoreNotice(null);
    setClassStoreOrigin(null);
  };
  const inputClassStoreNotice = () => {
    if (classStoreNotice !== "input_wrong") {
      setClassStoreNotice("input_action");
    }
    setClassStoreDetail(null);
    setClassStoreOrigin(null);
  };
  const inputClassStoreOrigin = () => {
    if (classStoreOrigin !== "input_wrong") {
      setClassStoreOrigin("input_action");
    }
    setClassStoreNotice(null);
    setClassStoreDetail(null);
  };

  const onChangeStoreDetail = (event) => {
    setClassStoreDetail("input_action");
    setErrClassDetail("");
    setStoreDetail(event.target.value);
  };

  const onChangeStoreNotice = (event) => {
    setClassStoreNotice("input_action");
    setErrClassNotice("");
    setStoreNotice(event.target.value);
  };
  const onChangeStoreOrigin = (event) => {
    setClassStoreOrigin("input_action");
    setStoreOrigin(event.target.value);
  };

  const insertForm = async () => {
    let temp = false;
    if (mainType.length < 1) {
      setClassClassStoreMainType("input_wrong");
      setErrClassMainType("주업종을 선택해주세요.");
      temp = true;
    }

    if (storeDetail === "") {
      setClassStoreDetail("input_wrong");
      setErrClassDetail("매장 소개글은 필수입니다.");
      temp = true;
    }

    if (!temp) {
      const oData = {
        mainType,
        subType,
        isSub,
        storeDetail,
        storeNotice,
        storeOrigin,
      };
      if (fnInsertForm !== undefined && typeof fnInsertForm === "function") {
        await fnInsertForm(oData);
      }
    }
  };

  const asyncData = async () => {
    // if (isMobile) {
    //     for await (const s of iPlainOptions) {
    //         if (s.toString() === iData.mainType.toString()) {
    //             setIsMain("1");
    //         }
    //         if (s.toString() === iData.subType.toString()) {
    //             setIsSub("1");
    //         }
    //     }
    //     for await (const i of iCafeOptions) {
    //         if (i.toString() === iData.mainType.toString()) {
    //             setIsMain("2");
    //         }
    //         if (i.toString() === iData.subType.toString()) {
    //             setIsSub("2");
    //         }
    //     }
    //     for await (const x of iShopOptions) {
    //         if (x.toString() === iData.mainType.toString()) {
    //             setIsMain("3");
    //         }
    //         if (x.toString() === iData.subType.toString()) {
    //             setIsSub("3");
    //         }
    //     }
    // } else {
    for await (const s of iPlainOptions) {
      if (iData.mainType !== undefined && iData.mainType !== null) {
        if (iData.mainType.length > 0) {
          if (s.toString() === iData.mainType[0].toString()) {
            setIsMain("1");
          }
        } else {
          if (s.toString() === iData.mainType.toString()) {
            setIsMain("1");
          }
        }
      }
      if (iData.subType !== undefined && iData.subType !== null) {
        if (iData.subType.length > 0) {
          if (s.toString() === iData.subType[0].toString()) {
            setIsSub("1");
          }
        } else {
          if (s.toString() === iData.subType.toString()) {
            setIsSub("1");
          }
        }
      }
    }
    for await (const i of iCafeOptions) {
      if (iData.mainType !== undefined && iData.mainType !== null) {
        if (iData.mainType.length > 0) {
          if (i.toString() === iData.mainType[0].toString()) {
            setIsMain("2");
          }
        } else {
          if (i.toString() === iData.mainType.toString()) {
            setIsMain("2");
          }
        }
      }
      if (iData.subType !== undefined && iData.subType !== null) {
        if (iData.subType.length > 0) {
          if (i.toString() === iData.subType[0].toString()) {
            setIsSub("2");
          }
        } else {
          if (i.toString() === iData.subType.toString()) {
            setIsSub("2");
          }
        }
      }
    }
    for await (const x of iShopOptions) {
      if (iData.mainType !== undefined && iData.mainType !== null) {
        if (iData.mainType.length > 0) {
          if (x.toString() === iData.mainType[0].toString()) {
            setIsMain("3");
          }
        } else {
          if (x.toString() === iData.mainType.toString()) {
            setIsMain("3");
          }
        }
      }
      if (iData.subType !== undefined && iData.subType !== null) {
        if (iData.subType.length > 0) {
          if (x.toString() === iData.subType[0].toString()) {
            setIsSub("3");
          }
        } else {
          if (x.toString() === iData.subType.toString()) {
            setIsSub("3");
          }
        }
      }
    }
    // }

    setMainType(iData.mainType);
    setSubType(iData.subType);
    setStoreDetail(iData.detail);
    setStoreNotice(iData.noti);
    setStoreOrigin(iData.origin);
  };
  useEffect(() => {
    console.log(isMain);
  }, [isMain]);
  useEffect(() => {
    setPlainOptions(iPlainOptions);
    setCafeOptions(iCafeOptions);
    setShopOptions(iShopOptions);
    if (
      iRegister !== undefined &&
      iRegister !== null &&
      iRegister &&
      isType !== "edit"
    ) {
      asyncData();
    }
  }, [iData]);

  return (
    <Fragment>
      <Style>
        <BrowserView>
          <div className="container">
            <div className="title_box">
              <h2>매장설정</h2>
              <div className="tip">
                <SVG src={manual} width="18px" height="18px" />
                <span>
                  <strong>매장정보</strong>를 설정 및<br />
                  확인이 가능합니다.
                </span>
              </div>
            </div>
            <div className="category_title">
              <p style={{ marginTop: "2%" }}>
                주업종<span className="essential_form">(필수)</span>
              </p>
              <select
                value={isMain}
                onChange={setChangeMain}
                className={classStoreMainType}
              >
                <option value="" disabled>
                  매장의 주업종을 선택해주세요
                </option>
                <option value="1">음식점</option>
                <option value="2">카페</option>
                <option value="3">샵</option>
              </select>
              {isMain !== "" && (
                <>
                  <div
                    className="location_box_line"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="how_box">
                      <p>
                        해당하는 업종에 따라 한개 또는 여러개 선택이 가능합니다
                      </p>
                    </div>
                  </div>
                  <div
                    className="selectItemWrapper"
                    style={{
                      border:
                        classStoreMainType === "input_wrong" &&
                        "1px solid #DD1212",
                    }}
                  >
                    <div className="selectItem">
                      {isMain === "1" && (
                        <Checkbox.Group
                          options={plainOptions}
                          onChange={onChange1}
                          value={mainType}
                        />
                      )}
                      {isMain === "2" && (
                        <Checkbox.Group
                          options={cafeOptions}
                          onChange={onChange1}
                          value={mainType}
                        />
                      )}
                      {isMain === "3" && (
                        <Checkbox.Group
                          options={shopOptions}
                          onChange={onChange1}
                          value={mainType}
                        />
                      )}
                    </div>
                  </div>
                  <p
                    style={{
                      margin: "0",
                      color: "#DD1212",
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                  >
                    {errClassMainType}
                  </p>
                </>
              )}
              {isMain !== "" && (
                <>
                  <p style={{ marginTop: "2%" }}>
                    부업종<span>(선택)</span>
                  </p>
                  <select value={isSub} onChange={setChangeSub}>
                    <option value="" disabled>
                      부업종을 선택하세요
                    </option>
                    {isMain !== "1" && <option value="1">음식점</option>}
                    {isMain !== "2" && <option value="2">카페</option>}
                    {isMain !== "3" && <option value="3">샵</option>}
                    {isMain !== "4" && <option value="4">없음</option>}
                  </select>
                  {isSub !== "" && isSub !== "4" && (
                    <div className="selectItemWrapper">
                      <div className="selectItem">
                        {isSub === "1" && (
                          <Checkbox.Group
                            options={plainOptions}
                            onChange={onChange2}
                            value={subType}
                          />
                        )}
                        {isSub === "2" && (
                          <Checkbox.Group
                            options={cafeOptions}
                            onChange={onChange2}
                            value={subType}
                          />
                        )}
                        {isSub === "3" && (
                          <Checkbox.Group
                            options={shopOptions}
                            onChange={onChange2}
                            value={subType}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="category_title" style={{ marginTop: "20px" }}>
              <p>
                매장 소개글<span className="essential_form">(필수)</span>
              </p>
              <textarea
                ref={storeDetailInput}
                type="text"
                placeholder="가게만의 특색있는 소개글을 적어주세요! 고객에게 전달되는 매장의 이미지가 됩니다."
                className={classStoreDetail}
                onClick={inputClassStoreDetail}
                value={storeDetail}
                onChange={onChangeStoreDetail}
              />
              <p
                style={{
                  margin: "0",
                  color: "#DD1212",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
              >
                {errClassDetail}
              </p>
            </div>

            <div className="category_title" style={{ marginTop: "20px" }}>
              <p>
                매장 공지사항<span>(선택)</span>
              </p>
              <textarea
                type="text"
                placeholder="가격변동, 긴급 휴무일 등 고객에게 알리고 싶은 가게 상황을 적어주세요."
                className={classStoreNotice}
                onClick={inputClassStoreNotice}
                value={storeNotice}
                onChange={onChangeStoreNotice}
              />
              <p
                style={{
                  margin: "0",
                  color: "#DD1212",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
              >
                {errClassNotice}
              </p>
            </div>

            <div className="category_title" style={{ marginTop: "20px" }}>
              <p>
                원산지 표시사항<span>(선택)</span>
              </p>
              <textarea
                type="text"
                placeholder={`매장에서 사용하는 재료의 원산지를 자세하게 적어주세요\n원산지 표시사항 의무대상은 반드시 입력해주셔야합니다`}
                className={classStoreOrigin}
                onClick={inputClassStoreOrigin}
                value={storeOrigin}
                onChange={onChangeStoreOrigin}
              />
            </div>
            <div className="insert_box" onClick={insertForm}>
              <p>매장정보 등록하기</p>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className="category_title_line">
            <div className="category_title">
              <div className="title_box">
                <p className="title_line">매장정보</p>
              </div>
              <div className="tip">
                <SVG src={manual} width="18px" height="18px" />
                <span>
                  <strong>매장정보</strong>를 설정 및<br />
                  확인이 가능합니다.
                </span>
              </div>
            </div>
            <div className="inputText">
              <p>
                주업종<span className="essential_form">(필수)</span>
              </p>
              <Select
                value={isMain}
                onChange={setChangeMain}
                className="select_bank"
                bordered={false}
                style={{ marginTop: "3%" }}
              >
                <Option disabled value={""}>
                  주업종을 선택하세요
                </Option>
                <Option value={"1"}>음식점</Option>
                <Option value={"2"}>카페</Option>
                <Option value={"3"}>샵</Option>
              </Select>
              {isMain !== "" && (
                <>
                  <div
                    className="selectItemWrapper"
                    style={{
                      border:
                        classStoreMainType === "input_wrong" &&
                        "1px solid #DD1212",
                    }}
                  >
                    <div className="selectItem">
                      {isMain === "1" && (
                        <Checkbox.Group
                          options={plainOptions}
                          onChange={onChange1}
                          value={mainType}
                        />
                      )}
                      {isMain === "2" && (
                        <Checkbox.Group
                          options={cafeOptions}
                          onChange={onChange1}
                          value={mainType}
                        />
                      )}
                      {isMain === "3" && (
                        <Checkbox.Group
                          options={shopOptions}
                          onChange={onChange1}
                          value={mainType}
                        />
                      )}
                    </div>
                  </div>
                  <p
                    style={{
                      margin: "0",
                      color: "#DD1212",
                      fontSize: "10px",
                      fontWeight: "400",
                    }}
                  >
                    {errClassMainType}
                  </p>
                </>
              )}
              {isMain !== "" && (
                <>
                  <p style={{ marginTop: "5%" }}>
                    부업종<span>(선택)</span>
                  </p>
                  <Select
                    value={isSub}
                    onChange={setChangeSub}
                    className="select_bank"
                    bordered={false}
                    style={{ marginTop: "3%" }}
                  >
                    <Option disabled value={""}>
                      부업종을 선택하세요
                    </Option>
                    {isMain !== "1" && <Option value="1">음식점</Option>}
                    {isMain !== "2" && <Option value="2">카페</Option>}
                    {isMain !== "3" && <Option value="3">샵</Option>}
                    {isMain !== "4" && <Option value="4">없음</Option>}
                  </Select>
                  {isSub !== "" && isSub !== "4" && (
                    <div className="selectItemWrapper">
                      <div className="selectItem">
                        {isSub === "1" && (
                          <Checkbox.Group
                            options={plainOptions}
                            onChange={onChange2}
                            value={subType}
                          />
                        )}
                        {isSub === "2" && (
                          <Checkbox.Group
                            options={cafeOptions}
                            onChange={onChange2}
                            value={subType}
                          />
                        )}
                        {isSub === "3" && (
                          <Checkbox.Group
                            options={shopOptions}
                            onChange={onChange2}
                            value={subType}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="inputText">
              <p style={{ marginTop: "5%" }}>
                매장 소개글<span className="essential_form">(필수)</span>
              </p>
              <textarea
                ref={storeDetailInput}
                type="text"
                placeholder="매장 소개글을 입력해주세요."
                className={classStoreDetail}
                onClick={inputClassStoreDetail}
                value={storeDetail}
                onChange={onChangeStoreDetail}
              />
              <p
                style={{
                  margin: "0",
                  color: "#DD1212",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
              >
                {errClassDetail}
              </p>
            </div>

            <div className="inputText">
              <p style={{ marginTop: "5%" }}>
                매장 공지사항<span className="essential_form">(필수)</span>
              </p>
              <textarea
                type="text"
                placeholder="매장 공지사항를 입력해주세요."
                className={classStoreNotice}
                onClick={inputClassStoreNotice}
                value={storeNotice}
                onChange={onChangeStoreNotice}
              />
              <p
                style={{
                  margin: "0",
                  color: "#DD1212",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
              >
                {errClassNotice}
              </p>
            </div>

            <div className="inputText">
              <p style={{ marginTop: "5%" }}>
                원산지 표시사항<span>(선택)</span>
              </p>
              <textarea
                type="text"
                placeholder="원산지 표시사항를 입력해주세요."
                className={classStoreOrigin}
                onClick={inputClassStoreOrigin}
                value={storeOrigin}
                onChange={onChangeStoreOrigin}
              />
            </div>
            <div className="last">
              <button className="finishButoon" onClick={insertForm}>
                매장정보 등록하기
              </button>
            </div>
          </div>
        </MobileView>
      </Style>
    </Fragment>
  );
};

const Style = styled.div`
  label.ant-checkbox-wrapper {
    span {
      color: rgb(102, 102, 102) !important;
    }
  }
`;

export default SettingForm;
