

import React, { useEffect, useState } from 'react';
import { isMobile } from "react-device-detect";
import { Outlet, useLocation } from "react-router-dom";
import styled from 'styled-components';
import '../../assets/css/font.css';
import { message } from "antd";

import Header from './header/Header';
import Side from './side/Side';

import MobileHeader from './header/MobileHeader';
import MobileFooter from './footer/MobileFooter';

const Main = ({ oProps, iStoreUnReviewCount }) => {

   const [status, setStatus] = useState("dashboard");
   const [activeStatus, setActiveStatus] = useState(false);
   const [bManualPaused, set_bManualPaused] = useState(false);
   const [bLoaded, set_bLoaded] = useState(false);

   const alertMessage = (sContent) => {
      message.warning({
         content: sContent,
         className: 'custom-class',
         duration: '5',

         style: {
            fontSize: "0.9rem",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
         }
      });
   };

   const checkUp = async () => {
      const iStoreId = oProps.UserConfigReducer.StoreID;
      const oResponse = await oProps.oManager.fDoAxios("/store/getOperationStatusV2-" + iStoreId, "get", "login", null);
      if (oResponse !== undefined && oResponse !== null) {
         //setActiveStatus(oResponse === 1 ? false : true);
         if (oResponse.paused === 1) {
            setActiveStatus(false);
         } else if (oResponse.paused === 0) {
            setActiveStatus(true);
         }

         if (oResponse.is_manual_paused === 1) {
            setActiveStatus(false);
            set_bManualPaused(true);
         }
      }
      set_bLoaded(true);
   }

   const operateStore = async (fCallback) => {
      const sParam = oProps.UserConfigReducer.StoreID;
      const oResponse = await oProps.oManager.fDoAxios("/store/operationStore", "post", null, { sParam });
      if (oResponse !== undefined && oResponse !== null) {
         if (oResponse.resultCd !== "0000") {
            alertMessage(oResponse.resultMsg);
         } else {
            if (fCallback && typeof fCallback === 'function') {
               fCallback();
            }
         }
      }
   }

   const changeStoreCloseStatus = async (bToOpen) => {
      const oData = {
         store_id: oProps.UserConfigReducer.StoreID,
         to_open: bToOpen
      }
      //const oResponse = await sProps.oManager.fDoAxios("/store/changeStatus/v2", "post", "login", oData);
      const oResponse = await oProps.oManager.fDoAxios("/store/changeCloseStatus", "post", "login", oData);
      if (oResponse !== undefined && oResponse !== null) {
         if (oResponse.resultCd === "0000") {
            setActiveStatus(bToOpen);

            if (bToOpen === false) {
               set_bManualPaused(true);
            } else if (bToOpen === true) {
               set_bManualPaused(false);
            }
         } else {
            alertMessage(oResponse.resultMsg);
         }
      }
   }

   useEffect(() => {
      checkUp();
   }, []);

   if (bLoaded === false) {
      return (
         <div></div>
      )
   }

   if (isMobile) {
      return (
         <Style>
            <div className="admin_mobile" >
               <div className="mobile_container">
                  <MobileHeader activeStatus={activeStatus} fOperateStore={operateStore} fChangeStoreCloseStatus={changeStoreCloseStatus} bManualPaused={bManualPaused} oProps={oProps} iStoreUnReviewCount={iStoreUnReviewCount} />
                  <Outlet />
                  <MobileFooter oProps={oProps} />
               </div>
            </div>
         </Style>
      )
   } else {
      return (
         <Style>
            <Header activeStatus={activeStatus} fOperateStore={operateStore} fChangeStoreCloseStatus={changeStoreCloseStatus} bManualPaused={bManualPaused} sProps={oProps} />
            <div className="totalContent">
               <div className="left_side">
                  <Side iStatus={status} sProps={oProps} iStoreUnReviewCount={iStoreUnReviewCount} />
               </div>
               <div className="rightContent">
                  <Outlet />
               </div>
            </div>
         </Style>
      )
   }
}

export default Main;

const Style = styled.div`
   * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Noto Sans KR', sans-serif;
   }

   .mobile_container {
      width: 100%;
      margin: 0 auto;
   }

   .totalContent {
      display: flex;
      background: #F1F3F7;
   }

   .left_side {
      /* height: 88vh; */
      width: 257px;
      /* padding-bottom: 60px;
      overflow-y: scroll;
      overflow-x: hidden; */
      border-right: 1px solid #DFDFE3!important;
      background: #FBFBFB;
   }
   .rightContent {
      margin-top: 6vh;
      min-height: calc(100vh - 6vh);
   }

`






// <Header iActive={activeStatus} sProps={iProps} fnMoveCategory={(sIndex) => moveCategory(sIndex)} />
//                 <div className="totalContent">
//                     <div className="left_side">
//                         <Side iStatus={status} fnMoveCategory={(sIndex) => moveCategory(sIndex)} sProps={iProps} />
//                     </div>
//                     <div className="rightContent"></div>