import React, { useState, useEffect } from 'react';
import { pulse } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import storeIcon from '../../../../assets/svg/storeIcon.svg';
import manual from '../../../../assets/svg/guidenoticebox.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';

const styles = {
    pulse: {
        animation: 'infinite 1s',
        animationName: Radium.keyframes(pulse, 'pulse')
    }
}

const Store = ({ sData, iState, fnMove, mState, pState }) => {
    const [dataList, setDataList] = useState({});
    const [isState, setState] = useState(false);

    const moveCategory = async () => {
        if (fnMove !== undefined && typeof fnMove === "function") {
            await fnMove("store");
        }
    }

    useEffect(() => {
        setDataList(sData);
        setState(iState);
    }, [sData, iState]);

    return (
        <StyleRoot>
            <BrowserView>
                {isState ?
                    <div className="store">
                        <div className="title">
                            <h2>매장 정보</h2>
                        </div>
                        <div className="subTitle">
                            <h2>{dataList.storeNm}매장</h2>
                        </div>
                        <div className="salesBox">
                            <div className="top">
                                <div className="time">
                                    <h2>전화번호</h2>
                                    <p>{dataList.phoneNm}</p>
                                </div>
                                <div className="meter">
                                    <h2>근접알림</h2>
                                    <p>{dataList.notiAlarm}M</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="selection">
                                    <h2>업종</h2>
                                    <p>{dataList.type}</p>
                                </div>
                                <div className="parking">
                                    <h2>주차가능</h2>
                                    <p>{dataList.parkingTime}분</p>
                                </div>
                            </div>
                        </div>
                        <Link to={WebRoute.ADMIN_STORE_INFO}>
                            <button className="storeButton">매장관리 바로가기</button>
                        </Link>
                    </div>
                    :
                    <div className="sContent" style={mState ? styles.pulse : null}>
                        {mState &&
                            <div className="guide_notice_box">
                                <img src={manual} alt="none" />
                            </div>
                        }
                        <div style={{ height: "7vh" }}>
                            <h2>매장 정보를 알려주세요.</h2>
                            <h3 style={{ marginBottom: "5%" }}>매장의 기본 정보가 필요합니다.</h3>
                        </div>
                        <div className="img_box">
                            <img src={storeIcon} alt="none" />
                        </div>
                        <Link to={WebRoute.ADMIN_STORE_INFO}>
                            <div style={{ height: "5vh" }}>
                                <button >매장정보 등록</button>
                            </div>
                        </Link>
                    </div>
                }
            </BrowserView>
            <MobileView>
                {isState ?
                    <Style>
                        <div className="store">
                            <div className="title_wrapper">
                                <h2>매장 정보</h2>
                            </div>
                            <div className="subTitle">
                                <h2>{dataList.storeNm}매장</h2>
                            </div>
                            <div className="salesBox">
                                <div className="top">
                                    <div className="time">
                                        <h2>전화번호</h2>
                                        <p>{dataList.phoneNm}</p>
                                    </div>
                                    <div className="meter">
                                        <h2>근접알림</h2>
                                        <p>{dataList.notiAlarm}M</p>
                                    </div>
                                </div>
                                <div className="bottom">
                                    <div className="selection">
                                        <h2>업종</h2>
                                        <p>{dataList.type}</p>
                                    </div>
                                    <div className="parking">
                                        <h2>주차가능</h2>
                                        <p>{dataList.parkingTime}분</p>
                                    </div>
                                </div>
                            </div>
                            <Link to={WebRoute.ADMIN_STORE_INFO}>
                                <button className="storeButton">매장관리 바로가기</button>
                            </Link>
                        </div>
                    </Style>
                    :
                    <div className="content3">
                        <div className="sContent" style={mState ? styles.pulse : null}>
                            {mState &&
                                <div className="guide_notice_box" style={{ left: "-5%", top: "-15%" }}>
                                    <img src={manual} alt="none" />
                                </div>
                            }
                            <h2>매장 정보를 알려주세요.</h2>
                            <h3>매장의 기본 정보가 필요합니다.</h3>
                            <div className="img_box">
                                <img src={storeIcon} alt="none" />
                            </div>
                            <Link to={WebRoute.ADMIN_STORE_INFO}>
                                <button className="button">매장정보 등록</button>
                            </Link>
                        </div>
                    </div>
                }
            </MobileView>
        </StyleRoot>
    )
}

export default Store;


const Style = styled.div`

    .store {
        margin: 16px;
        padding: 20px 24px 20px 24px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
    }

    .title_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0 5px 0;

        h2 {
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            color: #617BE3;
        }
        
    }
    
    .subTitle { 
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0 5px 0;

        h2 {
            margin-right: 12px;
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 29px;
        }
        
    }
    .salesBox {
        padding: 16px 0;
        margin-bottom: 8px;
        border: 1px solid #DFDFDF;
        border-radius: 4px;

        h2 {
            font-size: 0.7rem;
            font-weight: 400;
            color: #666;
        }
        p {
            font-size: 0.7rem;
            font-weight: 700;
        }
        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 22px;

            .time {
                flex: 0 0 50%;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
            .meter {
                flex: 0 0 50%;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
        .bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .selection {
                flex: 0 0 50%;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
            .parking {
                flex: 0 0 50%;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }
    .storeButton {
        width: 100%;
        height: 48px;
        background-color: #001e62;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        border: none;
        outline: none;
        border-radius: 8px ;
    }
`;