import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import koKR from 'antd/lib/locale/ko_KR';

import { store, persistor } from './config/store';
import RootRoutes from "./navigation/Routes";

import WebManager from './components/webManager/WebManager';

if (process && process.env && process.env.REACT_APP_ENV === 'PRD') {
   console.log = () => null;
}

const rootElement = document.getElementById("root");

const Root = () => (
   <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
         <BrowserRouter>
            <ConfigProvider locale={koKR}>
               <WebManager >
                  <RootRoutes />
               </WebManager>
            </ConfigProvider>
         </BrowserRouter>
      </PersistGate>
   </Provider>
);

ReactDOM.render(<Root />, rootElement);