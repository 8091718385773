import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// 이미지
import tip01 from '../../../../assets/img/ceoscreen/tip01.png';
import tip02 from '../../../../assets/img/ceoscreen/tip02.png';
import tip03 from '../../../../assets/img/ceoscreen/tip03.png';
import tip04 from '../../../../assets/img/ceoscreen/tip04.png';
import tip05 from '../../../../assets/img/ceoscreen/tip05.png';

const SubCeoTip = (oProps, oParam) => {
    return (
        <ContentsStyle >
            <div className="ceoTipSubPage">
                <div className="container">
                    <div className="subContent">
                        <h1>스루 사장님이 꼭 <br />알아야 할 정보</h1>
                        <div className="content">
                            <Link to={{ pathname: '/ceotip', page_id: 'mob_stamp', search: 'mob_stamp' }}>
                                <div className="contentIndex">
                                    <div className="box"><img src={tip05} alt="스탬프 이미지" width="100%" /></div>
                                    <div className="contentText">
                                        <div className="icon">TIP</div>
                                        <p>스탬프를 이용하여 단골 고객을 만들어보세요</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'mob_coupon', search: 'mob_coupon' }}>
                                <div className="contentIndex">
                                    <div className="box"><img src={tip04} alt="할인쿠폰 이미지" width="100%" /></div>
                                    <div className="contentText">
                                        <div className="icon">TIP</div>
                                        <p>할인쿠폰으로 고객의 주문을 유도해 보세요</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'mob_sales', search: 'mob_sales' }}>
                                <div className="contentIndex">
                                    <div className="box"><img src={tip01} alt="정산주기 이미지" width="100%" /></div>
                                    <div className="contentText">
                                        <div className="icon" style={{ backgroundColor: "#FF9900" }}>FAQ</div>
                                        <p>스루의 정산 주기는 어떻게 되나요?</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'mob_only', search: 'mob_only' }}>
                                <div className="contentIndex">
                                    <div className="box"><img src={tip02} alt="스루온리 이미지" width="100%" /></div>
                                    <div className="contentText">
                                        <div className="icon">TIP</div>
                                        <p>스루 온리로 운전자 고객을 사로잡아보세요</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'mob_now', search: 'mob_now' }}>
                                <div className="contentIndex">
                                    <div className="box"><img src={tip03} alt="스루나우 이미지" width="100%" /></div>
                                    <div className="contentText">
                                        <div className="icon">TIP</div>
                                        <p>스루 나우로 여유시간에 추가 매출 올려보세요</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </ContentsStyle >
    )
}

export default SubCeoTip;

export const ContentsStyle = styled.div`
 * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .container {
        width: 100%;
        padding: 0 5vw;
        margin: 0 auto;
    }
    p, a, h1, h2, h3, h4, h5, h6, li, ul, button {
        font-family: 'NanumSquare';
        transform: skew(-0.1deg);
        font-weight: 900;
    }
    ul,li {
        list-style: none;
    }

    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 121px 0 48px 0;
        text-align: center;
    }
    .content {
        margin-bottom: 100px;
        .contentIndex {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 12px;
            padding: 16px;
            border: 1px solid #ECEEF2;
            border-radius: 4px;

            .box {
                max-width: 106px;
                width: 100%;
                height: 76px;
                margin-right: 12px;
            }
            .contentText {
                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 8px;
                    width: 39px;
                    height: 24px;
                    background-color: #1A7CFF;
                    color: #fff;
                    border-radius: 12px;
                    font-size: 12px;
                    font-weight: 900;
                }
                p {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22px;
                    color: #000;
                    word-break: keep-all;
                }
            }
        }
    }

`

