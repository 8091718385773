/*global kakao*/
import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const KakaoMap = ({ sUserLat, sUserLng, iViewPoint }) => {
   const navigate = useNavigate();

   const anglePoint = useRef(null);

   const mapScript = document.createElement("script");

   mapScript.async = true;
   mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_API_KEY}&autoload=false`;

   document.head.appendChild(mapScript);

   const setLatLng = async (set) => {
      if (window.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage(
            JSON.stringify({ latLng: set })
         );
      }
   }

   const setViewPointPan = async (set) => {
      if (window.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage(
            JSON.stringify({ viewpoint: set })
         );
      }
   }

   const onLoadMap = async () => {
      window.kakao.maps.load(async () => {
         function MapWalker(position) {

            let content = document.createElement('div');
            let figure = document.createElement('div');
            let angleBack = document.createElement('div');

            content.className = 'MapWalker';
            figure.className = 'figure';
            angleBack.className = 'angleBack';

            content.appendChild(angleBack);
            content.appendChild(figure);

            let walker = new window.kakao.maps.CustomOverlay({
               position: position,
               content: content,
               yAnchor: 1
            });

            this.walker = walker;
            this.content = content;
         }

         function toggleRoadview(position) {
            roadViewClient.getNearestPanoId(position, 50, function (panoId) {
               if (panoId === null) {
                  roadViewContainer.style.display = 'none';
                  //mapWrapper.style.width = '100%';
                  map.relayout();

               } else {
                  //mapWrapper.style.width = '50%';
                  map.relayout();
                  //roadViewContainer.style.display = 'block';
                  roadView.setPanoId(panoId, position);
                  roadView.setViewpoint({
                     pan: anglePoint.current.pan,
                     tilt: anglePoint.current.tilt,
                     zoom: anglePoint.current.zoom,
                  });
                  roadView.relayout();
               }
            });
         }

         MapWalker.prototype.setAngle = function (angle) {

            const threshold = 22.5;
            for (let i = 0; i < 16; i++) {
               if (angle > (threshold * i) && angle < (threshold * (i + 1))) {

                  const className = 'm' + i;
                  this.content.className = this.content.className.split(' ')[0];
                  this.content.className += (' ' + className);
                  break;
               }
            }
         };

         MapWalker.prototype.setPosition = function (position) {
            this.walker.setPosition(position);
         };

         MapWalker.prototype.setMap = function (map) {
            this.walker.setMap(map);
         };

         //const mapWrapper = document.getElementById('mapWrapper');
         const container = await document.getElementById("kakaomap");
         const mapCenter = await new window.kakao.maps.LatLng(parseFloat(sUserLat), parseFloat(sUserLng));
         const options = {
            center: mapCenter,
            level: 3
         };

         const map = await new window.kakao.maps.Map(container, options);
         await map.addOverlayMapTypeId(window.kakao.maps.MapTypeId.ROADVIEW);

         const roadViewContainer = document.getElementById('roadview');
         const roadView = new window.kakao.maps.Roadview(roadViewContainer);
         const roadViewClient = new window.kakao.maps.RoadviewClient();

         toggleRoadview(mapCenter);

         const markImage = new window.kakao.maps.MarkerImage(
            'https://t1.daumcdn.net/localimg/localimages/07/2018/pc/roadview_minimap_wk_2018.png',
            new window.kakao.maps.Size(26, 46),
            {
               spriteSize: new window.kakao.maps.Size(1666, 168),
               spriteOrigin: new window.kakao.maps.Point(705, 114),
               offset: new window.kakao.maps.Point(13, 46)
            }
         );
         const rvMarker = new window.kakao.maps.Marker({
            image: markImage,
            position: mapCenter,
            draggable: true,
            map: map
         });

         window.kakao.maps.event.addListener(rvMarker, 'dragend', function (mouseEvent) {
            var position = rvMarker.getPosition();
            toggleRoadview(position);
         });

         window.kakao.maps.event.addListener(map, 'click', function (mouseEvent) {
            var position = mouseEvent.latLng;

            rvMarker.setPosition(position);
            toggleRoadview(position);
         });

         let mapWalker = null;

         window.kakao.maps.event.addListener(roadView, 'init', async () => {
            mapWalker = new MapWalker(mapCenter);
            mapWalker.setMap(map);
            roadView.setViewpoint({
               pan: parseFloat(anglePoint.current.pan),
               tilt: parseFloat(anglePoint.current.tilt),
               zoom: parseInt(anglePoint.current.zoom),
            });

            window.kakao.maps.event.addListener(roadView, 'viewpoint_changed', async () => {

               let viewpoint = roadView.getViewpoint();
               mapWalker.setAngle(viewpoint);
               await setViewPointPan(viewpoint);
            });

            window.kakao.maps.event.addListener(roadView, 'position_changed', async () => {

               let position = roadView.getPosition();
               mapWalker.setPosition(position);
               map.setCenter(position);
               await setLatLng(position);

            });
         });
      });
   };

   mapScript.addEventListener("load", onLoadMap);

   useEffect(() => {
      if (sUserLat !== undefined && sUserLat !== null && sUserLng !== undefined && sUserLng !== null && iViewPoint !== undefined && iViewPoint !== null) {
         anglePoint.current = iViewPoint;
      }
   }, [sUserLat, sUserLng, iViewPoint]);

   return (
      <Style>
         <div className="mapWrapper_box">
            <div id="kakaomap" style={{ width: "100%", height: "100%" }}></div>
            <div className="croos_line" />
            <div id="roadview" style={{ width: "100%", height: "100%" }}></div>
         </div>
      </Style>
   )
}

export default KakaoMap;

const Style = styled.div`
    height:100vh;
    .map_wrap {
        border-radius: 5px;
    }
    .mapWrapper_box {
        height:50vh;
    }
    .croos_line {
        height: 1px;
        background: #dfdfdf;
        margin-top: 3%;
        margin-bottom: 3%;
    }
`;


// var roadviewContainer = document.getElementById('roadview'); //로드뷰를 표시할 div
// var roadview = new kakao.maps.Roadview(roadviewContainer); //로드뷰 객체
// var roadviewClient = new kakao.maps.RoadviewClient(); //좌표로부터 로드뷰 파노ID를 가져올 로드뷰 helper객체

// var position = new kakao.maps.LatLng(userLat, userLng);

// // 특정 위치의 좌표와 가까운 로드뷰의 panoId를 추출하여 로드뷰를 띄운다.
// roadviewClient.getNearestPanoId(position, 50, function(panoId) {
// roadview.setPanoId(panoId, position); //panoId와 중심좌표를 통해 로드뷰 실행
// });

// kakao.maps.event.addListener(roadview, 'init', function() {
// roadview.setViewpoint({
// pan: 86.3879278861259,
// tilt: 0,
// zoom: 10
// });
// const test = roadview.getViewpoint();
// console.log("test",test);
// });