import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { isMobile, osName } from "react-device-detect";
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { message, DatePicker, Checkbox } from 'antd';
import Loader from "react-loader-spinner";
import DaumPostcode from 'react-daum-postcode';
import moment from 'moment-timezone';
import '../../../../assets/fonts/font.css'
import "antd/dist/antd.css";
import 'moment/locale/ko';

import checkboxNoneSvg from '../../../../assets/svg/checkbox_none.svg';
import checkboxdownSvg from '../../../../assets/svg/arrow_down.svg';
import checkboxSvg from '../../../../assets/svg/checkbox.svg';
import chevronsRight from '../../../../assets/svg/chevronsRight.svg';
import truckSvg from '../../../../assets/svg/truck.svg';
import boxSvg from '../../../../assets/svg/box.svg';
import clipboardSvg from '../../../../assets/svg/clipboard.svg';
import checkCircleSvg from '../../../../assets/svg/check_circle.svg';

import closeButton from '../../../../assets/img/modal/closeBtn.png';
import uploadImage from '../../../../assets/img/uploadImage.png';
import removeButton from '../../../../assets/img/modal/removeButton.png';
import backBtn from '../../../../assets/img/modal/backBtn.png';
import couponBoxImg from '../../../../assets/img/ceoscreen/tip04.png';
import appBanner02 from '../../../../assets/img/guide/appBanner02.png';
import appBanner03 from '../../../../assets/img/guide/appBanner03.png';
import appBanner04 from '../../../../assets/img/guide/appBanner04.png';
import appBanner05 from '../../../../assets/img/guide/appBanner05.png';
import appBanner07 from '../../../../assets/img/ad_banner.png';
import appBanner08 from '../../../../assets/img/ad_poster.jpg';
import CafeBanner from '../../../../assets/img/cafe_banner.png'
import FoodBanner from '../../../../assets/img/food_banner.png'
import banner from '../../../../assets/img/ad_coupon.jpg';
import promotionItem from '../../../../assets/img/ad_holder.png';
import throoOnlyModalImg from '../../../../assets/img/modal/throoOnlyModal.png';
import FoodKit from '../../../../assets/img/food_kit.png'
const defaultFormat = "YYYY/MM/DD";
const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const ModalThrooProduct = ({ sProps, fnClose, iDataBox }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '10%',
                    left: '35%',
                    border: 'none',
                    width: "30vw",
                    heigth: "65vh",
                    bottom: "15vh",
                    background: '#fff',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialProductStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>{iDataBox.name} 배송 현황</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className='container_content'>
                        <div className='status_box'>
                            <div className='first_box'>
                                <div className='line_box' style={{ border: iDataBox.param.toString() !== "0" ? "1px solid #dfdfdf" : "2px solid #6490E7" }}>
                                    <SVG className="arrow_down" src={clipboardSvg} width="3vw" height="3vh" title="더보기버튼" color={iDataBox.param.toString() !== "0" ? "#dfdfdf" : "#6490E7"} />
                                </div>
                                <div className='title_box'>
                                    <p style={{ color: iDataBox.param.toString() !== "0" ? "#dfdfdf" : "#6490E7" }}>주문 확인중</p>
                                </div>
                                {iDataBox.createdAt !== "Invalid date" &&
                                    <div className='title_box'>
                                        <p style={{ color: iDataBox.param.toString() !== "0" ? "#dfdfdf" : "#6490E7", color: "#6B7583" }}>{iDataBox.createdAt}</p>
                                    </div>
                                }
                            </div>
                            <div className='last_box'>
                                <SVG className="arrow_down" src={chevronsRight} width="3vw" height="3vh" title="더보기버튼" color={iDataBox.param.toString() !== "0" ? "#dfdfdf" : "#6490E7"} />
                            </div>
                            <div className='first_box'>
                                <div className='line_box' style={{ border: iDataBox.param.toString() !== "1" ? "1px solid #dfdfdf" : "2px solid #6490E7" }}>
                                    <SVG className="arrow_down" src={checkCircleSvg} width="3vw" height="3vh" title="더보기버튼" color={iDataBox.param.toString() !== "1" ? "#dfdfdf" : "#6490E7"} />
                                </div>
                                <div className='title_box'>
                                    <p style={{ color: iDataBox.param.toString() !== "1" ? "#dfdfdf" : "#6490E7" }}>주문 확인</p>
                                </div>
                                {iDataBox.confirmAt !== "Invalid date" &&
                                    <div className='title_box'>
                                        <p style={{ color: iDataBox.param.toString() !== "1" ? "#dfdfdf" : "#6490E7", color: "#333D4B" }}>{iDataBox.confirmAt}</p>
                                    </div>
                                }
                            </div>
                            <div className='last_box'>
                                <SVG className="arrow_down" src={chevronsRight} width="3vw" height="3vh" title="더보기버튼" color={iDataBox.param.toString() !== "1" ? "#dfdfdf" : "#6490E7"} />
                            </div>
                            <div className='first_box'>
                                <div className='line_box' style={{ border: iDataBox.param.toString() !== "2" ? "1px solid #dfdfdf" : "2px solid #6490E7" }}>
                                    <SVG className="arrow_down" src={truckSvg} width="3vw" height="3vh" title="더보기버튼" color={iDataBox.param.toString() !== "2" ? "#dfdfdf" : "#6490E7"} />
                                </div>
                                <div className='title_box'>
                                    <p style={{ color: iDataBox.param.toString() !== "2" ? "#dfdfdf" : "#6490E7" }}>배송 중</p>
                                </div>
                                {iDataBox.deliverdAt !== "Invalid date" &&
                                    <div className='title_box'>
                                        <p style={{ color: iDataBox.param.toString() !== "2" ? "#dfdfdf" : "#6490E7", color: "#333D4B" }}>{iDataBox.deliverdAt}</p>
                                    </div>
                                }
                            </div>
                            <div className='last_box'>
                                <SVG className="arrow_down" src={chevronsRight} width="3vw" height="3vh" title="더보기버튼" color={iDataBox.param.toString() !== "2" ? "#dfdfdf" : "#6490E7"} />
                            </div>
                            <div className='first_box'>
                                <div className='line_box' style={{ border: iDataBox.param.toString() !== "3" ? "1px solid #dfdfdf" : "2px solid #6490E7" }}>
                                    <SVG className="arrow_down" src={boxSvg} width="3vw" height="3vh" title="더보기버튼" color={iDataBox.param.toString() !== "3" ? "#dfdfdf" : "#6490E7"} />
                                </div>
                                <div className='title_box'>
                                    <p style={{ color: iDataBox.param.toString() !== "3" ? "#dfdfdf" : "#6490E7" }}>배송 완료</p>
                                </div>
                                {iDataBox.completeAt !== "Invalid date" &&
                                    <div className='title_box'>
                                        <p style={{ color: iDataBox.param.toString() !== "3" ? "#dfdfdf" : "#6490E7", color: "#333D4B" }}>{iDataBox.completeAt}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {iDataBox.list.length > 0 &&
                            <>
                                {parseInt(iDataBox.param) >= 2 &&
                                    <dic className="delivery_status">
                                        {iDataBox.list.map((item, index) => {
                                            return (
                                                <div className='delivery_status_box' index={index}>
                                                    <div className='delivery_status_title'>
                                                        <SVG className="arrow_down" src={boxSvg} width="2vw" height="2vh" title="더보기버튼" color={"#000"} />
                                                        <p style={{ fontWeight: 500, fontSize: "0.9rem" }}>{item.title}</p>
                                                        <p style={{ fontWeight: 500, fontSize: "0.8rem", marginLeft: "5%", color: 0 === index ? "#6490E7" : "#dfdfdf" }}>{item.status}</p>
                                                        <p style={{ marginLeft: "5%", fontWeight: 400, fontSize: "0.7rem", paddingTop: "1px", color: "#666" }}>{item.date}</p>
                                                    </div>
                                                    <div className='delivery_status_detail'>
                                                        <SVG className="arrow_down" src={checkboxdownSvg} width="3vw" height="2vh" title="더보기버튼" />
                                                        <p style={{ fontWeight: 500, fontSize: "0.8rem", color: "#666" }}>{item.content}</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </dic>
                                }
                            </>
                        }
                        <div className='item_box'>
                            <div className='commercial_img_box'>
                                <img src={iDataBox.img} alt="닫기버튼" width="100%" />
                            </div>
                            <div className='commercial_detail_box'>
                                <div className="pay_date">
                                    <h2>{iDataBox.name}</h2>
                                </div>
                                <div className="pay_period">
                                    <p>{iDataBox.detail1}</p>
                                </div>
                                <div className="pay_period">
                                    <p>{iDataBox.detail2}</p>
                                </div>
                                <div className="pay_amount">
                                    <h3>{iDataBox.priceCasting}</h3>
                                </div>
                            </div>
                        </div>
                        <div className='deliver_box'>
                            <div className="deliver_company">
                                <h2>송장번호:</h2>
                                <p>{iDataBox.deliveryParam}</p>
                            </div>
                            <div className="deliver_company">
                                <h2>배송업체:</h2>
                                <p>{iDataBox.deliveryCompany}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </CommercialProductStyle>
        </Modal>
    )

}

export const ModalChargedComamercialPoint = ({ sProps, iPoint, iPointWon, iAmountWon, fnClose, fnChargedPoint }) => {
    const [chargeLoading, setChargeLoading] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(true);

    const [paymentAmount, setPaymentAmount] = useState(10000);
    const [paymentAmountWon, setPaymentAmountWon] = useState("10,000");
    const [sumAmountWon, setSumAmountWon] = useState(iAmountWon);

    const priceToString = async (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const chargedPointFirstStep = async (aIndex) => {
        let oResult = {
            orderId: null,
            key: "9999",
        };

        const oData = {
            store_id: sProps.UserConfigReducer.StoreID,
            osInfo: "os:" + osName + ",mobile:" + isMobile,
            iPrice: aIndex,
        }
        const oResponse = await sProps.oManager.fDoAxios("/store/commercial/chargedPoint/firstStep", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
                oResult.orderId = oResponse.orderId;
                oResult.key = "0000";
            }
        }
        return oResult;
    }

    const chargedPointLastStep = async (aIndex) => {
        const oData = {
            store_id: sProps.UserConfigReducer.StoreID,
            orderId: aIndex,
        }
        const oResponse = await sProps.oManager.fDoAxios("/store/commercial/chargedPoint/lastStep", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse.resultCd !== "0000") {
                setChargeLoading(false);
            } else {
                setChargeLoading(false);
                message.success("충전이 완료되었습니다.");
                if (typeof fnChargedPoint === "function" && fnChargedPoint !== undefined) {
                    fnChargedPoint();
                }
            }
        }
    }

    const chargedPoint = async () => {
        setChargeLoading(true);
        const result = await chargedPointFirstStep(paymentAmount);
        if (result.key === "0000") {
            const rel = window.open(`${process.env.REACT_APP_TPAY}${result.orderId}`, '_blank', "height=600,width=800,location=no,directories=no,resizable=no,status=no,toolbar=no,menubar=no");
            window.addEventListener('message', async (res) => {
                if (res.data !== undefined && res.data !== null) {
                    const jsonData = JSON.parse(res.data);
                    if (jsonData.resultCd === "3001") {
                        rel.close();
                        await chargedPointLastStep(result.orderId);
                    } else {
                        rel.close();
                        setChargeLoading(false);
                        message.error(jsonData.resultMsg);
                    }
                }
            })
        } else {
            setChargeLoading(false);
        }
    }

    const onChangePaymentAmount = async (sIndex, aIndex) => {
        let temp = parseInt(iPoint) + sIndex + (sIndex * 10 / 100);
        temp = await priceToString(temp);
        setPaymentAmount(sIndex);
        setPaymentAmountWon(aIndex);
        setSumAmountWon(temp);
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '20%',
                    left: '35%',
                    bottom: '20%',
                    border: 'none',
                    width: "25vw",
                    heigth: "20vh",
                    background: '#fff',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <ChargedPointStyle>
                {chargeLoading ?
                    <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                    </div>
                    :
                    <div className='container'>
                        <div className='title_box'>
                            <p>유상 포인트 충전</p>
                            <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                <img src={closeButton} alt="닫기버튼" width="35%" />
                            </div>
                            <div style={{ position: "absolute", top: "9vh", right: "10%", height: "4vh", width: "20vw", background: "#EFF0F6", borderRadius: "1vw", zIndex: 10, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                <p style={{ color: "#6490E7", fontSize: "0.8rem", fontWeight: 900 }}>포인트를 충전하시면 충전금액의 10%를 더 충전해드려요!</p>
                            </div>
                        </div>
                        <div className='content_box'>
                            <h2 >충전 금액</h2>
                            <div className="selectPrice">
                                <button className={paymentAmount.toString() === "10000" ? "selectPrice_un_act_button" : "selectPrice_button"} onClick={() => onChangePaymentAmount(10000, "10,000")}>1만원</button>
                                <button className={paymentAmount.toString() === "30000" ? "selectPrice_un_act_button" : "selectPrice_button"} onClick={() => onChangePaymentAmount(30000, "30,000")}>3만원</button>
                                <button className={paymentAmount.toString() === "50000" ? "selectPrice_un_act_button" : "selectPrice_button"} onClick={() => onChangePaymentAmount(50000, "50,000")}>5만원</button>
                                <button className={paymentAmount.toString() === "100000" ? "selectPrice_un_act_button" : "selectPrice_button"} onClick={() => onChangePaymentAmount(100000, "100,000")}>10만원</button>
                            </div>
                            <p>충전 전 유상포인트: <span style={{ color: "#4E5867" }}>{iPointWon} </span></p>
                            <p>충전 후 유상포인트: <span>{sumAmountWon} </span></p>
                            <p>충전금액: <span style={{ color: "#001E62" }}>{paymentAmountWon} </span></p>
                        </div>
                        <div className='button_box'>
                            <div className='submit_box' onClick={chargedPoint}>
                                <p>{paymentAmountWon}원 충전하기</p>
                            </div>
                        </div>
                    </div>
                }
            </ChargedPointStyle>
        </Modal>
    )
}

export const ModalKitComamercial = ({ sProps, fnClose, fnCompleteKit, dataBox }) => {
    const [modalIsOpen, setIsOpen] = useState(true);
    const [addressModal, setAddressModal] = useState(false);

    const [sAddress, setAddress] = useState("");
    const [sExtraAddress, setExtraAddress] = useState("");
    const [sPhoneNm, setPhoneNm] = useState("");
    const [sNm, setNm] = useState("");
    const [picketType, setPicketType] = useState(-1)

    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const onChangeNm = (event) => {
        setNm(event.target.value);
    }

    const onChangePhone = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setPhoneNm(value);
        }
    }

    const onChangeExtra = (event) => {
        setExtraAddress(event.target.value);
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const completeCoupon = () => {
        if (picketType < 0) return message.error("업종을 선택해주세요.")
        if (sAddress !== "" && sPhoneNm !== "" && sNm !== "") {
            let temp = {
                key: dataBox.key,
                name: dataBox.param,
                img: dataBox.img,
                title: dataBox.name,
                priceCasting: dataBox.priceCasting,
                price: dataBox.price,
                param: dataBox.param,
                store_id: sProps.UserConfigReducer.StoreID,
                fromDate: toDate,
                deliver_price: dataBox.deliver_price,
                exec_period: dataBox.exec_period,
                picketType,
                sAddress,
                sExtraAddress,
                sPhoneNm,
                sNm,
            }
            if (typeof fnCompleteKit === "function" && fnCompleteKit !== undefined) {
                fnCompleteKit(temp);
            }
        } else {
            message.error("물품을 받으실분의 주소와 전화번호 그리고 성명을 입력해주세요.");
        }
    }

    // 주소검색
    const handleComplete = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setAddress(fullAddress);
        setAddressModal(false);
    }
    const HandleSameInfo = () => {
        setAddress(sProps.UserConfigReducer.Address)
        setPhoneNm(sProps.UserConfigReducer.StorePhone)
        setNm(sProps.UserConfigReducer.StoreOwner)
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '15%',
                    border: 'none',
                    width: "30vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>쿠폰X거치대</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className='content_box'>
                        <h2>예시 이미지</h2>
                        <div className="screen d-block">
                            <p>
                                * 카페
                            </p>
                            <img src={banner} alt="프로모션 배너 이미지" width="90%" />
                        </div>
                        <div className="screen  d-block mt-3" style={{ marginBottom: "10%" }}>
                            <p>
                                * 음식점
                            </p>
                            <img src={FoodKit} alt="preview-img" style={{ width: "100%", height: "100%" }} />
                        </div>

                        <div className="eventBanner">
                            <div className="container">
                                <h3>
                                    스루를 이용하는 매장에 필요한<br />
                                    스루 홍보물을 보내드립니다.<br />
                                    매장을 방문하는 고객에게 스루 시작을 알려보세요!
                                </h3>
                                <img src={promotionItem} alt="스루키트 이미지" width="100%" />
                                <div className="notice"><p>*  쿠폰 200매, 아크릴 거치대 1개로 구성되어 있습니다.<br />(위 이미지는 예시 이미지로 실제와 다를 수 있습니다.)</p></div>
                                <div ><p className='text-primary'>*  배송비 별도 5,000원 추가되는 상품입니다.</p></div>
                            </div>
                        </div>
                        <div style={{ width: '95%', marginBottom: '20px' }} >
                            <h2 style={{ alignSelf: 'center', margin: 0 }}>업종</h2>
                            <div className='d-flex'>
                                <div onClick={() => setPicketType(0)} className='d-flex mr-4'>
                                    <div className='align-self-center mr-2'>
                                        카페
                                    </div>
                                    <input type="radio"
                                        style={{ width: '18px', height: '18px' }}
                                        checked={picketType === 0}
                                    />
                                </div>
                                <div onClick={() => setPicketType(1)} className='d-flex'>
                                    <div className='align-self-center mr-2'>
                                        음식점
                                    </div>
                                    <input type="radio"
                                        style={{ width: '18px', height: '18px' }}
                                        checked={picketType === 1}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '95%', marginBottom: '20px' }} className='d-flex justify-content-between'>
                            <h2 style={{ alignSelf: 'center', margin: 0 }}>수령인</h2>
                            <div>
                                <button type="button" class="btn btn-outline-secondary" onClick={HandleSameInfo}>
                                    매장정보와 동일
                                </button>
                            </div>
                        </div>
                        <input type="text" value={sNm} onChange={onChangeNm} style={{ marginBottom: "5%", border: "none" }} />
                        <h2>휴대폰 번호</h2>
                        <input type="text" value={sPhoneNm} onChange={onChangePhone} style={{ marginBottom: "5%", border: "none" }} />
                        <div className="address" style={{ marginBottom: "10%" }}>
                            <h2>주소</h2>
                            <div className="searchAddress">
                                <input disabled type="text" defaultValue={sAddress} style={{ border: "none" }} />
                                <button onClick={() => setAddressModal(true)}>검색</button>
                            </div>
                            <input type="text" value={sExtraAddress} onChange={onChangeExtra} style={{ border: "none" }} />
                        </div>
                    </div>
                    <div className='button_box'>
                        <div className='submit_box' onClick={completeCoupon}>
                            <p>장바구니에 담기</p>
                        </div>
                    </div>
                    {addressModal &&
                        <div style={{ position: "absolute", top: 0, left: 0, heigth: "30vh", width: "100%", background: "#fff" }}>
                            <div className='title_box' style={{ marginBottom: "2%", height: "8vh" }}>
                                <p>주소입력</p>
                                <div className='delete_box' onClick={() => setAddressModal(false)} style={{ position: "absolute", left: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                    <img src={backBtn} alt="닫기버튼" width="25%" height="40%" />
                                </div>
                            </div>
                            <DaumPostcode onComplete={handleComplete} />
                            <div style={{ height: "18vh" }} />
                        </div>
                    }
                </div>
            </CommercialCouponStyle>
        </Modal>
    )
}

export const ModalPicketComamercial = ({ sProps, fnClose, fnCompletePicket, dataBox }) => {
    const [modalIsOpen, setIsOpen] = useState(true);
    const [addressModal, setAddressModal] = useState(false);

    const [sAddress, setAddress] = useState("");
    const [sExtraAddress, setExtraAddress] = useState("");
    const [sPhoneNm, setPhoneNm] = useState("");
    const [sNm, setNm] = useState("");
    const [loading, setLoading] = useState(false);

    const [toDate, setToDate] = useState(moment().format(defaultFormat));
    const [picketType, setPicketType] = useState(-1)
    const [bannerImg, setBannerImg] = useState("");
    const onChangeNm = (event) => {
        setNm(event.target.value);
    }

    const onChangePhone = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setPhoneNm(value);
        }
    }

    const onChangeExtra = (event) => {
        setExtraAddress(event.target.value);
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const completeCoupon = () => {
        if (picketType < 0) return message.error("업종을 선택해주세요.")
        if (!bannerImg || bannerImg === "") return message.error("이미지를 업로드해주세요.")
        if (sAddress !== "" && sPhoneNm !== "" && sNm !== "") {
            let temp = {
                key: dataBox.key,
                name: dataBox.param,
                img: dataBox.img,
                title: dataBox.name,
                priceCasting: dataBox.priceCasting,
                price: dataBox.price,
                param: dataBox.param,
                fromDate: toDate,
                deliver_price: dataBox.deliver_price,
                exec_period: dataBox.exec_period,
                bannerImgUrl: bannerImg,
                picketType,
                store_id: sProps.UserConfigReducer.StoreID,
                sAddress,
                sExtraAddress,
                sPhoneNm,
                sNm,
            }
            if (typeof fnCompletePicket === "function" && fnCompletePicket !== undefined) {
                fnCompletePicket(temp);
            }
        } else {
            message.error("물품을 받으실분의 주소와 전화번호 그리고 성명을 입력해주세요.");
        }
    }

    // 주소검색
    const handleComplete = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setAddress(fullAddress);
        setAddressModal(false);
    }

    const removeImgae = () => {
        setBannerImg("");
    }

    const uploadLogoImage = async (e) => {
        let origin = e.target.files[0];
        if (origin !== undefined) {
            if (origin.type !== undefined && origin.type !== null) {
                if (origin.type === "image/png" || origin.type === "image/jpeg" || origin.type === "image/jpg") {
                    let tempImage = await getBase64(origin);
                    uploadAllFunc(tempImage, origin);
                }
            }
        }
    }

    const uploadAllFunc = async (nIndex, sIndex) => {
        setLoading(true);
        let isProcess = false;
        let uploadFile = {};

        if (sIndex !== "") {
            const upload = await imageProcess(sIndex);
            if (upload === "") {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                isProcess = true;
                uploadFile = upload;
            }

        }
        if (isProcess) {
            setBannerImg(uploadFile.url_path);
        }
        setLoading(false);
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};
        const formData = new FormData();
        formData.append('photo', fileList);

        const oResponse = await sProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
        if (oResponse !== undefined) {
            if (oResponse.file_name !== undefined) {
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }
    const HandleSameInfo = () => {
        setAddress(sProps.UserConfigReducer.Address)
        setPhoneNm(sProps.UserConfigReducer.StorePhone)
        setNm(sProps.UserConfigReducer.StoreOwner)
    }
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '15%',
                    border: 'none',
                    width: "30vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>실외 배너X포스터</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className='content_box'>
                        <h2>예시 이미지</h2>
                        <div className="screen d-block">
                            <p>
                                * 카페
                            </p>
                            <img src={CafeBanner} alt="preview-img" style={{ width: "90%", height: "100%" }} />
                        </div>
                        <div className="screen  d-block" style={{ marginBottom: "10%" }}>
                            <p>
                                * 음식점
                            </p>
                            <img src={FoodBanner} alt="preview-img" style={{ width: "90%", height: "100%" }} />
                        </div>
                        <div className="table_banner_items">
                            <h2>실외 배너X포스터</h2>
                            <div className="item mb-1">
                                <h3 style={{ marginBottom: 0 }}>구성:</h3>
                                <p>배너, 물통, 폴대4개, 메인파이프</p>
                            </div>
                            <div className="item mb-1">
                                <h3 style={{ marginBottom: 0 }}>재질:</h3>
                                <p>페트 210μ </p>
                            </div>
                            <div className="item mb-1">
                                <h3 style={{ marginBottom: 0 }}>사이즈:</h3>
                                <p>1800 x 600mm</p>
                            </div>
                            <div className="item">
                                <h3 style={{ marginBottom: 0 }}>가격:</h3>
                                <p>30,000원</p>
                            </div>
                        </div>
                        <div style={{ height: "5vh" }} />

                        <div style={{ width: '95%', marginBottom: '20px' }} >
                            <h2 style={{ alignSelf: 'center', margin: 0 }}>업종</h2>
                            <div className='d-flex'>
                                <div onClick={() => setPicketType(0)} className='d-flex mr-4'>
                                    <div className='align-self-center mr-2'>
                                        카페
                                    </div>
                                    <input type="radio"
                                        style={{ width: '18px', height: '18px' }}
                                        checked={picketType === 0}
                                    />
                                </div>
                                <div onClick={() => setPicketType(1)} className='d-flex'>
                                    <div className='align-self-center mr-2'>
                                        음식점
                                    </div>
                                    <input type="radio"
                                        style={{ width: '18px', height: '18px' }}
                                        checked={picketType === 1}
                                    />
                                </div>
                            </div>
                        </div>
                        <h2>이미지 <span>권장사이즈 (500*350mm)<br />해상도 300권장 (해상도가 낮을시 이미지가 깨져보일수 있습니다)</span></h2>
                        <div className="imageUpload" style={{ display: "flex", alignItems: "flex-start", margin: "20px 0 30px 0" }}>
                            <div className="preview" style={{ position: "relative" }}>
                                {bannerImg !== "" && <img src={bannerImg} alt="preview-img" style={{ marginRight: "16px", width: "100px", height: "72px", border: "1px solid #FAFAFC", borderRadius: "4px" }} />}
                                {bannerImg !== "" && <img className='resetBtn' onClick={removeImgae} src={removeButton} style={{ width: "20px", height: "20px", marginRight: "16px" }} />}
                            </div>
                            {bannerImg ? undefined : <label htmlFor="file-input">
                                <img src={uploadImage} style={{ width: "100px", height: "72px" }} />
                            </label>}
                            {bannerImg ? undefined : <input id="file-input" type="file" style={{ display: "none" }} onChange={(e) => uploadLogoImage(e)} />}
                        </div>
                        <div style={{ width: '95%', marginBottom: '20px' }} className='d-flex justify-content-between'>
                            <h2 style={{ alignSelf: 'center', margin: 0 }}>수령인</h2>
                            <div>
                                <button type="button" class="btn btn-outline-secondary" onClick={HandleSameInfo}>
                                    매장정보와 동일
                                </button>
                            </div>
                        </div>
                        <input type="text" value={sNm} onChange={onChangeNm} style={{ marginBottom: "5%", border: "none" }} />
                        <h2>휴대폰 번호</h2>
                        <input type="text" value={sPhoneNm} onChange={onChangePhone} style={{ marginBottom: "5%", border: "none" }} />
                        <div className="address" style={{ marginBottom: "10%" }}>
                            <h2>주소</h2>
                            <div className="searchAddress">
                                <input disabled type="text" defaultValue={sAddress} style={{ border: "none" }} />
                                <button onClick={() => setAddressModal(true)}>검색</button>
                            </div>
                            <input type="text" value={sExtraAddress} onChange={onChangeExtra} style={{ border: "none" }} />
                        </div>
                    </div>
                    <div className='button_box'>
                        <div className='submit_box' onClick={completeCoupon}>
                            <p>장바구니에 담기</p>
                        </div>
                    </div>
                    {addressModal &&
                        <div style={{ position: "absolute", top: 0, left: 0, heigth: "30vh", width: "100%", background: "#fff" }}>
                            <div className='title_box' style={{ marginBottom: "2%", height: "8vh" }}>
                                <p>주소입력</p>
                                <div className='delete_box' onClick={() => setAddressModal(false)} style={{ position: "absolute", left: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                                    <img src={backBtn} alt="닫기버튼" width="25%" height="40%" />
                                </div>
                            </div>
                            <DaumPostcode onComplete={handleComplete} />
                            <div style={{ height: "18vh" }} />
                        </div>
                    }
                </div>
            </CommercialCouponStyle>
        </Modal>
    )
}

export const ModalStoreComamercial = ({ sProps, fnClose, fnCompleteStore, dataBox }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }
    const completeCoupon = () => {

        console.log("in banner", dataBox)
        if (toDate !== "") {
            let temp = {
                key: dataBox.key,
                name: dataBox.param,
                img: dataBox.img,
                title: dataBox.name,
                priceCasting: dataBox.priceCasting,
                price: dataBox.price,
                param: dataBox.param,
                store_id: sProps.UserConfigReducer.StoreID,
                fromDate: toDate,
                deliver_price: dataBox.deliver_price,
                exec_period: dataBox.exec_period,
            }
            if (typeof fnCompleteStore === "function" && fnCompleteStore !== undefined) {
                fnCompleteStore(temp);
            }
        } else {
            message.error("날짜를 선택해주세요.");
        }
    }

    const onOperatingDate = (value, dateString) => {
        if (dateString !== undefined && dateString !== null && dateString !== "") {
            setToDate(dateString);
        } else {
            setToDate(moment().format(defaultFormat));
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '15%',
                    border: 'none',
                    width: "30vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>신규 입점 광고</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className='content_box'>
                        <h2>앱 적용 이미지</h2>
                        <div className="screen">
                            <img src={appBanner04} alt="preview-img" style={{ width: "90%", height: "100%" }} />
                        </div>

                        <div className="textInput">
                            <h2>광고 시작날짜 <span>(광고 사용기간은 시작일로부터 30일입니다)</span></h2>
                            <DatePicker
                                className="date_picker"
                                style={{ border: "none", width: "100%", right: "1.5%" }}
                                format={defaultFormat}
                                value={moment(toDate, defaultFormat)}
                                onChange={onOperatingDate}
                            />
                        </div>
                    </div>
                    <div className='button_box'>
                        <div className='submit_box' onClick={completeCoupon}>
                            <p>장바구니에 담기</p>
                        </div>
                    </div>
                </div>
            </CommercialCouponStyle>
        </Modal>
    )

}

export const ModalHotProductComamercial = ({ sProps, fnClose, fnCompleteHotProduct, iList, dataBox, fnMoveToPath }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const [productId, setProductId] = useState("");
    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const changeData = async (item) => {
        let temp = productId;
        if (productId.toString() === item.id.toString()) {
            setProductId("");
            temp = "";
        } else {
            setProductId(item.id);
            temp = item.id;
        }
    };

    const moveToPath = () => {
        if (typeof fnMoveToPath === "function" && fnMoveToPath !== undefined) {
            fnMoveToPath();
        }
    }

    const completeCoupon = () => {
        if (productId !== "" && toDate !== "") {
            let temp = {
                key: dataBox.key,
                name: dataBox.param,
                img: dataBox.img,
                title: dataBox.name,
                priceCasting: dataBox.priceCasting,
                price: dataBox.price,
                param: dataBox.param,
                productId,
                deliver_price: dataBox.deliver_price,
                exec_period: dataBox.exec_period,
                fromDate: toDate,
            }
            if (typeof fnCompleteHotProduct === "function" && fnCompleteHotProduct !== undefined) {
                fnCompleteHotProduct(temp);
            }
        } else {
            message.error("상품을 선택해주세요.");
        }
    }

    const onOperatingDate = (value, dateString) => {
        if (dateString !== undefined && dateString !== null && dateString !== "") {
            setToDate(dateString);
        } else {
            setToDate(moment().format(defaultFormat));
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '15%',
                    border: 'none',
                    width: "30vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>핫메뉴 광고</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    {iList.length > 0 ?
                        <>
                            <div className='content_box'>
                                <h2>앱 적용 이미지</h2>
                                <div className="screen">
                                    <img src={appBanner05} alt="preview-img" style={{ width: "90%", height: "100%" }} />
                                </div>

                                <div className="textInput">
                                    <h2>광고 시작날짜 <span>(광고 사용기간은 시작일로부터 30일입니다)</span></h2>
                                    <DatePicker
                                        className="date_picker"
                                        style={{ border: "none", width: "100%", right: "1.5%" }}
                                        format={defaultFormat}
                                        value={moment(toDate, defaultFormat)}
                                        onChange={onOperatingDate}
                                    />
                                </div>

                                <h2>핫메뉴 상품 선택</h2>
                                {iList.length > 0 ?
                                    <>
                                        <div className="commercial_banner_text">
                                            <p className="optionDivider">광고에 사용하실 상품을 선택해주세요</p>
                                        </div>
                                        {iList.map((item, index) => {
                                            return (
                                                <div className="product_item_box" key={index}>
                                                    <div className='wrapper_box' style={{ background: (item.id.toString() === productId.toString()) ? "#F3F7FF" : "#FAFAFB" }} onClick={() => changeData(item)}>
                                                        <div className="img_box">
                                                            <img src={item.imgPath} alt="preview-img" />
                                                        </div>
                                                        <div className="box">
                                                            <p style={{ color: "#000", fontWeight: 800, fontSize: "1rem", paddingTop: "3%" }}>{item.name}</p>
                                                            {(item.discount !== undefined && item.discount !== null && parseInt(item.discount) > 0) ?
                                                                <p style={{ fontWeight: "600", fontSize: "0.9rem", color: "#919AA7" }}>{item.orgPrice} <span style={{ fontWeight: "600", fontSize: "0.9rem", color: "#5991FF" }}>{item.discount}% 할인</span></p>
                                                                :
                                                                <p style={{ fontWeight: "600", fontSize: "0.9rem", color: "#919AA7" }}>{item.orgPrice}</p>
                                                            }
                                                        </div>
                                                        <div className="check_box">
                                                            {(item.id.toString() === productId.toString()) ?
                                                                <SVG className="arrow_down" src={checkboxSvg} width="20px" height="20px" title="더보기버튼" />
                                                                :
                                                                <SVG className="arrow_down" src={checkboxNoneSvg} width="20px" height="20px" title="더보기버튼" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div style={{ height: "5vh" }} />
                                    </>
                                    :
                                    <div className="none_commercial_box">
                                        <p>등록된 상품이 없습니다.</p>
                                    </div>
                                }

                            </div>
                            <div className='button_box'>
                                <div className='submit_box' onClick={completeCoupon}>
                                    <p>장바구니에 담기</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div style={{ width: "100%", height: "6vh", display: "flex", alignItems: "center", justifyContent: "center", background: "#F2F3F5", marginTop: "3vh", borderRadius: "10px" }}>
                                <p style={{ color: "#6B7583", fontSize: "0.9rem", fontWeight: 700, marginTop: "3%" }}>등록된 스루온리 상품이 없습니다.</p>
                            </div>
                            <div style={{ display: "flex", height: "40vh", width: "26vw", justifyContent: "center" }}>
                                <img src={throoOnlyModalImg} alt="닫기버튼" />
                            </div>
                            <div className='button_box'>
                                <div className='submit_box' onClick={moveToPath}>
                                    <p>스루온리 상품 추가하기</p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </CommercialCouponStyle>
        </Modal>
    )
}

export const ModalThrooOnlyComamercial = ({ sProps, fnClose, fnCompleteThrooOnly, iList, dataBox, fnMoveToPath }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const [productId, setProductId] = useState("");
    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const changeData = async (item) => {
        let temp = productId;
        if (productId.toString() === item.id.toString()) {
            setProductId("");
            temp = "";
        } else {
            setProductId(item.id);
            temp = item.id;
        }
    };

    const completeCoupon = () => {
        console.log("in banner", dataBox)
        if (productId !== "" && toDate !== "") {
            let temp = {
                key: dataBox.key,
                name: dataBox.param,
                img: dataBox.img,
                title: dataBox.name,
                priceCasting: dataBox.priceCasting,
                price: dataBox.price,
                param: dataBox.param,
                productId: productId,
                fromDate: toDate,
                deliver_price: dataBox.deliver_price,
                exec_period: dataBox.exec_period,
            }
            if (typeof fnCompleteThrooOnly === "function" && fnCompleteThrooOnly !== undefined) {
                fnCompleteThrooOnly(temp);
            }
        } else {
            message.error("상품을 선택해주세요.");
        }
    }

    const moveToPath = () => {
        if (typeof fnMoveToPath === "function" && fnMoveToPath !== undefined) {
            fnMoveToPath();
        }
    }

    const onOperatingDate = (value, dateString) => {
        if (dateString !== undefined && dateString !== null && dateString !== "") {
            setToDate(dateString);
        } else {
            setToDate(moment().format(defaultFormat));
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '15%',
                    border: 'none',
                    width: "30vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>스루 온리 광고</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    {iList.length > 0 ?
                        <>
                            <div className='content_box'>
                                <h2>앱 적용 이미지</h2>
                                <div className="screen">
                                    <img src={appBanner03} alt="preview-img" style={{ width: "90%", height: "100%" }} />
                                </div>

                                <div className="textInput">
                                    <h2>광고 시작날짜 <span>(광고 사용기간은 시작일로부터 30일입니다)</span></h2>
                                    <DatePicker
                                        className="date_picker"
                                        style={{ border: "none", width: "100%", right: "1.5%" }}
                                        format={defaultFormat}
                                        value={moment(toDate, defaultFormat)}
                                        onChange={onOperatingDate}
                                    />
                                </div>

                                <h2>스루온리 상품 선택</h2>
                                {iList.length > 0 ?
                                    <>
                                        <div className="commercial_banner_text">
                                            <p className="optionDivider">광고에 사용하실 상품을 선택해주세요</p>
                                        </div>
                                        {iList.map((item, index) => {
                                            return (
                                                <div className="product_item_box" key={index}>
                                                    <div className='wrapper_box' style={{ background: (item.id.toString() === productId.toString()) ? "#F3F7FF" : "#FAFAFB" }} onClick={() => changeData(item)}>
                                                        <div className="img_box">
                                                            <img src={item.imgPath} alt="preview-img" />
                                                        </div>
                                                        <div className="box">
                                                            <p style={{ color: "#000", fontWeight: 800, fontSize: "1rem", paddingTop: "3%" }}>{item.name}</p>
                                                            {(item.discount !== undefined && item.discount !== null && parseInt(item.discount) > 0) ?
                                                                <p style={{ fontWeight: "600", fontSize: "0.9rem", color: "#919AA7" }}>{item.orgPrice} <span style={{ fontWeight: "600", fontSize: "0.9rem", color: "#5991FF" }}>{item.discount}% 할인</span></p>
                                                                :
                                                                <p style={{ fontWeight: "600", fontSize: "0.9rem", color: "#919AA7" }}>{item.orgPrice}</p>
                                                            }
                                                        </div>
                                                        <div className="check_box">
                                                            {(item.id.toString() === productId.toString()) ?
                                                                <SVG className="arrow_down" src={checkboxSvg} width="20px" height="20px" title="더보기버튼" />
                                                                :
                                                                <SVG className="arrow_down" src={checkboxNoneSvg} width="20px" height="20px" title="더보기버튼" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div style={{ height: "5vh" }} />
                                    </>
                                    :
                                    <div className="none_commercial_box">
                                        <p>등록된 스루온리 상품이 없습니다.</p>
                                    </div>
                                }

                            </div>
                            <div className='button_box'>
                                <div className='submit_box' onClick={completeCoupon}>
                                    <p>장바구니에 담기</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div style={{ width: "100%", height: "6vh", display: "flex", alignItems: "center", justifyContent: "center", background: "#F2F3F5", marginTop: "3vh", borderRadius: "10px" }}>
                                <p style={{ color: "#6B7583", fontSize: "0.9rem", fontWeight: 700, marginTop: "3%" }}>등록된 스루온리 상품이 없습니다.</p>
                            </div>
                            <div style={{ display: "flex", height: "40vh", width: "26vw", justifyContent: "center" }}>
                                <img src={throoOnlyModalImg} alt="닫기버튼" />
                            </div>
                            <div className='button_box'>
                                <div className='submit_box' onClick={moveToPath}>
                                    <p>스루온리 상품 추가하기</p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </CommercialCouponStyle>
        </Modal>
    )
}

export const ModalCouponComamercial = ({ sProps, fnClose, fnCompleteCoupon, iList, dataBox, fnMoveToPath }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const [sCouponId, setCouponId] = useState("");
    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const onOperatingDate = (value, dateString) => {
        if (dateString !== undefined && dateString !== null && dateString !== "") {
            setToDate(dateString);
        } else {
            setToDate(moment().format(defaultFormat));
        }
    }

    const changeData = async (item) => {
        let temp = sCouponId;
        if (sCouponId.toString() === item.id.toString()) {
            setCouponId("");
            temp = "";
        } else {
            setCouponId(item.id);
            temp = item.id;
        }
    };

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const moveToPath = () => {
        if (typeof fnMoveToPath === "function" && fnMoveToPath !== undefined) {
            fnMoveToPath();
        }
    }

    const completeCoupon = () => {
        if (sCouponId !== "" && toDate !== "") {
            let temp = {
                key: dataBox.key,
                name: dataBox.param,
                img: dataBox.img,
                title: dataBox.name,
                priceCasting: dataBox.priceCasting,
                price: dataBox.price,
                param: dataBox.param,
                couponId: sCouponId,
                fromDate: toDate,
                deliver_price: dataBox.deliver_price,
                exec_period: dataBox.exec_period,
            }
            if (typeof fnCompleteCoupon === "function" && fnCompleteCoupon !== undefined) {
                fnCompleteCoupon(temp);
            }
        } else {
            message.error("쿠폰을 선택해주세요.");
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '15%',
                    border: 'none',
                    width: "30vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>내 주변 쿠폰 광고</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    {iList.length > 0 ?
                        <>
                            <div className='content_box'>
                                <h2>앱 적용 이미지</h2>
                                <div className="screen">
                                    <img src={appBanner02} alt="preview-img" style={{ width: "100%", height: "100%" }} />
                                </div>

                                <div className="textInput">
                                    <h2>광고 시작날짜 <span>(광고 사용기간은 시작일로부터 30일입니다)</span></h2>
                                    <DatePicker
                                        className="date_picker"
                                        style={{ border: "none", width: "100%", right: "1.5%" }}
                                        format={defaultFormat}
                                        value={moment(toDate, defaultFormat)}
                                        onChange={onOperatingDate}
                                    />
                                </div>

                                <h2>쿠폰 선택</h2>
                                {iList.length > 0 ?
                                    <>
                                        <div className="commercial_banner_text">
                                            <p className="optionDivider">광고에 사용하실 쿠폰을 선택해세요</p>
                                        </div>
                                        {iList.map((item, index) => {
                                            return (
                                                <div className="product_item_box" key={index}>
                                                    <div className='wrapper_box' style={{ background: (item.id.toString() === sCouponId.toString()) ? "#F3F7FF" : "#FAFAFB" }} onClick={() => changeData(item)}>
                                                        <div className="img_box">
                                                            <img src={couponBoxImg} alt="preview-img" />
                                                        </div>
                                                        <div className="box">
                                                            <p style={{ color: "#000", fontWeight: 800, fontSize: "1rem", paddingTop: "3%" }}>{item.name}</p>
                                                            {item.type === "amount" ?
                                                                <p style={{ fontWeight: "600", fontSize: "0.9rem", color: "#6B7583" }}>{item.amount}원 할인쿠폰 </p>
                                                                :
                                                                <p style={{ fontWeight: "600", fontSize: "0.9rem", color: "#6B7583" }}>{item.amount}원 할인쿠폰 </p>
                                                            }
                                                        </div>
                                                        <div className="check_box">
                                                            {(item.id.toString() === sCouponId.toString()) ?
                                                                <SVG className="arrow_down" src={checkboxSvg} width="20px" height="20px" title="더보기버튼" />
                                                                :
                                                                <SVG className="arrow_down" src={checkboxNoneSvg} width="20px" height="20px" title="더보기버튼" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div style={{ height: "5vh" }} />
                                    </>
                                    :
                                    <div className="none_commercial_box">
                                        <p>등록된 쿠폰이 없습니다.</p>
                                    </div>
                                }

                            </div>
                            <div className='button_box'>
                                <div className='submit_box' onClick={completeCoupon}>
                                    <p>장바구니에 담기</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div style={{ width: "100%", height: "6vh", display: "flex", alignItems: "center", justifyContent: "center", background: "#F2F3F5", marginTop: "3vh", borderRadius: "10px" }}>
                                <p style={{ color: "#6B7583", fontSize: "0.9rem", fontWeight: 700, marginTop: "3%" }}>등록된 쿠폰이 없습니다.</p>
                            </div>
                            <div style={{ height: "40vh", width: "26vw", justifyContent: "center", paddingTop: "5%" }}>
                                <img src={appBanner02} alt="닫기버튼" width={"100%"} height={"70%"} />
                                <p style={{ color: "#6B7583", fontSize: "0.9rem", fontWeight: 700, marginTop: "3%" }}>쿠폰 등록하고 내 주변 쿠폰 광고를 이용하세요.</p>
                            </div>
                            <div className='button_box'>
                                <div className='submit_box' onClick={moveToPath}>
                                    <p>쿠폰 등록하기</p>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </CommercialCouponStyle>
        </Modal>
    )
}

export const ModalBannerComamercial = ({ sProps, fnClose, fnCompleteBanner, dataBox }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const [loading, setLoading] = useState(false);

    const [bannerTitle, setBannerTitle] = useState("");
    const [bannerSubTitle, setBannerSubTitle] = useState("");
    const [bannerImg, setBannerImg] = useState("");
    const [toDate, setToDate] = useState(moment().format(defaultFormat));

    const onChangeNm = (event) => {
        let temp = event.target.value;
        if (temp.length <= 16) {
            setBannerTitle(event.target.value);
        }
    }

    const onChangeSubNm = (event) => {
        let temp = event.target.value;
        if (temp.length <= 8) {
            setBannerSubTitle(event.target.value);
        }
    }

    const removeImgae = () => {
        setBannerImg("");
    }

    const onOperatingDate = (value, dateString) => {
        if (dateString !== undefined && dateString !== null && dateString !== "") {
            setToDate(dateString);
        } else {
            setToDate(moment().format(defaultFormat));
        }
    }

    const uploadLogoImage = async (e) => {
        let origin = e.target.files[0];
        if (origin !== undefined) {
            if (origin.type !== undefined && origin.type !== null) {
                if (origin.type === "image/png" || origin.type === "image/jpeg" || origin.type === "image/jpg") {
                    let tempImage = await getBase64(origin);
                    uploadAllFunc(tempImage, origin);
                }
            }
        }
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};
        const formData = new FormData();
        formData.append('photo', fileList);

        const oResponse = await sProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
        if (oResponse !== undefined) {
            if (oResponse.file_name !== undefined) {
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }

    const uploadAllFunc = async (nIndex, sIndex) => {
        setLoading(true);
        let isProcess = false;
        let uploadFile = {};

        if (sIndex !== "") {
            const upload = await imageProcess(sIndex);
            if (upload === "") {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                isProcess = true;
                uploadFile = upload;
            }

        }
        if (isProcess) {
            setBannerImg(uploadFile.url_path);
        }
        setLoading(false);
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const completeBanner = () => {
        if (bannerTitle !== "" && bannerSubTitle !== "" && bannerImg !== "" && toDate !== "") {
            let temp = {
                key: dataBox.key,
                name: dataBox.param,
                img: dataBox.img,
                title: dataBox.name,
                priceCasting: dataBox.priceCasting,
                price: dataBox.price,
                param: dataBox.param,
                bannerTitle,
                bannerSubTitle,
                bannerImgUrl: bannerImg,
                fromDate: toDate,
                deliver_price: dataBox.deliver_price,
                exec_period: dataBox.exec_period,
            }
            if (typeof fnCompleteBanner === "function" && fnCompleteBanner !== undefined) {
                fnCompleteBanner(temp);
            }
        } else {
            message.error("배너광고 제목과 부제목 그리고 이미지를 등록해주세요.");
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '10%',
                    left: '35%',
                    border: 'none',
                    width: "30vw",
                    heigth: "50vh",
                    background: '#fff',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialBannerStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>메인 배너광고</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className='content_box'>
                        <h2>앱 적용 이미지</h2>
                        <div className="screen">
                            <div className="text">
                                <div className="storeName"><p>{sProps.UserConfigReducer.StoreName}</p></div>
                                <h3>{bannerSubTitle !== "" ? bannerSubTitle : "4월 이벤트"}</h3>
                                <h2>{bannerTitle !== "" ? bannerTitle : "예) 딱한달! 14,400원 (300g)"}</h2>
                            </div>
                            <div className="image">
                                <div className="preview">
                                    {(bannerImg !== "" && !loading) && <img src={bannerImg} alt="preview-img" style={{ width: "104px", height: "104px" }} />}
                                </div>
                            </div>
                        </div>

                        <h2>이미지 <span>(권장사이즈: 600 x 600px)</span></h2>
                        <div className="imageUpload" style={{ display: "flex", alignItems: "flex-start", margin: "20px 0 30px 0" }}>
                            <div className="preview" style={{ position: "relative" }}>
                                {bannerImg !== "" && <img src={bannerImg} alt="preview-img" style={{ marginRight: "16px", width: "100px", height: "72px", border: "1px solid #FAFAFC", borderRadius: "4px" }} />}
                                {bannerImg !== "" && <img className='resetBtn' onClick={removeImgae} src={removeButton} style={{ width: "20px", height: "20px", marginRight: "16px" }} />}
                            </div>
                            <label htmlFor="file-input">
                                <img src={uploadImage} style={{ width: "100px", height: "72px" }} />
                            </label>
                            <input id="file-input" type="file" style={{ display: "none" }} onChange={(e) => uploadLogoImage(e)} />
                        </div>

                        <div className="textInput">
                            <h2>메인타이틀 <span>(16자 이내)</span></h2>
                            <input
                                type="text"
                                placeholder="예) 딱한달! 14,400원 (300g)"
                                value={bannerTitle}
                                onChange={onChangeNm}
                                style={{ width: "100%" }}
                                onFocus={(e) => e.target.placeholder = ''}
                                onBlur={(e) => e.target.placeholder = '예) 딱한달! 14,400원 (300g)'}
                            />
                            <h2>서브타이틀 <span>(8자 이내)</span></h2>
                            <input
                                type="text"
                                placeholder='4월 이벤트'
                                style={{ width: "100%" }}
                                value={bannerSubTitle}
                                onChange={onChangeSubNm}
                                onFocus={(e) => e.target.placeholder = ''}
                                onBlur={(e) => e.target.placeholder = '4월 이벤트'}
                            />
                            <h2>광고 시작날짜 <span>(광고 사용기간은 시작일로부터 30일입니다)</span></h2>
                            <DatePicker
                                className="date_picker"
                                style={{ border: "none", width: "100%", right: "1.5%" }}
                                format={defaultFormat}
                                value={moment(toDate, defaultFormat)}
                                onChange={onOperatingDate}
                            />
                        </div>

                    </div>
                    <div className='button_box'>
                        <div className='submit_box' onClick={completeBanner}>
                            <p>장바구니에 담기</p>
                        </div>
                    </div>
                </div>
            </CommercialBannerStyle>
        </Modal>
    )
};

export const ModalBannerComamercialEdit = ({ sProps, fnClose, iDataBox }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const [loading, setLoading] = useState(false);

    const [sData, setData] = useState("");

    const [bannerTitle, setBannerTitle] = useState("");
    const [bannerSubTitle, setBannerSubTitle] = useState("");
    const [bannerImg, setBannerImg] = useState("");

    const onChangeNm = (event) => {
        let temp = event.target.value;
        if (temp.length <= 16) {
            setBannerTitle(event.target.value);
        }
    }

    const onChangeSubNm = (event) => {
        let temp = event.target.value;
        if (temp.length <= 8) {
            setBannerSubTitle(event.target.value);
        }
    }

    const removeImgae = () => {
        setBannerImg("");
    }

    const uploadLogoImage = async (e) => {
        let origin = e.target.files[0];
        if (origin !== undefined) {
            if (origin.type !== undefined && origin.type !== null) {
                if (origin.type === "image/png" || origin.type === "image/jpeg" || origin.type === "image/jpg") {
                    let tempImage = await getBase64(origin);
                    uploadAllFunc(tempImage, origin);
                }
            }
        }
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};
        const formData = new FormData();
        formData.append('photo', fileList);

        const oResponse = await sProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
        if (oResponse !== undefined) {
            if (oResponse.file_name !== undefined) {
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }

    const uploadAllFunc = async (nIndex, sIndex) => {
        setLoading(true);
        let isProcess = false;
        let uploadFile = {};

        if (sIndex !== "") {
            const upload = await imageProcess(sIndex);
            if (upload === "") {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                isProcess = true;
                uploadFile = upload;
            }

        }
        if (isProcess) {
            setBannerImg(uploadFile.url_path);
        }
        setLoading(false);
    }

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        if (typeof fnClose === "function" && fnClose !== undefined) {
            fnClose();
        }
    }

    const completeBanner = async () => {
        if (bannerTitle !== "" && bannerSubTitle !== "" && bannerImg !== "") {
            const oData = {
                store_id: sProps.UserConfigReducer.StoreID,
                img_url: bannerImg,
                title: bannerTitle,
                subTitle: bannerSubTitle,
                eventId: sData.eventId,
                commercialId: sData.id,
            }
            const oResponse = await sProps.oManager.fDoAxios("/store/commercial/editBannerCommercial", "post", "login", oData);
            if (oResponse !== undefined && oResponse) {
                if (typeof fnClose === "function" && fnClose !== undefined) {
                    fnClose();
                }
            } else {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            }
        } else {
            message.error("배너광고 제목과 부제목 그리고 이미지를 등록해주세요.");
        }
    }

    const asyncData = async () => {
        setLoading(true);
        setData(iDataBox);
        setBannerTitle(iDataBox.title);
        setBannerSubTitle(iDataBox.subTitle);
        setBannerImg(iDataBox.img);
        setLoading(false);
    }

    useEffect(() => {
        asyncData();
    }, [iDataBox]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '10%',
                    left: '35%',
                    border: 'none',
                    width: "30vw",
                    heigth: "50vh",
                    background: '#fff',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialBannerStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>광고 내용 수정</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className='content_box'>
                        <h2>앱 적용 이미지</h2>
                        <div className="screen">
                            <div className="text">
                                <div className="storeName"><p>{sProps.UserConfigReducer.StoreName}</p></div>
                                <h3>{bannerSubTitle !== "" ? bannerSubTitle : "4월 이벤트"}</h3>
                                <h2>{bannerTitle !== "" ? bannerTitle : "예) 딱한달! 14,400원 (300g)"}</h2>
                            </div>
                            <div className="image">
                                <div className="preview">
                                    {(bannerImg !== "" && !loading) && <img src={bannerImg} alt="preview-img" style={{ width: "104px", height: "104px" }} />}
                                </div>
                            </div>
                        </div>

                        <h2>이미지 <span>(권장사이즈: 600 x 600px)</span></h2>
                        <div className="imageUpload" style={{ display: "flex", alignItems: "flex-start", margin: "20px 0 30px 0" }}>
                            <div className="preview" style={{ position: "relative" }}>
                                {bannerImg !== "" && <img src={bannerImg} alt="preview-img" style={{ marginRight: "16px", width: "100px", height: "72px", border: "1px solid #FAFAFC", borderRadius: "4px" }} />}
                                {bannerImg !== "" && <img className='resetBtn' onClick={removeImgae} src={removeButton} style={{ width: "20px", height: "20px", marginRight: "16px" }} />}
                            </div>
                            <label htmlFor="file-input">
                                <img src={uploadImage} style={{ width: "100px", height: "72px" }} />
                            </label>
                            <input id="file-input" type="file" style={{ display: "none" }} onChange={(e) => uploadLogoImage(e)} />
                        </div>

                        <div className="textInput">
                            <h2>메인타이틀 <span>(16자 이내)</span></h2>
                            <input
                                type="text"
                                placeholder="예) 딱한달! 14,400원 (300g)"
                                value={bannerTitle}
                                onChange={onChangeNm}
                                style={{ width: "100%" }}
                            />
                            <h2>서브타이틀 <span>(8자 이내)</span></h2>
                            <input
                                type="text"
                                placeholder='4월 이벤트'
                                style={{ width: "100%" }}
                                value={bannerSubTitle}
                                onChange={onChangeSubNm}
                            />
                        </div>

                    </div>
                    <div className='button_box'>
                        <div className='submit_box' onClick={completeBanner}>
                            <p>수정</p>
                        </div>
                    </div>
                </div>
            </CommercialBannerStyle>
        </Modal>
    )
};


const CommercialCouponStyle = styled.div`
    .container {
        font-family: 'Pretendard';

        .title_box {
            display: flex;
            height: 5vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .eventBanner {
            margin-bottom: 30px;
    
            h3 {
                margin: 34px 0 40px 0;
                color: #333;
                font-size: 1rem;
                font-weight: 700;
                line-height: 24px;
                text-align: center;
            }
            p {
                margin-top: 10px;
                font-size: 10px;
                font-weight: 400;
                line-height: 1rem;
                color: #999;
                width: 95%;
                word-break: keep-all;
            }
        }

        .table_banner_items {
            padding: 0 24px;
            height: 23vh;
            border-bottom: 16px solid #F2F3F5;

            h1 {
                font-size: 0.9rem;
                font-weight: 600;
                color: #191f28;
            }
            
            h3 {
                font-size: 1.1rem;
                margin-right: 34px;
                color: #4E5968;
            }
            p {
                margin: 0;
                color: #333d4b;
                font-size: 0.8rem;
            }
            
            .item {
                display: flex;
                align-items: center;
                justify-content:flex-start;
            }

        }

        .content_box {
            padding-top: 3vh;
            height: 50vh;
            width: 100%;
            overflow-y: scroll;
            
            h2 {
                margin-bottom : 20px;
                font-size: 1rem;
                font-weight: 600;
                color: #3B3B46;
            }

            .content_box h2, .textInput h2{
                text-align: left;
                span {
                    color: #B0B7C1;
                }
            }

            .screen {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .textInput {
                padding-top: 34px;
                padding-bottom: 34px;

                input {
                    padding: 16px;
                    outline: none;
                    border-radius: 4px;
                    background: #F2F3F5;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #919aa7;
                }
            }

            input {
                width: 95%;
                background: #F2F3F5;
                border-radius: 4px;
                min-height: 48px;
                outline: none;
            }
            .address {
                .searchAddress {
                    margin-bottom: 10px;
                    display: flex;
                    width: 95%;
                    align-items: center;
                    justify-content: space-between;
                    
                    input {
                        flex: 0 0 75%;
                    }
                    button {
                        flex: 0 0 20%;
                        min-height: 48px;
                        border: none;
                        outline: none;
                        background: #212634;
                        color: #fff;
                        border-radius: 4px;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
            } 

            .none_commercial_box {
                margin-top: 5%;
                width: 100%;
                height: 20vh;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    color : #bbb;
                    font-size: 0.9rem;
                    font-weight: 700;
                }
            }

            .commercial_banner_text {
                margin-top: 5%;
                margin-bottom: 5%;
                padding: 4%;
                height: 6vh;
                background: #F2F3F5;
                border-radius: 10px;
                align-items: center;

                .optionDivider {
                    color : #6B7583;
                    font-size: 0.8rem;
                    font-weight: 600;

                    strong {
                        cursor: pointer;
                    }
                }
            }

            .coupon_item_box {
                height: 8vh;
                width: 100%;
                
                .box {
                    display: flex;
                    align-items: center;
                    padding-left: 5%;
                    height: 6vh;
                    border-radius: 10px;
                }
            }

            .product_item_box {
                display: flex;
                height: 10vh;
                width: 100%;

                .wrapper_box {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 8vh;
                    background: #FAFAFB;
                    border-radius: 10px;
                    cursor: pointer;

                    .box {
                        align-items: space-between;
                        padding-left: 5%;
                        height: 8vh;
                        width: 60%;
                        
                        p {
                            color : #6B7583;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                    }
                    
                    .img_box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 8vh;
                        width: 25%;
                        
                        img {
                            border-radius: 10px;
                            width: 8vh;
                            height: 7vh;
                        }
                    }

                    .check_box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 8vh;
                        width: 15%;
                        
                        img {
                            border-radius: 10px;
                            width: 8vh;
                            height: 7vh;
                        }
                    }
                }
            }

        }

        .button_box {
            display: flex;
            height: 10vh;
            width: 100%;
            align-items: center;
            justify-content: center;
            
            .submit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                border-radius: 10px;
                background: #6490E7;
                cursor: pointer;
                p {
                    padding-top: 3%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
`;

const CommercialProductStyle = styled.div`
    .container {
        font-family: 'Pretendard';

        .title_box {
            display: flex;
            height: 5vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .container_content {
            height: 60vh;
            
            .deliver_box {
                height: 15vh;
                padding-top: 2vh;
                margin-bottom: 2vh;

                h2 {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: #666666;
                }
                p {
                    margin-left: 2vw;
                    padding-top: 1vh;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #111111;
                }

                .deliver_company {
                    display: flex;
                    align-items: center;
                    height: 5vh;

                }
            }
           
            .delivery_status {
                border-top: 2px solid #dfdfdf;

                .delivery_status_box {
                    padding-top: 5px;
                    width: 100%;
                    height: 12vh;

                    .delivery_status_title {
                        display: flex;
                        padding: 10px;
                        align-content: flex-end;
                        height: 4vh;
                        width: 100%;
                    }
                    .delivery_status_detail {
                        display: flex;
                        width: 100%;
                        padding 3px 0px 10px 0px;
                        height: 3vh;
                    }
                }
            }

            .item_box {
                display: flex;
                height: 25vh;
                border-top: 2px solid #dfdfdf;
                border-bottom: 2px solid #dfdfdf;
                padding-top: 2vh;

                .commercial_img_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 22vh;
                    width: 30%;
                    margin-right: 10%;
                }
                
                .commercial_detail_box {
                    height: 22vh;
                    width: 60%;

                    h2 {
                        color: #000;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                    h3 {
                        color: #2F2F2F;
                        font-size: 0.9rem;
                        font-weight: 600;
                    }
                    p {
                        color: #666666;
                        font-size: 0.8rem;
                        font-weight: 500;
                    }
                }
            }

            .status_box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 2px solid #dfdfdf;
                height: 20vh;
                
                .first_box {
                    width: 20%;
                    height: 15vh;
                    background : #fff;
                    
                    .line_box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 9vh;
                        border-radius : 5px;
                    }

                    .title_box {
                        height: 3vh;

                        p {
                            font-size: 0.8rem;
                            font-weight: 600;
                        }
                    }
                    
                }
                
                .last_box {
                    display: flex;
                    justify-content: center;
                    width: 5%;
                    padding-top: 3vh;
                    height: 15vh;
                    background : #fff;
                }
            }
        }
    }
`;

const CommercialBannerStyle = styled.div`
    .container {
        font-family: 'Pretendard';

        .title_box {
            display: flex;
            height: 5vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .content_box {
            padding-top: 3vh;
            height: 65vh;
            width: 95%;
            overflow-y: scroll;

            h2 {
                margin-bottom : 20px;
                font-size: 1rem;
                font-weight: 600;
                color: #3B3B46;
            }

            .content_box h2, .textInput h2{
                text-align: left;
                span {
                    color: #B0B7C1;
                }
            }
            
            .screen {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 15px;
                height: 20vh;
                background: #F1F3F7;
                border-radius: 4px;
                margin: 20px 16px 30px 16px;
    
               
                .text {
                    max-width: 60%;
                    min-width: 25%;
                    overflow: hidden;
                }
                .storeName {
                    p {
                        background: #fff;
                        padding: 3px 7px;
                        border-radius: 30px;
                        text-align: center;
                        font-size: 0.7rem;
                        font-weight: 500;
                        color: #111;
                        white-spa ce: nowrap;
                    }
                }
                h3 {
                    width: 100%;
                    margin: 8px 0 6px 0;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #111;
                    white-space: nowrap;
                }
                h2 {
                    font-size: 1rem;
                    font-weight: 600;
                    line-height: 26px;
                    color: #111;
                }
                .image {
                    max-width: 40%;
                    min-width: 104px;
                    height: 104px;
                    background-color: #fff;
                }   
            }

            .textInput {
                border-top: 1px solid #F2F3F5;
                padding-top: 34px;

                input {
                    margin: 10px 0 26px 0;
                    padding: 16px;
                    border: 0px solid #fff;
                    outline: none;
                    border-radius: 4px;
                    background: #F2F3F5;
                    font-size: 1rem;
                    font-weight: 500;
                    color: #919aa7;
                }
            }

        }

        .button_box {
            display: flex;
            height: 10vh;
            width: 100%;
            align-items: center;
            justify-content: center;
            
            .submit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                border-radius: 10px;
                background: #6490E7;
                cursor: pointer;
                p {
                    padding-top: 3%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
`;

const ChargedPointStyle = styled.div`
    .container {
        font-family: 'Pretendard';

        .title_box {
            display: flex;
            height: 5vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .content_box {
            height: 40vh;
            padding-top: 10vh;
            width: 100%;

            .selectPrice_button {
                margin-bottom: 10px;
                margin-right: 6px;
                padding: 6px 25px;
                font-size: 1rem;
                font-weight: 600;
                color: #6B7583;
                background: #F2F3F5;
                border: none;
                outline: none;
                border-radius: 4px;
                
            }
    
            .selectPrice_un_act_button {
                margin-bottom: 10px;
                margin-right: 6px;
                padding: 6px 25px;
                font-size: 1rem;
                font-weight: 600;
                color: #6490E7;
                background: #F3F7FF;
                border: none;
                outline: none;
                border-radius: 4px;
                
            }
    
            h2 {
                margin-bottom : 20px;
                font-size: 1rem;
                font-weight: 600;
                color: #3B3B46;
            }
    
            p {
                margin-top: 5%;
                font-size: 0.9rem;
                font-weight: 600;
                color: #6B7583;
    
                span {
                    font-weight: 800;
                    color: #6490E7;
                }
            }
        }

        .button_box {
            display: flex;
            height: 10vh;
            width: 100%;
            align-items: center;
            justify-content: center;
            
            .submit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                border-radius: 10px;
                background: #6490E7;
                cursor: pointer;
                p {
                    padding-top: 5%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
`;