import React, { useState, useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Table, DatePicker } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import manual from '../../../assets/svg/manual.svg';
import addressSearch from '../../../assets/svg/addressSearch.svg';

import AdjustmentPayment from '../../manage/payment/Calculate/AdjustmentPayment';

const defaultFormat = "YYYY-MM";

const Payment = ({ oProps, parentList }) => {
    const [isLoading, setLoading] = useState(true);

    const [sCategory, setCategory] = useState("");

    const [sAmount, setAmount] = useState(0);
    const [sPeriod, setsPeriod] = useState(null);
    const [dateValue, setDateValue] = useState(moment().format(defaultFormat));
    const [sList, setList] = useState([]);
    const [fakeList] = useState([]);

    const [iList] = useState(parentList);

    const categoryIdNm = useRef(null);

    const onOperatingDate = async (date, dateString) => {
        if(date != null){
            setDateValue(dateString);
        } else {
            setDateValue(moment().format(defaultFormat));
        }
    }

    const searchList = () => {
        setLoading(true);
        getList(dateValue);
    }

    const onChangeProductValue = (event) => {
        setLoading(true);
        setCategory(event.target.value);
        categoryIdNm.current = parseInt(event.target.value);
        getList();
    }

    const getList = async (bIndex) => {
        if(parentList !== undefined && parentList !== null && parentList.length > 0){
            let storeId = parentList[0].key;
            let fromDate = moment().format(defaultFormat);
            if(bIndex !== undefined && bIndex !== null){
                fromDate = bIndex;
            }
            if(categoryIdNm.current !== undefined && categoryIdNm.current != null){
                storeId = parseInt(categoryIdNm.current);
            }
            const oData = {
                storeId,
                fromDate,
            };
            const oResponse = await oProps.oManager.fDoAxios(`/sales/payment/AdjustmentPayment/v2`, "post", null, oData);
            if(oResponse !== undefined){
                setAmount(oResponse.amount);
                setsPeriod(oResponse.period);
                setList(oResponse.result);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        getList();
    },[]);

    return (
        <Style>
            <div className="content_container">
                <div className="container">
                    <div className="title_box">
                        <h2>정산 조회</h2>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span><strong>정산내역</strong>을 날짜별 확인하고<br /><strong>엑셀파일로</strong>다운로드 가능합니다</span>
                        </div>
                    </div>
                    <div className="category_title">
                        <p>매장선택</p>
                        <select placeholder="매장을 선택해주세요" value={sCategory} onChange={onChangeProductValue} >
                            {iList.map((item, index) => {
                                return (
                                    <option key={index} value={item.key}>{item.storeName}</option>
                                )
                            })};
                        </select>
                    </div>
                    {isLoading ?
                        <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center"}}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                        </div>
                    :
                    <>
                        <div className="title_line">
                            <p>정산 예상 금액<span className="period_box">({sPeriod})</span></p>
                        </div>
                        <div className="last_line">
                            <div style={{width: "50%", cursor:""}}>
                                <div className="today_box">
                                    <p>{sAmount} 원</p>
                                </div>
                            </div>
                        </div>
                        <div className="searching_line">
                            <div style={{width: "25%"}}>
                                <div className="search_date">
                                    <DatePicker 
                                        picker="month" 
                                        bordered={false} 
                                        style={{marginLeft: "7%", fontSize: "0.7rem"}}
                                        value={moment(dateValue, defaultFormat)} 
                                        onChange={onOperatingDate}
                                    />
                                </div>
                            </div>
                            <div style={{width: "30%"}}>
                                <div className="search_bar" onClick={searchList} >
                                    <img src={addressSearch} alt="none"/>
                                    <p>조회</p>
                                </div>
                            </div>
                            <div style={{width: "25%"}}>
                                
                            </div>
                            <div style={{width: "25%"}}>
                                
                            </div>
                        </div>
                        {sList.length > 0 ?
                            <>
                                <div className="data_title">
                                    <div className="pay_date">
                                        <p>입금일</p>
                                    </div>
                                    <div className="pay_status">
                                        <p>상태</p>
                                    </div>
                                    <div className="pay_period">
                                        <p>정산대상기간</p>
                                    </div>
                                    <div className="pay_amount">
                                        <p>입금금액</p>
                                    </div>
                                    <div className="excel_download" />
                                    <div className="detail_line" />
                                </div>
                                <div className={"data_box"}>
                                    {sList.map((item,index) => {
                                        return (
                                            <AdjustmentPayment sName={oProps.UserConfigReducer.StoreName} sData={item} key={"listing-" + index.toString()}/>
                                        )
                                    })

                                    }
                                </div>
                            </>
                            :
                            <div className={"nodata_box"}>
                                <Table
                                    bordered={true}
                                    pagination={false}
                                    dataSource={fakeList}
                                />
                            </div>
                        }
                    </>
                    }
                </div>
            </div>
        </Style>
    )
}

export default Payment;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .content_container {
        width: 42vw; 
        border-radius: 4px;
        margin-bottom: 4%;
        background-color: #F1F3F7;

        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 180px;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }
    }

    .title_box {
        display: flex;
        align-items: center;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 16px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
    }

    .title_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3vh;
        width: 100%;

        .period_box {
            margin-left: 10px;
            color: #617BE3;
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }
    }
    .last_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 8vh;
        padding-bottom: 1%;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            line-height: 24px;
            color: #617BE3;
        }

        .today_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 90%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
            
        }

        .yesterday_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
        }
    }

    .searching_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;
    }

    .search_bar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            text-align: center;
            font-size: 0.8rem;
            margin-left: 5%;
            color: #fff;
        }
    }

    .search_file {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        width: 70%;
        height: 5vh;
        
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.7rem;
            text-align: center;
            color: #fff;
        }
    }

    .search_no_file {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        width: 70%;
        height: 5vh;
        
        background: #dfdfdf;
        border-radius: 5px;
        cursor: not-allowed;

        p {
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .search_selectOption {
        display: flex;
        align-items: center;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .search_date {
        display: flex;
        align-items: center;
        margin-right: 5%;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .data_box {
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 20%;
    }

    .nodata_box {
        margin-top: 3%;
    }

    .data_title {
        margin-top: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #000;
        border-bottom: 1px solid #dfdfdf;
        height: 3vh;

        .pay_date {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;
        }
        .pay_status {
            width: 13%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .pay_period {
            width: 21%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .pay_amount {
            width: 22%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .excel_download {
            width: 23%;
            display: flex;
            align-items: center;
            justify-content: center;
        } 

        .detail_line {
            width: 13%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p{
            font-style: normal;
            font-weight: 400;
            font-size: 0.7rem;
            text-align: center;
            color: #000;
        }
    }

    .category_title {
        height: 70px;
        margin-top: 30px;
        margin-bottom: 30px;

        input {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .address input {
            width: 90%;
        }
        .address_button {
            cursor: pointer;
            margin-top: 2%;
            width: 12%;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 1px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        textarea {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }
    
`


