import React, { useEffect, useState } from 'react';
import { isMobile } from "react-device-detect";
import Loader from "react-loader-spinner";

import M_notice from "./mobile/M_notice";
import D_notice from "./desktop/D_notice";

const MainNotice = ({oProps}) => {
    const [isLoad, setIsLoad] = useState(true);
    const [sList, setList] = useState([]);

    
    const getList = async () => {
        const countNm = 100;
        const oResponse = await oProps.oManager.fDoAxios("/store/notice-" + countNm, "get", null, null);
        if(oResponse != undefined){
            if(oResponse.resultCd === "0000"){
                setList(oResponse.resultData);
            }
        }
        setIsLoad(false);
    }

    useEffect(() => {
        getList();
    },[]);

    return (
        <>
            {isLoad ?
                <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center", marginTop: 60}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
            :
            <>
                {isMobile ?  <M_notice iList={sList} /> : <D_notice iList={sList} />}
            </>

            }
        </>
    )
}

export default MainNotice;