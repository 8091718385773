import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_noticeTab from "./mobile/M_noticeTab";
import D_noticeTab from "./desktop/D_noticeTab";

const MainPosnoticeTab = (oProps) => {
    const PosDownRender = () => {
        if (isMobile) {
            return <M_noticeTab />
        }

        return <D_noticeTab />
    }

    return (
        <>
            {PosDownRender()}
        </>
    )
}

export default MainPosnoticeTab;