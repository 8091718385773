import React from 'react';
import styled from 'styled-components';

import print01 from '../../../../assets/img/guide/print/print01.png';
import print02 from '../../../../assets/img/guide/print/print02.png';
import print03 from '../../../../assets/img/guide/print/print03.png';
import print04 from '../../../../assets/img/guide/print/print04.png';
import print05 from '../../../../assets/img/guide/print/print05.png';
import print_baudrate from '../../../../assets/img/guide/print/print_baudrate.png';
// 
const PrintGuide = (oProps) => {

    return (
        <D_printGuide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>스루 가이드</h3>
                        <h1>스루 프린터 설정 방법</h1>
                        <div className="subTitle">
                            <p>
                                프린터 설정은 SERIAL / USB / NETWORK 3가지 중 하나를 선택할 수 있습니다.<br />
                                <span>*배달의 민족 또는 쿠팡 이츠를 사용하는 경우 똑같이 설정해 주시면 됩니다!</span>
                            </p>
                        </div>
                        <h2>스루 포스를 다운로드한 후<br />프린터 설정에 들어가주세요.</h2>
                        <img src={print01} alt="스루프린터메인화면 이미지" width="400px" />
                        <p>SERIAL, USB, NETWORK 방식 중 하나를 선택할 수 있습니다.</p>
                        <h2>1. SERIAL 설정 방법</h2>
                        <img src={print02} alt="serial설정방법 이미지" width="400px" />
                        <p>1) 포스와 프린터를 연결해 줍니다.<br />2) 포스에 맞는 Port와 프린트에 맞는 Baud Rate를 선택해 줍니다.</p>
                        <div className="tip">
                            <h2>😥 Port 값을 모르겠어요.</h2>
                            <p>
                                1) 시리얼 포트(9핀)를 사용하는 상황이라면 POS 기기 뒤편에 잭이 꽂혀진 곳의 COM 번호를 확인해 주세요.<br />
                                2) 번호 확인이 불가한 상황이라면 Port 값을 1부터 하나씩 변경하면서 테스트 인쇄를 시도해 주세요.<br />
                                3) 그래도 Port 값을 모르시겠다면 사용하시는 포스 업체에 문의해 주세요!<br />
                            </p>
                        </div>
                        <div className="tip">
                            <h2>😥 Baud Rate 값을 모르겠어요.</h2>
                            <img src={print_baudrate} alt="BaudRate설정방법 이미지" width="400px" />
                            <p><span>*만약 출력이 안된다면, BaudRate 값을 9600으로 설정 후,<br /> Port 값을 하나씩 변경하면서 테스트 인쇄를 시도해 보세요.</span></p>
                        </div>
                        <h2>2. USB 설정 방법</h2>
                        <img src={print03} alt="usb설정방법 이미지" width="400px" />
                        <p>1) USB를 프린터에 연결해 줍니다.<br />2) 사용하시는 프린터 이름을 선택해 줍니다.</p>
                        <div className="tip">
                            <h2>😥 프린터 이름을 모르겠어요.</h2>
                            <p>
                                프린터를 하나씩 변경하면서 테스트 인쇄를 시도해 주세요.
                            </p>
                        </div>
                        <h2>3. NETWORK 설정 방법</h2>
                        <img src={print04} alt="NETWORK 설정 방법 이미지" width="400px" />
                        <p>사용하시는 네트워크의 IP와 네트워크 Port를 입력해 줍니다.</p>
                        <div className="tip">
                            <h2>😥 네트워크 설정값을 모르겠어요.</h2>
                            <p>네트워크 값은 저희 쪽에서도 확인이 어려워요ㅠㅠ 사용하시는 포스 업체에 문의해 주세요! </p>
                        </div>
                        <div className="tip">
                            <h1>🔥 오류 시 확인해 주세요!</h1>
                            <h2>😥 매장에 맞는 설정값이 선택사항에 없어요.</h2>
                            <img src={print05} alt="새로고침 이미지" width="400px" />
                            <p>매장에 맞는 설정값이 없다면 하단에 있는 새로 고침을 한번 눌러주세요!</p>
                            <h2>😥 영수증 글씨가 깨져서 보여요.</h2>
                            <p>
                                BaudRate 값을 9600으로 설정한 후 인쇄해보세요. 해결되지 않는다면, BaudRate 값을 변경하면서 테스트 인쇄를 시도해 주세요. <br />
                                (BaudRate 값은 9600, 115200, 38400, 19200이 많이 사용됩니다)
                            </p>
                        </div>
                        <div className="tip">
                            <p><span>*3가지 중 한 가지 방법을 선택해서 포스와 프린터를 연결한 뒤 꼭 테스트 출력을 해주세요!!</span></p>
                            <p> 문제가 있을 경우 언제든 스루 고객센터로 연락 주세요. 친절하게 상담을 도와드리겠습니다.😊 </p>
                        </div>
                    </div>
                </div>
            </div>
        </D_printGuide>
    )
}

export default PrintGuide;

export const D_printGuide = styled.aside`
   * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .pGuideCont {
        margin-top: 106px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;

        em,span {
            font-weight: 900;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 34px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;

        span {
            color: #8E929F;
        }
    }
    p {
        margin: 16px 0;
        font-size: 16px;
        font-weight: 700;
        word-break: keep-all;
    }

    .tip {
        margin-top: 34px;
        border-top: 2px solid #EEEEEE;

        h1 {
            margin-top: 20px;
            font-size: 22px;
            font-weight: 900;
        }
        h2 {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 900;
            line-height: 32px;
        }
        p {
            margin: 10px 0;
            font-size: 16px;
            font-weight: 700;
            word-break: keep-all;

            span {
                color: #F45452;
                font-weight: 900;
            }
        }
    }
`