import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "moment/locale/ko";
import "../../assets/css/font.css";
import modalImage from "../../assets/img/modalLgUplus.png";

const Modal = (oprops) => {
  const [modalOpen, setModalOpen] = useState(true);

  const closeModal = () => {
    setModalOpen(false);
  };
  const todayCloseModal = () => {
    const formattedDate = new Date().toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    localStorage.setItem("modalClosedToday" + formattedDate, "true");
    setModalOpen(false);
  };

  useEffect(() => {
    const formattedDate = new Date().toLocaleDateString("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const isModalClosedToday = localStorage.getItem(
      "modalClosedToday" + formattedDate
    );
    if (isModalClosedToday) {
      setModalOpen(false);
    }
  }, []);
  const openNotice = () => {
    window.open("https://ceo.throo.co.kr/notice", "_blank");
  };
  return (
    <ModalStyle>
      {modalOpen === true ? (
        <div className="modalPage">
          <div className="modalContainer">
            <div className="modalWrap">
              <button onClick={openNotice}>
                <img
                  src={modalImage}
                  alt="공모전 우수상 모달 이미지"
                  width="100%"
                />
              </button>
              <div className="closeFooter">
                <button onClick={todayCloseModal}>
                  <p>오늘 하루 그만보기</p>
                </button>
                <button onClick={closeModal}>
                  <p>닫기</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </ModalStyle>
  );
};

export default Modal;

export const ModalStyle = styled.div`
  .modalPage {
    position: fixed;
    overflow: hidden;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.7);

    .modalContainer {
      width: 90%;
      position: relative;
      left: 50%;
      right: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 539px;

      .briefingImg {
        position: absolute;
        max-width: 410px;
        min-height: 662px;
        max-height: 662px;
        margin-top: 10%;
      }
      /* 버튼 */
      .closeButton {
        position: absolute;
        width: 100%;
        right: 3%;
        top: 9%;
        width: 36px;
        height: 36px;
        cursor: pointer;
      }
      .openButton {
        position: absolute;
        width: 100%;
        bottom: 0%;
        max-height: 60px;
        border: none;
        outline: none;
        background: none;
      }
      .linkBtn {
        margin: 0 auto;
        max-width: 350px;
      }
    }
  }
  .modalWrap {
    background: none;

    button {
      background: none;
      border: none;
      outline: none;
    }
    .closeFooter {
      padding: 16px;
      background: #fff;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 44px;

        p {
          text-decoration: underline;
          text-underline-offset: 5px;
        }
      }
    }
  }
`;
