import React, { useState, useEffect } from 'react';
import randtoken from 'rand-token';
import { isMobile, osName } from "react-device-detect";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import M_Banner from "../../../components/home/mobile/M_Banner";
import M_Content from "../../../components/home/mobile/M_Content";
import D_Banner from "../../../components/home/desktop/D_Banner";
import D_Content from "../../../components/home/desktop/D_Content";

const HomeScreen = ({oProps}) => {
   const [isLoad, setIsLoad] = useState(true);
   const [sList, setList] = useState([]);

   const checkInfo = async () => {
      let oUserConfig = {};
      const typeField = "ceo";
      let token = oProps.UserConfigReducer.LocalToken;

      const currentPath = window.location.pathname;
      if(token === ''){
         token = randtoken.generate(32);
         oUserConfig['LocalToken'] = token;
         oProps.reduxSetUserConfig(oUserConfig);
      }
      await oProps.oManager.fDoAxios("/home/checkInfo", "post", null, {token, isMobile, osName, currentPath, typeField});

   }

   const asyncData = async () => {
      const oResponse = await oProps.oManager.fDoAxios("/home/bannerImgCEO", "get", null, null);
      if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
               if (isMobile) {
                  setList(oResponse.mobileList);
                  setIsLoad(false);
               } else {
                  setList(oResponse.list);
                  setIsLoad(false);
               }
            }
      }
   }

   useEffect(() => {
      checkInfo();
      asyncData();
      window.doKakaoPixel('스루사장님창구', 'tag');
      document.title = "스루사장님창구";
      document.querySelector('meta[name="keywords"]').setAttribute("content", '스루 사장님 창구, 스루 관리페이지');
      document.querySelector('meta[name="description"]').setAttribute("content", '스루 설치 사장님을 위한 사이트입니다.');
      document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://ceo.throo.co.kr');
      document.querySelector('meta[property="og:title"]').setAttribute("content", '우리매장도 드라이브스루 - 스루사장님창구');
      document.querySelector('meta[property="og:description"]').setAttribute("content", '스루 설치 사장님을 위한 사이트입니다.');
   }, []);

   return (
      <div>
         {isLoad ?
            <div style={{display: "flex", justifyContent: "center", height: "50vh",alignItems: "center", marginTop: 60}}>
               <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
            </div>
         :
            <>
               {isMobile ? <M_Banner iList={sList}/> : <D_Banner iList={sList}/>}
            </>
         }
         {isMobile ? <M_Content {...oProps}/> : <D_Content {...oProps}/>}
      </div>
   )

};


export default HomeScreen;