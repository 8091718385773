import React, { useEffect, useState } from 'react';
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import '../../assets/css/font.css';

import Side from './side/Side';
import Dashboard from './dashboard/Dashboard';

import MobileHeader from './header/MobileHeader';

const Main = ({ iProps }) => {

    const [exceptional, setExceptional] = useState("");
    const [status, setStatus] = useState("dashboard");
    const [activeStatus, setActiveStatus] = useState(false);

    const [initialize, setInitialize] = useState({});
    const [iStoreReviewCount, set_iStoreReviewCount] = useState(0);

    const [sLoading, setLoading] = useState(true);

    const [iReviewCount, set_iReviewCount] = useState(0);

    const now = new Date();
    const [startDate, setStartDate] = useState(new Date(now.setMonth(now.getMonth() - 1))); //한달전
    const [endDate, setEndDate] = useState(new Date());

    const location = useLocation();
    const navigate = useNavigate()

    const moveCategory = async (sIndex, aIndex) => {
        if (aIndex !== undefined && aIndex !== null) {
            setExceptional(aIndex);
        } else {
            setExceptional("");
        }
        if (sIndex === "dashboard") {
            await initialData();
        }
        setStatus(sIndex);
    }

    const checkUp = async () => {
        const sParam = iProps.UserConfigReducer.StoreID;
        const oResponse = await iProps.oManager.fDoAxios("/store/v2/currentStatus-" + sParam, "get", null, null);
        if (oResponse !== undefined && oResponse !== null) setActiveStatus(oResponse);
    }

    const initialData = async () => {
        const sParam = iProps.UserConfigReducer.StoreID;
        const oResponse = await iProps.oManager.fDoAxios("/v2/ceoDashboard-" + sParam, "get", null, null);
        if (oResponse !== undefined && oResponse !== null) {
            setInitialize(oResponse);
            if (location.search !== null && location.search !== undefined && location.search !== "") {
                let str = location.search;
                str = str.substr(2, str.length);
                if (str.toString() === "product") {
                    setStatus("product");
                } else if (str.toString() === "commercial") {
                    setStatus("commercial");
                }
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        initialData();
    }, []);

    useEffect(() => {
        checkUp();
    }, [status]);


    return (
        <Dashboard
            sProps={iProps}
            initialLoading={sLoading}
            sInitialize={initialize}
            fnMoveCategory={(sIndex, aIndex) => moveCategory(sIndex, aIndex)}
        />
    )

}

export default Main;
