import React, { useState, useEffect, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import moment from 'moment-timezone';
import 'moment/locale/ko';

import arrow_down from '../../../../assets/svg/arrow.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import { WebRoute } from '../../../../navigation/WebRoutes';
import { Link } from 'react-router-dom';

const Notice = ({ sData, fnMove, mState }) => {
    const [dataList, setDataList] = useState([]);

    const goToNoticePage = async (sIndex) => {
        if (fnMove !== undefined && typeof fnMove === "function") {
            await fnMove(sIndex);
        }
    }

    useEffect(() => {
        setDataList(sData);
    }, [sData])

    return (
        <Fragment>
            <BrowserView>
                <div className="notice">
                    <div className="title">
                        <h1 >공지사항</h1>
                        <p>{moment().format("YYYY-MM-DD")}</p>
                    </div>
                    <div className="text">
                        {dataList.length > 0 &&
                            <>
                                {dataList.map((item, index) => {
                                    return (
                                        <div key={"notice" + index.toString()} className="textContent">
                                            <p>{item.title}</p>
                                            <p>{item.date}</p>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                    <Link to={WebRoute.ADMIN_NOTICE}>
                        <div className="moreButton" onClick={() => goToNoticePage("notice")}>
                            <p style={{ marginRight: "2%" }}>더 자세히보기</p>
                            <SVG className="arrow_down" src={arrow_down} width="20px" height="20px" title="더보기버튼" />
                        </div>
                    </Link>
                </div>
            </BrowserView>
            <MobileView>

                <div className="content6">
                    <div className="title">
                        <h1 >공지사항</h1>
                        <p>{moment().format("YYYY-MM-DD")}</p>
                    </div>
                    <div className="text">
                        {dataList.length > 0 &&
                            <>
                                {dataList.slice(0, 5).map((item, index) => {
                                    return (
                                        <div key={"notice" + index.toString()} className="textContent">
                                            <p>{item.title}</p>
                                            <p>{item.date}</p>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                    <Link to={WebRoute.ADMIN_NOTICE}>
                        <button className="moreButton" style={{ width: "100%", padding: "0px" }} onClick={goToNoticePage}>
                            <p>더 자세히보기</p>
                        </button>
                    </Link>
                </div>
            </MobileView>
        </Fragment>
    )
}

export default Notice;
