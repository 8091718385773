import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Loader from "react-loader-spinner";
import { message, DatePicker, Select } from 'antd';
import moment from 'moment-timezone';
import "antd/dist/antd.css";
import 'moment/locale/ko';

import manual from '../../../../assets/svg/manual.svg';
import addressSearch from '../../../../assets/svg/search_black.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';

import Layout from '../../Layout'
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';
const { Option } = Select;

const defaultFormat = "YYYY-MM-DD HH:mm:ss";
const { RangePicker } = DatePicker;

const Category = ({ oProps }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [regularDayList, setRegularDayList] = useState([]);
    const [temporaryDayList, setTemporaryDayList] = useState([]);

    const [iCount, setCount] = useState(0);
    const [sCount, setSCount] = useState(0);

    // 정기휴무일, 임시휴무일 추가 버튼
    const addList = (sIndex) => {
        if (sIndex === "regular") {
            if (regularDayList.length < 5) {
                const newData = {
                    key: iCount + 1,
                    sMethodValue: "1",
                    sDayValue: "1",
                };
                setRegularDayList([...regularDayList, newData]);
                setCount(iCount + 1);
            }
        } else {
            if (temporaryDayList.length < 5) {
                const newData = {
                    key: sCount + 1,
                    fromDate: moment().startOf('day').format(defaultFormat),
                    toDate: moment().set({ hour: 23, minute: 59, second: 59 }).format(defaultFormat),
                };
                setTemporaryDayList([...temporaryDayList, newData]);
                setSCount(sCount + 1);
            }
        }
    }

    // 저장된 내용 불러오기
    const asyncStore = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/getStoreHoliday-" + store_id, "get", "login", null);
        
        if (oResponse !== undefined && oResponse != null) {
            if (oResponse.resultCd === "locked") {
                setRegularDayList(oResponse.official);
                setTemporaryDayList(oResponse.temporary);
                setCount(oResponse.oKey);
                setSCount(oResponse.tKey);
            }
        }
        setIsLoading(false);
    }
    useEffect(() => {
        asyncStore();
    }, []);

    // 정기휴무일 삭제
    const deleteRegularList = async (key) => {
        setRegularDayList(regularDayList.filter((item) => item.key.toString() !== key.toString()));
    }
    // 정기휴무
    const sItemSave = (sKey, sIndex, kIndex) => {
        let sList = regularDayList;
        const index = sList.findIndex((data) => sKey === data.key);
        const item = {
            key: sKey,
            sMethodValue: sIndex,
            sDayValue: kIndex,
        }
        sList.splice(index, 1, item);
        setRegularDayList(sList);
    }
    const RegularDay = ({ sIndex }) => {
        const [sMethodValue, setMethodValue] = useState(sIndex.sMethodValue);
        const [sDayValue, setDayValue] = useState(sIndex.sDayValue);

        const changeMethodValue = (event) => {
            if (isMobile) {
                setMethodValue(event)
                sItemSave(sIndex.key, event, sDayValue)
            } else {
                setMethodValue(event.target.value);
                sItemSave(sIndex.key, event.target.value, sDayValue)
            }
        }

        const changeDayValue = (event) => {
            if (isMobile) {
                setDayValue(event);
                sItemSave(sIndex.key, sMethodValue, event)
                return;
            }
            setDayValue(event.target.value);
            sItemSave(sIndex.key, sMethodValue, event.target.value)
        }

        return (
            <>
                <BrowserView>
                    <div className="select_box">
                        <select value={sMethodValue} onChange={changeMethodValue}>
                            <option value="" disabled>선택하세요</option>
                            {stringList.map((sItem, kIndex) => {
                                return (
                                    <option key={kIndex} value={sItem.value}>{sItem.name}</option>
                                )
                            })}
                        </select>
                        <select value={sDayValue} onChange={changeDayValue}>
                            {dayList.map((sItem, pIndex) => {
                                return (
                                    <option key={pIndex} value={sItem.value}>{sItem.name}</option>
                                )
                            })}
                        </select>
                        <div className="delete_selected_box" onClick={() => deleteRegularList(sIndex.key)}>
                            <p>삭제</p>
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="option_input">
                        <Select value={sMethodValue?.toString() || '1'} onChange={changeMethodValue} className="select_bank" bordered={false}>
                            <Option value="" disabled>선택하세요</Option>
                            {stringList.map((sItem, kIndex) => {
                                console.log("name", sItem, sItem.name, sMethodValue)
                                return (
                                    <Option key={kIndex} value={sItem.value}>{sItem.name}</Option>
                                )
                            })}
                        </Select>
                        <Select value={sDayValue?.toString() || '1'} onChange={changeDayValue} className="select_bank" bordered={false}>
                            {dayList.map((sItem, pIndex) => {
                                return (
                                    <Option key={pIndex} value={sItem.value}>{sItem.name}</Option>
                                )
                            })}
                        </Select>
                        <div className="delete_selected_box" onClick={() => deleteRegularList(sIndex.key)}>
                            <p>삭제</p>
                        </div>
                    </div>
                </MobileView>
            </>
        )
    }

    // 임시휴무 삭제
    const deleteTemporaryList = (key) => {
        setTemporaryDayList(temporaryDayList.filter((item) => item.key !== key));
    }
    // 임시휴무일
    const nItemSave = (sKey, sIndex, kIndex) => {
        let sList = temporaryDayList;
        const index = sList.findIndex((data) => sKey === data.key);

        const fromDate = moment(sIndex, defaultFormat).startOf('day').format(defaultFormat);

        const toDate = moment(kIndex, defaultFormat).set({ hour: 23, minute: 59, second: 59 }).format(defaultFormat);

        const item = {
            key: sKey,
            fromDate: fromDate,
            toDate: toDate,
        }
        sList.splice(index, 1, item);
        setTemporaryDayList(sList);
    }
    const TemporaryDay = ({ sIndex }) => {
        const [fromDate, setFromDate] = useState(moment(sIndex.fromDate).format(defaultFormat));
        const [toDate, setToDate] = useState(moment(sIndex.toDate).format(defaultFormat));

        const onOperatingDate = (value, dateString) => {
            let tempFromDate = fromDate;
            let tempToDate = toDate;
            if (dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== "") {
                if (fromDate === moment().format(defaultFormat)) {
                    if (dateString[1] !== undefined && dateString[1] !== null && dateString[1] !== "") {
                        tempFromDate = dateString[0];
                        tempToDate = dateString[1];
                        setFromDate(dateString[0]);
                        setToDate(dateString[1]);
                    } else {
                        tempFromDate = dateString[0];
                        setFromDate(dateString[0]);
                    }
                } else {
                    tempFromDate = dateString[0];
                    tempToDate = dateString[1];
                    setFromDate(dateString[0]);
                    setToDate(dateString[1]);
                }
            } else {
                tempFromDate = moment().format(defaultFormat);
                tempToDate = moment().add(1, 'days').format(defaultFormat);
                setFromDate(moment().format(defaultFormat));
                setToDate(moment().add(1, 'days').format(defaultFormat));
            }
            nItemSave(sIndex.key, tempFromDate, tempToDate);
        }

        return (
            <div className="search_date">
                <RangePicker
                    className="date_picker"
                    style={{ width: "90%", borderRadius: "4px" }}
                    format="YYYY-MM-DD"
                    value={[moment(fromDate, defaultFormat), moment(toDate, defaultFormat)]}
                    onChange={onOperatingDate}
                    showTime={false}
                />
                <div className="delete_selected_box" onClick={() => deleteTemporaryList(sIndex.key)}>
                    <p>삭제</p>
                </div>
            </div>
        )
    }

    // 등록하기버튼(submit)
    const insertDuvetDay = async () => {
        const oData = {
            iList: regularDayList,
            sList: temporaryDayList,
            store_id: oProps.UserConfigReducer.StoreID
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/editStoreHoliday", "post", "login", oData);
        if (oResponse !== undefined) {
            if (!oResponse) {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
            } else {
                message.info("정상적으로 저장이 완료되었습니다.");
            }
        }
        asyncStore();
    }

    //등록화면 
    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.store}>
            <BrowserView>
                <Style>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <div className="container">
                            <div className="title_box">
                                <h2>휴무설정</h2>
                                <div className="tip">
                                    <SVG src={manual} width="18px" height="18px" />
                                    <span>매장의<strong>휴무일</strong>을 지정하거나<br />수정이 가능합니다</span>
                                </div>
                            </div>
                            <div className="category_title">
                                <p style={{ marginTop: "2%" }}>정기휴무</p>
                                {regularDayList.map((item, index) => {
                                    return (
                                        <RegularDay key={"listing" + index.toString()} sIndex={item} />
                                    )
                                })}
                                {regularDayList.length < 5 &&
                                    <div className="regular_day_off_box" onClick={() => addList("regular")}>
                                        <p>정기 휴무일 추가</p>
                                    </div>
                                }
                            </div>
                            <div className="category_title">
                                <p style={{ marginTop: "5%" }}>임시휴무</p>
                                {temporaryDayList.map((item, index) => {
                                    return (
                                        <TemporaryDay key={"listing" + index.toString()} sIndex={item} />
                                    )
                                })}
                                {temporaryDayList.length < 5 &&
                                    <div className="regular_day_off_box" onClick={() => addList("temporary")}>
                                        <p>임시 휴무일 추가</p>
                                    </div>
                                }
                            </div>
                            <div
                                className="insert_box"
                                onClick={() => {
                                    setIsLoading(true);
                                    insertDuvetDay();
                                }}
                            >
                                <p>등록하기</p>
                            </div>
                        </div>
                    }

                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <div className="category_title_line">
                            <div className="category_title">
                                <div className="title_box">
                                    <p className="title_line">휴무설정</p>
                                </div>
                                <div className="tip">
                                    <SVG src={manual} width="18px" height="18px" />
                                    <span>매장의<strong>휴무일</strong>을 지정하거나<br />수정이 가능합니다</span>
                                </div>
                            </div>
                            <div className="option_box">
                                <p>정기휴무</p>
                                {regularDayList.map((item, index) => {
                                    return (
                                        <RegularDay key={"listing" + index.toString()} sIndex={item} />
                                    )
                                })}
                                {regularDayList.length < 5 &&
                                    <div className="insert_box" onClick={() => addList("regular")}>
                                        <p>정기 휴무일 추가</p>
                                    </div>
                                }
                            </div>

                            <div className="option_box" style={{ marginTop: "5vh" }}>
                                <p>임시휴무</p>
                                {temporaryDayList.map((item, index) => {
                                    return (
                                        <TemporaryDay key={"listing" + index.toString()} sIndex={item} />
                                    )
                                })}
                                {temporaryDayList.length < 5 &&
                                    <div className="insert_box" onClick={() => addList("temporary")}>
                                        <p>임시 휴무일 추가</p>
                                    </div>
                                }
                            </div>
                            <div
                                className="register_box"
                                onClick={() => {
                                    setIsLoading(true);
                                    insertDuvetDay();
                                }}
                            >
                                <p>등록하기</p>
                            </div>
                        </div>
                    }

                </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default Category;

const stringList = [
    { id: 1, value: "1", name: "매주" },
    { id: 1, value: "2", name: "매달 첫주" },
    { id: 1, value: "3", name: "매달 둘째주" },
    { id: 1, value: "4", name: "매달 셋째주" },
    { id: 1, value: "5", name: "매달 넷째주" },
    { id: 1, value: "6", name: "매달 다섯째주" },
    { id: 1, value: "7", name: "매달 마지막주" },
];
const dayList = [
    { id: 1, value: "1", name: "월요일" },
    { id: 1, value: "2", name: "화요일" },
    { id: 1, value: "3", name: "수요일" },
    { id: 1, value: "4", name: "목요일" },
    { id: 1, value: "5", name: "금요일" },
    { id: 1, value: "6", name: "토요일" },
    { id: 1, value: "0", name: "일요일" },
];

const Style = styled.div`
    position: relative;
    width: 42vw;

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  

    .app_guide_modal_container {
        position: absolute;
        left: 103%;
        top: 22px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .text_box {
            margin-left: 10px;
            width: 158px;
            height: 74px;

            .title_box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 32px;

                p {
                    margin-left: 10px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                height: 42px;
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }

    .essential_form {
        font-size: 0.8rem;
        font-weight: 400;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8%;
        height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .regular_day_off_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        height: 5vh;
        border: 1px solid #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .regular_day_off_box:hover {
        background: #617BE3;

        p {
            color: #fff;
        }
    }

    .app_modal_container {
        position: absolute;
        right: -23%;
        top: 0;
        .app_modal {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 9vw;
            height: 5vh;
            background: #fff;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }

        .app_modal_detail {
            margin-top: 5%;
            font-size: 0.7rem;
            font-weight: 400;
            color: #666666;
        }
    }

    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            width: 9vw;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3vh;
            width: 5vw;;
            border: 1px solid #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 1px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        width: 42vw; 
    }


    .select_store_type {
        width: 100%;
        border: 1px solid #BBBBBB;
        border-radius: 4px;
    }

    .selectItemWrapper {
        margin: 15px 0;
        padding: 20px 14px;
        background-color: #F1F3F7;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;

        .ant-radio-group.ant-radio-group-outline.radioButton {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        .ant-radio-wrapper {
            flex: 0 0 25%;
            margin: 3px 0px;
        }
    }

    .category_title {
        margin-bottom: 3%;

        .select_box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2%;
                width: 20%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }

            select {
                margin-right: 2%;
                margin-top: 2%;
                padding: 12px;
                width: 100%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
                outline: none;
            }
        }

        .search_date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2%;

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 9%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }

            input {
                padding: 12px;
            }
        }

    }
`



const Mstyle = styled.div`

    .essential_form {
        font-size: 0.8rem;
        font-weight: 700;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .category_title {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5vh;
        margin-bottom: 5%;
        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }
        }
    }

    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 50vw;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.8rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1);
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .option_box {
        margin-bottom: 10%;

        .search_date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2%;

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }

            input {
                padding: 12px;
            }
        }

        .option_input {
            margin-top: 2%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .select_bank {
                margin-right: 2%;
                padding: 6px;
                width: 100%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
                outline: none;
            }

            .select_bank::placeholder {
                font-size: 0.9rem;
                font-weight: 400;
                color: #BBBBBB;
            }

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }
            
        }
    }

    .register_box {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #617BE3;
        height: 6vh;
        margin-top: 40px;
        border-radius: 5px;

        p {
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #617BE3;
        height: 6vh;
        margin-top: 40px;
        border-radius: 5px;

        p {
            font-size: 1rem;
            font-weight: 700;
            color: #617BE3;
        }
    }
    
`


