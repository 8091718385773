/*global kakao*/
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import 'antd/dist/antd.css';

import DoubleLineChart from '../../../variables/DoubleLine';

const UserLocation = ({ oProps }) => {
   const [wrongAccess, setWrongAccess] = useState(true);

   const [iConfig, setIConfig] = useState({
      data: [],
      xField: 'date',
      yField: 'scales',
      seriesField: 'name',
      yAxis: {},
      smooth: true,
      animation: {
         appear: {
            animation: 'path-in',
            duration: 5000,
         },
      },
   });

   const navigate = useNavigate();
   const location = useLocation()
   const asyncData = async (sIndex, aIndex, jIndex, mIndex) => {
      const oData = {
         store_id: sIndex,
         type: aIndex,
         endDate: mIndex,
         adverId: jIndex,
      }
      const oResponse = await oProps.oManager.fDoAxios("/store/commercial/getChart", "post", null, oData);
      if (oResponse !== undefined && oResponse !== null) {
         let temp = {
            data: oResponse,
            xField: 'date',
            yField: 'scales',
            seriesField: 'name',
            yAxis: {},
            smooth: true,
            animation: {
               appear: {
                  animation: 'path-in',
                  duration: 5000,
               },
            },
         }
         setIConfig(temp)
         setWrongAccess(false);
      }
   }

   const validate = async () => {
      if (wrongAccess) {
         if (location.search !== null && location.search !== undefined && location.search !== "") {
            let str = await location.search;
            str = await str.substr(2, str.length);
            str = await str.split("?@=");
            if (str.length > 1) {
               asyncData(str[0], str[1], str[2], str[3]);
            } else {
               setWrongAccess(true);
            }

         } else {
            setWrongAccess(true);
         }
      }
   }

   useEffect(() => {
      validate();
   }, []);

   return (
      <Style>
         {wrongAccess ?
            <div style={{ display: "flex", justifyContent: "center", height: "8vh", alignItems: "center" }}>
               <Loader type="BallTriangle" color="#13166b" height={40} width={40} timeout={9000000} />
            </div>
            :
            <div className="commercial_container2">
               <div className="chartBox">
                  <DoubleLineChart vChart={iConfig} />
               </div>
            </div>
         }
      </Style>
   )
}

export default UserLocation;

const Style = styled.div`
    .commercial_container2 {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 90vw;
        
        .chartBox {
            height: 100%;
            width: 100%;
        }
    }
`;
