import React from 'react';
import {
    isMobile
} from "react-device-detect";

import M_serviceCenter from "./mobile/M_serviceCenter";
import D_serviceCenter from "./desktop/D_serviceCenter";

const MainServiceCenter = ({oProps}) => {
    const ServiceRender = () => {
        if (isMobile) {
            return <M_serviceCenter />
        }

        return <D_serviceCenter />
    }

    return (
        <>
            {ServiceRender()}
        </>
    )
}

export default MainServiceCenter;