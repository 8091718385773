import React from 'react';
import {
   isMobile
} from "react-device-detect";

import M_JoinForm from "./mobile/M_JoinFrom";
import D_JoinForm from "./desktop/D_JoinForm";

const MainJoinForm = ({ oProps }) => {
   return (
      <>
         {isMobile ? <M_JoinForm {...oProps} /> : <D_JoinForm {...oProps} />}
      </>
   )
}

export default MainJoinForm;