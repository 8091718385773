import React, { useState, useEffect, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import "antd/dist/antd.css";
import { Radio, Select } from "antd";

import manual from '../../../../assets/svg/manual.svg';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
const { Option } = Select;

const sEasy = [];
const sNormal = [];
const sBusy = [];
const sWalk = [];
for (let i = 1; i < 40; i++) {
    sEasy.push({
        key: i,
        value: i,
        name: `${i} 분`,
    });
};
for (let i = 5; i < 50; i++) {
    sNormal.push({
        key: i,
        value: i,
        name: `${i} 분`,
    });
};
for (let i = 10; i < 60; i++) {
    sBusy.push({
        key: i,
        value: i,
        name: `${i} 분`,
    });
};
for (let i = 0; i < 60; i++) {
    sWalk.push({
        key: i,
        value: i,
        name: `${i} 분`,
    });
};

const OrderTimeForm = ({ fnInsertForm, nRegister, sData }) => {
    const [isType, setIsType] = useState("");

    const [iEasy, setEasy] = useState(1);
    const [iNormal, setNormal] = useState(15);
    const [iBusy, setBusy] = useState(30);
    const [iWalkThroo, setWalkThroo] = useState(0);

    const setChangeEasy = (value) => {
        setEasy(value);
    }
    const setChangeNormal = (value) => {
        setNormal(value);
    }
    const setChangeBusy = (value) => {
        setBusy(value);
    }
    const setChangeWalk = (value) => {
        setWalkThroo(value);
    }

    const insertForm = async () => {
        const oData = {
            iEasy,
            iNormal,
            iBusy,
            iWalkThroo
        }
        if (fnInsertForm !== undefined && typeof fnInsertForm === "function") {
            await fnInsertForm(oData);
        }
    }

    useEffect(() => {
        if (nRegister !== undefined && nRegister !== null && nRegister) {
            setEasy(parseInt(sData.sEasy));
            setNormal(parseInt(sData.sNormal));
            setBusy(parseInt(sData.sBusy));
            setWalkThroo(parseInt(sData.sWalk));
        }
    }, [sData]);

    return (
        <Fragment>
            <BrowserView>
                <div className="container" style={{ marginTop: "5%" }}>
                    <div className="title_box">
                        <h2>상품 준비시간</h2>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span>상품을 준비하는데 걸리는<strong>시간</strong><br />을 설정 및 확인이 가능합니다.</span>
                        </div>

                    </div>
                    <div className="category_title" style={{ marginTop: "1%", marginBottom: "2%" }}>
                        <p>여유<span className="essential_form">(필수)</span></p>
                        <select value={iEasy} onChange={(e) => setChangeEasy(e.target.value)}>
                            {sEasy.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="location_box_line">
                        <div className="how_box">
                            <p>매장이 한가할 때 준비시간을 설정해주세요</p>
                        </div>
                    </div>
                    <div className="category_title" style={{ marginBottom: "2%" }}>
                        <p>보통<span className="essential_form">(필수)</span></p>
                        <select value={iNormal} onChange={(e) => setChangeNormal(e.target.value)}>
                            {sNormal.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="location_box_line">
                        <div className="how_box">
                            <p>상품을 전달하는 가장 일반적인 준비시간을 설정해주세요</p>
                        </div>
                    </div>
                    <div className="category_title" style={{ marginBottom: "2%" }}>
                        <p>바쁨<span className="essential_form">(필수)</span></p>
                        <select value={iBusy} onChange={(e) => setChangeBusy(e.target.value)}>
                            {sBusy.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="location_box_line">
                        <div className="how_box">
                            <p>매장이 피크타임일때 준비시간을 설정해주세요</p>
                        </div>
                    </div>
                    <div className="category_title" style={{ marginBottom: "2%" }}>
                        <p>워크스루<span>(선택)</span></p>
                        <select value={iWalkThroo} onChange={(e) => setChangeWalk(e.target.value)}>
                            {sWalk.map((item, index) => {
                                return (
                                    <option key={index} value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="location_box_line">
                        <div className="how_box">
                            <p>매장의 바쁜 정도에따라 상품준비시간을 미리 설정할 수 있어요.  너무 바뻐서 차까지 전달이 어려운 시간대는 워크스루만 가능으로 선택하시면, 해당 시간에는 고객에게 워크스루만 가능 매장으로 표시됩니다.</p>
                        </div>
                    </div>
                    <div className="insert_box" onClick={insertForm}>
                        <p>상품 준비시간 등록</p>
                    </div>
                </div>
            </BrowserView>
            <MobileView>

                <div className="category_title_line" style={{ marginTop: "8px" }}>
                    <div className="category_title">
                        <div className="title_box">
                            <p className="title_line">상품 준비시간</p>
                        </div>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span>상품을 준비하는데 걸리는<strong>시간</strong><br />을 설정 및 확인이 가능합니다.</span>
                        </div>
                    </div>
                    <div className="inputText" style={{ marginTop: "1%", marginBottom: "5%" }}>
                        <p>여유<span className="essential_form">(필수)</span></p>
                        <Select value={iEasy} onChange={setChangeEasy} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                            {sEasy.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="inputText" style={{ marginBottom: "5%" }}>
                        <p>보통<span className="essential_form">(필수)</span></p>
                        <Select value={iNormal} onChange={setChangeNormal} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                            {sNormal.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="inputText" style={{ marginBottom: "5%" }}>
                        <p>바쁨<span className="essential_form">(필수)</span></p>
                        <Select value={iBusy} onChange={setChangeBusy} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                            {sBusy.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="inputText">
                        <p>워크스루<span>(선택)</span></p>
                        <Select value={iWalkThroo} onChange={setChangeWalk} className="select_bank" bordered={false} style={{ marginTop: "3%" }}>
                            {sWalk.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                    <div className="last">
                        <button className="finishButoon" onClick={insertForm}>상품 준비시간 등록</button>
                    </div>
                </div>
            </MobileView>
        </Fragment>
    )
}

export default OrderTimeForm;
