import React, { useState, useEffect, useRef } from 'react';
import Loader from "react-loader-spinner";
import { useLocation, useNavigate } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';
import styled from 'styled-components';
import { Select } from 'antd';

import {
   PcModalPublishModalStamp,
   ModalPublishModalStamp
} from '../../../modal/StampModal';
import Layout from '../../Layout'
import layoutHeaderLists from '../../config';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

const { Option } = Select;

const targetStamp = [
    { key: 1, value: 4, name: "4개" },
    { key: 2, value: 5, name: "5개" },
    { key: 3, value: 6, name: "6개" },
    { key: 4, value: 7, name: "7개" },
    { key: 5, value: 8, name: "8개" },
    { key: 6, value: 9, name: "9개" },
    { key: 7, value: 10, name: "10개" },
    { key: 8, value: 11, name: "11개" },
    { key: 9, value: 12, name: "12개" },
];
const periodStamp = [
   //{ key: 1, value: 6, name: "발행일로부터 6개월" },
   { key: 2, value: 12, name: "발행일로부터 12개월" },
   { key: 3, value: 24, name: "발행일로부터 24개월" },
   { key: 4, value: 0, name: "제한없음" },
];

const List = ({ oProps, fnChangeType }) => {
   const [loading, setLoading] = useState(true);
   const [publishModal, setPublishModal] = useState(false);

   const [cNm, setNm] = useState("");
   const [cMinAmount, setMinAmount] = useState("");
   const [couponAmount, setCouponAmount] = useState("");
   const [cPeriod, setPeriod] = useState("6");
   const [cTarget, setTarget] = useState("4");
   const [sCouponId, setCouponId] = useState("");
   const [sId, setId] = useState("");

   const [classStampNm, setClassStampNm] = useState(null);
   const [classStampPrice, setClassStampPrice] = useState(null);
   const [classStampCoupon, setClassStampCoupon] = useState(null);

   const [oStampData, set_oStampData] = useState(null);
   const [sPartialEditClass, set_sPartialEditClass] = useState('show');

   // category state
   const [sCategoryList, setCategoryList] = useState([]);

   const iRef = useRef(null);
   const aRef = useRef(null);
   const navigate = useNavigate();
   const location = useLocation()
   const textSelection = async (event) => {
      if (isMobile) {
         setPeriod(event)
      } else
         setPeriod(event.target.value);
   }

   const targetSelection = async (event) => {
      if (isMobile) {
         setTarget(event);
      } else
         setTarget(event.target.value);
   }

   const onChangeCouponNm = (event) => {
      setClassStampNm("input_action");
      setNm(event.target.value);
   }

   const onChangeStampCouponValue = (event) => {
      const value = event.target.value;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
         setCouponAmount(value);
      }
   }

   const onChangeStampPriceValue = (event) => {
      const value = event.target.value;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
         setMinAmount(value);
      }
   }

   const inputClassStampNm = () => {
      if (classStampNm !== "input_wrong") {
         setClassStampNm("input_action");
      }
      if (classStampPrice !== "input_wrong") {
         setClassStampPrice(null);
      }
      if (classStampCoupon !== "input_wrong") {
         setClassStampCoupon(null);
      }
   }

   const inputClassStampPrice = () => {
      if (classStampPrice !== "input_wrong") {
         setClassStampPrice("input_action");
      }
      if (classStampNm !== "input_wrong") {
         setClassStampNm(null);
      }
      if (classStampCoupon !== "input_wrong") {
         setClassStampCoupon(null);
      }
   }

   const inputClassStampCoupon = () => {
      if (classStampCoupon !== "input_wrong") {
         setClassStampCoupon("input_action");
      }
      if (classStampNm !== "input_wrong") {
         setClassStampNm(null);
      }
      if (classStampPrice !== "input_wrong") {
         setClassStampPrice(null);
      }
   }

   const publishClose = async () => {
      setPublishModal(false);
   }

   const cancelEvent = async () => {
      navigate(WebRoute.ADMIN_STAMP)
      // if(fnChangeType !== undefined && typeof fnChangeType === "function"){
      //     await fnChangeType();
      // }
   }

   const checkNotice = () => {
      let process1 = false;
      let process2 = false;
      let process3 = false;
      if (cNm === "") {
         setClassStampNm("input_wrong");
      } else {
         setClassStampNm(null);
         process1 = true;
      }

      if (cMinAmount === "") {
         setClassStampPrice("input_wrong");
      } else {
         setClassStampPrice(null);
         process2 = true;
      }

      if (couponAmount === "") {
         setClassStampCoupon("input_wrong");
      } else {
         setClassStampCoupon(null);
         process3 = true;
      }

      if (process1 && process2 && process3) {
         const oData = {
            name: cNm,
            id: sId,
            couponId: sCouponId,
            couponCount: cMinAmount,
            amount: couponAmount,
            period: cPeriod,
            target: cTarget,
         }
         aRef.current = oData;
         console.log(aRef.current)
         setPublishModal(true);
      }

   }

   const insertProduct = async () => {
      const oData = {
         store_id: oProps.UserConfigReducer.StoreID,
         nm: cNm,
         minAmount: cMinAmount,
         targetValue: cTarget,
         partnerDiscount: couponAmount,
         period: cPeriod,
         description: "스루스토어에서 생성",
         stampId: sId,
         stampCouponId: sCouponId,
         stampUsage: sCategoryList.map((e, i) => {
            return {
               category_id: e.id,
               stamp_usage: e.stamp_usage
            }
         })
      };

      let sEditUrl = "/stamp/edit";
      if (oStampData && parseInt(oStampData.userEvent) > 0) {
         sEditUrl = "/stamp/update";
      }

      const oResponse = await oProps.oManager.fDoAxios(sEditUrl, "post", "login", oData);
      if (oResponse !== undefined) {
         if (oResponse.resultCd === "0000") {
            await asyncData();
            setNm("");
            setId("");
            setCouponId("");
            setMinAmount("");
            setCouponAmount("");
            setPeriod("6");
            setTarget("6");
            setPublishModal(false);
            navigate(WebRoute.ADMIN_STAMP)

            // if (fnChangeType !== undefined && typeof fnChangeType === "function") {
            //     await fnChangeType();
            // }
         }
      }
   }



   const getList = async () => {
      const store_id = oProps.UserConfigReducer.StoreID;
      const { id } = location?.state;
      console.log("id", id)
      const oResponse = await oProps.oManager.fDoAxios("/store/register/categoryListForStamp/v2-" + store_id + "&" + id, "get", "login", null);
      if (oResponse !== undefined) {
         console.log("oresponse", oResponse)
         if (oResponse.noList !== "none") {
            setCategoryList(oResponse.sResult);
         }
      }
      setLoading(false);
   }

   const asyncData = async () => {
      if (location.state) {
         const { name, id, couponId, couponCount, amount, period, target, item } = location?.state;

         if (!name || name === "" || !id || id === "" || !couponId || couponId === "" || !couponCount || couponCount === "" || !amount || amount === "") {
            return navigate(-1) // where
         }
         set_oStampData(item);
         setLoading(true);
         setNm(name);
         setId(id);
         setCouponId(couponId);
         setMinAmount(couponCount);
         setCouponAmount(amount);
         setPeriod(period);
         setTarget(target);
         await getList()
         setLoading(false);
      } else {
         return navigate(-1)
      }
   }

   const editStampUsage = async (event, data) => {
      const tmp = Object.assign({}, data);
      let tmp_catList = Array.from(sCategoryList);
      if ((tmp_catList.filter((e, i) => e.stamp_usage).length <= 1) && tmp_catList.filter((e) => e.stamp_usage)[0].id === tmp.id)
         return;
      tmp.stamp_usage = !tmp.stamp_usage
      tmp_catList = await tmp_catList.map((e) => data.id === e.id ? tmp : e)
      setCategoryList(tmp_catList);
   }

   useEffect(() => {
      if (oStampData && parseInt(oStampData.userEvent) > 0) {
         set_sPartialEditClass('d-none');
      }
   }, [oStampData]);

   useEffect(() => {
      asyncData();
   }, []);

   return (
      <>
         <Layout sProps={oProps} routeList={[layoutHeaderLists.stamp[2]]}>
            <BrowserView>
               <Style>
                  {loading ?
                     <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                     </div>
                     :
                     <div className="container">
                        <div className="category_title">
                           <p>스탬프 이름을 입력해주세요</p>
                           <input
                              type="text"
                              placeholder="스탬프 이름을 입력해주세요"
                              value={cNm}
                              onChange={onChangeCouponNm}
                              className={classStampNm}
                              onClick={inputClassStampNm}
                           />
                           <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}></p>
                           <div className="location_box_line" style={{ marginTop: "10px" }}>
                              <div className="how_box">
                                 <p>고객에게 보여지는 스탬프이름입니다 <br />ex) 스탬프 10개를 모으면 1,000원 할인!</p>
                              </div>
                           </div>
                        </div>
                        <div className="category_price_line">
                           <p>스탬프 부여기준을 정해주세요</p>
                           <div style={{ position: "relative", marginTop: "2%", marginBottom: "2%" }}>
                              {oStampData && parseInt(oStampData.userEvent) > 0 &&
                                 <span className="stamp-min-amount">{cMinAmount}</span>
                              }
                              <input
                                 type="text"
                                 placeholder="기준 금액을 입력바랍니다"
                                 value={cMinAmount}
                                 onChange={onChangeStampPriceValue}
                                 className={[classStampPrice, sPartialEditClass].join(' ')}
                                 onClick={inputClassStampPrice}
                              />
                              <p className="product_price_box">원을 기준으로 스탬프 1개 발행</p>
                           </div>
                           <div className="location_box_line" style={{ marginTop: "10px" }}>
                              <div className="how_box">
                                 <p>
                                    스탬프 발행은 할인금액을 제외한 결제금액 기준으로 발행됩니다 <br />
                                                입력하신 금액기준으로 스탬프가 발행됩니다<br />
                                                예) 결제금액 59,000원 기준금액 10,000원 스탬프 5개발행
                                            </p>
                              </div>
                           </div>
                        </div>

                        <div className="category_price_line">
                           <p>스탬프 이벤트 기간</p>

                           {oStampData && parseInt(oStampData.userEvent) > 0 &&
                              <span className="stamp-min-amount">{cPeriod === 0 ? '제한없음' : `발행일로부터 ${cPeriod}개월`}</span>
                           }

                           <select value={cPeriod} onChange={textSelection} className={[sPartialEditClass].join(' ')}>
                              <option value="12">발행일로부터 12개월</option>
                              <option value="24">발행일로부터 24개월</option>
                              <option value="0">제한없음</option>
                           </select>
                           <div className="location_box_line" style={{ marginTop: "10px" }}>
                              <div className="how_box">
                                 <p>
                                    기간은 스탬프 이벤트가 진행되는 날짜를 의미합니다 <br />
                                                보상으로 발급되는 선불권의 이용기간은 발급후 6개월로 발행됩니다
                                            </p>
                              </div>
                           </div>
                        </div>

                        {sCategoryList.length ? <div className="category_price_line">
                           <p>스탬프 카테고리</p>
                           {sCategoryList.map((e, i) =>
                              <div className='category_wrapper'>
                                 <label className='category_label'>
                                    <input className='category_checkbox' id={e.id} type="checkbox"
                                       checked={e.stamp_usage}
                                       onChange={(event) => editStampUsage(event, e)}
                                    />
                                    {e.name} </label>
                              </div>
                           )}
                           <div className="location_box_line" style={{ marginTop: "10px" }}>
                              <div className="how_box">
                                 <p>
                                    스탬프가 발행될 상품분류(카테고리)를 정합니다.
                                    예)커피,디저트 메뉴를 판매 중이라면 ‘커피 메뉴’ 구매시에만 스탬프 적립이 되게 설정할 수 있습니다.
                                            </p>
                              </div>
                           </div>
                        </div> : undefined}
                        <div className="category_price_line">
                           <p>스탬프 목표 갯수</p>
                           {oStampData && parseInt(oStampData.userEvent) > 0 &&
                              <span className="stamp-min-amount">총 {cTarget}개</span>
                           }
                           <select value={cTarget} onChange={targetSelection} className={[sPartialEditClass].join(' ')}>
                              <option value="4">총 4개</option>
                              <option value="5">총 5개</option>
                              <option value="6">총 6개</option>
                              <option value="7">총 7개</option>
                              <option value="8">총 8개</option>
                              <option value="9">총 9개</option>
                              <option value="10">총 10개</option>
                              <option value="11">총 11개</option>
                              <option value="12">총 12개</option>
                           </select>
                           <div className="location_box_line" style={{ marginTop: "10px" }}>
                              <div className="how_box">
                                 <p>
                                    보상을 받기위해 모아야하는 총 갯수를 의미합니다
                                            </p>
                              </div>
                           </div>
                        </div>

                        <div className="category_price_line">
                           <p>목표 달성시 주어지는 선불권 금액을 입력해주세요</p>
                           <div style={{ position: "relative", marginTop: "2%", marginBottom: "2%" }}>

                              {oStampData && parseInt(oStampData.userEvent) > 0 &&
                                 <span className="stamp-min-amount">{couponAmount}</span>
                              }
                              <input
                                 type="text"
                                 placeholder="선불권 금액을 입력바랍니다."
                                 value={couponAmount}
                                 onChange={onChangeStampCouponValue}
                                 className={[classStampCoupon, sPartialEditClass].join(' ')}
                                 onClick={inputClassStampCoupon}
                              />
                              <p className="product_price_box">원 선불권 지급</p>
                           </div>
                           <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}></p>
                           <div className="location_box_line" style={{ marginTop: "10px" }}>
                              <div className="how_box">
                                 <p>
                                    보상금액은 선불권으로 고객에게 발행됩니다<br />
                                                발급된 선불권은 금액 제한이 없습니다<br />
                                                발금된 선불권의 유효기간은 발급후 6개월입니다
                                            </p>
                              </div>
                           </div>
                        </div>

                        <div className="return_box" onClick={cancelEvent}>
                           <p>수정 취소</p>
                        </div>
                        <div className="insert_box" onClick={checkNotice}>
                           <p>스탬프 수정하기</p>
                        </div>

                     </div>
                  }
                  {publishModal &&
                     <PcModalPublishModalStamp
                        sProps={oProps}
                        aRef={aRef.current}
                        iBannerModal={publishModal}
                        fnClose={() => publishClose()}
                        fnComplete={() => insertProduct()}
                     />
                  }</Style>
            </BrowserView>
            <MobileView>
               <Mstyle>
                  {!loading ?
                     <div className="buisness">
                        <div className="businessTitle">
                           <p>수정하기</p>
                        </div>
                        <div className="inputText">
                           <h3>스탬프 이름을 입력해주세요</h3>
                           <input
                              type="text"
                              placeholder="스탬프 이름을 입력해주세요"
                              value={cNm}
                              onChange={onChangeCouponNm}
                              className={classStampNm}
                              onClick={inputClassStampNm}
                           />
                        </div>
                        <div className="location_box_line" style={{ height: "7vh" }}>
                           <div className="how_box" style={{ height: "7vh" }}>
                              <p>고객에게 보여지는 스탬프이름입니다 <br />ex) 스탬프 10개를 모으면 1,000원 할인!</p>
                           </div>
                        </div>

                        <div className="inputText">
                           <h3>스탬프 부여기준을 정해주세요</h3>
                           {oStampData && parseInt(oStampData.userEvent) > 0 &&
                              <span className="stamp-min-amount">{cMinAmount}</span>
                           }
                           <input
                              type="text"
                              placeholder="기준 금액을 입력바랍니다"
                              value={cMinAmount}
                              onChange={onChangeStampPriceValue}
                              className={[classStampPrice, sPartialEditClass].join(' ')}
                              onClick={inputClassStampPrice}
                           />
                        </div>
                        <div className="location_box_line" style={{ height: "10vh" }}>
                           <div className="how_box" style={{ height: "10vh" }}>
                              <p>
                                 스탬프 발행은 할인금액을 제외한 결제금액 기준으로 발행됩니다 <br />
                                            입력하신 금액기준으로 스탬프가 발행됩니다<br />
                                            예) 결제금액 59,000원 기준금액 10,000원 스탬프 5개발행
                                        </p>
                           </div>
                        </div>
                        <div className="inputText">
                           <h3>스탬프 이벤트 기간</h3>
                           {oStampData && parseInt(oStampData.userEvent) > 0 &&
                              <span className="stamp-min-amount">발행일로부터 {textSelection}개월</span>
                           }
                           <Select defaultValue='발행일로부터 6개월' className={['select_bank', sPartialEditClass].join(' ')} value={parseInt(cPeriod)} onChange={textSelection} bordered={false}>
                              {periodStamp.map((item, index) => {
                                 return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                 )
                              })}
                           </Select>
                        </div>


                        <div className="location_box_line" style={{ height: "7vh" }}>
                           <div className="how_box" style={{ height: "7vh" }}>
                              <p>
                                 기간은 스탬프 이벤트가 진행되는 날짜를 의미합니다 <br />
                                            보상으로 발급되는 선불권의 이용기간은 발급후 6개월로 발행됩니다
                                        </p>
                           </div>
                        </div>
                        {sCategoryList.length ? <div className="inputText">
                           <h3>스탬프 카테고리</h3>
                           {sCategoryList.map((e, i) =>
                              <div className='category_wrapper'>
                                 <label className='category_label'>
                                    <input className='category_checkbox' id={e.id} type="checkbox"
                                       checked={e.stamp_usage}
                                       onChange={(event) => editStampUsage(event, e)}
                                    />
                                    {e.name} </label>
                              </div>
                           )}
                           <div className="location_box_line" style={{ height: "5vh" }}>
                              <div className="how_box" style={{ height: "5vh" }}>
                                 <p>
                                    스탬프가 발행될 상품분류(카테고리)를 정합니다.
                                    예)커피,디저트 메뉴를 판매 중이라면 ‘커피 메뉴’ 구매시에만 스탬프 적립이 되게 설정할 수 있습니다.
                                            </p>
                              </div>
                           </div>
                        </div> : undefined}
                        <div className="inputText">
                           <h3>스탬프 목표 갯수</h3>
                           {oStampData && parseInt(oStampData.userEvent) > 0 &&
                              <span className="stamp-min-amount">총 {cTarget}개</span>
                           }
                           <Select defaultValue='발행일로부터 6개월' className={["select_bank", sPartialEditClass].join(' ')} value={parseInt(cTarget)} onChange={targetSelection} bordered={false}>
                              {targetStamp.map((item, index) => {
                                 return (
                                    <Option key={index} value={item.value}>{item.name}</Option>
                                 )
                              })}
                           </Select>
                        </div>
                        <div className="location_box_line" style={{ height: "5vh" }}>
                           <div className="how_box" style={{ height: "5vh" }}>
                              <p>보상을 받기위해 모아야하는 총 갯수를 의미합니다</p>
                           </div>
                        </div>
                        <div className="inputText">
                           <h3>목표 달성시 주어지는 선불권 금액을 입력해주세요</h3>
                           {oStampData && parseInt(oStampData.userEvent) > 0 &&
                              <span className="stamp-min-amount">{couponAmount}</span>
                           }
                           <input
                              type="text"
                              placeholder="기준 금액을 입력바랍니다"
                              value={couponAmount}
                              onChange={onChangeStampCouponValue}
                              className={[classStampCoupon, sPartialEditClass].join(' ')}
                              onClick={inputClassStampCoupon}
                           />
                        </div>
                        <div className="location_box_line" style={{ height: "10vh" }}>
                           <div className="how_box" style={{ height: "10vh" }}>
                              <p>
                                 보상금액은 선불권으로 고객에게 발행됩니다<br />
                                            발급된 선불권은 금액 제한이 없습니다<br />
                                            발금된 선불권의 유효기간은 발급후 6개월입니다
                                        </p>
                           </div>
                        </div>
                        <div className="last">
                           <button className="finishButoon" onClick={checkNotice}>스탬프 수정하기</button>
                        </div>
                        <div className="modify">
                           <button className="finishButoon" onClick={cancelEvent}>수정 취소</button>
                        </div>
                     </div>
                     :
                     <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                     </div>
                  }
                  {publishModal &&
                     <ModalPublishModalStamp
                        sProps={oProps}
                        aRef={aRef.current}
                        iBannerModal={publishModal}
                        fnClose={() => publishClose()}
                        fnComplete={() => insertProduct()}
                     />
                  }
               </Mstyle>

            </MobileView>
         </Layout>
      </>
   )
}

export default List;


const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .stamp-min-amount {
        padding: 12px 0px 12px 4px;
        font-size: 0.9rem;
        font-weight: 400;
        color: #999;
        display: block;
    }

    .essential_form {
        font-size: 0.8rem;
        font-weight: 400;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }
    
    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }

    .menu_container {
        margin-top: 10px;
        border-radius: 4px;
        .tab {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw; 
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    
    .content_container {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;

            p {
                margin-left: 5%;
                font-size: 1rem;
                font-weight: 600;
            }
        }
        
        .menuContent {
            height: 30vh;

            .menu {
                display: flex;
                align-items: center;
                padding: 2%;
                
                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4vw;
                    height: 7vh;
                    margin-right: 16px;
                    border-radius: 4px;

                    .box_image {
                        width: 4vw;
                        height: 7vh;
                        border-radius: 5%;
                    }
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 30vw;

                    h3 {
                        margin-left: 5%;
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .content_detail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 2%;
                margin-left: 5%;
                margin-right: 5%;

                p {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: #3B3B46;
                }
            }
            

            .stamp_edit_box {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 12vw;
                height: 4vh;
                margin-left: auto;
            }

            .state_change_box {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 5vw;
                height: 4vh;
                background : #EF4452;
                border-radius: 5px;
                margin-left: auto;
                cursor: pointer;
                transition: 0.3s;

                h2 {
                    padding: 0;
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .state_onchange_box {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 5vw;
                height: 4vh;
                border-radius: 5px;
                background : #40C1BB;
                margin-left: auto;
                cursor: pointer;
                transition: 0.3s;

                h2 {
                    padding: 0;
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .sold_out {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 5vw;
                height: 4vh;
                background : #E8EFFC;
                border-radius: 5px;
                margin-left: auto;
                cursor: pointer;

                h2 {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            
        }
        .page_nation {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 7vh;
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 100px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        width: 42vw;
    }

    .pictures_title {
        height: 80px;

        .location_box_line {
            height: 60px;

            .how_box {
                display: flex;
                align-items: center;
                height: 50px;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
    .pictures_list_title {
        height: 25vh;
    }
    
    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .category_title {
        position: relative;
        margin-bottom: 3%;

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        input {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 2px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        
        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 72px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            position: relative;
            top: 14px;
            bottom: 0;
            right: 0;
            border: 0;
            outline: 0;
            left: 90%;
        }

        textarea {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            min-height: 15vh;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        textarea::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .set_location_box {
            display: flex;
            align-items: center;
            height: 106px;

            .set_location_button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 6vw;
                height: 6vh;
                border-radius: 4px;
                background: #617BE3;
                cursor: pointer;
                p {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }


    }
    .location_box_line {
        height: 80px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 80px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }
    
    .location_box_line2 {
        height: 50px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .category_array {
        margin-bottom: 3%;

        .search_date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2%;

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 9%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }

            input {
                padding: 12px;
            }
        }

    }

    .category_price_line {
        position: "relative";
        margin-bottom: 5%;

        .category_wrapper {
            margin-top:2%;
            display : inline-block;
            margin-right:5%;
            .category_label {
                text-align: center;
                margin-bottom:5%;
                .category_checkbox {
                    align-self:center;
                    width:auto;
                    margin-right: 0.3rem;
                }
            }
        }
        .discount_detail_box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            height: 40px;
            background: #F1F3F7;
            border-radius: 5px;
             
            p {
                margin-left: 15px;
                color: #DD1212;
                font-size: 0.6rem;
                font-weight: 500;
            }
        }

        .product_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 0;
        }
        .product_discount_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 25%;
        }

        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
            
        }

        .classDiscountPrice {
            background: #F1F3F7;
            border: 1px solid #617BE3;
        }

        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 2px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 2px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .discount_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right:0;
            background: #001E62;
            padding: 11px;
            width: 8vw;

            .discount_text {
                font-size: 16px;
                font-weight: bold;
                color: #fff; 
            }

            p {
                font-size: 10px;
                font-weight: normal;
                color: #dfdfe3; 
            }

        }

        select {
            margin-top: 10px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
    }

    .select_box {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .start_hour_box_null {
            width: 49%;
        }
        .start_minute_box_null {
            width: 49%;
        }
        .start_hour_box {
            width: 28%;
        }
        .start_minute_box {
            width: 19%;
        }
        .time_arrange_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4%;
        }
        
        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        height: 50px;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .return_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        height: 50px;
        background: #F45552;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .option_select_box {
        margin-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 50px;
        border : 1px solid #617BE3;
        border-radius: 4px;
        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .option_select_box:hover {
        background : #617BE3;

        p {
            color: #fff;
        }
    }

    .limit_select_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .delete_selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2%;
            width: 20%;
            height: 50px;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #DD1212;
                text-decoration: underline;
            }
        }

        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }

    .delete_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DD1212;
        border-radius: 5px;
        cursor: pointer;
        height: 30px;
        width: 70px;
        font-size: 0.8rem;
        font-weight: 400;
        color: #DD1212;
    }

    .delete_box:hover {
        background: #DD1212;
        color: #fff
    }
`

const Mstyle = styled.div`
        
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }   

    .stockPage {
        background-color: #f1f1f1;
        padding-bottom: 5%;
    }

    .tapPage {
        background-color: #F1F3F7;
        padding-bottom: 8px;
    }

    .menu_container {
        background: #fff;
        height: 10vh;

        .tab {
            display: flex;
            align-items: center;
            height: 10vh;
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    .totalPage {
        width: 100%;
        background-color: #fff;
        height: 35vh;

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;

            h2 {
                margin-left: 5%;
                font-size: 0.9rem;
                font-weight: 400;
            }
        }

        .menuContent {
            height: 30vh;
            
            .menu {
                display: flex;
                align-items: center;
                padding: 5% 5% 5% 5%;

                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 11vw;
                    height: 5vh;
                    margin-right: 16px;
                    border-radius: 4px;
                    border : 1px solid #dfdfdf;

                    .box_image {
                        border-radius: 5%;
                        width: 11vw;
                        height: 5vh;
                    }
                }

                .stamp_edit_box {
                    display: flex;
                    height: 100%;
                    width: 70%;
                    justify-content: flex-end;
                    align-items: center;
                    
                    .sold_out {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 4vh;
                        width: 20vw;
                        background : #6A7684;
                        border-radius: 5px;
                        margin-right: 2vh;

                        p {
                            font-size: 0.8rem;
                            font-weight: 600;
                            color: #fff;
                        }
                    }
                    
                    .state_onchange_box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 4vh;
                        width: 20vw;
                        background : #E8EFFC;
                        border-radius: 5px;

                        p {
                            font-size: 0.8rem;
                            font-weight: 600;
                            color: #6490E7;
                        }
                    }
                    
                    .state_change_box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 4vh;
                        width: 23vw;
                        background : #EF4452;
                        border-radius: 5px;

                        p {
                            font-size: 0.8rem;
                            font-weight: 600;
                            color: #fff;
                        }
                    }
                }

                .title {
                    display: flex;
                    height: 100%;
                    width: 30%;
                    align-items: center;

                    h3 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
                
            }
        }

        .content_detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 2%;
            margin-left: 5%;
            margin-right: 5%;

            .title {
                p {
                    color: #4E5867;
                    font-size: 0.8rem;
                    font-weight: 500;
                }
            }
            .data {
                p {
                    font-size: 0.9rem;
                    font-weight: 600;
                }
            }
        }

        .stock {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 16vw;
            height: 6vh;
            border-radius: 5%;
            background: #617be3;
            margin-left: auto;
            cursor: pointer;
            transition: 0.3s;

            h2 {
                padding: 0;
                font-size: 0.7rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }
    
    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .category_wrapper {
            margin-top:2%;
            display : inline-block;
            margin-right:5%;
            .category_label {
                text-align: center;
                margin-bottom:5%;
                .category_checkbox {
                    align-self:center;
                    width:auto;
                    margin-right: 0.3rem;
                }
            }
        }
        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 2px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 78px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }

        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .buisness, .number {
        padding: 20px 16px;
        margin-bottom: 8px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .tip span {
        display: none;
        position: absolute;
        top: 27vh;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .businessTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .last {
        width: 100%;
        padding: 30px 0px 0px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  

    .modify {
        width: 100%;
        padding: 30px 0px 30px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #EF4452;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  
    .location_box_line {
        margin-top: 5%;
        
        .how_box {
            display: flex;
            align-items: center;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 500;
                color: #666666;
            }
        }
    }`