import React, { useState, useRef } from "react";
import SVG from 'react-inlinesvg';
import "./Accordion.css";
import moreBtn from '../../../../assets/svg/moreBtn.svg'
import closeBtn from '../../../../assets/svg/closeBtn.svg'


function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(setActive === "active" ? "0px" : `${content.current.scrollHeight}px`);
  }

  return (
    <div className="accordionSection">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <div className="aTitle">
          <p className="accordionTitle">{props.title}</p>
          {setActive === "" ?
            <SVG src={moreBtn} width="10px" height="17px" />
            :
            <SVG src={closeBtn} width="10px" height="17px" />
          }

        </div>

        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordionContent">
          <div
            className="accordionText"
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </div>
      </button>

    </div>
  )
}

export default Accordion
