import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import ReactDragListView from "react-drag-listview";
import { Table, message, Popconfirm, Modal } from 'antd';
import "antd/dist/antd.css";

import addressSearch from '../../../../assets/svg/search_black.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';
import searchBlack from '../../../../assets/svg/search_black.svg';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import InsertPage from './InsertForm';
import EditPage from './EditForm';

import { ModalCategoryPage, GuideCategoryList } from '../../../modal/AppModal';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';

const Category = ({ oProps }) => {
    const [isLoading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);

    const [iStatus, setStatus] = useState("");

    const [hasList, setHasList] = useState("none");
    const [iLimit, setLimit] = useState(false);

    const [sMainId, setMainId] = useState(0);

    const [sData, setData] = useState({});
    const [sCategoryList, setCategoryList] = useState([]);
    const [sCategoryColumns] = useState([
        {
            title: "순서",
            key: "key",
            render: (text, record, index) => <a className="drag-handle">{text.key}</a>
        },
        {
            title: "분류명",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "사용",
            dataIndex: "useYn",
            key: "useYn"
        },
        {
            title: "대표메뉴",
            dataIndex: "isMain",
            key: "isMain"
        },
        {
            title: "설정",
            key: "action",
            render: (text, record, index) => <div className="detail_box" onClick={() => editRow(text)}><p>수정</p></div>
        },
        {
            title: "삭제",
            key: "action",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => showModal(text.id)}><p>삭제</p></div>
        }
    ]);

    const categoryId = useRef(0);
    const [modal, contextHolder] = Modal.useModal();

    const showModal = (sIndex) => {
        categoryId.current = sIndex;
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const editRow = (sIndex) => {
        setData(sIndex);
        setStatus("edit");
    }

    const changeNormal = () => {
        setStatus("new");
    }

    const dragProps = (fromIndex, toIndex) => {
        let tempColumns = sCategoryList;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        changeRow(columnsCopy);
    }

    const editCategoryList = async (sIndex) => {
        const storeId = oProps.UserConfigReducer.StoreID;
        const oData = {
            sContent: sIndex.sNm,
            isMain: sIndex.isMain,
            isUse: sIndex.selectValue,
            is_alcohol: sIndex.is_alcohol,
            menuId: sIndex.sId,
            storeId
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/editCategoryList", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse) {
                
                message.info("변경되었습니다");
            } else {
                message.error("변경에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        setStatus("");
        await getList();
    }

    const addList = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const storeName = oProps.UserConfigReducer.StoreName;
        const oData = {
            sContent: sIndex.sNm,
            isMain: sIndex.isMain,
            isUse: sIndex.isUse,
            menuId: sIndex.menuId,
            isConfirmed: sIndex.checkValue,
            sCategoryList,
            storeName,
            store_id,
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/insertCategory", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse.resultCd === "0000") {
                message.info("등록되었습니다");
            } else {
                message.error(oResponse.resultMsg);
            }
        }
        await getList();
    }

    const alcoholList = async (pw) => {
        try {
            const oParams = {
                password: pw,
                store_id: oProps.UserConfigReducer.StoreID,
            }
            const oResponse = await oProps.oManager.fDoAxios("/authenticate/pass", "post", null, oParams);

            return oResponse;

        } catch (err) {
            console.log("err-->", err);
        }
    }

    const deleteRow = async () => {
        const storeId = oProps.UserConfigReducer.StoreID;
        const oData = {
            category_id: categoryId.current,
            storeId
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/deleteCategory/v2", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse) {
                message.info("삭제되었습니다");
                setVisible(false);
            } else {
                message.error("삭제에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        setStatus("");
        await getList();
    }

    const changeRow = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oData = {
            sIndex,
            store_id,
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/changeIndexCategory", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse.resultCd === "9999") {
                message.error("변경에 실패했습니다 잠시 후 다시 시도바랍니다");
            } else if (oResponse.resultCd === "0000") {
                message.info("변경되었습니다");
            }
        }
        await getList();
    }
    const ChangeRowMob = async (sIndex, fIndex) => {
        if ((sIndex === 0 && fIndex === -1) || (sIndex === sCategoryList.length - 1 && fIndex === 1)) {
            return;
        }
        let tempColumns = sCategoryList;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(sIndex, 1)[0];
        columnsCopy.splice(sIndex + fIndex, 0, item);
        changeRow(columnsCopy);
    }

    const getList = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/categoryList/v2-" + store_id, "get", "login", null);
        if (oResponse !== undefined) {
            if (oResponse.noList !== "none") {
                setCategoryList(oResponse.sResult);
                setHasList(true);
            }
            setMainId(oResponse.mainId);
            setHasList(oResponse.noList);
            setLimit(oResponse.sLimit);
        }
        setLoading(false);
    }

    const modalOpen = {
        content: (
            <ModalCategoryPage sProps={oProps} />
        )
    };

    const modalOpenGuide = {
        width: "50vw",
        content: (
            <GuideCategoryList sProps={oProps} />
        )
    };

    useEffect(() => {
        getList();
    }, [])

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.product}>
            <BrowserView>
                <Style>
                    {iStatus !== "edit" ?
                        <InsertPage
                            sHasList={hasList}
                            sLimit={iLimit}
                            fnAddList={(sIndex) => addList(sIndex)}
                            alcoholList={alcoholList}

                        />
                        :
                        <EditPage
                            alcoholList={alcoholList}
                            iMainId={sMainId}
                            iProps={oProps}
                            iDetail={sData}
                            sLimit={iLimit}
                            fnChangeNormal={() => changeNormal()}
                            fnEditCategoryList={(sIndex) => editCategoryList(sIndex)}
                        />
                    }
                    <div className="container">
                        {isLoading ?
                            <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                            :
                            <div className="table_wrapper">
                                <ReactDragListView.DragColumn
                                    onDragEnd={dragProps}
                                    handleSelector="a"
                                >
                                    <Table columns={sCategoryColumns} dataSource={sCategoryList} pagination={false} />
                                </ReactDragListView.DragColumn>
                                <div className="location_box_line" style={{ marginTop: "10px" }}>
                                    <div className="how_box">
                                        <p>분류의 순서 변경을 원하시면 순서의 숫자를 잡고 드래그 해주세요!</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="side_help_content">
                        <div className="app_modal_container" onClick={() => modal.success(modalOpen)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={addressSearch} alt="none" />
                                    <p>앱 미리보기</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>내 입력정보의 예상화면을 <br />미리 확인해보세요</p>
                                </div>
                            </div>
                        </div>
                        <div className="app_guide_modal_container" onClick={() => modal.success(modalOpenGuide)}>
                            <div className="arrow_box">
                                <img src={guide_arrow} alt="none" />
                            </div>
                            <div className="text_box">
                                <div className="title_box">
                                    <img className='help_content_img' src={guide_question} alt="none" />
                                    <p>간편도움말</p>
                                </div>
                                <div className="detail_box_line">
                                    <p>입력예시를 통해 <br />입력방법을 알려드립니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        title="주의사항"
                        visible={visible}
                        onOk={deleteRow}
                        onCancel={hideModal}
                        okText="네"
                        cancelText="아니오"
                    >
                        <p>해당 상품분류를 사용하지 않는 경우에는 수정 {"->"} 사용여부에서 '미사용'으로 변경해주세요</p>
                        <p>만약 분류 삭제시 해당분류에 등록된 상품들도 같이 삭제됩니다</p>
                        <p>이 상품분류를 삭제하시겠습니까?</p>
                    </Modal>
                    {contextHolder}
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    {iStatus !== "edit" ?
                        <InsertPage
                            sHasList={hasList}
                            sLimit={iLimit}
                            fnAddList={(sIndex) => addList(sIndex)}
                            alcoholList={alcoholList}
                        />
                        :
                        <EditPage
                            iMainId={sMainId}
                            iProps={oProps}
                            iDetail={sData}
                            sLimit={iLimit}
                            fnChangeNormal={() => changeNormal()}
                            fnEditCategoryList={(sIndex) => editCategoryList(sIndex)}
                            fnAddList={(sIndex) => addList(sIndex)}
                            alcoholList={alcoholList}
                        />
                    }
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <>
                            {sCategoryList.map((item, index) => {
                                return (
                                    <div key={"listing-" + index.toString()} className="category_content_line">
                                        <div className="table_box">
                                            <div className="table_content">
                                                <div className="title">
                                                    <p>{item.name}</p>
                                                </div>
                                                <div className="content">
                                                    {item.isMain === "예" &&
                                                        <div className="main_title">
                                                            <p>대표메뉴</p>
                                                        </div>
                                                    }
                                                    <p>{item.useYn}</p>
                                                </div>
                                            </div>
                                            <div className='change_order' onClick={() => ChangeRowMob(index, -1)}>
                                                <ArrowUpOutlined />
                                            </div>
                                            <div className='change_order' onClick={() => ChangeRowMob(index, 1)}>
                                                <ArrowDownOutlined />
                                            </div>
                                            <div className="table_action">
                                                <div className="edit_box" onClick={() => editRow(item)}>
                                                    <p>수정</p>
                                                </div>
                                                <div className="delete_box" onClick={() => showModal(item.id)}>
                                                    <p>삭제</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    }
                    <div className="app_modal_container">
                        <div className="app_modal" onClick={() => modal.success(modalOpen)}>
                            <p>앱 미리보기</p>
                            <img src={searchBlack} alt="search" style={{ marginLeft: "5%" }} />
                        </div>
                        <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
                    </div>
                    <Modal
                        title="주의사항"
                        visible={visible}
                        onOk={deleteRow}
                        onCancel={hideModal}
                        okText="네"
                        cancelText="아니오"
                    >
                        <p>해당 상품분류를 사용하지 않는 경우에는 수정 {"->"} 사용여부에서 '미사용'으로 변경해주세요</p>
                        <p>만약 분류 삭제시 해당분류에 등록된 상품들도 같이 삭제됩니다</p>
                        <p>이 상품분류를 삭제하시겠습니까?</p>
                    </Modal>
                    {contextHolder}
                </Mstyle>

            </MobileView>
        </Layout>
    )
}

export default Category;

const Style = styled.div`
    position: relative;
    width: 42vw;

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    } 

    .location_box_line {
        align-items: center;
        height: 50px;
        margin-bottom: 20px;
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }


    /* 앱미리보기/간편도움말 공통css */
    .side_help_content {
        position: absolute;
        left: 103%;
        top: 0;
    }
    .app_guide_modal_container, .app_modal_container {
        padding: 13px 13px 40px 13px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #FBFBFB;
        border: 1px solid #DFDFE3;
        border-radius: 8px;
        
        .arrow_box img {
            width: 5px;
        }
        .text_box {
            margin-left: 5px;
            min-width: 220px;

            .title_box {
                height: auto;
                margin-bottom: 9px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                }
            }
            .help_content_img {
                width: 18px;
            }
        }
    }

    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 16px;
            font-weight: 700;
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 5vw;
            border: 1px solid #DD1212;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #DD1212;
            }
        }

        .new_category_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 5vw;
            background: #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box:hover {
            border: none;
            background: #DD1212;
            p {
                color: #fff;
            }
        }
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        height: 50px;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .table_wrapper {
    }

    .category_title {
        height: 70px;
        margin-bottom: 5%;

        input {
            margin-top: 10px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 10px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 1px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        width: 42vw; 
    }

    .delete_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3vw;
        height: 3vh;
        border: 1px solid #DD1212;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #DD1212;
        }
    }

    .detail_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3vw;
        height: 3vh;
        background:  #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #fff;
        }
    }
`




const Mstyle = styled.div`

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            .detail_line {
                margin-left: 5px;
                font-size: 0.6rem;
                font-weight: 700;
                color: #617BE3;
            }

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .change_order {
            path {
                fill: black!important
            }
        }
        .table_content {
            width: 45%;
            height: 7vh;

            .title {
                display: flex;
                height: 3.5vh;
                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                }
            }

            .content {
                display: flex;
                height: 3.5vh;

                .main_title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 17vw;
                    height: 3vh;
                    background: #001E62;
                    border-radius: 5px;
                    margin-right: 5%;

                    p {
                        font-size: 0.7rem;
                        font-weight: 400;
                        color: #fff;
                    }
                }
                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                }
            }
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;

            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }
    }
    
    .last {
        width: 100%;
        padding: 24px 0px 32px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  

`


