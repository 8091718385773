import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Pagination, message, Modal } from 'antd';
import "antd/dist/antd.css";

import EditPage from '../../manage/product/Category/EditForm';
import InsertForm from '../../manage/product/Category/InsertForm';

import searchBlack from '../../../assets/svg/search_black.svg';

import { ModalCategoryPage } from '../../modal/AppModal';

const Option = ({ oProps }) => {
    const [isLoading, setLoading] = useState(true);
    const [sCurrent, setCurrent] = useState(1);
    const [iStatus, setStatus] = useState("");

    const [noDataText] = useState("데이터가 없습니다.");

    const [iList, setIList] = useState([]);

    const [startPage, setStartPage] = useState(0);
    const [endPage, setEndPage] = useState(5);

    const [sOptionList, setOptionList] = useState({});
    const [sOptionId, setOptionId] = useState(0);

    const [modal, contextHolder] = Modal.useModal();

    const changeNormal = () => {
        setStatus("");
    }

    const onChangePage = page => {
        let tempStart = 0;
        let tempEnd = 5;
        
        if(parseInt(page) !== 1){
            tempEnd = tempEnd * page;
            tempStart = tempEnd - 5;
        }
        setStartPage(tempStart);
        setEndPage(tempEnd);
        setCurrent(page);
    };

    const registerOption = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        let oData = {
            sGroupTitle : sIndex.sNm,
            type : sIndex.type,
            sData : sIndex.sData,
            maxCount : 1,
            minCount : 1,
            store_id,
        }

        if(sIndex.type === "checkbox"){
            oData = {
                sGroupTitle : sIndex.sNm,
                type : sIndex.type,
                sData : sIndex.sData,
                maxCount : sIndex.sMax,
                store_id,
            }
        } else {
            if(parseInt(sIndex.sMin) > 1){
                oData = {
                    sGroupTitle : sIndex.sNm,
                    type : "radioDouble",
                    sData : sIndex.sData,
                    maxCount : sIndex.sMin,
                    minCount : sIndex.sMin,
                    store_id,
                }
            }
        }
        
        const oResponse = await oProps.oManager.fDoAxios("/store/register/insertOption/v2", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse.resultCd === "0000"){
                message.info("등록되었습니다");
            } else {
                message.error(oResponse.resultMsg);
            }
        }
        setLoading(true);
        await getList();
    }

    const detailRow = async (sIndex) => {
        const option_id = sIndex.id;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/detailOptionRow-" + option_id, "get", "login", null);
        if(oResponse !== undefined){
            if(oResponse.resultCd === "0000"){
                setOptionList(oResponse.resultData);
                setOptionId(option_id);
                setStatus("edit");
            } else {
                message.error(oResponse.resultMsg);
            }
        }
    }

    const deleteRow = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oData = {
            sIndex,
            store_id
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/deleteOption", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse){
                message.info("삭제되었습니다");
            } else {
                message.error("삭제에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        setLoading(true);
        await getList();
    }

    const editOption = async (sIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        let oData = {
            sGroupTitle : sIndex.sNm,
            option_id : sIndex.sOptionId,
            sData : sIndex.sData,
            store_id
        }

        if(sIndex.type === "checkbox"){
            oData = {
                sGroupTitle : sIndex.sNm,
                option_id : sIndex.sOptionId,
                sData : sIndex.sData,
                maxCount : sIndex.sMax,
                store_id,
            }
        } else {
            if(parseInt(sIndex.sMin) > 1){
                oData = {
                    sGroupTitle : sIndex.sNm,
                    option_id : sIndex.sOptionId,
                    sData : sIndex.sData,
                    minCount : sIndex.sMin,
                    store_id,
                }
            }
        }

        const oResponse = await oProps.oManager.fDoAxios("/store/register/editOption/v2", "post", "login", oData);
        if(oResponse !== undefined){
            if(oResponse.resultCd === "0000"){
                message.info("변경되었습니다");
                setLoading(true);
                await getList();
            } else {
                message.error(oResponse.resultMsg);
            }
        }
    }


    const getList = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/optionList-" + store_id, "get", "login", null);
        if(oResponse !== undefined){
            setIList(oResponse);
        }
        setLoading(false);
    }

    const modalOpen = {
        content: (
            <ModalCategoryPage sProps={oProps} />
        )
    };

    useEffect(() => {
        getList();
    },[])

    return (
        <Style>
            {iStatus !== "edit" ?
                <InsertForm 
                    fAddList={(sIndex) => registerOption(sIndex)}
                />
                :
                <EditPage 
                    fnChangeNormal={() => changeNormal()}
                    fEditList={(sIndex) => editOption(sIndex)}
                    iOptionList={sOptionList}
                    iOptionId={sOptionId}
                />
            }
            {!isLoading ?
                <>
                    {iList.length > 0 ?
                        <>
                        {iList.slice(startPage,endPage).map((item,index) => {
                            return(
                                <div className="category_content_line" key={"listing-" + index.toString()}>
                                    <div className="table_box">
                                        <div className="table_content">
                                            <p>{item.name}</p>
                                        </div>
                                        <div className="table_action">
                                            <div className="edit_box" onClick={() => detailRow(item)}>
                                                <p>수정</p>
                                            </div>
                                            <div className="delete_box" onClick={() => deleteRow(item)}>
                                                <p>삭제</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </>
                    :
                        <div className="page_nation">
                            <p>{noDataText}</p>
                        </div>
                    }
                    <div className="page_nation">
                        <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={iList.length} />
                    </div>
                </>
                :
                <div style={{display: "flex", justifyContent: "center", height: "30vh",alignItems: "center"}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
            }
            <div className="app_modal_container">
                <div className="app_modal" onClick={() => modal.success(modalOpen)}>
                    <p>앱 미리보기</p>
                    <img src={searchBlack} alt="search" style={{marginLeft: "5%"}}/>
                </div>
                <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
            </div>
            {contextHolder}
        </Style>
    )
}

export default Option;

const Style = styled.div`

    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    .insert_option_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15%;
        margin-bottom: 5%;
        height: 50px;
        cursor: pointer;
        border : 1px solid #617BE3;
        border-radius: 4px;

        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .page_nation {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 9vh;
        background: #fff;
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            .detail_line {
                margin-left: 5px;
                font-size: 0.6rem;
                font-weight: 700;
                color: #617BE3;
            }

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        margin-bottom: 1px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table_content {
            display: flex;
            align-items: center;
            width: 60%;
            height: 7vh;

            p {
                font-size: 0.9rem;
                font-weight: 700;
            }
            
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;

            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }

    .essential_box {
        height: 70px;
        margin-top: 3%;
        margin-bottom: 10%;

        .select_line_box {
            margin-top: 2%;
            display: flex;


            .selected_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 50px;
                background: #617BE3;
                border-radius: 5px;
                cursor: pointer;
                

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .unselect_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 50px;
                border: 1px solid #617BE3;
                border-radius: 5px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }

            .disable_wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 50px;
                border: 1px solid #BBBBBB;
                border-radius: 5px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #bbbbbb;
                }
            }

        }
    }

    .option_list {
        margin-bottom: 10%;
        margin-top: 5%;

        .pay_box {
            position: absolute;
            right: 22%;
            top: 30%;
        }

        .option_list_box {
            height: 7vh;
            margin-top: 5%;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .option_name_list {
                height: 6vh;
                padding: 12px;
                width: 45%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
            }
            .option_price_list {
                height: 6vh;
                padding: 12px;
                width: 33%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
            }
            
            .option_delete_box {
                height: 6vh;
                padding: 12px;
                width: 17%;

                p {
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: #dd1212;
                    text-decoration: underline;
                }
            }

        }
    }

    .option_box {
        height: 7vh;
        margin-bottom: 10%;
        margin-top: 5%;

        .pay_box {
            position: absolute;
            right: 5%;
            top: 36%;
        }

        .option_input {
            position: relative;
            display: flex;

            input {
                margin-top: 2%;
                padding: 12px;
                width: 100%;
                border: 1px solid #BBBBBB;
                border-radius: 4px;
                outline: none;
            }

            .input_action {
                border: solid 1px #000000;
                transition: 0.1s ease-out;
            }

            .input_wrong {
                border: solid 1px #DD1212;
                transition: 0.1s ease-out;
            }

            input::placeholder {
                font-size: 0.9rem;
                font-weight: 400;
                color: #BBBBBB;
            }
        }
    }

    .inputText {
        height: 70px;
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            margin-top: 2%;
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }
    }
    
    .last {
        width: 100%;
        padding: 0px 0px 24px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  

`


