import React from 'react';
import { Pie } from '@ant-design/charts';

interface ConfigData {
  sChart: any;
}

const PieChart: React.FC<ConfigData> = props => {
  
  return <Pie {...props.sChart} />;
};

export default PieChart;


