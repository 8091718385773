import React, {useState} from 'react';
import { Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";
import 'antd/dist/antd.css';


import { WebRoute } from '../../navigation/WebRoutes';

const { Paragraph, Text } = Typography;

const ConnectionFailed = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    const login = () => {
        navigate(WebRoute.LOGIN);
    }

    return (
        <React.Fragment>
            <Row className="justify-content-md-center" style={{marginTop: "10%"}}>
              <Col md="6">
                <Result
                    status="warning"
                    title="문제가 발생했습니다"
                    subTitle="잠시 후 다시 시도해주세요"
                    extra={[
                        <Button type="primary" key="console" onClick={() => goBack()}>돌아가기</Button>,
                        <Button key="login" onClick={() => login()}>로그인</Button>
                    ]}
                >
                    <div className="desc">
                        <Paragraph>
                        <Text strong style={{fontSize: 16}}>
                            다음과 같은 이유로 인하여 발생했습니다
                        </Text>
                        </Paragraph>
                        <Paragraph>
                            <CloseCircleOutlined className="site-result-demo-error-icon" style={{color: "red"}}/> 통신에 장애가 발생했습니다
                        </Paragraph>
                        <Paragraph>
                            <CloseCircleOutlined className="site-result-demo-error-icon" style={{color: "red"}}/> 네트워크에러입니다 잠시 후 다시 시도바랍니다
                        </Paragraph>
                        <Paragraph>
                            <CloseCircleOutlined className="site-result-demo-error-icon" style={{color: "red"}}/> 로그인 기간이 만료되었습니다
                        </Paragraph>
                    </div>
                </Result>
              </Col>
            </Row>
        </React.Fragment>
    )
};

export default ConnectionFailed;