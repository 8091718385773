import React, { useState, useEffect, Fragment } from 'react';
import { Route, Routes, BrowserRouter, useNavigate, unstable_HistoryRouter as Router } from "react-router-dom";
import { connect } from 'react-redux';

import { WebRoute } from './WebRoutes';
import { setUserConfig } from "../reducers/UserConfigReducer";
import { setAppConfigStatus } from "../reducers/AppConfigReducer";

import moment from 'moment-timezone';
import 'moment/locale/ko';

import ScrollToTop from "../utils/ScrollToTop";

import HeaderPage from "../components/header/MainHeader";
import FooterPage from "../components/footer/MainFooter";

import HomeScreen from "../screens/home/main/HomeScreen";
import NoticePage from "../screens/home/notice/MainNoticePage";
import PromotionPage from '../screens/home/promotion/mainPromotion';
import PosDownloadPage from "../screens/home/posDown/MainPosDownload";
import ServiceCenter from "../screens/home/service/mainSeviceCenter";
import CeoLanding from '../screens/home/landing/MainLanding';
import JoinForm from '../screens/home/joinForm/MainJoinForm';
import CeoGuide from '../screens/home/ceoGuide/MainCeoGuide';
import CeoTip from '../screens/home/ceoTip/MainCeoTip';
import SubCeoTip from '../screens/home/ceoTip/SubCeoTip';
import PosGuide from '../screens/home/ceoGuide/noticeTab/MainPosNotice';

import PosPageItem from '../screens/manager/pos/PosPageItem';
import Inventory from '../components/pos/Inventory/Inventory';
import Product from '../components/pos/menu/Main';
import Payment from '../components/pos/payment/Main';
import CommercialChart from '../components/manage/app/CommercialChart';
import AppSidebar from '../components/manage/app/AppSidebar';

import AppConnect from '../screens/etc/AppConnect';
import UserLocation from '../screens/etc/UserLocation';

import ErrorScreen from "../screens/error/Error";
import ConnectionFailed from "../screens/error/ConnectionFailed";

import SalesAuthenticate from "../screens/authenticate/SalesAuthenticate";
import StoreAuthenticate from "../screens/authenticate/StoreAuthenticate";

import BehindInventory from "../components/kiosk/inventory";


import ManageRoutes from './ManageRoutes';
import { createBrowserHistory } from 'history';

const RootRoutes = (oProps) => {
   const [state, setState] = useState(true);
   const [mode, setMode] = useState(false);
   const history = createBrowserHistory();
   const navigate = useNavigate()

   const queryString = window.location.search;
   const urlParams = new URLSearchParams(queryString);
   const sUrlPath = window.location.pathname;

   const asyncPath = async () => {
      const checkTokenRefresh = await signInWithTokenResfreshToken();
      if (checkTokenRefresh === true) {
         setMode(true);
         navigate(sUrlPath);
         return;
      }

      if (window.location.pathname.indexOf('/selfmanage/pos/item') !== -1) {
         //const navigate = useNavigate();
         //navigate(WebRoute.ADMIN_PRODUCT_PRODUCT);
      }
      let temp = false;
      for (const sPath of Object.values(WebRoute)) {
         let locationName = window.location.pathname;
         let tempPath = sPath.toString();
         if (locationName.substring(1, 11) === "selfmanage") {
            setMode(true);
         } 
         else if (locationName.substring(1, 7) === "behind") {
            setMode(true);
         } 
         else {
            setMode(false);
         }
         if (tempPath === window.location.pathname) {
            temp = true;
         }
      }
      setState(temp);
   };

   const signInWithTokenResfreshToken = async () => {

      return new Promise(async (resolve, reject) => {
         console.log('queryString', queryString);
         console.log('urlParams', urlParams);

         if (urlParams.has('wpos_token') && urlParams.has('wpos_refresh_token')) {
            const sWinPosUserToken = urlParams.get('wpos_token');
            const sWinPosUserRefreshToken = urlParams.get('wpos_refresh_token');

            const oData = {
               token: sWinPosUserToken,
               refresh_token: sWinPosUserRefreshToken
            }

            const oResponse = await oProps.oManager.fDoAxios("/authenticate/token", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {

               let oUserConfig = {};
               if (oResponse.result_cd === "0000") {

                  const oUserData = oResponse.result_cnt;
                  console.log('oUserData >>>>>>>>>>>>>> ', oUserData);
                  oUserConfig['UserId'] = oUserData.user_id;
                  oUserConfig['isPOS'] = false;
                  oUserConfig['isPOSStore'] = false;
                  oUserConfig['RememberId'] = true;
                  oUserConfig['LoggedIn'] = true;
                  oUserConfig['Token'] = oUserData.token;
                  oUserConfig['RefreshToken'] = oUserData.refresh_token;
                  oUserConfig['UUID'] = oUserData.uuid;

                  oUserConfig['Commercial'] = false;
                  oUserConfig['StorePause'] = oUserData.pause;
                  oUserConfig['StoreID'] = oUserData.store_id;
                  oUserConfig['StoreName'] = oUserData.store_name;
                  oUserConfig['StorePhone'] = oUserData.store_phone_number;
                  oUserConfig['StoreOwner'] = oUserData.store_merc_full_name;
                  oUserConfig['Address'] = oUserData.store_address1;
                  oUserConfig['StoreLat'] = oUserData.store_lat;
                  oUserConfig['StoreLng'] = oUserData.store_lng;

                  oUserConfig['SalesAutoLogin'] = false;
                  oUserConfig['SalesLoggedIn'] = false;
                  oUserConfig['SalesLoginTime'] = "";
                  oUserConfig['SalesRefreshToken'] = "";
                  oUserConfig['SalesToken'] = "";
                  oUserConfig['SalesId'] = 0;
                  oUserConfig['SalesGroupId'] = 0;
                  oUserConfig['SalesEmail'] = "";
                  oUserConfig['SalesKey'] = "";
                  oUserConfig['SalesUserName'] = "";
                  oUserConfig['SalesUserType'] = "";
                  oUserConfig['SalesStoreList'] = [];

                  oUserConfig['accessible'] = oUserData.status;
                  oUserConfig['LoginTime'] = moment().add(1, "days").format('YYYY-MM-DD');
                  await oProps.reduxSetUserConfig(oUserConfig);
                  resolve(true);
               }
            }
         }
         resolve(false);
      });
   }

   useEffect(() => {
      asyncPath();
   }, [window.location.pathname]);

   // const [isHide, setHide] = useState(false);

   // const pathConnect = async () => {
   //    if (window.location.pathname === "/behind" !== -1) {
   //       setHide(true);
   //    } else {
   //       setHide(false);
   //    }
   // };

   // useEffect(() => {
   //    pathConnect();
   // }, []);


   return (
      <Fragment>
         <ScrollToTop />
         {!mode && <HeaderPage {...oProps} />}
         <Routes>
            {/* CEOPAGE */}
            <Route exact path={WebRoute.HOME} element={<HomeScreen oProps={oProps} />} />
            <Route exact path={WebRoute.PROMOTION} element={<PromotionPage oProps={oProps} />} />
            <Route exact path={WebRoute.NOTICE} element={<NoticePage oProps={oProps} />} />
            <Route exact path={WebRoute.POSGUIDE} element={<PosGuide oProps={oProps} />} />
            <Route exact path={WebRoute.DOWNLOAD} element={<PosDownloadPage oProps={oProps} />} />
            <Route exact path={WebRoute.SERVICE} element={<ServiceCenter oProps={oProps} />} />
            <Route path={WebRoute.LANDING} element={<CeoLanding oProps={oProps} />} />
            <Route exact path={WebRoute.JOINFORM} element={<JoinForm oProps={oProps} />} />
            <Route exact path={WebRoute.CEOGUIDE} element={<CeoGuide oProps={oProps} />} />
            <Route exact path={WebRoute.CEOTIP} element={<CeoTip oProps={oProps} />} />
            <Route exact path={WebRoute.CEOTIPSUB} element={<SubCeoTip oProps={oProps} />} />

            {/* SELFMANAGE */}
            {/* <Route exact path={WebRoute.ADMIN_LOGIN} component={AdminHome(oProps)} /> */}
            <Route path="/selfmanage/*" element={<ManageRoutes oProps={oProps} />} />

            {/* AUTHENTICATE */}
            <Route exact path={WebRoute.AUTHENTICATESALES} element={<SalesAuthenticate oProps={oProps} />} />
            <Route exact path={WebRoute.AUTHENTICATESTORE} element={<StoreAuthenticate oProps={oProps} />} />
            <Route exact path={WebRoute.AUTHENTICATEUSER} element={<StoreAuthenticate oProps={oProps} />} />

            {/* POS */}
            <Route exact path={WebRoute.POS} element={<PosPageItem oProps={oProps} />} />
            <Route exact path={WebRoute.POS_INVENTORY} element={<Inventory oProps={oProps} />} />
            <Route exact path={WebRoute.POS_PRODUCT} element={<Product oProps={oProps} />} />
            <Route exact path={WebRoute.POS_PAYMENT} element={<Payment oProps={oProps} />} />
            <Route exact path={WebRoute.POS_USERLOCATION} element={<UserLocation oProps={oProps} />} />

            {/* CEOAPP */}
            <Route exact path={WebRoute.MANAGERAPPCOMERCIAL} element={<CommercialChart oProps={oProps} />} />
            <Route exact path={WebRoute.APP_SIDEBAR} element={<AppSidebar oProps={oProps} />} />

            {/* SALESAPP */}
            <Route exact path={WebRoute.SALESAPP} element={<AppConnect oProps={oProps} />} />

            {/* CONNECTIONFAIL */}
            <Route exact path={WebRoute.FAIL} element={<ConnectionFailed />} />
            <Route path="*" element={<ErrorScreen {...oProps} />} />

            {/* behind_kiosk */}
            <Route path={WebRoute.BEHINDINVENTORY} element={<BehindInventory oProps={oProps} />} />
         </Routes>
         {!mode && <FooterPage {...oProps} />}
      </Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      AppConfigReducer: state.AppConfigReducer,
      UserConfigReducer: state.UserConfigReducer
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      reduxSetAppConfigStatus: (oData) => dispatch(setAppConfigStatus(oData)),
      reduxSetUserConfig: (oData) => dispatch(setUserConfig(oData))
   };
};

// const BehindKioskRoutes = (oProps) => {
//    const [isHide, setHide] = useState(false);

//    const pathConnect = async () => {
//       if (window.location.pathname === "/behind") {
//          setHide(true);
//       }
//    };
//    // console.log('111111111',pathConnect)

//    useEffect(() => {
//       pathConnect();
//    }, []);

//    return (
//       <Fragment>
//          <Routes>
//             <Route exact path={WebRoute.BEHIND + '/inventory'} element={<BehindInventory oProps={oProps} />} />
//             {/* <Route path={WebRoute.FOODIEBOOSTER + '/company'} exact component={() => Companypage(oProps)} /> */}
//             {/* {!isHide && <FooterPage />} */}
//          </Routes>

//       </Fragment>
//    )
// }

export default connect(mapStateToProps, mapDispatchToProps)(RootRoutes);