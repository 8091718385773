export const Types = {
   SETAPPCONFIG: "app/SETAPPCONFIG"
};

// Initial State
const INITIAL_STATE = {
   PermissionRequest: false,
   PermissionLocationRequest: false,
   PermissionInternet: '',
   PermissionCamera: '',
   PermissionVibrate: '',
   PermissionFineLoc: '',
   PermissionCoarseLoc: '',
   PermissionNfc: '',
   PermissionBluetooth: '',
   PermissionReadStorage: '',
   PermissionReadContacts: '',
   PermissionReadSms: '',
   PermissionReadPhoneState: '',
   PermissionCallPhone: '',
   InstructionPageShown: false,
   FirstPageRoute: '',

   Env: {},
   StoresLatLng: {},
   MapPolygon: {}
};

export function setAppConfigStatus(oResult) {
   return {
      type: Types.SETAPPCONFIG,
      payload: {
         result: oResult
      }
   };
}

// Reducer
export default function reducer(state = INITIAL_STATE, action) {
   switch (action.type) {
      case Types.SETAPPCONFIG: {
         let oMerged = { ...state, ...action.payload.result };
         return oMerged;
      }
      default: return state;
   }
}