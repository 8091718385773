import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import Accordion from "../accordion/Accordion";
import "../../../../assets/css/font.css";

const faq = [
  {
    id: 0,
    title: "스루 이용 요금이 어떻게 되나요?",
    text: `2022년 7월 1일 입점 매장부터 월 매출 30만 원 이상이거나 주문건수가 30건 이상일 경우에만 S/W 요금이 부과되며, 한 조건이라도 해당되지 않을 경우 요금이 부과되지 않습니다. 또한 설치비용은 전액 무료입니다.`,
  },
  {
    id: 1,
    title: "스루를 이용하고 싶은데 업종 제한이 있나요?",
    text: `스루는 매장 앞에 잠시 정차만 가능하다면 모든 업종이 가능합니다.
        음식점, 카페뿐만 아니라 꽃 가게, 밀키트, 반찬가게, 편의점 등 다양한 업종들이 드라이브스루 플랫폼을 이용하실 수 있습니다.`,
  },
  {
    id: 2,
    title: "지역, 위치 제한이 있나요?",
    text: `스루는 지역 위치 제한이 없으며 매장 앞에 정차만 가능하다면 어디든 이용 가능합니다.`,
  },
  {
    id: 3,
    title: "주문 누락, 결제 오류 등이 발생하면 어떻게 되나요? ",
    text: `스루의 시스템 오류로 인한 주문 누락, 결제 오류가 발생하셨다면 100% 보상해드립니다.
        단, 정상 주문/결제 후 고객 이 방문, 판매자 과실, 상품 누락 등은 과실책임자가 상대방에게 변상을 해드립니다.`,
  },
];

const ServiceCenterPage = (oProps) => {
  const openCEOKakao = () => {
    if (isMobile) {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    } else {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    }
  };

  return (
    <TotalStyle>
      <Service>
        <div className="sContent">
          <div className="container">
            <h2>
              스루 사용 중<br />
              문제가 발생하셨나요?
            </h2>
            <p>
              사장님 걱정마세요!
              <br />
              시스템의 오류나 장애로 인한 피해라면
              <br />
              반드시 보상해드립니다.
            </p>
            <div className="time">
              <p>평일 09시-18시</p>
            </div>
          </div>
        </div>
      </Service>
      <AccordionStyle>
        <div className="aContent">
          <div className="container">
            <h2>자주 묻는 질문</h2>
            {faq.map((noticeContent) => {
              return (
                <Accordion
                  key={noticeContent.id}
                  title={noticeContent.title}
                  content={noticeContent.text}
                />
              );
            })}
          </div>
        </div>
      </AccordionStyle>
      <div className="floating" style={{ zIndex: "999" }}>
        <div className="topGradation"></div>
        <div className="bottomBtn">
          <div className="pBtn">
            <a href="tel:1670-5324">
              <p>전화 상담</p>
            </a>
          </div>
          <button
            style={{
              border: "none",
              backgroundColor: "#1A7CFF",
              color: "#fff",
            }}
            onClick={openCEOKakao}
          >
            카톡 상담
          </button>
        </div>
      </div>
    </TotalStyle>
  );
};

export default ServiceCenterPage;

export const TotalStyle = styled.div`
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  input,
  label {
    transform: skew(-0.1deg);
    color: #212329;
    font-family: "NanumSquare";
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }
  .container {
    width: 100%;
    padding: 0 5vw;
    margin: 0 auto;
  }

  /* 플로팅버튼 */
  .floating {
    position: fixed;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;

    .topGradation {
      height: 34px;
      width: 100%;
      background: linear-gradient(
        to top,
        rgb(255, 255, 255),
        rgba(255, 255, 255, 0)
      );
      /* background-color: red; */
    }
    .bottomBtn {
      padding: 15px 5vw 20px 5vw;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;

      button,
      .pBtn {
        flex: 0 0 49%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 60px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 900;
        background-color: #fff;
        border: 1px solid #1a7cff;
        color: #fff;
        outline: none;
      }
      p {
        margin: 0;
        color: #1a7cff;
      }
    }
  }
`;
export const Service = styled.div`
  .sContent {
    padding: 124px 0 64px 0;
    background-color: #eef6f3;
    text-align: center;
  }
  h2 {
    font-size: 30px;
    font-weight: 900;
    line-height: 40px;
  }
  p {
    margin: 18px 0 32px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .time {
    margin-top: 32px;

    p {
      margin: 0 auto;
      max-width: 170px;
      padding: 14px 26px;
      font-size: 16px;
      font-weight: 900;
      line-height: 34px;
      background-color: #e3eeef;
      border-radius: 50px;
    }
  }
`;
export const AccordionStyle = styled.div`
  .aContent {
    padding: 61px 0 120px 0;
  }
  h2 {
    margin-bottom: 48px;
    text-align: center;
    font-size: 32px;
    font-weight: 900;
    line-height: 68px;
  }

  /* 제목 */
  .aTitle {
    padding: 22px 16px;
  }
  .accordionSection button.active {
    background-color: #f8f9fa;
  }
  .accordionTitle {
    width: 85%;
    word-break: keep-all;
    line-height: 26px;
  }

  /* 내용 */
  .accordionText {
    padding: 0px 16px 26px 16px;
    font-size: 16px;
    line-height: 28px;
    word-break: break-all;
  }
`;
