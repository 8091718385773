import React, { useState, useEffect, useRef } from 'react';
import Loader from "react-loader-spinner";
import { message } from 'antd';
import "antd/dist/antd.css";

import {
    PcModalCautionCoupon,
} from '../../../modal/CouponModal';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import { WebRoute } from '../../../../navigation/WebRoutes';
import layoutHeaderLists from '../../config';
import {
    ModalCautionCoupon,
} from '../../../modal/CouponModal';
import styled from 'styled-components';
const List = ({ oProps }) => {
    const [loading, setLoading] = useState(true);
    const [cautionModal, setCautionModal] = useState(false);

    const [iList, setList] = useState([]);
    const deleteIndex = useRef(null);

    const cautionClose = async () => {
        setCautionModal(false);
    }

    const showModal = (sIndex) => {
        deleteIndex.current = sIndex.id;
        setCautionModal(true);
    };
  
    const handleOk = async () => {
        const oData = {
            store_id: oProps.UserConfigReducer.StoreID,
            couponId: deleteIndex.current,
        }
        const oResponse = await oProps.oManager.fDoAxios("/coupon/deleteCoupon", "post", "login", oData);
        if(oResponse !== undefined){
            setCautionModal(false);
            asyncData();
        } else {
            message.error("삭제에 실패하였습니다 관리자에 문의바랍니다.");
        }
        
    };

    const asyncData = async () => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oResponse = await oProps.oManager.fDoAxios("/coupon/getData-" + store_id, "get", "login", null);
        if(oResponse !== undefined){
            setList(oResponse);
        }
        setLoading(false);
    }

    useEffect(() => {
        asyncData();
    }, [])
    return (
        <>
        <Layout sProps={oProps} routeList={layoutHeaderLists.coupon}>
            <BrowserView>
            <Style>
            <div className="content_container" style={{ marginBottom: "1vh"}}>
                <div className="title_box">
                    <p>등록된 쿠폰 총 {iList.length}개</p>
                </div>
            </div>
            {!loading ?
                <>
                {iList.map((item,index) => {
                    return(
                        <div className="content_container" style={{ marginBottom: "1%" }} key={index}>
                            <div className="menuContent">
                                <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf"}}>
                                    <div className="title">
                                        <h3>{item.name}</h3>
                                    </div>
                                    <div className="sold_out" onClick={() => showModal(item)}>
                                        <h2>삭제</h2>
                                    </div>
                                </div>
                                {item.type === "amount" ?
                                <>
                                    <div className="content_detail">
                                        <div className="title">
                                            <p>할인금액</p>
                                        </div>
                                        <div className="title">
                                            <p style={{ color: "#000"}}>{item.amount}</p>
                                        </div>
                                    </div>
                                    
                                </>
                                :
                                <>
                                    <div className="content_detail">
                                        <div className="title">
                                            <p>할인률</p>
                                        </div>
                                        <div className="title">
                                            <p style={{ color: "#000"}}>{item.percent} %</p>
                                        </div>
                                    </div>
                                    <div className="content_detail">
                                        <div className="title">
                                            <p>최대할인금액</p>
                                        </div>
                                        <div className="title">
                                            <p style={{ color: "#000"}}>{item.maxlimitAmount}</p>
                                        </div>
                                    </div>
                                </>
                                }
                                <div className="content_detail">
                                    <div className="title">
                                        <p>최소주문금액</p>
                                    </div>
                                    <div className="title">
                                        <p style={{ color: "#000"}}>{item.limitAmount}</p>
                                    </div>
                                </div>
                                <div className="content_detail">
                                    <div className="title">
                                        <p>다운받은 고객수</p>
                                    </div>
                                    <div className="title">
                                        <p style={{ color: "#000"}}>{item.userCount}</p>
                                    </div>
                                </div>
                                <div className="content_detail">
                                    <div className="title">
                                        <p>쿠폰발행수</p>
                                    </div>
                                    <div className="title">
                                        <p style={{ color: "#000"}}>{item.couponCount}</p>
                                    </div>
                                </div>
                                <div className="content_detail">
                                    <div className="title">
                                        <p>사용기간</p>
                                    </div>
                                    <div className="title">
                                        <p style={{ color: "#000"}}>{item.fromDate} ~ {item.toDate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </>
            :
                <div style={{display: "flex", justifyContent: "center", height: "30vh",alignItems: "center"}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
            }
            {cautionModal && 
                <PcModalCautionCoupon 
                    sProps={oProps}
                    iBannerModal={cautionModal}
                    fnClose={() => cautionClose()} 
                    fnComplete={() => handleOk()} 
                />
            }
            {/* <Modal title="주의사항" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} cancelText="아니오" okText="네">
                <div className="notice_subTitle">
                    <p style={{color: "#617BE3", fontSize: "1.1rem", fontWeight: "600"}}>쿠폰삭제시 이미 해당 쿠폰을 </p>
                    <p style={{color: "#617BE3", fontSize: "1.1rem", fontWeight: "600"}}>다운받은 고객은 적용되지 않습니다</p>
                    <p style={{color: "#617BE3", fontSize: "1.1rem", fontWeight: "600"}}>삭제하시겠습니까?</p>
                </div>
            </Modal> */}
</Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
            <div className="totalPage" style={{ marginBottom: "2%" }}>
                <div className="title_box">
                    <p style={{ fontWeight: 500, fontSize: "1rem", color: "#000", paddingLeft: "5%" }}>등록된 쿠폰 총 {iList.length}개</p>
                </div>
            </div>
            {!loading ?
                <>
                {iList.map((item,index) => {
                    return(
                        <div className="totalPage" style={{ marginBottom: "1%" }} key={index}>
                            <div className="menuContent" >
                                <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf"}}>
                                    <div className="title">
                                        <h3>{item.name}</h3>
                                    </div>
                                    <div className="stamp_edit_box">
                                        <div className="state_change_box" onClick={() => showModal(item)}>
                                            <p>삭제</p>
                                        </div>
                                    </div>
                                </div>
                                {item.type === "amount" ?
                                <>
                                    <div className="content_detail">
                                        <div className="title">
                                            <p>할인금액</p>
                                        </div>
                                        <div className="data">
                                            <p>{item.amount}</p>
                                        </div>
                                    </div>
                                    
                                </>
                                :
                                <>
                                    <div className="content_detail">
                                        <div className="title">
                                            <p>할인률</p>
                                        </div>
                                        <div className="data">
                                            <p>{item.percent} %</p>
                                        </div>
                                    </div>
                                    <div className="content_detail">
                                        <div className="title">
                                            <p>최대할인금액</p>
                                        </div>
                                        <div className="data">
                                            <p>{item.maxlimitAmount}</p>
                                        </div>
                                    </div>
                                </>
                                }
                                <div className="content_detail">
                                    <div className="title">
                                        <p>최소주문금액</p>
                                    </div>
                                    <div className="data">
                                        <p>{item.limitAmount}</p>
                                    </div>
                                </div>
                                <div className="content_detail">
                                    <div className="title">
                                        <p>다운받은 고객수</p>
                                    </div>
                                    <div className="data">
                                        <p>{item.userCount}</p>
                                    </div>
                                </div>
                                <div className="content_detail">
                                    <div className="title">
                                        <p>쿠폰발행수</p>
                                    </div>
                                    <div className="data">
                                        <p>{item.couponCount}</p>
                                    </div>
                                </div>
                                <div className="content_detail">
                                    <div className="title">
                                        <p>사용기간</p>
                                    </div>
                                    <div className="data">
                                        <p>{item.fromDate} ~ {item.toDate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </>
            :
                <div style={{display: "flex", justifyContent: "center", height: "30vh",alignItems: "center"}}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                </div>
            }
            {cautionModal && 
                <ModalCautionCoupon 
                    sProps={oProps}
                    iBannerModal={cautionModal}
                    fnClose={() => cautionClose()} 
                    fnComplete={() => handleOk()} 
                />
            }
                </Mstyle>
            </MobileView>
            
            </Layout>
        </>
    )
}

export default List;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .essential_form {
        font-size: 0.8rem;
        font-weight: 400;
        color: #617BE3;
    }
    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }
    
    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }

    .menu_container {
        margin-top: 10px;
        border-radius: 4px;

        .tab {
            display: flex;
            align-items: center;
            height: 8vh;
            width: 42vw; 
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    
    .content_container {
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        background: #fff;
        width: 42vw; 

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;

            p {
                margin-left: 5%;
                font-size: 1rem;
                font-weight: 700;
            }
        }
        
        .menuContent {
            
            padding-bottom: 20px;

            .menu {
                display: flex;
                align-items: center;
                padding: 2%;

                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 4vw;
                    height: 7vh;
                    margin-right: 16px;
                    border-radius: 4px;

                    .box_image {
                        width: 4vw;
                        height: 7vh;
                        border-radius: 5%;
                    }
                }

                .title {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 30vw;

                    h3 {
                        margin-left: 5%;
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .content_detail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 8px;
                padding-bottom: 4px;
                margin-left: 5%;
                margin-right: 5%;

                p {
                    font-size: 0.8rem;
                    font-weight: 600;
                    color: #3B3B46;
                }
            }

            .sold_out {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 5vw;
                height: 4vh;
                background : #DD1212;
                border-radius: 5px;
                margin-left: auto;
                cursor: pointer;

                h2 {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            
        }
        .page_nation {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 7vh;
        }
    }

    .container {
        padding: 32px;
        margin-bottom: 100px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
        width: 42vw;
    }

    .pictures_title {
        height: 80px;

        .location_box_line {
            height: 50px;

            .how_box {
                display: flex;
                align-items: center;
                height: 50px;
                background: #f1f3f7;
                border-radius: 4px;

                p {
                    margin-left: 3%;
                    font-size: 0.7rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
    }
    .pictures_list_title {
        height: 25vh;
    }
    
    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .register_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                border: 1px solid #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .category_title {
        position: relative;
        margin-bottom: 3%;

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        input {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 2px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        
        .address {
            position: relative;
            margin-bottom: 8px;

            .search_box {
                width: 72px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                background: #617BE3;
            }
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            position: relative;
            top: 14px;
            bottom: 0;
            right: 0;
            border: 0;
            outline: 0;
            left: 90%;
        }

        textarea {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            min-height: 15vh;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        textarea::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .set_location_box {
            display: flex;
            align-items: center;
            height: 106px;

            .set_location_button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 6vw;
                height: 6vh;
                border-radius: 4px;
                background: #617BE3;
                cursor: pointer;
                p {
                    font-size: 1rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }


    }
    .location_box_line {
        height: 60px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 60px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }
    
    .location_box_line2 {
        height: 50px;
        
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    .category_array {
        margin-bottom: 3%;

        .search_date {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2%;

            .delete_selected_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 9%;
                height: 5vh;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 400;
                    color: #DD1212;
                    text-decoration: underline;
                }
            }

            input {
                padding: 12px;
            }
        }

    }

    .category_price_line {
        position: "relative";
        margin-bottom: 5%;

        .discount_detail_box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            height: 40px;
            background: #F1F3F7;
            border-radius: 5px;
             
            p {
                margin-left: 15px;
                color: #DD1212;
                font-size: 0.6rem;
                font-weight: 500;
            }
        }

        .product_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 0;
        }
        .product_discount_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 25%;
        }

        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
            
        }

        .classDiscountPrice {
            background: #F1F3F7;
            border: 1px solid #617BE3;
        }

        .input_action {
            border: solid 2px #6490E7;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 2px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .discount_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right:0;
            background: #001E62;
            padding: 11px;
            width: 8vw;

            .discount_text {
                font-size: 16px;
                font-weight: bold;
                color: #fff; 
            }

            p {
                font-size: 10px;
                font-weight: normal;
                color: #dfdfe3; 
            }

        }
    }

    .select_box {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .start_hour_box_null {
            width: 49%;
        }
        .start_minute_box_null {
            width: 49%;
        }
        .start_hour_box {
            width: 28%;
        }
        .start_minute_box {
            width: 19%;
        }
        .time_arrange_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4%;
        }
        
        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 7vh;
        height: 6vh;
        background: #fff;
        border : 1px solid #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .insert_box:hover {
        background: #617BE3;
        cursor: pointer;

        p {
            color: #fff;
        }
    }

    .option_select_box {
        margin-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 50px;
        border : 1px solid #617BE3;
        border-radius: 4px;
        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .option_select_box:hover {
        background : #617BE3;

        p {
            color: #fff;
        }
    }

    .limit_select_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .delete_selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2%;
            width: 20%;
            height: 50px;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #DD1212;
                text-decoration: underline;
            }
        }

        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }

    .delete_box {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DD1212;
        border-radius: 5px;
        cursor: pointer;
        height: 30px;
        width: 70px;
        font-size: 0.8rem;
        font-weight: 400;
        color: #DD1212;
    }

    .delete_box:hover {
        background: #DD1212;
        color: #fff
    }
`


const Mstyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }   

    .stockPage {
        background-color: #f1f1f1;
        padding-bottom: 5%;
    }

    .tapPage {
        background-color: #F1F3F7;
        padding-bottom: 8px;
    }

    .menu_container {
        background: #fff;
        height: 10vh;

        .tab {
            display: flex;
            align-items: center;
            height: 10vh;
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }
    .totalPage {
        width: 100%;
        background-color: #fff;

        .title_box {
            display: flex;
            align-items: center;
            height: 8vh;

            h2 {
                margin-left: 5%;
                font-size: 0.9rem;
                font-weight: 400;
            }
        }

        .menuContent {
            padding-bottom:5%;

            .menu {
                display: flex;
                align-items: center;
                padding: 5% 5% 5% 5%;

                .box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 11vw;
                    height: 5vh;
                    margin-right: 16px;
                    border-radius: 4px;
                    border : 1px solid #dfdfdf;

                    .box_image {
                        border-radius: 5%;
                        width: 11vw;
                        height: 5vh;
                    }
                }

                .title {
                    display: flex;
                    height: 100%;
                    width: 30%;
                    align-items: center;

                    h3 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }

                .stamp_edit_box {
                    display: flex;
                    height: 100%;
                    width: 70%;
                    justify-content: flex-end;
                    align-items: center;
                    
                    .sold_out {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 4vh;
                        width: 20vw;
                        background : #6A7684;
                        border-radius: 5px;
                        margin-right: 2vh;

                        p {
                            font-size: 0.8rem;
                            font-weight: 600;
                            color: #fff;
                        }
                    }
                    
                    .state_onchange_box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 4vh;
                        width: 20vw;
                        background : #E8EFFC;
                        border-radius: 5px;

                        p {
                            font-size: 0.8rem;
                            font-weight: 600;
                            color: #6490E7;
                        }
                    }
                    
                    .state_change_box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 4vh;
                        width: 23vw;
                        background : #EF4452;
                        border-radius: 5px;

                        p {
                            font-size: 0.8rem;
                            font-weight: 600;
                            color: #fff;
                        }
                    }
                }
                
            }
        }

        .content_detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 2%;
            margin-left: 5%;
            margin-right: 5%;

            .title {
                p {
                    color: #4E5867;
                    font-size: 0.8rem;
                    font-weight: 500;
                }
            }
            .data {
                p {
                    font-size: 0.9rem;
                    font-weight: 600;
                }
            }
        }

        .sold_out {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 4vh;
            width: 23vw;
            background : #FCC3C2;
            border-radius: 5px;

            p {
                font-size: 0.8rem;
                font-weight: 600;
                color: #EF4452;
            }
        }
        

        .stock {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 16vw;
            height: 6vh;
            border-radius: 5%;
            background: #617be3;
            margin-left: auto;
            cursor: pointer;
            transition: 0.3s;

            h2 {
                padding: 0;
                font-size: 0.7rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }
    
`


