import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';

// 이미지
import promotion01 from '../../../../assets/img/guide/d_20221001_1.png';
import promotion02 from '../../../../assets/img/guide/d_20230202_2.png';
import promotion03 from '../../../../assets/img/guide/d_20230202_3.png';
import promotion04 from '../../../../assets/img/guide/d_20221001_4.png';
import promotionBtn from '../../../../assets/img/guide/d_20221001_5.png';
const promotionBenefits = (oProps) => {

    const openCEOKakao = () => {
        window.open('https://pf.kakao.com/_tzxkNK/chat', '_blank');
    }
    return (
        <D_benefits>
            <div className="ceoTip">
                <img src={promotion01} alt="사장님 프로모션 이미지" width="100%" />
                <img src={promotion02} alt="사장님 프로모션 이미지" width="100%" />
                <img src={promotion03} alt="사장님 프로모션 이미지" width="100%" />
                <div className="throoContact">
                    <img src={promotion04} alt="사장님 프로모션 이미지" width="100%" />
                    <button className='throoButton'><img src={promotionBtn} alt="프로모션 혜택 신청하기" onClick={openCEOKakao}/></button>
                </div>
            </div>
        </D_benefits>
    )
}

export default promotionBenefits;
export const D_benefits = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    img {
        margin-top: -3px;
    }
    .ceoTip {
        margin-top: 60px;
    }
    .throoContact {
        position: relative;

        .throoButton {
            width: 100%;
            
            position: absolute;
            bottom: 10%;
            left: 0;
            right: 0;
            border: none;
            outline: none;
            background: none;

            img {
                max-width: 200px;
            }
        }
    }
`