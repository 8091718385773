
import React, { useState, useEffect, useRef, Fragment, forwardRef } from 'react';
import { Modal, message, Table, Select } from 'antd';
import ReactDragListView from "react-drag-listview";
import SelectReact from 'react-select';
import styled from 'styled-components';
import "antd/dist/antd.css";

import noneImageBox from '../../../../assets/svg/none_image.svg';

import { ModalOptionChoice, ModalOptionRelease, ModalSetProductChoice, ModalSetProductRelease } from '../../../modal/AppModal';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';

let discountCheckTime;
const { Option } = Select;

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const ProductForm = ({ iProps, iCategoryList, fAddList }, ref) => {
    const [OptionCategory, setOptionCategory] = useState("mProduct");
    const [iStatus, setStatus] = useState("");

    const [sCategory, setCategory] = useState("");
    const [sPreviewImage, setPreviewImage] = useState("");
    const [sFileList, setFileList] = useState("");
    const [productNm, setProductNm] = useState("");
    const [sPrice, setPrice] = useState("");
    const [dPrice, setDiscountPrice] = useState("");
    const [discountNm, setDiscountNm] = useState("");
    const [productDetail, setProductDetail] = useState("");
    const [iStock, setStock] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [productType, setProductType] = useState('normal');

    const [classProductNm, setClassProductNm] = useState(null);
    const [classPrice, setClassPrice] = useState(null);
    const [classProductDetail, setClassProductDetail] = useState(null);

    const [sCategoryList, setCategoryList] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const [sOptionBox, setOptionBox] = useState([]);
    const [setMenuBox, setSetMenuBox] = useState([]);
    const optionBox = useRef([]);
    const setMenuOptionBox = useRef([]);
    const optionSelected = useRef(false);

    const [modal, contextHolder] = Modal.useModal();
    React.useImperativeHandle(ref, () => ({
        // setProductNm,
        // setPrice,
        setDiscountPrice,
        setFileList,
        setProductDetail,
        // setOptionBox,
        setSetMenuBox,
        setProductType: () => {
            setProductType('normal');
        }
    }))
    //  옵션(선택)추가하기/세트상품등록 공통사항
    const deleteList = (key) => {
        optionBox.current = optionBox.current.filter((item) => item.key !== key);
        setMenuOptionBox.current = setMenuOptionBox.current.filter((item) => item.key !== key);
        setOptionBox(optionBox.current);
        setSetMenuBox(setMenuOptionBox.current)
    }
    const modalDone = () => {
        let temp = false;
        if (optionBox.current.length > 0) {
            temp = true;
        }
        if (setMenuOptionBox.current.length > 0) {
            temp = true;
        }
        optionSelected.current = temp;
        setOptionBox(optionBox.current);
        setSetMenuBox(setMenuOptionBox.current);
    }

    // 옵션(선택)추가하기
    const [sProductColumns] = useState(isMobile ? [
        {
            title: "옵션그룹명",
            key: "name",
            width: "60%",
            dataIndex: "name",
        },
        {
            title: "순서",
            key: "key",
            width: "20%",
            render: (text, record, index) =>
                <div className='spc-between'>

                    <div onClick={() => ChangeRowMob(index, -1)}>
                        ↑
                    </div>
                    <div onClick={() => ChangeRowMob(index, 1)}>
                        ↓
                    </div>
                </div>
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteList(text.key)}><p style={{ color: "#DD1212" }}>해제</p></div>
        }
    ] : [
        {
            title: "순서",
            key: "key",
            width: "15%",
            render: (text, record, index) => <a className="drag-handle">{parseInt(index) + 1}</a>
        },
        {
            title: "옵션그룹명",
            key: "name",
            width: "65%",
            dataIndex: "name",
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteList(text.key)}><p>해제</p></div>
        }
    ]);
    const ChangeRowMob = async (sIndex, fIndex) => {
        if ((sIndex === 0 && fIndex === -1) || (sIndex === optionBox.current.length - 1 && fIndex === 1)) {
            return;
        }
        let tempColumns = optionBox.current;
        const columnsCopy = tempColumns.slice();

        const item = columnsCopy.splice(sIndex, 1)[0];
        columnsCopy.splice(sIndex + fIndex, 0, item);
        optionBox.current = columnsCopy;
        setOptionBox(columnsCopy);
    }
    const setChangeOption = (e) => {
        if (optionSelected.current) {
            modal.success(modalOptionRelease);
        } else {
            modal.success(modalOptionSelect);
        }
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
    }
    const modalOptionSelect = {
        content: (
            <ModalOptionChoice sProps={iProps} fUploadData={(sIndex) => uploadData(sIndex)} />
        ), onOk() {
            modalDone();
        },
    };
    const modalOptionRelease = {
        content: (
            <ModalOptionRelease iOptions={sOptionBox} fUploadData={(sIndex) => uploadData(sIndex)} sProps={iProps} />
        ), onOk() {
            modalDone();
        },
    };
    const uploadData = (sIndex) => {
        optionBox.current = sIndex;
    }

    // 세트상품만들기
    const ChangeSetMenuRowMob = async (sIndex, fIndex) => {
        if ((sIndex === 0 && fIndex === -1) || (sIndex === setMenuOptionBox.current.length - 1 && fIndex === 1)) {
            return;
        }
        let tempColumns = setMenuOptionBox.current;
        const columnsCopy = tempColumns.slice();

        const item = columnsCopy.splice(sIndex, 1)[0];
        columnsCopy.splice(sIndex + fIndex, 0, item);
        setMenuOptionBox.current = columnsCopy;
        setSetMenuBox(columnsCopy)
    }
    const [sSetMenuColumns] = useState(isMobile ? [
        {
            title: "세트메뉴 옵션명",
            key: "name",
            width: "60%",
            dataIndex: "name",
        },
        {
            title: "순서",
            key: "key",
            width: "20%",
            render: (text, record, index) =>
                <div className='spc-between'>

                    <div onClick={() => ChangeSetMenuRowMob(index, -1)}>
                        ↑
                    </div>
                    <div onClick={() => ChangeSetMenuRowMob(index, 1)}>
                        ↓
                    </div>
                </div>
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteList(text.key)}><p style={{ color: "#DD1212" }}>해제</p></div>
        }
    ] : [
        {
            title: "순서",
            key: "key",
            width: "15%",
            render: (text, record, index) => <a className="drag-handle">{parseInt(index) + 1}</a>
        },
        {
            title: "세트메뉴 옵션명",
            key: "name",
            width: "40%",
            dataIndex: "name",
        },
        {
            title: "최저가",
            key: "price",
            width: "20%",
            dataIndex: "price",
        },
        {
            title: "해제",
            key: "action",
            width: "20%",
            render: (text, record, index) =>
                <div className="delete_box" onClick={() => deleteList(text.key)}><p>해제</p></div>
        }
    ]);
    const setChangeSetMenu = (e) => {
        if (optionSelected.current) {
            modal.success(modalSetProductRelease);
        } else {
            modal.success(modalSetProductSelect);
        }
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);

    }
    const modalSetProductSelect = {
        content: (
            <ModalSetProductChoice sProps={iProps} fUploadData={(sIndex) => uploadsData(sIndex)} />
        ), onOk() {
            modalDone();

        },
    };
    const modalSetProductRelease = {
        content: (
            <ModalSetProductRelease iOptions={setMenuBox} fUploadData={(sIndex) => uploadsData(sIndex)} sProps={iProps} />
        ), onOk() {
            modalDone();
        },
    };
    const uploadsData = (sIndex) => {
        setMenuOptionBox.current = sIndex;
    }

    // 최저가 합계
    const items = setMenuOptionBox.current;
    const totalPrice = items.reduce((acc, cur) => {
        const priceNumber = parseInt(cur.price);

        return acc + priceNumber;

    }, 0);

    const drag_set_Props = (fromIndex, toIndex) => {
        let tempColumns = setMenuBox;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        setMenuOptionBox.current = columnsCopy;
        setSetMenuBox(columnsCopy);
    }
    const dragProps = (fromIndex, toIndex) => {
        let tempColumns = sOptionBox;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        optionBox.current = columnsCopy;
        setOptionBox(columnsCopy);
    }

    // 기타
    const inputClassProductNm = () => {
        if (classProductNm !== "input_wrong") {
            setClassProductNm("input_action");
        }
        setClassPrice(null);
        setClassProductDetail(null);
    }
    const inputClassPrice = () => {
        if (classPrice !== "input_wrong") {
            setClassPrice("input_action");
        }
        setClassProductNm(null);
        setClassProductDetail(null);
    }
    const inputClassDetail = () => {
        if (classProductDetail !== "input_wrong") {
            setClassProductDetail("input_action");
        }
        setClassPrice(null);
        setClassProductNm(null);
    }
    const inputClassCategory = () => {
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
    }

    const uploadLogoImage = async (e) => {
        let origin = e.target.files[0];
        if (origin !== undefined) {
            if (origin.type !== undefined && origin.type !== null) {
                if (origin.type === "image/png" || origin.type === "image/jpeg") {
                    setFileList(origin);
                    origin = await getBase64(origin);
                    setPreviewImage(origin);
                } else {
                    message.error("파일형식은 jpg나 png만 가능합니다.");
                }
            }
        }
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
    }
    const onChangeCategoryValue = (event) => {
        // if (isMobile) {
        //     if (sCategory === "") {
        //         setCategory(iCategoryList[0].id)
        //     }
        //     setCategory(event);

        // } else
        //     setCategory(event.target.value);
        setCategory(event.target.value);
    }
    const cancelLogoImage = () => {
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
        setFileList("");
        setPreviewImage("");
    }
    const onChangeNmValue = (event) => {
        const value = event.target.value;
        setClassProductNm("input_action");
        setProductNm(value);
    }
    const onChangePriceValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setClassPrice("input_action");
            setPrice(value);
        }

    }
    const onChangeDiscountPriceValue = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setDiscountPrice(value);
        }

        if (discountCheckTime) clearTimeout(discountCheckTime);
        discountCheckTime = setTimeout(() => {
            let temp = 0;
            if (setMenuOptionBox.current.length > 0) {
                if (value.toString().trim() === "" || totalPrice.toString().trim() === "") {
                    temp = 0;
                } else {
                    temp = Math.round(parseFloat(100 - (value / totalPrice * 100)));
                }
            } else {
                if (value.toString().trim() === "" || sPrice.toString().trim() === "") {
                    temp = 0;
                } else {
                    temp = Math.round(parseFloat(100 - (value / sPrice * 100)));
                }
            }

            setDiscountNm(temp);
        }, 300);
    }

    const onChangeStock = event => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setStock(value);
        }
    };
    const onChangeProductDetail = (event) => {
        const value = event.target.value;
        setClassProductDetail("input_action");
        setProductDetail(value);
    }

    const changeStatus = async (sIndex) => {
        optionBox.current = [];
        setMenuOptionBox.current = [];
        optionSelected.current = false;
        setOptionBox([]);
        setSetMenuBox([]);
        setPreviewImage("");
        setFileList("");
        setCategory("");
        setProductNm("");
        setPrice("");
        setDiscountPrice("");
        setProductDetail("");
        setClassProductNm(null);
        setClassPrice(null);
        setClassProductDetail(null);
        setStock("");
        setProductType("normal");
        if (sIndex === "new") {
            setStatus("new");
            setFileList("");
        } else {
            setStatus("");
        }
    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
        if (!isChecked) {
            setProductType('only')
        } else {
            setProductType('normal')
        }
    }

    // submit버튼
    const registerProduct = async () => {

        try {

            if (iCategoryList.length > 0) {
                if (productNm === "") {
                    message.info("상품명을 입력해주세요.");
                    setClassProductNm("input_wrong");
                }
                else if (sPrice === "") {
                    message.info("가격을 입력해주세요");
                    setClassPrice("input_wrong");
                } else {
                    let temp = sCategory;
                    if (sCategory === "") {
                        temp = iCategoryList[0].id;
                    }
                    const oData = {
                        sFileList,
                        sCategory: temp,
                        productNm,
                        sPrice,
                        dPrice,
                        productDetail,
                        sOptionBox,
                        setMenuBox,
                        productType,
                    }

                    if (fAddList !== undefined && typeof fAddList === "function") {
                        await fAddList(oData);
                        setIsChecked(false)
                        console.log('등록완료',oData)
                    }
                }

            } else {
                message.error("상품분류를 등록해주세요.");
            }
        } catch (err) {
            console.log("err-->", err);
        }
    }

    // 상품분류 다중선택 상품검색,추가(나중에 오픈)
    function select_product_Change(selectedOption) {
        setSelectedOption(selectedOption);
    }

    useEffect(() => {
        setCategoryList(iCategoryList);

    }, [iCategoryList]);
    useEffect(() => {
        if (setMenuOptionBox.current.length > 0 && OptionCategory === "mSetProduct") {
            setPrice(totalPrice)
        }
    }, [setChangeSetMenu]);

    return (
        <Fragment>
            <BrowserView>
                <StyleInsertPage>
                    <div className="container">
                        <div className="title_box">
                            <h2>상품관리</h2>
                            {iStatus !== "" ?
                                <div className="cancel_box" onClick={() => changeStatus("")}>
                                    <p>등록취소</p>
                                </div>
                                :
                                <div className="new_category_box" onClick={() => changeStatus("new")}>
                                    <p>상품등록</p>
                                </div>
                            }
                        </div>
                        {iStatus !== "" &&
                            <div className='insertPageWrap'>
                                <div className="essential_box" >
                                    <div className='option_type_tab' style={{ margin: 0, height: 0 }} >
                                        <button style={{ background: '#fff', height: 0 }} className={OptionCategory === "mProduct" ? "selected_option_tab" : "unselect_option_tab"} onClick={() => setOptionCategory("mProduct")}>
                                            <p style={{ color: '#fff', padding: 0 }}>상품 만들기</p>
                                        </button>
                                    </div>
                                </div>
                                {/* 추후 오픈 */}
                                {/* <div className="essential_box">
                                    <div className='option_type_tab'>
                                        <button className={OptionCategory === "mProduct" ? "selected_option_tab" : "unselect_option_tab"} onClick={() => setOptionCategory("mProduct")}>
                                            <p>상품 만들기</p>
                                        </button>
                                        <button className={OptionCategory === "mSetProduct" ? "selected_option_tab" : "unselect_option_tab"} style={{ marginLeft: "2%" }} onClick={() => setOptionCategory("mSetProduct")}>
                                            <p>세트 상품 만들기</p>
                                        </button>
                                    </div>
                                </div> */}
                                {OptionCategory === "mSetProduct" &&
                                    <div className="notice_box">
                                        <p>
                                            *세트메뉴 구성용 옵션입니다.<br />
                                            *옵션 추가/관리는 옵션관리 탭에서 가능합니다.<br />
                                            *선택옵션 추가는 하단 옵션추가하기에서 등록하여야합니다.<br />
                                        </p>
                                    </div>
                                }
                                <div className="category_title">
                                    <h3 style={{ marginBottom: '10px' }}>상품분류</h3>
                                    <select placeholder="메뉴가 들어갈 분류를 선택해주세요" value={sCategory} onClick={inputClassCategory} onChange={onChangeCategoryValue} >
                                        {sCategoryList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                        })};
                                    </select>
                                </div>
                                <div className="category_title">
                                    <h3>상품명</h3>
                                    <input
                                        type="text"
                                        placeholder="상품명을 입력해주세요."
                                        value={productNm}
                                        onChange={onChangeNmValue}
                                        className={classProductNm}
                                        onClick={inputClassProductNm}
                                    />
                                </div>
                                {OptionCategory === "mSetProduct" &&
                                    <div className="category_title">
                                        <h3>세트 메뉴 상품 만들기</h3>
                                        <div className="setMenu_wrap">
                                            <p onClick={setChangeSetMenu}>+ 세트 메뉴 상품 추가</p>
                                        </div>
                                        <div className="option_table_wrapper">
                                            <ReactDragListView.DragColumn
                                                onDragEnd={drag_set_Props}
                                                handleSelector="a"
                                            >
                                                <Table columns={sSetMenuColumns} dataSource={setMenuBox} pagination={false} />
                                            </ReactDragListView.DragColumn>
                                        </div>
                                        <div className="location_box_line">
                                            <div className="how_box">
                                                <p>순서를 변경하고 싶으시다면 순서의 숫자를 잡고 드래그를 해주세요!</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="category_price_line">
                                    <h3 style={{ margin: 0 }}>가격</h3>
                                    {OptionCategory === "mSetProduct"
                                        ?
                                        <>
                                            <div style={{ margin: '10px 0' }}>
                                                <p style={{ fontSize: '16px', fontWeight: '600' }}>참고 가격: {totalPrice}원 <span style={{ color: '#888', fontSize: '14px' }}>(각 옵션 그룹 중 가장 낮은 상품의 합산입니다)</span></p>
                                            </div>
                                            <div className="discount_detail_box">
                                                <p> 세트메뉴 할인된 최종 판매 가격을 입력하지 않을 시 <span style={{ marginLeft: '0' }}>각 옵션 그룹 중 가장 낮은 상품의 합산인 참고 가격이 상품 가격이 됩니다.</span></p>
                                            </div>
                                        </>
                                        :
                                        <div style={{ position: "relative", marginTop: "2%", marginBottom: "2%" }}>

                                            <input
                                                type="text"
                                                placeholder="(필수) 상품 정가 입력"
                                                value={sPrice}
                                                onChange={onChangePriceValue}
                                                className={classPrice}
                                                onClick={inputClassPrice}
                                            />
                                            <p className="product_price_box">원</p>
                                        </div>
                                    }
                                    {OptionCategory === "mProduct" && <h3>할인된 최종 판매가격<span>(선택)</span></h3>}
                                    {OptionCategory === "mSetProduct" && <h3>세트 메뉴 할인된 최종 판매가격<span>(선택)</span></h3>}
                                    <div style={{ position: "relative", marginTop: "2%" }}>
                                        <input
                                            type="text"
                                            placeholder="(선택) 할인된 최종판매가격 입력"
                                            value={dPrice}
                                            onChange={onChangeDiscountPriceValue}
                                            className="classDiscountPrice"
                                        />
                                        <div className="discount_box">
                                            <p>할인율</p>
                                            <p className="discount_text">- {discountNm} %</p>
                                        </div>
                                        <p className="product_discount_price_box">원</p>
                                    </div>
                                    <div className="discount_detail_box">
                                        <p> ex ) 20,000원 상품을 5,000원 할인시 15,000원을 입력해 주세요.</p>
                                        <p> 할인된 가격을 입력하시면, 앱상에 할인율이 강조 표시되며, 고객의 주문을 유도할 수 있습니다.</p>
                                        <span>✨총 할인액은 광고포인트로 전환 됩니다.</span>
                                    </div>
                                </div>
                                <div className="category_picture">
                                    <h3>상품 이미지<span> 권장 이미지 사이즈: 800*600(선택)</span></h3>
                                    <div className="image_line">
                                        {sFileList !== "" ?
                                            <div className="image_box">
                                                <img src={sPreviewImage} alt="none_image" />
                                            </div>
                                            :
                                            <div className="none_image_box">
                                                <img src={noneImageBox} alt="none_image" />
                                            </div>
                                        }
                                        <div className="image_box_button">
                                            <div className="filebox">
                                                <label htmlFor="menu_file">
                                                    <div className="edit_image">
                                                        <p>등록</p>
                                                    </div>
                                                    <input type="file" id="menu_file" onChange={(e) => uploadLogoImage(e)} />
                                                </label>
                                            </div>
                                            <div className={sFileList !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage()}><p>삭제</p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="category_title">
                                    <h3>스루 온리 상품 설정<span>(선택)</span></h3>
                                    <div className="throo_only_choice">
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            style={{
                                                width: '18px',
                                                height: '18px'
                                            }}
                                        />
                                        <h3>스루에서만 판매하는 상품입니다. (설정 시 스루 앱 상단에 노출됩니다.)</h3>
                                    </div>
                                </div>
                                <div className="category_title">
                                    <h3>상품설명<span>(선택)</span></h3>
                                    <textarea
                                        type="text"
                                        placeholder="상품설명을 입력시 고객에게 자세하게 상품에 대해 알려드릴 수 있어요!"
                                        value={productDetail}
                                        onChange={onChangeProductDetail}
                                        className={classProductDetail}
                                        onClick={inputClassDetail}
                                    />
                                </div>
                                <div className="option_title">
                                    <h3>옵션<span> (선택)</span></h3>
                                    <div className="option_select_box" >
                                        <p onClick={setChangeOption}>+ 옵션 추가하기</p>
                                    </div>
                                    <div className="option_table_wrapper">
                                        <ReactDragListView.DragColumn
                                            onDragEnd={dragProps}
                                            handleSelector="a"
                                        >
                                            <Table columns={sProductColumns} dataSource={sOptionBox} pagination={false} />
                                        </ReactDragListView.DragColumn>
                                    </div>
                                    <div className="location_box_line">
                                        <div className="how_box">
                                            <p>[순서변경방법] 순서 숫자를 클릭한 상태로 원하는 위치로 이동하세요</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="insert_box" onClick={registerProduct}>
                                    <p>상품등록하기</p>
                                </div>
                            </div>
                        }
                        {contextHolder}
                    </div>
                </StyleInsertPage>
            </BrowserView>
            <MobileView>
                <StyleInsertPage>
                    <div className="category_title_line">
                        <div className="category_title">
                            <h2>상품관리</h2>
                            {iStatus !== "" ?
                                <div className="cancel_box" onClick={() => changeStatus("")}>
                                    <p>등록취소</p>
                                </div>
                                :
                                <div className="insert_box" onClick={() => changeStatus("new")}>
                                    <p>상품등록</p>
                                </div>
                            }
                        </div>
                        {iStatus !== "" &&
                            <div className='insertPageWrap'>
                                <div className="essential_box" >
                                    <div className='option_type_tab' style={{ margin: 0, height: 0 }} >
                                        <button style={{ background: '#fff', height: 0 }} className={OptionCategory === "mProduct" ? "selected_option_tab" : "unselect_option_tab"} onClick={() => setOptionCategory("mProduct")}>
                                            <p style={{ color: '#fff', padding: 0 }}>상품 만들기</p>
                                        </button>
                                    </div>
                                </div>
                                {/* 추후 오픈 */}
                                {/* <div className="essential_box">
                                    <div className='option_type_tab'>
                                        <button className={OptionCategory === "mProduct" ? "selected_option_tab" : "unselect_option_tab"} onClick={() => setOptionCategory("mProduct")}>
                                            <p>상품 만들기</p>
                                        </button>
                                        <button className={OptionCategory === "mSetProduct" ? "selected_option_tab" : "unselect_option_tab"} style={{ marginLeft: "2%" }} onClick={() => setOptionCategory("mSetProduct")}>
                                            <p>세트 상품 만들기</p>
                                        </button>
                                    </div>
                                </div> */}
                                {OptionCategory === "mSetProduct" &&
                                    <div className="notice_box">
                                        <p>
                                            *세트메뉴 구성용 옵션입니다.<br />
                                            *옵션 추가/관리는 옵션관리 탭에서 가능합니다.<br />
                                            *선택옵션 추가는 하단 옵션추가하기에서 등록하여야합니다.<br />
                                        </p>
                                    </div>
                                }
                                <div className="inputText">
                                    <h3 style={{ marginBottom: '10px' }}>상품분류</h3>
                                    <select placeholder="메뉴가 들어갈 분류를 선택해주세요" value={sCategory} onClick={inputClassCategory} onChange={onChangeCategoryValue} style={{ width: '100%', padding:'10px 2px' }} >
                                        {sCategoryList.map((item, index) => {
                                            return (
                                                <option key={index} value={item.id}>{item.name}</option>
                                            )
                                        })};
                                    </select>
                                </div>
                                <div className="inputText">
                                    <h3>상품명</h3>
                                    <input
                                        type="text"
                                        placeholder="상품명을 입력해주세요."
                                        value={productNm}
                                        onChange={onChangeNmValue}
                                        className={classProductNm}
                                        onClick={inputClassProductNm}
                                    />
                                </div>
                                {OptionCategory === "mSetProduct" &&
                                    <div className="option_title">
                                        <h3>세트 메뉴 상품 만들기</h3>
                                        <div className="option_select_box" >
                                            <p onClick={setChangeSetMenu}>+ 세트 메뉴 상품 추가</p>
                                        </div>
                                        <div className="option_table_wrapper" style={{ marginBottom: '10px' }}>
                                            <Table columns={sSetMenuColumns} dataSource={setMenuBox} pagination={false} />
                                        </div>
                                        <div className="location_box_line" style={{ margin: '2px 0 30px 0', background: '#F1F3F7', borderRadius: '8px' }}>
                                            <div className="how_box" style={{ padding: '10px 6px' }}>
                                                <p style={{ fontSize: '12px' }}>순서를 변경하고 싶으시다면 순서 변경 화살표를 클릭해주세요!</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="category_price_line" style={{ marginTop: '15px' }}>
                                    <h3 style={{ margin: 0 }}>가격</h3>
                                    {OptionCategory === "mSetProduct"
                                        ?
                                        <>
                                            <div style={{ margin: '10px 0' }}>
                                                <span style={{ color: '#888', fontSize: '12px' }}>*각 옵션 그룹 중 가장 낮은 상품의 합산입니다</span>
                                                <p style={{ fontSize: '16px', fontWeight: '600' }}>참고 가격: {totalPrice}원 </p>
                                            </div>
                                            <div className="discount_detail_box">
                                                <p> 세트메뉴 할인된 최종 판매 가격을 입력하지 않을 시 <span style={{ marginLeft: '0' }}>각 옵션 그룹 중 가장 낮은 상품의 합산인 참고 가격이 상품 가격이 됩니다.</span></p>
                                            </div>
                                        </>
                                        :
                                        <div style={{ position: "relative", marginTop: "2%", marginBottom: "2%" }}>

                                            <input
                                                type="text"
                                                placeholder="(필수) 상품 정가 입력"
                                                value={sPrice}
                                                onChange={onChangePriceValue}
                                                className={classPrice}
                                                onClick={inputClassPrice}
                                            />
                                            <p className="product_price_box">원</p>
                                        </div>
                                    }
                                    {OptionCategory === "mProduct" && <h3>할인된 최종 판매가격<span>(선택)</span></h3>}
                                    {OptionCategory === "mSetProduct" && <h3>세트 메뉴 할인된 최종 판매가격<span>(선택)</span></h3>}
                                    <div style={{ position: "relative", marginTop: "2%" }}>
                                        <input
                                            type="text"
                                            placeholder="(선택) 할인된 최종 판매가격"
                                            value={dPrice}
                                            onChange={onChangeDiscountPriceValue}
                                            className="classDiscountPrice"
                                        />
                                        <div className="discount_box">
                                            <p>할인율</p>
                                            <p className="discount_text">- {discountNm} %</p>
                                        </div>
                                        <p className="product_discount_price_box">원</p>
                                    </div>
                                    <div className="discount_detail_box">
                                        <p> ex ) 20,000원 상품을 5,000원 할인시 15,000원을 입력해 주세요.</p>
                                        <p> 할인된 가격을 입력하시면, 앱상에 할인율이 강조 표시되며, 고객의 주문을 유도할 수 있습니다.</p>
                                        <span>총 할인액은 광고포인트로 전환 됩니다.</span>
                                    </div>

                                </div>
                                <div className="category_picture">
                                    <h3>상품 이미지<span> 권장 이미지 사이즈: 800*600(선택)</span></h3>
                                    <div className="image_line">
                                        {sFileList !== "" ?
                                            <div className="none_image_box">
                                                <img src={sPreviewImage} alt="none_image" />
                                            </div>
                                            :
                                            <div className="image_box">
                                                <img src={noneImageBox} alt="none_image" />
                                            </div>
                                        }
                                        <div className="image_box_button">
                                            <div className="filebox">
                                                <label htmlFor="menu_file">
                                                    <div className="edit_image">
                                                        <p>등록</p>
                                                    </div>
                                                    <input type="file" id="menu_file" onChange={(e) => uploadLogoImage(e)} />
                                                </label>
                                            </div>
                                            <div className={sFileList !== "" ? "delete_image" : "un_delete_image"} onClick={() => cancelLogoImage()}><p>삭제</p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inputText">
                                    <h3>스루 온리 상품 설정<span>(선택)</span></h3>
                                    <div className="throo_only_choice">
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            style={{
                                                width: '18px',
                                                height: '18px'
                                            }}
                                        />
                                        <h3>스루에서만 판매하는 상품입니다.<br /> (설정 시 스루 앱 상단에 노출됩니다.)</h3>
                                    </div>
                                </div>
                                <div className="inputText">
                                    <h3>상품설명<span>(선택)</span></h3>
                                    <textarea
                                        type="text"
                                        placeholder="상품설명을 입력시 고객에게 자세하게 상품에 대해 알려드릴 수 있어요!"
                                        value={productDetail}
                                        onChange={onChangeProductDetail}
                                        className={classProductDetail}
                                        onClick={inputClassDetail}
                                    />
                                </div>
                                <div className="option_title">
                                    <h3>옵션<span> (선택)</span></h3>
                                    <div className="option_select_box" >
                                        <p onClick={setChangeOption}>+ 옵션 추가하기</p>
                                    </div>
                                    <div className="option_table_wrapper" style={{ marginBottom: '10px' }}>
                                        <Table columns={sProductColumns} dataSource={sOptionBox} pagination={false} />
                                    </div>
                                    <div className="location_box_line" style={{ margin: '2px 0 30px 0', background: '#F1F3F7', borderRadius: '8px' }}>
                                        <div className="how_box" style={{ padding: '10px 6px' }}>
                                            <p style={{ fontSize: '12px' }}>순서를 변경하고 싶으시다면 순서 변경 화살표를 클릭해주세요!</p>
                                        </div>
                                    </div>
                                </div>
                                {iStatus !== "" ? <div className='bottom_fixed'>
                                    <button className="finishButton" onClick={registerProduct}>등록하기</button>
                                </div> : undefined}
                            </div>
                        }
                        {contextHolder}
                    </div>
                </StyleInsertPage>
            </MobileView>
        </Fragment >
    )
}

export default forwardRef(ProductForm);

const StyleInsertPage = styled.div`
/* 공통 css */
h3 {
    font-size: 15px;
    font-weight: 500;
    margin: 25px 0 0 0;
    color: #000;
}
p {
    margin-top: 1px;
    font-size: 15px;
    font-weight: 400;
}
/* 옵션 타입 선택 */
.option_type_tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 10px 0;
    background:  #EAEAEA;
    border-radius: 50px;

    .selected_option_tab, .unselect_option_tab {
        background: #617BE3;
        border-radius: 50px;
        border: none;
        outline: none;
        width: 100%;
        
        p {
            margin: 0;
            padding: 15px 0;
            font-weight: 600;
            color: #fff;
        }
        
    }
    .unselect_option_tab {
        background:  #EAEAEA;
        
        p {
            color: #7C8297;
            font-weight: 400;
        }
    }
}

/* 안내 문구 */
.notice_box {
    padding: 10px 15px;
    background: #F1F3F7;
    border-radius: 8px;
    
    p {
        font-size: 12px;
        color: #DD1212;
    }
}

/* 세트 메뉴 상품 만들기 */
.setMenu_wrap {
    margin-top: 16px;
    cursor: pointer;

    p {
        color: #6490E7;
        font-size: 15px;
        font-weight: 700;
    }
}
/* 스루 온리 */
.throo_only_choice {
    width: 100%;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 400;
        color: #BBBBBB;
    }
    h3 {
        margin: 0 0 0 10px;
        font-size: 15px;
        font-weight: 500;
        color: #617BE3;
    }
    input {
        margin: 0!important;
    }
}
`
