import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import Loader from "react-loader-spinner";
import moment from "moment-timezone";
import "moment/locale/ko";
import "../../../assets/fonts/font.css";
import PosGuide from "../posGuide/MainPosGuide";

import Modal from "../../modal/MainModal";
import ModalSmartStore from "../../modal/ModalSmartStore";
import notice from "../../../assets/img/ceoscreen/notice.png";
import selfmanage from "../../../assets/svg/mainPage/selfmanage.svg";
import rightArrow from "../../../assets/img/ceoscreen/rightArrow.png";
import tip01 from "../../../assets/img/ceoscreen/tip01.png";
import tip04 from "../../../assets/img/ceoscreen/tip04.png";
import tip05 from "../../../assets/img/ceoscreen/tip05.png";
import posImg from "../../../assets/img/ceoscreen/posImg.png";

import { WebRoute } from "../../../navigation/WebRoutes";

const ContentsPage = (oProps) => {
  const [noticeLoad, setNoticeLoad] = useState(true);
  const [nList, setList] = useState([]);

  const [modal, setModal] = useState(true);
  const [smartStoreModal, setSmartStoreModal] = useState(true);

  // 링크
  const openStoremanage = () => {
    let sDomainUrl = "https://ceo.throo.co.kr";
    if (
      process.env !== undefined &&
      process.env.REACT_APP_CEO_WEBURL !== undefined
    ) {
      sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
    }
    window.open(sDomainUrl + "/selfmanage/signin", "_blank");
  };

  const getList = async () => {
    const oResponse = await oProps.oManager.fDoAxios(
      "/home/contentList",
      "get",
      null,
      null
    );
    if (oResponse !== undefined) {
      if (oResponse.resultCd === "0000") {
        if (oResponse.resultData.length > 0) {
          setList(oResponse.resultData);
          setNoticeLoad(false);
        }
      }
    }
  };

  const goProductPath = async () => {
    let sDomainUrl = "https://ceo.throo.co.kr";
    if (
      process.env !== undefined &&
      process.env.REACT_APP_CEO_WEBURL !== undefined
    ) {
      sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
    }
    window.open(sDomainUrl + "/selfmanage/commercial", "_blank");
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <M_contentsStyle>
      <div className="MobileContent">
        {/* {modal == true ? <Modal /> : null} */}
        <div className="container">
          <div className="contentPage">
            <h2>스루 사장님이 꼭 알아야 할 정보</h2>
            <div className="content">
              <Link
                to={{
                  pathname: "/ceotip",
                  page_id: "mob_stamp",
                  search: "mob_stamp",
                }}
              >
                <div className="contentIndex">
                  <div className="box">
                    <img src={tip05} alt="스탬프 이미지" width="100%" />
                  </div>
                  <div className="contentText">
                    <div className="icon">TIP</div>
                    <p>스탬프를 이용하여 단골 고객을 만들어보세요</p>
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname: "/ceotip",
                  page_id: "mob_coupon",
                  search: "mob_coupon",
                }}
              >
                <div className="contentIndex">
                  <div className="box">
                    <img src={tip04} alt="할인쿠폰 이미지" width="100%" />
                  </div>
                  <div className="contentText">
                    <div className="icon">TIP</div>
                    <p>할인쿠폰으로 고객의 주문을 유도해 보세요</p>
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname: "/ceotip",
                  page_id: "mob_sales",
                  search: "mob_sales",
                }}
              >
                <div className="contentIndex">
                  <div className="box">
                    <img src={tip01} alt="정산주기 이미지" width="100%" />
                  </div>
                  <div className="contentText">
                    <div
                      className="icon"
                      style={{ backgroundColor: "#FF9900" }}
                    >
                      FAQ
                    </div>
                    <p>스루의 정산 주기는 어떻게 되나요?</p>
                  </div>
                </div>
              </Link>
            </div>
            <Link to={WebRoute.CEOTIPSUB}>
              <div className="tipBtn">
                더보기{" "}
                <img
                  src={rightArrow}
                  alt="더보기 아이콘"
                  width="12px"
                  height="12px"
                />
              </div>
            </Link>
          </div>
          <div className="contentPage">
            <h2>공지사항</h2>
            {noticeLoad ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "30vh",
                  alignItems: "center",
                  border: "1px solid #ECEEF2",
                  borderRadius: 4,
                }}
              >
                <Loader
                  type="BallTriangle"
                  color="#00BFFF"
                  height={80}
                  width={80}
                  timeout={9000000}
                />
              </div>
            ) : (
              <Link to={WebRoute.NOTICE}>
                <div className="notice">
                  <ul>
                    {nList.map((item, index) => {
                      return (
                        <li key={index}>
                          <ul
                            className="nTitle"
                            style={{
                              marginBottom:
                                nList.length - 1 === index ? "0" : "10",
                            }}
                          >
                            <li>{item.title}</li>
                            <li>{moment(item.created_at).format("L")}</li>
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Link>
            )}
            <Link to={WebRoute.NOTICE}>
              <div className="nBtn">
                공지사항 더보기{" "}
                <img
                  src={rightArrow}
                  alt="더보기 아이콘"
                  width="12px"
                  height="12px"
                />
              </div>
            </Link>
          </div>
          <div className="contentPage">
            <h2>스루 이용가이드</h2>
            <div className="guideNotice">
              <PosGuide />
              <Link to={WebRoute.POSGUIDE}>
                <div className="nBtn">
                  이용가이드 더보기{" "}
                  <img
                    src={rightArrow}
                    alt="더보기 아이콘"
                    width="12px"
                    height="12px"
                  />
                </div>
              </Link>
            </div>
          </div>
          <div className="contentPage">
            <h2>스루 포스</h2>
            <Link to={WebRoute.DOWNLOAD}>
              <div className="pos">
                <div className="box">
                  <img src={posImg} alt="포스이미지" />
                </div>
                <div className="posDownload">
                  <h3>포스 다운로드</h3>
                  <p>
                    우리 매장에 스루 포스를 설치
                    <br />
                    해보세요.
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="contentPage">
            <div className="promotion">
              <h2>
                드라이브스루를 시작하는
                <br />
                우리 매장을 고객에게 알려주세요!
              </h2>
              <img src={notice} alt="알림 아이콘" width="46px" height="46px " />
            </div>
            <div className="promotionBtn" onClick={() => goProductPath()}>
              <p>홍보물 신청하러 가기</p>
            </div>
          </div>
          <div className="floating">
            <SVG
              style={{ zIndex: "999" }}
              src={selfmanage}
              onClick={openStoremanage}
              title="매장관리 바로가기 버튼"
            />
          </div>
        </div>
      </div>
    </M_contentsStyle>
  );
};

export default ContentsPage;

export const M_contentsStyle = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  ul,
  button {
    font-family: "NanumSquare";
    transform: skew(-0.1deg);
    font-weight: 900;
  }
  ul,
  li {
    list-style: none;
  }
  .MobileContent {
    width: 100%;
  }
  .container {
    width: 100%;
    padding: 0 5vw;
    margin: 0 auto;
  }
  .contentPage h2 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 900;
  }

  /* 스루 사장님이 꼭 알아야 할 정보 */
  .contentPage:nth-child(1) {
    margin: 32px 0 48px 0;

    .tipBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      padding: 9px 0;
      width: 100%;
      background-color: #fff;
      border: 1px solid #eceef2;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 900;
      color: #888;
    }
  }
  .content {
    margin-top: 16px;
  }
  .contentIndex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 9px;
    padding: 16px;
    border: 1px solid #eceef2;
    border-radius: 4px;

    .box {
      max-width: 106px;
      width: 100%;
      height: 76px;
      margin-right: 12px;
    }
    .contentText {
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        width: 39px;
        height: 24px;
        background-color: #1a7cff;
        color: #fff;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 900;
      }
      p {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        color: #000;
        word-break: keep-all;
      }
    }
  }

  /* 공지사항 */

  .contentPage:nth-child(2) {
    margin-bottom: 24px;
  }
  .nBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    padding: 9px 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #eceef2;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 900;
    color: #888;
  }
  .notice {
    padding: 24px 16px;
    border: 1px solid #eceef2;
    border-radius: 4px;

    .nTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 48px;

      li {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: #888888;
      }
      li:nth-child(1) {
        max-width: 190px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        color: #000;
      }
    }
  }

  /* 스루 이용가이드 */
  .contentPage:nth-child(3) {
    margin-bottom: 32px;
    margin-top: 48px;
  }
  .guideContent {
    .title {
      width: 80%;
    }
    .title h2 {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  /* 스루포스 */
  .contentPage:nth-child(4) {
    margin-bottom: 38px;

    h2 {
      margin-bottom: 8px;
    }
  }
  .pos {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #eceef2;
    border-radius: 4px;

    .box img {
      width: 104px;
      height: 104px;
      margin-right: 12px;
    }
    .posDownload {
      h3 {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 900;
      }
      p {
        font-size: 16px;
        font-weight: 700;
        color: #888;
        line-height: 24px;
      }
    }
  }

  /* 홍보물 신청 */
  .contentPage:nth-child(5) {
    padding: 24px 16px;
    margin-bottom: 20px;
    border: 1px solid #eceef2;
    border-radius: 4px;
  }
  .promotion {
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
    }
  }
  .promotionBtn {
    cursor: pointer;
    p {
      width: 100%;
      padding: 12px 0;
      margin-top: 20px;
      background-color: #1a7cff;
      color: #fff;
      border-radius: 4px;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 900;
      text-align: center;
    }
  }

  /* 매장바로가기 floating버튼 */
  .floating svg {
    width: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 4vw;
    margin-bottom: 1vh;
  }
`;
