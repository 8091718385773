import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { Pagination, DatePicker, Select } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import stopIcon from '../../../assets/img/stopIcon.png';
import manual from '../../../assets/svg/manual.svg';
import addressSearch from '../../../assets/svg/addressSearch.svg';

const defaultFormat = "YYYY/MM/DD";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Payment = ({ oProps }) => {
   const [isLoading, setLoading] = useState(true);

   const [todayPay, setTodayPay] = useState(0);
   const [exDayPay, setExDayPay] = useState(0);
   const [selectValue, setSelectValue] = useState("total");
   const [sCurrent, setCurrent] = useState(1);
   const [fromDate, setFromDate] = useState(moment().format(defaultFormat));
   const [toDate, setToDate] = useState(moment().add(1, 'days').format(defaultFormat));

   const [startPage, setStartPage] = useState(0);
   const [endPage, setEndPage] = useState(5);

   const [iList, setIList] = useState([]);

   const selectValueChange = (value) => {
      setSelectValue(value);
   }

   const searchList = async () => {
      setLoading(true);
      getList(fromDate, toDate, selectValue);
   }

   const onChangePage = page => {
      let tempStart = 0;
      let tempEnd = 5;

      if (parseInt(page) !== 1) {
         tempEnd = tempEnd * page;
         tempStart = tempEnd - 5;
      }
      setStartPage(tempStart);
      setEndPage(tempEnd);
      setCurrent(page);
   };

   const getList = async (iIndex, aIndex, bIndex) => {
      let storeId = oProps.UserConfigReducer.StoreID;
      let iValue = "total";
      let from_Date = moment().format(defaultFormat);
      let to_Date = moment().add(1, 'days').format(defaultFormat);

      if (iIndex !== undefined && iIndex != null) {
         from_Date = iIndex;
      }
      if (aIndex !== undefined && aIndex != null) {
         to_Date = aIndex;
      }
      if (bIndex !== undefined && bIndex != null) {
         iValue = bIndex;
      }

      const oData = {
         storeId,
         iValue,
         fromDate: from_Date,
         toDate: to_Date,
      }
      const oResponse = await oProps.oManager.fDoAxios(`/payment/listing/v2`, "post", "login", oData);
      if (oResponse !== undefined) {
         setIList(oResponse.list);
         setTodayPay(oResponse.todayPay);
         setExDayPay(oResponse.yesterdayPay);
      }
      setLoading(false);
   }

   const onOperatingDate = (value, dateString) => {
      if (dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== "") {
         if (fromDate === moment().format(defaultFormat)) {
            setFromDate(dateString[0]);
         } else {
            setFromDate(dateString[0]);
            setToDate(dateString[1]);
         }
      } else {
         setFromDate(moment().format(defaultFormat));
         setToDate(moment().add(1, 'days').format(defaultFormat));
      }
   }

   useEffect(() => {
      getList();

      setTimeout(() => {
         window.location.replace('https://ceo.throo.co.kr');
      }, 500);
   }, []);

   return (
      <Style>
         {/* {isLoading ?
                <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                </div>
                : */}
         <div className='renewalPage'>
            <img src={stopIcon} alt="페이지 준비중 아이콘" width={280} />
            <h2><strong>페이지 이동중입니다...</strong></h2>
            {/*
            <p>이용에 불편을 드려 죄송합니다.</p>
            <p>보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.</p>
            <p>빠른시일내에 준비하여 찾아뵙겠습니다.</p>
            */}
            {/* <div className="payment_title_line">
                        <div className="payment_title">
                            <h2>매출조회</h2>
                            <div className="tip">
                                <SVG src={manual} width="18px" height="18px" />
                                <span><strong>자세한 매출내역 조회는</strong>PC에서<br />확인이 가능합니다.</span>
                            </div>
                        </div>
                        <div className="first_line">
                            <div className="payment_line">
                                <h3 style={{ width: "50%", marginRight: "5%" }}>오늘 매출</h3>
                                <h3 style={{ width: "50%" }}>어제 매출</h3>
                            </div>
                            <div className="payment_line">
                                <div className="today_box">
                                    <p>{todayPay} 원</p>
                                </div>
                                <div className="yesterday_box">
                                    <p>{exDayPay} 원</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="number">
                        <div className="select_value">
                            <Select value={selectValue} style={{ width: "100%" }} bordered={false} className="search_selectOption" onChange={selectValueChange}>
                                <Option key="total" value="total">전체</Option>
                                <Option key="complete" value="complete">완료</Option>
                                <Option key="cancel" value="cancel">취소</Option>
                            </Select>
                        </div>
                        <div className="date_picker">
                            <RangePicker
                                bordered={false}
                                style={{ margin: "0 5%",width:"100%"}}
                                format={defaultFormat}
                                value={[moment(fromDate, defaultFormat), moment(toDate, defaultFormat)]}
                                onChange={onOperatingDate}
                            />
                        </div>
                        <div className="search_line" onClick={searchList}>
                            <img src={addressSearch} />
                            <p>조회</p>
                        </div>
                    </div>
                    {iList.length > 0 ?
                        <>
                            {iList.slice(startPage, endPage).map((item, index) => {
                                return (
                                    <div key={"listing" + index.toString()} className="table_box">
                                        <div className="content_line">
                                            <div className="content1">
                                                <p>{item.carNm}</p>
                                                <div className="state_box" style={{ background: item.status === "cancel" ? "#666666" : "#001E62" }}>
                                                    <p>{item.status === "cancel" ? "취소" : "정상"}</p>
                                                </div>
                                            </div>
                                            <div className="content2">
                                                <p>{item.list}</p>
                                            </div>
                                        </div>
                                        <div className="price_line">
                                            <p>{item.totalPay}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        :
                        <div className="table_box_none">
                            <p>데이터가 없습니다.</p>
                        </div>
                    }
                    <div className="pagination_box">
                        <Pagination size="small" defaultPageSize={5} current={sCurrent} onChange={onChangePage} total={iList.length} />
                    </div> */}
         </div>
         {/* } */}
      </Style>
   )
}

export default Payment;

const Style = styled.div`
.renewalPage {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        margin: 10px 0 30px 0;
        font-size: 40px;
    }
    p {
        font-size: 20px;
        color: #888;
    }
}
    /* .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        height: 6.5vh;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .payment_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .payment_title_line, .number {
        padding: 20px 16px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .first_line {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        .payment_line {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                font-style: normal;
                font-weight: normal;
                font-size: 0.8rem;
                line-height: 21px;
            }

            .today_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 6vh;
                border: 1px solid #617BE3;
                border-radius: 5px;
                margin-top: 2%;
                margin-right: 5%;

            }
            .yesterday_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 6vh;
                border: 1px solid #617BE3;
                border-radius: 5px;
                margin-top: 2%;
            }

            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #617BE3;
            }
        }
    }

    .number {
        border-bottom: 1px solid #dfdfdf;

        .select_value {
            height: 10vh;

            .search_selectOption {
                display: flex;
                align-items: center;
                height: 7vh;
                border : 1px solid #000;
                border-radius: 5px;
                font-size: 0.8rem;
            }

        }

        .date_picker {
            display: flex;
            align-items: center;
            height: 7vh;
            border : 1px solid #000;
            border-radius: 5px;
            font-size: 0.8rem;
            margin-bottom: 10%;
        }


        .search_line {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 7vh;
            border-radius: 5px;
            background-color: #617BE3;

            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                margin-left: 3%;
                color: #fff; 
            }
        }

    }

    .table_box_none {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vh;
        background: #fff;
        border-bottom: 1px solid #dfdfdf;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 24px;
        }
    }
    .table_box {
        display: flex;
        align-items: center;
        height: 13vh;
        background: #fff;
        border-bottom: 1px solid #dfdfdf;

        .content_line {
            width: 65%;
            
            .content1 {
                display: flex;
                align-items: flex-end;
                margin-left: 7%;
                width: 100%;
                height: 7vh;

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.1rem;
                    line-height: 24px;
                }
                .state_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 3vh;
                    width: 37px;
                    margin-left: 5%;
                    background: #666666;
                    border-radius: 5px;

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.7rem;
                        color: #fff; 
                    }
                }
            }
            .content2 {
                display: flex;
                align-items: flex-start;
                margin-left: 7%;
                width: 100%;
                height: 7vh;

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.9rem;
                    line-height: 24px;
                }
            }
        }

        .price_line {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35%;
            height: 14vh;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                margin-right: 10%;
                color: #000; 
            }
        }

    }

    .pagination_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7vh;
        background: #fff;
    } */


`


