import React, { useState, useEffect } from 'react';
import Loader from "react-loader-spinner";
import { pulse } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import styled from 'styled-components';

import PieChart from '../../../../variables/PieChart';

import productIcon from '../../../../assets/svg/productIcon.svg';
import manual from '../../../../assets/svg/guidenoticebox.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';

const styles = {
    pulse: {
        animation: 'infinite 1s',
        animationName: Radium.keyframes(pulse, 'pulse')
    }
}

const Product = ({ sData, iState, fnMove, mState }) => {
    const [loading, setLoading] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [isState, setState] = useState(false);

    const moveCategory = async () => {
        if (fnMove !== undefined && typeof fnMove === "function") {
            await fnMove("product");
        }
    }

    useEffect(() => {
        if (sData.data !== undefined && sData.data !== null) {
            setDataList(sData);
            setLoading(false);
        }
        setState(iState);
    }, [sData, iState]);

    return (
        <StyleRoot>
            <BrowserView>
                {isState ?
                    <div className="product_wrapper">
                        <div className="title">
                            <h2>상품판매 TOP5</h2>
                        </div>
                        <div className="chartBox">
                            <div className="wrapper_chart">
                                {!loading ?
                                    <PieChart sChart={dataList} />
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", height: "15vh", alignItems: "center" }}>
                                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                                    </div>
                                }
                            </div>
                        </div>
                        <Link to={WebRoute.ADMIN_PRODUCT_CATEGORY}>
                            <button >상품관리 바로가기</button>
                        </Link>
                    </div>
                    :
                    <div className="sContent" style={mState ? styles.pulse : null}>
                        {mState &&
                            <div className="guide_notice_box">
                                <img src={manual} alt="none" />
                            </div>
                        }
                        <div style={{ height: "7vh" }}>
                            <h2>상품을 등록해보세요.</h2>
                            <h3 style={{ marginBottom: "5%" }}>상품 등록하시고 매장을 활성화하세요.</h3>
                        </div>
                        <div className="img_box">
                            <img src={productIcon} alt="none" />
                        </div>
                        <Link to={WebRoute.ADMIN_PRODUCT_PRODUCT}>
                            <div style={{ height: "5vh" }}>
                                <button onClick={moveCategory}>상품정보 등록</button>
                            </div>
                        </Link>
                    </div>
                }
            </BrowserView>
            <MobileView>
                {isState ?
                    <div className="content2">
                        <Style>
                            <h2>상품판매 <br />TOP5</h2>
                            <div className="chartBox">
                                {!loading ?
                                    <PieChart sChart={dataList} />
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", height: "15vh", alignItems: "center" }}>
                                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                                    </div>
                                }
                                <Link to={WebRoute.ADMIN_PRODUCT_CATEGORY}>
                                    <button onClick={moveCategory} className="button">상품관리 바로가기</button>
                                </Link>
                            </div>
                        </Style>
                    </div>
                    :
                    <div className="content3" style={mState ? styles.pulse : null}>
                        {mState &&
                            <div className="guide_notice_box">
                                <img src={manual} alt="none" />
                            </div>
                        }
                        <h2>상품을 등록해보세요.</h2>
                        <h3>상품 등록하시고 매장을 활성화하세요.</h3>
                        <div className="img_box">
                            <img src={productIcon} alt="none" />
                        </div>
                        <Link to={WebRoute.ADMIN_PRODUCT_PRODUCT}>

                            <button onClick={moveCategory} className="button">상품정보 등록</button>
                        </Link>
                    </div>
                }
            </MobileView>
        </StyleRoot>
    )
}

export default Product;

const Style = styled.div`
    height: 37vh;

    h2 {
        font-size: 0.8rem;
        font-weight: 700;
        line-height: 21px;
        color: #617BE3;
    }
    p {
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 21px;
    }
`;