import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import '../../../../assets/css/font.css';

import Accordion from '../accordion/Accordion';

const NoticePage = ({ iList }) => {
    return (
        <NoticeStyle>
            <div className="noticeMore">
                <div className="container">
                    <div className="nContent">
                        <h1>공지사항</h1>
                        {iList.map((item,index) => {
                            return (
                                <Accordion key={index} title={item.title} content={item.content} date={moment(item.created_at).format('LL')} />
                            )
                        })}
                    </div>
                </div>
            </div>
        </NoticeStyle>
    )
};
export default NoticePage;

export const NoticeStyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .container {
        width: 1120px;
        margin: 0 auto;
    }
    p, a, h1, h2, h3, h4, h5, h6, li, ul, button {
        font-family: 'NanumSquare';
        transform: skew(-0.1deg);
        font-weight: 900;
    }
    ul,li {
        list-style: none;
    }
    h1 {
        font-size: 54px;
    }

    .nContent {
        width: 100%;
        margin: 129px 0 182px 0;

        h1 {
            padding-bottom: 48px;
            text-align: center;
        }
    }
   
`
