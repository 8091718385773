import React, { useState, useEffect, Fragment } from 'react';
import Modal from 'react-modal';
import { message, Select } from 'antd';
import "antd/dist/antd.css";
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import styled from 'styled-components';
import AlcoholModal from '../../../modal/AlcoholModal';


const { Option } = Select;

const InsertForm = ({ fnAddList, sHasList, sLimit, alcoholList }) => {
   const iAlcoholList = alcoholList;

   const [iStatus, setStatus] = useState("");
   const [hasList, setHasList] = useState("none");
   const [iLimit, setLimit] = useState(false);

   const [sNm, setNm] = useState("");
   const [isMain, setIsMain] = useState("no");

   const [classNm, setClassNm] = useState(null);
   const [errClassNm, setErrClassNm] = useState("");

   const [isChecked, setIsChecked] = useState(false);
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [successMessage, set_successMessage] = useState('');
   const [checkValue, set_checkValue] = useState('');

   // 주류 안내 모달
   function handleCheckboxChange() {
      setIsChecked(!isChecked);
      if (isChecked === false) {
         setIsChecked(!isChecked);
      }
   }
   function handlePopupOpen() {
      if (isChecked === true) {
         setModalIsOpen(false);

      } else if (isChecked === false) {
         setModalIsOpen(true);
      }
   }

   const inputClassStoreNm = () => {
      setClassNm("input_action");
   }

   const onChangeStoreNameValue = (event) => {
      // setClassNm("input_action");
      setErrClassNm("");
      setNm(event.target.value);
   }

   const setChangeMain = (event) => {
      if (isMobile) {
         setIsMain(event);
      } else setIsMain(event.target.value);
      setClassNm(null);
   }

   const registerCategory = async () => {
      if (fnAddList !== undefined && typeof fnAddList === "function") {
         if (sNm === "") {
            setErrClassNm("분류명을 입력해주세요");
            // setClassNm("input_wrong");
         } else {
            let temp = true;
            if (iLimit) {
               if (isMain === "yes") {
                  message.error("이미 등록된 대표메뉴가 있습니다");
                  temp = false;
               }
            }
            let oData = {
               sNm,
               isMain,
               menuId: hasList,
               isUse: "use",
               checkValue
            }
            temp && fnAddList(oData);
            console.log('분류저장oData',oData)
         }
      }
   }

   const changeStatus = (sIndex) => {
      setNm("");
      setErrClassNm("");
      setClassNm(null)
      if (sIndex === "new") {
         setStatus("new");
      } else {
         setStatus("");
      }
   }

   useEffect(() => {
      setHasList(sHasList);
      setLimit(sLimit);
   }, [sHasList, sLimit])

   return (
      <Fragment>
         <BrowserView>
            <Style>
               <div className="container">
                  <div className="title_box">
                     <h2>상품분류</h2>
                     {iStatus !== "" ?
                        <div className="cancel_box" onClick={() => changeStatus("")}>
                           <p>등록취소</p>
                        </div>
                        :
                        <div className="new_category_box" onClick={() => changeStatus("new")}>
                           <p>분류등록</p>
                        </div>
                     }
                  </div>
                  {iStatus !== "" &&
                     <>
                        <div className="category_title">
                           <p>분류명</p>
                           <input
                              type="text"
                              placeholder="ex) 고기류,밥류,면류, MAIN"
                              className={classNm}
                              // onClick={inputClassStoreNm}
                              value={sNm}
                              onChange={onChangeStoreNameValue}
                           />
                           <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errClassNm}</p>
                        </div>
                        <div className="alcohol">
                           <label htmlFor="alcohol-check">
                              <input id="alcohol-check" type="checkbox" style={{ width: '18px', height: '18px', marginRight: '5px' }} onChange={handleCheckboxChange} checked={isChecked} onClick={handlePopupOpen} />
                              <p>주류 메뉴를 등록하시겠습니까? </p>
                           </label>
                           <p className={successMessage === "" ? 'nSuccessText' : 'successText'}>{successMessage}</p>
                        </div>
                        <div className="category_title">
                           <p>대표메뉴 설정</p>
                           <select value={isMain} onChange={setChangeMain}  >
                              <option value="yes">예</option>
                              <option value="no">아니오</option>
                           </select>
                        </div>
                        <div className="location_box_line" style={{ marginTop: "10px" }}>
                           <div className="how_box">
                              <p>대표메뉴설정시 순서설정과 상관없이 메뉴 상단에 우선 노출됩니다.<br /> 가장 인기있는 상품들은 대표메뉴로 설정해주세요!</p>
                           </div>
                        </div>
                        <div className="insert_box" onClick={registerCategory}>
                           <p>분류등록하기</p>
                        </div>
                        <AlcoholModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} setIsChecked={setIsChecked} fnAddList={fnAddList} iAlcoholList={iAlcoholList} set_successMessage={set_successMessage} set_checkValue={set_checkValue} />
                     </>
                  }

               </div>
            </Style>
         </BrowserView>
         <MobileView>
            <Mstyle>
               <div className="category_title_line">
                  <div className="category_title">
                     <div className="title_box">
                        <p className="title_line">상품분류</p>
                        <p className="detail_line">(최소 1개 필수)</p>
                     </div>
                     {iStatus !== "" ?
                        <div className="cancel_box" onClick={() => changeStatus("")}>
                           <p>등록취소</p>
                        </div>
                        :
                        <div className="insert_box" onClick={() => changeStatus("new")}>
                           <p>+ 분류등록</p>
                        </div>
                     }
                  </div>
                  {iStatus !== "" &&
                     <>
                        <div className="inputText">
                           <h3>분류명</h3>
                           <input
                              type="text"
                              placeholder="그룹명을 입력해주세요."
                              className={classNm}
                              onClick={inputClassStoreNm}
                              value={sNm}
                              onChange={onChangeStoreNameValue}
                           />
                           <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errClassNm}</p>
                        </div>
                        <div className="alcohol">
                           <label htmlFor="alcohol-check">
                              <input id="alcohol-check" type="checkbox" style={{ width: '18px', height: '18px', marginRight: '5px' }} onChange={handleCheckboxChange} checked={isChecked} onClick={handlePopupOpen} />
                              <p>주류 메뉴를 등록하시겠습니까? </p>
                           </label>
                           <p className={successMessage === "" ? 'nSuccessText' : 'successText'}>{successMessage}</p>
                        </div>
                        <div className="inputText">
                           <h3>대표메뉴설정</h3>
                           <Select value={isMain} onChange={setChangeMain} className="select_bank" bordered={false}>
                              <Option value="yes">예</Option>
                              <Option value="no">아니오</Option>
                           </Select>
                        </div>
                        <div className="last">
                           <button className="finishButoon" onClick={registerCategory}>등록하기</button>
                        </div>
                     </>
                  }
                  <AlcoholModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} setIsChecked={setIsChecked} fnAddList={fnAddList} iAlcoholList={iAlcoholList} set_successMessage={set_successMessage} set_checkValue={set_checkValue} />
               </div>
            </Mstyle>
         </MobileView>
      </Fragment>
   )
}

export default InsertForm;

const Style = styled.div`
    .alcohol {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        p {
            font-weight: 700;
        }

        label {
          display: flex;
          align-items: center;
        }
    }
    .successText{
        margin-left: 5px;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 400!important;
        border: 1px solid #6490E7;
        border-radius: 4px;
        background: #E8EFFC;
        color: #6490E7
    }
`

const Mstyle = styled.div`
    .alcohol {
        display: flex;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 15px;

        p {
            font-weight: 700;
        }

        label {
          display: flex;
          align-items: center;
        }
    }
    .successText{
        margin-left: 5px;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 400!important;
        border: 1px solid #6490E7;
        border-radius: 4px;
        background: #E8EFFC;
        color: #6490E7
    }
`