import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { WebRoute } from "../../../navigation/WebRoutes";
import "../../../assets/css/font.css";

import facebookSVG from "../../../assets/svg/mainPage/facebook.svg";
import naverSVG from "../../../assets/svg/mainPage/naver.svg";
import instagramSVG from "../../../assets/svg/mainPage/instagram.svg";

const Footer = (oProps) => {
  const [scrollPosition, setScrollPosition] = useState("footer");
  const [removeKakao, setRemoveKakao] = useState("remove");
  const [bIsPosStore, set_bIsPosStore] = useState(false);

  let sDomainUrl = "https://throo.co.kr";
  if (
    process.env !== undefined &&
    process.env.REACT_APP_THROO_WEBURL !== undefined
  ) {
    sDomainUrl = process.env.REACT_APP_THROO_WEBURL;
  }

  const openCEOKakao = () => {
    if (isMobile) {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    } else {
      window.open("https://pf.kakao.com/_tzxkNK/chat", "_blank");
    }
  };
  const throoHomepage = () => {
    window.open(sDomainUrl, "_blank");
  };

  useEffect(() => {
    let oUserData = oProps.UserConfigReducer;
    if (oUserData && oUserData.isPOSStore === true) {
      set_bIsPosStore(true);
    }
  }, []);

  if (bIsPosStore === true) {
    return <M_footer></M_footer>;
  }

  return (
    <M_footer>
      <div className={scrollPosition}>
        <div className={removeKakao} id={"kakaoButton"}>
          <div id="footer">
            <div className="fContainer">
              <div className="footerPage">
                <div className="footerContent">
                  <ul className="content">
                    <h3>서비스</h3>
                    <li>
                      <a href={sDomainUrl + "/company"}>
                        <p>회사소개</p>
                      </a>
                    </li>
                    <li>
                      <p>
                        <Link to={WebRoute.SERVICE}>고객센터</Link>
                      </p>
                    </li>
                    <li onClick={throoHomepage}>스루 홈페이지</li>
                  </ul>
                  <ul className="content">
                    <li>
                      <h3>입점문의</h3>
                    </li>
                    <li>
                      <a href="mailto:partners@ivid.kr">
                        <p>
                          메일: <span>partners@ivid.kr</span>
                        </p>
                      </a>
                    </li>
                    <li>
                      <p onClick={openCEOKakao}>
                        카카오톡(사장님): <span>@스루 사장님 창구</span>
                      </p>
                    </li>
                  </ul>
                  <div className="content">
                    <h3>전화문의</h3>
                    <a href="tel:1670-5324">
                      <p>1670-5324 (평일 09:00 - 18:00)</p>
                    </a>
                  </div>
                </div>
                <div className="companyInfo">
                  <h1>인비저블아이디어(주)</h1>
                  <p>
                    주소: 서울특별시 강남구 선릉로 622 (삼성동, 대영빌딩) 2층
                  </p>
                  <p>통신판매업신고: 2020-서울서초-3341</p>
                  <p>사업자등록번호: 159-86-01794, 대표자: 윤언식</p>
                  <p>© INVISIBLE IDEAS All rights reserved.</p>
                </div>
                <div className="sns">
                  <a
                    href="https://www.facebook.com/throo2020/"
                    target="_blank"
                    aria-label="Facebook"
                    rel="noreferrer"
                  >
                    <SVG
                      src={facebookSVG}
                      width="2vw"
                      height="100%"
                      title="페이스북 로고"
                    />
                  </a>
                  <a
                    href="https://blog.naver.com/throo2020"
                    target="_blank"
                    aria-label="naver"
                    rel="noreferrer"
                  >
                    <SVG
                      src={naverSVG}
                      width="2vw"
                      height="100%"
                      title="네이버 로고"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/throo_official/"
                    target="_blank"
                    aria-label="Instagram"
                    rel="noreferrer"
                  >
                    <SVG
                      src={instagramSVG}
                      width="2vw"
                      height="100%"
                      title="인스타그램 로고"
                      strokeWidth="2000px"
                      rel="noreferrer"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </M_footer>
  );
};

export default Footer;

export const M_footer = styled.aside`
  html {
    overflow: hidden;
  }

  html,
  body {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  /* reset */
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li button,
  textarea {
    transform: skew(-0.1deg);
    color: #333;
    font-family: "NanumSquare";
  }
  ul,
  ol,
  li {
    font-family: "NanumSquare";
    text-decoration: none;
    list-style: none;
  }
  a,
  li,
  ul,
  span {
    color: #888;
    text-decoration: none;
    outline: none;
    font-weight: 700;
  }
  a:hover,
  a:active {
    text-decoration: none;
    color: #666;
    font-weight: 700;
  }

  /* footer */
  #footer {
    padding-top: 60px;
    border-top: 1px solid #ebebeb;
    margin: 0px 0 60px 0;
  }
  .fContainer {
    width: 100%;
    margin: 0 auto;
    padding: 0 5vw;
    display: flex;
    align-items: flex-end;
  }
  .footerContent {
    width: 100%;
    margin-bottom: 20px;
  }
  .footerContent ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .content h3 {
    margin-bottom: 8px;
    font-family: "NanumSquare";
    font-size: 14px;
    font-weight: 900;
    color: #000;
  }
  .content p {
    margin-bottom: 3px;
    font-family: "NanumSquare";
    font-size: 14px;
    font-weight: 700;
    color: #888888;
  }
  .content p span:hover {
    color: #666;
    font-weight: 700;
  }

  /* companyInfo */
  .companyInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .companyInfo h1 {
    margin-bottom: 10px;
    font-family: "NanumSquare";
    font-size: 12px;
    font-weight: 700;
    color: #888888;
    line-height: 8px;
  }
  .companyInfo p {
    font-family: "NanumSquare";
    font-size: 12px;
    font-weight: 700;
    color: #888888;
    line-height: 8px;
  }

  /* sns */
  .sns {
    width: 100%;
  }
  .sns svg {
    width: 36px;
    height: 36px;
    margin-right: 6px;
  }

  .main_footer {
    display: block;
  }
`;
