import styled from "styled-components";
import "../css/font.css";

export const DashboardStyle = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
    color: #000;
  }

  .dashboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 260px);
    min-height: calc(100vh - 102px - 72px);
    padding: 23px;
    background-color: #f1f3f7;

    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 29px;
    }
    h3 {
      margin-bottom: 24px !important;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    button {
      width: 338px;
      height: 48px;
      margin-top: 115px;
      background-color: #001e62;
      border-radius: 8px;
      border: none;
      outline: none;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }
  }

  .left {
    flex: 0 0 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .downContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
    }
    .order {
      display: flex;

      .orderLeft {
        flex: 0 0 50%;

        h2 {
          margin: 23px 0 16px 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          span {
            font-size: 20px;
            font-weight: 700;
            line-height: 29px;
          }
        }

        .orderBoxTop {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .box {
            width: 32%;
            min-height: 75px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(223, 223, 223, 1);
            border-radius: 4px;

            h2 {
              margin: 0 0 4px 0;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
            }
            p {
              font-size: 13px;
              font-weight: 400;

              span {
                font-size: 20px;
                font-weight: 700;
              }
            }
          }
        }
        .orderBoxBottom {
          .box {
            margin-top: 7px;
            padding: 8px 14px;
            width: 100%;
            min-height: 75px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid rgba(223, 223, 223, 1);

            h2 {
              margin: 0;
            }
            .totalCancle {
              text-align: center;
              h2 {
                font-size: 14px;
                font-weight: 400;
              }
              p {
                font-size: 13px;
                font-weight: 400;

                span {
                  font-size: 20px;
                  font-weight: 700;
                }
              }
              h3 {
                margin: 0 !important;
                font-size: 10px;
                font-weight: 400;
                color: #666;
              }
            }
            .cancle {
              text-align: center;

              h2 {
                font-size: 12px;
                font-weight: 400;
                color: #666;
              }
              p {
                font-size: 11px;
                font-weight: 400;
                color: #666;

                span {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  color: #666;
                }
              }
            }
          }
        }
      }
      .orderRight {
        flex: 0 0 50%;
        background-color: gray;
      }
    }
  }
  .right {
    flex: 0 0 49%;
    display: flex;
    flex-direction: column;

    .topContent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
    }
  }

  .lContent {
    margin-right: 20px;
    background-color: #fff;
    width: 796px;
    padding: 24px;
    min-height: 320px;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  }
  .sContent {
    background-color: #fff;
    width: 386px;
    min-height: 320px;
    padding: 32px 24px 16px 24px;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      color: #617be3;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #666;
    }
  }

  /* 스루 가이드 */
  .guide {
    h3 {
      margin-bottom: 32px !important;
    }

    .box {
      margin-right: 8px;
    }

    .imgBox {
      margin-bottom: 6px;
      width: 192px;
      height: 128px;
      background-color: #e4f0fe;
      border-radius: 8px;
    }
  }

  /* 공지사항 */
  .notice {
    background-color: #fff;
    width: 386px;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);

    .title {
      padding: 24px;
    }
    .text {
      height: 20vh;
      padding: 0px 24px 19px 24px;
      border-bottom: 1px solid #dfdfdf;
      .textContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 0;

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
        p:nth-child(1) {
          width: 220px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      min-height: 71px;
      background-color: #fff;
      p {
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        color: #666;
        margin-right: 6px;
      }
    }
  }
  @media screen and (max-width: 1800px) {
    .dashboard {
      flex-direction: column;
    }
    .left {
      margin-bottom: 24px;
    }
  }
`;

export const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color:#000;
    }

    .guide_notice_box {
        position: absolute;
        top: -5%;
        left: 0%;
    }

    .wrapper {
        width: calc(100vw - 260px);
        height: calc(100vh - 6.7vh);
        background-color: #F1F3F7;
        padding-top: 2.2%;
        padding-left: 1%;
        padding-right: 1%;
        padding-bottom: 2.2%;
        display: flex;
        flex-direction: column;

        .kakakoCeo {
            position: fixed;
            padding: 0;
            bottom: 0;
            right: 0;
            padding-right:  .5%;
            margin-bottom: 8px;
            button {
                padding: 0;
                outline: none;
                border: none;
                background-color: #F1F3F7;
            }
        }
    }

    .firstLine {
        border-radius: 4px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        background: #fff;
        height: 37vh;
        margin-bottom: 1%;
    }

    .secondLine {
        margin-left: 4%;
        margin-bottom: 1%;
        border-radius: 4px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        background: #fff;
        height: 37vh;
    }

    .thirdLine {
        margin-right: 4%;
        margin-top: 2%;
        border-radius: 4px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        background: #fff;
        height: 37vh;
    }

    .forthLine {
        margin-top: 2%;
        border-radius: 4px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        background: #fff;
        height: 37vh;
    }

    .lastLine {
        margin-left: 2%;
        margin-top: 1%;
        border-radius: 4px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        background: #fff;
        height: 37vh;
    }

    .topContent {
        padding: 2%;
    }

    .guide {
        padding: 2%;

        h3 {
            font-size: 1rem;
            margin-top: 1%!important;
            margin-bottom: 5%!important;
        }

        .box {
            margin-right: 2%;
            cursor: pointer;
        }

        .imgBox {
            margin-bottom: 2%;
            width: 192px;
            height: 14vh;
            background-color: #E4F0FE;
            border-radius: 8px;

            img {
                width: 100%;
                height: 14vh;
                border-radius: 4px;
            }
        }
    }

    .notice {
        .title {
            height: 5vh;
            padding: 8%;
        }
        
        .text {
            padding: 0px 20px 19px 20px;
            border-bottom: 1px solid #DFDFDF;
            height: 23vh;
            position: "relative";
            overflow-y: scroll;
            overflow-x: hidden;

            .textContent {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 7px 0;
                height: 4vh;

                p {
                    font-size: 0.7rem;
                    font-weight: 400;
                    line-height: 18px;
                }
                p:nth-child(1) {
                    width: 220px;
                    white-space: nowrap; 
                    overflow: hidden; 
                    text-overflow: ellipsis;
                }
            }
        }

        .moreButton {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            height: 8vh;
            cursor: pointer;
            
            .button_wrapper {
                p {
                    text-align: center;
                    font-size: 0.8rem;
                    line-height: 21px;
                    font-weight: 700;
                    color: #666;
                }
            }
        }
        button {
            
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 3vh;

        h1 {
            font-size: 0.8rem;
            font-weight: 700;
            line-height: 21px;
            color: #617be3;
        }
        p {
            font-size: 0.6rem;
            font-weight: 400;
            color: #666;
        }
    }

    .sContent {
        padding-top: 10%;
        padding-left: 5%;

        h2 {
            font-size: 1.2rem;
            font-weight: 700;
            line-height: 29px;
        }
        h3 {
            margin-bottom: 24px!important;
            font-size: 0.7rem;
            font-weight: 700;
            line-height: 18px;
        }
        p {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 18px;
        }
        
        button {
            width: 95%;
            height: 40px;
            background-color: #001e62;
            border-radius: 8px;
            border: none;
            outline: none;
            color: #fff;
            font-size: 0.9rem;
            font-weight: 700;
            line-height: 21px;
        }

        .img_box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 19vh;

            img {
                height: 19vh;
                width: 100%;
            }
        }
    }

    .order {
            display: flex;
            
            .orderLeft {
                flex: 0 0 50%;

                h2 {
                    margin: 15px 0 16px 0;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 24px;

                    span {
                        font-size: 1.1rem;
                        font-weight: 700;
                        line-height: 29px;
                    }
                }

                .orderBoxTop {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .box {
                        width: 32%;
                        height: 9vh;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba(223, 223, 223, 1);
                        border-radius: 4px;

                        h2 {
                            margin: 0 0 4px 0;
                            font-size: 0.8rem;
                            font-weight: 400;
                            line-height: 21px;
                        }
                        p {
                            font-size: 0.7rem;
                            font-weight: 400;

                            span {
                                font-size: 0.9rem;
                                font-weight: 700;
                            }
                        }
                    }
                }
                .orderBoxBottom {
                    .box {
                        margin-top: 7px;
                        padding: 8px 14px;
                        width: 100%;
                        height: 10vh;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid rgba(223, 223, 223, 1);

                        h2 {
                            margin: 0;
                        }
                        .totalCancle {
                            text-align: center;
                            h2 {
                                font-size: 0.8rem;
                                font-weight: 400;
                            }
                            p {
                                font-size: 0.7rem;
                                font-weight: 400;
                                
                                span {
                                    font-size: 1.1rem;
                                    font-weight: 700;
                                }
                            }
                            h3 {
                                margin: 0!important;
                                font-size: 0.5rem;
                                font-weight: 400;
                                color: #666;
                            }
                        }
                        .cancle {
                            text-align: center;
                           
                            h2 {
                                font-size: 0.7rem;
                                font-weight: 400;
                                color: #666;
                            }
                            p {
                                font-size: 0.7rem;
                                font-weight: 400;
                                color: #666;

                                span {
                                    font-size: 1rem;
                                    font-weight: 400;
                                    line-height: 24px;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            }
            .orderRight {
                flex: 0 0 50%;
                height: 31vh;

                .chart_corner {
                    margin: 5%;
                    height: 28vh;
                }
            }

        }
    }

    /* 상품관리 */
    .product_wrapper {
        padding: 5%;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 4vh;

            h2 {
                font-size: 14px;
                font-weight: 700;
                line-height: 21px;
                color: #617BE3;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
        }

        .chartBox {
            padding: 1.5%;
        }
        .wrapper_chart {
            height: 23vh;
        }

        button {
            width: 100%;
            height: 4vh;
            background-color: #001e62;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            border: none;
            outline: none;
            border-radius: 8px ;
        }
    }

    /* 사업자완료 */
    .buisness {
        padding: 5%;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 4vh;

            h2 {
                font-size: 0.8rem;
                font-weight: 700;
                line-height: 21px;
                color: #617BE3;
            }
            p {
                font-size: 0.6rem;
                font-weight: 400;
                line-height: 21px;
            }
        }
        .subTitle { 
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 23px 0 27px 0;
            height: 2vh;

            h2 {
                font-size: 1rem;
                font-weight: 700;
                line-height: 24px;
            }
            p {
                font-size: 0.9rem;
                font-weight: 400;
                line-height: 24px;
            }
        }
        .salesBox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 31px 0 25px 0;
            margin-bottom: 24px;
            border: 1px solid #DFDFDF;
            border-radius: 4px;
            height: 13.5vh;

            h2 {
                font-size: 0.8rem;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
            }
            h3 {
                font-size: 1rem;
                font-weight: 700;
                line-height: 29px;
                text-align: center;
            }

            .left {
                border-right: 1px solid #c4c4c4;
                flex: 50%;
            }
            .right {
                flex: 50%;
            }
        }

        button {
            width: 100%;
            height: 4vh;
            background-color: #001e62;
            color: #fff;
            font-size: 0.8rem;
            font-weight: 700;
            border: none;
            outline: none;
            border-radius: 8px ;
        }
    }

    /* store */
    .store {
        padding: 5%;

        .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 4vh;

            h2 {
                font-size: 0.8rem;
                font-weight: 700;
                line-height: 21px;
                color: #617BE3;
            }
        }
        .subTitle { 
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 12px 0 23px 0;
            height: 2vh;

            h2 {
                margin-right: 12px;
                font-size: 1rem;
                font-weight: 700;
                line-height: 29px;
            }
            
        }
        .salesBox {
            padding: 16px 0;
            border: 1px solid #DFDFDF;
            border-radius: 4px;
            height: 15vh;
            
            h2 {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666;
            }
            p {
                font-size: 0.8rem;
                font-weight: 700;
            }
            .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 22px;
                height: 5vh;

                .time {
                    flex: 0 0 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
                .meter {
                    flex: 0 0 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
            }
            .bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 5vh;

                .selection {
                    flex: 0 0 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
                .parking {
                    flex: 0 0 50%;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }
            }
        }
        .storeButton {
            margin-top: 9%;
            width: 100%;
            height: 4vh;
            background-color: #001e62;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            border: none;
            outline: none;
            border-radius: 8px ;
        }
    }

    


`;

export const MobileStyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }
    
    .guide_notice_box {
        position: absolute;
        top: -5%;
        left: 5%;
    }

    .dashboard {
        background-color: #F1F3F7;
        padding: 10px 0;

        .content1 {
            width: 100%;
            min-height: 452px;
            background-color: #fff;
            margin-bottom: 16px;
            padding: 32px 24px 18px 24px;

            .title {
                padding: 0;
                margin-bottom: 8px;
            }
            .order {
                h2 {
                    margin: 24px 0 16px 0;
                    font-size: 16px;
                    font-weight: 400;
                    
                    span {
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
                .orderBoxTop {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align: center;
                    
                    .box {
                        width: 32%;
                        min-height: 75px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba(223, 223, 223, 1);
                        border-radius: 4px;

                        h2 {
                            margin: 0 0 4px 0;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                        }
                        p {
                            font-size: 13px;
                            font-weight: 400;

                            span {
                                font-size: 20px;
                                font-weight: 700;
                            }
                        }
                    }
                }
                .orderBoxBottom {
                    .box {
                        margin-top: 7px;
                        padding: 8px 14px;
                        width: 100%;
                        min-height: 75px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid rgba(223, 223, 223, 1);
                        border-radius: 4px;

                        h2 {
                            margin: 0;
                        }
                        .totalCancle {
                            text-align: center;
                            h2 {
                                font-size: 14px;
                                font-weight: 400;
                            }
                            p {
                                font-size: 13px;
                                font-weight: 400;
                                
                                span {
                                    font-size: 20px;
                                    font-weight: 700;
                                }
                            }
                            h3 {
                                margin: 0!important;
                                font-size: 10px;
                                font-weight: 400;
                                color: #666;
                            }
                        }
                        .cancle {
                            text-align: center;
                           
                            h2 {
                                font-size: 12px;
                                font-weight: 400;
                                color: #666;
                            }
                            p {
                                font-size: 11px;
                                font-weight: 400;
                                color: #666;

                                span {
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 24px;
                                    color: #666;
                                }
                            }
                        }
                    }
                }
            } 

            .chart_corner {
                margin-top: 5%;
                border: 1px solid #dfdfdf;
                border-radius: 4px;
                height: 25vh;

                .chartBox {
                    height: 20vh;
                    padding: 5%;
                }
            }  
        }
            
    }

    .dashboard.is-pos-store {
      padding: 0 0;
   }
   
        .content2 {
            margin: 16px;
            min-height: 328px;
            padding: 32px 24px 18px 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
            
            .img_box {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20vh;

                img {
                    height: 20vh;
                    width: 100%;
                }
            }

            .button {
                margin-top: 130px;
            }

            .chartBox {
                height: 25vh;
                .button {
                    margin-top: 1%;
                }
            }  
        }
        .content3 {
            margin: 16px;
            min-height: 256px;
            padding: 32px 24px 18px 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);

            .img_box {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20vh;

                img {
                    height: 20vh;
                    width: 100%;
                }
            }
            
            .button {
                margin-top: 60px;
            }
        }
        .content4 {
            margin: 16px;
            min-height: 224px;
            padding: 32px 24px 18px 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);

            .img_box {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 25vh;

                img {
                    height: 25vh;
                    width: 100%;
                }
            }

            .button {
                margin-top: 28px;
            }
        }
        .content5 {
            margin: 16px;
            min-height: 311px;
            padding: 32px 24px 18px 24px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);

            .title {
                padding: 0;
                margin-bottom: 8px;
            }

            .box {
                margin-right: 8px;
            }

            .imgBox {
                margin-bottom: 6px;
                width: 128px;
                height: 128px;
                background-color: #E4F0FE;
                border-radius: 8px;

                img {
                    width: 100%;
                    height: 128px;
                    border-radius: 4px;
                }
            }
            
        }
        .content6 {
            margin: 16px;
            min-height: 311px;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);

            .title {
                padding: 0;
                margin-bottom: 24px;
                padding: 32px  24px 0px 24px;
            }
            .text {
                padding: 0px 24px 37px 24px;
                .textContent {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 7px 0;
                    

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                    }
                    p:nth-child(1) {
                        width: 80%;
                        white-space: nowrap; 
                        overflow: hidden; 
                        text-overflow: ellipsis;
                    }
                }
            }
            
            .moreButton {
                width: 100%;
                height: 52px;
                border-radius: 0px 0px 4px 4px;
                background-color: #001e62;
                border: 0;
                outline: 0;
                
                p {
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    color: #fff;
                }
            }
        }
        
        .kakao {
            margin: 16px;
            .kakaoButton {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 16px;
                min-height: 76px;
                border-radius: 4px;
                background-color: #FAE300;
                box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.25);
                border: 0;
                outline: 0;

                h2 {
                    flex: 0 0 80%;
                    display: flex;
                    justify-content: flex-start;
                    margin-left: 13px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    color: #391B1B;
                } 
            }
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;

        h1 {
            font-size: 14px;
            font-weight: 700;
            color: #617be3;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #666;
        }
    }
    h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
    }
    h3 {
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
    }
    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
    .button {
        width: 100%;
        height: 48px;
        border-radius: 8px;
        background-color: #001e62;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        color: #fff;
        border: none;
        outline: none;
    }
 
`;

export const MobileHeader = styled.div`
  /* hover문구 */
  .tip {
    position: relative;
    display: flex;
  }
  .tip span {
    display: none;
    position: absolute;
    top: 20px;
    left: -130px;
    width: 148px;
    min-height: 52px;
    padding: 8px 12px;
    z-index: 100;
    background: #f1f1f1;
    color: #000;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid rgba(223, 223, 223, 1);
  }
  .tip span strong {
    color: #617be3;
  }
  .tip:hover.tip span {
    display: block;
    margin-top: 5px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 48px;
    padding: 0 19px 0 16px;

    p {
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;

      span {
        margin-right: 4px;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        border-bottom: 1px solid #000;
      }
    }
    .right,
    .left {
      display: flex;
      align-items: center;
    }
    .left p {
      margin-right: 8px;
    }
    .box {
      width: 53px;
      height: 20px;
      margin: 0 5px;
      border-radius: 4px;
      background-color: rgba(102, 102, 102, 1);

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        color: #fff;
        font-size: 11px;
        font-weight: 700;
        line-height: 16.5px;
      }
    }
  }
`;

export const StoreInfoMobile = styled.div`
  .store {
    margin: 24px;
    width: 386px;
    border-radius: 4px;
    padding: 24px 24px 16px 24px;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      color: #617be3;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    .slideButton {
      display: flex;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #617be3;
      }
      .switch {
        margin-left: 6px;
      }
    }
  }
  .subTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0 5px 0;

    h2 {
      margin-right: 12px;
      font-size: 20px;
      font-weight: 700;
      line-height: 29px;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .salesBox {
    padding: 16px 0;
    margin-bottom: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;

    h2 {
      font-size: 11px;
      font-weight: 400;
      color: #666;
    }
    p {
      font-size: 16px;
      font-weight: 700;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 22px;

      .time {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      .meter {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .selection {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      .parking {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
  .storeButton {
    width: 100%;
    height: 48px;
    background-color: #001e62;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    border: none;
    outline: none;
    border-radius: 8px;
  }
`;
