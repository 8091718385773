import React, { useState, useEffect, useRef } from 'react';
import Loader from "react-loader-spinner";
import { message } from 'antd';
import "antd/dist/antd.css";
import styled from 'styled-components';

import {
    ModalBannerComamercial,
    ModalCouponComamercial,
    ModalThrooOnlyComamercial,
    ModalHotProductComamercial,
    ModalStoreComamercial,
    ModalKitComamercial,
    ModalPicketComamercial,
} from '../../../modal/commercial/pc/CommercialModal';

import {
    ModalBannerComamercial as ModalBannerComamercialMobile,
    ModalCouponComamercial as ModalCouponComamercialMobile,
    ModalThrooOnlyComamercial as ModalThrooOnlyComamercialMobile,
    ModalHotProductComamercial as ModalHotProductComamercialMobile,
    ModalStoreComamercial as ModalStoreComamercialMobile,
    ModalKitComamercial as ModalKitComamercialMobile,
    ModalPicketComamercial as ModalPicketComamercialMobile,
} from '../../../modal/commercial/mobile/CommercialModal';
import { BrowserView, MobileView } from 'react-device-detect';

const List = ({ iProps, commercial, iCartList, fnSetCart, fnMoveToPath }) => {
    const [isLoading, setLoading] = useState(false);
    const [bannerModal, setBannerModal] = useState(false);
    const [couponModal, setCouponModal] = useState(false);
    const [storeModal, setStoreModal] = useState(false);
    const [throoOnlyModal, setThrooOnlyModal] = useState(false);
    const [hotProductModal, setHotProductModal] = useState(false);
    const [kitModal, setKitModal] = useState(false);
    const [tableBannerModal, setTableBannerModal] = useState(false);

    const [commercialList, setCommercialList] = useState([]);
    const [couponList, setCouponList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [hotProductList, setHotProductList] = useState([]);

    const couponItem = useRef({});
    const bannerItem = useRef({});
    const storeItem = useRef({});
    const throoOnlyItem = useRef({});
    const hotProductItem = useRef({});
    const kitItem = useRef({});
    const tableBannerItem = useRef({});
    const cartList = useRef([]);

    const kitClose = async () => {
        setKitModal(false);
    }

    const picketClose = async () => {
        setTableBannerModal(false);
    }

    const bannerClose = async () => {
        setBannerModal(false);
    }

    const couponClose = async () => {
        setCouponModal(false);
    }

    const throoOnlyClose = async () => {
        setThrooOnlyModal(false);
    }

    const hotProductClose = async () => {
        setHotProductModal(false);
    }

    const storeClose = async () => {
        setStoreModal(false);
    }

    const moveToThrooOnly = async () => {
        if (typeof fnMoveToPath === "function" && fnMoveToPath !== undefined) {
            setThrooOnlyModal(false);
            fnMoveToPath("product");
        }
    }

    const moveToCoupon = async () => {
        if (typeof fnMoveToPath === "function" && fnMoveToPath !== undefined) {
            setCouponModal(false);
            fnMoveToPath("coupon");
        }
    }

    const checkItem = async (sIndex, aIndex) => {
        let temp = cartList.current;
        let iState = false;

        if (temp.length > 0) {
            for await (const iterator of temp) {
                if (iterator.key === sIndex.key) {
                    iState = true;
                    break;
                }
            }
        }
        if (!iState) {
            if (sIndex.param === "banner") {
                bannerItem.current = sIndex;
                setBannerModal(true);
            } else if (sIndex.param === "coupon") {
                couponItem.current = sIndex;
                setCouponModal(true);
            } else if (sIndex.param === "new") {
                storeItem.current = sIndex;
                setStoreModal(true);
            } else if (sIndex.param === "only") {
                throoOnlyItem.current = sIndex;
                setThrooOnlyModal(true);
            } else if (sIndex.param === "hot") {
                hotProductItem.current = sIndex;
                setHotProductModal(true);
            } else if (sIndex.param === "kit") {
                kitItem.current = sIndex;
                setKitModal(true);
            } else if (sIndex.param === "picket") {
                tableBannerItem.current = sIndex;
                setTableBannerModal(true);
            }
        } else {
            message.error("이미 선택된 상품입니다 장바구니에서 상품삭제후 선택해주세요");
        }
    }

    const getThrooOnlyList = async () => {
        const store_id = iProps.UserConfigReducer.StoreID;
        const oResponse = await iProps.oManager.fDoAxios("/store/commercial/throoOnly/getThrooOnlyList-" + store_id, "get", "login", null);
        if (oResponse !== undefined) {
            setProductList(oResponse);
        }
    }

    const getCouponList = async () => {
        const store_id = iProps.UserConfigReducer.StoreID;
        const oResponse = await iProps.oManager.fDoAxios("/store/commercial/coupon/getCouponList-" + store_id, "get", "login", null);
        if (oResponse !== undefined) {
            setCouponList(oResponse);
        }
    }

    const getHotProductList = async () => {
        const store_id = iProps.UserConfigReducer.StoreID;
        const oResponse = await iProps.oManager.fDoAxios("/store/commercial/product/getProductList-" + store_id, "get", "login", null);
        if (oResponse !== undefined) {
            setHotProductList(oResponse);
        }
    }

    const setCart = async (sIndex) => {
        if (sIndex !== undefined && sIndex !== null) {
            let temp = cartList.current;
            let iState = false;
            if (temp.length > 0) {
                for await (const iterator of temp) {
                    if (iterator.key === sIndex.key) {
                        iState = true;
                        break;
                    }
                }
            }

            if (!iState) {
                temp.push(sIndex);
                cartList.current = temp
                if (fnSetCart !== undefined && typeof fnSetCart === "function") {
                    await fnSetCart(cartList.current);
                }

            } else {
                message.error("이미 선택된 상품입니다 장바구니에서 상품삭제후 선택해주세요");
            }

            setBannerModal(false);
            setCouponModal(false);
            setStoreModal(false);
            setThrooOnlyModal(false);
            setHotProductModal(false);
            setKitModal(false);
            setTableBannerModal(false);
        }
    }

    const asyncData = async () => {
        setLoading(true);
        setCommercialList(commercial);
        await getCouponList();
        await getThrooOnlyList();
        await getHotProductList();
        setLoading(false);
    }

    useEffect(() => {
        asyncData();
    }, []);

    useEffect(() => {
        cartList.current = iCartList
    }, [iCartList]);

    return (
        <>
            <BrowserView>
                {isLoading ?
                    <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                    </div>
                    :
                    <div className="content_container4">
                        <div className="title_box">
                            <p>등록 가능한 광고상품</p>
                        </div>
                        <div className='data_contain'>
                            <div className="data_title">
                                <div className="pay_img">
                                    <p></p>
                                </div>
                                <div className="pay_date">
                                    <p>광고명</p>
                                </div>
                                <div className="pay_period">
                                    <p>설명</p>
                                </div>
                                <div className="pay_amount">
                                    <p>집행기간</p>
                                </div>
                                <div className="pay_status">
                                    <p>단가</p>
                                </div>
                                <div className="pay_box">
                                    <p></p>
                                </div>
                            </div>
                            {commercialList.slice().reverse().map((item, index) => {
                                return (
                                    <div className="data_box_map" key={index}>
                                        <div className="pay_img">
                                            <img src={item.type === 5 && !item.img ? require('../../../../assets/img/kit.png').default : item.type === 6 && !item.img ? require('../../../../assets/img/banner.png').default : item.img} alt="none_image" style={{ borderRadius: "10px" }} />
                                        </div>
                                        <div className="pay_date">
                                            <p>{item.name}</p>
                                        </div>
                                        <div className="pay_period">
                                            <p>{item.detail1}</p>
                                        </div>
                                        <div className="pay_amount">
                                            <p>{item.date}</p>
                                        </div>
                                        <div className='pay_wrapper'>
                                            <div className='price'>
                                                {item.is_saled ? <p className='before_discount_price'>{item.base_price} 원</p> : undefined}
                                                <p>{item.priceCasting} 원</p>
                                                {parseInt(item.deliver_price) ? <p className='text-primary'>{`배송비 ${parseInt(item.deliver_price)}원`}</p> : undefined}
                                                {item.type === 6 ? <p className='text-primary'>포인트 50%<br /> 사용 가능</p> : undefined}
                                            </div></div>
                                        <div className="pay_box">
                                            <div className="date_box" onClick={() => checkItem(item)}><p style={{ color: "#617BE3" }}>선택</p></div>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                    </div>
                }
                {bannerModal &&
                    <ModalBannerComamercial
                        sProps={iProps}
                        dataBox={bannerItem.current}
                        iBannerModal={bannerModal}
                        fnClose={() => bannerClose()}
                        fnCompleteBanner={(sIndex) => setCart(sIndex)}
                    />
                }
                {couponModal &&
                    <ModalCouponComamercial
                        sProps={iProps}
                        dataBox={couponItem.current}
                        iBannerModal={couponModal}
                        iList={couponList}
                        fnClose={() => couponClose()}
                        fnCompleteCoupon={(sIndex) => setCart(sIndex)}
                        fnMoveToPath={() => moveToCoupon()}
                    />
                }
                {throoOnlyModal &&
                    <ModalThrooOnlyComamercial
                        sProps={iProps}
                        dataBox={throoOnlyItem.current}
                        iBannerModal={throoOnlyModal}
                        iList={productList}
                        fnClose={() => throoOnlyClose()}
                        fnCompleteThrooOnly={(sIndex) => setCart(sIndex)}
                        fnMoveToPath={() => moveToThrooOnly()}
                    />
                }
                {hotProductModal &&
                    <ModalHotProductComamercial
                        sProps={iProps}
                        dataBox={hotProductItem.current}
                        iBannerModal={hotProductModal}
                        iList={hotProductList}
                        fnClose={() => hotProductClose()}
                        fnCompleteHotProduct={(sIndex) => setCart(sIndex)}
                        fnMoveToPath={() => moveToThrooOnly()}
                    />
                }
                {storeModal &&
                    <ModalStoreComamercial
                        sProps={iProps}
                        dataBox={storeItem.current}
                        iBannerModal={storeModal}
                        fnClose={() => storeClose()}
                        fnCompleteStore={(sIndex) => setCart(sIndex)}
                    />
                }
                {kitModal &&
                    <ModalKitComamercial
                        sProps={iProps}
                        dataBox={kitItem.current}
                        iBannerModal={kitModal}
                        fnClose={() => kitClose()}
                        fnCompleteKit={(sIndex) => setCart(sIndex)}
                    />
                }
                {tableBannerModal &&
                    <ModalPicketComamercial
                        sProps={iProps}
                        dataBox={tableBannerItem.current}
                        iBannerModal={tableBannerModal}
                        fnClose={() => picketClose()}
                        fnCompletePicket={(sIndex) => setCart(sIndex)}
                    />
                }
            </BrowserView>
            <MobileView>
                <Mstyle>
                    <div className="totalPage" style={{ marginBottom: "1%" }}>
                        <div className="menuContent">
                            <div className="menu" style={{ marginBottom: "1%", borderBottom: "1px solid #dfdfdf" }}>
                                <div className="title">
                                    <h3>등록 가능한 광고상품</h3>
                                </div>
                            </div>
                            {commercialList?.length < 1 ?
                                <div style={{ display: "flex", justifyContent: "center", height: "10vh", alignItems: "center" }}>
                                    <Loader type="BallTriangle" color="#13166b" height={40} width={40} timeout={9000000} />
                                </div>
                                :
                                <>
                                    {commercialList.slice().reverse().map((item, index) => {
                                        return (
                                            <div className='commercial_box' key={index}>
                                                <div className='commercial_container'>
                                                    <div className='img_box'>
                                                        <img src={item.img} alt="스루키트 이미지" width="100%" height="100%" />
                                                    </div>
                                                    <div className='commercial_detail'>
                                                        <div className='title'>
                                                            <p>{item.name}</p>
                                                        </div>
                                                        <div className='detail'>
                                                            <p>{item.detail1}</p>
                                                        </div>
                                                        <div className='pay_wrapper'>
                                                            <div className='price'>
                                                                {item.is_saled ? <p className='before_discount_price'>{item.base_price} 원</p> : undefined}
                                                                <p>{item.priceCasting} 원</p>
                                                                {parseInt(item.deliver_price) ? <p className='text-primary'>{`배송비 ${parseInt(item.deliver_price)}원`}</p> : undefined}
                                                                {item.type === 6 ? <p className='text-primary'>포인트 50% 사용 가능</p> : undefined}
                                                            </div></div>
                                                    </div>
                                                </div>
                                                <div className='select_commercial'>
                                                    <div className='button_box' onClick={() => checkItem(item)}>
                                                        <p>선택</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    </div>
                    {bannerModal &&
                        <ModalBannerComamercialMobile
                            sProps={iProps}
                            dataBox={bannerItem.current}
                            iBannerModal={bannerModal}
                            fnClose={() => bannerClose()}
                            fnCompleteBanner={(sIndex) => setCart(sIndex)}
                        />
                    }
                    {couponModal &&
                        <ModalCouponComamercialMobile
                            sProps={iProps}
                            dataBox={couponItem.current}
                            iBannerModal={couponModal}
                            iList={couponList}
                            fnClose={() => couponClose()}
                            fnCompleteCoupon={(sIndex) => setCart(sIndex)}
                            fnMoveToPath={() => moveToCoupon()}
                        />
                    }
                    {throoOnlyModal &&
                        <ModalThrooOnlyComamercialMobile
                            sProps={iProps}
                            dataBox={throoOnlyItem.current}
                            iBannerModal={throoOnlyModal}
                            iList={productList}
                            fnClose={() => throoOnlyClose()}
                            fnCompleteThrooOnly={(sIndex) => setCart(sIndex)}
                            fnMoveToPath={() => moveToThrooOnly()}
                        />
                    }
                    {hotProductModal &&
                        <ModalHotProductComamercialMobile
                            sProps={iProps}
                            dataBox={hotProductItem.current}
                            iBannerModal={hotProductModal}
                            iList={hotProductList}
                            fnClose={() => hotProductClose()}
                            fnCompleteHotProduct={(sIndex) => setCart(sIndex)}
                            fnMoveToPath={() => moveToThrooOnly()}
                        />
                    }
                    {storeModal &&
                        <ModalStoreComamercialMobile
                            sProps={iProps}
                            dataBox={storeItem.current}
                            iBannerModal={storeModal}
                            fnClose={() => storeClose()}
                            fnCompleteStore={(sIndex) => setCart(sIndex)}
                        />
                    }
                    {kitModal &&
                        <ModalKitComamercialMobile
                            sProps={iProps}
                            dataBox={kitItem.current}
                            iBannerModal={kitModal}
                            fnClose={() => kitClose()}
                            fnCompleteKit={(sIndex) => setCart(sIndex)}
                        />
                    }
                    {tableBannerModal &&
                        <ModalPicketComamercialMobile
                            sProps={iProps}
                            dataBox={tableBannerItem.current}
                            iBannerModal={tableBannerModal}
                            fnClose={() => picketClose()}
                            fnCompletePicket={(sIndex) => setCart(sIndex)}
                        />
                    }
                </Mstyle>

            </MobileView>
        </>
    )
}

const Mstyle = styled.div`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans KR', sans-serif;
    color: #000;
}  

.stockPage {
    background-color: #f1f1f1;
    padding-bottom: 5%;
}

.tapPage {
    background-color: #F1F3F7;
    padding-bottom: 8px;
}

.menu_container {
    background: #fff;
    height: 10vh;

    .tab {
        display: flex;
        align-items: center;
        height: 10vh;
        margin-bottom: 24px;
        background-color: #fff;
        border-radius: 4px;
        overflow: auto;
        white-space: nowrap;
    }

    .scroll_menu {
        display: inline-block;
        text-align: center;
        text-decoration: none;
        margin: 5%;
        cursor: pointer;
        *display:inline;
        *zoom:1;
        vertical-align:top;
        font-size: 0.9rem;
        font-weight: 400;
        color: #666;
    }

    .scroll_menu_active {
        border-bottom: 1px solid #000;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        margin: 5%;
        cursor: pointer;
        *display:inline;
        *zoom:1;
        vertical-align:top;
        font-size: 0.9rem;
        font-weight: 500;
        color: #000;
    }
}

.tab {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}
.tab::-webkit-scrollbar {
    display: none; 
}
    .totalPage {
        width: 100%;
        background-color: #fff;

        .menuContent {
            .menu {
                display: flex;
                align-items: center;
                padding: 5% 5% 5% 5%;

                .title {
                    display: flex;
                    height: 100%;
                    width: 100%;
                    align-items: center;

                    h3 {
                        font-size: 1rem;
                        font-weight: 700;
                    }
                    p {
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: #666;
                    }
                }
            }

            .commercial_box {
                height: 27vh;

                .commercial_container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    height: 17vh;
                    margin-left: 5%;
                    margin-right: 5%;

                    .before_discount_price {
                        text-decoration: line-through;
                    }
                    .img_box {
                        width: 30%;
                        height: 15vh;

                        img {
                            height: 100%;
                            width: 100%;
                            border-radius: 5px;
                        }
                    }
                    
                    .commercial_detail {
                        width: 65%;
                        height: 15vh;

                        .title {
                            height: 3vh;
                            color: #000;
                            font-size: 1rem;
                            font-weight: 600;
                        }
                        .detail {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 7vh;
                            color: #666;
                            font-size: 0.7rem;
                            font-weight: 500;
                        }
                        .price {
                            height: 5vh;
                            color: #000;
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                    }
                }

                .select_commercial {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 10vh;

                    .button_box {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 5vh;
                        width: 90%;
                        background : #E8EFFC;
                        border-radius: 5px;

                        p {
                            font-size: 0.9rem;
                            font-weight: 600;
                            color: #6490E7;
                        }
                    }
                }
            }
        }
    }
`;
export default List;
