import React from 'react';
import styled from 'styled-components';

// 이미지
import auto01 from '../../../../assets/img/guide/pos/auto01.png';
import auto02 from '../../../../assets/img/guide/pos/auto02.png';
import auto03 from '../../../../assets/img/guide/pos/auto03.png';
import auto04 from '../../../../assets/img/guide/pos/auto04.png';

const AutoGuide = (oProps) => {

    return (
        <D_autoGuide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>스루 주문접수</h3>
                        <h1>자동으로 주문 접수 (클릭 최소화) 설정하는 방법</h1>
                        <p>아래 세 가지 기능으로 '상품 준비 완료' 클릭 한 번만 하실 수 있도록 업데이트되었습니다.<br /> 클릭하는 횟수가 줄어드는 만큼 상품 준비 시간에 더 집중하실 수 있으며 시간 절약 효과를 보실 수 있습니다.</p>
                        <h2>자동 기능 설정을 클릭해 주세요.</h2>
                        <img src={auto01} alt="자동주문접수 가이드 이미지" width="400px" />
                        <h2>✅ 주문 자동 접수 기능 설정</h2>
                        <img src={auto02} alt="자동주문접수 가이드 이미지" width="400px" />
                        <p>
                            주문 자동 접수 기능을 클릭해 주세요. <br />
                            지금까지 사장님이 직접 주문 확인 버튼을 직접 눌러야 주문이 접수되었다면 <br />
                            앞으로 들어오는 모든 주문들은 사장님이 따로 주문 확인을 하지 않아도 <span>자동으로 주문 접수</span>가 됩니다.<br /><br />

                            🔔 자동주문 접수 후 알림과 함께 프린트가 출력되니 프린트에서 고객 정보와 주문내역을 확인해 주세요!
                        </p>
                        <p><span>📢 주문이 자동 접수가 되기 때문에 재고의 변동이 큰 매장은 추천드리지 않습니다. <br /> 매장의 상황에 맞게 신중하게 설정해 주세요!</span></p>
                        <h2>✅ 전달 완료 기능 생략 설정</h2>
                        <img src={auto03} alt="자동주문접수 가이드 이미지" width="400px" />
                        <p>전달 완료 생략 기능 선택 시 고객이 도착했을 때 상품 전달이 완료된 것으로 인식됩니다. <br /> 상품 전달 버튼을 따로 누르지 않으셔도 되기 때문에 간편하다는 장점이 있습니다.</p>
                        <h2>✅ 알림 횟수 제한 기능 설정</h2>
                        <img src={auto04} alt="자동주문접수 가이드 이미지" width="400px" />
                        <p>기존에는 알림이 울리면 사장님이 직접 확인 후 클릭을 해야 알림이 꺼졌지만<br /> 횟수 제한 기능을 이용한다면 지정하신 횟수만큼만 울린 뒤 자동 알림 종료가 됩니다.</p>
                        <p style={{fontWeight: '900'}}>자동 주문 접수 기능을 통해 좀 더 편리하고 스마트하게 매장을 운영해 보세요.<br />  바쁜 매장일수록 일손을 덜어드리고 시간을 절약하는 효과를 보실 수 있습니다😊</p>
                    </div>
                </div>
            </div>
        </D_autoGuide>
    )
}
export default AutoGuide;

export const D_autoGuide = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .pGuideCont {
        margin-top: 110px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;
        word-break: keep-all;

        span {
            color: #8E929F;
        }
    }
    h2 span:nth-child(3) {
        color: #F45452;
        font-weight: 900;
    }
    p {
        margin: 15px 0 25px 0;
        font-size: 16px;
        font-weight: 700;

        span {
            color: #F45452;
            font-weight: 900;
        }
    }
`