import React, { useState, useRef, Fragment, useEffect } from 'react';
import ReactDragListView from "react-drag-listview";
import { Popconfirm, Table, message } from 'antd';
import { OrderedListOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import Style from "../../../../assets/styledComponents/OptionStyle"
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import SelectReact from 'react-select';


const sMaxList = [];
const sMinList = [];
const defaultValue = sMaxList[1];
for (let i = 1; i < 21; i++) {
    sMaxList.push({
        key: i,
        value: i,
        label: `${i} 개`,
    });
};
for (let i = 1; i < 21; i++) {
    sMinList.push({
        key: i,
        value: i,
        label: `${i} 개`,
    });
};

const InsertForm = ({ oProps, option_state }) => {
    const [oData, set_oData] = useState([]);

    const [sNm, setNm] = useState("");
    const [sMin, setMin] = useState(1);
    const [sMax, setMax] = useState(1);
    const [min_option, set_min_option] = useState(1);
    const [max_option, set_max_option] = useState(1);
    const [sOptionType, setOptionType] = useState("radio");
    const [selectedOption, setSelectedOption] = useState('');
    const [surcharge, set_surcharge] = useState('0');

    const [iData, setData] = useState([]);
    const sData = useRef([]);
    const sColumns = useRef([
        {
            title: "순서",
            key: "key",
            render: (text, record, index) =>
                <a className="drag-handle"><OrderedListOutlined /></a>
        },
        {
            title: "옵션명",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "정가",
            key: "price",
            dataIndex: "price",
        },
        {
            title: "추가 금액",
            key: "priceSurcharge",
            dataIndex: "priceSurcharge",
        },
        {
            title: "삭제",
            key: "action",
            render: (text, record, index) =>
                <Popconfirm
                    title="정말로 이 옵션을 삭제하시겠습니까?"
                    onConfirm={() => deleteList(text.key)}
                    okText="예"
                    cancelText="아니오"
                >
                    <div className="delete_box"><p>삭제</p></div>
                </Popconfirm>
        }
    ]);

    // 옵션 그룹명
    const onChangeStoreNameValue = (e) => {
        setNm(e.target.value);
    }

    // 필수 선택 여부
    const optionType = (sIndex) => {
        setOptionType(sIndex);
    }
    const selectOptionType = (sIndex) => {
        optionType(sIndex);
    }

    // 필수로 선택할 수 있는 상품옵션일때 최소 선택수 
    const setChangeMin = (sMin, sIndex) => {
        setMin(sMin);
        set_min_option(sMin.value)
        set_max_option(sMin.value)
    }
    // 추가로 선택할 수 있는 상품옵션일때 최대 선택수 
    const setChangeMax = (sMax, sIndex) => {
        setMax(sMax);
        set_min_option(0)
        set_max_option(sMax.value)
    }

    // 상품검색,추가
    function select_product_Change(selectedOption) {
        setSelectedOption(selectedOption);
    }
    // 추가요금
    const changeSurcharge = (event) => {
        set_surcharge(event.target.value);
    };
    // 상품 불러오기
    const getProduct = async () => {
        const aData = [];
        try {
            const oParams = {
                store_id: oProps.UserConfigReducer.StoreID,
            }
            const oResponse = await oProps.oManager.fDoAxios("/store/register/menuList/menuSetProductOption-" + oParams.store_id, "get", null, oParams);
            if (oResponse != undefined && oResponse != false) {
                for (let i = 0; i < oResponse.product_option_list.length; i++) {
                    const name = oResponse.product_option_list[i].name;
                    const base_price = oResponse.product_option_list[i].base_price;
                    const product_id = oResponse.product_option_list[i].product_id;
                    const value = oResponse.product_option_list[i].name;
                    const label = oResponse.product_option_list[i].name;

                    const oParams = {
                        name: name,
                        base_price: base_price,
                        product_id: product_id,
                        value: value,
                        label: label,
                    };

                    aData.push(oParams)
                }
            }
            set_oData(aData)
        } catch (err) {
            console.log("err-->", err);
        }
    }

    // 추가된 상품 list_table
    const insertList = async () => {
        let temp = false;

        const xData = {
            key: parseInt(sData.current.length) + 1,
            name: selectedOption.name,
            price: parseInt(selectedOption.base_price),
            priceSurcharge: parseInt(surcharge),
            product_id: selectedOption.product_id
        }
        for (let i = 0; i < sData.current.length; i++) {
            const listId = sData.current[i].product_id;
            if (xData.product_id === listId) {
                message.error("이미 선택된 상품입니다.");
                temp = true;
            }
        }
        if (selectedOption.name === undefined) {
            temp = true;
            message.error("상품을 선택해주세요.");
        }
        else if (!temp) {
            setData([...sData.current, xData]);
            sData.current = [...sData.current, xData];
            setSelectedOption('');
            set_surcharge('0');
        }
    }
    const dragProps = (fromIndex, toIndex) => {
        let tempColumns = sData.current;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        sData.current = columnsCopy;
        setData(columnsCopy);
    }
    const deleteList = (key) => {
        sData.current = sData.current.filter((item) => item.key !== key);
        setData(sData.current.filter((item) => item.key !== key));
    }

    // submit버튼
    const submitOption = async () => {
        const group_name = sNm;
        try {
            const oParams = {
                group_name: group_name,
                input_min: min_option,
                input_max: max_option,
                store_id: oProps.UserConfigReducer.StoreID,
                product_list: iData,
            }

            if (sNm === "") {
                message.error("옵션 그룹명을 입력해주세요");
            } else if (sMin.value > oParams.product_list.length) {
                message.error("선택하신 필수 선택수보다 상품수가 적습니다.");
            } else if (sMax.value > oParams.product_list.length) {
                message.error("선택하신 최대 선택수보다 상품수가 적습니다.");
            } else if (iData.length < 1) {
                message.error("세트메뉴를 구성할 상품을 추가해주세요.");
            } else {

                const oResponse = await oProps.oManager.fDoAxios("/store/register/insertProductOption", "post", null, oParams);

                if (oResponse != undefined && oResponse != null) {
                    if (oResponse.success) {
                        setNm('')
                        setData('');
                        sData.current = '';
                        setSelectedOption('');
                        setMax('')
                        message.success("등록이 완료되었습니다.");
                        console.log('최종보냄****', oParams)
                    }
                    else {
                        console.log("등록실패 사유:", oResponse.message);
                        message.error("등록이 실패되었습니다.");
                    }
                }
            }

        } catch (err) {
            console.log("err-->", err);
        }
    }

    useEffect(() => {
        getProduct();
    }, []);
    useEffect(() => {
        if (option_state === 'product_option') {
            setOptionType('checkbox');
            set_min_option(0)
            set_max_option(22)
        }
    }, [option_state]);

    return (
        <Fragment>
            <BrowserView>
                <Style>
                    <div>
                        <div className="essential_box">
                            {option_state === "product_option" ?
                                <>
                                    <div className="essential_wrap">
                                        <h3>필수 선택 여부</h3>
                                        <div className="select_essential_box">
                                            <button className={sOptionType === "radio" ? "disable_wrapper" : "disable_wrapper"} >
                                                <p>예</p>
                                            </button>
                                            <button className={sOptionType === "checkbox" ? "selected_wrapper" : "unselect_wrapper"} style={{ marginLeft: "2%" }}>
                                                <p>아니요</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="location_box_line" style={{ marginBottom: 0 }}>
                                        <div className="how_box" style={{ padding: '5px 0' }}>
                                            <p style={{ fontSize: '14px', color: '#DD1212' }}>필수로 선택해야 하는 경우에는 세트 메뉴 구성 옵션 만들기를 이용해 주세요.</p>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="select_line_box">
                                        <h3>필수 선택 여부</h3>
                                        <div className="select_essential_box">
                                            <button className={sOptionType === "radio" ? "select_wrapper" : "select_wrapper"} >
                                                <p>예</p>
                                            </button>
                                            <button className={sOptionType === "checkbox" ? "disable_wrapper" : "disable_wrapper"} style={{ marginLeft: "2%" }}>
                                                <p>아니요</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="location_box_line" style={{ marginBottom: 0 }}>
                                        <div className="how_box" style={{ padding: '5px 0' }}>
                                            <p style={{ fontSize: '14px', color: '#DD1212' }}>필수로 선택하지 않아도 되는 경우에는 선택 옵션 만들기를 이용해 주세요.</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="category_title">
                            <h3>옵션 그룹명</h3>
                            {option_state === "product_option" ?
                                <input
                                    type="text"
                                    placeholder="예) 곁들이기 좋은 메뉴"
                                    value={sNm}
                                    onChange={onChangeStoreNameValue}
                                />
                                :
                                <input
                                    type="text"
                                    placeholder="예) 파스타를 선택해주세요"
                                    value={sNm}
                                    onChange={onChangeStoreNameValue}
                                />
                            }
                        </div>
                        {sOptionType === "checkbox"
                            ?
                            <div className="inputText">
                                <h3>상품 옵션 최대 선택수</h3>
                                <SelectReact
                                    options={sMaxList}
                                    value={sMax}
                                    defaultValue={defaultValue}
                                    onChange={setChangeMax}
                                    placeholder="고객이 선택할 수 있는 최대 개수를 선택해 주세요"
                                />
                            </div>
                            :
                            <div className="inputText">
                                <h3>상품 옵션 필수 선택수</h3>
                                <SelectReact
                                    options={sMinList}
                                    value={sMin}
                                    onChange={setChangeMin}
                                    placeholder="고객이 선택할 수 있는 필수 개수를 선택해 주세요"
                                />
                            </div>
                        }
                        <div className="category_title">
                            <h3>상품추가</h3>
                            <div className="option_subTitle">
                                <div className="selectSearch">
                                    <SelectReact
                                        options={oData}
                                        value={selectedOption}
                                        onChange={select_product_Change}
                                        placeholder="상품을 검색,선택해 주세요"
                                    />
                                </div>
                                <div className='default_price'>
                                    <p>정가 :</p>
                                    {
                                        selectedOption === ""
                                            ?
                                            <p>0 원</p>
                                            :
                                            <p>{Math.floor(selectedOption.base_price)} 원</p>
                                    }
                                </div>
                                <div className='surcharge'>
                                    <input type="number" placeholder='추가 금액(숫자로 입력)' value={surcharge === '0' ? "" : surcharge} onChange={changeSurcharge} />
                                </div>
                                <div className="insert_box" onClick={insertList}>
                                    <h3>상품 추가</h3>
                                </div>
                            </div>
                            <div className="location_box_line" style={{ marginBottom: 0 }}>
                                <div className="how_box">
                                    <p>고객이 해당 옵션을 선택했을때 추가 결제가 필요한 경우, 추가될 금액을 입력해주세요. <br />
                                        <span style={{ color: '#DD1212', fontWeight: '700' }}>만약 추가 금액을 입력하지 않으시면 0원으로 등록됩니다. </span><br /></p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "5%" }}>
                            <ReactDragListView.DragColumn
                                onDragEnd={dragProps}
                                handleSelector="a"
                            >
                                <Table columns={sColumns.current} dataSource={iData} pagination={false} />
                            </ReactDragListView.DragColumn>
                        </div>
                        <div className="location_box_line">
                            <div className="how_box">
                                <p>[순서 변경 방법] 순서 아이콘을 클릭한 상태로 원하는 위치로 이동하세요</p>
                            </div>
                        </div>
                        <div className="submitBtn" onClick={submitOption}>
                            <p>등록완료</p>
                        </div>
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <Style>
                    <div>
                        <div className="essential_box">
                            {option_state === "product_option" ?
                                <>
                                    <div className="essential_wrap_mobile">
                                        <h3>필수 선택 여부</h3>
                                        <div className="select_essential_box">
                                            <button className={sOptionType === "radio" ? "disable_wrapper" : "disable_wrapper"} >
                                                <p>예</p>
                                            </button>
                                            <button className={sOptionType === "checkbox" ? "selected_wrapper" : "unselect_wrapper"} style={{ marginLeft: "2%" }}>
                                                <p>아니요</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="location_box_line" style={{ marginBottom: 0 }}>
                                        <div className="how_box" style={{ padding: '5px 0' }}>
                                            <p style={{ fontSize: '12px', color: '#DD1212', wordBreak: 'keep-all', fontWeight: '500', lineHeight: '16px' }}>필수로 선택해야 하는 경우에는 세트 메뉴 구성 옵션 만들기를 이용해 주세요.</p>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="essential_wrap_mobile">
                                        <h3>필수 선택 여부</h3>
                                        <div className="select_essential_box">
                                            <button className={sOptionType === "radio" ? "selected_wrapper" : "selected_wrapper"} >
                                                <p>예</p>
                                            </button>
                                            <button className={sOptionType === "checkbox" ? "disable_wrapper" : "disable_wrapper"} style={{ marginLeft: "2%" }}>
                                                <p>아니요</p>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="location_box_line" style={{ marginBottom: 0 }}>
                                        <div className="how_box" style={{ padding: '5px 0' }}>
                                            <p style={{ fontSize: '12px', color: '#DD1212', wordBreak: 'keep-all', fontWeight: '500', lineHeight: '16px' }}>필수로 선택하지 않아도 되는 경우에는 선택 옵션 만들기를 이용해 주세요.</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className="category_title_mobile">
                            <h3>옵션 그룹명</h3>
                            {option_state === "product_option" ?
                                <input
                                    type="text"
                                    placeholder="예) 곁들이기 좋은 메뉴"
                                    value={sNm}
                                    onChange={onChangeStoreNameValue}
                                />
                                :
                                <input
                                    type="text"
                                    placeholder="예) 파스타를 선택해주세요"
                                    value={sNm}
                                    onChange={onChangeStoreNameValue}
                                />
                            }
                        </div>
                        {sOptionType === "checkbox"
                            ?
                            <div className="inputText">
                                <h3>상품 옵션 최대 선택수</h3>
                                <SelectReact
                                    options={sMaxList}
                                    value={sMax}
                                    defaultValue={defaultValue}
                                    onChange={setChangeMax}
                                    placeholder="고객이 선택할 필수 개수를 선택해 주세요"
                                />
                            </div>
                            :
                            <div className="inputText">
                                <h3>상품 옵션 필수 선택수</h3>
                                <SelectReact
                                    options={sMinList}
                                    value={sMin}
                                    onChange={setChangeMin}
                                    placeholder="고객이 선택할 수 있는 최대 개수를 선택해 주세요"
                                />
                            </div>
                        }
                        <div className="category_title_mobile">
                            <h3>상품추가</h3>

                            <div className="selectSearch">
                                <SelectReact
                                    options={oData}
                                    value={selectedOption}
                                    onChange={select_product_Change}
                                    placeholder="상품을 검색,선택해 주세요"
                                />
                            </div>
                            <div className="option_subTitle" style={{ marginTop: '5px' }}>
                                <div className='default_price'>
                                    <p>정가 :</p>
                                    {
                                        selectedOption === ""
                                            ?
                                            <p>0 원</p>
                                            :
                                            <p>{Math.floor(selectedOption.base_price)} 원</p>
                                    }
                                </div>
                                <div className='surcharge'>
                                    <input type="number" placeholder='추가 금액(숫자입력)' value={surcharge === '0' ? "" : surcharge} onChange={changeSurcharge} />
                                </div>
                                <div className="insert_box" onClick={insertList}>
                                    <h3>상품 추가</h3>
                                </div>
                            </div>
                            <div className="location_box_line" style={{ marginBottom: 0 }}>
                                <div className="how_box" >
                                    <p style={{ fontSize: '12px', padding: 0, wordBreak:'keep-all' }}>고객이 해당 옵션을 선택했을때 추가 결제가 필요한 경우, 추가될 금액을 입력해주세요. <br />
                                        <span style={{ color: '#DD1212', fontWeight: '700' }}>만약 추가 금액을 입력하지 않으시면 0원으로 등록됩니다. </span><br /></p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "5%" }}>
                            <ReactDragListView.DragColumn
                                onDragEnd={dragProps}
                                handleSelector="a"
                            >
                                <Table columns={sColumns.current} dataSource={iData} pagination={false} />
                            </ReactDragListView.DragColumn>
                        </div>
                        <div className="location_box_line" style={{ padding: '8px 4px' }}>
                            <div className="how_box" style={{ fontSize: '12px' }}>
                                <p>[순서 변경 방법] 순서 아이콘을 클릭한 상태로 원하는 위치로 이동하세요</p>
                            </div>
                        </div>
                        <div className="submitBtn" onClick={submitOption}>
                            <p>등록완료</p>
                        </div>
                    </div>
                </Style>

            </MobileView>
        </Fragment>
    )
}

export default InsertForm;


