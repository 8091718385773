import React from 'react';
import styled from 'styled-components';

// 이미지
import sales01 from '../../../../assets/img/guide/sales/sales01.png';
import sales02 from '../../../../assets/img/guide/sales/sales02.png';
import sales03 from '../../../../assets/img/guide/sales/sales03.png';
import sales04 from '../../../../assets/img/guide/sales/sales04.png';
import sales05 from '../../../../assets/img/guide/sales/sales05.png';

const salesGuide = (oProps) => {

    return (
        <D_sales>
            <div className="salesGuide">
                <div className="container">
                    <div className="sCont">
                        <h3>스루 가이드</h3>
                        <h1>정산내역 안내 및 조회 방법</h1>
                        <p>
                            스루의 정산 주기는 일주일입니다.<br /><br />
                            <span>월요일부터 일요일 매출을 기준으로 다음 주 수요일</span>에 정산됩니다.<br />(정산 일이 휴일일 경우 다음 영업일에 정산됩니다.)<br /><br />
                            <em>스루는 정산시 카드수수료 3%(부가세별도)</em>가 차감되어 정산 됩니다.
                        </p>
                        <div className="step">
                            <p>정산내역 조회는 <span>스루 사장님 창구 <span>&#8594;</span> 매장관리 <span>&#8594;</span> 매출 관리</span>에서 확인하실 수 있습니다.</p>
                            <h2>
                                <span>STEP 1</span><br />
                                매장관리 클릭 후 로그인을 해주세요.
                            </h2>
                            <img src={sales01} alt="매장관리 클릭 이미지" width="auto" />
                            <h2>
                                <span>STEP 2</span><br />
                                매출 관리 <span>&#8594;</span> 정산내역 페이지를 클릭해 주세요.
                            </h2>
                            <img src={sales02} alt="정산내역 페이지 이미지" width="auto" />
                            <h3>
                                😊 TIP<br /><br />정산 명세서 받기를 클릭하시면 엑셀파일로 다운로드도 가능해요.
                            </h3>
                            <p>매출 관리 페이지에서는 매출 조회, 매출 차트로 한눈에 보기, 부가세 신고자료(참고용)도 확인하실 수 있습니다.</p>
                        </div>
                        <div className="guide">
                            <h2>기간별로 우리 매장의 매출을 조회해 보세요.</h2>
                            <img src={sales03} alt="매출조회 페이지 이미지" width="auto" />
                            <h2>매장의 매출을 보기 쉽게 차트로 확인해 보세요.</h2>
                            <img src={sales04} alt="매출 차트로 확인 페이지 이미지" width="auto" />
                            <h2>번거로운 부가세 신고자료를 손쉽게 확인해 보세요.</h2>
                            <img src={sales05} alt="부가세 신고자료 페이지 이미지" width="auto" />
                            <p>😊 매장관리 페이지를 통해 우리 매장의 매출과 정산 과정을 편리하게 확인해 보세요.<br />더욱더 편리하고 체계적으로 매장을 운영할 수 있어요.</p>
                        </div>
                    </div>
                </div>
            </div>
        </D_sales>
    )
}

export default salesGuide;

export const D_sales = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .sCont {
        margin-top: 110px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 26px 0;
            font-size: 32px;
            font-weight: 900;
        }
        p {
            font-size: 16px;
            font-weight: 700;
            word-break: keep-all;
            
            span {
                color: #F45452;
                font-weight: 900;
            }
            em {
                font-weight: 900;
                font-style: normal;
                line-height: 32px;
            }
        }
        
        .step {
            margin-top: 21px;
            padding: 26px 0;
            border-top: 2px solid #eee;

            h2 {
                margin: 36px 0 10px 0;
                font-size: 22px;
                font-weight: 900;
                line-height: 36px;

                span {
                    color: #8E929F;
                }
            }
            h3 {
                margin: 20px 0;
                font-size: 16px;
                font-weight: 900;
                color :#212329;
            }
            p {
                line-height: 32px;
                span {
                    color: #1A7CFF;
                }
            }
        }
        .guide {
            padding-top: 34px;
            border-top: 2px solid #eee;

            h2 {
                margin: 34px 0 10px 0;
                font-size: 22px;
                font-weight: 900;
            }
            p {
                margin-top: 20px;
                line-height: 32px;
            }
        }
    }

    
`