import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';
import { Modal } from 'antd';
import "antd/dist/antd.css";
import { WebRoute } from '../../../../navigation/WebRoutes';

// 이미지
import promotion01 from '../../../../assets/img/guide/add/add_kb01.png';
import promotion02 from '../../../../assets/img/guide/add/add_kb02.png';
import promotion03 from '../../../../assets/img/guide/add/add_kb03.png';
const AddKbLife = () => {
    const [loan_value, set_loan_value] = useState('');
    const [insurance_value, set_insurance_value] = useState('');
    const [privacy, set_Privacy] = useState(false);
    const [sAddress, setAddress] = useState("");
    const [addressModal, setAddressModal] = useState(false);
    const [sExtraAddress, setExAddress] = useState("");
    const [modal, contextHolder] = Modal.useModal();

    const PrivacyCheckboxChange = (event) => {
        set_Privacy(event.target.checked);
    };
    const handleComplete = async (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }
            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        setAddress(fullAddress);
        setAddressModal(false);
    }
    console.log('loan_value',loan_value)
    console.log('insurance_value',insurance_value)

    return (
        <Style>
            <div className="wrap">
                <img src={promotion01} alt="사장님 프로모션 이미지" width="100%" />
                <img src={promotion02} alt="사장님 프로모션 이미지" width="100%" />
                <img src={promotion03} alt="사장님 프로모션 이미지" width="100%" />
                <div className="submit_form_wrap">
                    <div className="submit_form">
                        <div className="header">
                            <h1>상담 신청 문의</h1>
                            <p>관심있는 분야에 체크해 주세요.</p>
                        </div>
                        {/* 대출 */}
                        <div className="title">대출</div>
                        <div className="radio_wrap">
                            <label>
                                <input
                                    type="radio"
                                    value="loan01"
                                    checked={loan_value === 'loan01'}
                                    onChange={() => set_loan_value('loan01')}
                                />
                                <p>소상공인대출</p>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="loan02"
                                    checked={loan_value === 'loan02'}
                                    onChange={() => set_loan_value('loan02')}
                                />
                                <p>사장님 신용대출</p>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="loan03"
                                    checked={loan_value === 'loan03'}
                                    onChange={() => set_loan_value('loan03')}
                                />
                                <p>정부자금대출</p>
                            </label>
                        </div>
                        {/* 보험 */}
                        <div className="title">보험</div>
                        <div className='radio_wrap'>
                            <label>
                                <input
                                    type="radio"
                                    value="insurance01"
                                    checked={insurance_value === 'insurance01'}
                                    onChange={() => set_insurance_value('insurance01')}
                                />
                                <p>매장 화재보험</p>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="insurance02"
                                    checked={insurance_value === 'insurance02'}
                                    onChange={() => set_insurance_value('insurance02')}
                                />
                                <p>책임보험(음식물/주차장/학원/이미용 등)</p>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="insurance03"
                                    checked={insurance_value === 'insurance03'}
                                    onChange={() => set_insurance_value('insurance03')}
                                />
                                <p>종업원보험</p>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="insurance04"
                                    checked={insurance_value === 'insurance04'}
                                    onChange={() => set_insurance_value('insurance04')}
                                />
                                <p>CEO 보험</p>
                            </label>
                        </div>
                        {/* 정보입력 */}
                        <div className="title">상담정보</div>
                        <div className='input_wrap'>
                            <input type="text" placeholder='성함' />
                            <input type="text" placeholder='휴대전화번호' />
                            <input type="text" placeholder='업태' />
                            <div className="address">
                                <input type="text" value={sAddress} placeholder='주소' />
                                <button onClick={() => { setAddressModal(true) }}>검색</button>
                            </div>
                            <input type="text" placeholder='상세 주소' />
                        </div>
                        {/* 개인정보 수집 동의 */}
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={privacy}
                                    onChange={PrivacyCheckboxChange}
                                />
                                <p>[필수] 개인정보 수집 이용 동의<br />상담을 위한 필수 동의/상담 후 폐기됩니다.</p>
                            </label>
                        </div>

                        <button className='submitBtn'>신청하기</button>
                    </div>
                </div>
            </div>
            <Modal visible={addressModal} centered={true} closable={false} onOk={() => { setAddressModal(false) }} onCancel={() => { setAddressModal(false) }} okText="확인" cancelText="취소">
                <DaumPostcode onComplete={handleComplete} />
            </Modal>
            {contextHolder}

        </Style>
    )
}

export default AddKbLife;
export const Style = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        margin: 0;
        padding: 0;
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'pretendard';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .wrap {
        width: 100%;
    }
    img {
        margin-top: -3px;
    }
    .ceoTip {
        margin-top: 60px;
    }

    /* header */
    .header {
        margin-bottom: 20px;

        h1 {
            font-size: 22px;
            font-weight: 900;
        }
        p {
            font-size: 16px;
            font-weight: 700;
        }
    }

    /* 신청폼 */
    .submit_form_wrap {
        width: 100%;
        margin: 100px 0;
    }
    .submit_form {
        width: 60vw;
        margin: 0 auto;
        padding: 54px 8vw;
        border: 1px solid #ECEEF2;
        border-radius: 4px;
    }
    
    .title {
        margin-top: 24px;
        margin-bottom: 18px;
        padding: 10px 12px;
        max-width: 60px;
        background: #eceef2;
        color: #888; 
        border-radius: 6px;
        font-family: 'pretendard';
        font-size: 16px;
        font-weight: 700;
        text-align: center;
    }
    .title:nth-of-type(6){
        margin-bottom: 12px;
        max-width: 100%;
    }
    .input_wrap {
        input {
            width: 100%;
            padding: 10px 12px;
            margin: 8px 0;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid #eceef2;
            border-radius: 6px;
        }
        input::placeholder { 
            color: #888;
            opacity: 1; /* Firefox */
        }
    }
    .radio_wrap {
        display: flex;
        flex-wrap: wrap;
    }
    .radio_wrap:nth-of-type(5){
        justify-content: flex-start;
        label:nth-child(odd) {
            flex: 0 0 30%;
        }
    }
    label {
        margin-right: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        input {
            margin-right: 11px;
            width: 18px;
            height: 18px;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            color: #888;
        }
    }
    /* 주소검색 */
    .address {
        position: relative;
        input {
            width: 85%;
        }
        
        button {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            min-width: 13%;
            margin: 8px 0 8px 10px;
            padding: 10px 17px;
            background: #000;
            color: #fff;
            border-radius: 6px;
            border: none;
            outline: none;
            font-weight: 700;
        }
    }


    /* 신청하기 */
    .submitBtn {
        width: 100%;
        padding: 12px 0;
        background: #1A7CFF;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border: none;
        outline: none;
        border-radius: 4px;
    }
`