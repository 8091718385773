import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// 이미지
import sw01 from "../../../../assets/img/guide/sw01.png";
import sw02 from "../../../../assets/img/guide/sw02.png";
import sw03 from "../../../../assets/img/guide/sw03.png";
import sw04 from "../../../../assets/img/guide/sw04.png";
import sw05 from "../../../../assets/img/guide/sw05.png";

const OnlyGuide = (oProps) => {
  return (
    <D_onlyGuide>
      <div className="ceoTip">
        <div className="container">
          <div className="cTip">
            <h3>스루 가이드</h3>
            <img src={sw01} alt="요금제개편 이미지" width="100%" />
            <img src={sw02} alt="요금제개편 이미지" width="100%" />
            <img src={sw03} alt="요금제개편 이미지" width="100%" />
            <div>
              <a href="https://ceo.throo.co.kr/join">
                <img src={sw04} alt="요금제개편 이미지" width="100%" />
              </a>
            </div>
            <div>
              <a href="https://throo.co.kr/cafeshow/behind">
                <img src={sw05} alt="요금제개편 이미지" width="100%" />
              </a>
            </div>
            {/* <h1>스루 요금제 개편에 따른 스루 S/W 이용료 안내</h1>
                        <p>
                            안녕하세요 사장님<br />스루입니다.<br /><br />
                            2023년 11월 1일을 기준으로 요금제 개편에 따라 적용되는 S/W 이용료를 안내 드립니다.
                        </p>
                        <div className="tip">
                            <h2>이용요금</h2>
                            <img src={sw} alt="얼리어탑터 사장님 프로모션 이미지" width="100%" />
                            
                            <p>
                                👉🏻 객단가가 적을 때는 구매금액으로, 클 때는 구매건수로 사장님께 유리한 조건이 매달 자동으로 적용되므로 걱정하실 필요 없습니다.<br />
                                👉🏻 매출 30만 원 이상, 30건 이상 조건이 둘 다 충족될 경우에만 요금이 부과되며, 한 조건이라도 해당되지 않을 경우 요금이 부과되지 않습니다.<br />
                                👉🏻 매달 1일~말일 기준으로 계산되며 첫째 주 정산일에 사용료가 매출 정산금에서 자동으로 차감됩니다.<br />
                                👉🏻 스루는 정산시 카드수수료 3%(부가세별도)가 차감되어 정산 됩니다.<br />
                            </p>
                        </div>
                        <p style={{ marginTop: "34px", paddingTop: "20px", borderTop: "2px solid #EEEEEE" }}>😊  S/W 요금제에 관련하여 문의사항이 있으실 경우 언제든지 고객센터로 문의해 주시기 바랍니다.<br /><br />감사합니다.</p> */}
          </div>
        </div>
      </div>
    </D_onlyGuide>
  );
};

export default OnlyGuide;
export const D_onlyGuide = styled.aside`
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  textarea {
    transform: skew(-0.1deg);
    color: #212329;
    font-family: "NanumSquare";
  }

  html,
  body {
    margin: 0;
    padding: 0;
  }
  .container {
    width: 702px;
    margin: 0 auto;
  }
  /* img {
        margin: 12px 0;
    } */
  /* .cTip {
        margin-top: 106px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 26px 0;
            font-size: 32px;
            font-weight: 900;
            line-height: 48px;
        }
        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .tip {
        h2 {
            margin: 34px 0 10px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;

            span {
                color: #8E929F;
            }
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    } */
`;
