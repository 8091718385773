import React from 'react';
import {
    isMobile
} from "react-device-detect";

// 데스크
import D_sales from "./desktop/D_sales";
import D_throoNow from "./desktop/D_throoNow";
import D_throoOnly from "./desktop/D_throoOnly";
import D_stamp from "./desktop/D_stamp";
import D_coupon from "./desktop/D_coupon";
import D_walkThroo from "./desktop/D_walkThroo";
import D_appBanner from "./desktop/D_appBanner";
import D_payback from "./desktop/D_payback";
import D_sw from "./desktop/D_sw";
import D_add_kbLife from "./desktop/D_add_kbLife";

import Page_20220601 from "./desktop/20220601";
import D_ceoPromotion from "./desktop/D_ceoPromotion";

// 모바일
import M_sales from "./mobile/M_sales";
import M_throoNow from "./mobile/M_throoNow";
import M_throoOnly from "./mobile/M_throoOnly";
import M_coupon from "./mobile/M_coupon";
import M_stamp from "./mobile/M_stamp";
import M_walkThroo from "./mobile/M_walkThroo";
import M_appBanner from "./mobile/M_appBanner";
import M_payback from "./mobile/M_payback";
import M_sw from "./mobile/M_sw";

import Mobile_20220601 from "./mobile/20220601";
import M_ceoPromotion from "./mobile/M_ceoPromotion";
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const MainCeoTip = (oProps, oParam) => {
    const location = useLocation()
    const params = useParams()
    useEffect(() => {
        console.log(location)
    },[])
    const tipRender = () => {
        if (isMobile) {
            if (location.search != undefined && location.search === 'mob_sales' || location.search === '?mob_sales') {
                return (
                    <M_sales />
                )
            } 
            if (location.search != undefined && location.search === 'mob_only' || location.search === '?mob_only') {
                return (
                    <M_throoOnly />
                )
            } if (location.search != undefined && location.search === 'mob_now' || location.search === '?mob_now') {
                return (
                    <M_throoNow />
                )
            } if (location.search != undefined && location.search === 'mob_coupon' || location.search === '?mob_coupon') {
                return (
                    <M_coupon />
                )
            } if (location.search != undefined && location.search === 'mob_stamp' || location.search === '?mob_stamp') {
                return (
                    <M_stamp />
                )
            } 
            if (location.search != undefined && location.search === 'mob_walk' || location.search === '?mob_walk') {
                return (
                    <M_walkThroo />
                )
            } 
            if (location.search != undefined && location.search === 'mob_promotion20220601' || location.search === '?mob_promotion20220601') {
                return (
                    <Mobile_20220601 />
                )
            } 
            if (location.search != undefined && location.search === 'mob_throoCeoPromotion' || location.search === '?mob_throoCeoPromotion') {
                return (
                    <M_ceoPromotion />
                )
            } 
            if (location.search != undefined && location.search === 'mob_walk' || location.search === '?mob_walk') {
                return (
                    <M_walkThroo />
                )
            } 
            if (location.search != undefined && location.search === 'mob_appBanner' || location.search === '?mob_appBanner') {
                return (
                    <M_appBanner />
                )
            } 
            if (location.search != undefined && location.search === 'mob_payback' || location.search === '?mob_payback') {
                return (
                    <M_payback />
                )
            } 
            if (location.search != undefined && location.search === 'mob_sw' || location.search === '?mob_sw') {
                return (
                    <M_sw />
                )
            } 
        } else {
            if (location.search != undefined && location.search === 'stamp' || location.search === '?stamp') {
                return (
                    <D_stamp />
                )

            } if (location.search != undefined && location.search === 'coupon' || location.search === '?coupon') {
                return (
                    <D_coupon/>
                )

            }if (location.search != undefined && location.search === 'only' || location.search === '?only') {
                return (
                    <D_throoOnly />
                )
            } if (location.search != undefined && location.search === 'now' || location.search === '?now') {
                return (
                    <D_throoNow />
                )
            }
             if (location.search != undefined && location.search === 'sales' || location.search === '?sales') {
                return (
                    <D_sales />
                )
            } 
            if (location.search != undefined && location.search === 'promotion20220601' || location.search === '?promotion20220601') {
                return (
                    <Page_20220601 />
                )
            } 
            if (location.search != undefined && location.search === 'walk' || location.search === '?walk') {
                return (
                    <D_walkThroo />
                )
            } 
            if (location.search != undefined && location.search === 'appBanner' || location.search === '?appBanner') {
                return (
                    <D_appBanner />
                )
            } 
            if (location.search != undefined && location.search === 'payback' || location.search === '?payback') {
                return (
                    <D_payback />
                )
            } 
            if (location.search != undefined && location.search === 'sw' || location.search === '?sw') {
                return (
                    <D_sw />
                )
            } 
            // ceoEventPage
            if (location.search != undefined && location.search === 'throoCeoPromotion' || location.search === '?throoCeoPromotion') {
                return (
                    <D_ceoPromotion />
                )
            } 
            // 광고
            if (location.search != undefined && location.search === 'add_kb' || location.search === '?add_kb') {
                return (
                    <D_add_kbLife />
                )
            } 
        }
    }


    return (
        <>
            {tipRender()}
        </>
    )
}

export default MainCeoTip;

