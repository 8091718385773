import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled from 'styled-components';
import SignatureCanvas from 'react-signature-canvas'
import SVG from 'react-inlinesvg';
import Loader from "react-loader-spinner";
import { message } from 'antd';
import "antd/dist/antd.css";
import Accordion from "../../components/manage/accordion";
import loginLogo from '../../assets/svg/loginlogo.svg';
import { BasicTerms, AgreeTerms, PersonalTerms } from '../../components/login/ContractFile';
import { WebRoute } from '../../navigation/WebRoutes';
import { FaCheckCircle } from "react-icons/fa";

const StyledAccordionContent = styled(Accordion.AccordionContent)`
    display: flex;
`;

const dataURLtoFile = (dataurl, filename) => {
   const arr = dataurl.split(',')
   const mime = arr[0].match(/:(.*?);/)[1]
   const bstr = atob(arr[1])
   let n = bstr.length
   const u8arr = new Uint8Array(n)
   while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
   }
   return new File([u8arr], filename, { type: mime })
}

const StoreList = ({ oProps }) => {
   const [sLoading, setLoading] = useState(true);
   const [bIsComplete, set_bIsComplete] = useState(false);

   const canvasImg = useRef(null);
   const [sPosition, setPosition] = useState("");
   const [currentlyOpened] = useState([]);
   const [sList] = useState([
      {
         id: 1,
         title: "판매 이용약관",
         date: "2021-07-16"
      },
      {
         id: 2,
         title: "전자금융거래이용약관",
         date: "2021-07-16"
      },
      {
         id: 3,
         title: "판매자 개인 정보 수집 및 이용 동의",
         date: "2021-07-16"
      },
   ]);

   const navigate = useNavigate();
   const location = useLocation()
   const imageProcess = async (fileList) => {
      let logoUpload = {};
      const formData = new FormData();
      formData.append('photo', fileList);

      const oResponse = await oProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
      if (oResponse !== undefined) {
         if (oResponse.file_name !== undefined) {
            logoUpload = oResponse;
         }
      }
      return logoUpload;
   }

   const authentication = async () => {
      setLoading(true);

      let isProcess = true;
      let uploadFile = {};

      if (!canvasImg.current.isEmpty()) {
         const canvasData = canvasImg.current.isEmpty();
         const imgData = canvasImg.current.getTrimmedCanvas().toDataURL("image/png");
         const file = dataURLtoFile(imgData);
         const upload = await imageProcess(file);
         if (upload === "") {
            message.warning("문제가 발생했습니다 관리자에 연락바랍니다.");
            isProcess = false;
         } else {
            uploadFile = upload;
         }

         if (isProcess) {
            const param = sPosition.split("?=");
            const oData = {
               userId: param[0],
               userPhone: param[1],
               imgData: uploadFile,
               canvasImg: canvasData
            }
            const oResponse = await oProps.oManager.fDoAxios("/authenticate/signUp/smsAuthenticate", "post", null, oData);
            if (oResponse !== undefined && oResponse !== null) {
               if (oResponse.resultId === "0000") {
                  message.info("인증되었습니다, 감사합니다");
                  set_bIsComplete(true);
               } else {
                  message.warning(oResponse.resultMsg);
               }
            }
         }
      } else {
         message.warning("대표자님 서명을 부탁드립니다.");
      }
      setLoading(false);
      //isMobile ? setLoading(false) : setLoading(true);
   }

   useEffect(() => {
      if (bIsComplete === true) {
         setTimeout(() => {
            window.close();
         }, 2000);
      }
   }, [bIsComplete]);

   useEffect(() => {
      if (location.search !== null && location.search !== undefined) {
         let str = location.search;
         str = str.substr(2, str.length);
         setPosition(str.toString());
         setLoading(false);
      }
   }, []);

   return (
      <Style>
         <div className="loginPage">
            <div className="loginBox">
               <SVG src={loginLogo} width="54px" height="18px" title="로그인페이지로고" />
               <Accordion.AccordionGroup currentlyOpened={currentlyOpened}>
                  {sList.map((item, index) => (
                     <Accordion.AccordionItem id={item.id} key={"listing-" + index.toString()}>
                        <Accordion.AccordionHeader className="menuContent">
                           {(accordionOpen) => (
                              <div className="notice_title" open={accordionOpen}>
                                 <div className="title">
                                    <p>{item.title}</p>
                                 </div>
                                 <div className="date_box">
                                    <p>{item.date}</p>
                                 </div>
                              </div>
                           )}
                        </Accordion.AccordionHeader>
                        <StyledAccordionContent>
                           <div className="notice_content">
                              {parseInt(item.id) === 1 && <BasicTerms />}
                              {parseInt(item.id) === 2 && <AgreeTerms />}
                              {parseInt(item.id) === 3 && <PersonalTerms />}
                           </div>
                        </StyledAccordionContent>
                     </Accordion.AccordionItem>
                  ))}
               </Accordion.AccordionGroup>
            </div>
            {bIsComplete === false &&
               <div className="agree_box">
                  <p className="drive_throo">약관에 동의하시면 서명을 부탁드립니다</p>
                  <div className="canvas_box">
                     <SignatureCanvas
                        penColor='#001E62'
                        canvasProps={{ width: 270, height: 170, className: 'sigCanvas' }}
                        ref={canvasImg}
                     />
                  </div>
                  <div className="agree_button" onClick={authentication}>
                     <p>확인</p>
                  </div>

                  {sLoading === true &&
                     <div style={{ display: "flex", justifyContent: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, alignItems: "center", background: '#fff', borderRadius: 4 }}>
                        <Loader type="BallTriangle" color="#13166b" height={50} width={50} timeout={9000000} />
                     </div>
                  }
               </div>
            }
            {bIsComplete === true &&
               <div className="agree_box">
                  <p className="complete-icon">
                     <FaCheckCircle />
                  </p>
                  <p className="complete-txt">인증되었습니다, 감사합니다</p>
               </div>
            }
         </div>
      </Style>
   )
}

export default StoreList;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }
    .loginPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        background-color: #E5E5E5;
    }

    .notice_title {
        border-bottom: 1px solid #dfdfdf;
        height: 7vh;

        .title {
            display: flex;
            align-items: flex-end;
            height: 3.5vh;

            p {
                font-size: 0.9rem;
                font-weight: 700;
            }
        }
        .date_box {
            display: flex;
            align-items: flex-start;
            height: 3.5vh;

            p {
                font-size: 0.7rem;
                font-weight: 400;
            }
        }
    }

    .agree_box {
        margin-top: 10px;
        width: 314px;
        padding: 24px;
        background-color: #fff; 
        border-radius: 4px;
        height: 300px;

        .drive_throo {
            margin: 0px 0 8px 0;
            font-size: 0.8rem;
            font-weight: 500;
            line-height: 19.5px;

        }
        
        .canvas_box {
            height: 170px;
            border: 1px solid #dfdfdf;
        }

        .agree_button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5%;
            height: 48px;
            background: #001E62;
            border-radius: 8px;

            p {
                font-size: 1rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .complete-icon {
           font-size: 40px; text-align:center; padding-top:20px; color:green;
        }

        .complete-txt {
           font-size: 16px; text-align:center; padding-top:20px;
        }

    }

    .loginBox {
        width: 314px;
        padding: 24px;
        background-color: #fff; 
        border-radius: 4px;

        h2 {
            margin: 16px 0 8px 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
        }
        
        .join {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
        }

        .back_out {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            background: #fff;
            color: #DD1212;
            border: none;
            outline: none;
        }
        .back_out:hover {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            background: #DD1212;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
        }
        
    }

    @media (max-width: 550px){
        .loginBox {
            padding: 24px;
        }
    }
`;