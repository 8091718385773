import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import '../../../assets/fonts/font.css';
import { BrowserView, MobileView } from 'react-device-detect';

import CancelSvg from '../../../assets/svg/cancel_modal.svg';

const AnswerReviewModal = (oProps) => {
    const [modalIsOpen, setIsOpen] = useState(oProps.isVisible);
    console.log('확인', oProps)

    function closeModal() {
        oProps.setIsOpen(false);
    }
    const handleMessageChange = event => {
        oProps.set_answerText(event.target.value);
    };
    useEffect(() => {
        oProps.set_answerText(oProps?.currentData?.ceoComment || "");
    },[])

    return (
        <>
            <BrowserView>
                <Modal
                    isOpen={modalIsOpen}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,.4)',
                            height: '100%',
                            zIndex: 100
                        },
                        content: {
                            minWidth: '30%',
                            padding: '16px 12px',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            border: 'none',
                            backgroundColor: 'rgba(0,0,0,.1)',
                            WebkitOverflowScrolling: 'touch',
                            borderRadius: '8px',
                            outline: 'none',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    }}
                >
                    <AnswerModalStyle>
                        <div className="answerModal">
                            <div className="title">
                                {oProps.currentData.have_store_comment === 0 && <h1>사장님 답변 입력</h1>}
                                {oProps.currentData.have_store_comment === 1 && <h1>사장님 답변 수정</h1>}
                                <div className="closeIcon" onClick={closeModal}><SVG src={CancelSvg} alt="닫기버튼" fill='red' /></div>
                            </div>
                            <div className="answer">
                                <h2>내용</h2>
                                <div className='textAreaBox'>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={oProps.message}
                                        onChange={handleMessageChange}
                                    />
                                    <p className='textLength'>{oProps.message.length}</p>
                                </div>

                            </div>
                            <div onClick={() => oProps.onSubmit(oProps.currentData, oProps.type)} className="submitButton">
                                {oProps.currentData.have_store_comment === 0 && <button>답글 남기기 완료</button>}
                                {oProps.currentData.have_store_comment === 1 && <button>답글 수정 완료</button>}
                            </div>
                        </div>
                    </AnswerModalStyle>
                </Modal>
            </BrowserView>
            <MobileView>

                <Modal
                    isOpen={modalIsOpen}
                    style={{
                        overlay: {
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,.4)',
                            height: '100vh',
                            zIndex: 100
                        },
                        content: {
                            padding: '0',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            border: 'none',
                            backgroundColor: 'rgba(0,0,0,.1)',
                            WebkitOverflowScrolling: 'touch',
                            outline: 'none',
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }
                    }}
                >
                    <MAnswerModalStyle>
                        <div className="answerModal">
                            <div className="title">
                                {oProps.currentData.have_store_comment === 0 && <h1>사장님 답변 입력</h1>}
                                {oProps.currentData.have_store_comment === 1 && <h1>사장님 답변 수정</h1>}
                                <div className="closeIcon" onClick={closeModal}><SVG src={CancelSvg} alt="닫기버튼" fill='red' /></div>
                            </div>
                            <div className="answer">
                                <h2>내용</h2>
                                <div className='textAreaBox'>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={oProps.message}
                                        onChange={handleMessageChange}
                                    />
                                    <p className='textLength'>{oProps.message.length}</p>
                                </div>

                            </div>
                            <div onClick={() => oProps.onSubmit(oProps.currentData, oProps.type)} className="submitButton">
                                {oProps.currentData.have_store_comment === 0 && <button>답글 남기기 완료</button>}
                                {oProps.currentData.have_store_comment === 1 && <button>답글 수정 완료</button>}
                            </div>
                        </div>
                    </MAnswerModalStyle>
                </Modal>
            </MobileView>
        </>
    )
};
export default AnswerReviewModal;

const AnswerModalStyle = styled.div`
    background: #fff;
    width: 30%;
    min-width: 448px;
    border-radius: 8px;
    
    *, h1, h2, h3, h4, p, span, ul, li, ol, button, textarea {
        font-family: ' Pretendard'; 
        margin: 0;
        padding: 0;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        border-bottom: 1px solid #DFDFE3;

        h1 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            flex: 0 0 95%;
            text-align: center;
        }
        .closeIcon {
            flex: 0 0 5%;
            margin-left: auto;

            svg{
                width: 16px;
                height: 16px;
            }
        }
    }
    .answer {
        padding: 24px;

        h2 {
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: 600;
        }
        .textAreaBox {
            position:  relative;
        }
        textarea {
            width: 100%;
            min-height: 200px;
            padding: 12px;
            border: 1px solid #DFDFE3;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            outline-color: #6490E7;
            resize:none;
        }
        .textLength {
            margin: 2% 2.5%;
            position: absolute;
            bottom: 0;
            right: 0;
            color: rgba(0, 0, 0, 0.4);
        }
    }
    .submitButton {
        width: 100%;
        padding: 16px 24px;

        button {
            width: 100%;
            padding: 13px;
            border: none;
            outline: none;
            border-radius: 8px;
            background: #6490e7;
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            line-height: 20px;
        }
    }
    
`


const MAnswerModalStyle = styled.div`
    background: #fff;
    width: 100%;
    height: 100vh;
    
    *, h1, h2, h3, h4, p, span, ul, li, ol, button, textarea {
        font-family: ' Pretendard'; 
        margin: 0;
        padding: 0;
    }
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 24px;
        border-bottom: 1px solid #DFDFE3;

        h1 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            flex: 0 0 95%;
            text-align: center;
        }
        .closeIcon {
            flex: 0 0 5%;
            margin-left: auto;

            svg{
                width: 16px;
                height: 16px;
            }
        }
    }
    .answer {
        padding: 24px;

        h2 {
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: 600;
        }
        .textAreaBox {
            position:  relative;
        }
        textarea {
            width: 100%;
            min-height: 200px;
            padding: 12px;
            border: 1px solid #DFDFE3;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            outline-color: #6490E7;
            resize:none;
        }
        .textLength {
            margin: 2% 2.5%;
            position: absolute;
            bottom: 0;
            right: 0;
            color: rgba(0, 0, 0, 0.4);
        }
    }
    .submitButton {
        width: 100%;
        padding: 16px 24px;

        button {
            width: 100%;
            padding: 13px;
            border: none;
            outline: none;
            border-radius: 8px;
            background: #6490e7;
            font-size: 16px;
            font-weight: 700;
            color: #fff;
            line-height: 20px;
        }
    }
    
`