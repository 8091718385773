import { WebRoute } from "../../../navigation/WebRoutes"

const layoutHeaderLists = {
    store: [
        { name: "매장정보", value: WebRoute.ADMIN_STORE_INFO },
        { name: "픽업정보", value: WebRoute.ADMIN_STORE_PICKUP_INFO },
        { name: "운영시간", value: WebRoute.ADMIN_STORE_OPERATION_INFO },
        { name: "휴무설정", value: WebRoute.ADMIN_STORE_DUVET },
        { name: "매장사진", value: WebRoute.ADMIN_STORE_PICTURE },
        { name: "배달관리", value: WebRoute.ADMIN_STORE_DELIVERY },
    ],
    product: [
        { name: "상품분류", value: WebRoute.ADMIN_PRODUCT_CATEGORY, },
        { name: "상품관리", value: WebRoute.ADMIN_PRODUCT_PRODUCT, },
        { name: "옵션관리", value: WebRoute.ADMIN_PRODUCT_OPTION, },
        { name: "내 브랜드 찾기", value: WebRoute.ADMIN_PRODUCT_BRAND, }
    ],
    business: [

        { name: "사업자인증", value: WebRoute.ADMIN_BUSINESS },
        { name: "개인정보", value: null },
        { name: "계약서확인", value: null }
    ],
    payment: [
        { name: "매출조회", value: WebRoute.ADMIN_PAYMENT },
        { name: "정산내역", value: WebRoute.ADMIN_PAYMENT_CALCULATE },
        { name: "차트", value: WebRoute.ADMIN_PAYMENT_CHART },
        { name: "부가세신고자료", value: WebRoute.ADMIN_PAYMENT_TAX },
    ],
    inventory: [
        { name: '대표메뉴', value: WebRoute.ADMIN_INVENTORY }
    ],
    coupon: [
        { name: '쿠폰 목록', value: WebRoute.ADMIN_COUPON },
        { name: '쿠폰 등록', value: WebRoute.ADMIN_COUPON_ADD }
    ],
    stamp: [
        { name: '스탬프 목록', value: WebRoute.ADMIN_STAMP },
        { name: '스탬프 등록', value: WebRoute.ADMIN_STAMP_ADD },
        { name: '스탬프 수정', value: WebRoute.ADMIN_STAMP_EDIT }
    ],
    commercial: [
        { name: '스루 광고 서비스', value: WebRoute.ADMIN_COMMERCIAL },
        { name: '광고 성과 차트', value: WebRoute.ADMIN_COMMERCIAL_CHART },
        { name: '스루 광고 추가하기', value: WebRoute.ADMIN_COMMERCIAL_ADD },
        { name: '광고 결제내역', value: WebRoute.ADMIN_COMMERCIAL_PAYMENT_LIST },
    ],
    guide: [
        { name: '포스 가이드', value: WebRoute.ADMIN_GUIDE_POS },
        { name: '프린터 가이드', value: WebRoute.ADMIN_GUIDE_PRINTER },
    ],
    notice: [
        { name: '전체', value: WebRoute.ADMIN_NOTICE },
    ]
}

export default layoutHeaderLists