import React, { useState, useEffect, useRef, Fragment } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components';
import { Pagination, message, Modal } from 'antd';
import Loader from "react-loader-spinner";
import Select from 'react-select';
import guide_arrow from '../../assets/svg/guide_arrow.svg';
import guide_question from '../../assets/svg/guide_question.svg';
import axios from 'axios';

const Inventory = ({ oProps, fnMoveCategory }) => {
   const [sKey, setKey] = useState(null);
   const [sCategory, setCategory] = useState("");
   const [sList, setSList] = useState([]);
   const [iList, setIList] = useState([]);
   const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

   useEffect(() => {
      function handleBeforeUnload(event) {
         if (hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = '';
         }
      }
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
         window.removeEventListener('beforeunload', handleBeforeUnload);
      };
   }, [hasUnsavedChanges]);


   const alertMessage = (sIndex) => {
      message.warning({
         content: sIndex,
         style: {
            fontSize: "0.9rem",
            fontWeight: "500",
         }
      });
   };

   const moveCategory = async (sIndex, aIndex) => {
      setHasUnsavedChanges(false);
      setCategory(aIndex);
      setKey(sIndex);
      getData(sIndex);

      // if (hasUnsavedChanges) {
      //    const confirmDiscardChanges = window.confirm('변경사항이 있습니다. 저장하시겠습니까?');
      //    if (!confirmDiscardChanges) {

      //    } else {
      //       onSubmit();
      //    }
      // } else {
      //    setHasUnsavedChanges(false);
      //    setCategory(aIndex);
      //    setKey(sIndex);
      //    getData(sIndex);;
      // }
   }

   // 품절처리
   const change_sold_status = (item, index) => {
      let aTemp = Array.from(iList);

      if (aTemp[index].sold === 0) {
         aTemp[index].sold = 1;
         item.sold = 1;
      } else if (aTemp[index].sold === 1) {
         aTemp[index].sold = 0;
         item.sold = 0;
      }
      setIList(aTemp);
      setHasUnsavedChanges(true);
   }
   // 메뉴숨김처리
   const change_hide_status = (item, index) => {
      let aTemp = Array.from(iList);

      if (aTemp[index].is_hide === 0) {
         aTemp[index].is_hide = 1;
         item.is_hide = 1;
      } else if (aTemp[index].is_hide === 1) {
         aTemp[index].is_hide = 0;
         item.is_hide = 0;
      }
      setIList(aTemp);
      setHasUnsavedChanges(true);
   }

   // 메뉴 카테고리 가져오기
   const asyncCategoryData = async () => {
      const store_id = oProps.UserConfigReducer.StoreID;
      const oResponse = await oProps.oManager.fDoAxios("/store/register/categoryList-" + store_id, "get", "login", null);
      if (oResponse !== undefined) {
         if (oResponse.sResult !== undefined) {
            if (oResponse.sResult.length > 0) {
               setSList(oResponse.sResult);
               setCategory(oResponse.sResult[0].name);
               setKey(oResponse.sResult[0].id);
               getData(oResponse.sResult[0].id);
            } else {
               alertMessage("상품등록이 필요합니다.");
               if (fnMoveCategory !== undefined && typeof fnMoveCategory === "function") {
                  await fnMoveCategory("dashboard");
               }
            }
         }
      }
   }


   // 메뉴 데이터 가져오기
   const getData = async (aIndex) => {
      let aData = [];

      const sIndex = aIndex;
      const store_id = oProps.UserConfigReducer.StoreID
      const oResponse = await oProps.oManager.fDoAxios("/inventory/menuSupplyList", "post", "login", { store_id, category_id: sIndex });
      console.log('데이터 가져오기oResponse>>>>>>>', oResponse)

      try {
         if (oResponse != undefined && oResponse != false && oResponse.success === true) {
            for (let i = 0; i < oResponse.stocks.length; i++) {
               const name = oResponse.stocks[i].name;
               const product_id = oResponse.stocks[i].product_id;
               const sold = oResponse.stocks[i].sold;
               const hide = oResponse.stocks[i].is_hide;

               const oParams = {
                  name: name,
                  product_id: product_id,
                  sold: Number(sold),
                  is_hide: Number(hide),
               };
               aData.push(oParams)

            }

            setIList(aData);
         } else {
            setIList([])
         }
      }
      catch (err) {
         console.log("err-->", err);
      }
   }

   // 저장하기
   const onSubmit = async () => {
      let temp = [];
      let tmp = Array.from(iList)

      
      for (let i = 0; i < iList.length; i++) {

         let tObj = { ...iList[i] };

         delete tObj.name;
         // delete tObj.selected_refresh_object;

         temp.push(tObj);
      }

      setIList(tmp);

      console.log("====>>>",temp);

      try {
         const data = {
            data:temp
         }

         
        console.log(oProps)
         const oResponse = await oProps.oManager.fDoBehideAxios("/api/store/upsertPrdSupply", "post", data);
         // console.log(oResponse)
         if (JSON.parse(oResponse.data).ok) {
            asyncCategoryData();
            setIList(tmp)
            message.success("저장되었습니다.");
            setHasUnsavedChanges(false);

         }else{
            message.error('오류가 발생했습니다. 다시 시도해 주세요');
         }

      } catch (err) {
         console.log("err-->", err);
         message.error("오류가 발생했습니다. 다시 시도해 주세요.");
      }
   }


   useEffect(() => {
      asyncCategoryData();
   }, []);

   return (
      <Fragment>
         <Style>
            <div className="kioskInventory">
               <div className="category_wrap">
                  {sList.length > 0 ?
                     <div className="tab">
                        <ScrollContainer className='scrollBar' hideScrollbars={false} style={{ width: "100%" }}>
                           {sList.map((item, index) => {
                              return (
                                 <div className={item.name === sCategory ? "scroll_menu_active" : "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.id, item.name)}>
                                    {item.name}
                                 </div>
                              )
                           })}
                        </ScrollContainer>
                     </div>
                     :
                     <div className="tab" style={{ display: "flex", justifyContent: "center", height: "8vh", alignItems: "center" }}>
                        <p>등록된 상품분류가 없습니다. 상품정보에서 상품분류를 등록해주세요.</p>
                     </div>
                  }
                  <div className="menu_wrap">
                     {
                        iList.map((item, index) => {
                           return (
                              <div className='menu'>
                                 <div className="menu_list">
                                    <h2>{item.name}</h2>
                                    <button className={item.sold === 0 ? "activeButton" : "unActiveButton"} onClick={() => change_sold_status(item, index)} >
                                       <p>{item.sold === 0 ? "판매중" : "일시품절"}</p>
                                    </button>
                                    <button style={{ marginLeft: '20px' }} className={item.is_hide === 0 ? "activeButton" : "unActiveButton"} onClick={() => change_hide_status(item, index)} >
                                       <p>{item.is_hide === 0 ? "메뉴 노출" : "메뉴 숨김"}</p>
                                    </button>
                                 </div>
                              </div>
                           )
                        })
                     }
                  </div>

               </div>
               <button className='submitButton' onClick={onSubmit}>저장하기</button>
            </div>
         </Style>
      </Fragment>
   )
}

export default Inventory;


const Style = styled.div`
   /* scrollbar 디자인 */
   .scrollBar::-webkit-scrollbar {
      height: 6px!important;
      margin-top: 16px!important;
   }
   /* Track */
   .scrollBar::-webkit-scrollbar-track {
      background: #f1f1f1; 
      margin-top: 16px;
   }
   /* Handle */
   .scrollBar::-webkit-scrollbar-thumb {
      background: #3538CD; 
   }
   /* Handle on hover */
   .scrollBar::-webkit-scrollbar-thumb:hover {
      background: #3538CD; 
   }
   
   .kioskInventory {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      padding: 0 3vw;
      margin: 10% 0;
   }

   .category_wrap {
      background: #fff;

      .tab {
         padding-top: 16px;
         overflow-x: auto;
         white-space: nowrap;
         background-color: #fff;
         border-radius: 4px;
         box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
      }

      .scroll_menu, .scroll_menu_active {
         display:  inline-block;
         padding: 0 20px 20px 20px;
         padding-bottom: ;
         cursor: pointer;
         font-size: 1.2rem;
      }

      .scroll_menu_active {
         display:  inline-block;
         font-weight: 700;
         color:#3538CD!important;
         
      }
   }

   .menu_wrap {
      margin: 40px 0;
      padding: 12px 24px;
      border-radius: 4px;
      box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
   }
   .menu_list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 0;
      border-bottom: 1px solid #D8DBDF;

      h2 {
         margin-right: auto;
         margin-bottom: 0;
         padding: 0;
         font-size: 1.4rem;
         font-weight: 700;
      }
      p {
         padding: 0;
         margin: 0;
         font-size: 1rem;
      }
      button {
         padding: 12px 24px;
         border-radius: 4px;
         outline: none;
         
         p{
            font-weight: 700;
         }
      }

      .activeButton {
         border: 1px solid #6490E7;
         background: #E8EFFC;

         p {
            color: #6490E7;
            font-weight: 700;
         }
      }
      .unActiveButton {
         border: 1px solid #DD1212;
         background: #fff;

         p{
            color: #DD1212;
            font-weight: 700;
         }
      }
   }

   .submitButton {
      position: fixed; 
      bottom: 3%;
      left: 0;
      right: 0;
      width: 94%;
      margin: 0 auto;
      padding: 12px 0;
      z-index:99;
      background: #3538CD;
      color: #fff;
      font-size: 1.6rem;
      font-weight: 700;
      border: none;
      outline: none;
      border-radius: 4px;
   }

`




