import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Modal, Select, notification } from 'antd';
import DaumPostcode from 'react-daum-postcode';
import 'antd/dist/antd.css';
import '../../../../assets/css/font.css';

import { WebRoute } from '../../../../navigation/WebRoutes';

import promotionBanner from '../../../../assets/img/ceoscreen/promotion/promotionBanner.png';
import promotionItem from '../../../../assets/img/ceoscreen/promotion/promotionItem.jpg';
import notice from '../../../../assets/img/ceoscreen/notice.png';

const { Option } = Select;

const PromotionScreen = oProps => {
   const [isLoading, setLoading] = useState(false);

   const [storeName, setStoreName] = useState("");
   const [iValue, setValue] = useState("");
   const [sPhoneNm, setPhoneNm] = useState("");
   const [sAddress, setAddress] = useState("");
   const [sExtraAddress, setExAddress] = useState("");

   // input 에러
   const [choiceError, setChoiceError] = useState("");
   const [storeError, setStoreError] = useState("");
   const [nameError, setNameError] = useState("");
   const [phoneError, setPhoneError] = useState("");
   const [addressError, setAddressError] = useState("");
   const [extraAddressError, setextraAddressError] = useState("");
   const [fileError, setFileError] = useState("");

   // 에러 className변경
   const [classStoreNameId, setClassStoreNameId] = useState(null);
   const [classNameId, setClassNameId] = useState(null);
   const [classPhone, setClassPhone] = useState(null);
   const [classAddress, setClassAddress] = useState(null);
   const [classExtraAddress, setClassExtraAddress] = useState(null);

   const [addressModal, setAddressModal] = useState(false);

   const navigate = useNavigate();

   const openNotificationWithIcon = (type, sTitle) => {
      notification[type]({
         message: sTitle,
      });
   };

   const inputStoreName = (e) => {
      let sValue = e.target.value;
      setStoreName(sValue);
      setStoreError("");

      if (sValue != '') {
         setClassStoreNameId("input");
      }
      setStoreName(e.target.value);
   }

   const inputOwnerName = (e) => {
      setValue(e.target.value);
      setClassNameId("input");
      setNameError("");
   }

   const inputExAddress = (e) => {
      setExAddress(e.target.value);
      setClassExtraAddress("input");
      setextraAddressError("");
   }

   const inputPhoneNumber = (e) => {
      const { value } = e.target;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
         setPhoneNm(e.target.value);
      }
      setPhoneError("");
      setClassPhone("input");
   }

   // 주소검색
   const handleComplete = async (data) => {
      let fullAddress = data.address;
      let extraAddress = '';

      if (data.addressType === 'R') {
         if (data.bname !== '') {
            extraAddress += data.bname;
         }
         if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
         }
         fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
      }
      setAddress(fullAddress);
      setClassAddress("input");
      setAddressError("");
      setAddressModal(false);
   }

   const onFinish = () => {
      if (storeName === "") {
         setClassStoreNameId("input_wrong");
         setStoreError("매장명을 입력해주세요");
         return false;
      }
      if (iValue === "") {
         setClassNameId("input_wrong");
         setNameError("수령인을 입력해주세요");
         return false;
      }
      if (sPhoneNm === "") {
         setClassPhone("input_wrong");
         setPhoneError("휴대폰 번호를 입력해주세요");
         return false;
      }
      if (sAddress === "") {
         setClassAddress("input_wrong");
         setAddressError("매장 주소를 입력해주세요");
         return false;
      }
      if (sExtraAddress === "") {
         setClassExtraAddress("input_wrong");
         setextraAddressError("매장 상세주소를 입력해주세요");
         return false;
      }

      setLoading(true);
      participate();
   }

   const participate = async () => {
      const oData = {
         iValue,
         storeName,
         sAddress,
         sPhoneNm,
         sExtraAddress,
      }
      const oResponse = await oProps.oManager.fDoAxios("/home/bannerEvent/v2", "post", null, oData);
      if (oResponse !== undefined) {
         if (oResponse) {
            await openNotificationWithIcon('info', "신청완료되었습니다");
            navigate(WebRoute.HOME);
         } else {
            openNotificationWithIcon('warning', "네트워크에러입니다 잠시 후 다시 시도바랍니다.");
         }
         setLoading(false);
      }
   }

   useEffect(() => {
      window.doKakaoPixel('스루마켓', 'tag');
      document.title = "스루마켓";
      document.querySelector('meta[name="keywords"]').setAttribute("content", '스루 마켓, 스루 홍보물, 스루 홍보물 이벤트');
      document.querySelector('meta[name="description"]').setAttribute("content", '스루 이용시 필요한 홍보물을 지원해 드립니다.');
      document.querySelector('link[rel="canonical"]').setAttribute("href", 'https://ceo.throo.co.kr/event');
      document.querySelector('meta[property="og:title"]').setAttribute("content", '스루마켓');
      document.querySelector('meta[property="og:description"]').setAttribute("content", '스루 이용시 필요한 홍보물을 지원해 드립니다.');
   }, []);

   return (
      <>
         <EventStyle>
            <div className="eventBanner">
               <div className="pBanner">
                  <img src={promotionBanner} alt="이벤트 배너 이미지" width="100%" />
               </div>
               <div className="container">
                  <div className="eventItem">
                     <img src={promotionItem} alt="이벤트 배너 이미지" style={{ maxWidth: "1120px" }} />
                  </div>
                  <div className="eventNotice">
                     <p>* a2 포스터, a3 포스터, a5 전단지, 테이블 텐트, 매장 부착용 스티커 2종, 테이블 텐트 2종으로 구성되어 있습니다. (위 이미지는 예시 이미지로 실제와 다를 수 있습니다.)</p>
                  </div>
               </div>
            </div>
         </EventStyle>
         {isLoading ?
            <Loading>
               <div className="singup">
                  <Loader type="BallTriangle" color="#00BFFF" height={80} width={80} timeout={9000000} />
               </div>
            </Loading>
            :
            <FormStyle>
               <div className="eventForm">
                  <div className="container">
                     <div className="mainTitle">
                        <h2>홍보물 신청</h2>
                        <img src={notice} alt="알림 아이콘" width="76px" height="76px" />
                     </div>
                     <h3>폼을 작성하고 신청하기 버튼을 눌러 주세요.</h3>
                     <div className="form-group">
                        <input placeholder="매장명" className={classStoreNameId} value={storeName} onChange={inputStoreName} />
                        <p className="errorText">{storeError}</p>
                        <input placeholder="수령인" value={iValue} className={classNameId} onChange={inputOwnerName} />
                        <p className="errorText">{nameError}</p>
                        <input placeholder="휴대폰 번호" className={classPhone} value={sPhoneNm} onChange={inputPhoneNumber} maxLength={11} />
                        <p className="errorText">{phoneError}</p>
                        <div className="address">
                           <input type="text" className={classAddress} value={sAddress} placeholder="주소" />
                           <button onClick={() => { setAddressModal(true) }}>검색</button>
                        </div>
                        <p className="errorText">{addressError}</p>
                        <input style={{ width: "100%", maxWidth: "394px" }} className={classExtraAddress} placeholder="상세 주소" value={sExtraAddress} onChange={inputExAddress} />
                        <p className="errorText">{extraAddressError}</p>
                        <button className="fBtn" type="primary" block size="large" onClick={onFinish} >신청하기</button>
                     </div>
                  </div>
               </div>
            </FormStyle>
         }
         <Modal visible={addressModal} centered={true} closable={false} onOk={() => { setAddressModal(false) }} onCancel={() => { setAddressModal(false) }} okText="확인" cancelText="취소">
            <AddressBox>
               <DaumPostcode
                  onComplete={handleComplete}
               />
            </AddressBox>
         </Modal>
      </>

   )
}


export default PromotionScreen;

const AddressBox = styled.div`

  
`;

const Loading = styled.div`

  
`;
// 홍보물 선택
export const EventStyle = styled.div`  
   p, a, h1, h2, h3, h4, h5, h6, button, input, label {
      transform: skew(-0.1deg);
      color: #000;
      font-family: 'NanumSquare';
   }

   html,
   body {
      margin: 0;
      padding: 0;
   }
   .container {
      width: 1120px;
      margin: 0 auto;
      padding: 0;
   }
   .eventBanner {
      margin-bottom: 100px;
   }
   .pBanner {
      margin-bottom: 46px;
   }
   .eventItem {
      display: flex;
      justify-content: center;
      align-items: center;

   }
   .eventNotice p {
      padding-top: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      line-height: 22px;
      margin: 0;
   }
`;

// 홍보물신청서
const FormStyle = styled.div`
   p, a, h1, h2, h3, h4, h5, h6, button, input, label {
      transform: skew(-0.1deg);
      color: #000;
      font-family: 'NanumSquare';
   }
   .eventForm {
      background-color: #F8F9FA;
      padding: 100px 0 140px 0;
   }
   .container {
      padding: 20px 20px 30px 20px;      
      width: 510px;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #eceef2;
   }

   .mainTitle {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      h2 {
         font-size: 22px;
         font-weight: 900;
      }
      img {
         padding: 15px;
         border-radius: 50px;
         background-color: #E5F3FF;
      }
   }
   h3{
      margin: 20px 0 44px 0;
      font-size: 16px;
      font-weight: 700;
   }

   input {
      width: 100%;
      padding: 12px 13px;
      margin-bottom: 12px;
      border: 1px solid #ECEEF2;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 700;
      outline: none;
   }

   /* 주소입력창 */
   .address {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
         width: 100%;
         max-width: 394px;
         background-color: #fff;
         outline: none;
      }
      button {
         margin-bottom: 12px;
         padding: 12px 19px;
         border-radius: 6px;
         border: none;
         outline: none;
         color: #fff;
         background-color: #212634;
         font-size: 16px;
         font-weight: 400;
      }
   }

   /* 신청하기 버튼 */
   .fBtn {
      padding: 12px 0;
      width: 100%;
      background-color: #1A7CFF;
      color: #fff;
      border: none;
      outline: none;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
   }

   /* 오류 css */
   .input_wrong {
      border: 1px solid #F45452 !important;
      background-color: #FEF5F5;
      outline: none;
   }
   .input {
      border: 1px solid #ECEEF2 !important;
      background-color: #fff;
      color: #888888;
      outline: none;
   }
   .errorText {
      font-size: 14px;
      font-weight: 700;
      color: #F45452;
   }

`;
