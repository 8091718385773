import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import NoticeCeoGuide from '../../ceoGuide/noticeTab/MainPosNotice'

// 이미지
import banner from '../../../../assets/img/ceoscreen/posDown/downBanner.png';
import androidPosDown from '../../../../assets/img/ceoscreen/posDown/android.png';
import windowPosDown from '../../../../assets/img/ceoscreen/posDown/window.png';
import posService from '../../../../assets/img/ceoscreen/posDown/posService.png';

const PosDownloadPage = (oProps) => {

    useEffect(() => {
        window.scrollSection();
    }, []);

    // 포스다운로드 링크
    const androidPos = () => {
        window.open('https://play.google.com/store/apps/details?id=com.throo_ceo', '_blank');
    }
    const windowPos = () => {
        window.open('https://api-pos.throo.kr/throopos/download.html', '_blank');
    }
    // 원격사이트 링크
    const remotePos = () => {
        window.open('http://helppc.net/throo', '_blank');
    }

    return (
        <Style>
            <div className="posDownload">
                <div className="banner" style={{ backgroundImage: `url(${banner})` }}>
                    <div className="container">
                        <div className="bCont">
                            <h2><span>스루 포스</span>로 간편하게<br />주문을 확인해보세요</h2>
                            <div className="download hide fade_up">
                                <h3>스루 포스 프로그램 다운로드</h3>
                                <p>PC 또는 모바일에 스루 포스를 다운로드하고 스루 주문을 확인해 보세요.</p>
                                <div className="downLink">
                                    <button className="android" onClick={androidPos}><img src={androidPosDown} alt="안드로이드포스 다운로드 이미지" /></button>
                                    <button className="window" onClick={windowPos}>PC 프로그램 다운로드<img src={windowPosDown} alt="윈도우포스 다운로드 이미지" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="posGuide">
                    <div className="container">
                        <NoticeCeoGuide />
                    </div>
                </div>
                <div className="pService">
                    <div className="container">
                        <div className="remote">
                            <div className="left">
                                <h2>사장님 원격지원<br />서비스</h2>
                                <button onClick={remotePos}>컴퓨터에서 원격 지원받기</button>
                            </div>
                            <img src={posService} alt="포스 원격지원 이미지" width="238px" height="238px" />
                        </div>
                    </div>
                </div>
            </div>
        </Style>
    )
}
export default PosDownloadPage;

export const Style = styled.aside`
     p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #000;
        font-family: 'NanumSquare';
    }

    html,
    body {
        display: block;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    
    /* 텍스트 애니메이션 */
    .in_view {
        opacity: 1;
        transition: all .9s ease-in-out;
    }
    .fade_up {
        opacity: 0;
        transform: translate3d(0, 15%, 0);
    }
    .in_view.fade_up{
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    .container {
        width: 1120px;
        margin: 0 auto;
        padding: 0 40px;
    }

    .banner {
        margin-top: 60px;
        background-image: 'url(${banner})';
        width: 100%;
        min-height: 463px;
        max-height: 463px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .bCont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 463px;
        max-height: 463px;

        h2 {
            font-size: 54px;
            font-weight: 500;
            line-height: 72px;
            color: #fff;

            span {
                font-weight: 800;
            }
        }
    }
    .download {
        padding: 29px 32px 46px 32px;
        max-width: 400px;
        background-color: #fff;
        border: 1px solid #ECEEF2;
        border-radius: 4px;

        h3 {
            font-size: 22px;
            font-weight: 900;
        }
        p {
            margin: 10px 0;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            word-break: keep-all;
        }

        .downLink {
            display: flex;
            flex-direction: column;
            text-align: center;
            
            button {
                margin-top: 10px;
                padding: 12px 0;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 900;
                color: #fff;
                border: none;
                outline: none;
            }
            .android {
                padding: 10px 0;
                background-color: #fff;
                border: 1px solid #6F6F6F;

                img {
                    width: 115px;
                    height: 24px;
                }
            }
            .window {
                background-color: #1A7CFF;

                img {
                    width: 16px;
                    height: 20px;
                    margin-left: 6px;
                }
            }
        }
    }
    
    /* 가이드 */
    /* tabMenu */
    .tab-menu ul {
        margin: 0 0 40px 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 900;
        border-bottom: 1px solid #ECEEF2;
    }

    .tab-menu ul li {
        list-style-type: none;
        display: inline-block;
        padding-bottom: 14px;
    }

    .tab-menu ul li a {
        text-decoration: none;
        font-size: 14px;
        font-weight: 900;
        margin-right: 42px;
        color: #888888;
        font-weight: 700;
        padding-bottom: 16px;
    }

    .tab-menu ul li a.active-a {
        border-bottom: 3px solid #000;
        color: #000;
        font-size: 14px;
        font-weight: 900;
        
    }

    .tab {
        display: none;
    }

    .tab-active {
        display: block;
    }


    .guide {
        margin-top: 150px;
    }
    .guideContent {
        margin-top: 40px;
        border-bottom: 1px solid #ECEEF2;
    }
    .guideContent:last-child {
        margin-bottom: 180px;
        border-bottom: none;
    }

    /* 원격지원 */
    .pService {
        width: 100%;
        margin-bottom: 0px;
        background-color: #E6F0FE;

        .remote {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 36px 100px;
        }
        h2 {
            margin-bottom: 20px;
            font-size: 38px;
            font-weight: 900;
            line-height: 50px;
            color: #000;
        }
        button {
            padding: 0;
            outline: none;
            background: none;
            border: none;
            border-bottom: 2px solid #1A7CFF;
            color: #1A7CFF;
            font-size: 22px;
            font-weight: 900;
        }
    }
`;