import React from 'react';
import styled from 'styled-components';

// 이미지
import coupon01 from '../../../../assets/img/ceoscreen/coupon01.png';
import coupon02 from '../../../../assets/img/ceoscreen/coupon02.png';
import coupon03 from '../../../../assets/img/ceoscreen/coupon03.png';
import coupon04 from '../../../../assets/img/ceoscreen/coupon04.png';
import coupon05 from '../../../../assets/img/ceoscreen/coupon05.png';
import coupon06 from '../../../../assets/img/ceoscreen/coupon06.png';
import coupon07 from '../../../../assets/img/ceoscreen/coupon07.png';
import coupon08 from '../../../../assets/img/ceoscreen/coupon08.png';

const StampGuide = (oProps) => {

    return (
        <D_coupon> 
            <div className="ceoTip">
                <div className="container">
                    <div className="cTip">
                        <h3>스루 가이드</h3>
                        <h1>쿠폰 기능 활용하기</h1>
                        <p>
                            쿠폰 기능은 매장을 찾은 고객에게 할인 혜택을 적용해 매출을 올리도록 돕는 기능입니다.<br />쿠폰을 이용하여 사장님 매장의 매출을 올려보세요.
                        </p>
                        <div className="subTitle">
                            <p>
                                스루 쿠폰 등록은<br />
                                <em>스루 사장님 창구 <span>&#8594;</span> 매장관리 <span>&#8594;</span> 로그인 <span>&#8594;</span> 쿠폰 관리 <span>&#8594;</span> 쿠폰 등록</em> 에서 가능합니다.
                            </p>
                        </div>
                        <div className="tip">
                            <h2><span>STEP 01</span><br />스루 사장님 창구에서 매장관리 클릭 후 로그인을 해주세요.</h2>
                            <img src={coupon01} alt="쿠폰관리 페이지 이동경로 안내" width="100%" />
                        </div>
                        <div className="tip">
                            <h2><span>STEP 2</span><br />쿠폰 관리 <span style={{ color: "#212329" }}>&#8594;</span> 쿠폰 등록 탭을 클릭해 주세요.</h2>
                            <img src={coupon02} alt="쿠폰관리 페이지 이동경로 안내" width="100%" />
                        </div>
                        <div className="div" style={{ border: "2px solid #EEEEEE", margin: "36px 0 22px 0"}}></div>
                        <div className="notice">
                            <h2>😊 TIP<br />쿠론 등록 과정을 알려드릴게요.</h2>
                            <p>쿠폰은 <span>금액 할인인 정액 할인</span>과 <span>퍼센트 할인인 정률 할인 </span>중 하나를 선택할 수 있어요.<br /> 메뉴의 가격변동률이나 매장 상황에 맞게 선택해 주세요!</p>
                        </div>
                        <div className="guide">
                            <h2>고객에게 보여지는 쿠폰 이름을 입력해 주세요.</h2>
                            <img src={coupon03} alt="쿠폰 이름 입력 이미지" width="100%" />
                            <h2>금액 할인과 퍼센트 할인 중 원하시는 할인 방법을 선택해 주세요.</h2>
                            <img src={coupon04} alt="쿠폰 할인방법 선택 이미지" width="100%" />
                            <h2>쿠폰 유효기간을 입력해 주세요. 기간 내에만 쿠폰이 노출돼요.</h2>
                            <img src={coupon05} alt="쿠폰 유효기간 입력 이미지" width="100%" />
                            <h2>쿠폰 수량을 제한하고 싶으시다면 원하시는 수량을 입력해 주세요.</h2>
                            <img src={coupon06} alt="쿠폰 수량 입력 이미지" width="100%" />
                            <h2>쿠폰 사용 시 최소 주문금액이 있다면 입력해 주세요.</h2>
                            <img src={coupon07} alt="쿠폰 최소 금액 입력 이미지" width="100%" />
                        </div>
                        <div className="app">
                            <h2>🙂 앱에선 이렇게 표시돼요.</h2>
                            <img src={coupon08} alt="앱적용 화면 이미지" width="100%" />
                            <h3>👍 가격의 메리트를 높일 수 있는 쿠폰 등록을 통해 매장의 주문율과 매출을 높여보세요!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </D_coupon>
    )
}

export default StampGuide;
export const D_coupon = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .cTip {
        margin-top: 106px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 16px 0;
            font-size: 32px;
            font-weight: 900;
            line-height: 48px;
        }
        p {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #1A7CFF;
        }
    }
    .tip {
        h2 {
            margin: 34px 0 10px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;

            span {
                color: #8E929F;
            }
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .notice {
        h2 {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 900;
            color: #212329;
            line-height: 32px;
        }
        p {
            margin-bottom: 60px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
            color: #212329;

            span {
                font-weight: 900;
                color: #1A7CFF;
            }
        }
        
    }
    .guide {
        h2 {
            margin: 36px 0 8px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;
            color: #212329;
        }
    }
    .app {
        margin-top: 62px;
        h2 {
            font-size: 22px;
            line-height: 36px;
            font-weight: 900;
            color: #212329;
        }
        img {
            margin: 10px 0 20px 0;
        }
        h3 {
            font-size: : 16px;
            font-weight: 900;
            line-height: 34px;
            color: #212329;
        }
    }
`