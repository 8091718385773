import React, { useState, useRef, useEffect, Fragment } from "react";
import ReactDragListView from "react-drag-listview";
import { Popconfirm, Table, message, Select } from "antd";
import {
  OrderedListOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import styled from "styled-components";
import OptionStyle from "../../../../assets/styledComponents/OptionStyle";
const { Option } = Select;

const sMaxList = [];
const sMinList = [];
for (let i = 1; i < 21; i++) {
  sMaxList.push({
    key: i,
    value: i,
    name: `${i} 개`,
  });
}
for (let i = 1; i < 21; i++) {
  sMinList.push({
    key: i,
    value: i,
    name: `${i} 개`,
  });
}
const SvgStyle = styled.div`
  .drag-handle {
    path {
      fill: black !important;
      color: black !important;
    }
  }
`;

const EditForm = ({ fEditList, iOptionList, iOptionId, fnChangeNormal }) => {
  const [iStatus, setStatus] = useState("");

  const [sNm, setNm] = useState("");
  const [sMax, setMax] = useState(1 + " 개");
  const [sMin, setMin] = useState(1 + " 개");
  const [iMax, setiMax] = useState(1);
  const [iMin, setiMin] = useState(1);
  const [sOptionType, setOptionType] = useState("radio");
  const [sOptionNm, setOptionNm] = useState("");
  const [sOptionPrice, setOptionPrice] = useState(0);

  const [classNm, setClassNm] = useState(null);
  const [classOptionNm, setClassOptionNm] = useState(null);
  const [classOptionPrice, setClassOptionPrice] = useState(null);

  const [sOptionId, setOptionId] = useState(0);

  const [iData, setData] = useState([]);
  const sData = useRef([]);
  const sColumns = useRef([
    {
      title: "순서",
      key: "key",
      render: (text, record, index) => (
        <SvgStyle>
          <a className="drag-handle">
            <OrderedListOutlined style={{ fill: "#000 !important" }} />
          </a>
        </SvgStyle>
      ),
    },
    {
      title: "옵션명",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "가격",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "삭제",
      key: "action",
      render: (text, record, index) => (
        <Popconfirm
          title="정말로 이 옵션을 삭제하시겠습니까?"
          onConfirm={() => deleteList(text.key)}
          okText="예"
          cancelText="아니오"
        >
          <div className="delete_box">
            <p>삭제</p>
          </div>
        </Popconfirm>
      ),
    },
  ]);

  const setChangeMin = (event) => {
    classNm !== "input_wrong" && setClassNm(null);
    classOptionNm !== "input_wrong" && setClassOptionNm(null);
    classOptionPrice !== "input_wrong" && setClassOptionPrice(null);

    setMin(event.target.value);
    setMax(event.target.value);
    setiMax(Number(event.target.value.substr(0, 2)));
    setiMin(Number(event.target.value.substr(0, 2)));
  };

  const onChangeStoreNameValue = (event) => {
    setClassNm("input_action");
    setNm(event.target.value);
  };

  const onChangeClassOptionNm = (event) => {
    setClassOptionNm("input_action");
    setOptionNm(event.target.value);
  };

  const onChangeClassOptionPrice = (event) => {
    const value = event.target.value;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      setClassOptionPrice("input_action");
      setOptionPrice(value);
    }
  };

  const inputClassStoreNm = () => {
    classNm !== "input_wrong" && setClassNm("input_action");
    setClassOptionNm(null);
    setClassOptionPrice(null);
  };
  const inputClassOptionNm = () => {
    classOptionNm !== "input_wrong" && setClassOptionNm("input_action");
    setClassNm(null);
    setClassOptionPrice(null);
  };
  const inputClassOptionPrice = () => {
    classOptionPrice !== "input_wrong" && setClassOptionPrice("input_action");
    setClassOptionNm(null);
    setClassNm(null);
  };

  const setChangeMax = (event) => {
    classNm !== "input_wrong" && setClassNm(null);
    classOptionNm !== "input_wrong" && setClassOptionNm(null);
    classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
    // if (isMobile) {
    //     setMax(event);
    // } else
    //     setMax(event.target.value);
    console.log(event.target.value);
    // console.log(event.target.value.substr(0, 2))
    setiMax(Number(event.target.value.substr(0, 2)));
    setMax(event.target.value);
  };

  const dragProps = (fromIndex, toIndex) => {
    let tempColumns = sData.current;
    const columnsCopy = tempColumns.slice();
    const item = columnsCopy.splice(fromIndex, 1)[0];
    columnsCopy.splice(toIndex, 0, item);
    sData.current = columnsCopy;
    setData(columnsCopy);
  };
  const changeOrder = (fromIndex, value) => {
    if (fromIndex + value < 0 || fromIndex + value >= sData.current.length)
      return;
    let tempColumns = sData.current;
    const columnsCopy = tempColumns.slice();
    const item = columnsCopy.splice(fromIndex, 1)[0];
    columnsCopy.splice(fromIndex + value, 0, item);
    sData.current = columnsCopy;
    setData(columnsCopy);
  };

  const changeStatus = async () => {
    if (fnChangeNormal !== undefined && typeof fnChangeNormal === "function") {
      setStatus("");
      await fnChangeNormal();
    }
  };

  const insertList = async () => {
    let temp = false;

    if (sOptionNm === "") {
      setClassOptionNm("input_wrong");
      temp = true;
    }
    if (sOptionPrice === "") {
      setClassOptionPrice("input_wrong");
      temp = true;
    }

    if (!temp) {
      const xData = {
        key: parseInt(sData.current.length) + 1,
        name: sOptionNm,
        price: parseInt(sOptionPrice),
      };
      setData([...sData.current, xData]);
      sData.current = [...sData.current, xData];
      classNm !== "input_wrong" && setClassNm(null);
      classOptionNm !== "input_wrong" && setClassOptionNm(null);
      classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
    }
  };

  const deleteList = (key) => {
    sData.current = sData.current.filter((item) => item.key !== key);
    setData(sData.current.filter((item) => item.key !== key));
  };

  const editOption = async () => {
    if (sNm === "") {
      setClassNm("input_wrong");
    } else if (iData.length < 1) {
      message.error("옵션을 지정해주세요.");
    } else {
      let oData = {
        sNm,
        sMin: iMin,
        sMax: iMax,
        sData: sData.current,
        sOptionType,
        sOptionId,
        iOptionList,
      };

      if (sOptionType !== "checkbox") {
        if (sData.current.length < parseInt(sMin.substr(0, 2))) {
          message.error("선택가능한 옵션이 필수선택횟수보다 작습니다.");
          return false;
        } else if (fEditList !== undefined && typeof fEditList === "function") {
          await fEditList(oData);
          console.log("최종", oData);
        }
      }
      if (sOptionType !== "radio") {
        if (sData.current.length < parseInt(sMax.substr(0, 2))) {
          message.error("선택가능한 옵션이 최소선택횟수보다 작습니다.");
          return false;
        } else if (fEditList !== undefined && typeof fEditList === "function") {
          await fEditList(oData);
          console.log("최종", oData);
        }
      }
    }
  };

  useEffect(() => {
    console.log("iOptionList", iOptionList);
    if (iStatus === "" || parseInt(sOptionId) !== parseInt(iOptionId)) {
      if (
        parseInt(
          (iOptionList.input_min === 0) &
            (iOptionList.input_max === 0) &
            (iOptionList.inputType === "radio")
        )
      ) {
        console.log("@@@@@@@@@@radio");
        setOptionType("radio");
        setMin(1 + " 개");
        setMax(1 + " 개");
        setiMin(1);
        setiMax(1);
      } else if (
        (iOptionList.input_min === 0) &
        (iOptionList.input_max === 0) &
        (iOptionList.inputType === "checkbox")
      ) {
        console.log("@@@@@@@@@checkbox@");
        setOptionType("checkbox");
        setMin(0 + " 개");
        setMax(1 + " 개");
        setiMin(0);
        setiMax(1);
      } else {
        setOptionType(iOptionList.inputType);
        setMin(iOptionList.input_min + " 개");
        setMax(iOptionList.input_max + " 개");
        setiMin(iOptionList.input_min);
        setiMax(iOptionList.input_max);
      }

      // if (parseInt(iOptionList.minCount) > 1) {
      //     setOptionType("radio");
      //     setMin(parseInt(iOptionList.minCount) + " 개");
      //     // setiMin(iOptionList.minCount)
      //     // setiMax(iOptionList)
      // } else {
      //     if (iOptionList.inputType === "radio") {
      //         setOptionType("radio");
      //         setMin("1 개");
      //     } else {
      //         setOptionType("checkbox");
      //         setMax(parseInt(iOptionList.maxCount) + " 개");
      //         setMin("0 개")
      //     }
      // }

      setOptionId(iOptionId);
      setData(iOptionList.list);
      setNm(iOptionList.sGroupTitle);
      sData.current = iOptionList.list;
    }
  }, [iOptionList, iOptionId]);

  const changeOptionType = (sType) => {
    if (sType == "radio") {
      setOptionType("radio");
      setMin("1 개");
      setMax("1 개");
      setiMin(1);
      setiMax(1);
    } else if (sType == "checkbox") {
      setOptionType("checkbox");
      setMax(1 + " 개");
      setMin("0 개");
      setiMin(0);
      setiMax(1);
    }
  };

  return (
    <Fragment>
      <BrowserView>
        <OptionStyle>
          <div className="container">
            <div className="title_box">
              <h2>옵션 관리 수정</h2>
              <div className="cancel_box" onClick={() => changeStatus()}>
                <p>수정취소</p>
              </div>
            </div>
            <div className="category_title" style={{ marginBottom: "15px" }}>
              <p>옵션그룹명</p>
              <input
                type="text"
                placeholder="예) 사이드메뉴 추가"
                className={classNm}
                onClick={inputClassStoreNm}
                value={sNm}
                onChange={onChangeStoreNameValue}
              />
            </div>
            <div className="essential_box" style={{ marginBottom: "15px" }}>
              <p>필수여부</p>
              <div className="select_line_box">
                <div
                  className={iMin > 0 ? "selected_wrapper" : "disable_wrapper"}
                  onClick={() => {
                    changeOptionType("radio");
                  }}
                >
                  <p>선택해야 주문가능 </p>
                </div>
                <div
                  className={
                    iMin === 0 ? "selected_wrapper" : "disable_wrapper"
                  }
                  onClick={() => {
                    changeOptionType("checkbox");
                  }}
                >
                  <p>선택안해도 주문가능 </p>
                </div>
              </div>
            </div>
            {!((iMin === 0 && iMax === 0) || iMin > 0) ? (
              <div className="category_title" style={{ marginBottom: "15px" }}>
                <p>최대선택수</p>
                <select value={sMax} onChange={setChangeMax}>
                  {sMaxList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  ;
                </select>
              </div>
            ) : (
              <div className="category_title" style={{ marginBottom: "15px" }}>
                <p>필수선택수</p>
                <select value={sMin} onChange={setChangeMin}>
                  {sMinList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  ;
                </select>
              </div>
            )}
            <div className="option_box">
              <p>옵션</p>
              <div className="option_subTitle">
                <input
                  type="text"
                  placeholder="예) 사이드메뉴 추가"
                  className={classOptionNm}
                  onClick={inputClassOptionNm}
                  value={sOptionNm}
                  onChange={onChangeClassOptionNm}
                />
                <div className="option_price">
                  <input
                    type="text"
                    placeholder="예) 사이드메뉴 추가"
                    className={classOptionPrice}
                    onClick={inputClassOptionPrice}
                    value={sOptionPrice}
                    onChange={onChangeClassOptionPrice}
                  />
                  <p className="pay_box">원</p>
                </div>
                <div className="insert_box" onClick={insertList}>
                  <h3>+ 추가하기</h3>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "5%" }}>
              <ReactDragListView.DragColumn
                onDragEnd={dragProps}
                handleSelector="a"
              >
                <Table
                  columns={sColumns.current}
                  dataSource={iData}
                  pagination={false}
                />
              </ReactDragListView.DragColumn>
            </div>
            <div className="location_box_line">
              <div className="how_box">
                <p>
                  순서를 변경하고 싶으시다면 순서의 아이콘을 잡고 드래그를
                  해주세요!
                </p>
              </div>
            </div>
            <div className="register_box" onClick={editOption}>
              <p>수정완료</p>
            </div>
          </div>
        </OptionStyle>
      </BrowserView>
      <MobileView>
        {/* <div className="category_title_line">
                    <div className="category_title">
                        <div className="title_box">
                            <p className="title_line">옵션관리</p>
                            <p className="detail_line"></p>
                        </div>
                        <div className="cancel_box" onClick={() => changeStatus()}>
                            <p>수정취소</p>
                        </div>
                    </div>
                    <div className="inputText">
                        <h3>옵션그룹명</h3>
                        <input
                            type="text"
                            placeholder="예) 사이드메뉴 추가"
                            className={classNm}
                            onClick={inputClassStoreNm}
                            value={sNm}
                            onChange={onChangeStoreNameValue}
                        />
                    </div>
                    <div className="essential_box">
                        <p>필수여부</p>
                        <div className="select_line_box">
                            <div className={sOptionType === "radio" ? "selected_wrapper" : "disable_wrapper"}>
                                <p>선택해야 주문가능</p>
                            </div>
                            <div className={sOptionType === "checkbox" ? "selected_wrapper" : "disable_wrapper"} style={{ marginLeft: "2%" }}>
                                <p>선택안해도 주문가능</p>
                            </div>
                        </div>
                    </div>
                    {sOptionType === "checkbox" ?
                        <div className="inputText">
                            <p>최대선택수</p>
                            <Select value={sMax} onChange={setChangeMax} className="select_bank" bordered={false}>
                                {sMaxList.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                        :
                        <div className="inputText">
                            <p>최소선택수</p>
                            <Select value={sMin} onChange={setChangeMin} className="select_bank" bordered={false}>
                                {sMinList.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.id}>{item.name}</Option>
                                    )
                                })}
                            </Select>
                        </div>
                    }
                    <div className="option_box">
                        <p>옵션</p>
                        <div className="option_input">
                            <input
                                style={{ marginRight: "3%" }}
                                type="text"
                                placeholder="예) 사이드메뉴 추가"
                                className={classOptionNm}
                                onClick={inputClassOptionNm}
                                value={sOptionNm}
                                onChange={onChangeClassOptionNm}
                            />
                            <input
                                type="text"
                                placeholder="예) 사이드메뉴 추가"
                                className={classOptionPrice}
                                onClick={inputClassOptionPrice}
                                value={sOptionPrice}
                                onChange={onChangeClassOptionPrice}
                            />
                            <p className="pay_box">원</p>
                        </div>
                    </div>
                    <div className="insert_box" onClick={insertList}>
                        <p>옵션 추가</p>
                    </div>
                    {iData !== undefined && iData !== null && iData.length > 0 &&
                        <div className="option_list">
                            <p>옵션 리스트</p>
                            {iData.map((item, index) => {
                                return (
                                    <div key={index} className="option_list_box">
                                        <div className="option_name_list">
                                            <p>{item.name}</p>
                                        </div>
                                        <div className="option_price_list">
                                            <p>{item.price}</p>
                                        </div>
                                        <div onClick={() => changeOrder(index, -1)} className='option_order'>
                                            <ArrowUpOutlined />
                                        </div>
                                        <div onClick={() => changeOrder(index, 1)} className='option_order'>
                                            <ArrowDownOutlined />
                                        </div>
                                        <p className="pay_box">원</p>
                                        <div className="option_delete_box" onClick={() => deleteList(item.key)}>
                                            <p>삭제</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    
                </div> */}
        <OptionStyle>
          <div className="mobile_container">
            <div className="title_box">
              <h2>옵션 관리 수정</h2>
              <div className="cancel_box" onClick={() => changeStatus()}>
                <p>수정취소</p>
              </div>
            </div>
            <div className="category_title_mobile" style={{ margin: "15px 0" }}>
              <p>옵션그룹명</p>
              <input
                type="text"
                placeholder="예) 사이드메뉴 추가"
                className={classNm}
                onClick={inputClassStoreNm}
                value={sNm}
                onChange={onChangeStoreNameValue}
              />
            </div>
            <div className="essential_box" style={{ marginBottom: "15px" }}>
              <p>필수여부</p>
              <div className="select_line_box">
                <div
                  className={iMin > 0 ? "selected_wrapper" : "disable_wrapper"}
                  onClick={() => changeOptionType("radio")}
                >
                  <p>선택해야 주문가능 </p>
                </div>
                <div
                  className={iMin > 0 ? "selected_wrapper" : "disable_wrapper"}
                  onClick={() => changeOptionType("checkbox")}
                >
                  <p>선택안해도 주문가능 </p>
                </div>
              </div>
            </div>
            {!((iMin === 0 && iMax === 0) || iMin > 0) ? (
              <div
                className="category_title_mobile"
                style={{ marginBottom: "15px" }}
              >
                <p>최대선택수</p>
                <select
                  value={sMax}
                  onChange={setChangeMax}
                  style={{ width: "100%", padding: "10px 4px" }}
                >
                  {sMaxList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  ;
                </select>
              </div>
            ) : (
              <div
                className="category_title_mobile"
                style={{ marginBottom: "15px" }}
              >
                <p>필수선택수</p>
                <select
                  value={sMin}
                  onChange={setChangeMin}
                  style={{ width: "100%", padding: "10px 4px" }}
                >
                  {sMinList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                  ;
                </select>
              </div>
            )}
            <div className="option_box">
              <p>옵션</p>
              <div className="option_subTitle">
                <input
                  type="text"
                  placeholder="예) 사이드메뉴 추가"
                  className={classOptionNm}
                  onClick={inputClassOptionNm}
                  value={sOptionNm}
                  onChange={onChangeClassOptionNm}
                />
                <div className="option_price">
                  <input
                    type="text"
                    placeholder="예) 사이드메뉴 추가"
                    className={classOptionPrice}
                    onClick={inputClassOptionPrice}
                    value={sOptionPrice}
                    onChange={onChangeClassOptionPrice}
                  />
                  <p className="pay_box">원</p>
                </div>
                <div
                  className="insert_box"
                  onClick={insertList}
                  style={{ whiteSpace: "nowrap", padding: "1px 0" }}
                >
                  <h3>+ 추가</h3>
                </div>
              </div>
            </div>

            {iData !== undefined && iData !== null && iData.length > 0 && (
              <div className="option_list moblie">
                <p>옵션 리스트</p>
                <div className="option_list_box_header">
                  <div className="option_name_list">옵션명</div>
                  <div className="option_price_list">가격</div>
                  <div className="option_order_title">순서 변경</div>
                  <div className="option_delete_box">삭제</div>
                </div>
                {iData.map((item, index) => {
                  return (
                    <div>
                      <div key={index} className="option_list_index">
                        <div className="option_name_list">
                          <p>{item.name}</p>
                        </div>
                        <div className="option_price_list">
                          <p>{item.price}</p>
                          <p>원</p>
                        </div>
                        <div
                          onClick={() => changeOrder(index, -1)}
                          className="option_order"
                        >
                          <ArrowUpOutlined />
                        </div>
                        <div
                          onClick={() => changeOrder(index, 1)}
                          className="option_order"
                        >
                          <ArrowDownOutlined />
                        </div>
                        <div
                          className="option_delete_box button"
                          onClick={() => deleteList(item.key)}
                        >
                          <p>삭제</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className="location_box_line" style={{ marginTop: "2px" }}>
              <div className="how_box" style={{ padding: "10px 2px" }}>
                <p style={{ fontSize: "12px" }}>
                  순서를 변경하고 싶으시다면 순서 변경 화살표를 클릭해주세요!
                </p>
              </div>
            </div>
            <div className="bottom_fixed">
              <button className="finishButton" onClick={editOption}>
                수정완료
              </button>
            </div>
          </div>
        </OptionStyle>
      </MobileView>
    </Fragment>
  );
};

export default EditForm;
