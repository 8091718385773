import React, { useState, useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Loader from "react-loader-spinner";

import LineChart from '../../../../variables/LineChart';

const SalesStatus = ({ sData, mState }) => {
    const [dataList, setDataList] = useState({});
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const iConfig = {
            data: sData.sChart,
            xField: 'date',
            yField: 'scale',
            meta: {
                date: { alias: '날짜' },
                scale: { alias: '주문건수' },
            },
        };
        setChartData(iConfig);
        setDataList(sData);
    }, [sData])
    
    return (
        <div>
            <BrowserView>
            <div className="title">
                <h1>주문현황</h1>
                <p>{dataList.date}</p>
            </div>
            <div className="order">
                <div className="orderLeft">
                    <h2>사장님 당일 매출은<br /><span>{dataList.payment}원</span> 입니다.</h2>
                    <div className="orderBoxTop">
                        <div className="box">
                            <h2>당일 전체주문</h2>
                            <p><span>{dataList.totalCount}</span>건</p>
                        </div>
                        <div className="box">
                            <h2>어제 주문건수</h2>
                            <p><span>{dataList.yesterday}</span>건</p>
                        </div>
                        <div className="box">
                            <h2>주문수락률</h2>
                            <p><span>{dataList.percent}</span>%</p>
                        </div>
                    </div>
                    <div className="orderBoxBottom">
                        <div className="box">
                            <div className="totalCancle">
                                <h2>전체취소</h2>
                                <p><span>{dataList.totalCancel}</span>건</p>
                            </div>
                            <div className="cancle">
                                <h2>고객취소</h2>
                                <p><span>{dataList.userCancel}</span>건</p>
                            </div>
                            <div className="cancle">
                                <h2>시간초과</h2>
                                <p><span>{dataList.cancel}</span>건</p>
                            </div>
                            <div className="cancle">
                                <h2>판매자사유</h2>
                                <p><span>{dataList.storeCancel}</span>건</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="orderRight">
                    <div className="chart_corner">
                        {chartData.data == undefined && chartData.data == null ?
                            <div style={{display: "flex", justifyContent: "center", height: "30vh",alignItems: "center"}}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                            </div>
                            :
                            <LineChart sChart={chartData}/>
                        }
                    </div>
                </div>
            </div>
            </BrowserView>
            <MobileView>
                
            <div className="content1">
                <div className="title">
                    <h1>주문현황</h1>
                    <p>{dataList.date}</p>
                </div>
                <div className="order">
                    <h2>사장님 당일 매출은<br /><span>{dataList.payment}원</span> 입니다.</h2>
                    <div className="orderBoxTop">
                        <div className="box">
                            <h2>당일 전체주문</h2>
                            <p><span>{dataList.totalCount}</span>건</p>
                        </div>
                        <div className="box">
                            <h2>어제 주문건수</h2>
                            <p><span>{dataList.yesterday}</span>건</p>
                        </div>
                        <div className="box">
                            <h2>주문수락률</h2>
                            <p><span>{dataList.percent}</span>%</p>
                        </div>
                    </div>
                    <div className="orderBoxBottom">
                        <div className="box">
                            <div className="totalCancle">
                                <h2>전체취소</h2>
                                <p><span>{dataList.totalCancel}</span>건</p>
                            </div>
                            <div className="cancle">
                                <h2>고객취소</h2>
                                <p><span>{dataList.userCancel}</span>건</p>
                            </div>
                            <div className="cancle">
                                <h2>시간초과</h2>
                                <p><span>{dataList.cancel}</span>건</p>
                            </div>
                            <div className="cancle">
                                <h2>판매자사유</h2>
                                <p><span>{dataList.storeCancel}</span>건</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chart_corner">
                    {chartData.data == undefined && chartData.data == null ?
                        <div style={{display: "flex", justifyContent: "center", height: "30vh",alignItems: "center"}}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000}/> 
                        </div>
                    :
                        <div className="chartBox">
                            <LineChart sChart={chartData}/>
                        </div>
                    }
                </div>
            </div>
            </MobileView>
        </div>
    )
}

export default SalesStatus;
