import React, { Fragment, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { message } from 'antd';
import "antd/dist/antd.css";
import { BrowserView, MobileView } from 'react-device-detect';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import { WebRoute } from '../../../navigation/WebRoutes';


const Main = ({ sProps, sException, fnMoveCategory, children, routeList = [] }) => {
    const [iStatus, setStatus] = useState(1);
    const [sCategory, setCategory] = useState("사업자인증");
    const [oSharedMenuData, set_oSharedMenuData] = useState('');
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [sList] = useState([
        { id: 1, name: "사업자인증", value: "certificate" },
        { id: 2, name: "개인정보", value: "information" },
        { id: 2, name: "계약서확인", value: "contract" },
    ]);
    useEffect(() => {
        console.log("loc", location)
    }, [])
    const moveCategory = async (aIndex, sIndex) => {
        if (sIndex === null) {
            message.info("준비중입니다.");
        } else {
            setStatus(sIndex);
            navigate(sIndex);
            // setCategory(aIndex);

        }
    }

    const checkForSharedMenu = async () => {
        const store_id = sProps.UserConfigReducer.StoreID;
        setLoading(true)
        const oResponse = await sProps.oManager.fDoAxios("/store/menu/checkShared", "post", null, { store_id: store_id });
        if (oResponse !== undefined) {
            if (oResponse.has_shared_menu !== undefined && oResponse.has_shared_menu === 1) {
                set_oSharedMenuData(oResponse);
            }
            else {
                set_oSharedMenuData('');
            }
        }
        setLoading(false)
    }
    useEffect(() => {
        if (location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0) {
            checkForSharedMenu();
        }
    }, []);

    return (
        <Fragment>
            <BrowserView>
                <Style>
                    <div className="wrapper">
                        <Row>
                            <Col>
                                <div className="menu_container">
                                    <div className="tab">
                                        <ScrollContainer style={{ width: "100%" }}>

                                            {routeList.map((item, index) => {
                                                return (
                                                    <div className={item.value === location.pathname ? "scroll_menu_active" : "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.name, item.value)}>
                                                        {item.name}
                                                        {(item.value === WebRoute.ADMIN_BUSINESS || item.value === WebRoute.ADMIN_PRODUCT_CATEGORY || item.value === WebRoute.ADMIN_PRODUCT_PRODUCT || item.value === WebRoute.ADMIN_STORE_INFO || item.value === WebRoute.ADMIN_STORE_PICKUP_INFO || item.value === WebRoute.ADMIN_STORE_OPERATION_INFO) &&
                                                            <>
                                                                {sProps.UserConfigReducer.accessible === "inaccessible" &&
                                                                    <div className="app_guide__container">
                                                                        <div className="text_box">
                                                                            <div className="title_box">
                                                                                <p>필수!</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </ScrollContainer>
                                    </div>
                                </div>
                                {parseInt(iStatus) === 0 &&
                                    <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                        <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                                    </div>
                                }
                                {oSharedMenuData !== '' && oSharedMenuData.has_shared_menu === 1 && location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0 &&
                                    <Row>
                                        <Col>
                                            <div className="menu_container">
                                                <div className="tab" style={{ height: '10vh' }}>
                                                    <div className="title_box">
                                                        <p style={{ paddingLeft: 30, fontSize: 14, color: 'red' }}>공유 메뉴 사용하고 있습니다. </p>
                                                        <p style={{ paddingLeft: 30, fontSize: 14, color: 'red' }}>'내 브랜드 찾기' 기능을 제외한 메뉴 수정이 필요하시면 마스터 메뉴 수정하십시오.</p>
                                                        <p style={{ paddingLeft: 30, fontSize: 14, color: 'red', paddingTop: 4, fontWeight: 600 }}>연결된 마스터 메뉴 매장명 : {oSharedMenuData.store_name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                {location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0 && location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_BRAND) < 0 ?
                                    oSharedMenuData !== '' && oSharedMenuData.has_shared_menu === 1 ? <></>
                                        : loading ? <></> : children : undefined}

                                {location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) < 0 || location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_BRAND) >= 0 ? children : <></>
                                }
                            </Col>
                        </Row>
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <Mstyle>
                    <div className="slaesMobilePage">
                        <div className="tapPage">
                            <div className="menu_container">
                                <div className="tab">
                                    <ScrollContainer style={{ width: "100%" }}>
                                        {routeList.map((item, index) => {
                                            return (
                                                <div className={item.value === location.pathname ? "scroll_menu_active" : "scroll_menu"} key={"listing-" + index.toString()} onClick={() => moveCategory(item.name, item.value)}>

                                                    {(item.value === WebRoute.ADMIN_BUSINESS || item.value === WebRoute.ADMIN_PRODUCT_CATEGORY || item.value === WebRoute.ADMIN_PRODUCT_PRODUCT || item.value === WebRoute.ADMIN_STORE_INFO || item.value === WebRoute.ADMIN_STORE_PICKUP_INFO || item.value === WebRoute.ADMIN_STORE_OPERATION_INFO) &&
                                                        <>
                                                            {sProps.UserConfigReducer.accessible === "inaccessible" &&
                                                                <div className="app_guide__container">
                                                                    <div className="text_box">
                                                                        <div className="title_box">
                                                                            <p>필수!</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    {item.name}
                                                </div>
                                            )
                                        })}
                                    </ScrollContainer>
                                </div>
                            </div>
                        </div>
                        {parseInt(iStatus) === 0 &&
                            <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                        }
                        {oSharedMenuData !== '' && oSharedMenuData.has_shared_menu === 1 &&
                            <div className="tapPage">
                                <div className="menu_container" style={{ height: '12vh' }}>
                                    <p style={{ fontSize: 14, color: 'red', paddingLeft: 16, paddingRight: 16, paddingTop: 10 }}>공유 메뉴 사용하고 있습니다. 메뉴를 수정이 필요하시면 마스터 메뉴 수정하십시오. <span style={{ fontSize: 14, color: 'red', paddingTop: 4, fontWeight: 600 }}><br />연결된 마스터 메뉴 매장명 : {oSharedMenuData.store_name}</span>
                                    </p>
                                </div>
                            </div>
                        }
                        {location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0 && location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_BRAND) < 0 ?
                            oSharedMenuData !== '' && oSharedMenuData.has_shared_menu === 1 ? <></>
                                : loading ? <></> : children : undefined}
                        {location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) < 0 || location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_BRAND) >= 0 ? children : <></>
                        }
                    </div>
                </Mstyle>

            </MobileView>
        </Fragment>
    )
}

export default Main;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }  

    .app_guide__container {
        position: absolute;
        top: 5px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        margin-left: 2%;

        .text_box {
            width: 45px;
            height: 10px;
            .title_box {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                background: #EF4452;
                border-radius: 5px;

                p {
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
    }

    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }
    
    .menu_container {
        margin-top: 10px;
        border-radius: 4px;
        white-space: nowrap;
        .tab {
            margin-bottom: 24px;
            width: 42vw; 
            display: flex;
            align-items: center;
            height: 8vh;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }

        .tab {
            -ms-overflow-style: none; 
            scrollbar-width: none; 
        }
        .tab::-webkit-scrollbar {
            display: none; 
        }
    }

`


const Mstyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
        color: #000;
    }

    .app_guide__container {
        position: absolute;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        cursor: pointer;
        top: 8%;


        .text_box {
            width: 100%;
            .title_box {
                padding: 1px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #EF4452;
                border-radius: 5px;

                p {
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
    }
    
    .slaesMobilePage {
        background-color: #f1f1f1;
    }

    .tapPage {
        background-color: #F1F3F7;
        padding-bottom: 8px;
    }

    .menu_container {
        background: #fff;
        height: 10vh;

        .tab {
            display: flex;
            align-items: center;
            height: 10vh;
            margin-bottom: 24px;
            background-color: #fff;
            border-radius: 4px;
            overflow: auto;
            white-space: nowrap;
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }
    }

    .tab {
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    .tab::-webkit-scrollbar {
        display: none; 
    }

`

