import React from 'react';
import styled from 'styled-components';

// 이미지
import timeSetting01 from '../../../../assets/img/guide/pos/timeSetting01.png';
import timeSetting02 from '../../../../assets/img/guide/pos/timeSetting02.png';
import timeSetting03 from '../../../../assets/img/guide/pos/timeSetting03.png';
import timeSetting04 from '../../../../assets/img/guide/pos/timeSetting04.png';
import timeSetting05 from '../../../../assets/img/guide/pos/timeSetting05.png';

const TimeSettingGuide = (oProps) => {

    return (
        <D_timeSettingGuide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>포스 가이드</h3>
                        <h1>포스에서 매장 준비 시간 설정 방법</h1>
                        <p>준비 시간의 기본 설정 시간은 매장 등록 시 입력하셨던 상품 준비 시간입니다. <br />매장 혼잡도가 실시간으로 변경되는 경우에는 간편하게 포스에서 바로 준비 시간을 변경하실 수 있습니다.</p>
                        <h2><span>STEP 1</span><br />수동으로 준비 시간 변경하기</h2>
                        <img src={timeSetting01} alt="포스 알림 설정" width="400px" />
                        <p>
                            바를 클릭한 후 원하는 시간으로 드래그를 해주세요.<br />
                            수동으로 변경 시 실시간으로 고객 앱에 변경 시간이 표시됩니다.
                        </p>
                        <h2><span>STEP 2</span><br />기본 설정 시간으로 변경하기</h2>
                        <img src={timeSetting02} alt="포스 알림 설정" width="400px" />
                        <p>다시 기본 설정 시간으로 변경을 원할 경우 ‘수동’ 글자를 클릭하시면 변경하실 수 있습니다.</p>
                        <h2><span>STEP 3</span><br />매장 운영 일시정지 설정</h2>
                        <img src={timeSetting03} alt="포스 알림 설정" width="400px" />
                        <p>
                            바를 클릭 후 오른쪽 맨 끝까지 드래그하시면 즉시 매장 운영이 일시정지가 됩니다.<br />
                            매장이 혼잡하여 스루 주문 접수가 어려운 경우 잠시 일시정지를 해보세요. <br />
                            다시 운영을 원하실 경우 원하시는 시간으로 드래그하시면 손쉽게 일시정지를 풀 수 있습니다.
                        </p>
                        <div className="tip">
                            <p>
                                😊 TIP <br />
                                기본 설정값으로 등록되어 있는 상품 준비 시간도 언제든지 변경이 가능합니다.
                            </p>
                            <h2>스루사장님창구 <span style={{ color: '#212329' }}>&gt;</span> 매장관리 <span style={{ color: '#212329' }}>&gt;</span> 로그인 <span style={{ color: '#212329' }}>&gt;</span> 매장관리 <span style={{ color: '#212329' }}>&gt;</span>매장정보에서 변경해 주세요.</h2>
                            <img src={timeSetting04} alt="포스 알림 설정" width="90%" />
                        </div>
                        <div className="tip">
                            <p>
                                😊 TIP <br />
                                상품을 고객에게 전달하는 시간을 토대로 상품 준비 시간을 설정할 수 있습니다.<br />
                                매장의 혼잡도에 따라 시간대별로 상품 준비 시간을 여유, 보통, 바쁨 상태로 설정해 보세요. <br />
                                변경을 원하시는 경우 스루사장님창구 <span style={{ color: '#212329' }}>&gt;</span>  매장관리 <span style={{ color: '#212329' }}>&gt;</span>  로그인 <span style={{ color: '#212329' }}>&gt;</span>  매장관리 <span style={{ color: '#212329' }}>&gt;</span>  운영시간에서 변경해 주세요.
                            </p>
                            <img src={timeSetting05} alt="포스 알림 설정" width="90%" style={{ marginTop: "25px" }} />
                            <p>여유, 보통, 바쁨은 매장 상황에 맞게 시간별로 설정하실 수 있습니다.</p><br /><br />
                            <p>매장의 혼잡도가 실시간으로 변동되는 만큼 포스에서 터치 한 번으로 간편하게 매장 준비 시간을 변경해 보세요😊</p>
                        </div>
                    </div>
                </div>
            </div>
        </D_timeSettingGuide>
    )
}
export default TimeSettingGuide;

export const D_timeSettingGuide = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .pGuideCont {
        margin-top: 110px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;
        word-break: keep-all;

        span {
            color: #8E929F;
        }
    }
    h2 span:nth-child(3) {
        color: #F45452;
        font-weight: 900;
    }
    p {
        margin: 15px 0 25px 0;
        font-size: 16px;
        font-weight: 700;

        span {
            color: #F45452;
            font-weight: 900;
        }
    }
    .tip {
        margin-top: 34px;
        padding-top: 34px;
        border-top: 2px solid #EEEEEE;

        h2 {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 900;
            line-height: 32px;
        }
        p {
            margin: 10px 0;
            font-size: 16px;
            font-weight: 600;
        }
    }
`