import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import '../../../assets/css/font.css';

import { WebRoute } from '../../../navigation/WebRoutes';
import arrow_right from '../../../assets/svg/arrow_right.svg';
import hamburger from "../../../assets/svg/Mhamburger.svg";


const CeoAppSideHeaderPage = ({ oProps }) => {
   const [sAppVersion, set_sAppVersion] = useState('');
   const navigate = useNavigate()
   const location = useLocation()
   const moveCategory = async (sIndex) => {
   }

   const closeNav = () => {
      if (window.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage(
            JSON.stringify({ close: true })
         );
      }
   }

   const logoutApp = () => {
      if (window.ReactNativeWebView) {
         window.ReactNativeWebView.postMessage(
            JSON.stringify({ logout: true })
         );
      }
   }

   const HandleWebViewLogin = async (data) => {
      try {
         await oProps.reduxSetUserConfig(data);
         if (data && data.RouteParams && data.RouteParams.type) {
            if (data.RouteParams.type === 'inventory') {
               navigate(WebRoute.ADMIN_INVENTORY)
            } else if (data.RouteParams.type === 'product') {
               navigate(WebRoute.ADMIN_PRODUCT_CATEGORY)
            } else if (data.RouteParams.type === 'sales') {
               navigate(WebRoute.ADMIN_PAYMENT)
            } else if (data.RouteParams.type === 'advertisement') {
               navigate(WebRoute.ADMIN_COMMERCIAL_ADD)
            } else if (data.RouteParams.type === 'coupon') {
               navigate(WebRoute.ADMIN_COUPON)
            } else if (data.RouteParams.type === 'stamp') {
               navigate(WebRoute.ADMIN_STAMP)
            } else if (data.RouteParams.type === 'behindinventory') {
               navigate(WebRoute.BEHINDINVENTORY)
            }
         }

         if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
               JSON.stringify({ logged_success: true })
            );
         }
      } catch (err) {
         alert(err)
      }
   }

   useEffect(() => {
      document.addEventListener('message', function WebViewListener({ data }) {
         try {
            const loginData = JSON.parse(data);
            HandleWebViewLogin(loginData);
            document.removeEventListener('message', WebViewListener);
            set_sAppVersion(loginData.StoreAppVersion);
         } catch (err) {
            //alert(err)
         }
      })
      window.addEventListener('message', function WebViewListener({ data }) {
         try {
            const loginData = JSON.parse(data);
            HandleWebViewLogin(loginData);
            document.removeEventListener('message', WebViewListener);
            set_sAppVersion(loginData.StoreAppVersion);
         } catch (err) {
            //alert(err)
         }
      })
   }, [])

   const queryString = window.location.search;
   if (queryString.indexOf('type=') !== -1) {
      return (
         <div></div>
      )
   }

   return (
      <AppSidebar>
         <div className="header_wrap">
            <div className="posStoreHeaderSpacer"></div>
            <div className="posStoreHeader" >
               <div className="side_menu left-col">
                  <MobileIcon onClick={() => { navigate(WebRoute.APP_SIDEBAR) }}>
                     <SVG src={hamburger} width="24px" height="24px" title="사이드메뉴버튼" />
                  </MobileIcon>
               </div>
               <div className="close_button right-col">
                  <button onClick={closeNav}>닫기</button>
               </div>
            </div>
            <Link to={WebRoute.ADMIN_REVIEW}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_REVIEW) >= 0 ? "activate_line beta" : "unactivate_line beta"} onClick={() => moveCategory("review")} >
                  <span>리뷰관리</span>
                  <div className="beta_box_container">
                     <div className="beta_box">
                        <p>Beta</p>
                     </div>
                  </div>
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_COMMERCIAL}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_COMMERCIAL) >= 0 ? "activate_line beta" : "unactivate_line beta"} onClick={() => moveCategory("commercial")}>
                  <span>광고관리</span>
                  <div className="beta_box_container">
                     <div className="beta_box">
                        <p>Beta</p>
                     </div>
                  </div>
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_SALES_DASHBOARD}>
               <>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_SALES_DASHBOARD) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("dashboard")}>
                     <span>홈</span>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>

               </>
            </Link>
            <Link to={WebRoute.ADMIN_STORE_INFO}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_STORE_INFO) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("store")}>
                  <span>매장관리</span>
                  {oProps.UserConfigReducer.accessible === "inaccessible" &&
                     <div className="app_guide__container">
                        <div className="title_box">
                           <p>필수!</p>
                        </div>
                     </div>
                  }
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_PRODUCT_CATEGORY}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("product")}>
                  <span>상품관리</span>
                  {oProps.UserConfigReducer.accessible === "inaccessible" &&
                     <div className="app_guide__container">
                        <div className="title_box">
                           <p>필수!</p>
                        </div>
                     </div>
                  }
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_BUSINESS}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_BUSINESS) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("business")}>
                  <span>사장님정보</span>
                  {oProps.UserConfigReducer.accessible === "inaccessible" &&
                     <div className="app_guide__container">
                        <div className="title_box">
                           <p>필수!</p>
                        </div>
                     </div>
                  }
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_PAYMENT}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_PAYMENT) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("payment")}>
                  <span>매출관리</span>
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_INVENTORY}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_INVENTORY) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("inventory")}>
                  <span>재고관리</span>
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_COUPON}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_COUPON) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("coupon")}>
                  <span>쿠폰관리</span>
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <Link to={WebRoute.ADMIN_STAMP}>
               <div className={location.pathname.indexOf(WebRoute.ADMIN_STAMP) >= 0 ? "activate_line" : "unactivate_line"} onClick={() => moveCategory("stamp")}>
                  <span>스탬프관리</span>
                  <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
               </div>
            </Link>
            <div className={"unactivate_line div-flex"}>
               <span className="left-col"></span>
               <span className="right-col"></span>
            </div>
            <div className={"unactivate_line div-flex"}>
               <span className="left-col">앱버전</span>
               <span className="right-col">{sAppVersion}</span>
            </div>
            <div className={"unactivate_line"} onClick={logoutApp}>
               <span>로그아웃</span>
               <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
            </div>
         </div>
      </AppSidebar>
   )
}

export default CeoAppSideHeaderPage;

export const AppSidebar = styled.aside`
   /* 공통 css */
   *, h1, h2, h3, p, button, ul, li, a {
        font-family: 'Pretendard';
    }

    .posStoreHeaderSpacer {
      height: 52px;
    }

    .posStoreHeader {
       display: flex;
       align-items: center;
       background: #f1f1f1;  
       position: fixed;
       top: 0;
       z-index: 20;
       width: 100vw;
    }

   .posStoreHeader .left-col {
      padding-left: 14px;
      flex:1;
   }

   .posStoreHeader .right-col {
      padding-right: 14px;
   }
    
    /* side */
    .header_wrap {
        height: calc(var(--vh, 1vh) * 100); 
        width: 100%;
        overflow-y: scroll;

        @media screen and (max-width: 480px) {
            grid-template-rows: repeat(6, 60px);
        }
    }
    /* 닫기버튼 */
    .close_button {
        padding: 8px 24px 8px 24px; 
        text-align: right;
        background: #f1f1f1;  

        button {
            padding: 6px 16px;
            font-size: 14px;
            font-weight: 600;
            background: #f1f1f1;     
            color: #666;
            border: 1px solid #ccc;
            border-radius: 8px;
            outline: none;
        }
    }

    /* 메뉴 */
    .activate_line.beta, .unactivate_line.beta {
        background: #0A1D42;
        flex: 1;
        justify-content: flex-start;

        span {
            padding:0;
            margin:0;
            color: #fff;
        }
        .beta_box_container {
            padding: 0;
            margin: 0;
        }
        .beta_box p{
            margin: 0 4px;
            font-size: 13px;
            font-weight: 400;
            color: #6490E7;
        }
        SVG {
            margin-left: auto;
            path {
                fill : #fff;
            }
        }
    }
    .activate_line.beta {
        background: #050F21;
        
        span {
            color: #6490E7;
        }
        SVG {
            path {
                fill : #6490E7;
            }
        }
    }
    .review_count {
        margin-left: 4px;
        padding: 2px 9px;
        background: #617be3;
        border-radius: 99px;
        line-height: 24px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
    }

    /* Bata제외한 나머지 탭 */
    .activate_line {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 10px 24px;
        background-color: #F4F6FA;
        border: none;
        outline: none;
        cursor: pointer;

        span {
            margin-right: auto;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            color : #1F57C7;
        }
        svg path {
            fill : #1F57C7;
            z-index:999;
        }   
    }
    .unactivate_line {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;

        &:hover {
            span {
                color : #617be3;
            }
            svg path {
                fill : #617be3;
            }
        }

        span {
            margin-right: auto;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .div-flex {
       display: flex;
    }
    .div-flex span.left-col {
       flex: 1;
       display: flex;
    }
    .div-flex span.right-col {
       margin: 0;
       color: #666;
       padding-right: 4px;
    }

    /* 필수표시 */
    .app_guide__container {
        margin-right: 12px;
        
        .title_box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 6px;
            background: #F5F5F5;
            border: 1px solid #DFDFE3;
            border-radius: 4px;

            p {
                font-size: 12px;
                font-weight: 400 ;
                color: #EF4643;
                line-height: 14px;
                margin: 0;
                padding: 0;
            }
        }
    }
`;

export const MobileIcon = styled.div`
    display: block;
    
`;
