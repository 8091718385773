import styled from 'styled-components';
import '../css/font.css';

export const SignUpStyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }
    .loginPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        background-color: #E5E5E5;
    }

    .chk_signup:hover {
        cursor: pointer;
    }

    
    .emailBoxContainer {
        width: 266px;
        height: 44px;
        margin: 3px 0;
        padding: 12px 14px;
        border: 1px solid #bbb;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        outline: none;

    }

    .emailBoxContainer_input {
        width: 266px;
        height: 44px;
        margin: 3px 0;
        padding: 12px 14px;
        border: 1px solid #000000;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        outline: none;
        transition: 0.1s ease-out;
    }

    .emailBoxContainer_input_wrong {
        width: 266px;
        height: 44px;
        margin: 3px 0;
        padding: 12px 14px;
        border: 1px solid #DD1212;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        outline: none;
        transition: 0.1s ease-out;
    }

    .finInfoBox {
        width: 314px;
        padding: 24px;
        background-color: #fff; 
        border-radius: 4px;
    
        h2 {
            margin: 16px 0 8px 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
        }
        .inputBox {
            position: relative;
        }
        input {
            width: 266px;
            height: 44px;
            margin: 3px 0;
            padding: 12px 14px;
            border: 1px solid #bbb;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            outline: none;
    
        }
    
        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }
    
        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }
    
        input.form {
            border: 1px solid red;
        }
        label {
            width: 266px;
            height: 44px;
            position: relative;
            margin-bottom: 12px;
        }
        label input {
            position: absolute;
            margin: 3px 0;
            border: 1px solid #bbb;
            outline: none;
    
    
        }
        label button, .btn {
            position: relative;
            width: 91px;
            height: 44px;
            border: none;
            outline: none;
            margin: 3px 0;
            right: -175px;
            bottom:  0;
            top: 0;
            background-color: #bbb;
            border-radius: 4px;
            margin: 3px 0;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
        }
        .cancelBtn {
            position: absolute;
            right: 0;
            top: 0;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: none;
            outline: none;
            width: 30px;
            height: 40px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin: 5px 0 16px 0;
        }
        p span {
            color: #617BE3;
            font-weight: 500;
        }
        .join {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
        }
        .memberLogin {
            margin-top: 12px;
            
            p {
                text-align: center;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                color: #666;
            }
        }
    }

    .loginBox {
        width: 314px;
        min-height: 514px;
        max-height: 694px;
        padding: 24px;
        background-color: #fff; 
        border-radius: 4px;

        h2 {
            margin: 16px 0 8px 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
        }
        .inputBox {
            position: relative;
        }
        input {
            width: 266px;
            height: 44px;
            margin: 3px 0;
            padding: 12px 14px;
            border: 1px solid #bbb;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            outline: none;

        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input.form {
            border: 1px solid red;
        }
        label {
            width: 266px;
            height: 44px;
            position: relative;
            margin-bottom: 12px;
        }
        label input {
            position: absolute;
            margin: 3px 0;
            border: 1px solid #bbb;
            outline: none;


        }
        label button, .btn {
            position: relative;
            width: 91px;
            height: 44px;
            border: none;
            outline: none;
            margin: 3px 0;
            right: -175px;
            bottom:  0;
            top: 0;
            background-color: #bbb;
            border-radius: 4px;
            margin: 3px 0;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
        }
        .cancelBtn {
            position: absolute;
            right: 0;
            top: 0;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: none;
            outline: none;
            width: 30px;
            height: 4vh;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin: 5px 0 16px 0;
        }
        p span {
            color: #617BE3;
            font-weight: 500;
        }
        .join {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
        }
        .memberLogin {
            margin-top: 12px;
            
            p {
                text-align: center;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                color: #666;
            }
        }

    }

    .back_out {
        width: 266px;
        height: 48px;
        border-radius: 8px;
        background-color: #bbb;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        text-align: center;
        background: #fff;
        color: #617BE3;
        border: none;
        outline: none;
    }
    .back_out:hover {
        width: 266px;
        height: 48px;
        border-radius: 8px;
        background-color: #bbb;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        background: #617BE3;
        text-align: center;
        color: #fff;
        border: none;
        outline: none;
    }

    .kakao {
        width: 317px;
        height: 67px;
        margin-top: 16px;
        outline: none;
        border: none;
        background-color: #E5E5E5;
        padding: 0;

    }

    @media (max-width: 550px){
        .loginPage {
            background-color: #fff;
            height: auto;
        }
        .loginBox {
            padding: 24px;
        }
        .kakao {
            margin-bottom: 30px;
            background-color: #fff;
        }
    }
`;

export const SignInStyle = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }
    .loginPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        background-color: #E5E5E5;
    }
    .loginBox {
        width: 314px;
        height: 382px;
        padding: 24px;
        background-color: #fff; 
        border-radius: 4px;

        h2 {
            margin: 16px 0 8px 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
        }
        input {
            width: 266px;
            height: 44px;
            margin-bottom: 6px;
            padding: 12px 14px;
            border: 1px solid #bbb;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            outline: none;
        }

        .input_action {
            border: solid 2px #001e62;
            transition: 0.1s ease-out;
        }

        .chk_signup:hover {
            cursor: pointer;
        }

        .checkbox_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .saveId {
            display: flex;
            align-items: center;
            width: 100%;
            margin: 6px 0 16px 0;

            input {
                width: 18px;
                height: 18px;
                margin:0 8px 0 0;
                padding: 0;
            }
            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }
        }

        .loginButton {
            width: 266px;
            height: 48px;
            background-color: #001e62;
            border: none;
            outline: none;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            border-radius: 8px;
        }

        .search {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 24px 0 12px 0;

            p {
                font-size: 12px;
                font-weight: 400;
                padding: 0 12px;
                line-height: 18px;
            }
        }
        
        .memberSignUP  {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 5vh;
            text-align: center;
            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: #666666;
                span {
                    padding-left: 4px;
                    color: #617eb3;
                }
            }
        }
        
        .findMember  {
            display: flex;
            height: 5vh;
            align-items: center;
            justify-content: space-around;

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                color: #666666;
            }
        }
    }
    .kakao {
        width: 317px;
        height: 67px;
        margin-top: 16px;
        outline: none;
        border: none;
        background-color: #E5E5E5;
        padding: 0;

    }

    @media (max-width: 550px){
        .loginPage {
            background-color: #fff;
        }
        .loginBox {
            padding: 24px;
        }
        .kakao {
            margin-bottom: 30px;
            background-color: #fff;
        }
    }
`;