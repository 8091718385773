import React, { useState, useRef, useEffect, Fragment } from 'react';
import ReactDragListView from "react-drag-listview";
import SelectReact from 'react-select';
import { Popconfirm, Table, message, Select } from 'antd';
import { OrderedListOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import styled from 'styled-components'
import OptionStyle from "../../../../assets/styledComponents/OptionStyle"
const { Option } = Select;

const sMaxList = [];
const sMinList = [];
for (let i = 1; i < 21; i++) {
    sMaxList.push({
        key: i,
        value: i,
        name: `${i} 개`,
    });
};
for (let i = 1; i < 21; i++) {
    sMinList.push({
        key: i,
        value: i,
        name: `${i} 개`,
    });
};
const SvgStyle = styled.div`
    .drag-handle {
        path {
            fill: black !important;
            color: black !important;
        }
    }
`

const EditForm = ({ fEditList, iOptionList, iOptionId, fnChangeNormal, oProps }) => {
    const [iStatus, setStatus] = useState("");

    const [sNm, setNm] = useState("");
    const [sMax, setMax] = useState(1 + " 개");
    const [sMin, setMin] = useState(1 + " 개");
    const [sOptionType, setOptionType] = useState("radio");
    const [sOptionNm, setOptionNm] = useState("");
    const [sOptionPrice, setOptionPrice] = useState(0);

    const [classNm, setClassNm] = useState(null);
    const [classOptionNm, setClassOptionNm] = useState(null);
    const [classOptionPrice, setClassOptionPrice] = useState(null);

    const [sOptionId, setOptionId] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [aData, set_aData] = useState([]);
    const [priceSurcharge, set_surcharge] = useState('0');
    const [min_option, set_min_option] = useState(1);
    const [max_option, set_max_option] = useState(1);

    const [iData, setData] = useState();
    const sData = useRef([]);
    const sColumns = useRef([
        {
            title: "순서",
            key: "key",
            render: (text, record, index) =>
                <SvgStyle>
                    <a className="drag-handle"><OrderedListOutlined style={{ fill: '#000 !important' }} /></a>
                </SvgStyle>
        },
        {
            title: "옵션명",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "정가",
            key: "price",
            dataIndex: "price",
        },
        {
            title: "추가 금액",
            key: "priceSurcharge",
            dataIndex: "priceSurcharge",
        },
        {
            title: "삭제",
            key: "action",
            render: (text, record, index) =>
                <Popconfirm
                    title="정말로 이 옵션을 삭제하시겠습니까?"
                    onConfirm={() => deleteList(text.key)}
                    okText="예"
                    cancelText="아니오"
                >
                    <div className="delete_box"><p>삭제</p></div>
                </Popconfirm>
        }
    ]);

    // const setChangeMin = (event) => {
    //     classNm !== "input_wrong" && setClassNm(null);
    //     classOptionNm !== "input_wrong" && setClassOptionNm(null);
    //     classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
    //     if (isMobile) {
    //         setMin(event);
    //     } else
    //         setMin(event.target.value);
    // }

    const onChangeStoreNameValue = (event) => {
        setClassNm("input_action");
        setNm(event.target.value);
    }

    const onChangeClassOptionNm = (event) => {
        setClassOptionNm("input_action");
        setOptionNm(event.target.value);
    }

    const onChangeClassOptionPrice = (event) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
            setClassOptionPrice("input_action");
            setOptionPrice(value);
        }
    }

    const inputClassStoreNm = () => {
        classNm !== "input_wrong" && setClassNm("input_action");
        setClassOptionNm(null);
        setClassOptionPrice(null);
    }
    const inputClassOptionNm = () => {
        classOptionNm !== "input_wrong" && setClassOptionNm("input_action");
        setClassNm(null);
        setClassOptionPrice(null);
    }
    const inputClassOptionPrice = () => {
        classOptionPrice !== "input_wrong" && setClassOptionPrice("input_action");
        setClassOptionNm(null);
        setClassNm(null);
    }
    const setChangeMax = (event) => {
        classNm !== "input_wrong" && setClassNm(null);
        classOptionNm !== "input_wrong" && setClassOptionNm(null);
        classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
        if (isMobile) {
            setMax(event);
        } else {
            setMax(event.target.value);
            if (sOptionType === "radio") {
                setMax(event.target.value);
                setMin(event.target.value);
            } else {
                setMax(event.target.value);
                setMin(0 + '개');
            }
        }
    }

    // const setChangeMax = (event) => {
    //     classNm !== "input_wrong" && setClassNm(null);
    //     classOptionNm !== "input_wrong" && setClassOptionNm(null);
    //     classOptionPrice !== "input_wrong" && setClassOptionPrice(null);

    //     if (isMobile) {
    //         setMax(event);
    //     } else {
    //         setMin(event.target.value);

    //         if (iOptionList.inputType === "radio") {
    //             const clickedInputId = event.target.id;
    //             const correspondingMinInput = document.querySelector(`input[name=${clickedInputId}]`);
    //             setMax(correspondingMinInput.value);
    //         } else {
    //             setMax(event.target.value);
    //         }
    //     }
    // }

    // 추가요금
    const changeSurcharge = (event) => {
        set_surcharge(event.target.value);
    };


    // 순서변경
    const dragProps = (fromIndex, toIndex) => {
        let tempColumns = sData.current;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        sData.current = columnsCopy;
        setData(columnsCopy);
    }
    const changeOrder = (fromIndex, value) => {
        if (fromIndex + value < 0 || fromIndex + value >= sData.current.length) return;
        let tempColumns = sData.current;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(fromIndex + value, 0, item);
        sData.current = columnsCopy;
        setData(columnsCopy);
    }
    const changeStatus = async () => {
        if (fnChangeNormal !== undefined && typeof fnChangeNormal === "function") {
            setStatus("");
            await fnChangeNormal();
        }
    }

    // const insertList = async () => {
    //     let temp = false;
    //     alert('dfsk;flks;dfk')
    //     if (sOptionNm === "") {
    //         setClassOptionNm("input_wrong");
    //         temp = true;
    //     }
    //     if (sOptionPrice === "") {
    //         setClassOptionPrice("input_wrong");
    //         temp = true;
    //     }

    //     if (!temp) {
    //         const xData = {
    //             key: parseInt(sData.current.length) + 1,
    //             name: sOptionNm,
    //             price: parseInt(sOptionPrice)
    //         }
    //         setData([...sData.current, xData]);
    //         sData.current = [...sData.current, xData];
    //         classNm !== "input_wrong" && setClassNm(null);
    //         classOptionNm !== "input_wrong" && setClassOptionNm(null);
    //         classOptionPrice !== "input_wrong" && setClassOptionPrice(null);
    //     }
    // }
    const insertList = async () => {
        let temp = false;

        const xData = {
            key: parseInt(sData.current.length) + 1,
            name: selectedOption.name,
            price: parseInt(selectedOption.base_price),
            priceSurcharge: parseInt(priceSurcharge),
            option_id: selectedOption.product_id,
        }

        if (selectedOption.name === undefined) {
            temp = true;
            message.error("상품을 선택해주세요.");
        }
        for (let i = 0; i < sData.current.length; i++) {
            const listId = sData.current[i].option_id;
            if (xData.option_id === listId) {
                message.error("이미 선택된 상품입니다.");
                temp = true;
            }
        }

        if (!temp) {
            setData([...sData.current, xData]);
            sData.current = [...sData.current, xData];
            setSelectedOption('');
            set_surcharge('0')

        }
    }

    const deleteList = (key) => {
        sData.current = sData.current.filter((item) => item.key !== key);
        setData(sData.current.filter((item) => item.key !== key));
    }

    // const editOption = async () => {
    //     if (sNm === "") {
    //         setClassNm("input_wrong");
    //     } else if (iData.length < 1) {
    //         message.error("옵션을 지정해주세요.");
    //     } else {
    //         let oData = {
    //             sNm,
    //             sMin: parseInt(sMin.substr(0, 2)),
    //             sMax: parseInt(sMax.substr(0, 2)),
    //             sData: sData.current,
    //             sOptionType,
    //             sOptionId
    //         };

    //         if (sOptionType !== "checkbox") {
    //             if (sData.current.length < parseInt(sMin.substr(0, 2))) {
    //                 message.error("선택가능한 옵션이 최소선택횟수보다 작습니다.");
    //                 return false;
    //             }
    //         }

    //         if (fEditList !== undefined && typeof fEditList === "function") {
    //             await fEditList(oData);
    //         }
    //     }
    // }

    // submit버튼
    const submitOption = async () => {
        const group_name = sNm;
        for (let i = 0; i < iData.length; i++) {
            iData[i].product_id = iData[i].option_id;
        }

        try {
            const oParams = {
                group_name: group_name,
                input_min: parseInt(sMin.substr(0, 2)),
                input_max: parseInt(sMax.substr(0, 2)),
                store_id: oProps.UserConfigReducer.StoreID,
                product_list: iData,
                option_type_id: iOptionId,
            }

            if (sNm === "") {
                message.error("옵션 그룹명을 입력해주세요");
            } else if (iData.length < 1) {
                message.error("세트메뉴를 구성할 상품을 추가해주세요.");
            } else if (oParams.input_max > oParams.product_list.length) {
                message.error("선택하신 최대 선택수보다 상품수가 적습니다.");
            } else {

                const oResponse = await oProps.oManager.fDoAxios("/store/register/editProductOption/v2", "post", null, oParams);
                console.log('보내기성공', oResponse)

                if (oResponse != undefined && oResponse != null) {
                    if (oResponse.success) {
                        message.success("등록이 완료되었습니다.");
                    }
                    else {
                        console.log("등록실패 사유:", oResponse.message);
                        message.error("등록이 실패되었습니다.");
                    }
                }
            }

        } catch (err) {
            console.log("err-->", err);
        }
    }

    // 상품검색,추가
    function select_product_Change(selectedOption) {
        setSelectedOption(selectedOption);
    }

    // 상품 불러오기
    const getProduct = async () => {
        const prouctData = [];
        try {
            const oParams = {
                store_id: oProps.UserConfigReducer.StoreID,
            }
            const oResponse = await oProps.oManager.fDoAxios("/store/register/menuList/menuSetProductOption-" + oParams.store_id, "get", null, oParams);

            if (oResponse != undefined && oResponse != false) {
                for (let i = 0; i < oResponse.product_option_list.length; i++) {
                    const name = oResponse.product_option_list[i].name;
                    const base_price = oResponse.product_option_list[i].base_price;
                    const product_id = oResponse.product_option_list[i].product_id;
                    const priceSurcharge = oResponse.product_option_list[i].priceSurcharge;
                    const value = oResponse.product_option_list[i].name;
                    const label = oResponse.product_option_list[i].name;

                    const oParams = {
                        name: name,
                        base_price: base_price,
                        product_id: product_id,
                        value: value,
                        label: label,
                        priceSurcharge: priceSurcharge
                    };

                    prouctData.push(oParams)
                }
            }
            set_aData(prouctData)
        } catch (err) {
            console.log("err-->", err);
        }
    }
    useEffect(() => {
        if (iStatus === "" || parseInt(sOptionId) !== parseInt(iOptionId)) {
            if (parseInt(iOptionList.minCount) > 0) {
                setOptionType("radio");
                setMax(parseInt(iOptionList.maxCount) + " 개");
                setMin(parseInt(iOptionList.maxCount) + " 개");

            } else {
                if (iOptionList.inputType === "radio") {
                    // 세트상품옵션
                    // alert(2)
                    setOptionType("radio");
                    setMax(parseInt(iOptionList.maxCount) + " 개");
                    setMin(parseInt(iOptionList.maxCount) + " 개");
                } else {
                    // 일반옵션안에상품
                    setOptionType("checkbox");
                    setMax(parseInt(iOptionList.maxCount) + " 개");
                    setMin(parseInt(iOptionList.minCount) + " 개");
                }
            }
            setOptionId(iOptionId);
            setData(iOptionList.list);
            setNm(iOptionList.sGroupTitle);
            sData.current = iOptionList.list;
        }
    }, [iOptionList, iOptionId]);
    useEffect(() => {
        getProduct();
    }, []);

    return (
        <Fragment>
            <BrowserView>
                <OptionStyle>
                    {/* 세트 메뉴 구성 옵션 수정 */}
                    <div className="container">
                        <div className="title_box">
                            <h2>옵션 관리 수정</h2>
                            <div className="cancel_box" onClick={() => changeStatus()}>
                                <p>수정취소</p>
                            </div>
                        </div>
                        <div className="category_title">
                            <p>옵션 그룹명</p>
                            <input
                                type="text"
                                placeholder="예) 사이드메뉴 추가"
                                className={classNm}
                                onClick={inputClassStoreNm}
                                value={sNm}
                                onChange={onChangeStoreNameValue}
                            />
                        </div>
                        <div className="essential_box" style={{ marginTop: '15px' }}>
                            <p>필수여부</p>
                            <div className="select_line_box" >
                                {iOptionList.input_min === 0
                                    ?
                                    <div className="select_line_box" style={{ width: ' 100%', marginTop: '0' }}>
                                        <div className={sOptionType === "radio" ? "selected_wrapper" : "disable_wrapper"}>
                                            <p>선택해야 주문가능</p>
                                        </div>
                                        <div className={sOptionType === "checkbox" ? "selected_wrapper" : "disable_wrapper"}>
                                            <p>선택안해도 주문가능</p>
                                        </div>
                                    </div>
                                    :
                                    <div className={sOptionType === "radio" ? "selected_wrapper" : "disable_wrapper"}>
                                        <p>세트메뉴 옵션</p>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* 수정수정수정 */}
                        {iOptionList.input_min === 0
                            ?
                            <div className="category_title">
                                <p>최대선택수</p>
                                <select value={sMax} onChange={setChangeMax}  >
                                    {sMaxList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })};
                                </select>
                            </div>
                            :
                            <div className="category_title">
                                <p>필수선택수</p>
                                <select value={sMax} onChange={setChangeMax}  >
                                    {sMaxList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })};
                                </select>
                            </div>
                        }
                        {/* 수정수정수정 */}

                        <div className="category_title" style={{ marginTop: '15px' }}>
                            <p>상품추가</p>
                            <div className="option_subTitle">
                                <div className="selectSearch">
                                    <SelectReact
                                        options={aData}
                                        value={selectedOption}
                                        onChange={select_product_Change}
                                        placeholder="상품을 검색,선택해 주세요"
                                    />
                                </div>
                                <div className='default_price'>
                                    <p>정가 :</p>
                                    {
                                        selectedOption === ""
                                            ?
                                            <p>0 원</p>
                                            :
                                            <p>{Math.floor(selectedOption.base_price)} 원</p>
                                    }
                                </div>
                                <div className='surcharge'>
                                    <input type="number" placeholder='추가 금액(숫자로 입력)' value={priceSurcharge === '0' ? "" : priceSurcharge} onChange={changeSurcharge} />
                                </div>
                                <div className="insert_box" onClick={insertList}>
                                    <h3>상품 추가</h3>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: "5%" }}>
                            <ReactDragListView.DragColumn
                                onDragEnd={dragProps}
                                handleSelector="a"
                            >
                                <Table columns={sColumns.current} dataSource={iData} pagination={false} />
                            </ReactDragListView.DragColumn>
                        </div>
                        <div className="location_box_line">
                            <div className="how_box">
                                <p>순서를 변경하고 싶으시다면 순서의 아이콘을 잡고 드래그를 해주세요!</p>
                            </div>
                        </div>
                        <div className="register_box" onClick={submitOption}>
                            <p>수정완료</p>
                        </div>
                    </div>
                </OptionStyle>
            </BrowserView>
            <MobileView>
                <OptionStyle>
                    {/* 세트 메뉴 구성 옵션 수정 */}
                    <div className="mobile_container">
                        <div className="title_box">
                            <h2>옵션 관리 수정</h2>
                            <div className="cancel_box" onClick={() => changeStatus()}>
                                <p>수정취소</p>
                            </div>
                        </div>
                        <div className="category_title_mobile">
                            <p>옵션 그룹명</p>
                            <input
                                type="text"
                                placeholder="예) 사이드메뉴 추가"
                                className={classNm}
                                onClick={inputClassStoreNm}
                                value={sNm}
                                onChange={onChangeStoreNameValue}
                            />
                        </div>
                        <div className="essential_box" style={{ marginTop: '15px' }}>
                            <p>필수여부</p>
                            <div className="select_line_box" >
                                {iOptionList.input_min === 0
                                    ?
                                    <div className="select_line_box" style={{ width: ' 100%', marginTop: '0' }}>
                                        <div className={sOptionType === "radio" ? "selected_wrapper" : "disable_wrapper"}>
                                            <p>선택해야 주문가능</p>
                                        </div>
                                        <div className={sOptionType === "checkbox" ? "selected_wrapper" : "disable_wrapper"}>
                                            <p>선택안해도 주문가능</p>
                                        </div>
                                    </div>
                                    :
                                    <div className={sOptionType === "radio" ? "selected_wrapper" : "disable_wrapper"}>
                                        <p>세트메뉴 옵션</p>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* 수정수정수정 */}
                        {iOptionList.input_min === 0
                            ?
                            <div className="category_title_mobile">
                                <p>최대선택수</p>
                                <select value={sMax} onChange={setChangeMax} style={{ width: '100%', padding: '10px 4px' }} >
                                    {sMaxList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })};
                                </select>
                            </div>
                            :
                            <div className="category_title_mobile">
                                <p>필수선택수</p>
                                <select value={sMax} onChange={setChangeMax} style={{ width: '100%', padding: '10px 4px' }} >
                                    {sMaxList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id}>{item.name}</option>
                                        )
                                    })};
                                </select>
                            </div>
                        }
                        {/* 수정수정수정 */}

                        <div className="category_title_mobile" style={{ marginTop: '15px' }}>
                            <p>상품추가</p>
                            <div className="selectSearch">
                                <SelectReact
                                    options={aData}
                                    value={selectedOption}
                                    onChange={select_product_Change}
                                    placeholder="상품을 검색,선택해 주세요"
                                />
                            </div>

                            <div className="product_option_add">
                                <div className='default_price'>
                                    <p>정가 :</p>
                                    {
                                        selectedOption === ""
                                            ?
                                            <p>0 원</p>
                                            :
                                            <p>{Math.floor(selectedOption.base_price)} 원</p>
                                    }
                                </div>
                                <div className='surcharge'>
                                    <input type="number" placeholder='추가 금액(숫자로 입력)' value={priceSurcharge === '0' ? "" : priceSurcharge} onChange={changeSurcharge} />
                                </div>
                                <div className="insert_box" onClick={insertList}>
                                    <h3>상품 추가</h3>
                                </div>
                            </div>
                        </div>

                        {iData !== undefined && iData !== null && iData.length > 0 &&
                            <div className="option_list moblie">
                                <p>옵션 리스트</p>
                                <div className="option_list_box_header">
                                    <div className="option_name_list">옵션명</div>
                                    <div className="option_price_list" style={{ marginLeft: '2%' }}>추가금액</div>
                                    <div className='option_order_title'>순서 변경</div>
                                    <div className="option_delete_box">삭제</div>
                                </div>
                                {iData.map((item, index) => {
                                    return (
                                        <div key={index} className="option_list_index">
                                            <div className="option_name_list">
                                                <p>{item.name}</p>
                                            </div>
                                            <div className="option_price_list" style={{ marginLeft: '2%' }}>
                                                <p>{item.priceSurcharge}원</p>
                                            </div>
                                            <div onClick={() => changeOrder(index, -1)} className='option_order'>
                                                <ArrowUpOutlined />
                                            </div>
                                            <div onClick={() => changeOrder(index, 1)} className='option_order'>
                                                <ArrowDownOutlined />
                                            </div>
                                            <div className="option_delete_box button" onClick={() => deleteList(item.key)}>
                                                <p>삭제</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        <div className="location_box_line" style={{ marginTop: '2px' }}>
                            <div className="how_box" style={{ padding: '10px 2px' }}>
                                <p style={{ fontSize: '12px' }}>순서를 변경하고 싶으시다면 순서 변경 화살표를 클릭해주세요!</p>
                            </div>
                        </div>
                        <div className="bottom_fixed">
                            <button className="finishButton" onClick={submitOption}>수정완료</button>
                        </div>
                    </div>
                </OptionStyle>
            </MobileView>
        </Fragment>
    )
}

export default EditForm;
