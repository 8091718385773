import React from 'react';
import styled from 'styled-components';

// 이미지
import stamp01 from '../../../../assets/img/ceoscreen/stamp01.png';
import stamp02 from '../../../../assets/img/ceoscreen/stamp02.png';
import stamp03 from '../../../../assets/img/ceoscreen/stamp03.png';
import stamp04 from '../../../../assets/img/ceoscreen/stamp04.png';
import stamp05 from '../../../../assets/img/ceoscreen/stamp05.png';
import stamp06 from '../../../../assets/img/ceoscreen/stamp06.png';
import stamp07 from '../../../../assets/img/ceoscreen/stamp07.png';
import stamp08 from '../../../../assets/img/ceoscreen/stamp08.png';
import stamp09 from '../../../../assets/img/ceoscreen/stamp09.png';

const StampGuide = (oProps) => {

    return (
        <D_stamp> 
            <div className="ceoTip">
                <div className="container">
                    <div className="cTip">
                        <h3>스루 가이드</h3>
                        <h1>스탬프 기능 활용하기</h1>
                        <p>
                            스탬프 기능은 매장을 여러 번 방문해 주시는 고객을 위해 재주문 혜택을 제공하는 기능입니다.<br />스탬프 기능을 이용하여 사장님 매장의 단골 고객을 사로잡아보세요.
                        </p>
                        <div className="subTitle">
                            <p>
                                스탬프 등록은<br />
                                <em>스루 사장님 창구 <span>&#8594;</span> 매장관리 <span>&#8594;</span> 로그인 <span>&#8594;</span> 스탬프 관리 <span>&#8594;</span> 스탬프등록</em> 에서 가능합니다.
                            </p>
                        </div>
                        <div className="tip">
                            <h2><span>STEP 01</span><br />스루 사장님 창구에서 매장관리 클릭 후 로그인을 해주세요.</h2>
                            <img src={stamp01} alt="스탬프관리 페이지 이동경로 안내" width="100%" />
                        </div>
                        <div className="tip">
                            <h2><span>STEP 2</span><br />스탬프 관리 <span style={{ color: "#212329" }}>&#8594;</span> 스탬프 등록 탭을 클릭해 주세요.</h2>
                            <img src={stamp02} alt="스탬프관리 페이지 이동경로 안내" width="100%" />
                        </div>
                        <div className="div" style={{ border: "2px solid #EEEEEE", margin: "36px 0 22px 0"}}></div>
                        <div className="notice">
                            <h2>😊 TIP<br />스탬프 등록 과정을 알려드릴게요.</h2>
                            <p>스탬프는 <span>부여 기준, 목표 개수 달성 시 </span>고객에게 할인쿠폰 형태로 지급됩니다.</p>
                        </div>
                        <div className="guide">
                            <h2>고객에게 보여지는 스탬프 이름을 입력해 주세요.</h2>
                            <img src={stamp03} alt="스탬프 이름 입력 이미지" width="100%" />
                            <h2>스탬프가 발행되는 기준금액을 입력해 주세요.<br />(할인금액을 제외한 결제금액을 기준으로 스탬프가 발행됩니다.)</h2>
                            <img src={stamp04} alt="스탬프 금액 입력 이미지" width="100%" />
                            <h2>스탬프 유효기간을 설정해 주세요.</h2>
                            <img src={stamp05} alt="스탬프 유효기간 입력 이미지" width="100%" />
                            <h2>고객이 쿠폰을 받기 위해 몇 개의 스탬프를 모아야 하는지 설정해 주세요.</h2>
                            <img src={stamp06} alt="스탬프 조건 입력 이미지" width="100%" />
                            <h2>스탬프 목표 달성 시 주어지는 할인쿠폰 금액을 입력해 주세요.</h2>
                            <img src={stamp07} alt="스탬프 달성시 쿠폰 금액 입력 이미지" width="100%" />
                        </div>
                        <div className="notice">
                            <h2 style={{ marginTop:"32px" }}>📢  스탬프는 한번 발행되면 <sapn style={{ color: "#F45452" }}>수정 및 추가가 어려워요.</sapn></h2>
                            <p>입력한 조건이 다 맞게 설정되어 있는지 꼭 확인해 주세요!</p>
                            <img src={stamp08} alt="스탬프등록 유의사항 이미지" width="100%" />
                        </div>
                        <div className="app">
                            <h2>🙂 앱에선 이렇게 표시돼요.</h2>
                            <img src={stamp09} alt="스탬프 앱등록 이미지" width="100%" />
                            <h3>👍 구매내역이 쌓일수록 혜택이 주어지는 스탬프 기능을 이용하여 단골 고객을 사로잡아 매출을 올려보세요!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </D_stamp>
    )
}

export default StampGuide;
export const D_stamp = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .cTip {
        margin-top: 106px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 16px 0;
            font-size: 32px;
            font-weight: 900;
            line-height: 48px;
        }
        p {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #1A7CFF;
        }
    }
    .tip {
        h2 {
            margin: 34px 0 10px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;

            span {
                color: #8E929F;
            }
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .notice {
        h2 {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 900;
            color: #212329;
            line-height: 32px;
        }
        p {
            margin-bottom: 60px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
            color: #212329;

            span {
                font-weight: 900;
                color: #1A7CFF;
            }
        }
        
    }
    .guide {
        h2 {
            margin: 36px 0 8px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;
            color: #212329;
        }
    }
    .app {
        margin-top: 62px;
        h2 {
            font-size: 22px;
            line-height: 36px;
            font-weight: 900;
            color: #212329;
        }
        img {
            margin: 10px 0 20px 0;
        }
        h3 {
            font-size: : 16px;
            font-weight: 900;
            line-height: 34px;
            color: #212329;
        }
    }
`