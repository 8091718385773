import React, { useState, useEffect, useRef, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import Loader from "react-loader-spinner";
import { Table } from 'antd';
import "antd/dist/antd.css";

import styled from 'styled-components';
import manual from '../../../../assets/svg/manual.svg';
import DataForm from './DataForm';
import { BrowserView, MobileView } from 'react-device-detect';

const InsertForm = ({ fnCancel, fnRegister, iData, bAlert }) => {
    const [isLoading, setIsLoading] = useState(true);

    const [sData, setSData] = useState([]);
    const [sColumns] = useState([
        {
            title: "요일",
            key: "day",
            dataIndex: "day",
        },
        {
            title: "영업시간",
            key: "operation",
            dataIndex: "operation",
        },
        {
            title: "상품준비시간",
            key: "time",
            dataIndex: "time",
        },
        {
            key: "action",
            render: (text, record, index) => <p className="delete_box" onClick={() => deleteList(text.key)}>삭제</p>
        }
    ])

    const aData = useRef([]);

    const onChangeCancel = async () => {
        if (fnCancel !== undefined && typeof fnCancel === "function") {
            await fnCancel();
        }
    }

    const onChangeRegister = async () => {
        if (fnRegister !== undefined && typeof fnRegister === "function") {
            await fnRegister(aData.current);
        }
    }

    const deleteList = async (key) => {
        setIsLoading(true);
        aData.current = aData.current.filter((item) => item.key.toString() !== key.toString());
        setSData(aData.current);
        setIsLoading(false);
    }

    const insert = async (key) => {
        setIsLoading(true);
        aData.current = key
        setSData(aData.current);
        setIsLoading(false);
    }

    useEffect(() => {
        aData.current = iData;
        setSData(aData.current);
        setIsLoading(false);
    }, []);

    return (
        <Fragment>
            <BrowserView>
                <Style>
                    <div className="container">
                        <div className="title_box">
                            <h2>매장 영업일</h2>
                            <div className="tip">
                                <SVG src={manual} width="18px" height="18px" />
                                <span>매장의<strong>영업일</strong>을 등록하거나<br />수정이 가능합니다</span>
                            </div>
                        </div>
                        <DataForm
                            fnInsert={(sIndex) => insert(sIndex)}
                            listData={sData}
                            iAlert={bAlert}
                        />
                        {isLoading ?
                            <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                            </div>
                            :
                            <div style={{ marginTop: "4vh" }}>
                                <Table columns={sColumns} dataSource={aData.current} pagination={false} />
                            </div>
                        }
                        {aData.current.length > 0 &&
                            <div className="insert_box" onClick={onChangeRegister}>
                                <p>시간 설정 완료</p>
                            </div>
                        }
                        <div className="cancel_box" onClick={onChangeCancel}>
                            <p>취소</p>
                        </div>
                    </div>
                </Style>
            </BrowserView>
            <MobileView>
                <div className="category_title_line">
                    <div className="title_box_container">
                        <div className="title_box">
                            <p className="title_line">매장 영업일</p>
                        </div>
                        <div className="tip">
                            <SVG src={manual} width="18px" height="18px" />
                            <span>매장의<strong>영업일</strong>을 등록하거나<br />수정이 가능합니다</span>
                        </div>
                    </div>
                    <DataForm
                        fnInsert={(sIndex) => insert(sIndex)}
                        listData={sData}
                        iAlert={bAlert}
                    />
                    {isLoading ?
                        <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                            <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                        </div>
                        :
                        <div style={{ marginTop: "4vh" }}>
                            <Table columns={sColumns} dataSource={aData.current} pagination={false} />
                        </div>
                    }
                    <div className="insert_box" onClick={onChangeRegister}>
                        <p>시간 설정 완료</p>
                    </div>
                    <div className="cancel_box" onClick={onChangeCancel}>
                        <p>취소</p>
                    </div>
                </div>

            </MobileView>
        </Fragment>
    )
}

export default InsertForm;

const Style = styled.div`
position: relative;
width: 42vw;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans KR', sans-serif;
} 

.app_guide_modal_container {
    position: absolute;
    left: 103%;
    top: 22px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .text_box {
        margin-left: 10px;
        width: 158px;
        height: 74px;

        .title_box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 32px;

            p {
                margin-left: 10px;
                font-size: 16px;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .detail_box_line {
            height: 42px;
            p {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
            }
        }
    }
}

.location_box_line {
    align-items: center;
    margin-top: 10px;
    height: 60px;
    .how_box {
        display: flex;
        align-items: center;
        height: 50px;
        background: #f1f3f7;
        border-radius: 4px;

        p {
            margin-left: 3%;
            font-size: 0.7rem;
            font-weight: 400;
            color: #666666;
        }
    }
}

.essential_form {
    font-size: 0.8rem;
    font-weight: 400;
    color: #617BE3;
}
span {
    font-size: 0.8rem;
    font-weight: 400;
    color: #BBBBBB;
}

.delete_box {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 400;
    color: #DD1212;
    text-decoration: underline;
    cursor: pointer;
}

.edit_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    height: 5vh;
    border: 1px solid #617BE3;
    border-radius: 5px;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 700;
        color: #617BE3;
    }
}

.edit_box:hover {
    border: none;
    background: #617BE3;
    p {
        color: #fff;
    }
}
.cancel_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    height: 60px;
    border: 1px solid #DD1212;
    border-radius: 5px;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 700;
        color: #DD1212;
    }
}

.cancel_box:hover {
    border: none;
    background: #DD1212;
    p {
        color: #fff;
    }
}

.insert_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
    height: 60px;
    border: 1px solid #617BE3;
    border-radius: 5px;
    cursor: pointer;

    p {
        font-size: 0.9rem;
        font-weight: 700;
        color: #617BE3;
    }
}

.insert_box:hover {
    border: none;
    background: #617BE3;
    p {
        color: #fff;
    }
}

.title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5vh;

    h2 {
        font-size: 1rem;
        font-weight: 700;
    }

    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        width: 9vw;
        min-height: 52px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }
}

.container {
    padding: 32px;
    margin-bottom: 20vh;
    background-color: #fff;
    box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
    border-radius: 4px;
    width: 42vw; 

}

.category_title {

    .time_separate_line {
        margin-top: 5%;
        display: flex;
        align-items: center;

        .light_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1vh;
            border-radius: 4px;
            width: 10%;
        }

        p {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;
            font-size: 0.8rem;
            font-weight: 400;
        }
    }

    .weekly_date_bottom_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .operation_color_box {
            border-bottom: 2px solid #dfdfdf;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.5vh;
            width: 13%;
            background: #fff;
        }
    }
    .weekly_date_top_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        .operation_color_box {
            border-top: 2px solid #dfdfdf;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.5vh;
            width: 13%;
            background: #fff;
        }
        
    }
    .weekly_date_box {
        display: flex;
        align-items: center;
        justify-content: space-between;


        .operation_color_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.5vh;
            width: 13%;
            background: #fff;
        }

        .date_name_box {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 4vh;
            width: 13%;
            p {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
    }

    .option_select_box {
        margin-top: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 6vh;
        border : 1px solid #617BE3;
        border-radius: 4px;
        p {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617BE3;
        }
    }

    .option_select_box:hover {
        background : #617BE3;

        p {
            color: #fff;
        }
    }

    .select_box {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        select {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .start_hour_box_null {
            width: 49%;
        }
        .start_minute_box_null {
            width: 49%;
        }
        .start_hour_box {
            width: 28%;
        }
        .start_minute_box {
            width: 19%;
        }
        .time_arrange_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 4%;
        }
        
        .selected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            background: #617BE3;
            border-radius: 4px;
            cursor: pointer;
            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
        .unselected_box_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49%;
            height: 5vh;
            border-radius: 4px;
            border: 1px solid #617BE3;
            cursor: pointer;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
    }


`