import React from 'react';
import styled from 'styled-components';
import '../../../../assets/fonts/font.css';
import { Link } from 'react-router-dom';

// 이미지
import payback01 from '../../../../assets/img/ceoscreen/m_payback01.png';
import payback02 from '../../../../assets/img/ceoscreen/m_payback02.png';
import payback03 from '../../../../assets/img/ceoscreen/m_payback03.png';
import payback04 from '../../../../assets/img/ceoscreen/m_payback04.png';
import payback05 from '../../../../assets/img/ceoscreen/m_payback05.png';
import payback06 from '../../../../assets/img/ceoscreen/m_payback06.png';
import payback07 from '../../../../assets/img/ceoscreen/m_payback07.png';
import payback08 from '../../../../assets/img/ceoscreen/m_payback08.png';
import paybackButton01 from '../../../../assets/img/ceoscreen/m_paybackButton01.png';
import paybackButton02 from '../../../../assets/img/ceoscreen/m_paybackButton02.png';
import paybackButton03 from '../../../../assets/img/ceoscreen/m_paybackButton03.png';

// https://ceo.throo.co.kr/ceotip?appBanner

const StampGuide = (oProps) => {

    const goProductPath = async () => {
        let sDomainUrl = "https://ceo.throo.co.kr";
        if (process.env !== undefined && process.env.REACT_APP_CEO_WEBURL !== undefined) {
            sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
        }
        window.open(sDomainUrl + '/selfmanage/product', '_blank');
    }

    return (
        <M_payback>
            <div className="paybackPage">
                <div className="banner">
                    <img src={payback01} alt="페이백광고포인트배너" width="100%" />
                    {/* 상품관리->상품관리 제일 맨위 카테고리 리스트화면 */}
                    <button onClick={goProductPath}><img src={paybackButton01} alt="우리 매장 무료 광고 버튼" width="80%" /></button>
                </div>
                <div className="content" style={{ background: '#', textAlign: 'center' }}>
                    <img src={payback02} alt="스루 광고시스템 오픈 설명" width="100%" />
                </div>
                <div className="content" style={{ background: '#EEEEEE', margin: "0 atuo", textAlign: 'center', paddingBottom: '56px' }}>
                    <h2>할인금액 돌려받고<br />광고 무료로<br />진행하는 방법</h2>
                    <img src={payback03} alt="포인트 환급받고 광고 무료로 진행하는 방법" width="80%" />
                    <img src={payback04} alt="포인트 환급받고 광고 무료로 진행하는 방법" width="80%" />
                    <img src={payback05} alt="포인트 환급받고 광고 무료로 진행하는 방법" width="80%" />
                    <img src={payback06} alt="포인트 환급받고 광고 무료로 진행하는 방법" width="80%" />
                </div>
                <div className="type">
                    <img src={payback07} alt="스루 광고 종류" width="100%" />
                    <Link to={{ pathname: '/ceotip', page_id: 'mob_appBanner', search: 'mob_appBanner' }}><button><img src={paybackButton02} alt="스루 광고 종류 자세히 보기 버튼" width="80%" /></button></Link>
                </div>
                <div className="paybackEvent">
                    <img src={payback08} alt="광고 무상포인트 받으러가는 페이지" width="100%" />
                    {/* 상품관리->상품관리 제일 맨위 카테고리 리스트화면 */}
                    <button onClick={goProductPath}><img src={paybackButton03} alt="우리 매장 무료 광고 버튼" width="50%" /></button>
                </div>
            </div>
        </M_payback>
    )
}

export default StampGuide;
export const M_payback = styled.aside`

    html,
    body {
        margin: 0;
        padding: 0;
        
    }
    .paybackPage {
        width: 100%;
        margin:0 auto;
    }
    h2 {
        padding: 34px 0 22px 0;
        font-family: 'Pretendard';
        font-size: 36px;
        font-weight: 700;
        line-height: 45px;
        color:#000;
    }
    button {
        background: none;
        border: none;
        outline: none;
        margin: 0 auto;
    }

    /* banner */
    .banner {
        position: relative;
        margin-top: 60px;

        button {
            position: absolute;
            bottom: 26%;
            left: 0;
            right:0;
        }
    }
    /* content */
    .content img {
        margin-top: 20px;
    }
    /* type */
    .type {
        position: relative;

        button {
            position: absolute;
            bottom: 3%;
            left: 0;
            right:0;
        }
    }
    /* paybackEvent */
    .paybackEvent {
        position: relative;

        button {
            position: absolute;
            bottom: 7%;
            left: 0;
            right:0;
        }
    }
`