import React, { useState, useEffect } from 'react';
import { pulse } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

import businessIcon from '../../../../assets/svg/businessIcon.svg';

import manual from '../../../../assets/svg/guidenoticebox.svg';
import styled from 'styled-components';
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { WebRoute } from '../../../../navigation/WebRoutes';

const styles = {
    pulse: {
        animation: 'infinite 1s',
        animationName: Radium.keyframes(pulse, 'pulse')
    }
}

const Business = ({ sData, iState, fnMove }) => {
    const [dataList, setDataList] = useState({});
    const [isState, setState] = useState(false);

    const moveCategory = async (sIndex, aIndex) => {
        if (fnMove !== undefined && typeof fnMove === "function") {
            await fnMove(sIndex, aIndex);
        }
    }

    useEffect(() => {
        setDataList(sData);
        setState(iState);
    }, [iState, sData]);

    return (
        <StyleRoot>
            <BrowserView>
                {isState ?
                    <div className="buisness">
                        <div className="title">
                            <h2>사업자 정보</h2>
                            <p>{dataList.storeName}</p>
                        </div>
                        <div className="subTitle">
                            <h2>지난주 정산금액</h2>
                            <p>{dataList.preAmount}원</p>
                        </div>
                        <div className="salesBox">
                            <div className="left">
                                <h2>정산일</h2>
                                <h3>매주 수요일</h3>
                            </div>
                            <div className="right">
                                <h2>정산 예상 금액</h2>
                                <h3>{dataList.lastMonthPay}원</h3>
                            </div>
                        </div>
                        <Link to={WebRoute.ADMIN_PAYMENT}>
                            <button>매출관리 바로가기</button>
                        </Link>
                    </div>
                    :
                    <div className="sContent" style={styles.pulse}>
                        <div className="guide_notice_box">
                            <img src={manual} alt="none" />
                        </div>
                        <div style={{ height: "7vh" }}>
                            <h2>사업자 인증을 해주세요.</h2>
                            <h3 style={{ marginBottom: "5%" }}>기본적인 서류 및 정보 입력으로 상품을 노출하세요.</h3>
                        </div>
                        <div className="img_box">
                            <img src={businessIcon} alt="none" />
                        </div>
                        <Link to={WebRoute.ADMIN_BUSINESS}>
                            <div style={{ height: "5vh" }}>
                                <button >사업자인증</button>
                            </div>
                        </Link>
                    </div>
                }
            </BrowserView>
            <MobileView>
                {isState ?
                    <Style>
                        <div className="buisness">
                            <div className="title_wrapper">
                                <h2>사업자 정보</h2>
                            </div>
                            <div className="subTitle">
                                <h2>지난주 정산금액</h2>
                                <p>{dataList.preAmount}원</p>
                            </div>
                            <div className="salesBox">
                                <div className="left">
                                    <h2>정산일</h2>
                                    <h3>매주 수요일</h3>
                                </div>
                                <div className="right">
                                    <h2>정산 예상 금액</h2>
                                    <h3>{dataList.lastMonthPay}원</h3>
                                </div>
                            </div>
                            <Link to={WebRoute.ADMIN_PAYMENT}>
                                <button >매출관리 바로가기</button>
                            </Link>
                        </div>
                    </Style>
                    :
                    <div className="content4" style={styles.pulse}>
                        <div className="guide_notice_box">
                            <img src={manual} alt="none" />
                        </div>
                        <h2>사업자 인증을 해주세요.</h2>
                        <h3>기본적인 서류 및 정보 입력으로 상품을 노출하세요.</h3>
                        <div className="img_box">
                            <img src={businessIcon} alt="none" />
                        </div>
                        <Link to={WebRoute.ADMIN_BUSINESS}>
                            <button className="button" >사업자인증</button>
                        </Link>
                    </div>
                }
            </MobileView>
        </StyleRoot>
    )
}

export default Business;


const Style = styled.div`
    .buisness {
        margin: 16px;
        padding: 20px 24px 20px 24px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
    }
   
    .title_wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 10px 0 5px 0;

        h2 {
            font-size: 0.9rem;
            font-weight: 700;
            line-height: 21px;
            color: #617BE3;
        }
        
    }

    .subTitle { 
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 15px 0 19px 0;

        h2 {
            font-size: 0.9rem;
            font-weight: 700;
            line-height: 24px;
        }
    }
    .salesBox {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 5%;
        border-radius: 4px;

        h2 {
            font-size: 0.9rem;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
        }
        h3 {
            font-size: 1rem;
            font-weight: 700;
            line-height: 29px;
            text-align: center;
        }

        .left {
            border-right: 1px solid #c4c4c4;
            flex: 50%;
        }
        .right {
            flex: 50%;
        }
    }
    button {
        margin-top: 2%;
        width: 100%;
        height: 52px;
        background-color: #001e62;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        border: none;
        outline: none;
        border-radius: 8px;
    }
`;