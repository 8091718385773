import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { message, Modal } from "antd";
import "antd/dist/antd.css";

import searchBlack from "../../../../assets/svg/search_black.svg";
import guide_arrow from "../../../../assets/svg/guide_arrow.svg";
import guide_question from "../../../../assets/svg/guide_question.svg";
import now_1 from "../../../../assets/img/throo-now1.png";

import SettingForm from "./SettingForm";
import OrderTimeForm from "./OrderTimeForm";
import Layout from "../../Layout";

import {
  ModalBackPage,
  GuideSettingForm,
  GuideOrderTime,
} from "../../../modal/AppModal";
import { BrowserView, MobileView } from "react-device-detect";
import layoutHeaderLists from "../../config";

const Category = ({ oProps }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [sRegister, setRegister] = useState(false);
  const [iRegister, setIRegister] = useState(false);
  const [plainOptions, setPlainOptions] = useState([]);
  const [cafeOptions, setCafeOptions] = useState([]);
  const [shopOptions, setShopOptions] = useState([]);

  const [sData, setData] = useState({});
  const [iData, setIData] = useState({});

  const [modal, contextHolder] = Modal.useModal();

  const modalOpen = {
    content: <ModalBackPage sProps={oProps} />,
  };

  const modalOpenGuide = {
    width: "50vw",
    content: <GuideSettingForm sProps={oProps} />,
  };

  const modalOpenGuide2 = {
    width: "50vw",
    content: <GuideOrderTime sProps={oProps} />,
  };

  const insertForm = async (sIndex) => {
    setIsLoading(true);

    const oData = {
      store_id: oProps.UserConfigReducer.StoreID,
      sInfo: sIndex.storeDetail,
      sNoti: sIndex.storeNotice,
      sDetail: sIndex.storeOrigin,
      mainType: sIndex.mainType,
      subType: sIndex.subType,
      isSub: sIndex.isSub,
    };

    const oResponse = await oProps.oManager.fDoAxios(
      "/store/register/editStoreInfo",
      "post",
      "login",
      oData
    );
    if (oResponse !== undefined && oResponse !== null) {
      if (!oResponse) {
        message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
      } else {
        message.info("정상적으로 저장이 완료되었습니다.");
        setRegister(true);
      }
    }
    await asyncStore();
  };

  const uploadAllFunc = async (sIndex) => {
    setIsLoading(true);

    const oData = {
      sEasy: parseInt(sIndex.iEasy),
      sNormal: parseInt(sIndex.iNormal),
      sBusy: parseInt(sIndex.iBusy),
      sWalkThroo: parseInt(sIndex.iWalkThroo),
      store_id: oProps.UserConfigReducer.StoreID,
    };
    const oResponse = await oProps.oManager.fDoAxios(
      "/store/register/orderTime",
      "post",
      "login",
      oData
    );
    if (oResponse !== undefined && oResponse !== null) {
      if (!oResponse) {
        message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
      } else {
        message.info("정상적으로 저장이 완료되었습니다.");
        setIRegister(true);
      }
    }
    await asyncStore();
  };

  const asyncStore = async () => {
    const store_id = oProps.UserConfigReducer.StoreID;
    const oResponse = await oProps.oManager.fDoAxios(
      "/store/settingStoreDetail-" + store_id,
      "get",
      "login",
      null
    );
    if (oResponse !== undefined && oResponse !== null) {
      if (oResponse.resultCd === "locked") {
        const nData = {
          detail: oResponse.sInfo,
          noti: oResponse.sNoti,
          origin: oResponse.sDetail,
          mainType: oResponse.setType,
          subType: oResponse.subTypeNm,
        };
        setRegister(true);
        setData(nData);
      }

      setPlainOptions(oResponse.plainOptions);
      setCafeOptions(oResponse.cafeOptions);
      setShopOptions(oResponse.shopOptions);
    }
    const iResponse = await oProps.oManager.fDoAxios(
      "/store/orderTimeDesc-" + store_id,
      "get",
      "login",
      null
    );
    if (iResponse !== undefined && iResponse !== null) {
      if (iResponse.result_cd === "0000") {
        const kData = {
          sEasy: iResponse.sEasy,
          sNormal: iResponse.sNormal,
          sBusy: iResponse.sBusy,
          sWalk:
            iResponse.sWalkThroo.toString() !== "" ? iResponse.sWalkThroo : 0,
        };
        setIRegister(true);
        setIData(kData);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    asyncStore();
  }, []);

  return (
    <Layout sProps={oProps} routeList={layoutHeaderLists.store}>
      <BrowserView>
        <Style>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                alignItems: "center",
              }}
            >
              <Loader
                type="BallTriangle"
                color="#13166b"
                height={80}
                width={80}
                timeout={9000000}
              />
            </div>
          ) : (
            <>
              <SettingForm
                fnInsertForm={(sIndex) => insertForm(sIndex)}
                iRegister={sRegister}
                iData={sData}
                iPlainOptions={plainOptions}
                iCafeOptions={cafeOptions}
                iShopOptions={shopOptions}
              />
              <OrderTimeForm
                fnInsertForm={(sIndex) => uploadAllFunc(sIndex)}
                nRegister={iRegister}
                sData={iData}
              />
              <div className="side_help_content">
                <div
                  className="app_modal_container"
                  onClick={() => modal.success(modalOpen)}
                >
                  <div className="arrow_box">
                    <img src={guide_arrow} alt="none" />
                  </div>
                  <div className="text_box">
                    <div className="title_box">
                      <img
                        className="help_content_img"
                        src={searchBlack}
                        alt="none"
                      />
                      <p>앱 미리보기</p>
                    </div>
                    <div className="detail_box">
                      <p>
                        내 입력정보의 예상화면을 <br />
                        미리 확인해보세요
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="app_guide_modal_container"
                  onClick={() => modal.success(modalOpenGuide)}
                >
                  <div className="arrow_box">
                    <img src={guide_arrow} alt="none" />
                  </div>
                  <div className="text_box">
                    <div className="title_box">
                      <img
                        className="help_content_img"
                        src={guide_question}
                        alt="none"
                      />
                      <p>간편도움말</p>
                    </div>
                    <div className="detail_box">
                      <p>
                        입력예시를 통해 <br />
                        입력방법을 알려드립니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="side_help_content2">
                <div
                  className="app_guide_modal_container2"
                  onClick={() => modal.success(modalOpenGuide2)}
                >
                  <div className="arrow_box">
                    <img src={guide_arrow} alt="none" />
                  </div>
                  <div className="text_box">
                    <div className="title_box">
                      <img
                        className="help_content_img"
                        src={guide_question}
                        alt="none"
                      />
                      <p>간편도움말</p>
                    </div>
                    <div className="detail_box">
                      <p>
                        입력예시를 통해 <br />
                        입력방법을 알려드립니다.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="app_throo_now_modal_container2"
                  onClick={() => modal.success(modalOpenGuide2)}
                >
                  <div className="arrow_box">
                    <img src={guide_arrow} alt="none" />
                  </div>
                  <div className="text_box">
                    <div className="title_box">
                      <img src={now_1} alt="none" />
                      <p>스루나우 매장이란?</p>
                    </div>
                    <div className="detail_box">
                      <p>
                        고객님이 도착 후 빠른 픽업이
                        <br />
                        가능한 매장을 강조합니다.
                        <br />
                        다양한 광고효과와
                        <br />
                        매출상승 효과를 기대할 수 있습니다.
                        <br />
                        (준비시간이 짧을 수록 유리합니다.)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {contextHolder}
        </Style>
      </BrowserView>
      <MobileView>
        <Mstyle>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                alignItems: "center",
              }}
            >
              <Loader
                type="BallTriangle"
                color="#13166b"
                height={80}
                width={80}
                timeout={9000000}
              />
            </div>
          ) : (
            <>
              <SettingForm
                fnInsertForm={(sIndex) => insertForm(sIndex)}
                iRegister={sRegister}
                iData={sData}
                iPlainOptions={plainOptions}
                iCafeOptions={cafeOptions}
                iShopOptions={shopOptions}
              />
              <OrderTimeForm
                fnInsertForm={(sIndex) => uploadAllFunc(sIndex)}
                nRegister={iRegister}
                sData={iData}
              />
            </>
          )}
        </Mstyle>
      </MobileView>
    </Layout>
  );
};

export default Category;

const Style = styled.div`
  position: relative;
  width: 42vw;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Sans KR", sans-serif;
  }

  .location_box_line {
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    .how_box {
      display: flex;
      align-items: center;
      height: 50px;
      background: #f1f3f7;
      border-radius: 4px;

      p {
        margin-left: 3%;
        font-size: 0.7rem;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .essential_form {
    font-size: 0.8rem;
    font-weight: 400;
    color: #617be3;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
    color: #bbbbbb;
  }

  .insert_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    height: 60px;
    background: #617be3;
    border-radius: 5px;
    cursor: pointer;

    p {
      font-size: 0.9rem;
      font-weight: 700;
      color: #fff;
    }
  }

  /* 앱미리보기/간편도움말/스루나우 공통css */
  .side_help_content {
    position: absolute;
    left: 103%;
    top: 0;
  }
  .side_help_content2 {
    position: absolute;
    left: 103%;
    top: 60%;
  }
  .app_guide_modal_container,
  .app_modal_container,
  .app_guide_modal_container2,
  .app_throo_now_modal_container2 {
    padding: 13px 13px 40px 13px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    background: #fbfbfb;
    border: 1px solid #dfdfe3;
    border-radius: 8px;

    .arrow_box img {
      width: 5px;
    }
    .text_box {
      margin-left: 5px;
      min-width: 220px;

      .title_box {
        height: auto;
        margin-bottom: 9px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          margin-left: 8px;
          font-size: 16px;
          font-weight: 700;
          color: #617be3;
        }
      }
      .detail_box_line {
        p {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
        }
      }
      .help_content_img {
        width: 18px;
      }
    }
  }

  .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5vh;

    h2 {
      font-size: 1rem;
      font-weight: 700;
    }

    .tip {
      position: relative;
      display: flex;
    }
    .tip span {
      display: none;
      position: absolute;
      top: 20px;
      width: 10vw;
      min-height: 52px;
      padding: 8px 10px;
      z-index: 100;
      background: #f1f1f1;
      color: #000;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      font-size: 0.7rem;
      font-weight: 700;
      border: 1px solid rgba(223, 223, 223, 1);
    }
    .tip span strong {
      color: #617be3;
    }
    .tip:hover.tip span {
      display: block;
      margin-top: 5px;
    }

    .cancel_box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 3vh;
      width: 5vw;
      border: 1px solid #617be3;
      border-radius: 5px;
      cursor: pointer;
      p {
        font-size: 0.8rem;
        font-weight: 700;
        color: #617be3;
      }
    }
  }

  .container {
    padding: 32px;
    margin-bottom: 1px;
    background-color: #fff;
    box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 42vw;
  }

  .select_store_type {
    width: 100%;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
  }

  .selectItemWrapper {
    margin: 15px 0;
    padding: 20px 14px;
    background-color: #f1f3f7;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;

    .ant-radio-group.ant-radio-group-outline.radioButton {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .ant-radio-wrapper {
      flex: 0 0 25%;
      margin: 3px 0px;
    }
  }

  .category_title {
    position: relative;

    input {
      margin-top: 2%;
      padding: 12px;
      width: 100%;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
      outline: none;
    }

    .input_action {
      border: solid 1px #000000;
      transition: 0.1s ease-out;
    }

    .input_wrong {
      border: solid 1px #dd1212;
      transition: 0.1s ease-out;
    }

    input::placeholder {
      font-size: 0.9rem;
      font-weight: 400;
      color: #bbbbbb;
    }
    select {
      margin-top: 2%;
      padding: 12px;
      width: 100%;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
      outline: none;
    }

    .address {
      position: relative;
      margin-bottom: 8px;
    }
    .address input {
      width: 100%;
      position: absolute;
    }
    .address button {
      position: relative;
      top: 14px;
      bottom: 0;
      right: 0;
      border: 0;
      outline: 0;
      left: 90%;
    }

    textarea {
      margin-top: 2%;
      padding: 12px;
      width: 100%;
      min-height: 15vh;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
      outline: none;
    }
    textarea::placeholder {
      font-size: 0.9rem;
      font-weight: 400;
      color: #bbbbbb;
    }
  }
`;

const Mstyle = styled.div`
  .app_modal_container {
    margin-top: 8vh;
    height: 14vh;

    .app_modal {
      margin-left: 32%;
      margin-right: 32%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6vh;
      background: #fff;
      border-radius: 4px;

      p {
        font-size: 0.9rem;
        font-weight: 700;
        color: #617be3;
      }
    }
    .app_modal_detail {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      line-height: 21px;
      font-weight: 400;
      color: #666666;
    }
  }

  .essential_form {
    font-size: 0.8rem;
    font-weight: 700;
    color: #617be3;
  }
  span {
    font-size: 0.8rem;
    font-weight: 400;
    color: #bbbbbb;
  }

  .tip {
    position: relative;
    display: flex;
  }
  .tip span {
    display: none;
    position: absolute;
    top: 20px;
    right: 10px;
    width: 40vw;
    padding: 8px 10px;
    z-index: 100;
    background: #f1f1f1;
    color: #000;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    font-size: 0.8rem;
    font-weight: 700;
    border: 1px solid rgba(223, 223, 223, 1);
  }
  .tip span strong {
    color: #617be3;
  }
  .tip:hover.tip span {
    display: block;
    margin-top: 5px;
  }

  .category_title_line,
  .category_content_line {
    padding: 20px 16px;
    margin-bottom: 1px;
    background-color: #fff;

    h2 {
      font-size: 1.1rem;
      font-weight: 700;
    }
  }

  .category_content_line {
    margin: 0;
    margin-bottom: 1px;
  }

  .category_title {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5vh;
    margin-bottom: 5%;
    .title_box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4vh;

      .title_line {
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }

  .inputText {
    background-color: #fff;

    h3 {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .input_action {
      border: solid 1px #000000;
      transition: 0.1s ease-out;
    }

    .input_wrong {
      border: solid 1px #dd1212;
      transition: 0.1s ease-out;
    }

    .select_bank {
      padding: 6px;
      width: 100%;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
      outline: none;
    }

    .select_bank::placeholder {
      font-size: 0.9rem;
      font-weight: 400;
      color: #bbbbbb;
    }

    textarea {
      margin-top: 3%;
      padding: 12px;
      width: 100%;
      border: 1px solid #bbbbbb;
      border-radius: 4px;
      outline: none;
    }
  }

  .selectItemWrapper {
    margin: 8px 0;
    padding: 20px 14px;
    background-color: #f1f3f7;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;

    .ant-radio-group.ant-radio-group-outline.radioButton {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .ant-radio-wrapper {
      flex: 0 0 33%;
      margin: 3px 0px;
      font-size: 12px;
    }
  }
  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    border-right: 1px solid #fff;
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
    border-right: 1px solid #fff;
  }
  .ant-menu-item.ant-menu-item-only-child {
    padding: 0 12px !important;
    border-right: 1px solid #fff;
  }

  .last {
    width: 100%;
    padding: 24px 0px 32px 0px;
    background: #fff;

    .finishButoon {
      width: 100%;
      padding: 12px 0;
      background-color: #617be3;
      font-size: 1rem;
      font-weight: 700;
      color: #fff;
      border-radius: 4px;
      border: none;
      outline: none;
    }
  }
`;
