import React, { useState, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import 'moment/locale/ko';
import "antd/dist/antd.css";

import manual from '../../../../assets/svg/manual.svg';
import addressSearch from '../../../../assets/svg/addressSearch.svg';

import LineChart from '../../../../variables/LineChart';
import ColumnChart from '../../../../variables/ColumnChart';
import Layout from '../../Layout'
import { BrowserView, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';

const defaultFormat = "YYYY/MM/DD";
const { RangePicker } = DatePicker;

const Chart = ({ oProps }) => {
   const [iLoading, setILoading] = useState(true);
   const [sLoading, setSLoading] = useState(true);

   const [selectValue, setSelectValue] = useState("count");
   const [sChartData, setSChartData] = useState([]);
   const [iChartData, setIChartData] = useState([]);
   const [fromDate, setFromDate] = useState(moment().add(-1, 'month').format(defaultFormat));
   const [toDate, setToDate] = useState(moment().add(1, 'month').format(defaultFormat));

   const onChangeChart = (sIndex) => {
      if (sIndex === "payment") {
         setILoading(true);
         getLineChart();
      } else {
         setSLoading(true);
         getBarChart();
      }
      setSelectValue(sIndex);
   }

   const onOperatingDate = (value, dateString) => {
      if (dateString[0] !== undefined && dateString[0] !== null && dateString[0] !== "") {
         if (fromDate === moment().format(defaultFormat)) {
            if (dateString[1] !== undefined && dateString[1] !== null && dateString[1] !== "") {
               setFromDate(dateString[0]);
               setToDate(dateString[1]);
            } else {
               setFromDate(dateString[0]);
            }
         } else {
            setFromDate(dateString[0]);
            setToDate(dateString[1]);
         }
      } else {
         setFromDate(moment().format(defaultFormat));
         setToDate(moment().add(1, 'days').format(defaultFormat));
      }
   }

   const searchChart = () => {
      //setILoading(true);
      //getLineChart(fromDate, toDate);
      //getBarChart();
   }

   const getBarChart = async () => {
      let store_id = oProps.UserConfigReducer.StoreID;
      const oResponse = await oProps.oManager.fDoAxios(`/payment/getColumnChart/v2-` + store_id, "get", "login", null);
      if (oResponse !== undefined) {
         const config = {
            data: oResponse,
            xField: 'date',
            yField: 'value',
            xAxis: {
               label: {
                  autoHide: true,
                  autoRotate: false,
               },
            },
            meta: {
               date: { alias: '날짜' },
               value: { alias: '주문건수' },
            },
         };
         setIChartData(config);
      }
      setSLoading(false);
   }

   const getLineChart = async (iIndex, aIndex) => {
      return;
      let storeId = oProps.UserConfigReducer.StoreID;
      let from_Date = moment().add(-1, 'month').format(defaultFormat)
      let to_Date = moment().add(1, 'month').format(defaultFormat)

      if (iIndex !== undefined && iIndex != null) {
         from_Date = iIndex;
      }
      if (aIndex !== undefined && aIndex != null) {
         to_Date = aIndex;
      }

      const oData = {
         storeId,
         fromDate: from_Date,
         toDate: to_Date,
      }
      const oResponse = await oProps.oManager.fDoAxios(`/payment/getLineChart/v2`, "post", "login", oData);
      if (oResponse !== undefined) {
         const iConfig = {
            data: oResponse,
            xField: 'date',
            yField: 'value',
            meta: {
               date: { alias: '날짜' },
               value: { alias: '금액' },
            },
         };
         setSChartData(iConfig);
      }
      setILoading(false);
   }

   useEffect(() => {
      //getLineChart();
      onChangeChart("count");
   }, []);

   return (
      <Layout sProps={oProps} routeList={layoutHeaderLists.payment}>
         <BrowserView>
            <Style>
               <div className="container">
                  <div className="title_box">
                     <h2>차트</h2>
                     <div className="tip">
                        <SVG src={manual} width="18px" height="18px" />
                        <span><strong>매장에 대한 정보를</strong>차트로<br />확인이 가능합니다.</span>
                     </div>
                  </div>
                  <div className="last_line">
                     {/*
                     <div className={selectValue === "payment" ? "selected_box" : "unselected_box"} style={{ marginRight: "1%" }} onClick={() => onChangeChart("payment")}>
                        <p>날짜별 매출</p>
                     </div>
                     */}
                     <div className={selectValue !== "payment" ? "selected_box" : "unselected_box"} style={{ marginLeft: "1%" }} onClick={() => onChangeChart("count")}>
                        <p>월별 주문 건수</p>
                     </div>
                  </div>
                  {selectValue === "payment" ?
                     <>
                        <div className="searching_line">
                           <div className="search_date">
                              <RangePicker
                                 bordered={false}
                                 className="date_picker"
                                 style={{ marginLeft: "2%", fontSize: "0rem", width: "100%" }}
                                 format={defaultFormat}
                                 value={[moment(fromDate, defaultFormat), moment(toDate, defaultFormat)]}
                                 onChange={onOperatingDate}
                              />
                           </div>
                           <div className="search_box" onClick={searchChart}>
                              <img src={addressSearch} alt="none" />
                              <p>조회</p>
                           </div>
                        </div>
                        <div className="wrapper_chart">
                           {!iLoading && sChartData.data !== undefined && sChartData.data !== null ?
                              <LineChart sChart={sChartData} />
                              :
                              <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                 <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                              </div>
                           }
                        </div>
                     </>
                     :
                     <div className="wrapper_chart">
                        {!sLoading && iChartData.data !== undefined && iChartData.data !== null ?
                           <ColumnChart iChart={iChartData} />
                           :
                           <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                              <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                           </div>
                        }
                     </div>
                  }
               </div>
            </Style>
         </BrowserView>
         <MobileView>
            <Mstyle>
               <div className="payment_title_line">
                  <div className="payment_title">
                     <p>차트</p>
                     <div className="tip">
                        <SVG src={manual} width="18px" height="18px" />
                        <span><strong>매장에 대한 정보를</strong>차트로<br />확인이 가능합니다.</span>
                     </div>
                  </div>
                  <div className="payment_line">
                     {/*
                            <div className={selectValue === "payment" ? "selected_box" : "unselected_box"} style={{ marginRight: "1%" }} onClick={() => onChangeChart("payment")}>
                                <p>날짜별 매출</p>
                            </div>
                           */}
                     <div className={selectValue !== "payment" ? "selected_box" : "unselected_box"} style={{ marginLeft: "1%" }} onClick={() => onChangeChart("count")}>
                        <p>월별 주문 건수</p>
                     </div>
                  </div>
                  {selectValue === "payment" ?
                     <>
                        <div className="searching_line">
                           <div className="search_date">
                              <RangePicker
                                 bordered={false}
                                 className="date_picker"
                                 style={{ marginLeft: "2%", fontSize: "0rem", width: "100%" }}
                                 format={defaultFormat}
                                 value={[moment(fromDate, defaultFormat), moment(toDate, defaultFormat)]}
                                 onChange={onOperatingDate}
                              />
                           </div>
                           <div className="search_box" onClick={searchChart}>
                              <img src={addressSearch} alt="none" />
                              <p>조회</p>
                           </div>
                        </div>
                        <div className="wrapper_chart">
                           {!iLoading && sChartData.data !== undefined && sChartData.data !== null ?
                              <LineChart sChart={sChartData} />
                              :
                              <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                                 <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                              </div>
                           }
                        </div>
                     </>
                     :
                     <div className="wrapper_chart">
                        {!sLoading && iChartData.data !== undefined && iChartData.data !== null ?
                           <ColumnChart iChart={iChartData} />
                           :
                           <div style={{ display: "flex", justifyContent: "center", height: "30vh", alignItems: "center" }}>
                              <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                           </div>
                        }
                     </div>
                  }
               </div>
            </Mstyle>

         </MobileView>
      </Layout>
   )
}

export default Chart;

const Style = styled.div`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  

    .wrapper_chart {
        margin-top: 5%;
    }

    .title_box {
        display: flex;
        align-items: center;
        height: 5vh;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
        .tip {
            position: relative;
            display: flex;
        }
        .tip span {
            display: none;
            position: absolute;
            top: 20px;
            left: 10px;
            width: 10vw;
            min-height: 52px;
            padding: 8px 10px;
            z-index: 100;
            background: #F1F1F1;
            color: #000;
            -moz-border-radius: 4px; 
            -webkit-border-radius: 4px; 
            font-size: 0.7rem;
            font-weight: 700;
            border: 1px solid rgba(223, 223, 223, 1)
        }
        .tip span strong {
            color: #617BE3;
        }
        .tip:hover.tip span {
            display: block;
            margin-top: 5px;
        }
    }

    .container {
        width: 42vw; 
        padding: 32px;
        margin-bottom: 16px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;


    }

    
    .last_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 8vh;
        padding-bottom: 1%;
        border-bottom: 1px solid #dfdfdf;

        
        .selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 5vh;
            background : #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #fff;
            }
            
        }

        .unselected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 5vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #617BE3;
            }
        }

        .unselected_box:hover {
            background : #617BE3;

            p {
                color: #fff;
            }
        }

    }

    .searching_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;

        .search_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 2%;
            width: 20%;
            height: 5vh;
            background : #617BE3;
            border-radius: 5px;
            cursor: pointer;

            p {
                margin-left: 5%;
                font-size: 0.9rem;
                color: #fff;
            }
        }
    } 

    .search_bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 100%;
        min-height: 5vh;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            color: #fff;
            text-align: center;
        }
        svg {
            width: 18px;
            height: 18px;
            margin-right: 8px;
        }
    }

    .search_selectOption {
        display: flex;
        align-items: center;
        height: 5vh;
        border : 1px solid #000;
        border-radius: 5px;
        font-size: 0.8rem;
    }

    .search_date {
        display: flex;
        align-items: center;
        height: 5vh;
        width: 100%;
        border : 1px solid #000;
        border-radius: 5px;
    }

    .data_box {
        margin-top: 2%;
    }

    .nodata_box {
        margin-top: 2%;
        height: 50vh;
    }
    
`



const Mstyle = styled.div`

    .wrapper_chart {
        margin-top: 5%;
    }

    .tip {
        position: relative;
        display: flex;
    }
    .tip span {
        display: none;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 180px;
        padding: 8px 10px;
        z-index: 100;
        background: #F1F1F1;
        color: #000;
        -moz-border-radius: 4px; 
        -webkit-border-radius: 4px; 
        font-size: 0.7rem;
        font-weight: 700;
        border: 1px solid rgba(223, 223, 223, 1)
    }
    .tip span strong {
        color: #617BE3;
    }
    .tip:hover.tip span {
        display: block;
        margin-top: 5px;
    }

    .payment_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }

        svg {
            margin-left: 7px;
        }
    }

    .payment_title_line, .number {
        padding: 20px 16px;
        background-color: #fff;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .number {
        margin: 0;
    }

    .searching_line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;

        .search_date {
            display: flex;
            align-items: center;
            height: 6vh;
            width: 100%;
            border : 1px solid #000;
            border-radius: 5px;
        }

        .search_box {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 2%;
            width: 30%;
            height: 6vh;
            background : #617BE3;
            border-radius: 5px;
            cursor: pointer;

            p {
                margin-left: 5%;
                font-size: 0.9rem;
                color: #fff;
            }
        }
    }

    .payment_line {
        margin-top: 5%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5%;
        border-bottom: 1px solid #dfdfdf;
        h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 0.8rem;
            line-height: 21px;
        }

        .selected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 6vh;
            background : #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #fff;
            }
            
        }

        .unselected_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 6vh;
            border : 1px solid #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                line-height: 24px;
                color: #617BE3;
            }
        }
    }
    

    .number {
        border-bottom: 1px solid #dfdfdf;

        .select_value {
            height: 10vh;

            .search_selectOption {
                display: flex;
                align-items: center;
                height: 7vh;
                border : 1px solid #000;
                border-radius: 5px;
                font-size: 0.8rem;
            }

        }

        .date_picker {
            display: flex;
            align-items: center;
            height: 7vh;
            border : 1px solid #000;
            border-radius: 5px;
            font-size: 0.8rem;
            margin-bottom: 10%;
        }


        .search_line {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 7vh;
            border-radius: 5px;
            background-color: #617BE3;

            p {
                font-style: normal;
                font-weight: bold;
                font-size: 0.9rem;
                margin-left: 3%;
                color: #fff; 
            }
        }

    }

    .table_box_none {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vh;
        background: #fff;
        border-bottom: 1px solid #dfdfdf;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 24px;
        }
    }
    .table_box {
        display: flex;
        align-items: center;
        height: 13vh;
        background: #fff;
        border-bottom: 1px solid #dfdfdf;

        .content_line {
            width: 65%;
            
            .content1 {
                display: flex;
                align-items: flex-end;
                margin-left: 7%;
                width: 100%;
                height: 7vh;

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1.1rem;
                    line-height: 24px;
                }
                .state_box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 3vh;
                    width: 37px;
                    margin-left: 5%;
                    background: #666666;
                    border-radius: 5px;

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 0.7rem;
                        color: #fff; 
                    }
                }
            }
            .content2 {
                display: flex;
                align-items: flex-start;
                margin-left: 7%;
                width: 100%;
                height: 7vh;

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.9rem;
                    line-height: 24px;
                }
            }
        }

        .price_line {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35%;
            height: 14vh;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 0.9rem;
                margin-right: 10%;
                color: #000; 
            }
        }

    }

    .pagination_box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 7vh;
        background: #fff;
    }


`


