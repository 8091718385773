import {createStore, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import LocalStorage from 'redux-persist/lib/storage';

// Imports: Redux
import rootReducer from '../reducers/index';

const persistConfig = {
  // Root
  key: 'throo_admin_system',

  // Storage Method (React Native)
  storage: LocalStorage,

  // Whitelist (Save Specific Reducers)
  whitelist: ['AppConfigReducer', 'UserConfigReducer'],
  
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const store = createStore(persistedReducer, applyMiddleware());

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

export {store, persistor};
