import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// 이미지
import tip01 from '../../../../assets/img/ceoscreen/tip01.png';
import tip02 from '../../../../assets/img/ceoscreen/tip02.png';
import tip03 from '../../../../assets/img/ceoscreen/tip03.png';
import tip04 from '../../../../assets/img/ceoscreen/tip04.png';
import tip05 from '../../../../assets/img/ceoscreen/tip05.png';

const SubCeoTip = () => {
    return (
        <ContentsStyle >
            <div className="ceoTipSubPage">
                <div className="container">
                    <div className="subContent">
                        <h1>스루 사장님이 꼭 알아야 할 정보</h1>
                        <div className="tipContent">
                            <Link to={{ pathname: '/ceotip', page_id: 'stamp', search: 'stamp' }}>
                                <div className="tip">
                                    <div className="img"><img src={tip05} alt="스탬프 이미지" width="100%" /></div>
                                    <div className="content">
                                        <div className="icon">TIP</div>
                                        <p>스탬프 기능을 활용하여 우리 매장 단골 고객을 만들어보세요</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'coupon', search: 'coupon' }}>
                                <div className="tip">
                                    <div className="img"><img src={tip04} alt="할인쿠폰 이미지" width="100%" /></div>
                                    <div className="content">
                                        <div className="icon">TIP</div>
                                        <p>할인쿠폰으로 고객의 주문을 유도해 보세요</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'sales', search: 'sales' }}>
                                <div className="tip">
                                    <div className="img"><img src={tip01} alt="스루정산 이미지" width="100%" /></div>
                                    <div className="content">
                                        <div className="icon" style={{ backgroundColor: "#FF9900" }}>FAQ</div>
                                        <p>스루의 정산 주기는 어떻게 되나요?</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'only', search: 'only' }}>
                                <div className="tip">
                                    <div className="img"><img src={tip02} alt="스루온리 이미지" width="100%" /></div>
                                    <div className="content">
                                        <div className="icon">TIP</div>
                                        <p>스루 온리로 운전자 고객을 사로잡아보세요</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{ pathname: '/ceotip', page_id: 'now', search: 'now' }}>
                                <div className="tip">
                                    <div className="img"><img src={tip03} alt="스루나우 이미지" width="100%" /></div>
                                    <div className="content">
                                        <div className="icon">TIP</div>
                                        <p>스루 나우로 여유시간에 추가 매출 올려보세요</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </ContentsStyle >
    )
}

export default SubCeoTip;

export const ContentsStyle = styled.div`
 * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .container {
        width: 1120px;
        margin: 0 auto;
    }
    p, a, h1, h2, h3, h4, h5, h6, li, ul, button {
        font-family: 'NanumSquare';
        transform: skew(-0.1deg);
        font-weight: 900;
    }
    ul,li {
        list-style: none;
    }

    h1 {
        font-size: 54px;
        font-weight: 900;
        margin: 156px 0 48px 0;
        text-align: center;
    }
    .tipContent {
        margin-bottom: 100px;

        .tip {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 16px;
            padding: 17px 23px;
            border: 1px solid #ECEEF2;
            border-radius: 4px;
            
            .img img {
                width: 106px;
                min-height: 76px;
                max-height: 76px;
            }
            .content {
                background-color: #fff;
                width: 100%;
                text-align: left;
                margin-left: 11px;
                a {
                }
                
                .icon {
                    width: 36px;
                    height: 22px;
                    padding-top: 1px;
                    margin-bottom: 16px;
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'NanumSquare';
                    transform: skew(-0.1deg);
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 24px;
                    background-color: #1A7CFF;
                    color: #fff;
                }
                p {
                    font-size: 16px;
                    font-weight: 700;
                    color: #000;
                }
            }
        }
    }

`

