import React from 'react';
import styled from 'styled-components';

// 이미지
import only01 from '../../../../assets/img/guide/only01.png';
import only02 from '../../../../assets/img/guide/only02.png';

const OnlyGuide = (oProps) => {

    return (
        <M_onlyGuide>
            <div className="ceoTip">
                <div className="container">
                    <div className="cTip">
                        <h3>스루 가이드</h3>
                        <h1>스루 ONLY</h1>
                        <p>
                            스루 ONLY는 '스루에서만 판매하는 스루 이용 고객에 특화된 상품이나 구성'을 뜻합니다.<br /><br />
                            스루에서만 판매할 수 있는 상품을 만들어보세요.<br />
                            출근길 간편하게 드실 수 있는 아침 대용식, 커피와 디저트 세트, 퇴근 후 혼밥하는 고객을 위한 1인용 메뉴, 홈 파티 메뉴, 차크닉을 위한 피크닉 SET 구성 등 다양한 상품과 구성으로 스루 고객 공략이 가능합니다.
                        </p>
                        <div className="tip">
                            <h2><span>TIP 01</span><br />스루 ONLY 이용 방법</h2>
                            <p>사장님 창구 페이지 <span>&#62;</span> 매장관리에서 스루 ONLY 박스를 체크 시 스루 ONLY로 표시됩니다.</p>
                            <img src={only01} alt="스루only이용방법 이미지" width="100%" />
                        </div>
                        <div className="tip">
                            <h2><span>TIP 02</span><br />스루 ONLY 이용 장점</h2>
                            <p>스루 ONLY 상품은 앱 내 상품 상단에 우선 노출됩니다.</p>
                            <img src={only02} alt="스루only이용장점 이미지" width="100%" />
                        </div>
                        <p style={{marginTop:"10px"}}>차에서 먹기 좋은 메뉴로 운전자 고객을 사로잡아 단골 고객을 확보해 보세요!</p>
                    </div>
                </div>
            </div>
        </M_onlyGuide>
    )
}

export default OnlyGuide;
export const M_onlyGuide = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 100%;
        margin: 0 auto;
        padding: 0 5vw;
    }
    .cTip {
        margin-top: 106px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 26px 0;
            font-size: 32px;
            font-weight: 900;
            line-height: 48px;
        }
        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .tip {
        h2 {
            margin: 34px 0 10px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;

            span {
                color: #8E929F;
            }
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
`