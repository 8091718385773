import React from 'react';
import styled from 'styled-components';

import pos01 from '../../../../assets/img/guide/pos/posProcess01.png';
import pos02 from '../../../../assets/img/guide/pos/posProcess02.png';
import pos03 from '../../../../assets/img/guide/pos/posProcess03.png';
import pos04 from '../../../../assets/img/guide/pos/posProcess04.png';
import pos05 from '../../../../assets/img/guide/pos/posProcess05.png';
import pos06 from '../../../../assets/img/guide/pos/posProcess06.png';
import pos07 from '../../../../assets/img/guide/pos/posProcess07.png';
import pos08 from '../../../../assets/img/guide/pos/posProcess08.png';


const PosGuide = (oProps) => {

    return (
        <D_posGuide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>스루 주문접수</h3>
                        <h1>스루 주문 접수 가이드</h1>
                        <div className="subTitle">
                            <p>
                                스루 주문 접수는<br />
                                <em>주문 접수 <span>&#8594;</span> 주문 확인하기 <span>&#8594;</span> 고객 위치 확인 <span>&#8594;</span>고객 도착 알림 <span>&#8594;</span> 전달 완료</em> 순서대로 진행됩니다.
                            </p>
                        </div>
                        <h2><span>STEP 1</span><br />고객의 주문이 들어옵니다.</h2>
                        <img src={pos01} alt="스루 주문 접수 가이드 이미지" width="400px" />
                        <p>주문이 들어오면 알림과 함께 진행 중 주문 탭에 주문 카드가 생깁니다.<br /> 이 주문 카드를 클릭해 주세요.</p>
                        <h2><span>STEP 2</span><br />주문 확인하기 버튼을 눌러주세요.</h2>
                        <img src={pos02} alt="스루 주문 접수 가이드 이미지" width="400px" />
                        <p>주문정보에서는 고객 도착 예상시간과 위치를 확인할 수 있습니다.</p>
                        <img src={pos03} alt="스루 주문 접수 가이드 이미지" width="400px" />
                        <p>위치 확인 버튼을 누르면 고객의 위치가 실시간으로 확인이 가능합니다.</p>
                        <h2><span>STEP 3</span><br />상품 준비가 완료되었다면 고객에게 준비 완료 알림을 보낼 수 있어요.</h2>
                        <img src={pos04} alt="스루 주문 접수 가이드 이미지" width="400px" />
                        <h2><span>STEP 4</span><br />고객이 매장 근처에 접근하면 두 번의 알림이 울려요.<br />고객 근접 알림 (200M 근처) / 고객 도착 알림 (50M 근처)</h2>
                        <p>거리 설정은 기본값이며 수정이 가능해요.</p>
                        <h2><span>STEP 5</span><br />상품 전달 완료!</h2>
                        <img src={pos05} alt="스루 주문 접수 가이드 이미지" width="400px" />
                        <p>고객이 도착하면 지정한 픽업 존으로 이동해서 상품을 전달 후 전달 완료 버튼을 눌러주세요!</p>
                        <div className="tip">
                            <h2>✨워크스루 오픈✨</h2>
                            <p>
                                매장에서 상품을 받는 <span style={{color: "#F45452", fontWeight:"900"}}>워크스루주문</span>이 새로 생겼어요.<br />
                                워크스루주문은 주문서에 고객 차량 정보 대신 <span style={{fontWeight:"900"}}>‘매장에서 픽업’</span>이라고 적혀있어요.<br />
                                워크 스루 손님은 <span style={{color: "#F45452", fontWeight:"700"}}>매장에서 상품을 전달</span>해 주세요!
                            </p>
                            <img src={pos06} alt="스루 주문 접수 가이드 이미지" width="400px" style={{marginTop:"16px",marginBottom:"40px"}} />
                            <img src={pos07} alt="스루 주문 접수 가이드 이미지" width="400px" />
                        </div>
                        <div className="tip">
                            <p style={{marginTop:"20px"}}>👍 TIP<br />바탕화면에 스루 포스 아이콘으로<br />다른 프로그램과 같이 사용하는 것이 편리해요!</p>
                            <img src={pos08} alt="스루 주문 접수 가이드 이미지" width="400px" />
                        </div>
                        <div className="tip">
                            <p>스루의 고객 경험은 사장님의 정성과 서비스 마인드로 완성됩니다.<br /> 주문 한 건도 자동 취소되지 않고 고객이 경험할 수 있도록 부탁드립니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </D_posGuide>
    )
}

export default PosGuide;

export const D_posGuide = styled.aside`
   * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .pGuideCont {
        margin-top: 110px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;

        span {
            color: #8E929F;
        }
    }
    p {
        margin: 15px 0 25px 0;
        font-size: 16px;
        font-weight: 700;
    }

    .tip {
        margin-top: 34px;
        border-top: 2px solid #EEEEEE;

        h2 {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 900;
            line-height: 32px;
        }
        p {
            margin: 10px 0;
            font-size: 16px;
            font-weight: 600;
        }
    }
`