import React from 'react';
import { Line } from '@ant-design/charts';

interface ConfigData {
  sChart: any;
}

const LineChart: React.FC<ConfigData> = props => {
  return <Line {...props.sChart}/>;
};
export default LineChart;