import React, { useState, useEffect, useRef, Fragment } from 'react';
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import ReactDragListView from "react-drag-listview";
import { message, Popconfirm, Table, Modal, Select } from 'antd';
import "antd/dist/antd.css";

import addressSearch from '../../../../assets/svg/search_black.svg';
import guide_arrow from '../../../../assets/svg/guide_arrow.svg';
import guide_question from '../../../../assets/svg/guide_question.svg';
import only from '../../../../assets/img/throo-only1.png';
import noneImageBox from '../../../../assets/svg/none_image.svg';
import searchBlack from '../../../../assets/svg/search_black.svg';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { ModalCategoryPage, GuideProductList } from '../../../modal/AppModal';

import InsertPage from './InsertPage';
import EditPage from './EditPage';
import Layout from '../../Layout'
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import layoutHeaderLists from '../../config';
const { Option } = Select;

const Product = ({ oProps }) => {
    const [sCategory, setCategory] = useState("");
    const [selectCategory, set_selectCategory] = useState("");
    const [visible, setVisible] = useState(false);

    const [isLoading, setLoading] = useState(true);
    const [iStatus, setStatus] = useState("");

    const [sCategoryList, setCategoryList] = useState([]);
    const [sProductList, setProductList] = useState([]);
    const [sDetail, setDetail] = useState({});
    const insertPageRef = useRef(null)





    const [sProductColumns] = useState([
        {
            title: "순서",
            key: "key",
            render: (text, record, index) => <a className="drag-handle">{text.key}</a>
        },
        {
            title: "메뉴명",
            key: "name",
            dataIndex: "name",
            render: (text, record, index, productType) => (
                <div>
                    {text}
                    {record.productType === 1 && <span style={{ background: '#E8EFFC', border: '1px solid #6490E7', color: '#6490e7', fontSize: '6px', marginLeft: '10px', padding: '2px 6px', borderRadius: '4px' }}>세트 상품</span>}
                </div>
            ),
        },
        {
            title: "상태",
            dataIndex: "soldOut",
            key: "soldOut"
        },
        {
            title: "설정",
            key: "action",
            render: (text, record, index) => <div className="detail_box" onClick={() => openEditPage(text)}><p>수정</p></div>
        },
        {
            title: "삭제",
            key: "action",
            render: (text, record, index) =>
                <Popconfirm
                    title="이 상품을 삭제하시겠습니까?"
                    onConfirm={() => {
                        setLoading(true);
                        deleteRow(text.id)
                    }}
                    okText="예"
                    cancelText="아니오"
                >
                    <div className="delete_box"><p>삭제</p></div>
                </Popconfirm>
        }
    ]);

    const categoryIdNm = useRef(0);
    const sMenuId = useRef(0);

    const showModal = (sIndex) => {
        sMenuId.current = sIndex;
        setVisible(true);
    };

    const [modal, contextHolder] = Modal.useModal();

    const changeNormal = () => {
        setStatus("new");
    }

    const dragProps = (fromIndex, toIndex) => {
        let tempColumns = sProductList;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(fromIndex, 1)[0];
        columnsCopy.splice(toIndex, 0, item);
        setLoading(true);
        changeRow(columnsCopy, sCategory);
    }

    const onChangeProductValue = (event) => {
        setLoading(true);
        // if (isMobile) {
        //     set_selectCategory(parseInt(event))
        //     setCategory(event);
        //     getMenuList(parseInt(event));
        //     categoryIdNm.current = parseInt(event);

        // } else {
        //     set_selectCategory(parseInt(event.target.value))
        //     setCategory(parseInt(event.target.value));
        //     getMenuList(parseInt(event.target.value));
        //     categoryIdNm.current = parseInt(event.target.value);
        // }

        set_selectCategory(parseInt(event.target.value))
        setCategory(parseInt(event.target.value));
        getMenuList(parseInt(event.target.value));
        categoryIdNm.current = parseInt(event.target.value);
    }

    const deleteRow = async (sIndex) => {
        let oData = {}
        if (isMobile) {
            oData = {
                sIndex: sMenuId.current
            }
        } else
            oData = {
                sIndex
            };
        const oResponse = await oProps.oManager.fDoAxios("/store/register/deleteMenu/v2", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse) {
                message.info("삭제되었습니다");
                setVisible(false)
            } else {
                message.error("삭제에 실패했습니다 잠시 후 다시 시도바랍니다");
            }
        }
        await getMenuList(parseInt(categoryIdNm.current));
    }

    const imageProcess = async (fileList) => {
        let logoUpload = {};

        const formData = new FormData();
        formData.append('photo', fileList);

        const oResponse = await oProps.oManager.fDoAxios("/store/register/filesLogo", "post", "multipart", formData)
        if (oResponse !== undefined) {
            if (oResponse.file_name !== undefined) {
                logoUpload = oResponse;
            }
        }
        return logoUpload;
    }

    const addList = async (sIndex) => {
        let isProcess = true;
        let logoUpload = {};
        const store_id = oProps.UserConfigReducer.StoreID;

        if (sIndex.sFileList !== "") {
            const upload = await imageProcess(sIndex.sFileList);
            if (upload === undefined && upload === null && upload.url_path === "") {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                isProcess = false;
            } else {
                logoUpload = upload;
            }
        }

        if (isProcess) {
            const oData = {
                sFileList: logoUpload,
                sTitle: sIndex.productNm,
                sCategory: parseInt(sIndex.sCategory),
                iPrice: sIndex.sPrice,
                dPrice: sIndex.dPrice,
                option: sIndex.sOptionBox,
                product_option: sIndex.setMenuBox,
                sDesc: sIndex.productDetail,
                productType: sIndex.productType,
                store_id
            }

            const oResponse = await oProps.oManager.fDoAxios("/store/register/registerMenu/v3", "post", "login", oData);
            if (oResponse !== undefined) {
                if (oResponse.resultCd === "0000") {
                    message.info("등록되었습니다");


                    // insertPageRef.current.setProductNm("");
                    // insertPageRef.current.setPrice("");
                    insertPageRef.current.setDiscountPrice("");
                    insertPageRef.current.setFileList("");
                    insertPageRef.current.setProductDetail("");
                    // insertPageRef.current.setOptionBox("");
                    insertPageRef.current.setSetMenuBox("");
                    insertPageRef.current.setProductType("normal");
                } else {
                    message.info("등록에 실패했습니다.");
                    // message.error(oResponse.resultMsg);
                }
            }

            if (sCategory !== "") {
                await getMenuList(parseInt(sIndex.sCategory));
                set_selectCategory(parseInt(oData.sCategory))
            }
        }
    }
    useEffect(() => {
    }, [selectCategory]);

    const editList = async (sIndex) => {

        const store_id = oProps.UserConfigReducer.StoreID;
        let isProcess = true;
        let logoUpload = {};

        if (sIndex.sFileList !== "") {
            const upload = await imageProcess(sIndex.sFileList);
            if (upload === undefined && upload === null && upload.url_path === "") {
                message.error("문제가 발생했습니다 관리자에 연락바랍니다.");
                isProcess = false;
            } else {
                sIndex.sPreviewImage = upload.url_path;
                logoUpload = upload;
            }
        }

        if (isProcess) {

            // id_order추가
            for (let i = 0; i < sIndex.sOptionBox.length; i++) {
                sIndex.sOptionBox[i].id_order = i;
            }
            for (let i = 0; i < sIndex.setMenuBox.length; i++) {
                sIndex.setMenuBox[i].id_order = i;
            }

            const oData = {
                sFileList: logoUpload,
                sTitle: sIndex.productNm,
                sCategory: sIndex.sCategory,
                iPrice: sIndex.sPrice,
                dPrice: sIndex.dPrice,
                options: [].concat(sIndex.sOptionBox, sIndex.setMenuBox),
                sDesc: sIndex.productDetail,
                product_id: sIndex.sProductId,
                media_id: sIndex.mediaId,
                sProductPic: sIndex.sPreviewImage,
                pre_option_list: sIndex.preOptionList,
                iStock: sIndex.iStock,
                productType: sIndex.productType,
                setOptions: sIndex.setMenuBox,
                sOptionBox: sIndex.sOptionBox,
                store_id
            }


            const oResponse = await oProps.oManager.fDoAxios("/store/register/editMenu/v2", "post", "login", oData);
            if (oResponse !== undefined) {
                if (oResponse.resultCd === "0000") {
                    message.info("수정되었습니다");
                    set_selectCategory(parseInt(oData.sCategory))
                } else {
                    message.error(oResponse.resultMsg);
                }
            }
        }
        if (sCategory !== "") {
            await getMenuList(parseInt(sIndex.sCategory));
        }
        // if (sIndex.sCategory !== sCategory) {
        //     await getMenuList(parseInt(sCategory));
        // }
    }

    const changeRow = async (sIndex, aIndex) => {
        const store_id = oProps.UserConfigReducer.StoreID;
        const oData = {
            sIndex,
            store_id
        }
        const oResponse = await oProps.oManager.fDoAxios("/store/register/changeIndexMenu", "post", "login", oData);
        if (oResponse !== undefined) {
            if (oResponse.resultCd === "9999") {
                message.error("변경에 실패했습니다 잠시 후 다시 시도바랍니다");
            } else if (oResponse.resultCd === "0000") {
                message.info("변경되었습니다");
            }
        }
        await getMenuList(parseInt(aIndex));
    }
    const ChangeRowMob = async (sIndex, fIndex) => {

        if ((sIndex === 0 && fIndex === -1) || (sIndex === sProductList.length - 1 && fIndex === 1)) {
            return;
        }
        let tempColumns = sProductList;
        const columnsCopy = tempColumns.slice();
        const item = columnsCopy.splice(sIndex, 1)[0];
        columnsCopy.splice(sIndex + fIndex, 0, item);
        changeRow(columnsCopy, sCategory);
    }

    const openEditPage = async (sIndex) => {
        const menu_id = sIndex.id;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/getMenuDetail/v2-" + menu_id, "get", "login", null);

        if (oResponse !== undefined) {
            setDetail(oResponse);
        }
        setStatus("edit");
    }

    // 등록 메뉴 목록
    const getMenuList = async (sIndex) => {
        const category_id = sIndex;
        const oResponse = await oProps.oManager.fDoAxios("/store/register/menuList-" + category_id, "get", "login", null);
        console.log('oResponse', oResponse)
        if (oResponse !== undefined) {
            setProductList(oResponse);
        }
        setLoading(false);
    }

    // 등록 카테고리 메뉴
    const categoryList = async (sIndex) => {
        let aData = [];
        try {
            const oParams = {
                store_id: oProps.UserConfigReducer.StoreID,

            }
            const oResponse = await oProps.oManager.fDoAxios("/store/register/detailMenuList-" + oParams.store_id, "get", "login", null, oParams)

            console.log('sIndexsIndex', sIndex)
            if (oResponse !== undefined) {
                // setCategoryList(oResponse.sResult);
                for (let i = 0; i < oResponse.sResult.length; i++) {
                    const value = oResponse.sResult[i].name;
                    const label = oResponse.sResult[i].name;
                    const key = oResponse.sResult[i].key;
                    const name = oResponse.sResult[i].name;
                    const id = oResponse.sResult[i].id;

                    const oParams = {
                        value: value,
                        label: label,
                        key: key,
                        name: name,
                        id: id,
                    };
                    aData.push(oParams)
                }
                // aData.push({
                //     value: "분류 없음",
                //     label: "분류 없음",
                //     key: 0,
                //     name: "분류 없음",
                //     id: 0,
                // })
                setCategoryList(aData)

                if (oResponse.sResult.length > 0) {
                    categoryIdNm.current = oResponse.sResult[0].id;
                    setCategory(parseInt(oResponse.sResult[0].id));
                    getMenuList(parseInt(oResponse.sResult[0].id));
                }
            }
            setLoading(false);
        } catch (err) {
            console.log("err-->", err);
        }
    }

    const modalOpen = {
        content: (
            <ModalCategoryPage sProps={oProps} />
        )
    };

    const modalOpenGuide = {
        width: "50vw",
        content: (
            <GuideProductList sProps={oProps} />
        )
    };

    useEffect(() => {
        categoryList();
    }, [])

    return (
        <Layout sProps={oProps} routeList={layoutHeaderLists.product}>
            <Fragment>
                <BrowserView>
                    <Style>
                        {iStatus !== "edit" ?
                            <InsertPage
                                iProps={oProps}
                                iCategoryList={sCategoryList}
                                fAddList={(sIndex) => addList(sIndex)}
                                ref={insertPageRef}
                            />
                            :
                            <EditPage
                                iProps={oProps}
                                iDetail={sDetail}
                                iCategoryList={sCategoryList}
                                fnChangeNormal={() => changeNormal()}
                                fnEdit={(sIndex) => editList(sIndex)}
                            />
                        }
                        <div className="container">
                            <div className="category_box">
                                <p>상품분류</p>
                                <select value={selectCategory} onChange={onChangeProductValue}>
                                    {sCategoryList.map((item, index) => {
                                        return (
                                            <option key={index} value={item.id} selected={item.id === parseInt(selectCategory)}>
                                                {item.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {isLoading ?
                                <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                                </div>
                                :
                                <div className="table_wrapper">
                                    <ReactDragListView.DragColumn
                                        onDragEnd={dragProps}
                                        handleSelector="a"
                                    >
                                        <Table columns={sProductColumns} dataSource={sProductList} pagination={false} />
                                    </ReactDragListView.DragColumn>
                                    <div className="location_box_line" style={{ marginTop: "10px" }}>
                                        <div className="how_box">
                                            <p>순서를 변경하고 싶으시다면 순서의 숫자를 잡고 드래그를 해주세요!</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="side_help_content">
                            <div className="app_modal_container" onClick={() => modal.success(modalOpen)}>
                                <div className="arrow_box">
                                    <img src={guide_arrow} alt="none" style={{ width: '5px' }} />
                                </div>
                                <div className="text_box">
                                    <div className="title_box">
                                        <img src={addressSearch} alt="none" style={{ width: '18px' }} />
                                        <p>앱 미리보기</p>
                                    </div>
                                    <div className="detail_box_line">
                                        <p>내 입력정보의 예상화면을 <br />미리 확인해보세요</p>
                                    </div>
                                </div>
                            </div>
                            <div className="app_guide_modal_container" onClick={() => modal.success(modalOpenGuide)}>
                                <div className="arrow_box">
                                    <img src={guide_arrow} alt="none" style={{ width: '5px' }} />
                                </div>
                                <div className="text_box">
                                    <div className="title_box">
                                        <img src={guide_question} alt="none" style={{ width: '18px' }} />
                                        <p>간편도움말</p>
                                    </div>
                                    <div className="detail_box_line">
                                        <p>입력예시를 통해 <br />입력방법을 알려드립니다.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="throo_only_modal_container">
                                <div className="arrow_box">
                                    <img src={guide_arrow} alt="none" style={{ width: '5px' }} />
                                </div>
                                <div className="text_box">
                                    <div className="title_box">
                                        <img src={only} alt="none" />
                                        <p>스루온리란?</p>
                                    </div>
                                    <div className="detail_box_line">
                                        <p>
                                            우리 매장만의 드라이브 스루
                                            <br />
                                            전용 상품을 등록하세요.
                                            <br />
                                            다양한 광고효과와
                                            <br />
                                            매출상승 효과를 기대할 수 있습니다.
                                            <br />
                                            (드라이브 스루 전용 패키지,
                                            <br />
                                            '스루'에만 있는 구성 등)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {contextHolder}
                    </Style>
                </BrowserView>
                <MobileView>
                    <Mstyle>
                        {iStatus !== "edit" ?
                            <InsertPage
                                iProps={oProps}
                                iCategoryList={sCategoryList}
                                fAddList={(sIndex) => addList(sIndex)}
                                ref={insertPageRef}
                            />
                            :
                            <EditPage
                                iProps={oProps}
                                iDetail={sDetail}
                                iCategoryList={sCategoryList}
                                fnChangeNormal={() => changeNormal()}
                                fnEdit={(sIndex) => editList(sIndex)}
                            />
                        }

                        <div className="category_line_box">
                            <select value={selectCategory} onChange={onChangeProductValue} className="select_bank">
                                {sCategoryList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id} selected={item.id === parseInt(selectCategory)}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="container">
                            {isLoading ?
                                <div style={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                                    <Loader type="BallTriangle" color="#13166b" height={80} width={80} timeout={9000000} />
                                </div>
                                :
                                <>
                                    {
                                        sProductList.map((item, index) => {
                                            return (
                                                <div key={"listing-" + index.toString()} className="category_content_line">
                                                    <div className="table_box">
                                                        <div className="table_content">
                                                            <div className="image_box">
                                                                <img src={noneImageBox} alt="no_image" />
                                                            </div>
                                                            <div>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <p>{item.name}</p>
                                                                    {item.productType === 1 && <span style={{ background: '#E8EFFC', border: '1px solid #6490E7', color: '#6490e7', fontSize: '9px', marginLeft: '5px', padding: '2px 6px', borderRadius: '4px' }}>세트 상품</span>}
                                                                </div>
                                                                <p className="main_title">{item.soldOut}</p>
                                                            </div>
                                                        </div>
                                                        <div className='change_order' onClick={() => ChangeRowMob(index, -1)}>
                                                            <ArrowUpOutlined />
                                                        </div>
                                                        <div className='change_order' onClick={() => ChangeRowMob(index, 1)}>
                                                            <ArrowDownOutlined />
                                                        </div>
                                                        <div className="table_action">
                                                            <div className="edit_box" onClick={() => openEditPage(item)}>
                                                                <p>수정</p>
                                                            </div>
                                                            <div className="delete_box" onClick={() => showModal(item.id)}>
                                                                <p>삭제</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }
                        </div>

                        <div className="app_modal_container">
                            <div className="app_modal" onClick={() => modal.success(modalOpen)}>
                                <p>앱 미리보기</p>
                                <img src={searchBlack} alt="search" style={{ marginLeft: "5%" }} />
                            </div>
                            <p className="app_modal_detail">어떻게 보이는지 미리 확인할 수 있습니다.</p>
                        </div>
                        <Modal
                            title="주의사항"
                            visible={visible}
                            onOk={deleteRow}
                            onCancel={() => setVisible(false)}
                            okText="네"
                            cancelText="아니오"
                        >
                            <p>이 상품을 삭제하시겠습니까?</p>
                        </Modal>
                        {contextHolder}                        {contextHolder}
                    </Mstyle>
                </MobileView>

            </Fragment>
        </Layout>
    )
}

export default Product;

const Style = styled.div`
    position: relative;
    width: 42vw;
    
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Noto Sans KR', sans-serif;
    }  
    
    .app_guide__container {
        position: absolute;
        top: 5px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .text_box {
            width: 45px;
            height: 10px;
            
            .title_box {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                background: #EF4452;
                border-radius: 5px;

                p {
                    font-size: 0.7rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
    }

    .wrapper {
        background-color: #F1F3F7;
        padding-top: 6%;
        padding-left: 15%;
        padding-bottom: 2.2%;
    }

    .menu_container {
        margin-top: 10px;
        border-radius: 4px;

        .tab {
            margin-bottom: 24px;
            width: 42vw; 
            display: flex;
            align-items: center;
            height: 8vh;
            background-color: #fff;
            border-radius: 4px;
            box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        }

        .scroll_menu {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 400;
            color: #666;
        }

        .scroll_menu_active {
            border-bottom: 1px solid #000;
            display: inline-block;
            text-align: center;
            text-decoration: none;
            margin: 5%;
            cursor: pointer;
            *display:inline;
            *zoom:1;
            vertical-align:top;
            font-size: 0.9rem;
            font-weight: 500;
            color: #000;
        }

        .tab {
            -ms-overflow-style: none; 
            scrollbar-width: none; 
        }
        .tab::-webkit-scrollbar {
            display: none; 
        }
    }

    .delete_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3vw;
        height: 3vh;
        border: 1px solid #DD1212;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #DD1212;
        }
    }

    .detail_box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3vw;
        height: 3vh;
        background:  #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.8rem;
            font-weight: 500;
            color: #fff;
        }
    }

    .location_box_line {
        align-items: center;
        margin-bottom: 50px;
        height: 50px;
        .how_box {
            display: flex;
            align-items: center;
            height: 50px;
            background: #f1f3f7;
            border-radius: 4px;

            p {
                margin-left: 3%;
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
            }
        }
    }

    /* 스루온리 */
    .throo_only_wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        cursor:pointer ;

        .left,.right {
            padding: 10px 0;
            flex: 0 0 48%;
            text-align: center;
            border-radius: 5px;
        }


    }

    /* 앱미리보기/간편도움말/스루온리 공통css */
    .side_help_content {
        position: absolute;
        left: 103%;
        top: 0;
    }
    .app_guide_modal_container, .app_modal_container, .throo_only_modal_container {
        padding: 13px 13px 40px 13px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        background: #FBFBFB;
        border: 1px solid #DFDFE3;
        border-radius: 8px;

        .text_box {
            margin-left: 5px;
            min-width: 220px;

            .title_box {
                height: auto;
                margin-bottom: 9px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                p {
                    margin-left: 8px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #617BE3;
                }
            }
            .detail_box_line {
                p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 21px;
                }
            }
        }
    }
    

    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }


    .option_title {
        margin-top: 20px;
        
        .selected_option_box {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 50px;
            margin-top: 10px;
            border: 1px solid #BBBBBB;
            border-radius: 5px;

            p {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
        .option_select_box {
            margin-top: 20px;
            display: flex;
            align-items: center;
            height: 30px;
            cursor: pointer;

            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #617BE3;
            }
        }

    }
    

    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        h2 {
            font-size: 1rem;
            font-weight: 700;
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 5vw;
            border: 1px solid #DD1212;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #DD1212;
            }
        }

        .new_category_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 5vw;
            background: #617BE3;
            border-radius: 5px;
            cursor: pointer;
            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box:hover {
                border: none;
                background: #DD1212;
                p {
                    color: #fff;
                }
            }
    }

    .table_wrapper {
        margin-top: 10%;
    }
    .option_table_wrapper {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .insert_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        height: 50px;
        background: #617BE3;
        border-radius: 5px;
        cursor: pointer;

        p {
            font-size: 0.9rem;
            font-weight: 700;
            color: #fff;
        }
    }

    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 9vh;
            }

        }
        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9vh;
            width: 5vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 5vw;
                height: 5vh;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 5vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 5vh;
                width: 5vw;
                border-radius: 4px;
                cursor: not-allowed;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .category_box {
        height: 7vh;
        margin-bottom: 5%;

        select {
            margin-top: 2%;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
    }

    .category_price_line {
        position: "relative";
        margin-bottom: 0;

        

        .discount_detail_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 10px 0;
            padding: 10px 4px;
            background: #F1F3F7;
            border-radius: 5px;
             
            p,span {
                margin-left: 15px;
                color: #000;
                font-size: 11.5px;
                font-weight: 500;
            }
            span {
                color: #DD1212;
            }
            
        }
        .product_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 0;
        }
        .product_discount_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 25%;
        }

        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
            
        }

        .classDiscountPrice {
            background: #F1F3F7;
            border: 1px solid #617BE3;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 1px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .discount_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right:0;
            background: #001E62;
            padding: 11px;
            width: 8vw;

            .discount_text {
                font-size: 16px;
                font-weight: bold;
                color: #fff; 
            }

            p {
                font-size: 10px;
                font-weight: normal;
                color: #dfdfe3; 
            }

        }
    }

    .category_title {
        margin-bottom: 30px;
        input {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .address input {
            width: 90%;
        }
        .address_button {
            cursor: pointer;
            margin-top: 2%;
            width: 12%;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 1px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
        select {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
        select::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        textarea {
            margin-top: 12px;
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }
    
    

    .category_picture {
        margin: 5% 0;

        .address {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .address input {
            width: 90%;
        }
        .address_button {
            cursor: pointer;
            margin-top: 2%;
            width: 12%;
        }
        
    }

    .container {
        width: 42vw; 
        padding: 32px;
        margin-bottom: 1px;
        background-color: #fff;
        box-shadow: 2px 2px 6px 0 rgba(0,0,0,0.15);
        border-radius: 4px;
    }

`



const Mstyle = styled.div`
    .spc-between {
        justify-content: space-between;
        flex-direction: row;
        display: flex;
    }
    .app_modal_container {
        margin-top: 8vh;
        height: 14vh;

        .app_modal {
            margin-left: 32%;
            margin-right: 32%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 6vh;
            background: #fff;
            border-radius: 4px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #617BE3;
            }
        }
        .app_modal_detail {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            line-height: 21px;
            font-weight: 400;
            color: #666666;
        }
    }

    span {
        font-size: 0.8rem;
        font-weight: 400;
        color: #BBBBBB;
    }

    .option_table_wrapper {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .category_title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4vh;

            .title_line {
                font-size: 1rem;
                font-weight: 700;
            }

            .detail_line {
                margin-left: 5px;
                font-size: 0.6rem;
                font-weight: 700;
                color: #617BE3;
            }

        }

        .insert_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            background: #617BE3;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }

        .cancel_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 87px;
            height: 30px;
            border: 1px solid #DD1212;
            border-radius: 5px;

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #DD1212
            }
        }

    }
    .category_line_box {
        padding: 10px 16px;
        background-color: #617BE3;

        .select_bank {
            padding: 6px;
            width: 100%;
            background: #fff;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }
    }

    .category_title_line, .category_content_line {
        padding: 20px 16px;
        background-color: #fff;
        height:auto;

        h2 {
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .category_content_line {
        margin: 0;
        margin-bottom: 1px;
    }

    .table_box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .change_order {
            path {
                fill: black!important
            }
            width: 10%;
            display:flex;
            justify-content:center
        }
        .table_content {
            display: flex;
            align-items: center;
            width: 65%;
            height: 7vh;

            .image_box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15vw;
                height: 8vh;
                background: #F1F3F7;
                border-radius: 5px;
                margin-right: 5%;

                img {
                    height: 7vh;
                    width: 10vw;
                }
            }

            .main_title {
                font-size: 0.7rem;
                font-weight: 400;
                color: #666666;
                height: 3vh;
            }
            p {
                font-size: 0.8rem;
                font-weight: 700;
                height: 3.5vh;
            }
        }

        .table_action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 6vh;
            width: 35%;
            .edit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #617BE3;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #DD1212;
                border-radius: 5px;
                height: 6vh;
                width: 45%;
            }

            p {
                font-size: 0.9rem;
                font-weight: 700;
                color: #fff;
            }
        }
    }
    .category_price_line {
        position: "relative";

        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }

        .classDiscountPrice {
            background: #F1F3F7;
            border: 1px solid #617BE3;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_action_discount {
            border: solid 1px #617be3;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        .discount_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            right:0;
            background: #001E62;
            padding: 11px;
            width: 120px;

            .discount_text {
                font-size: 1rem;
                font-weight: bold;
                color: #fff; 
            }

            p {
                font-size: 0.7rem;
                font-weight: normal;
                color: #dfdfe3; 
            }

        }
        .discount_detail_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 10px 0;
            padding: 10px 4px;
            background: #F1F3F7;
            border-radius: 5px;
             
            p,span {
                margin-left: 15px;
                color: #000;
                font-size: 11.5px;
                font-weight: 500;
            }
            span {
                color: #DD1212;
            }
            
        }

        .product_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 0;
        }
        .product_discount_price_box {
            position: absolute;
            padding: 12px;
            top: 0;
            right: 35%;
        }
        input {
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
            padding:12px 12px 12px 12px;
            
        }
    }

    .inputText {
        h3 {
            margin-top: 16px;
            margin-bottom: 8px;
            font-size: 0.9rem;
            font-weight: 400;
        }
        .throo_only {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .left {
                display: flex;
                height: 7vh;
                width: 50%;
                border-radius: 4px;
                outline: none;
                margin-right: 2%;
                align-items: center;
                justify-content: center;
            }
            .right {
                display: flex;
                width: 50%;
                height: 7vh;
                border-radius: 4px;
                outline: none;
                justify-content: center;
                align-items: center;
            }

             p {
                font-size: 0.9rem;
                font-weight: 400;
                color: #BBBBBB;
            }
        }
        input {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .select_bank {
            padding: 6px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }

        .select_bank::placeholder {
            font-size: 0.9rem;
            font-weight: 400;
            color: #BBBBBB;
        }

        .address {
            position: relative;
            margin-bottom: 8px;
        }
        .address input {
            width: 100%;
            position: absolute;
        }
        .address button {
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            position: relative;
            top: 0;
            bottom: 0;
            right: 0%;
            border: 0;
            outline: 0;
        }

        textarea {
            padding: 12px;
            width: 100%;
            border: 1px solid #BBBBBB;
            border-radius: 4px;
            outline: none;
        }
    }
    
    .last {
        width: 100%;
        padding: 24px 0px 32px 0px;
        background: #fff;

        .finishButoon {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }  
    .bottom_fixed {
        width: 100%;
        position: sticky;
        bottom: 15px;
        .finishButton {
            width: 100%;
            padding: 12px 0;
            background-color: #617be3;
            font-size: 1rem;
            font-weight: 700;
            color: #fff;
            border-radius: 4px;
            border: none;
            outline: none;
            z-index:10;
        }
    }  
    .image_line {
        display: flex;
        align-items: center;
        margin-top: 2%;

        .image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 11vh;
            width: 22vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 7vw;
                height: 12vh;
            }
        }

        .none_image_box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 11vh;
            width: 22vw;
            background: #F1F3F7;
            border-radius: 4px;

            img {
                border-radius: 4px;
                height: 11vh;
                width: 22vw;
            }
        }

        

        .image_box_button {
            display: flex;
            align-items: center;
            margin-left: 5%;

            .edit_image {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 6vh;
                width: 20vw;
                background: #617BE3;
                margin-right: 5%;
                border-radius: 4px;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
            .delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #DD1212;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #DD1212;
                }
            }

            .un_delete_image {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #BBBBBB;
                height: 6vh;
                width: 20vw;
                border-radius: 4px;
                cursor: pointer;

                p {
                    font-size: 0.9rem;
                    font-weight: 700;
                    color: #BBBBBB;
                }
            }


        }
    }

    .filebox label { display: inline-block; padding: .5em .75em; color: #fff; font-size: inherit; line-height: normal; vertical-align: middle; background-color: #fff; cursor: pointer; }
    .filebox input[type="file"] { position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip:rect(0,0,0,0); border: 0; }

    .option_title {
        margin-top: 3%;

        .selected_option_box {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 5vh;
            margin-top: 1%;
            border: 1px solid #BBBBBB;
            border-radius: 5px;

            p {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
        .option_select_box {
            margin-top: 20px;
            display: flex;
            align-items: center;
            height: 3vh;
            cursor: pointer;

            p {
                font-size: 0.8rem;
                font-weight: 700;
                color: #617BE3;
            }
        }

    }
    
`


