import React from 'react';
import styled from 'styled-components';

import est01 from '../../../../assets/img/guide/est/est01.png';
import est02 from '../../../../assets/img/guide/est/est02.png';
import est03 from '../../../../assets/img/guide/est/est03.png';
import est04 from '../../../../assets/img/guide/est/est04.png';
import est05 from '../../../../assets/img/guide/est/est05.png';
import est06 from '../../../../assets/img/guide/est/est06.png';
import est07 from '../../../../assets/img/guide/est/est07.png';
import est08 from '../../../../assets/img/guide/est/est08.png';
import est09 from '../../../../assets/img/guide/est/est09.png';

const EstGuide = (oProps) => {

    return (
        <M_estGuide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>스루 가이드</h3>
                        <h1>POS 설치 후 실행이 안 돼요 (알약 버전)</h1>
                        <p>최근 백신 프로그램 업데이트 이후 스루 POS가 실행되지 않는 현상이 발생하고 있어요 😢<br />만약 매장 포스에 <span>알약 백신 프로그램</span>을 사용 중이시라면 아래 가이드를 단계별로 진행해 주세요.</p>
                        <h2><span>STEP 1</span><br />알약 환경설정을 클릭해 주세요.</h2>
                        <img src={est01} alt="알약환경설정이미지" width="100%" />
                        <h2><span>STEP 2</span><br />탐지 제외 메뉴에 들어가서 추가 버튼을 클릭해 주세요.</h2>
                        <img src={est02} alt="알약환경설정이미지" width="100%" />
                        <h2><span>STEP 3</span><br />제외 방법에 <span style={{ color: '#1A7CFF' }}>파일</span>과 <span style={{ color: '#1A7CFF' }}>경로</span>를 선택 후 찾아보기를 클릭해 주세요. 경로를 찾아 선택하고 추가 버튼을 클릭해 주세요.</h2>
                        <img src={est03} alt="알약환경설정이미지" width="100%" />
                        <p>✅ c:\User\(사용자 이름)\ThrooPOS<br />✅ c:\User\(사용자 이름)\내 문서\ThrooPOS<br /><br />두 개 중에 해당하는  <span style={{fontWeight:"900", color: '#212329'}}>파일 한 개</span>를 선택해 주세요</p>
                        <h2><span>STEP 4</span><br />목록에 해당 스루 파일이 있는지 확인 후 확인 버튼을 눌러주세요.</h2>
                        <img src={est04} alt="알약환경설정이미지" width="100%" />
                        <h2><span>STEP 5</span><br />윈도우 디펜더 [열기] 버튼을 더블클릭해 주세요.</h2>
                        <img src={est05} alt="알약환경설정이미지" width="100%" />
                        <h2><span>STEP 6</span><br />windows 보안 영역에서 바이러스 및 위협 방지를 클릭해 주세요.</h2>
                        <img src={est06} alt="알약환경설정이미지" width="100%" />
                        <h2><span>STEP 7</span><br />하단에 windows defender 바이러스 백신 옵션 클릭하여 주기적 검사를 <span style={{ color: '#F45452' }}>끔으로 변경</span>해 주세요.</h2>
                        <img src={est07} alt="알약환경설정이미지" width="100%" />
                        <h2><span>STEP 8</span><br />우측 카테고리에서 앱 및 브라우저 컨트롤 <span style={{color: '#212329'}}>&#8594;</span> 평판 기반 보호 설정을 클릭하여 노출되는 이미지에 선택란에 대하여 <span style={{ color: '#F45452' }}>끄기로 선택</span>해 주세요.</h2>
                        <img src={est08} alt="알약환경설정이미지" width="100%" style={{marginBottom:"10px"}} />
                        <img src={est09} alt="알약환경설정이미지" width="100%" />
                    </div>
                </div>
            </div>
        </M_estGuide>
    )
}

export default EstGuide;

export const M_estGuide = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 100%;
        padding: 0 5vw;
    }
    .pGuideCont {
        margin-top: 110px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;
        word-break: keep-all;

        span {
            color: #8E929F;
        }
    }
    h2 span:nth-child(3) {
        color: #F45452;
        font-weight: 900;
    }
    p {
        margin: 15px 0 25px 0;
        font-size: 16px;
        font-weight: 700;

        span {
            color: #F45452;
            font-weight: 900;
        }
    }
`