import React, { useState, useEffect } from 'react';
import { Steps, Transfer, Result, message } from "antd";
import styled from 'styled-components';
import Loader from "react-loader-spinner";
import "antd/dist/antd.css";

const { Step } = Steps;

export const ProductCopyPage = ({ iProps, fnCloseModal, sKey, iKey, sName, iName }) => {
    const [sLoading, setsLoading] = useState(true);
    const [rLoading, setRLoading] = useState(true);
    const [sNm, setNm] = useState(1);
    
    const [targetKeys, setTargetKeys] = useState([]);
    const [iList, setIList] = useState([]);
    const [nList, setNList] = useState({});
    
    const productChange = async (target) => {
        setTargetKeys(target);
    }

    const createProduct = async () => {
        let oResponse = null;
        if(targetKeys !== undefined && targetKeys !== null && targetKeys.length > 0){
            setNm(2);

            const checkId = iProps.UserConfigReducer.SalesGroupId;
            const oData = {
                sales_id : iProps.UserConfigReducer.SalesId,
                from : iKey,
                to : sKey,
                targetKeys,
                type: iProps.UserConfigReducer.SalesUserType,
            }

            if(checkId.toString() === "100"){
                oResponse = await iProps.oManager.fDoAxios("/sales/manager/productCopyToStore", "post", null, oData);
            } else {
                oResponse = await iProps.oManager.fDoAxios("/sales/productCopyToStore", "post", null, oData);
            }
            if(oResponse !== undefined && oResponse !== null) {
                if(oResponse.resultCd === "0000"){
                    let temp = {
                        total: oResponse.total,
                        insert: oResponse.insert,
                        fail: oResponse.fail,
                    }
                    setNList(temp);
                    setIList([]);
                    setRLoading(false);
                } else {
                    message.info("네트워크 에러입니다, 잠시 후 다시 시도바랍니디.");
                    setNm(1);
                }
            }
        }
    }
    
    const closeModal = async () => {
        if(fnCloseModal !== undefined && typeof closeModal === "function"){
            setsLoading(true);
            setRLoading(true);
            setNm(1);
            setTargetKeys([]);
            setIList([]);
            await fnCloseModal();
        }
    }

    const asyncData = async () => {
        if(sKey !== undefined && sKey !== null && iKey !== undefined && iKey !== null){
            const checkId = iProps.UserConfigReducer.SalesGroupId;
            const oData = {
                sales_id : iProps.UserConfigReducer.SalesId,
                sParam : iKey,
                type: iProps.UserConfigReducer.SalesUserType,
            }

            if(checkId.toString() === "100"){
                const oResponse = await iProps.oManager.fDoAxios("/sales/manager/getDesignateStoreProduct", "post", null, oData);
                if(oResponse !== undefined && oResponse !== null) setIList(oResponse);
            } else {
                const oResponse = await iProps.oManager.fDoAxios("/sales/getDesignateStoreProduct", "post", null, oData);
                if(oResponse !== undefined && oResponse !== null) setIList(oResponse);
            }

            setsLoading(false);
        } else {
            message.info("알수없는이유로 에러가 발생했습니다 관리자에 연락바랍니다.");
            closeModal();
        }
    }

    useEffect(() => {
        asyncData();
    }, [sKey,iKey]);

    const Step1 = () => {
        return (
            <>
            <div className="step_container">
                <div className="title_box">
                    <p className="input_current">{iName}</p>
                    <p className="output_current">{sName}</p>
                </div>
                <div className="tree_box">
                    {sLoading ?
                        <div style={{display: "flex", justifyContent: "center",alignItems: "center", height: 300}}>
                            <Loader type="BallTriangle" color="#13166b" height={50} width={50} timeout={9000000}/> 
                        </div>
                    :
                        <Transfer
                            className="tree_line"
                            dataSource={iList}
                            targetKeys={targetKeys}
                            onChange={productChange}
                            render={item => item.title}
                            showSelectAll={false}
                            pagination
                        />
                    }
                </div>
            </div>
            <div className="modal_footer_two">
                <div className="return_box" onClick={closeModal}>
                    <p>닫기</p>
                </div>
                <div className="next_box" onClick={createProduct}>
                    <p>완료</p>
                </div>
            </div>
            </>
        )
    }

    const Step2 = () => {
        return (
            <>
            <div className="tree_box">
                {rLoading ?
                    <div style={{display: "flex", justifyContent: "center",alignItems: "center", height: 300}}>
                        <p>상품을 저장중입니다....</p>
                        <Loader type="BallTriangle" color="#13166b" height={50} width={50} timeout={9000000}/> 
                    </div>
                :
                    <Result
                        status="success"
                        title="선택한 상품이 해당 매장에 저장되었습니다"
                        subTitle={`총 ${nList.total}개의 상품중 ${nList.insert}개의 상품을 저장하고 ${nList.fail}개의 상품을 저장에 실패하였습니다.`}
                    />
                }
            </div>
            <div className="modal_footer">
                <div className="button_box" onClick={closeModal}>
                    <p>닫기</p>
                </div>
            </div>
            </>
        )
    }

    return (
        <Style>
            <div className="option_group_list_box">
                <div className="eraser_box">
                    <Steps size="small" current={sNm}>
                        <Step title="매장선택" />
                        <Step title="상품선택" />
                        <Step title="복사완료" />
                    </Steps>
                </div>
                {sNm.toString() === "1" && <Step1 />}
                {sNm.toString() === "2" && <Step2 />}
            </div>
        </Style>
    )
};

const Style = styled.div`
    * {
        top: 5%;
    }

    .step_container {
        padding-top: 10px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .title_box {
        padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;

        .input_current {
            font-size: 0.8rem;
            font-weight: 700;
            color: #DD1212;
        }
        .output_current {
            font-size: 0.8rem;
            font-weight: 700;
            color: #617be3;
        }
    }

    .option_group_list_box {
        padding-top: 5%;
        height: 500px;
        width: 100%;
    }

    .store_list_box {
        width: 100%;
        height: 350px;
        margin-bottom: 10%;
        border: 1px solid #666666;
        position: "relative";
        overflow-y: scroll;
        overflow-x: hidden;

        .list_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 60px;
            border-bottom: 1px solid #dfdfdf;

            .store_box  {
                display: flex;
                align-items: center;
                width: 80%;
                height: 60px;
                padding-left: 20px;
                padding-top: 10px;
                
                .store_title {
                    padding: 0;
                    font-size: 0.9rem;
                    font-weight: 700;
                }
                
            }
            .store_button_box  {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%;
                height: 60px;
                padding-right: 10px; 

                .line {
                    padding : 10px;
                    width: 100%;
                    border: 1px solid #617be3;
                    border-radius: 5px;
                    height: 40px;
                    cursor: pointer;
                    p {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.8rem;
                        font-weight: 700;
                        color: #617be3;
                    }
                }

                .line:hover {
                    background: #617be3;
                    p {
                        color: #fff;
                    }
                }

            }
        }
    }

    .tree_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0%;
        padding-bottom: 80px;
        height: 460px;
        width: 100%;

        .tree_line {
            width: 100%;
            height: 400px;
        }
    }

    .store_list {
        margin-top: 30px;
        height: 440px;


        .search_box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;

            input {
                width: 78%;
                height: 44px;
                margin: 3px 0;
                padding: 12px 14px;
                border: 1px solid #000;
                font-size: 14px;
                font-weight: 400;
                outline: none;
            }

            .search_button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 3px 0;
                width: 20%;
                height: 44px;
                background: #13166b;
                border-radius: 5px;
                cursor: pointer;
                padding-top: 15px;

                p {
                    font-size: 0.8rem;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }

    }

    .modal_footer {
        bottom: 0;
        top: 540px;
        width: 100%;
        right: 0%;
        position: absolute;
        height: 60px;
        background: #fff;
        z-index: 1;
        border-top: 1px solid #dfdfdf;

        .button_box {
            display: flex;
            justify-content: center; 
            align-items: center;
            height: 60px;
            cursor: pointer;
            border-radius: 5px;
            padding-top: 20px;

            p {
                font-size: 1rem;
                font-weight: 500;
            }
        }
        
    }

    .modal_footer_two {
        bottom: 0;
        top: 540px;
        width: 100%;
        right: 0%;
        position: absolute;
        height: 60px;
        background: #fff;
        z-index: 1;
        display: flex;
        justify-content: space-between; 
        align-items: center;
        border-top: 1px solid #dfdfdf;

        .return_box {
            display: flex;
            justify-content: center; 
            align-items: center;
            height: 60px;
            width: 50%;
            cursor: pointer;
            padding-top: 20px;
            border-right: 1px solid #dfdfdf;

            p {
                font-size: 1rem;
                font-weight: 500;
                color: #DD1212;
            }
        }

        .next_box {
            display: flex;
            justify-content: center; 
            align-items: center;
            height: 60px;
            width: 50%;
            padding-top: 20px;
            cursor: pointer;

            p {
                font-size: 1rem;
                font-weight: 500;
                color: #617be3;
            }
        }
        
    }

    .eraser_box {
        width: 100%;
        position: absolute;
        background: #fff;
        height: 75px;
        right: 0;
        left: 0;
        top: 0;
        padding: 25px;
        display: flex;
        justify-content: center; 
        text-align: center;
        border-bottom: 1px solid #dfdfdf;

        p {
            font-size: 0.9rem;
            font-weight: 700;
        }
    }
    
`;