import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import '../../../assets/css/font.css';

import { WebRoute } from '../../../navigation/WebRoutes';

// SVG
import logo from "../../../assets/svg/mainPage/throoCeoLogo.svg";


const HeaderPage = ({ toggle }) => {

   const navigate = useNavigate();

   // 링크
   const openStoremanage = () => {
      let sDomainUrl = "https://ceo.throo.co.kr";
      if (process.env !== undefined && process.env.REACT_APP_CEO_WEBURL !== undefined) {
         sDomainUrl = process.env.REACT_APP_CEO_WEBURL;
      }
      window.open(sDomainUrl + '/selfmanage/dashboard', '_blank');
   }
   const moveToPath = async (sIndex) => {
      if (sIndex === "HOME") {
         navigate(WebRoute.HOME);
      }
   }

   return (
      <D_headerStyle>
         <div className="header">
            <div className="hContainer">
               <div className="heaerContent">
                  <SVG onClick={() => moveToPath("HOME")} src={logo} width="154px" height="24px" title="스루사장님창구로고" />
                  <ul className="menu">
                     <li onClick={openStoremanage} style={{ padding: '8px 16px' }}>매장관리</li>
                     <li><Link to={WebRoute.DOWNLOAD}>포스설치</Link></li>
                     <li><Link to={WebRoute.SERVICE}>고객센터</Link></li>
                     <li><Link to={WebRoute.LANDING}>스루소개</Link></li>
                     <li><Link to={{ pathname: WebRoute.CEOTIP, page_id: 'sw', search: 'sw' }}>이용요금</Link></li>
                     <li><Link to={WebRoute.JOINFORM} style={{ backgroundColor: '#1A7CFF', color: '#fff', padding: '12px 20px', borderRadius: '6px' }}>입점신청</Link></li>
                  </ul>
               </div>
            </div>
         </div>
      </D_headerStyle>
   )
}

export default HeaderPage;

export const D_headerStyle = styled.aside`

    /* reset */
    ul,ol,li {
        text-decoration: none;
        list-style: none;
    }

    html,
    body {
        display: block;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    p, h1, h2, h3, h4, h5, h6, li button, textarea {
        transform: skew(-0.1deg);
        color: #333;
        font-family: 'NanumSquare';
    }
    a {
        font-family: 'NanumSquare';
        text-decoration: none;
        color: #333;
        transform: skew(-0.1deg);
        font-weight: 900;
        padding: 8px 12px;
    }
    
    /* container */
    .hContainer {
        max-width: 1120px;
        height: 60px;
        padding: 0 40px;
        margin: 0 auto;
    }

    /* header */
    .header {
        position: fixed;
        max-width: 100%;
        min-width: 100%;
        height: 60px;
        background-color: #fff;
        top: 0;
        border-bottom: 1px solid rgba(238, 238, 238, .3);
        z-index: 10;
    }
    .heaerContent {
        max-width: 100%;
        min-width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ul {
        margin: 0;
        height: 60px;
        display: flex;
        align-items: center;
    }
    .menu li {
        font-family: 'NanumSquare';
        margin-left: 28px;
        font-size: 16px;
        font-weight: 900;
        color: #333;
        padding: 8px 4px;
        cursor: pointer;
        transform: skew(-0.1deg);
    }
    .menu li:hover {
        background-color: rgba(196, 196, 196, .5);
        border-radius: 6px;
    }
    .menu li:last-child:hover {
        background-color: #fff;
    }

    svg {
        cursor: pointer;
    }
`