import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import closeIcon from "../../../assets/svg/closeIcon.svg";
import arrow_right from '../../../assets/svg/arrow_right.svg';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { WebRoute } from '../../../navigation/WebRoutes';
import manual from '../../../assets/svg/exclamation_mark.svg';
import rightArrow from '../../../assets/svg/arrow_right.svg';
import { message, Switch, Modal } from "antd";
import { MobileWelcomePage } from '../../modal/AppModal';

const Side = ({ isOpen, toggle, sProps, iStoreUnReviewCount, activeStatus, bManualPaused, fOperateStore, fChangeStoreCloseStatus }) => {
   const [unActivate, setUnActivate] = useState(true);
   const [tip_un_activate, set_tip_un_activate] = useState('true');
   const [modal, contextHolder] = Modal.useModal();
   let oStartStoreModal;

   const location = useLocation()
   const navigate = useNavigate()

   // 영업시작 안내문구
   const tip_click = () => {
      set_tip_un_activate('false');
   }

   const tip_open_click = () => {
      set_tip_un_activate('true');
   }

   const operationFinished = async () => {
      oStartStoreModal = modal.success(modalOpen);

      /*
      // Create test order
      let iStoreId = parseInt(sProps.UserConfigReducer.StoreID);
      if (iStoreId > 0) {
         await sProps.oManager.fDoAxios("/store/order/createTestOrder", "post", null, { store_id: iStoreId });
      }
      */
   }

   const goLogOut = async () => {
      let oUserConfig = {};

      oUserConfig['isPOS'] = false;
      oUserConfig['AutoLogin'] = false;
      oUserConfig['LoggedIn'] = false;
      oUserConfig['Token'] = "";
      oUserConfig['RefreshToken'] = "";
      oUserConfig['UUID'] = "";

      if (!sProps.UserConfigReducer.RememberId) {
         oUserConfig['UserId'] = "";
      }
      oUserConfig['UserKey'] = "";

      oUserConfig['StoreID'] = 0;
      oUserConfig['StoreName'] = "";
      oUserConfig['StorePhone'] = "";
      oUserConfig['StoreOwner'] = "";
      oUserConfig['Address'] = "";
      oUserConfig['StoreLat'] = "";
      oUserConfig['StoreLng'] = "";

      oUserConfig['accessible'] = "inaccessible";
      oUserConfig['LoginTime'] = "";

      await sProps.reduxSetUserConfig(oUserConfig);
      if (sProps.UserConfigReducer.SalesLoggedIn) {
         navigate(WebRoute.ADMIN_STORE_LIST)
      }

   }

   const updateAccessible = async () => {
      let oUserConfig = {};
      oUserConfig['accessible'] = "accessible";

      await sProps.reduxSetUserConfig(oUserConfig);
      setUnActivate(false);
   }

   const modalOpen = {
      content: (
         <MobileWelcomePage />
      ),
      onOk() {
         oStartStoreModal.destroy();
         updateAccessible()
      }
   };

   useEffect(() => {
      // const sParam = iProps.UserConfigReducer.StoreID;
      // const oResponse = await iProps.oManager.fDoAxios("/store/v2/currentStatus-" + sParam, "get", null, null);
      // if (oResponse !== undefined && oResponse !== null) setActiveStatus(oResponse);
      sProps.UserConfigReducer.accessible === "inaccessible" ? setUnActivate(true) : setUnActivate(false);
   }, []);

   return (
      <SidebarContainer isOpen={isOpen} >
         {/* <Icon onClick={goDashBoard}>
                
            </Icon> */}

         <SidebarMenu >
            <div className="side">
               {/* 영업시작 */}
               <div className="store_open">
                  <div className="ceo_name">
                     <Link to={WebRoute.ADMIN_BUSINESS}>
                        <p className='name'><span>{sProps.UserConfigReducer.StoreOwner}</span>님<SVG src={rightArrow} width="20px" title="오른쪽 화살표" /></p>
                     </Link>
                     <SVG className="closeIcon" src={closeIcon} width="16px" height="16px" title="closeIcon" onClick={toggle} />
                  </div>
                  <div className="open_store_content">
                     {unActivate ?
                        <div className='open_wrap'>
                           <div className="button_wrap">
                              <button className='open_store_button' onClick={() => { fOperateStore(operationFinished); }}>스루 시작하기</button>
                              <SVG onClick={tip_open_click} src={manual} width="23px" height="23px" />
                           </div>
                           {/* {tip_un_activate === 'true' &&
                              <div className="tip" onClick={tip_click}>
                                 <p>영업에 필요한 ‘필수’ 정보를 모두<br />입력해야  ‘영업시작’ 버튼이 활성화 됩니다</p>
                              </div>
                           } */}
                        </div>
                        :
                        <div className='switch_button_wrap'>
                           <div className="box" style={{ backgroundColor: !activeStatus ? "#7D7D7D" : "#6490E7" }}>
                              <p>{!activeStatus ? "준비중" : "영업중"}</p>
                              {/* {activeStatus === false && bManualPaused === false &&
                                 <div className="tip_text" style={{ minWidth: 140 }}>
                                    <p>주문 프로그램에서 로그인이 되어야<br />정상영업이 가능합니다</p>
                                 </div>
                              }
                              {bManualPaused === true &&
                                 <div className="tip_text" style={{ minWidth: 140 }}>
                                    <p>일시정지를 해제해야<br />정상 영업이 가능합니다</p>
                                 </div>
                              } */}
                           </div>
                           <div className="switchBox">
                              <Switch checked={activeStatus} onChange={fChangeStoreCloseStatus} />
                           </div>
                        </div>
                     }
                  </div>
               </div>
               <Link to={WebRoute.ADMIN_REVIEW}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_REVIEW) >= 0 ? "activate_line beta" : "unactivate_line beta"} >
                     <h2>리뷰관리</h2>
                     <div className="beta_box_container">
                        <div className="beta_box">
                           <p>Beta</p>
                        </div>
                     </div>
                     {/* 리뷰갯수 */}
                     <div className="review_count">
                        {iStoreUnReviewCount}
                     </div>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_COMMERCIAL}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_COMMERCIAL) >= 0 ? "activate_line beta" : "unactivate_line beta"} >
                     <h2>광고관리</h2>
                     <div className="beta_box_container">
                        <div className="beta_box">
                           <p>Beta</p>
                        </div>
                     </div>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_SALES_DASHBOARD}>
                  <>
                     <div className={location.pathname.indexOf(WebRoute.ADMIN_SALES_DASHBOARD) >= 0 ? "activate_line" : "unactivate_line"} >
                        <h2>홈</h2>
                        <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                     </div>

                  </>
               </Link>
               <Link to={WebRoute.ADMIN_STORE_INFO}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_STORE_INFO) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>매장관리</h2>
                     {sProps.UserConfigReducer.accessible === "inaccessible" &&
                        <div className="app_guide__container">
                           <div className="title_box">
                              <p>필수!</p>
                           </div>
                        </div>
                     }
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_PRODUCT_CATEGORY}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_PRODUCT_CATEGORY) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>상품관리</h2>
                     {sProps.UserConfigReducer.accessible === "inaccessible" &&
                        <div className="app_guide__container">
                           <div className="title_box">
                              <p>필수!</p>
                           </div>
                        </div>
                     }
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_BUSINESS}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_BUSINESS) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>사장님정보</h2>
                     {sProps.UserConfigReducer.accessible === "inaccessible" &&
                        <div className="app_guide__container">
                           <div className="title_box">
                              <p>필수!</p>
                           </div>
                        </div>
                     }
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_PAYMENT}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_PAYMENT) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>매출관리</h2>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_INVENTORY}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_INVENTORY) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>재고관리</h2>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_COUPON}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_COUPON) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>쿠폰관리</h2>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_STAMP}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_STAMP) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>스탬프관리</h2>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_GUIDE_POS}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_GUIDE) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>사용자 가이드</h2>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <Link to={WebRoute.ADMIN_NOTICE}>
                  <div className={location.pathname.indexOf(WebRoute.ADMIN_NOTICE) >= 0 ? "activate_line" : "unactivate_line"} >
                     <h2>공지사항</h2>
                     <SVG src={arrow_right} width="18px" height="18px" title="더보기 화살표" />
                  </div>
               </Link>
               <div className="unactivate_line" onClick={() => goLogOut()}>
                  <h2>로그아웃</h2>
               </div>
            </div>
            {contextHolder}
         </SidebarMenu>
      </SidebarContainer>
   )
}

export default Side;

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    height: 100%;
    background: #fff;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
    width:100%;

`;


export const Icon = styled.div`
    width: 100%;

    .topContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        padding: 16px;
        border-bottom: 1px solid #DFDFDF;

        .closeIcon path {
            fill: #12171d;
        }
    }
`;

export const SidebarMenu = styled.ul`
    /* 공통 css */
    *, h1, h2, h3, p, button, ul, li, a {
        font-family: 'Pretendard';
    }
    
    /* side */
    .side {
        height: calc(var(--vh, 1vh) * 100); 
        width: 100%;
        overflow-y: scroll;

        @media screen and (max-width: 480px) {
            grid-template-rows: repeat(6, 60px);
        }
    }

    /* store_open */
    .store_open {
        padding: 20px 24px;

        .ceo_name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 14px;

            .name {
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;

                span {
                    font-weight: 600;
                }
            }
        }
    }
    /* 스루 시작하기 버튼(필수 입력 전) */
    .open_wrap{
        position: relative;
        bottom: 0;
        left: 0;
    }
    .button_wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        .open_store_button {
            padding: 4px 12px;
            border-radius: 8px;
            background: #7D7D7D;
            color: #fff;
            border: none;
            outline: none;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        } 
        svg {
            margin-left: 4px;
        }
    }
    .tip {
        position: absolute;
        max-width: 265px;
        padding: 8px 16px;
        margin-top: 5px;
        border-radius: 8px;
        background-color: #262626;
        z-index: 999;

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: #fff;
        }
    }
    /* 매장 영업 on/off 버튼(필수 입력 후) */
    .switch_button_wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        .box {
            padding: 2px 10px;
            border-radius: 8px;

            p {
                font-size: 16px; 
                font-weight: 600;
                color: #fff;
            }
        }

        .switchBox {
            margin-left: 8px;
        }
    }

    .tip_text {
      position: absolute;
      margin-top: 8px;
      padding: 8px 16px;
      top: 100%;
      left: 0px;
      background: #000;
      border: 1px solid #262626;
      border-radius: 8px;
      cursor: pointer;
      z-index: 10;

      p {
         color: #fff;
      }
      
   }

    /* 리뷰관리, 광고관리 Beta */
    .activate_line.beta, .unactivate_line.beta {
        background: #0A1D42;
        display: flex;
        justify-content: flex-start;

        h2 {
            padding:0;
            margin:0;
            color: #fff;
        }
        .beta_box_container {
            padding: 0;
            margin: 0;
        }
        .beta_box p{
            margin: 0 4px;
            font-size: 13px;
            font-weight: 400;
            color: #6490E7;
        }
        SVG {
            margin-left: auto;
            path {
                fill : #fff;
            }
        }
    }
    .activate_line.beta {
        background: #050F21;
        
        h2 {
            color: #6490E7;
        }
        SVG {
            path {
                fill : #6490E7;
            }
        }
    }
    .review_count {
        margin-left: 4px;
        padding: 2px 9px;
        background: #617be3;
        border-radius: 99px;
        line-height: 24px;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
    }

    /* Bata제외한 나머지 탭 */
    .activate_line {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 14px 24px;
        background-color: #F4F6FA;
        border: none;
        outline: none;
        cursor: pointer;

        h2 {
            margin-right: auto;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            color : #1F57C7;
        }
        svg path {
            fill : #1F57C7;
            z-index:999;
        }   
    }
    .unactivate_line {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 14px 24px;
        background-color: #fff;
        border: none;
        outline: none;
        cursor: pointer;

        &:hover {
            h2 {
                color : #617be3;
            }
            svg path {
                fill : #617be3;
            }
        }

        h2 {
            margin-right: auto;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }
    }

    /* 필수표시 */
    .app_guide__container {
        margin-right: 12px;
        
        .title_box {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3px 6px;
            background: #F5F5F5;
            border: 1px solid #DFDFE3;
            border-radius: 4px;

            p {
                font-size: 12px;
                font-weight: 400 ;
                color: #EF4643;
            }
        }
    }
`;