import React from 'react';
import styled from 'styled-components';

// 이미지
import now01 from '../../../../assets/img/guide/now01.png';
import now02 from '../../../../assets/img/guide/now02.png';


const OnlyGuide = (oProps) => {

    return (
        <D_onlyGuide>
            <div className="ceoTip">
                <div className="container">
                    <div className="cTip">
                        <h3>스루 가이드</h3>
                        <h1>스루 NOW</h1>
                        <p>
                            고객이 주문 후 대기 없이 바로 받아 갈 수 있는 서비스 스루 NOW! <br />여유로운 시간에 준비 시간을 줄여 고객을 사로잡아보세요. 준비 시간을 짧게 잡을수록 더 많은 고객에게 스루 NOW가 표시됩니다.
                        </p>
                        <div className="tip">
                            <h2><span>TIP 01</span><br />스루 NOW 어떻게 노출되나요?</h2>
                            <img src={now01} alt="스루only이용방법 이미지" width="100%" />
                            <p>앱 지도상 매장명 혹은 아이콘 옆에 스루 나우 뱃지가 표시되요.</p>
                        </div>
                        <div className="tip">
                            <h2><span>TIP 02</span><br />스루 NOW 매장이 되려면 어떻게 해야하나요?</h2>
                            <p>
                                고객 도착 소요 시간보다 매장 상품 준비 시간이 1분보다 작거나 같을 경우 스루 NOW로 표시됩니다.<br />
                                즉, 상품 준비 시간이 짧을수록 더 많은 고객에게 스루 NOW로 노출됩니다.
                            </p>
                            <img src={now02} alt="스루only이용장점 이미지" width="100%" />
                        </div>
                        <div className="tip">
                            <h2><span>TIP 03</span><br />어떻게 응대해야 하나요?</h2>
                            <p>주문 수락 후 고객 도착예정 시간 전에 상품 준비를 완료해 주세요</p>
                        </div>
                        <p style={{ marginTop: "34px" }}>😊 준비 시간을 줄일수록 주문은 UP!<br />사장님 스루 NOW를 이용해 주문을 올려보세요.</p>
                    </div>
                </div>
            </div>
        </D_onlyGuide>
    )
}

export default OnlyGuide;
export const D_onlyGuide = styled.aside`
    p, a, h1, h2, h3, h4, h5, h6, button, input, textarea {
        transform: skew(-0.1deg);
        color: #212329;
        font-family: 'NanumSquare';
    }

    html,
    body {
        margin: 0;
        padding: 0;
    }
    .container {
        width: 702px;
        margin: 0 auto;
    }
    .cTip {
        margin-top: 106px;
        margin-bottom: 80px;

        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #888;
        }
        h1 {
            margin: 14px 0 16px 0;
            font-size: 32px;
            font-weight: 900;
            line-height: 48px;
        }
        p {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
    .tip {
        h2 {
            margin: 34px 0 10px 0;
            font-size: 22px;
            font-weight: 900;
            line-height: 36px;

            span {
                color: #8E929F;
            }
        }
        p {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
        }
    }
`