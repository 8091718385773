import React, { useState } from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import loginLogo from '../../assets/svg/loginlogo.svg';
import { WebRoute } from '../../navigation/WebRoutes';
import { useNavigate } from 'react-router-dom';

let passwordCheckTime;

const AddStore = ({ fModeChange, sProps }) => {
    const [userId, setUserId] = useState("");
    const [userPwd, setUserPwd] = useState("");
    const [userPwdT, setUserPwdT] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");

    const [errorMessageID, setErrorMessageID] = useState("");
    const [errorMessagePw, setErrorMessagePw] = useState("");
    const [errorMessagePwC, setErrorMessagePwC] = useState("");
    const [errorMessageUserNm, setErrorMessageUserNm] = useState("");
    const [errorMessageEmail, setErrorMessageEmail] = useState("");
    const [errorMessagePhone, setErrorMessagePhone] = useState("");
    
    const [classNameId, setClassNameId] = useState(null);
    const [classNamePw, setClassNamePw] = useState(null);
    const [classNamePwCheck, setClassNamePwCheck] = useState(null);
    const [classNameUserName, setClassNameUserName] = useState(null);
    const [classNameEmail, setClassNameEmail] = useState(null);
    const [classNamePhone, setClassNamePhone] = useState(null);
    const navigate = useNavigate()
    const checkId = async (sIndex) => {
        let hasId = true;
    
        const oResponse = await sProps.oManager.fDoAxios("/register/findId-" + sIndex, "get", null, null);
        if(oResponse !== undefined && oResponse !== null){
            if(!oResponse){
                hasId = false;
            }
        }
        return hasId;
    }

    const inputUserId = async (e) => {
        let sUserId = e.target.value;
        const regex = /[a-z0-9]+$/gi;
        if(sUserId === "" || sUserId === null){
            setErrorMessageID("");
            setClassNameId("input_action"); 
            setUserId(sUserId); 
        } else {
            if (regex.test(sUserId)) {
                const result = await checkId(sUserId);
                if(result){
                    setClassNameId("input_wrong"); 
                    setErrorMessageID("이미 사용중인 아이디입니다");
                } else {
                    setClassNameId("input_action"); 
                    setErrorMessageID("");
                }
                setUserId(sUserId);
            } else {
                setClassNameId("input_wrong"); 
                setErrorMessageID("영어나 숫자로만 입력이 가능합니다");
            }
        }
    }
    

    const completeSignUp = async () => {
        if(userId !== "" && userPwd !== "" && userPwdT !== "" && userName !== "" && userEmail !== "" && userPhone !== ""){
                const checkUp = await checkPhoneNm();
                if(checkUp){
                    const oData = {
                        key: sProps.UserConfigReducer.SalesId,
                        type: sProps.UserConfigReducer.SalesUserType,
                        userId,
                        userPwd,
                        userPwdT,
                        userName,
                        userEmail,
                        userPhone,
                    }
                    const oResponse = await sProps.oManager.fDoAxios("/sales/authenticate/addStore/v2", "post", null, oData);
                    if(oResponse !== undefined && oResponse !== null){
                        if(oResponse.resultId === "0000"){
                            navigate(WebRoute.ADMIN_STORE_LIST);
                            // if(fModeChange !== undefined && typeof fModeChange === "function"){
                            //     await fModeChange("sales");
                            // }
                        } else {
                            if(!oResponse.userId){
                                setClassNameId("input_wrong");
                            }
                            if(!oResponse.userPwd){
                                setClassNamePw("input_wrong");
                                setClassNamePwCheck("input_wrong");
                            }
                            if(!oResponse.userName){
                                setClassNameUserName("input_wrong");
                            }
                            if(!oResponse.userEmail){
                                setClassNameEmail("input_wrong");
                            }
                            if(!oResponse.userPhone){
                                setClassNamePhone("input_wrong");
                            }
                        }
                    }
                }
            } else {
                setClassNameId("input_wrong");
                setClassNamePw("input_wrong");
                setClassNamePwCheck("input_wrong");
                setClassNameUserName("input_wrong");
                setClassNameEmail("input_wrong");
                setClassNamePhone("input_wrong");
            }
    }

    const modeChange = async () => {
        navigate(WebRoute.ADMIN_STORE_LIST)
        return;
        if(fModeChange !== undefined && typeof fModeChange === "function"){
            await fModeChange("sales");
        }
    }

    const emailValidation = () => {
        const regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
        
        if(regExp.test(userEmail)){
            setClassNameEmail(null);
            setErrorMessageEmail("");
        } else {
            setClassNameEmail("input_wrong");
            setErrorMessageEmail("이메일 형식에 맞지 않습니다");
        }
    }

    const checkPhoneNm = async () => {
        let temp = false;

        const regex = /[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
        if(userPhone === "" || userPhone === null){
            setErrorMessagePhone(`'-'을 제외한 숫자 및 올바른 번호가 필요합니다`);
            setClassNamePhone("input_wrong"); 
        } else {
            if (regex.test(userPhone)) {
                temp = true;
            } else {
                setClassNamePhone("input_wrong"); 
                setErrorMessagePhone(`'-'을 제외한 숫자 및 올바른 번호가 필요합니다`);
            }
        }

        return temp;
    }

    const inputUserPwdT = (e) => {
        const sValue = e.target.value;
        setUserPwdT(sValue);

        if(passwordCheckTime) clearTimeout(passwordCheckTime);
        passwordCheckTime = setTimeout(() => {
            if(sValue !== userPwd){
                setClassNamePwCheck("input_wrong");
                setErrorMessagePwC("비밀번호를 다시 확인해주세요");
            } else {
                setClassNamePwCheck("input_action");
                setErrorMessagePwC("");
            }
        }, 300);
    }

    const inputUserPwd = (e) => {
        setUserPwd(e.target.value);
        setErrorMessagePw("");
        setClassNamePw("input_action");   
    }

    const inputUserName = (e) => {
        setUserName(e.target.value);
        setErrorMessageUserNm("");
        setClassNameUserName("input_action");   
    }

    const inputUserEmail = (e) => {
        setUserEmail(e.target.value);
        setErrorMessageEmail("");
    }

    const inputUserPhone = (e) => {
        let tempText = e.target.value;
        if(tempText.indexOf("-")){
            tempText = tempText.toString();
            tempText = tempText.replace(/\-/g, "")
        }
        setUserPhone(tempText);
        setErrorMessagePhone("");
    }

    const inputClassNameId = () => {
        validationErr("classNameId");
    }
    const inputClassNamePw = () => {
        validationErr("classNamePw");
    }
    const inputClassNamePwCheck = () => {
        validationErr("classNamePwCheck");
    }
    const inputClassNameUserName = () => {
        validationErr("classNameUserName");
    }
    const inputClassNameEmail = () => {
        validationErr("classNameEmail");
    }
    const inputClassNamePhone = () => {
        validationErr("classNamePhone");
    }

    const validationErr = async (sIndex) => {
        const classNameBox = [
            {name: "classNameId", value: classNameId},
            {name: "classNamePw", value: classNamePw},
            {name: "classNamePwCheck", value: classNamePwCheck},
            {name: "classNameUserName", value: classNameUserName},
            {name: "classNameEmail", value: classNameEmail},
            {name: "classNamePhone", value: classNamePhone},
        ];
        for await (const iterator of classNameBox) {
            if(iterator.value !== "input_wrong"){
                if(sIndex === iterator.name){
                    if(iterator.name === "classNameId"){
                        setClassNameId("input_action");   
                        setErrorMessageID("");
                    } else if(iterator.name === "classNamePw"){
                        setClassNamePw("input_action");   
                        setErrorMessagePw("");
                    } else if(iterator.name === "classNamePwCheck"){
                        setClassNamePwCheck("input_action");   
                        setErrorMessagePwC("");
                    } else if(iterator.name === "classNameUserName"){
                        setClassNameUserName("input_action");   
                        setErrorMessageUserNm("");
                    } else if(iterator.name === "classNameEmail"){
                        setClassNameEmail("input_action");   
                        setErrorMessageEmail("");
                    } else if(iterator.name === "classNamePhone"){
                        setClassNamePhone("input_action");   
                        setErrorMessagePhone("");
                    }
                } else {
                    if(iterator.name === "classNameId"){
                        setClassNameId(null);   
                    }
                    if(iterator.name === "classNamePw"){
                        setClassNamePw(null);   
                    }
                    if(iterator.name === "classNamePwCheck"){
                        setClassNamePwCheck(null);   
                    }
                    if(iterator.name === "classNameUserName"){
                        setClassNameUserName(null);   
                    }
                    if(iterator.name === "classNameEmail"){
                        setClassNameEmail(null);   
                    }
                    if(iterator.name === "classNamePhone"){
                        setClassNamePhone(null);   
                    }
                    
                }
            }
        }
        if(userEmail !== ""){
            emailValidation();
        }
    }

    return (
        <Style>
            <div className="loginPage">
                <div className="loginBox">
                    <SVG src={loginLogo} width="54px" height="18px" title="로그인페이지로고" />
                    <h2>간편등록으로 드라이브 스루가 가능해집니다.</h2>
                    <div className="inputBox">
                        <input 
                            type="text" 
                            placeholder="아이디" 
                            value={userId} 
                            onChange={inputUserId}
                            className={classNameId}
                            onClick={inputClassNameId}
                        />
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageID}</p>
                    <div className="inputBox">
                        <input 
                            type="password" 
                            placeholder="비밀번호" 
                            value={userPwd} 
                            onChange={inputUserPwd} 
                            className={classNamePw}
                            onClick={inputClassNamePw}
                        />
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessagePw}</p>
                    <div className="inputBox">
                        <input 
                            type="password" 
                            placeholder="비밀번호 확인" 
                            value={userPwdT} 
                            onChange={inputUserPwdT} 
                            className={classNamePwCheck}
                            onClick={inputClassNamePwCheck}
                        />
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessagePwC}</p>
                    <div className="inputBox">
                        <input 
                            type="text" 
                            placeholder="대표자명" 
                            value={userName} 
                            onChange={inputUserName}
                            className={classNameUserName}
                            onClick={inputClassNameUserName}
                        />
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageUserNm}</p>
                    <div className="inputBox">
                        <input 
                            type="email" 
                            placeholder="이메일" 
                            value={userEmail} 
                            onChange={inputUserEmail} 
                            className={classNameEmail}
                            onClick={inputClassNameEmail}
                        />
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessageEmail}</p>
                    <div className="inputBox">
                        <input 
                            type='text'
                            placeholder="휴대폰번호( ‘ - ’제외 )"  
                            value={userPhone} 
                            onChange={inputUserPhone}
                            className={classNamePhone}
                            onClick={inputClassNamePhone}
                        />
                    </div>
                    <p style={{ margin: "0", color: "#DD1212", fontSize: "10px", fontWeight: "400" }}>{errorMessagePhone}</p>
                    <button className="join" onClick={completeSignUp} style={{backgroundColor: "#001E62"}}>매장등록</button>
                    <button className="back_out" onClick={modeChange} style={{border: "1px solid #617BE3", marginTop: "5%"}}>뒤로 이동</button>
                </div>
            </div>
        </Style>
    )
}

export default AddStore;

const Style = styled.div`
    .loginPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        background-color: #E5E5E5;
    }

    .loginBox {
        width: 314px;
        min-height: 564px;
        max-height: 694px;
        padding: 24px;
        background-color: #fff; 
        border-radius: 4px;

        h2 {
            margin: 16px 0 8px 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 19.5px;
        }
        .inputBox {
            position: relative;

            input {
                width: 266px;
                height: 44px;
                margin: 3px 0;
                padding: 12px 14px;
                border: 1px solid #bbb;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                outline: none;
                color: #000;
                font-family: "tway_sky";
            }
        }

        .input_action {
            border: solid 1px #000000;
            transition: 0.1s ease-out;
        }

        .input_wrong {
            border: solid 1px #DD1212;
            transition: 0.1s ease-out;
        }

        input.form {
            border: 1px solid red;
        }
        label {
            width: 266px;
            height: 44px;
            position: relative;
            margin-bottom: 12px;
        }
        label input {
            position: absolute;
            margin: 3px 0;
            border: 1px solid #bbb;
            outline: none;


        }
        label button, .btn {
            position: relative;
            width: 91px;
            height: 44px;
            border: none;
            outline: none;
            margin: 3px 0;
            right: -175px;
            bottom:  0;
            top: 0;
            background-color: #bbb;
            border-radius: 4px;
            margin: 3px 0;
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
        }
        .cancelBtn {
            position: absolute;
            right: 0;
            top: 0;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: none;
            outline: none;
            width: 30px;
            height: 40px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin: 5px 0 16px 0;
        }
        p span {
            color: #617BE3;
            font-weight: 500;
        }
        .join {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
        }

        .back_out {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            background: #fff;
            color: #617BE3;
            border: none;
            outline: none;
        }
        .back_out:hover {
            width: 266px;
            height: 48px;
            border-radius: 8px;
            background-color: #bbb;
            font-size: 14px;
            font-weight: 700;
            line-height: 21px;
            background: #617BE3;
            text-align: center;
            color: #fff;
            border: none;
            outline: none;
        }

        .memberLogin {
            margin-top: 12px;
            
            p {
                text-align: center;
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;
                color: #666;
            }
        }
    }

    @media (max-width: 550px){
        .loginPage {
            background-color: #fff;
            height: auto;
        }
        .loginBox {
            padding: 24px;
        }
    }
`;