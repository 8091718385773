import React, { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import '../../assets/fonts/font.css'
import "antd/dist/antd.css";

import closeButton from '../../assets/img/modal/closeBtn.png';


export const PcModalPublishModalStamp = ({ sProps, aRef, fnClose, fnComplete }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const openModal = () => {
        setIsOpen(true);
    }

    const complete = () => {
        if(typeof fnComplete === "function" && fnComplete !== undefined){
            fnComplete();
        }
    }

    const closeModal = () => {
        if(typeof fnClose === "function" && fnClose !== undefined){
            fnClose();
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '20%',
                    height: "65vh",
                    border: 'none',
                    width: "20vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <PcCommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>주의사항</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "3vh", width: "3vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className="stamp_title">
                        <h3>
                            발행 조건을 다시 한번 확인해주세요.<br />
                            스탬프는 한번 발행되면 모든 조건의 <br />
                            수정 및 발행취소가 절대 불가합니다.<br />
                            (발행 받은 고객이 없을때만 가능)
                        </h3>
                    </div>
                    <div className='stamp_body'>
                        <p>스탬프 문구 : {aRef.name}</p>
                        <p>스탬프 목표수 : 총 {aRef.target} 개</p>
                        <p>스탬프 발행조건 : {aRef.couponCount} 원</p>
                        <p>스탬프 목표달성 선불권 금액 : {aRef.amount} 원</p>
                        <p>스탬프 이벤트 기간 : 발행일로부터 {aRef.period} 개월</p>
                    </div>
                    <div className='button_box'>
                        <div className='complete_box' onClick={complete}>
                            <p>네</p>
                        </div>
                        <div className='cancel_box' onClick={closeModal}>
                            <p>아니오</p>
                        </div>
                    </div>
                </div>
            </PcCommercialCouponStyle>
        </Modal>
    )
}

export const ModalPublishModalStamp = ({ sProps, aRef, fnClose, fnComplete }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const openModal = () => {
        setIsOpen(true);
    }

    const complete = () => {
        if(typeof fnComplete === "function" && fnComplete !== undefined){
            fnComplete();
        }
    }

    const closeModal = () => {
        if(typeof fnClose === "function" && fnClose !== undefined){
            fnClose();
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '10%',
                    bottom: '20%',
                    height: "65vh",
                    border: 'none',
                    width: "80vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>주의사항</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "15vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className="stamp_title">
                        <h3>
                            발행 조건을 다시 한번 확인해주세요.<br />
                            스탬프는 한번 발행되면 모든 조건의 <br />
                            수정 및 발행취소가 절대 불가합니다.(발행 받은 고객이 없을때만 가능)
                        </h3>
                    </div>
                    <div className='stamp_body'>
                        <p>스탬프 문구 : {aRef.name}</p>
                        <p>스탬프 목표수 : 총 {aRef.target} 개</p>
                        <p>스탬프 발행조건 : {aRef.couponCount} 원</p>
                        <p>스탬프 목표달성 선불권 금액 : {aRef.amount} 원</p>
                        <p>스탬프 이벤트 기간 : 발행일로부터 {aRef.period} 개월</p>
                    </div>
                    <div className='button_box'>
                        <div className='complete_box' onClick={complete}>
                            <p>네</p>
                        </div>
                        <div className='cancel_box' onClick={closeModal}>
                            <p>아니오</p>
                        </div>
                    </div>
                </div>
            </CommercialCouponStyle>
        </Modal>
    )
}

export const ModalCautionStamp = ({ sProps, fnClose, fnComplete }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const openModal = () => {
        setIsOpen(true);
    }

    const complete = () => {
        if(typeof fnComplete === "function" && fnComplete !== undefined){
            fnComplete();
        }
    }

    const closeModal = () => {
        if(typeof fnClose === "function" && fnClose !== undefined){
            fnClose();
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '10%',
                    bottom: '20%',
                    height: "45vh",
                    border: 'none',
                    width: "80vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <CommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>주의사항</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "5vh", width: "15vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className="eventBanner">
                        <h3>
                            신규 발행은 중지되지만<br />
                            이미 발급 받은 고객은 스탬프 취득과정과<br />
                            목표달성 혜택이 최초 발행 조건대로 유지됩니다.<br />
                            주의사항을 확인했으며 변경하시겠습니까?
                        </h3>
                    </div>
                    <div className='button_box'>
                        <div className='submit_box' onClick={complete}>
                            <p>네</p>
                        </div>
                        <div className='delete_box' onClick={closeModal}>
                            <p>아니오</p>
                        </div>
                    </div>
                </div>
            </CommercialCouponStyle>
        </Modal>
    )
}

export const PcModalCautionStamp = ({ sProps, fnClose, fnComplete }) => {
    const [modalIsOpen, setIsOpen] = useState(true);

    const openModal = () => {
        setIsOpen(true);
    }

    const complete = () => {
        if(typeof fnComplete === "function" && fnComplete !== undefined){
            fnComplete();
        }
    }

    const closeModal = () => {
        if(typeof fnClose === "function" && fnClose !== undefined){
            fnClose();
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={openModal}
            ariaHideApp={false}
            style={{
                overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0,.75)',
                    overflow: 'hidden',
                    height: '100%',
                    zIndex: 100
                },
                content: {
                    position: 'absolute',
                    top: '15%',
                    left: '35%',
                    bottom: '20%',
                    height: "45vh",
                    border: 'none',
                    width: "25vw",
                    background: '#fff',
                    borderRadius: '1%',
                    outline: 'none',
                    felxDirection: 'column',
                }
            }}
        >
            <PcCommercialCouponStyle>
                <div className='container'>
                    <div className='title_box'>
                        <p>주의사항</p>
                        <div className='delete_box' onClick={closeModal} style={{ position: "absolute", right: 0, height: "3vh", width: "5vw", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer"}}>
                            <img src={closeButton} alt="닫기버튼" width="35%" />
                        </div>
                    </div>
                    <div className="eventBanner">
                        <h3>
                            신규 발행은 중지되지만<br />
                            이미 발급 받은 고객은 스탬프 취득과정과<br />
                            목표달성 혜택이 최초 발행 조건대로 유지됩니다.<br />
                            주의사항을 확인했으며 변경하시겠습니까?
                        </h3>
                    </div>
                    <div className='button_box'>
                        <div className='submit_box' onClick={complete}>
                            <p>네</p>
                        </div>
                        <div className='delete_box' onClick={closeModal}>
                            <p>아니오</p>
                        </div>
                    </div>
                </div>
            </PcCommercialCouponStyle>
        </Modal>
    )
}


const PcCommercialCouponStyle = styled.div`
    .container {
        font-family: 'Pretendard';

        .title_box {
            display: flex;
            height: 5vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .eventBanner {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 1vh;
            height: 25vh;
            
            h3 {
                color: #767A83;
                font-size: 0.9rem;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .stamp_title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 1vh;
            height: 15vh;

            h3 {
                color: #767A83;
                font-size: 0.9rem;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .stamp_body {
            margin-top: 3vh;
            height: 25vh;
            p {
                color: #000;
                font-size: 1rem;
                font-weight: 600;
            }
        }
        
        .button_box {
            display: flex;
            height: 10vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            .submit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                cursor: pointer;
                p {
                    padding-top: 8%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #6490E7;
                }
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                border-radius: 10px;
                background: #EF4452;
                cursor: pointer;
                p {
                    padding-top: 8%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }
            }

            .complete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                border-radius: 10px;
                background: #6490E7;
                cursor: pointer;
                p {
                    padding-top: 12%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }
            }

            .cancel_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                cursor: pointer;
                p {
                    padding-top: 12%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #EF4452;
                }
            }
        }
    }
`;

const CommercialCouponStyle = styled.div`
    .container {
        font-family: 'Pretendard';

        .title_box {
            display: flex;
            height: 5vh;
            width: 100%;
            align-items: center;
            justify-content: center;

            p {
                padding-top: 2vh;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .eventBanner {
            padding-top: 1vh;
            height: 25vh;
            h3 {
                margin: 3px 0px 0px 0px;
                color: #767A83;
                font-size: 0.9rem;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .stamp_title {
            padding-top: 2vh;
            height: 15vh;
            h3 {
                color: #767A83;
                font-size: 0.8rem;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .stamp_body {
            margin-top: 3vh;
            height: 25vh;
            p {
                color: #000;
                font-size: 0.8rem;
                font-weight: 800;
            }
        }

        .button_box {
            display: flex;
            height: 12vh;
            width: 100%;
            align-items: center;
            justify-content: center;
            
            .submit_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                cursor: pointer;
                p {
                    padding-top: 12%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #6490E7;
                }
            }

            .delete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                border-radius: 10px;
                background: #EF4452;
                cursor: pointer;
                p {
                    padding-top: 12%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }
            }

            .complete_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                border-radius: 10px;
                background: #6490E7;
                cursor: pointer;
                p {
                    padding-top: 12%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }
            }

            .cancel_box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 5vh;
                width: 100%;
                cursor: pointer;
                p {
                    padding-top: 12%;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #EF4452;
                }
            }
        }
    }
`;