import React from 'react';
import styled from 'styled-components';

// 이미지
import sound01 from '../../../../assets/img/guide/pos/sound01.png';
import sound02 from '../../../../assets/img/guide/pos/sound02.png';
import sound03 from '../../../../assets/img/guide/pos/sound03.png';

const NoticeGuide = (oProps) => {

    return (
        <D_noticeGuide>
            <div className="posGuide">
                <div className="container">
                    <div className="pGuideCont">
                        <h3>포스 가이드</h3>
                        <h1>고객 근접, 도착 알림 설정</h1>
                        <p>스루 주문 접수 후 고객이 매장 도착 전 두 번의 알림이 울립니다. <br />어떤 상황에서 알림이 울리는지 확인 후 매장의 상황에 맞게 알림 설정해 주세요.</p>
                        <h2><span>STEP 1</span><br />근접 알림, 도착 알림 모두 설정</h2>
                        <img src={sound01} alt="포스 알림 설정" width="100%" />
                        <p>기본 설정으로 고객이 매장 도착 200M 전 근접 알림과, 50M 전 도착 알림이 같이 울립니다.<br /> 고객의 위치를 자주 확인하고 싶은 사장님들은 두 알림을 모두 켜주세요.</p>
                        <h2><span>STEP 2</span><br />고객 도착 알림만 설정</h2>
                        <img src={sound02} alt="포스 알림 설정" width="100%" />
                        <p>근접 알림을 끄게 되면 고객이 매장 도착 50M 전에만 알림이 울립니다.<br />고객이 매장 도착전에만 알림을 받고 싶은 사장님은 도착 알림만 켜주세요.</p>
                        <div className="tip">
                            <p>
                                😊 TIP <br />
                                근접 알림은 매장 도착200M 전이 기본 설정값이지만 사장님이 원하시는 거리로 변경도 가능합니다.
                            </p>
                            <h2>스루사장님창구 <span style={{ color: '#212329' }}>&gt;</span> 매장관리 <span style={{ color: '#212329' }}>&gt;</span> 로그인 <span style={{ color: '#212329' }}>&gt;</span> 매장관리 <span style={{ color: '#212329' }}>&gt;</span> 픽업정보에서 거리 변경이 가능합니다.</h2>
                            <img src={sound03} alt="포스 알림 설정" width="100%" />
                            <p>
                                매장 상황에 맞게 거리를 설정해 보세요. 설정해주신 거리에서 근접알림이 울려요.<br />
                                사장님이 원하시는 거리에서 울리는 알람으로 시간을 효율적으로 분배해 고객과 약속된 시간에 상품을 전달할 수 있습니다.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </D_noticeGuide>
    )
}
export default NoticeGuide;

export const D_noticeGuide = styled.aside`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'NanumSquare';
        color: #212329;
    }
    p, a, h1, h2, h3, h4, h5, h6 {
        transform: skew(-0.1deg);
    }
    
    .container {
        width: 100%;
        padding: 0 5vw;
        margin: 0 auto;
    }
    .pGuideCont {
        margin-top: 110px;
        margin-bottom: 80px;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        color: #888;
    }
    h1 {
        font-size: 32px;
        font-weight: 900;
        margin: 14px 0 26px 0;
    }
    .subTitle p {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;

        em,span {
            font-weight: 900;
            font-style: normal;
            color: #F45452;
        }
    }
    h2 {
        margin-top: 40px;
        margin-bottom: 12px;
        font-size:22px;
        font-weight: 900;
        line-height : 36px;
        word-break: keep-all;

        span {
            color: #8E929F;
        }
    }
    h2 span:nth-child(3) {
        color: #F45452;
        font-weight: 900;
    }
    p {
        margin: 15px 0 25px 0;
        font-size: 16px;
        font-weight: 700;

        span {
            color: #F45452;
            font-weight: 900;
        }
    }
    .tip {
        margin-top: 34px;
        padding-top: 34px;
        border-top: 2px solid #EEEEEE;

        h2 {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 900;
            line-height: 32px;
        }
        p {
            margin: 10px 0;
            font-size: 16px;
            font-weight: 600;
        }
    }
`