import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactExport from "react-data-export";
import "antd/dist/antd.css";
import { message } from 'antd';
import SVG from 'react-inlinesvg';
import manual from '../../../../assets/svg/manual.svg';
import moment from 'moment-timezone';
import 'moment/locale/ko';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const AdjustmentPayment = ({ sData, sName }) => {
   const [isHide, setHide] = useState(false);
   const [sPaymentStatus, set_sPaymentStatus] = useState("입금예정");

   const init = () => {
      let sPaymentStatusTxt = "입금예정";

      let today = moment().isoWeekday();
      let iYear = moment().year();
      let bCheckTime = moment().isAfter(moment({ hour: 18, minute: 0 }));
      if (today === 3 && bCheckTime === true) {
         sPaymentStatusTxt = "입금완료";
      }

      let sDate = iYear.toString() + "-" + sData.date;
      let iDiff = moment().isAfter(sDate, 'days');

      if (iDiff === true) {
         sPaymentStatusTxt = "입금완료";
      }

      set_sPaymentStatus(sPaymentStatusTxt);
   }

   useEffect(() => {
      init();
   }, []);

   return (
      <Style>
         <div className="title_box">
            <div className="pay_date">
               <p>{sData.date}</p>
            </div>
            <div className="pay_status">
               {sPaymentStatus === "입금예정" ?
                  <p style={{ color: "#e39161" }}>{sPaymentStatus}</p>
                  :
                  <p style={{ color: "#617BE3" }}>{sPaymentStatus}</p>
               }
            </div>
            <div className="pay_period">
               <p>{sData.period}</p>
            </div>
            <div className="pay_amount">
               <p>{sData.return} 원</p>
            </div>
            <div className="excel_download">
               <ExcelFile
                  filename={`스루-${sData.date}_정산명세서_${sName}`}
                  element={
                     <p>정산명세서 받기</p>
                  }
               >
                  <ExcelSheet dataSet={sData.iList} name="요약" />
                  <ExcelSheet dataSet={sData.aList} name="상세" />
               </ExcelFile>
            </div>
            <div className="detail_line" onClick={() => setHide(!isHide)}>
               <p>{isHide ? "자세히" : "숨기기"}</p>
            </div>
         </div>
         {!isHide &&
            <div className="detail_content">
               <div className="content_box">
                  <p>(A)매출 금액</p>
                  <p>{sData.totalPayment} 원</p>
               </div>
               <div className="content_box">
                  <p>(B)정산잔액</p>
                  <p>{sData.total_carried_balance} 원</p>
               </div>
               <div className="content_box">
                  <p>(C)스루-부담-할인</p>
                  <p>{sData.throoTotal} 원</p>
               </div>
               <div className="content_box_detail" style={{ display: "none" }}>
                  <p>- 적립금</p>
                  <p>{sData.tMoney} 원</p>
               </div>
               <div className="content_box_detail" style={{ display: "none" }}>
                  <p>- 쿠폰</p>
                  <p>{sData.tCoupon} 원</p>
               </div>
               <div className="content_box">
                  <p>(D)사장님-부담-할인</p>
                  <p>{sData.partnerTotal} 원</p>
               </div>
               <div className="content_box_detail" style={{ display: "none" }}>
                  <p>- 적립금</p>
                  <p>{sData.pMoney} 원</p>
               </div>
               <div className="content_box_detail" style={{ display: "none" }}>
                  <p>- 쿠폰</p>
                  <p>{sData.pCoupon} 원</p>
               </div>
               <div className="content_box_detail" style={{ display: "none" }}>
                  <p>- 스탬프</p>
                  <p>{sData.pStamp} 원</p>
               </div>
               <div className="content_box">
                  <div className='guide_box'>
                     <p>(E)카드결제 수수료</p>
                     <div className="tip">
                        <SVG src={manual} width="14px" height="14px" />
                        <span><strong>카드수수료 3%(부가세별도) </strong>가 차감되어 정산 됩니다.</span>
                     </div>
                  </div>
                  <p>{sData.fee} 원</p>

               </div>
               {sData.usage_balance &&
                  <div className="content_box">
                     <p>(F)서비스 이용료</p>
                     <p>{sData.usage_balance} 원</p>
                  </div>
               }
               <div className="return_box">
                  <p>입금받을금액((A + B) - (D + E + F))</p>
                  <p>{sData.rawReturn} 원</p>
               </div>
            </div>
         }
      </Style>
   )
}
export default AdjustmentPayment;

const Style = styled.div`

    .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        height: 12vh;

        .pay_date {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;
        }
        .pay_status {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .pay_period {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .pay_amount {
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .excel_download {
            position:relative;
            border-radius: 5px;
            width: 20%;
            height: 4vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #617BE3;
            cursor: pointer;
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 0.7rem;
                text-align: center;
                color: #fff;
            }
        } 

        .detail_line {
            border-radius: 5px;
            width: 13%;
            height: 4vh;
            display: flex;
            align-items: center;
            justify-content: center;
            border : 1px solid #617BE3;
            cursor: pointer;
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 0.8rem;
                text-align: center;
                color: #617BE3;
            }
        }

        p{
            font-style: normal;
            font-weight: 400;
            font-size: 0.8rem;
            text-align: center;
            color: #000;
        }
    }

    .detail_content {        
        background: #f9f9f9;

        .content_box {
            margin-left: 40%;
            margin-right: 15%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 5vh;

            .guide_box {
               display: flex;
               align-items: center;
               justify-content: flex-start;

               svg {
                  margin-left: 5px;
               }
            }
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 0.7rem;
                text-align: center;
                color: #000;
            }
        }
        .content_box_red {
         margin-left: 40%;
         margin-right: 15%;
         display: flex;
         align-items: center;
         justify-content: space-between;
         height: 5vh;

         .guide_box {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
               margin-left: 5px;
            }
         }
         p{
             font-style: normal;
             font-weight: 500;
             font-size: 0.7rem;
             text-align: center;
             color: #ff0000;
         }
      }
        .content_box_detail {
            margin-left: 40%;
            margin-right: 15%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 3vh;

            p{
                font-style: normal;
                font-weight: 500;
                font-size: 0.7rem;
                text-align: center;
                color: #666666;
            }
        }
        .return_box {
            border-top: 1px solid #dfdfdf;
            margin-left: 40%;
            margin-right: 15%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 5vh;

            p{
                font-style: normal;
                font-weight: 600;
                font-size: 0.8rem;
                text-align: center;
                color: #000;
            }
        }
    }

`;