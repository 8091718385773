import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Result, Typography } from "antd";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CloseCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import { WebRoute } from '../../../navigation/WebRoutes';

const { Paragraph } = Typography;

const ManagementPosItem = (oProps) => {
   const [wrongAccess, setWrongAccess] = useState(false);
   const [oSharedMenuData, set_oSharedMenuData] = useState('');

   const navigate = useNavigate();
   const location = useLocation()
   const reduxData = async (oResponse, str) => {
      let oUserConfig = {};
      oUserConfig['isPOS'] = true;
      oUserConfig['LoggedIn'] = true;
      oUserConfig['Token'] = await oResponse.token;
      oUserConfig['RefreshToken'] = await str[2];
      oUserConfig['UUID'] = await oResponse.uuid;

      oUserConfig['StoreID'] = await str[1];
      oUserConfig['StoreName'] = await oResponse.store_name;
      oUserConfig['StorePhone'] = await oResponse.store_phone_number;
      oUserConfig['Address'] = await oResponse.store_address;
      oUserConfig['StoreOwner'] = await oResponse.full_name;
      oUserConfig['StoreLat'] = await oResponse.store_lat;
      oUserConfig['StoreLng'] = await oResponse.store_lng;

      oUserConfig['accessible'] = await oResponse.status;

      await oProps.reduxSetUserConfig(oUserConfig);
   }

   const validate = async () => {
      if (!wrongAccess) {
         if (location.search !== null && location.search !== undefined && location.search !== "") {
            let str = await location.search;
            str = await str.substr(2, str.length);
            str = await str.split("?@=");
            if (str.length > 2) {
               const oData = {
                  storeId: str[1],
                  storeToken: str[2]
               };
               const oResponse = await oProps.oManager.fDoAxios('/authenticate/app', "post", null, oData);
               if (oResponse !== undefined) {
                  if (oResponse.resultCd === "0000") {
                     setWrongAccess(false);
                     await reduxData(oResponse, str);

                     if (str[0].toString() === "inventory") {
                        navigate(WebRoute.POS_INVENTORY);
                     } else if (str[0].toString() === "payment") {
                        navigate(WebRoute.POS_PAYMENT);
                     } else if (str[0].toString() === "product") {
                        navigate(WebRoute.POS_PRODUCT);
                     }

                  } else {
                     setWrongAccess(true);
                  }
               } else {
                  setWrongAccess(true);
               }
            } else {
               setWrongAccess(true);
            }
         } else {
            setWrongAccess(true);
         }
      }
   }

   const checkSharedMenu = async () => {
      let iStoreId = 0;
      if (location.search !== null && location.search !== undefined && location.search !== "") {
         let str = await location.search;
         str = await str.substr(2, str.length);
         str = await str.split("?@=");
         if (str.length > 2) {
            iStoreId = parseInt(str[1]);
         }
      }

      const oSharedMenuResponse = await oProps.oManager.fDoAxios("/store/menu/checkShared", "post", null, { store_id: iStoreId });
      if (oSharedMenuResponse !== undefined && oSharedMenuResponse.has_shared_menu !== undefined && oSharedMenuResponse.has_shared_menu === 1) {
         set_oSharedMenuData(oSharedMenuResponse);
         return;
      }
      validate();
   }

   //useEffect(() => {
   //   validate();
   //}, [wrongAccess, oProps]);

   useEffect(() => {
      checkSharedMenu();
   }, []);

   return (
      <div>
         {oSharedMenuData !== '' && oSharedMenuData.has_shared_menu !== undefined &&
            <div className="stockPage">
               <div className="menu_container" style={{ height: '12vh' }}>
                  <p style={{ fontSize: 14, color: 'red', paddingLeft: 16, paddingRight: 16, paddingTop: 10, fontWeight: 600 }}>공유 메뉴 사용하고 있습니다. 메뉴 수정/재고관리 불가합니다.</p>
               </div>
            </div>
         }
         {wrongAccess &&
            <Row className="justify-content-md-center" style={{ marginTop: "10%", marginBottom: "10%" }}>
               <Col md="6">
                  <Result
                     status="error"
                     title="잘못된 접근입니다"
                  >
                     <div className="desc">
                        <Paragraph>
                           <CloseCircleOutlined className="site-result-demo-error-icon" style={{ marginRight: "1%" }} />
                                혹시 잘못된 주소를 입력하셨나요? 정확한 주소로 접근해주세요
                            </Paragraph>
                        <Paragraph>
                           <CloseCircleOutlined className="site-result-demo-error-icon" style={{ marginRight: "1%" }} />
                                네트워크에러입니다 잠시 후 다시 시도바랍니다
                            </Paragraph>
                     </div>
                  </Result>
               </Col>
            </Row>
         }
      </div>
   )
}

export default ManagementPosItem;